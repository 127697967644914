var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: { title: "到手价", visible: _vm.dialogVisible, width: "700px" },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.closeDialog,
      },
    },
    [
      _c("div", { staticClass: "couponInfo" }, [
        _c("div", { staticClass: "left" }, [
          _c("img", { attrs: { src: _vm.coupon, width: "60", height: "50" } }),
          _c("h3", [_vm._v("优惠信息")]),
        ]),
        _c("div", { staticClass: "right" }, [
          _vm.yhPriceDetailData.yhMessageSkuAll
            ? _c("span", { staticClass: "item" }, [
                _c("span", { staticClass: "label" }, [_vm._v("【商品券】：")]),
                _c("span", { staticClass: "value" }, [
                  _vm._v(_vm._s(_vm.yhPriceDetailData.yhMessageSkuAll)),
                ]),
              ])
            : _vm._e(),
          _vm.yhPriceDetailData.giftMoney
            ? _c("span", { staticClass: "item" }, [
                _c("span", { staticClass: "label" }, [_vm._v("【礼金】：")]),
                _c("span", { staticClass: "value" }, [
                  _vm._v(_vm._s(_vm.yhPriceDetailData.giftMoney)),
                ]),
              ])
            : _vm._e(),
          _vm.yhPriceDetailData.yhMessageShopAll
            ? _c("span", { staticClass: "item" }, [
                _c("span", { staticClass: "label" }, [_vm._v("【店铺券】：")]),
                _c("span", { staticClass: "value" }, [
                  _vm._v(_vm._s(_vm.yhPriceDetailData.yhMessageShopAll)),
                ]),
              ])
            : _vm._e(),
          _vm.yhPriceDetailData.yhMessagePlatAll
            ? _c("span", { staticClass: "item" }, [
                _c("span", { staticClass: "label" }, [
                  _vm._v("【跨店活动】："),
                ]),
                _c("span", { staticClass: "value" }, [
                  _vm._v(_vm._s(_vm.yhPriceDetailData.yhMessagePlatAll)),
                ]),
              ])
            : _vm._e(),
        ]),
      ]),
      _c("div", { staticClass: "flowChart" }, [
        _c("div", { staticClass: "floor1" }, [
          _c("div", { staticClass: "item" }, [
            _c("span", [
              _vm._v("原价：" + _vm._s(_vm.yhPriceDetailData.price)),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "floor2" }, [
          _vm.yhPriceDetailData.yhMessageSku
            ? _c("div", { staticClass: "item" }, [
                _c("span", [
                  _vm._v(
                    "【商品券】：" + _vm._s(_vm.yhPriceDetailData.yhMessageSku)
                  ),
                ]),
                _c("span", [
                  _vm._v(
                    "-" + _vm._s(_vm.yhPriceDetailData.yhAmountSku) + "元"
                  ),
                ]),
              ])
            : _vm._e(),
          _vm.yhPriceDetailData.yhMessageShop
            ? _c("div", { staticClass: "item" }, [
                _c("span", [
                  _vm._v(
                    "【店铺券】：" + _vm._s(_vm.yhPriceDetailData.yhMessageShop)
                  ),
                ]),
                _c("span", [
                  _vm._v(
                    "-" + _vm._s(_vm.yhPriceDetailData.yhAmountShop) + "元"
                  ),
                ]),
              ])
            : _vm._e(),
          _vm.yhPriceDetailData.yhMessagePlat
            ? _c("div", { staticClass: "item" }, [
                _c("span", [
                  _vm._v(
                    "【跨店活动】：" +
                      _vm._s(_vm.yhPriceDetailData.yhMessagePlat)
                  ),
                ]),
                _c("span", [
                  _vm._v(
                    "-" + _vm._s(_vm.yhPriceDetailData.yhAmountPlat) + "元"
                  ),
                ]),
              ])
            : _vm._e(),
        ]),
        _c("div", { staticClass: "floor3" }, [
          _c("div", { staticClass: "item" }, [
            _c("span", [
              _vm._v("-礼金" + _vm._s(_vm.yhPriceDetailData.giftMoney) + "元"),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "floor4" }, [
          _c("div", { staticClass: "item" }, [
            _c("span", [
              _vm._v("到手价：" + _vm._s(_vm.yhPriceDetailData.yhPrice) + "元"),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }