<template>
  <div class="new-box">
    <div class="mg-search" >
      <el-card style="margin: 10px;">
        <SearchFilter  @search="handleFilter('hand')" @reset="resetQuery" @keyup.enter.native="handleFilter">
          <el-form-item label="商品类型" >
            <el-select v-model="queryForm.itemType">
              <el-option label="全部" :value=null />
              <el-option label="晨光商品" :value=1 />
              <el-option label="非晨光商品" :value=2 />
            </el-select>
          </el-form-item>
          <el-form-item label="内容">
            <el-input v-model="queryForm.experienceContent" clearable placeholder="请输入商品内容关键字" />
          </el-form-item>
          <el-form-item label="反馈时间" prop="rangeTime">
            <el-date-picker v-model="rangeTime" type="datetimerange" format="yyyy-MM-dd" value-format="yyyy-MM-dd" style="width: 500px;"></el-date-picker>
          </el-form-item>
        </SearchFilter>
    </el-card>
    </div>
    <el-card style="margin: 10px;" class="box-card">
      <el-button class="form-btn-item" size="mini" icon="el-icon-download" type="primary" @click="handleExperienceListExport">导出</el-button>

      <el-table
      style="margin-top: 10px;"
      ref="tableRef"
      :data="pageData.rows"
           size="small"
           class="customer-table"
           border
      v-loading="loading"
      highlight-current-row
      >
        <el-table-column label="内容" prop="experienceContent" width="200" >
          <template slot-scope="{ row }">
            <div style="text-align: left">
              <span>{{row.experienceContent}}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="图片/视频" width="300" align="center" >
          <template slot-scope="{ row }">
            <div style="text-align: left">
              <el-image v-for="(item) in row?.experienceMedias?.filter(i => i.mediaType==='image')?.map(i => i.mediaUrl)" :preview-src-list="[item]" :key="item" :src="item" lazy style="height: 80px;margin: 5px;">
              </el-image>
            </div>
            <div style="text-align: left">
              <video v-for="(item) in row?.experienceMedias?.filter(i => i.mediaType==='video')?.map(i => i.mediaUrl)" :key="item" :src="item" width="200px" height="160px" style="margin: 5px" controls >
              </video>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="商品类型" prop="itemTypeStr" width="100"  />
        <el-table-column label="商品信息" prop="skuInfos" width="200"  >
          <template slot-scope="{ row }">
            <div style="white-space: pre-line;">{{ row.skuInfos }}</div>
          </template>
        </el-table-column>
        <el-table-column label="反馈时间" prop="createTime" width="150"  />
        <el-table-column label="反馈人姓名" prop="feedbackUserName" width="150"  />
        <el-table-column label="反馈人分公司" prop="feedbackUserOrgName" width="150"  />
        <el-table-column label="反馈人手机号" prop="feedbackUserMobile" width="150"  />
        <el-table-column label="反馈人角色" prop="feedbackUserType" width="150"  />
      </el-table>
      <Pagination :total="pageData.total" :page.sync="queryForm.page" :limit.sync="queryForm.limit" @pagination="handleQuery" />
    </el-card>
  </div>
</template>
<script>
import {
  exportBoardItemFeedbacks,
  listBoardItemFeedbacks
} from "@/api/appTask";
import Pagination from "@/components/Pagination";
import SearchFilter from "@/components/SearchFilter/index";
import { deepClone } from "../../../../utils";
export default {
  name: "BoostPacket",
  components: { Pagination, SearchFilter},
  data() {
    return {
      deepClone,
      pageData: {
        total: 0,
        rows: [],
        selections: [],
      },
      rangeTime: [],
      queryForm: {
        page: 1,
        limit: 10,
        experienceContent: undefined,
        itemType: null,
        createTimeStart: undefined,
        createTimeEnd: undefined,
      },
      loading: false,
      dictParam: {}
    };
  },
  created() {
    this.handleQuery();
  },
  methods: {
    handleQuery() {
      this.loading = true;
      const param = { ...deepClone(this.queryForm), createTimeStart: this.rangeTime?.[0] ? (this.rangeTime?.[0] + ' 00:00:00') : undefined , createTimeEnd: this.rangeTime?.[1] ? (this.rangeTime?.[1] + ' 23:59:59') : undefined };
      listBoardItemFeedbacks(param).then(res => {
        if (!res.data) {
          return false;
        }
        this.loading = false;
        this.pageData.rows = res.data?.datas;
        this.pageData.total = res.data?.total;
      })
    },
    handleFilter() {
      this.queryForm.page = 1;
      this.handleQuery();
    },
    resetQuery() {
      const queryForm = {
        page: 1,
        limit: 10,
        experienceContent: undefined,
        itemType: null,
        createTimeStart: undefined,
        createTimeEnd: undefined,
      };
      this.queryForm = Object.assign({}, queryForm);
      this.rangeTime = [];
      this.$forceUpdate();
    },
    handleExperienceListExport() {
      this.loading = true;
      const param = { ...deepClone(this.queryForm), createTimeStart: this.rangeTime?.[0] ? (this.rangeTime?.[0] + ' 00:00:00') : undefined , createTimeEnd: this.rangeTime?.[1] ? (this.rangeTime?.[1] + ' 23:59:59') : undefined };
      exportBoardItemFeedbacks(param).then(res => {
        this.loading = false;
        if (!res) {
          return
        }
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = window.URL.createObjectURL(new Blob([res]))
        link.setAttribute('download', '商品反馈导出.xlsx')
        document.body.appendChild(link)
        link.click()
        link.remove()
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.label-class {
  font-weight: 600;
  font-size: 15px;
  color: #ac7017;
}
::v-deep{
  table {
    width: 100%!important;
  }
  .el-upload--picture-card {
    height:0px;
    width: 0px;
    .el-button{
      display: flex;
      margin: 3px auto;
    }
  }
  .el-upload-list__item {
    height:30px;
    width: 60px;
  }
  .el-checkbox__label {
    padding-left: 1px;
  }
}
</style>
