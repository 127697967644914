var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.homeLoading,
          expression: "homeLoading",
        },
      ],
      staticStyle: { padding: "20px" },
    },
    [
      _c(
        "div",
        { staticClass: "mg-search", staticStyle: { "padding-bottom": "15px" } },
        [
          _c(
            "el-form",
            {
              ref: "queryFormRef",
              attrs: {
                size: "mini",
                "label-width": "auto",
                inline: true,
                model: _vm.queryForm,
              },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.handleQuery.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "平台" } },
                [
                  _c("DictSelect", {
                    ref: "platform",
                    attrs: {
                      type: "platform",
                      value: _vm.queryForm.platforms,
                      multiple: "",
                      dictFun: _vm.dictPlatformFun,
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(_vm.queryForm, "platforms", $event)
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "型号" } },
                [
                  _c("DictSelect", {
                    ref: "skuModel",
                    attrs: {
                      type: "skuModel",
                      parentLinkageRef: "",
                      value: _vm.queryForm.skuModels,
                      dictFun: _vm.dictSkuModelsFun,
                      multiple: "",
                      remote: "",
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(_vm.queryForm, "skuModels", $event)
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "form-btn-item",
                      attrs: {
                        size: "mini",
                        icon: "el-icon-search",
                        type: "primary",
                      },
                      on: { click: _vm.handleFilter },
                    },
                    [_vm._v("查询")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "form-btn-item",
                      attrs: {
                        size: "mini",
                        icon: "el-icon-plus",
                        type: "primary",
                      },
                      on: { click: _vm.handleSkuPriceControlAdd },
                    },
                    [_vm._v("单行新增")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "form-btn-item",
                      attrs: {
                        size: "mini",
                        icon: "el-icon-upload",
                        type: "primary",
                      },
                      on: { click: _vm.handleSkuPriceControlImportNotice },
                    },
                    [_vm._v("批量导入")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "form-btn-item",
                      attrs: {
                        size: "mini",
                        icon: "el-icon-download",
                        type: "primary",
                      },
                      on: { click: _vm.handleSkuPriceControlExportNotice },
                    },
                    [_vm._v("批量导出")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "tableRef",
          attrs: {
            data: _vm.pageData.rows,
            height: "calc(100vh - 250px)",
            size: "mini",
            border: "",
            "highlight-current-row": "",
            "header-cell-style": {
              background: "rgb(235 235 235)",
              color: "black",
              textAlign: "center",
              verticalAlign: "middle",
            },
          },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _c("el-table-column", {
            attrs: { label: "平台", prop: "ptName", "min-width": "100" },
          }),
          _c("el-table-column", {
            attrs: { label: "型号", prop: "skuModel", "min-width": "100" },
          }),
          _c("el-table-column", {
            attrs: {
              label: "售卖规格单位",
              prop: "saleUnit",
              "min-width": "100",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "售卖规格单位是否最小单位",
              prop: "ifMinUnit",
              "min-width": "120",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "售卖规格下限",
              prop: "saleUnitMin",
              "min-width": "100",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "售卖规格上限",
              prop: "saleUnitMax",
              "min-width": "100",
            },
          }),
          _c("el-table-column", {
            attrs: { label: "售卖单价", prop: "unitPrice", "min-width": "100" },
          }),
          _c("el-table-column", {
            attrs: {
              label: "售卖总价",
              prop: "totalPrice",
              "min-width": "100",
            },
          }),
          _c("el-table-column", {
            attrs: { label: "赠品", prop: "gift", "min-width": "100" },
          }),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              "min-width": "120",
              align: "center",
              "class-name": "small-padding fixed-width",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          size: "mini",
                          type: "text",
                          icon: "el-icon-edit",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.handleSkuPriceControlEdit(scope.row)
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          size: "mini",
                          type: "text",
                          icon: "el-icon-delete",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.handleDeleteSkuPriceControl(scope.row)
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("Pagination", {
        attrs: {
          total: _vm.pageData.total,
          page: _vm.queryForm.page,
          limit: _vm.queryForm.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.queryForm, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.queryForm, "limit", $event)
          },
          pagination: _vm.handleQuery,
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "单品控价表导入",
            visible: _vm.skuPriceControlImportDialog.visible,
            "append-to-body": "",
            width: "400px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(
                _vm.skuPriceControlImportDialog,
                "visible",
                $event
              )
            },
          },
        },
        [
          _c(
            "el-upload",
            {
              ref: "skuPriceControlUpload",
              staticClass: "upload-demo",
              attrs: {
                drag: "",
                limit: 1,
                action: "#",
                "auto-upload": false,
                "file-list": _vm.skuPriceControlImportDialog.fileList,
                "on-change": (file, fileList) =>
                  _vm.handleSkuPriceControlFileChange(file, fileList, "change"),
                "on-remove": (file, fileList) =>
                  _vm.handleSkuPriceControlFileChange(file, fileList, "remove"),
              },
            },
            [
              _c("i", { staticClass: "el-icon-upload" }),
              _c("div", { staticClass: "el-upload__text" }, [
                _vm._v("将文件拖到此处，或"),
                _c("em", [_vm._v("点击上传")]),
              ]),
              _c(
                "div",
                {
                  staticClass: "el-upload__tip",
                  attrs: { slot: "tip" },
                  slot: "tip",
                },
                [
                  _c("span", [_vm._v("仅允许导入xls、xlsx格式文件。")]),
                  _c(
                    "el-link",
                    {
                      staticStyle: {
                        "font-size": "12px",
                        "vertical-align": "baseline",
                      },
                      attrs: {
                        type: "primary",
                        underline: false,
                        target: "_blank",
                        href: _vm.skuPriceControlImportDialog.template,
                      },
                    },
                    [_vm._v("下载模板")]
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "mini",
                    disabled: _vm.skuPriceControlSubmitDisabled,
                  },
                  on: { click: _vm.submitSkuPriceControlImport },
                },
                [_vm._v("确定")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.skuPriceControlImportDialog.visible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.skuPriceControlAddDialog.title,
            visible: _vm.skuPriceControlAddDialog.visible,
            width: "600px",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.skuPriceControlAddDialog, "visible", $event)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "spuInfoUpdate",
              attrs: {
                size: "mini",
                "label-width": "100px",
                model: _vm.skuPriceControlAddDialog.form,
                rules: _vm.skuPriceControlAddDialog.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "平台", prop: "ptName" } },
                [
                  _c("DictSelect", {
                    ref: "platform",
                    attrs: {
                      type: "platform",
                      value: _vm.skuPriceControlAddDialog.form.ptName,
                      dictFun: _vm.dictPlatformFun,
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(
                          _vm.skuPriceControlAddDialog.form,
                          "ptName",
                          $event
                        )
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "型号", prop: "skuModel" } },
                [
                  _c("DictSelect", {
                    ref: "skuModel",
                    attrs: {
                      type: "sku_model",
                      value: _vm.skuPriceControlAddDialog.form.skuModel,
                      dictFun: _vm.dictSkuModelFun,
                      "init-props": { label: "skuModel", value: "skuModel" },
                      remote: "",
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(
                          _vm.skuPriceControlAddDialog.form,
                          "skuModel",
                          $event
                        )
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "售卖规格单位", prop: "saleUnit" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入售卖规格单位" },
                    model: {
                      value: _vm.skuPriceControlAddDialog.form.saleUnit,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.skuPriceControlAddDialog.form,
                          "saleUnit",
                          $$v
                        )
                      },
                      expression: "skuPriceControlAddDialog.form.saleUnit",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "售卖规格单位是否最小单位",
                    "label-width": "180px",
                    prop: "ifMinUnit",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入售卖规格单位是否最小单位" },
                    model: {
                      value: _vm.skuPriceControlAddDialog.form.ifMinUnit,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.skuPriceControlAddDialog.form,
                          "ifMinUnit",
                          $$v
                        )
                      },
                      expression: "skuPriceControlAddDialog.form.ifMinUnit",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "售卖规格下限", prop: "saleUnitMin" } },
                [
                  _c("el-input-number", {
                    attrs: { clearable: "", placeholder: "请输入售卖规格下限" },
                    model: {
                      value: _vm.skuPriceControlAddDialog.form.saleUnitMin,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.skuPriceControlAddDialog.form,
                          "saleUnitMin",
                          $$v
                        )
                      },
                      expression: "skuPriceControlAddDialog.form.saleUnitMin",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "售卖规格上限", prop: "saleUnitMax" } },
                [
                  _c("el-input-number", {
                    attrs: { clearable: "", placeholder: "请输入售卖规格上限" },
                    model: {
                      value: _vm.skuPriceControlAddDialog.form.saleUnitMax,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.skuPriceControlAddDialog.form,
                          "saleUnitMax",
                          $$v
                        )
                      },
                      expression: "skuPriceControlAddDialog.form.saleUnitMax",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "售卖单价", prop: "unitPrice" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "请输入售卖单价" },
                    model: {
                      value: _vm.skuPriceControlAddDialog.form.unitPrice,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.skuPriceControlAddDialog.form,
                          "unitPrice",
                          $$v
                        )
                      },
                      expression: "skuPriceControlAddDialog.form.unitPrice",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "售卖总价", prop: "totalPrice" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "请输入售卖总价" },
                    model: {
                      value: _vm.skuPriceControlAddDialog.form.totalPrice,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.skuPriceControlAddDialog.form,
                          "totalPrice",
                          $$v
                        )
                      },
                      expression: "skuPriceControlAddDialog.form.totalPrice",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "赠品" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入赠品" },
                    model: {
                      value: _vm.skuPriceControlAddDialog.form.gift,
                      callback: function ($$v) {
                        _vm.$set(_vm.skuPriceControlAddDialog.form, "gift", $$v)
                      },
                      expression: "skuPriceControlAddDialog.form.gift",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.skuPriceControlAddDialog.visible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: { click: _vm.submitAddSkuPriceControlForm },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.skuPriceControlUpdateDialog.title,
            visible: _vm.skuPriceControlUpdateDialog.visible,
            width: "600px",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(
                _vm.skuPriceControlUpdateDialog,
                "visible",
                $event
              )
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "spuInfoUpdate",
              attrs: {
                size: "mini",
                "label-width": "100px",
                model: _vm.skuPriceControlUpdateDialog.form,
                rules: _vm.skuPriceControlUpdateDialog.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "平台", prop: "ptName" } },
                [
                  _c("DictSelect", {
                    ref: "platform",
                    attrs: {
                      disabled: "true",
                      type: "platform",
                      value: _vm.skuPriceControlUpdateDialog.form.ptName,
                      dictFun: _vm.dictPlatformFun,
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(
                          _vm.skuPriceControlUpdateDialog.form,
                          "ptName",
                          $event
                        )
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "型号", prop: "skuModel" } },
                [
                  _c("DictSelect", {
                    ref: "skuModel",
                    attrs: {
                      disabled: "true",
                      type: "sku_model",
                      value: _vm.skuPriceControlUpdateDialog.form.skuModel,
                      dictFun: _vm.dictSkuModelFun,
                      "init-props": { label: "skuModel", value: "skuModel" },
                      remote: "",
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(
                          _vm.skuPriceControlUpdateDialog.form,
                          "skuModel",
                          $event
                        )
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "售卖规格单位", prop: "saleUnit" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入售卖规格单位" },
                    model: {
                      value: _vm.skuPriceControlUpdateDialog.form.saleUnit,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.skuPriceControlUpdateDialog.form,
                          "saleUnit",
                          $$v
                        )
                      },
                      expression: "skuPriceControlUpdateDialog.form.saleUnit",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "售卖规格单位是否最小单位",
                    "label-width": "180px",
                    prop: "ifMinUnit",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入售卖规格单位是否最小单位" },
                    model: {
                      value: _vm.skuPriceControlUpdateDialog.form.ifMinUnit,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.skuPriceControlUpdateDialog.form,
                          "ifMinUnit",
                          $$v
                        )
                      },
                      expression: "skuPriceControlUpdateDialog.form.ifMinUnit",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "售卖规格下限", prop: "saleUnitMin" } },
                [
                  _c("el-input-number", {
                    attrs: { clearable: "", placeholder: "请输入售卖规格下限" },
                    model: {
                      value: _vm.skuPriceControlUpdateDialog.form.saleUnitMin,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.skuPriceControlUpdateDialog.form,
                          "saleUnitMin",
                          $$v
                        )
                      },
                      expression:
                        "skuPriceControlUpdateDialog.form.saleUnitMin",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "售卖规格上限", prop: "saleUnitMax" } },
                [
                  _c("el-input-number", {
                    attrs: { clearable: "", placeholder: "请输入售卖规格上限" },
                    model: {
                      value: _vm.skuPriceControlUpdateDialog.form.saleUnitMax,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.skuPriceControlUpdateDialog.form,
                          "saleUnitMax",
                          $$v
                        )
                      },
                      expression:
                        "skuPriceControlUpdateDialog.form.saleUnitMax",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "售卖单价", prop: "unitPrice" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "请输入售卖单价" },
                    model: {
                      value: _vm.skuPriceControlUpdateDialog.form.unitPrice,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.skuPriceControlUpdateDialog.form,
                          "unitPrice",
                          $$v
                        )
                      },
                      expression: "skuPriceControlUpdateDialog.form.unitPrice",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "售卖总价", prop: "totalPrice" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "请输入售卖总价" },
                    model: {
                      value: _vm.skuPriceControlUpdateDialog.form.totalPrice,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.skuPriceControlUpdateDialog.form,
                          "totalPrice",
                          $$v
                        )
                      },
                      expression: "skuPriceControlUpdateDialog.form.totalPrice",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "赠品" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入赠品" },
                    model: {
                      value: _vm.skuPriceControlUpdateDialog.form.gift,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.skuPriceControlUpdateDialog.form,
                          "gift",
                          $$v
                        )
                      },
                      expression: "skuPriceControlUpdateDialog.form.gift",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.skuPriceControlUpdateDialog.visible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: { click: _vm.submitSkuPriceControlEdit },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }