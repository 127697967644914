var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "block", staticStyle: { "padding-top": "2%" } },
      [
        _c(
          "label",
          {
            staticClass: "demonstration",
            staticStyle: { "font-size": "15px", "padding-right": "1%" },
          },
          [_vm._v("创建时间")]
        ),
        _c("el-date-picker", {
          attrs: {
            type: "daterange",
            align: "right",
            "unlink-panels": "",
            clearable: "",
            size: "mini",
            "range-separator": "至",
            "start-placeholder": "开始日期",
            "end-placeholder": "结束日期",
            "picker-options": _vm.pickerOptions,
            format: "yyyy-MM-dd",
            "value-format": "yyyy-MM-dd",
          },
          on: { change: _vm.getDateRange },
          model: {
            value: _vm.dateRangeValue,
            callback: function ($$v) {
              _vm.dateRangeValue = $$v
            },
            expression: "dateRangeValue",
          },
        }),
        _c(
          "label",
          {
            staticStyle: { "font-size": "15px", "padding-left": "2%" },
            attrs: { for: "qd-select" },
          },
          [_vm._v("渠道：")]
        ),
        _c(
          "el-radio-group",
          {
            staticStyle: { "font-size": "15px", "padding-right": "1%" },
            attrs: { size: "mini" },
            on: { input: _vm.handleRadioGroupInput },
            model: {
              value: _vm.radioGroup,
              callback: function ($$v) {
                _vm.radioGroup = $$v
              },
              expression: "radioGroup",
            },
          },
          [
            _c("el-radio-button", { attrs: { label: "全部" } }),
            _c("el-radio-button", { attrs: { label: "短信" } }),
            _c("el-radio-button", { attrs: { label: "企微" } }),
            _c("el-radio-button", { attrs: { label: "crm" } }),
            _c("el-radio-button", { attrs: { label: "crm(企微)" } }),
          ],
          1
        ),
        _c(
          "label",
          {
            staticStyle: {
              "font-size": "15px",
              "padding-right": "1%",
              "padding-left": "1%",
            },
          },
          [_vm._v("选择分组")]
        ),
        _c(
          "el-select",
          {
            staticStyle: {
              "font-size": "15px",
              "padding-right": "1%",
              width: "26%",
            },
            attrs: {
              clearable: "",
              filterable: "",
              placeholder: "请选择",
              id: "fz-select",
              size: "mini",
            },
            on: { change: _vm.handleSelectChange },
            model: {
              value: _vm.radio2,
              callback: function ($$v) {
                _vm.radio2 = $$v
              },
              expression: "radio2",
            },
          },
          _vm._l(_vm.GroupDataListCD, function (item) {
            return _c("el-option", {
              key: item.name,
              attrs: { label: item.name, value: item.name },
            })
          }),
          1
        ),
        _c(
          "el-button",
          {
            staticStyle: { "font-size": "15px" },
            attrs: { type: "primary", size: "mini" },
            on: { click: _vm.getConsumeStatistics },
          },
          [_vm._v("查询 ")]
        ),
        _c(
          "i",
          { staticStyle: { "font-size": "12px", "padding-left": "1%" } },
          [_vm._v("仅支持查询2023/5/1之后创建的分组")]
        ),
      ],
      1
    ),
    _c("div", [
      _vm.qwruleGroupData != null && _vm.dxruleGroupData == null
        ? _c("div", { staticClass: "title-cl" }, [
            _vm._v(
              "分组更新时间 " +
                _vm._s(_vm.qwruleGroupData.ruleGroupUpdateTime) +
                " "
            ),
          ])
        : _vm._e(),
      _vm.qwruleGroupData == null && _vm.dxruleGroupData != null
        ? _c("div", { staticClass: "title-cl" }, [
            _vm._v(
              "分组更新时间 " +
                _vm._s(_vm.dxruleGroupData.ruleGroupUpdateTime) +
                " "
            ),
          ])
        : _vm._e(),
      _vm.qwruleGroupData != null && _vm.dxruleGroupData != null
        ? _c("div", { staticClass: "title-cl" }, [
            _vm._v(
              "分组更新时间 " +
                _vm._s(_vm.qwruleGroupData.ruleGroupUpdateTime) +
                " "
            ),
          ])
        : _vm._e(),
      _vm.qwruleGroupData != null
        ? _c("div", [
            _c("span", { staticClass: "title-cl" }, [_vm._v("企微触达比例")]),
            _c("span", { staticClass: "title-cl" }, [
              _vm._v(_vm._s(_vm.qwruleGroupData.reachRatio)),
            ]),
            _c("span", { staticClass: "title-cl" }, [_vm._v("企微创建时间")]),
            _c("span", { staticClass: "title-cl" }, [
              _vm._v(_vm._s(_vm.qwruleGroupData.reachTime)),
            ]),
            _vm.qwruleGroupData.qwMsgConfigFlag == 3
              ? _c("span", { staticClass: "title-cl" }, [
                  _c(
                    "a",
                    {
                      staticStyle: { color: "#66b1ff" },
                      attrs: {
                        href: "https://t-datart.bi.mgmos.com.cn/organizations/ad7804d33ca14d128a38975c9f7c88b3/vizs/8a953495625d49959361cecd89c08088",
                        target: "_blank",
                      },
                    },
                    [_vm._v("前往配置企微消息")]
                  ),
                  _vm._v(",匹配成功后第二天数据可看"),
                ])
              : _vm._e(),
            _vm.qwruleGroupData.qwMsgConfigFlag == 2
              ? _c("span", { staticClass: "title-cl" }, [
                  _vm._v("当前企微消息匹配失败,"),
                  _c(
                    "a",
                    {
                      staticStyle: { color: "#66b1ff" },
                      attrs: {
                        href: "https://t-datart.bi.mgmos.com.cn/organizations/ad7804d33ca14d128a38975c9f7c88b3/vizs/8a953495625d49959361cecd89c08088",
                        target: "_blank",
                      },
                    },
                    [_vm._v("前往配置企微消息")]
                  ),
                  _vm._v(",匹配成功后第二天数据可看"),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
      _vm.dxruleGroupData != null
        ? _c("div", [
            _c("span", { staticClass: "title-cl" }, [_vm._v("短信触达比例")]),
            _c("span", { staticClass: "title-cl" }, [
              _vm._v(_vm._s(_vm.dxruleGroupData.reachRatio)),
            ]),
            _c("span", { staticClass: "title-cl" }, [_vm._v("短信创建时间")]),
            _c("span", { staticClass: "title-cl" }, [
              _vm._v(
                _vm._s(
                  _vm.dxruleGroupData.reachTime == ""
                    ? "未找到短信发送记录"
                    : _vm.dxruleGroupData.reachTime
                )
              ),
            ]),
          ])
        : _vm._e(),
    ]),
    _vm._m(0),
    _c(
      "div",
      { staticStyle: { display: "flex", "align-items": "center" } },
      [
        _c(
          "label",
          { staticStyle: { "font-size": "15px" }, attrs: { for: "qd-select" } },
          [_vm._v("渠道：")]
        ),
        _c(
          "el-radio-group",
          {
            staticStyle: { "font-size": "15px", "padding-right": "1%" },
            attrs: { id: "qd-select", size: "mini" },
            on: { input: _vm.handleRadio1Input },
            model: {
              value: _vm.radio1,
              callback: function ($$v) {
                _vm.radio1 = $$v
              },
              expression: "radio1",
            },
          },
          [
            _vm.radio1 === "全部" || _vm.radioTR === 1
              ? _c("el-radio-button", { attrs: { label: "全部" } })
              : _vm._e(),
            _vm.radio1 === "短信" || _vm.radioTR === 1
              ? _c("el-radio-button", { attrs: { label: "短信" } })
              : _vm._e(),
            _vm.radio1 === "企微" || _vm.radioTR === 1
              ? _c("el-radio-button", { attrs: { label: "企微" } })
              : _vm._e(),
            _vm.radio1 === "crm" || _vm.radioTR === 1
              ? _c("el-radio-button", { attrs: { label: "crm" } })
              : _vm._e(),
            _vm.radio1 === "crm(企微)" || _vm.radioTR === 1
              ? _c("el-radio-button", { attrs: { label: "crm(企微)" } })
              : _vm._e(),
          ],
          1
        ),
        _c("label", { staticStyle: { "font-size": "15px" } }, [
          _vm._v("消费跟踪周期："),
        ]),
        _c(
          "el-radio-group",
          {
            attrs: { id: "xfzq-select" },
            on: { input: _vm.handleRadioDInput },
            model: {
              value: _vm.radioD,
              callback: function ($$v) {
                _vm.radioD = $$v
              },
              expression: "radioD",
            },
          },
          [
            _c("el-radio", { attrs: { label: 0 } }, [_vm._v("当天")]),
            _c("el-radio", { attrs: { label: 3 } }, [_vm._v("3天")]),
            _c("el-radio", { attrs: { label: 7 } }, [_vm._v("7天")]),
            _c("el-radio", { attrs: { label: 30 } }, [_vm._v("30天")]),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "rea_" },
      [
        _c(
          "el-row",
          { attrs: { gutter: 60 } },
          [
            _c("el-col", { attrs: { span: 24, sm: 12 } }, [
              _c("div", { staticClass: "chart-container" }, [
                _c("div", { staticClass: "progress-container" }, [
                  _c("div", {
                    staticClass: "progress-bar",
                    style: { width: _vm.num + "%" },
                  }),
                  _c("div", { staticClass: "progress-text" }, [
                    _c("div", { staticClass: "progress-value" }, [
                      _vm._v("需触达人数（所选分组人数）"),
                    ]),
                    _c("div", { staticClass: "progress-total" }, [
                      _vm._v(_vm._s(_vm.xcxDict.planReachNum)),
                    ]),
                  ]),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "progress-summary",
                    staticStyle: { "text-align": "right" },
                  },
                  [_vm._v(" " + _vm._s(_vm.xcxDict.actualReachPct))]
                ),
                _c("div", { staticClass: "progress-container" }, [
                  _c("div", {
                    staticClass: "progress-bar2",
                    style: { width: _vm.xcxDict.actualReachPct },
                  }),
                  _c("div", { staticClass: "progress-text" }, [
                    _c("div", { staticClass: "progress-value" }, [
                      _vm._v(_vm._s(_vm.radioDText) + "实际触达人数"),
                    ]),
                    _c("div", { staticClass: "progress-total" }, [
                      _vm._v(_vm._s(_vm.xcxDict.actualReachNum)),
                    ]),
                  ]),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "progress-summary",
                    staticStyle: { "text-align": "right" },
                  },
                  [_vm._v(_vm._s(_vm.xcxDict.xsJyrsPct))]
                ),
                _c("div", { staticClass: "progress-container" }, [
                  _c("div", {
                    staticClass: "progress-bar4",
                    style: {
                      width:
                        (parseFloat(_vm.xcxDict.xsJyrsPct) / 100) *
                          parseFloat(_vm.xcxDict.actualReachPct) +
                        "%",
                    },
                  }),
                  _c("div", { staticClass: "progress-text" }, [
                    _c("div", { staticClass: "progress-value" }, [
                      _vm._v("小程序消费人数"),
                    ]),
                    _c("div", { staticClass: "progress-total" }, [
                      _vm._v(_vm._s(_vm.xcxDict.xsJyrs)),
                    ]),
                  ]),
                ]),
              ]),
            ]),
            _c("el-col", { attrs: { span: 24, sm: 12 } }, [
              _c("div", { staticClass: "chart-container" }, [
                _c("div", { staticClass: "progress-container" }, [
                  _c("div", {
                    staticClass: "progress-bar",
                    style: { width: _vm.num + "%" },
                  }),
                  _c("div", { staticClass: "progress-text" }, [
                    _c("div", { staticClass: "progress-value" }, [
                      _vm._v("需触达人数（所选分组人数）"),
                    ]),
                    _c("div", { staticClass: "progress-total" }, [
                      _vm._v(_vm._s(_vm.xcxDict.planReachNum)),
                    ]),
                  ]),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "progress-summary",
                    staticStyle: { "text-align": "right" },
                  },
                  [_vm._v(" " + _vm._s(_vm.xcxDict.actualReachPct))]
                ),
                _c("div", { staticClass: "progress-container" }, [
                  _c("div", {
                    staticClass: "progress-bar2",
                    style: { width: _vm.xcxDict.actualReachPct },
                  }),
                  _c("div", { staticClass: "progress-text" }, [
                    _c("div", { staticClass: "progress-value" }, [
                      _vm._v(_vm._s(_vm.radioDText) + "实际触达人数"),
                    ]),
                    _c("div", { staticClass: "progress-total" }, [
                      _vm._v(_vm._s(_vm.xcxDict.actualReachNum)),
                    ]),
                  ]),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "progress-summary",
                    staticStyle: { "text-align": "right" },
                  },
                  [_vm._v(_vm._s(_vm.xcxDict.xxJyrsPct))]
                ),
                _c("div", { staticClass: "progress-container" }, [
                  _c("div", {
                    staticClass: "progress-bar4",
                    style: {
                      width:
                        (parseFloat(_vm.xcxDict.xxJyrsPct) / 100) *
                          parseFloat(_vm.xcxDict.actualReachPct) +
                        "%",
                    },
                  }),
                  _c("div", { staticClass: "progress-text" }, [
                    _c("div", { staticClass: "progress-value" }, [
                      _vm._v("到店消费人数"),
                    ]),
                    _c("div", { staticClass: "progress-total" }, [
                      _vm._v(_vm._s(_vm.xcxDict.xxJyrs)),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "h2",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.channel == "企微" || _vm.channel === "crm(企微)",
            expression: "channel=='企微' || channel==='crm(企微)'",
          },
        ],
      },
      [
        _c("i", { staticClass: "el-icon-s-opportunity" }),
        _vm._v(" 门店触达任务统计"),
      ]
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.channel == "企微",
            expression: "channel=='企微'",
          },
        ],
        staticClass: "rea_",
      },
      [
        _c("div", { staticClass: "top-title", staticStyle: { width: "81%" } }, [
          _c(
            "div",
            { staticClass: "title-right" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", icon: "el-icon-download" },
                  on: {
                    click:
                      _vm.handleDownloadReachConsumeStoreTaskStatisticsExport,
                  },
                },
                [_vm._v(" 下载明细清单 ")]
              ),
            ],
            1
          ),
        ]),
        _c(
          "el-table",
          {
            staticStyle: { width: "80%" },
            attrs: { data: _vm.storeTaskDataQW, border: "" },
          },
          [
            _c("el-table-column", {
              attrs: {
                prop: "storeCode",
                label: "门店编码",
                width: "180",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "storeName",
                label: "门店名称",
                width: "180",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "dqName",
                label: "大区名称",
                width: "180",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: { prop: "xqName", label: "小区名称", align: "center" },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "taskStaffNum",
                label: "有任务的店员数",
                width: "180",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "taskDoneNum",
                label: "完成任务店员数",
                width: "180",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "taskReachNum",
                label: "任务涉及会员数",
                width: "180",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "actualReachNum",
                label: "已触达会员数",
                width: "180",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "reachExecutionRate",
                label: "触达执行率",
                width: "180",
                align: "center",
              },
            }),
          ],
          1
        ),
        _c("el-pagination", {
          staticStyle: { "padding-top": "2%", "padding-bottom": "3%" },
          attrs: {
            background: "",
            "current-page": _vm.storeTaskpageQW,
            "page-size": _vm.smsFailpageSizeQW,
            total: _vm.storeTasktotalQW,
            layout: "prev, pager, next",
          },
          on: { "current-change": _vm.storeTaskChangeQW },
        }),
      ],
      1
    ),
    _vm._m(1),
    _c(
      "div",
      { staticStyle: { display: "flex", "align-items": "center" } },
      [
        _c("label", { staticStyle: { "font-size": "15px" } }, [
          _vm._v("渠道："),
        ]),
        _c(
          "el-radio-group",
          {
            staticStyle: { "font-size": "15px" },
            attrs: { id: "wqd-select", size: "mini" },
            on: { input: _vm.handleRadio3Input },
            model: {
              value: _vm.radio3,
              callback: function ($$v) {
                _vm.radio3 = $$v
              },
              expression: "radio3",
            },
          },
          [
            _vm.radio3 === "短信" || _vm.radioTR === 1
              ? _c("el-radio-button", { attrs: { label: "短信" } })
              : _vm._e(),
            _vm.radio3 === "企微" || _vm.radioTR === 1
              ? _c("el-radio-button", { attrs: { label: "企微" } })
              : _vm._e(),
            _vm.radio3 === "crm" || _vm.radioTR === 1
              ? _c("el-radio-button", { attrs: { label: "crm" } })
              : _vm._e(),
            _vm.radio3 === "crm(企微)" || _vm.radioTR === 1
              ? _c("el-radio-button", { attrs: { label: "crm(企微)" } })
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.radio3 === "短信" || _vm.radio3 === "crm",
            expression: "radio3 === '短信' || radio3 === 'crm'",
          },
        ],
        staticClass: "rea_",
      },
      [
        _c("div", { staticClass: "top-title" }, [
          _c(
            "div",
            { staticClass: "title-right" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", icon: "el-icon-download" },
                  on: { click: _vm.handleDownloadMessageDetail },
                },
                [_vm._v("下载明细清单")]
              ),
            ],
            1
          ),
        ]),
        _c(
          "div",
          [
            _c(
              "label",
              { staticStyle: { "padding-left": "1%", "font-size": "15px" } },
              [
                _vm._v(
                  "失败总人数：" + _vm._s(_vm.WcdTotalFailNumData.totalFailNum)
                ),
              ]
            ),
            _c(
              "label",
              { staticStyle: { "padding-left": "5%", "font-size": "15px" } },
              [
                _vm._v(
                  "占触达分组占比：" +
                    _vm._s(_vm.WcdTotalFailNumData.reachFailNumPct)
                ),
              ]
            ),
            _c(
              "el-table",
              { attrs: { data: _vm.serverData, border: "" } },
              [
                _c("el-table-column", {
                  attrs: {
                    prop: "reachErrorMsg",
                    label: "短信发送失败原因",
                    width: "360",
                    align: "center",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "reachFailNum",
                    label: "人数",
                    width: "180",
                    align: "center",
                  },
                }),
                _c("el-table-column", {
                  attrs: { label: "人数占比", align: "center" },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          _c(
                            "div",
                            { staticClass: "progress-container-table" },
                            [
                              _c("div", {
                                staticClass: "progress-bar3",
                                style: {
                                  width: parseFloat(row.reachFailNumPct) + "%",
                                },
                              }),
                              _c("div", { staticClass: "progress-text" }, [
                                _c("div", { staticClass: "progress-total" }, [
                                  _vm._v(
                                    _vm._s(parseFloat(row.reachFailNumPct)) +
                                      "%"
                                  ),
                                ]),
                              ]),
                            ]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.radio3 === "企微" || _vm.radio3 === "crm(企微)",
            expression: "radio3 === '企微' || radio3 === 'crm(企微)'",
          },
        ],
        staticClass: "rea_",
      },
      [
        _c("div", { staticClass: "top-title" }, [
          _c(
            "div",
            { staticClass: "title-right" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", icon: "el-icon-download" },
                  on: { click: _vm.handleDownloadQwFailStore },
                },
                [_vm._v("下载统计结果")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", icon: "el-icon-download" },
                  on: { click: _vm.handleDownloadQwFail },
                },
                [_vm._v("下载明细清单")]
              ),
            ],
            1
          ),
        ]),
        _c(
          "div",
          [
            _c(
              "label",
              { staticStyle: { "padding-left": "1%", "font-size": "15px" } },
              [
                _vm._v(
                  "失败总人数：" + _vm._s(_vm.WcdTotalFailNumData.totalFailNum)
                ),
              ]
            ),
            _c(
              "label",
              { staticStyle: { "padding-left": "5%", "font-size": "15px" } },
              [
                _vm._v(
                  "占触达分组占比：" +
                    _vm._s(_vm.WcdTotalFailNumData.reachFailNumPct)
                ),
              ]
            ),
            _c(
              "el-table",
              {
                staticStyle: { width: "80%" },
                attrs: { data: _vm.serverDataQW, border: "" },
              },
              [
                _c("el-table-column", {
                  attrs: {
                    prop: "storeCode",
                    label: "门店ID",
                    width: "180",
                    align: "center",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "storeName",
                    label: "门店名称",
                    width: "180",
                    align: "center",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "reachFailNum",
                    label: "人数",
                    width: "180",
                    align: "center",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "reachFailNumPct",
                    label: "人数占比",
                    align: "center",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "reachFailAttributionNum",
                    label: "门店归属人数",
                    width: "180",
                    align: "center",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "reachFailAttributionNumPct",
                    label: "门店归属人数占比",
                    width: "180",
                    align: "center",
                  },
                }),
              ],
              1
            ),
            _c("el-pagination", {
              staticStyle: { "padding-top": "2%" },
              attrs: {
                background: "",
                "current-page": _vm.smsFailpageQW,
                "page-size": _vm.smsFailpageSizeQW,
                total: _vm.smsFailtotalQW,
                layout: "prev, pager, next",
              },
              on: { "current-change": _vm.smsFailHandlePageChangeQW },
            }),
          ],
          1
        ),
      ]
    ),
    _vm._m(2),
    _c(
      "div",
      {
        staticStyle: {
          display: "flex",
          "align-items": "center",
          "padding-bottom": "0%",
        },
      },
      [
        _c("label", { staticClass: "child-header-check" }, [
          _vm._v("对比维度："),
        ]),
        _c(
          "el-radio-group",
          {
            attrs: { size: "medium" },
            on: { input: _vm.handleRadioCheckInput },
            model: {
              value: _vm.radioCheck,
              callback: function ($$v) {
                _vm.radioCheck = $$v
              },
              expression: "radioCheck",
            },
          },
          [
            _c("el-radio-button", { attrs: { label: "时间" } }),
            _c("el-radio-button", { attrs: { label: "分组" } }),
          ],
          1
        ),
        _c(
          "label",
          {
            staticClass: "child-header-check",
            staticStyle: { "padding-left": "5%" },
          },
          [_vm._v("消费渠道：")]
        ),
        _c(
          "el-radio-group",
          {
            attrs: { size: "medium" },
            on: { input: _vm.handleRadioExpendInput },
            model: {
              value: _vm.radioExpend,
              callback: function ($$v) {
                _vm.radioExpend = $$v
              },
              expression: "radioExpend",
            },
          },
          [
            _c("el-radio-button", { attrs: { label: "ALL" } }, [
              _vm._v("全部"),
            ]),
            _c("el-radio-button", { attrs: { label: "1" } }, [_vm._v("门店")]),
            _c("el-radio-button", { attrs: { label: "2" } }, [
              _vm._v("小程序"),
            ]),
          ],
          1
        ),
      ],
      1
    ),
    _c("div", { staticClass: "rea_" }, [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.radioCheck === "时间",
              expression: "radioCheck === '时间'",
            },
          ],
          staticClass: "header",
        },
        [
          _c(
            "label",
            { staticClass: "child-header-one", attrs: { for: "qd-select" } },
            [_vm._v("渠道：")]
          ),
          _c(
            "el-radio-group",
            {
              staticStyle: { "font-size": "15px", "padding-right": "1%" },
              attrs: { id: "qd-selectcdrq", size: "mini" },
              on: { input: _vm.handleRadio5Input },
              model: {
                value: _vm.radio5,
                callback: function ($$v) {
                  _vm.radio5 = $$v
                },
                expression: "radio5",
              },
            },
            [
              _vm.radio5 === "短信" || _vm.radioTR === 1
                ? _c("el-radio-button", { attrs: { label: "短信" } })
                : _vm._e(),
              _vm.radio5 === "企微" || _vm.radioTR === 1
                ? _c("el-radio-button", { attrs: { label: "企微" } })
                : _vm._e(),
              _vm.radio5 === "crm" || _vm.radioTR === 1
                ? _c("el-radio-button", { attrs: { label: "crm" } })
                : _vm._e(),
              _vm.radio5 === "crm(企微)" || _vm.radioTR === 1
                ? _c("el-radio-button", { attrs: { label: "crm(企微)" } })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-radio-group",
            {
              attrs: { id: "xfzq-selectxcdffxrq" },
              on: { input: _vm.handleToDaymodelTiInput },
              model: {
                value: _vm.toDaymodelTi,
                callback: function ($$v) {
                  _vm.toDaymodelTi = $$v
                },
                expression: "toDaymodelTi",
              },
            },
            [
              _c("el-radio", { attrs: { label: 0 } }, [_vm._v("时间-到天")]),
              _c("el-radio", { attrs: { label: 1 } }, [_vm._v("时间-聚合")]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.toDaymodelTi === 0 && _vm.radioCheck === "时间",
              expression: "toDaymodelTi === 0 && radioCheck === '时间'",
            },
          ],
        },
        [
          _c(
            "div",
            { staticClass: "top-title", staticStyle: { width: "81%" } },
            [
              _c(
                "div",
                { staticClass: "title-right" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", icon: "el-icon-download" },
                      on: {
                        click: _vm.handleDownloadConsumeTodayStatisticsExport,
                      },
                    },
                    [_vm._v(" 下载明细清单 ")]
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "el-table",
            {
              staticStyle: { width: "80%" },
              attrs: { data: _vm.toDayStatisticsDataList, border: "" },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "target",
                  label: "指标",
                  align: "center",
                  fixed: "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "reachQ7d",
                  label: "触达前7天",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "reachQ6d",
                  label: "触达前6天",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "reachQ5d",
                  label: "触达前5天",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "reachQ4d",
                  label: "触达前4天",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "reachQ3d",
                  label: "触达前3天",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "reachQ2d",
                  label: "触达前2天",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "reachQ1d",
                  label: "触达前1天",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "reachToday",
                  label: "触达当天",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "reachH1d",
                  label: "触达后1天",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "reachH2d",
                  label: "触达后2天",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "reachH3d",
                  label: "触达后3天",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "reachH4d",
                  label: "触达后4天",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "reachH5d",
                  label: "触达后5天",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "reachH6d",
                  label: "触达后6天",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "reachH7d",
                  label: "触达后7天",
                  align: "center",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.toDaymodelTi === 1 && _vm.radioCheck === "时间",
              expression: "toDaymodelTi === 1 && radioCheck === '时间'",
            },
          ],
        },
        [
          _c(
            "div",
            [
              _c(
                "div",
                { staticClass: "top-title", staticStyle: { width: "81%" } },
                [
                  _c(
                    "div",
                    { staticClass: "title-right" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", icon: "el-icon-download" },
                          on: {
                            click:
                              _vm.handleDownloadConsumePolyStatisticsExport,
                          },
                        },
                        [_vm._v(" 下载明细清单 ")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "el-table",
                {
                  staticStyle: { width: "80%" },
                  attrs: { data: _vm.polyStatisticsDataList, border: "" },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "target",
                      label: "指标",
                      align: "center",
                      fixed: "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "reachRs",
                      label: "实际触达人数",
                      align: "center",
                      fixed: "",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "orderQty",
                      label: "订单数",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "sellAmount",
                      label: "销售额（含卡/券）",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "customerUnitPrice",
                      label: "客单价（销售额（含卡/券）/订单数）",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "jyrs", label: "消费人数", align: "center" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "xfRate",
                      label: "消费率（消费人数/实际触达人数）",
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(" " + _vm._s(scope.row.xfRate + "%") + " "),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "xbkBkRs",
                      label: "办卡人数",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "xbkKkRs",
                      label: "开卡人数",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "xbkXkRs",
                      label: "续卡人数",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "bkRate",
                      label: "办卡率（办卡人数/消费人数）",
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(" " + _vm._s(scope.row.bkRate + "%") + " "),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "yqRate",
                      label: "用券率（用券人数/消费人数）",
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(" " + _vm._s(scope.row.yqRate + "%") + " "),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "header" }, [
            _c("div", { staticClass: "store-polycontainer" }, [
              _c(
                "div",
                { staticClass: "store-polytable-wrapper" },
                [
                  _c(
                    "div",
                    { staticClass: "top-title", staticStyle: { width: "81%" } },
                    [
                      _c(
                        "div",
                        { staticClass: "title-right" },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                icon: "el-icon-download",
                              },
                              on: {
                                click:
                                  _vm.handleDownloadConsumeStorePolyStatisticsExport,
                              },
                            },
                            [_vm._v("下载明细清单 ")]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "80%" },
                      attrs: {
                        data: _vm.storePolyStatisticsDataList,
                        border: "",
                        "cell-class-name": _vm.tableRowClassName,
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "target",
                          label: "指标",
                          align: "center",
                          fixed: "",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "storeCode",
                          label: "门店ID",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "storeName",
                          label: "门店名称",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "reachRs",
                          label: "实际触达人数",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "orderQty",
                          label: "订单数",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "sellAmount",
                          label: "销售额（含卡/券）",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "customerUnitPrice",
                          label: "客单价（销售额（含卡/券）/订单数）",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "jyrs",
                          label: "消费人数",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "xfRate",
                          label: "消费率（消费人数/实际触达人数）",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " + _vm._s(scope.row.xfRate + "%") + " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "xbkBkRs",
                          label: "办卡人数",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "xbkKkRs",
                          label: "开卡人数",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "xbkXkRs",
                          label: "续卡人数",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "bkRate",
                          label: "办卡率（办卡人数/消费人数）",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " + _vm._s(scope.row.bkRate + "%") + " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "yqRate",
                          label: "用券率（用券人数/消费人数）",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _vm._v(
                                  " " + _vm._s(scope.row.yqRate + "%") + " "
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "store-polypagination-wrapper" },
                [
                  _c("el-pagination", {
                    staticStyle: { "padding-top": "2%" },
                    attrs: {
                      background: "",
                      "current-page": _vm.storePolySPage,
                      "page-size": _vm.storePolySPageSize,
                      total: _vm.storePolySPageSizeTotle,
                      layout: "prev, pager, next",
                    },
                    on: { "current-change": _vm.storePolySPageChange },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.radioCheck === "分组",
              expression: "radioCheck === '分组'",
            },
          ],
        },
        [
          _c(
            "div",
            { staticClass: "header" },
            [
              _c("label", { staticClass: "child-header" }, [
                _vm._v("对比组："),
              ]),
              _c(
                "el-radio-group",
                {
                  attrs: { id: "xfzq-selectcdsjwdrqgroup" },
                  on: { input: _vm.handleRadiosGwdInput },
                  model: {
                    value: _vm.radiosjwd,
                    callback: function ($$v) {
                      _vm.radiosjwd = $$v
                    },
                    expression: "radiosjwd",
                  },
                },
                [
                  _c(
                    "el-radio",
                    { staticClass: "header-option", attrs: { label: 0 } },
                    [_vm._v("对照组")]
                  ),
                  _c(
                    "el-radio",
                    { staticClass: "header-option", attrs: { label: 3 } },
                    [_vm._v("应触达未触达")]
                  ),
                  _c(
                    "el-radio",
                    { staticClass: "header-option", attrs: { label: 1 } },
                    [_vm._v("全部未触达")]
                  ),
                  _c(
                    "el-radio",
                    { staticClass: "header-option", attrs: { label: 2 } },
                    [_vm._v("自定义")]
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.radiosjwd === 2,
                      expression: "radiosjwd === 2",
                    },
                  ],
                },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: {
                        "font-size": "15px",
                        "padding-right": "1%",
                        width: "300px",
                      },
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "请选择分组名称",
                        id: "fz-select",
                        size: "mini",
                      },
                      on: { input: _vm.handleRadiosCheInput },
                      model: {
                        value: _vm.radioChe,
                        callback: function ($$v) {
                          _vm.radioChe = $$v
                        },
                        expression: "radioChe",
                      },
                    },
                    _vm._l(_vm.GroupDataList, function (item) {
                      return _c("el-option", {
                        key: item.name,
                        attrs: {
                          label: item.name,
                          value: item.ruleGroupChannelId,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", [
            _c(
              "div",
              { staticClass: "top-title", staticStyle: { width: "81%" } },
              [
                _c(
                  "div",
                  { staticClass: "title-right" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", icon: "el-icon-download" },
                        on: {
                          click:
                            _vm.handleDownloadConsumeGroupCompareStatisticsExport,
                        },
                      },
                      [_vm._v(" 下载明细清单 ")]
                    ),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "div",
              [
                _c(
                  "el-table",
                  {
                    staticStyle: { width: "80%" },
                    attrs: {
                      data: _vm.SmsGroupCompareStatisticsDataList,
                      border: "",
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        prop: "reachChannel",
                        label: "渠道",
                        align: "center",
                        fixed: "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "groupName",
                        label: "分组名称",
                        align: "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "target", label: "指标", align: "center" },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "reachRs",
                        label: "实际触达人数",
                        align: "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "orderQty",
                        label: "订单数",
                        align: "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "sellAmount",
                        label: "销售额（含卡/券）",
                        align: "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "customerUnitPrice",
                        label: "客单价（销售额（含卡/券）/订单数）",
                        align: "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "jyrs",
                        label: "消费人数",
                        align: "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "xfRate",
                        label: "消费率（消费人数/实际触达人数）",
                        align: "center",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    scope.row.xfRate !== "/"
                                      ? scope.row.xfRate + "%"
                                      : scope.row.xfRate
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "xbkBkRs",
                        label: "办卡人数",
                        align: "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "xbkKkRs",
                        label: "开卡人数",
                        align: "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "xbkXkRs",
                        label: "续卡人数",
                        align: "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "bkRate",
                        label: "办卡率（办卡人数/消费人数）",
                        align: "center",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " + _vm._s(scope.row.bkRate + "%") + " "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "yqRate",
                        label: "用券率（用券人数/消费人数）",
                        align: "center",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " + _vm._s(scope.row.yqRate + "%") + " "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "top-title", staticStyle: { width: "81%" } },
              [
                _c(
                  "div",
                  { staticClass: "title-right" },
                  [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", icon: "el-icon-download" },
                        on: {
                          click:
                            _vm.handleDownloadGroupToDayCompareStatisticsExport,
                        },
                      },
                      [_vm._v(" 下载明细清单 ")]
                    ),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "div",
              [
                _c(
                  "el-table",
                  {
                    staticStyle: { width: "80%" },
                    attrs: {
                      data: _vm.GroupToDayStatisticsDataList,
                      border: "",
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        prop: "reachChannel",
                        label: "渠道",
                        align: "center",
                        fixed: "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "groupName",
                        label: "分组名称",
                        align: "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: { prop: "target", label: "时间", align: "center" },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "reachRs",
                        label: "实际触达人数",
                        align: "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "orderQty",
                        label: "订单数",
                        align: "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "sellAmount",
                        label: "销售额（含卡/券）",
                        align: "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "customerUnitPrice",
                        label: "客单价（销售额（含卡/券）/订单数）",
                        align: "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "jyrs",
                        label: "消费人数",
                        align: "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "xfRate",
                        label: "消费率（消费人数/实际触达人数）",
                        align: "center",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    scope.row.xfRate !== "/"
                                      ? scope.row.xfRate + "%"
                                      : scope.row.xfRate
                                  ) +
                                  " "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "xbkBkRs",
                        label: "办卡人数",
                        align: "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "xbkKkRs",
                        label: "开卡人数",
                        align: "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "xbkXkRs",
                        label: "续卡人数",
                        align: "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "bkRate",
                        label: "办卡率（办卡人数/消费人数）",
                        align: "center",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " + _vm._s(scope.row.bkRate + "%") + " "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        prop: "yqRate",
                        label: "用券率（用券人数/消费人数）",
                        align: "center",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _vm._v(
                                " " + _vm._s(scope.row.yqRate + "%") + " "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]
      ),
    ]),
    _c("div", { staticClass: "end-page" }),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h2", [
      _c("i", {
        staticClass: "el-icon-s-opportunity",
        staticStyle: { "padding-top": "1%" },
      }),
      _vm._v(" 触达漏斗"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h2", [
      _c("i", { staticClass: "el-icon-s-opportunity" }),
      _vm._v("未触达任务统计"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h2", [
      _c("i", { staticClass: "el-icon-s-opportunity" }),
      _vm._v(" 实际触达人群消费数据"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }