<template>
  <div v-loading="homeLoading" style="padding: 20px">
    <div class="mg-search" style="padding-bottom: 15px">
      <div style="float: right">
        <el-dropdown>
          <el-button type="primary">
            触发指定型号刷新
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="createSkuModelTask">新建任务</el-dropdown-item>
            <el-dropdown-item @click.native="skuModelTaskScheduleQuery">任务进度</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <el-form ref="queryFormRef" size="mini" label-width="auto" :inline="true" :model="queryForm" @keyup.enter.native="handleQuery">
        <el-form-item label="分公司">
          <DictSelect type="org" :value.sync="queryForm.orgNames" :dictFun="dictFun" multiple />
        </el-form-item>
        <el-form-item label="平台">
          <DictSelect
            ref="platform"
            type="platform"
            :value.sync="queryForm.platforms"
            multiple
            linkageRef="shop"
            :dictFun="dictFun"
          />
        </el-form-item>
        <el-form-item label="店铺名称">
          <DictSelect
            ref="shop"
            type="shop"
            parentLinkageRef
            :value.sync="queryForm.shopNames"
            :dictFun="dictFun"
            multiple
            remote
            @getOtherJsonParam="(callback) => callback({ platforms: queryForm.platforms})"
          />
        </el-form-item>
        <el-form-item label="赛道">
          <DictSelect
            ref="race"
            type="race"
            :value.sync="queryForm.raceCodes"
            :dictFun="dictFun"
            multiple
            linkageRef="main"
            @changeLinkageRef="handleChangeLinkageRef"
          />
        </el-form-item>
        <el-form-item label="大类">
          <DictSelect
            ref="main"
            type="main"
            linkageRef="mid"
            parentLinkageRef
            :value.sync="queryForm.mainCategoryCodes"
            :dictFun="dictFun"
            multiple
            @getOtherJsonParam="(callback) => callback({ ...queryForm })"
            @changeLinkageRef="handleChangeLinkageRef"
          />
        </el-form-item>
        <el-form-item label="中类">
          <DictSelect
            ref="mid"
            type="mid"
            parentLinkageRef
            :value.sync="queryForm.midCategoryCodes"
            :dictFun="dictFun"
            linkageRef="sub"
            multiple
            @getOtherJsonParam="(callback) => callback({ ...queryForm })"
            @changeLinkageRef="handleChangeLinkageRef"
          />
        </el-form-item>
        <el-form-item label="小类">
          <DictSelect
            ref="sub"
            type="sub"
            parentLinkageRef
            :value.sync="queryForm.subCategoryCodes"
            :dictFun="dictFun"
            multiple
            @getOtherJsonParam="(callback) => callback({ ...queryForm })"
          />
        </el-form-item>
        <el-form-item label="商品型号">
          <DictSelect
            type="sku"
            :value.sync="queryForm.ecCargoNos"
            :dictFun="dictFun"
            remote
            multiple
          />
        </el-form-item>
        <el-form-item label="快捷筛选">
          <el-select v-model="queryForm.tag" placeholder="请选择">
            <el-option
              v-for="item in tagOptions"
              :key="item"
              :label="item"
              :value="item">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button class="form-btn-item" size="mini" icon="el-icon-search" type="primary" @click="handleFilter">查询</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-refresh" type="primary" @click="resetQuery">重置</el-button>
        </el-form-item>
      </el-form>
    </div>

    <el-tabs v-model="tabName" @tab-click="(val) => {
        // handleQuery();
        handleFilter();
      }">
      <el-tab-pane label="型号低价汇总" name="Low" />
      <el-tab-pane label="店铺在售商品信息" name="Goods" />
      <el-tab-pane label="单品控价提醒" name="Notice" />
      <el-tab-pane label="控价追踪" name="Trace" />
    </el-tabs>
    <div v-show="tabName==='Low'">
      <el-form ref="queryNoticeFormRef" :rules = "rules" size="mini" label-width="auto" :inline="true" :model="queryForm" style="padding: 10px;" @keyup.enter.native="handleQuery">
        <el-form-item label="价格更新时间">
          <el-date-picker
            v-model="queryForm.lowPriceModifiedDate"
            size="mini"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button class="form-btn-item" size="mini" icon="el-icon-search" type="primary" @click="handleFilter">查询</el-button>
        </el-form-item>
      </el-form>
      <el-table
        ref="lowTableRef"
        :data="pageLowData.rows"
        height="calc(100vh - 250px)"
        v-loading="lowLoading"
        size="mini"
        border
        highlight-current-row
        :header-cell-style="{ background: 'rgb(235 235 235)', color: 'black',textAlign: 'center', verticalAlign: 'middle' }"
        @sort-change="handleSortChange">
        <el-table-column label="型号" prop="skuModel" min-width="30" >
          <template #default="{ row }">
            <span v-if="row.skuModel === '总计'" style="font-size: 14px;font-weight: bold">{{ row.skuModel }}</span>
            <span v-else>{{ row.skuModel }}</span>
          </template>
        </el-table-column>
        <el-table-column label="型号名称" prop="skuModelName" min-width="80" />
        <el-table-column label="低于控价SKU数" prop="totalCount" width="100">
          <el-table-column label="合计" prop="totalCount" width="100">
            <template #default="{ row }">
              <a v-if="row.skuModel !== '总计'" style="color: #6a6ae9;font-size: 12px" @click="handleLowControlPriceClick(row.skuModel,undefined, 'Low')">{{ row.totalCount }}</a>
              <span v-else style="font-size: 14px;font-weight: bold">{{ row.totalCount }}</span>
            </template>
          </el-table-column>
          <el-table-column label="淘宝" prop="tbCount" width="100">
            <template #default="{ row }">
              <a v-if="row.skuModel !== '总计'" style="color: #6a6ae9;font-size: 12px" @click="handleLowControlPriceClick(row.skuModel,'淘宝', 'Low')">{{ row.tbCount }}</a>
              <span v-else style="font-size: 14px;font-weight: bold">{{ row.tbCount}}</span>
            </template>
          </el-table-column>
          <el-table-column label="拼多多" prop="pddCount" width="100">
            <template #default="{ row }">
              <a v-if="row.skuModel !== '总计'" style="color: #6a6ae9;font-size: 12px" @click="handleLowControlPriceClick(row.skuModel,'拼多多', 'Low')">{{ row.pddCount }}</a>
              <span v-else style="font-size: 14px;font-weight: bold">{{ row.pddCount}}</span>
            </template>
          </el-table-column>
          <el-table-column label="京东" prop="jdCount" width="100">
            <template #default="{ row }">
              <a v-if="row.skuModel !== '总计'" style="color: #6a6ae9;font-size: 12px" @click="handleLowControlPriceClick(row.skuModel,'京东', 'Low')">{{ row.jdCount }}</a>
              <span v-else style="font-size: 14px;font-weight: bold">{{ row.jdCount}}</span>
            </template>
          </el-table-column>
          <el-table-column label="抖音" prop="dyCount" width="100">
            <template #default="{ row }">
              <a v-if="row.skuModel !== '总计'" style="color: #6a6ae9;font-size: 12px" @click="handleLowControlPriceClick(row.skuModel,'抖音', 'Low')">{{ row.dyCount }}</a>
              <span v-else style="font-size: 14px;font-weight: bold">{{ row.dyCount}}</span>
            </template>
          </el-table-column>
        </el-table-column>
      </el-table>
      <Pagination :total="pageLowData.total" :page.sync="queryForm.page" :limit.sync="queryForm.limit" @pagination="handleQuery" />
    </div>
    <div v-show="tabName==='Goods'">
      <div style="padding: 10px">
        <el-button class="form-btn-item" size="mini" icon="el-icon-download" type="primary" @click="handleExportGoods">导出</el-button>
      </div>
      <el-table
        ref="tableRef"
        :data="pageGoodsData.rows"
        height="calc(100vh - 250px)"
        v-loading="goodsLoading"
        size="mini"
        border
        highlight-current-row
        :header-cell-style="{ background: 'rgb(235 235 235)', color: 'black',textAlign: 'center', verticalAlign: 'middle' }"
      >
        <el-table-column label="平台" prop="ptName" min-width="80" />
        <el-table-column label="分公司" prop="orgName" min-width="80" />
        <el-table-column label="店铺名称" prop="shopName" width="200" />
        <el-table-column label="店铺性质" prop="shopType" width="200" />
        <el-table-column label="晨光在售链接数" prop="saleSkuUrlCount" width="140" />
        <el-table-column label="晨光SKU数" prop="saleSkuIdCount" min-width="140">
          <template #default="{ row }">
            <a style="color: #6a6ae9;font-size: 12px" @click="handleRowClick(row, 'Goods')">{{ row.saleSkuIdCount }}</a>
          </template>
        </el-table-column>
      </el-table>
      <Pagination :total="pageGoodsData.total" :page.sync="queryForm.page" :limit.sync="queryForm.limit" @pagination="handleQuery" />
    </div>
    <div v-show="tabName=='Notice'">
      <el-form ref="queryNoticeFormRef" :rules = "rules" size="mini" label-width="auto" :inline="true" :model="queryForm" style="padding: 10px;" @keyup.enter.native="handleQuery">
        <el-form-item label="商品ID">
          <el-input v-model="queryForm.itemId" clearable />
        </el-form-item>
        <el-form-item label="SKUID">
          <el-input v-model="queryForm.skuId" clearable />
        </el-form-item>
        <el-form-item label="是否已确认">
          <el-select v-model="queryForm.ifConfirm" clearable>
            <el-option value="Y" label="是" /><el-option value="N" label="否" />
          </el-select>
        </el-form-item>
        <el-form-item label="警示价">
          <el-input-number v-model="queryForm.warningPrice" clerable :step="2" :min="0" :controls="false" />元
        </el-form-item>
        <el-form-item label="控价结果">
          <DictSelect type="controlPrice" :value.sync="queryForm.controlPriceResult" :dictFun="dictFun"/>
        </el-form-item>
        <el-form-item label="价差">
          <el-input-number v-model="queryForm.diffPriceStart" clearable="" style="width: 100px" @input="handleDiffPriceStart">
          </el-input-number>
          %&nbsp;至&nbsp;
          <el-input-number v-model="queryForm.diffPriceEnd" clearable="" style="width: 100px" @input="handleDiffPriceEnd">
            <template slot="suffix">%</template>
          </el-input-number>
          %
        </el-form-item>
        <el-form-item label="价格更新时间">
          <el-date-picker
            v-model="queryForm.priceModifiedDate"
            size="mini"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="是否组装" prop="ifCombined">
          <DictSelect
            ref="ifSpu"
            type="ifSpu"
            :value.sync="queryForm.ifCombined"
            :dictFun="dictSpuFun"
            @getOtherJsonParam="(callback) => callback({ platforms: queryForm.platforms})"
            style="width: 500px"
          />
        </el-form-item>
        <el-form-item>
          <el-button class="form-btn-item" size="mini" icon="el-icon-search" type="primary" @click="handleFilter">查询</el-button>
        </el-form-item>
      </el-form>
      <div style="padding: 10px">
        <el-button class="form-btn-item" size="mini" icon="el-icon-download" type="primary" @click="handleExportNotice">导出</el-button>
        <el-button class="form-btn-item" size="mini" icon="el-icon-delete-solid" type="primary" @click="handleBatchSaveNotice">清除所选项的备注</el-button>
        <el-button class="form-btn-item" size="mini" type="primary" @click="handleBatchMatchConfirm">批量确认匹配</el-button>
        <el-button class="form-btn-item" size="mini" type="primary" @click="handleSkuPriceControlTraceGenerate">生成控价追踪表</el-button>
        <el-popover
          placement="right"
          width="600"
          trigger="hover">
          <div style="overflow-y: scroll;">
            <label>功能说明：</label><br>
            1.页面商品需要有到SKU的对应关系，系统会根据页面信息及图片识别。如未识别上需要人工点击【匹配】按钮进行维护<br>
            2.控价计算结果需要依赖 控价表，用户可以点击 【导出控价表】获取当前控价信息。通过【导入控价表】可以覆盖最新控价信息。<br>
            3.控价到型号、规格、售卖数量上下限。型号需要完全一致，规格如果是一级规格（支、块、套、卡、本、包、袋、件、桶、本体、把、箱、张、个、筒）会自动转成"个"匹配上。<br>
            4.价差=(当前售价-控价总价)/控价总价，可以通过筛选项查找价差范围，如价差非常大，可能匹配关系异常，建议复核<br>
            5.警示价:即低于筛选低于警示价的所有型号，您可以针对指定型号或某个品类进行操作，快速找到低价品。<br>
            <br>

            <label>控价逻辑：</label><br>
            1.在控价表中的型号及规格范围，用   [页面售价]  与 [符合规格数量的控价] 对比<br>
            2.如控价表中没有的型号，则以  [零售价*页面售卖数量] 的9折对比 [页面售价]<br>
            <br>

            <label>控价结果解释：</label><br>
            1.符合控价： 价格高于控价<br>
            2.价格低于控价： 价格低于控价<br>
            3.不符合控价售卖规格：控价表中没有该型号页面售卖的规格<br>
            4.匹配关系缺失：页面商品型号或规格未知，点击【匹配】维护
            <br>
          </div>
          <el-link icon="el-icon-question" type="info" slot="reference" style="margin-left: 10px">使用说明</el-link>
        </el-popover>
      </div>
      <el-table
        ref="tableRef"
        :data="pageNoticeData.rows"
        height="calc(100vh - 250px)"
        v-loading="noticeLoading"
        size="mini"
        border
        highlight-current-row
        :header-cell-style="{ background: 'rgb(235 235 235)', color: 'black',textAlign: 'center', verticalAlign: 'middle' }"
        @sort-change="handleSortChange"
        @selection-change="handleSelectionChange">
        <el-table-column fixed type="selection" width="90"></el-table-column>
        <el-table-column label="平台" prop="ptName" min-width="60" />
        <el-table-column label="分公司" prop="orgName" min-width="80" />
        <el-table-column label="店铺名称" prop="shopName" width="200" />
        <el-table-column label="页面标题" prop="title" width="200" />
        <el-table-column label="商品ID" prop="itemId" min-width="100" />
        <el-table-column label="SKUID" prop="skuId" min-width="100" />
        <el-table-column label="商品信息" prop="skuName" min-width="200">
          <template #default="{ row }">
            <a target="_blank" style="color: #6a6ae9;font-size: 12px" :href="row.skuUrl">{{ row.skuName }}</a>
          </template>
        </el-table-column>
        <el-table-column label="商品图片" prop="skuPic" width="100">
          <template #default="{ row }">
            <el-image :preview-src-list="[row.skuPic]" :src="row.skuPic" lazy style="height: 80px;margin: 5px;" />
          </template>
        </el-table-column>
        <el-table-column label="匹配关系" prop="finalMatch" min-width="200">
          <template #default="{ row }">
            <div v-if="row.finalMatch">
              <p v-for="item in row.finalMatch.split('\n')" :key="item"> {{ item }}</p>
            </div>
            <el-button class="form-btn-item" size="mini" type="text" @click="handleJumpQuery(row.skuId)">匹配</el-button>
            <el-button v-if="row.ifShowConfirm === 'N'" class="form-btn-item" size="mini" type="text" @click="handleMatchConfirm(row)">确认</el-button>
          </template>
        </el-table-column>
<!--        <el-table-column label="折算最小单位数量" prop="discountMinUnitQuantity" min-width="80" />-->
        <el-table-column label="价差" prop="diffPrice" sortable min-width="80" >
          <template #default="{ row }">
            <span v-if="row.diffPrice != undefined" :style="row.diffPrice < 0? {color: 'red'}: {}">{{ row.diffPrice + '%' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="控价结果" prop="controlPriceResult" min-width="80">
          <template #default="{ row }">
            <span :style="row.controlTotalPrice>row.currentSellPrice? {color: 'red'}: {}">{{ row.controlPriceResult }}</span>
          </template>
        </el-table-column>
        <el-table-column label="价格更新时间" prop="priceModified" sortable min-width="140" />
        <el-table-column label="控价单价" prop="controlMinUnitPrice" min-width="80" >
          <template #default="{ row }">
            <div>
              <span>{{ row.controlMinUnitPrice }}</span>
            </div>
            <el-button class="form-btn-item" size="mini" type="text" @click="queryPriceControlList(row)">查询</el-button>
          </template>
        </el-table-column>
        <el-table-column label="x 控价规格" prop="saleUnitKj" min-width="80" />
        <el-table-column label="= 控价总价" prop="controlTotalPrice" min-width="80">
          <template slot="header">
            <div style="background-color: #99cbff">= 控价总价</div>
          </template>
        </el-table-column>
        <el-table-column label="当前售价" prop="currentSellPrice" min-width="80">
          <template slot="header">
            <div style="background-color: #99cbff">当前售价</div>
          </template>
          <template #default="{ row }">
            <el-popover
              placement="top-start"
              width="80"
              trigger="hover"
              v-if="row.screenshotImageUrl"
            >
              <el-image v-if="row.screenshotImageUrl" :preview-src-list="[row.screenshotImageUrl]" :src="row.screenshotImageUrl" style="height: 80px;margin: 5px;" />
<!--              <span v-else>图片待上传</span>-->
              <el-button slot="reference" type="text">{{ row.currentSellPrice }}</el-button>
            </el-popover>
            <span v-else>{{ row.currentSellPrice }}</span>
          </template>
        </el-table-column>
        <el-table-column label="到手价" prop="yhPrice" min-width="80">
          <template #default="{ row }">
            <el-button type="text" @click="openInHandsPriceDialog(row)">{{ row.yhPrice }}</el-button>
          </template>
        </el-table-column>
        <el-table-column label="控价赠品说明" prop="controlPriceGiftExplain" min-width="80" />
        <el-table-column label="备注" prop="orgName" min-width="140">
          <template #default="{ row }">
            <el-input v-model="row.remark" size="mini" @input="handleUpdatePriceNotice(row)" placeholder="输入后自动保存" />
          </template>
        </el-table-column>
      </el-table>
      <Pagination :total="pageNoticeData.total" :page.sync="queryForm.page" :limit.sync="queryForm.limit" @pagination="handleQuery" />
    </div>

    <div v-show="tabName==='Trace'">
      <el-form ref="queryTraceFormRef" :rules = "rules" size="mini" label-width="auto" :inline="true" :model="queryForm" style="padding: 10px;" @keyup.enter.native="handleQuery">
        <el-form-item label="商品ID">
          <el-input v-model="queryForm.traceItemId" clearable />
        </el-form-item>
        <el-form-item label="SKUID">
          <el-input v-model="queryForm.traceSkuId" clearable />
        </el-form-item>
        <el-form-item label="控价结果">
          <DictSelect type="controlPrice" :value.sync="queryForm.traceControlPriceResult" :dictFun="dictFun"/>
        </el-form-item>
        <el-form-item>
          <el-button class="form-btn-item" size="mini" icon="el-icon-search" type="primary" @click="handleFilter">查询</el-button>
          <el-button class="form-btn-item" size="mini" type="primary" @click="handleSkuPriceControlTraceCancel">取消跟进</el-button>
<!--          <el-button class="form-btn-item" size="mini" type="primary" >创建刷新业务</el-button>-->
          <el-button class="form-btn-item" size="mini" icon="el-icon-download" type="primary" @click="handlePriceControlTraceExportNotice">导出</el-button>
        </el-form-item>
      </el-form>
      <el-table
        ref="tableRef"
        :data="pageTraceData.rows"
        height="calc(100vh - 250px)"
        v-loading="traceLoading"
        size="mini"
        border
        highlight-current-row
        :header-cell-style="{ background: 'rgb(235 235 235)', color: 'black',textAlign: 'center', verticalAlign: 'middle' }"
        @sort-change="handleSortChange"
        @selection-change="handleTraceSelectionChange">
        <el-table-column fixed type="selection" width="90"></el-table-column>
        <el-table-column label="平台" prop="ptName" min-width="60" />
        <el-table-column label="分公司" prop="orgName" min-width="80" />
        <el-table-column label="店铺名称" prop="shopName" width="200" />
        <el-table-column label="页面标题" prop="title" width="200" />
        <el-table-column label="商品ID" prop="itemId" min-width="100" />
        <el-table-column label="SKUID" prop="skuId" min-width="100" />
        <el-table-column label="商品信息" prop="skuName" min-width="200">
          <template #default="{ row }">
            <a target="_blank" style="color: #6a6ae9;font-size: 12px" :href="row.skuUrl">{{ row.skuName }}</a>
          </template>
        </el-table-column>
        <el-table-column label="商品图片" prop="skuPic" width="100">
          <template #default="{ row }">
            <el-image :preview-src-list="[row.skuPic]" :src="row.skuPic" lazy style="height: 80px;margin: 5px;" />
          </template>
        </el-table-column>
        <el-table-column label="匹配关系" prop="finalMatch" min-width="200">
          <template #default="{ row }">
            <div v-if="row.finalMatch">
              <p v-for="item in row.finalMatch.split('\n')" :key="item"> {{ item }}</p>
            </div>
            <el-button class="form-btn-item" size="mini" type="text" @click="handleJumpQuery(row.skuId)">匹配</el-button>
            <el-button v-if="row.ifShowConfirm === 'N'" class="form-btn-item" size="mini" type="text" @click="handleMatchConfirm(row)">确认</el-button>
          </template>
        </el-table-column>
<!--        <el-table-column label="折算最小单位数量" prop="discountMinUnitQuantity" min-width="160" />-->
        <el-table-column label="价差" prop="diffPrice" sortable min-width="80" >
          <template #default="{ row }">
            <span v-if="row.spreadPrice != undefined" :style="row.spreadPrice < 0? {color: 'red'}: {}">{{ row.spreadPrice + '%' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="控价结果" prop="controlPriceResult" min-width="80">
          <template #default="{ row }">
            <span :style="row.controlTotalPrice>row.currentSellPrice? {color: 'red'}: {}">{{ row.controlPriceResult }}</span>
          </template>
        </el-table-column>
        <el-table-column label="价格更新时间" prop="priceModified" sortable min-width="140" />
        <el-table-column label="控价单价" prop="controlMinUnitPrice" min-width="80">
          <template #default="{ row }">
            <div>
              <span>{{ row.controlMinUnitPrice }}</span>
            </div>
            <el-button class="form-btn-item" size="mini" type="text" @click="queryPriceControlList(row)">查询</el-button>
          </template>
        </el-table-column>
        <el-table-column label="x 控价规格" prop="saleUnitKj" min-width="80" />
        <el-table-column label="= 控价总价" prop="controlTotalPrice" min-width="80">
          <template slot="header">
            <div style="background-color: #99cbff">= 控价总价</div>
          </template>
        </el-table-column>
        <el-table-column label="当前售价" prop="currentSellPrice" min-width="80">
          <template slot="header">
            <div style="background-color: #99cbff">当前售价</div>
          </template>
          <template #default="{ row }">
            <el-popover
              placement="top-start"
              width="80"
              trigger="hover"
              v-if="row.screenshotImageUrl"
            >
              <el-image v-if="row.screenshotImageUrl" :preview-src-list="[row.screenshotImageUrl]" :src="row.screenshotImageUrl" style="height: 80px;margin: 5px;" />
              <el-button slot="reference" type="text">{{ row.currentSellPrice }}</el-button>
            </el-popover>
            <span v-else>{{ row.currentSellPrice }}</span>
          </template>
        </el-table-column>
        <el-table-column label="到手价" prop="yhPrice" min-width="80">
          <template #default="{ row }">
            <el-button type="text" @click="openInHandsPriceDialog(row)">{{ row.yhPrice }}</el-button>
          </template>
        </el-table-column>
        <el-table-column label="原售价" prop="originSellPrice" min-width="80" />
        <el-table-column label="价格较加入时变化" prop="diffPrice" min-width="120" >
          <template #default="{ row }">
            <span v-if="row.diffPrice != undefined && row.diffPrice > 0" :style="{color: 'red'}">{{row.diffPrice}}</span>
            <span v-else-if="row.diffPrice != undefined && row.diffPrice < 0" :style="{color: 'green'}">{{row.diffPrice}}</span>
            <span v-else>{{row.diffPrice}}</span>
          </template>
        </el-table-column>
        <el-table-column label="价格更新次数" prop="collectCount" min-width="120" />
        <el-table-column label="符合控价次数" prop="qualifiedCount" min-width="120" />
        <el-table-column label="创建追踪时间" prop="traceCreateTime" min-width="140" />
        <el-table-column label="创建用户" prop="userName" min-width="140" />
      </el-table>
      <Pagination :total="pageTraceData.total" :page.sync="queryForm.page" :limit.sync="queryForm.limit" @pagination="handleQuery" />
    </div>

    <!-- 到手价弹框 -->
    <InHandsPrice v-if="inHandsPriceVisibile" :ptName="selectedRows.ptName" :shopName="selectedRows.shopName" :skuId="selectedRows.skuId"
      @close="inHandsPriceVisibile=false" />

    <el-dialog title="请选择正确的匹配关系" :visible.sync="skuModelUpdateDialog.visible" append-to-body width="1200px">
      <div style="overflow-y: scroll;max-height: 860px">
        <el-form ref="saveSkuModelUpdateFormRef" size="mini" :inline="true" :model="skuModelUpdateDialog.form">
          <div>
            <el-row style="width: 1100px">
              <el-col>
                <div>
                  <span style="font-size: 16px;font-weight: bold">识别到可能是以下商品,请选择:</span>
                </div>
                <div v-for="item in skuModelUpdateOptions"
                     :key="item.skuModel"
                     :label="item.skuModel" style="display: inline-block;margin-top: 1%;margin-left: 1%">
                  <el-carousel height="200px">
                    <el-carousel-item v-for="item1 in item.skuUrls" :key="item1">
                      <el-image :preview-src-list="[item1]" :src="item1" />
                    </el-carousel-item>
                  </el-carousel>
                  <div class="button-group">
                    <el-button :title="item.skuModelName" class="btn button-online" :class="{'selected': skuModelUpdateDialog.selectSkuModelButton === item.skuModel}" style="width: 200px;font-size: 10px;margin-top: 1%" @click="handSkuModelChange(item)">{{item.skuModel}}<br>{{'型号品名：' + item.skuModelName}}</el-button>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
          <div>
            <div style="border: 1px dashed red;margin-top: 1%;width: 1120px">
              <el-form-item label="最终型号" class="redLabel" style="margin-top: 1%;margin-left: 2%;color: #ea2838"></el-form-item>
              <el-form-item label="请从上方选择自动带入型号或下拉直接选择:" style="margin-top: 1%">
                <DictSelect
                  ref="skuModel"
                  type="sku_model"
                  :value.sync="skuModelUpdateDialog.form.skuModelByPerson"
                  :dictFun="dictSkuModelsFun"
                  :init-props="{ label: 'skuModel', value: 'skuModel'}"
                  @change="val =>handSkuModelInfoChange(val)"
                  remote
                />
              </el-form-item>
            </div>
            <div>
              <el-row>
                <el-col :span="12">
                  <div style="display: inline-block;margin-left: 30%;margin-top: 1%;border: 1px dashed black;height: 350px">
                    <el-carousel height="300px" style="width: 300px;">
                      <el-carousel-item v-for="item in skuModelUpdateDialog.selectSkuModelOptions" :key="item">
                        <el-image :preview-src-list="[item]" :src="item" />
                      </el-carousel-item>
                    </el-carousel>
                    <div style="width: 300px;height: 20px;text-align:center">
                      <span style="font-size: 12px">你最终选择匹配的商品图片<br>{{'型号品名：' + skuModelUpdateDialog.skuModelName}}</span>
                    </div>
                  </div>

                </el-col>
                <el-col :span="12">
                  <div style="display: inline-block;margin-left: 10%;margin-top: 1%;border: 1px dashed black;height: 350px">
                    <el-carousel height="300px" style="width: 300px;text-align:center;">
                      <el-carousel-item v-for="item in [skuModelUpdateDialog.form.skuPic]" :key="item" >
                        <el-image :preview-src-list="[item]" :src="item" style="height: 300px" />
                      </el-carousel-item>
                    </el-carousel>
                    <div style="height: 20px;text-align:center">
                      <span style="font-size: 12px">店铺商品图片<br>{{'型号品名：' + skuModelUpdateDialog.shopSkuModelName}}</span>
                    </div>
                  </div>
                </el-col>
              </el-row>
            </div>
            <div style="margin-top: 1%">
              <div>
                <span style="font-size: 16px;font-weight: bold">识别到规格,请确认或修改:</span>
              </div>
              <div style="border: 1px dashed red;margin-top: 1%;width: 1120px">
                <el-form-item label="最终规格" class="redLabel" style="margin-top: 1%;margin-left: 2%"></el-form-item>
                <el-form-item label="识别到规格:" prop="skuSpecificationsBySystem" style="margin-top: 1%" >
                  <span>{{skuModelUpdateDialog.form.skuSpecificationsBySystem}}</span>
                </el-form-item>
                <el-form-item label="人工维护售卖规格" prop="skuSpecificationsByPerson" style="margin-top: 1%" >
                  <el-input v-model="skuModelUpdateDialog.form.skuSpecificationsByPerson" clearable placeholder="请输入规格" />
                </el-form-item>
                <el-form-item label="是否组装" prop="ifCombined" style="margin-top: 1%" >
  <!--                <el-select v-model="skuModelUpdateDialog.form.ifCombined" size="mini" clearable>-->
  <!--                  <el-option value="是" label="是" /><el-option value="否" label="否" />-->
  <!--                </el-select>-->
                  <DictSelect
                    ref="ifSpu"
                    type="ifSpu"
                    :value.sync="skuModelUpdateDialog.form.ifCombined"
                    :dictFun="dictSpuFun"
                    @getOtherJsonParam="(callback) => callback({ platform: skuModelUpdateDialog.form.ptName})"
                    style="width: 500px"
                  />
                </el-form-item>
              </div>
            </div>
          </div>
        </el-form>
        <div slot="footer" class="dialog-footer" style="width: 90%;background-color: white;height: 30px">
          <div style="display: inline-block; float: right;margin-top: 1%">
            <el-button type="primary" size="mini" @click="skuModelUpdateDialog.visible=false">取消</el-button>
            <el-button type="primary" size="mini" @click="submitSkuModelEdit">确定</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog title="操作确认" :visible.sync="createTaskDialog.visible" append-to-body width="1000px" top="25vh">
      <div style="float: right"><span class="background-box" style="font-size: 15px;">受资源限制，最多存在2个任务</span></div>
      <div style="margin-top: 2%"><span style="font-size: 15px;font-weight: bold">请输入需要立刻采集的商品型号（可多选）</span></div>
      <el-form ref="createTaskFormRef" size="mini" label-width="auto" :inline="true" :model="createTaskDialog.form" style="margin-top: 30px">
        <el-row>
          <el-form-item label="商品型号">
            <DictSelect type="sku" :value.sync="createTaskDialog.form.ecCargoNos" :dictFun="dictFun" multiple remote @change="val =>handTaskSkuModelChange(val)"/>
          </el-form-item>
          <span style="display: inline-block;float: none;line-height: 28px">最多选择十个型号</span>
        </el-row>
        <el-row>
          <el-form-item label="选择频次">
            <el-radio-group v-model="createTaskDialog.form.collectFrequency" size="mini">
              <el-radio label="单次" />
<!--              <el-radio label="每小时一次" />-->
              <el-radio label="每天一次" />
            </el-radio-group>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item v-if="createTaskDialog.form.collectFrequency === '每小时一次' || createTaskDialog.form.collectFrequency === '每天一次'" label="任务时间范围">
            <el-date-picker
              v-model="createTaskDialog.form.taskTimeRange"
              size="mini"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
              :picker-options="pickerOptions">
            </el-date-picker>
          </el-form-item>
          <span v-if="createTaskDialog.form.collectFrequency === '每小时一次' || createTaskDialog.form.collectFrequency === '每天一次'" style="display: inline-block;float: none;line-height: 28px">选择每小时/每天一次时出现，即任务生效日期范围</span>
        </el-row>
        <el-row>
          <el-form-item v-if="createTaskDialog.form.collectFrequency === '每天一次'" label="任务小时">
            <el-time-select
              v-model="createTaskDialog.form.executeTime"
              :picker-options="{
                start: '00:01',
                step: '00:01',
                end: '23:59'
              }"
              editable
              placeholder="选择时间">
            </el-time-select>
          </el-form-item>
          <span v-if="createTaskDialog.form.collectFrequency === '每天一次'" style="display: inline-block;float: none;line-height: 28px">选择每天一次时出现，即每天几点执行</span>
        </el-row>
      </el-form>
      <div style="margin-top: 5%">
        <p style="font-size: 12px;box-sizing:border-box;color: #0B1523">共选择了 <span style="color: #ea2838">{{createTaskDialog.form.ecCargoNos!==undefined?createTaskDialog.form.ecCargoNos.length:0}}</span>个 型号，匹配到 <span style="color: #ea2838">{{createTaskDialog.matchSkuUrlCount}}</span> 条需要更新的商品链接</p>
      </div>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button type="primary" size="mini" @click="submitCreateSkuModelTask">确定</el-button>
        <el-button type="primary" size="mini" @click="createTaskDialog.visible = false">取消</el-button>
      </div>
    </el-dialog>
    <el-dialog title="任务管理" :visible.sync="taskScheduleDialog.visible" append-to-body width="1200px">
      <el-form ref="taskScheduleFormRef" size="mini" label-width="auto" :inline="true" :model="taskScheduleDialog.form">
        <el-form-item label="商品型号">
          <DictSelect type="sku" :value.sync="taskScheduleDialog.form.ecCargoNo" :dictFun="dictFun" remote />
        </el-form-item>
        <el-form-item>
          <el-button class="form-btn-item" size="mini" icon="el-icon-search" type="primary" @click="handleQueryTaskPages">查询</el-button>
        </el-form-item>
      </el-form>
      <el-table
        :data="taskScheduleDialog.taskData"
        size="mini"
        border
        highlight-current-row
        :header-cell-style="{ background: 'rgb(227 224 224)', color: 'black' }"
      >
        <el-table-column label="序号" prop="id" width="230"/>
        <el-table-column label="采集型号清单" prop="collectSkuModel" />
        <el-table-column label="采集频次" prop="collectFrequency" />
        <el-table-column label="创建时间" prop="createTime" />
        <el-table-column label="任务时间" prop="taskTime" width="150"/>
        <el-table-column label="最后一次采集完成时间" prop="lastCollectFinishTime" width="150"/>
        <el-table-column label="最后一次采集数据量" prop="lastCollectFinishTime" width="150"/>
        <el-table-column label="今日状态" prop="status" />
        <el-table-column label="任务管理" align="center" class-name="small-padding fixed-width">
          <template #default="scope">
            <el-button
              size="mini"
              type="text"
              icon="el-icon-delete"
              @click="deleteSkuModelTask(scope.row)"
            >删除任务</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>

    <el-dialog title="该型号当前控价" :visible.sync="priceControlListDialog.visible" append-to-body width="1350px">
      <div>
        <p style="font-size: 16px" v-if="(priceControlListDialog.saleUnitKj === null || priceControlListDialog.saleUnitKj === '') && priceControlListDialog.form.controlPriceResult !== '不符合控价售卖规格'">
          该商品控价按零售价9折。零售价为 <span>{{(((priceControlListDialog.form.discountMinUnitQuantity === 0 || priceControlListDialog.form.discountMinUnitQuantity === null)?0:(priceControlListDialog.form.controlTotalPrice/priceControlListDialog.form.discountMinUnitQuantity).toFixed(2))/0.9).toFixed(2)}}</span> 元，控价金额为 <span>{{(priceControlListDialog.form.discountMinUnitQuantity === 0 || priceControlListDialog.form.discountMinUnitQuantity === null)?0:(priceControlListDialog.form.controlTotalPrice/priceControlListDialog.form.discountMinUnitQuantity).toFixed(2)}}</span> 元
        </p>
      </div>

      <div v-show="(priceControlListDialog.form.controlPriceResult === '不符合控价售卖规格') || !(priceControlListDialog.saleUnitKj === null || priceControlListDialog.saleUnitKj === '')">
        <div v-show="priceControlListDialog.form.ifCombined === '是' || priceControlListDialog.form.ifCombined === '否'">
          <p v-if="priceControlListDialog.hitFlag !== undefined && priceControlListDialog.hitFlag ==='N'">当前售卖规格 <span>{{priceControlListDialog.finalMatchSkuSpecifications}}</span> 不符合控价售卖规格</p>
          <el-table
            ref="priceControlListTable"
            :data="priceControlListDialog.priceControlList.rows"
            size="mini"
            border
            highlight-current-row
            :row-class-name="tableRowClassName"
            :header-cell-style="{ background: 'rgb(227 224 224)', color: 'black' }"
          >
            <el-table-column label="平台" prop="ptName" min-width="100" />
            <el-table-column label="型号" prop="skuModel" min-width="100" />
            <el-table-column label="售卖规格单位" prop="saleUnit" min-width="100" />
            <el-table-column label="售卖规格单位是否最小单位" prop="ifMinUnit" min-width="120" />
            <el-table-column label="售卖规格下限" prop="saleUnitMin" min-width="100" />
            <el-table-column label="售卖规格上限" prop="saleUnitMax" min-width="100" />
            <el-table-column label="售卖单价" prop="unitPrice" min-width="100" />
            <el-table-column label="售卖总价" prop="totalPrice" min-width="100" />
            <el-table-column label="赠品" prop="gift" min-width="100" />
            <el-table-column label="操作" min-width="120" align="center" class-name="small-padding fixed-width">
              <template #default="{ row }">
                <el-button
                  size="mini"
                  type="text"
                  icon="el-icon-edit"
                  @click="handleSkuPriceControlEdit(row)"
                >编辑</el-button>
                <el-button
                  size="mini"
                  type="text"
                  icon="el-icon-delete"
                  @click="handleDeleteSkuPriceControl(row)"
                >删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <Pagination :total="priceControlListDialog.priceControlList.total" :page.sync="priceControlListDialog.form.page" :limit.sync="priceControlListDialog.form.limit" @pagination="handleQuerySkuModelPageList" />
        </div>
        <div v-show="priceControlListDialog.form.ifCombined !== '是' && priceControlListDialog.form.ifCombined !== '否'">
          <el-table
            ref="spuPriceControlListTable"
            :data="priceControlListDialog.priceControlList.rows"
            height="calc(100vh - 250px)"
            size="mini"
            border
            highlight-current-row
            :header-cell-style="{ background: 'rgb(235 235 235)', color: 'black',textAlign: 'center', verticalAlign: 'middle' }">
            <el-table-column label="组合装编码" prop="spuCode" min-width="120" />
            <el-table-column label="组合装名称" prop="spuName" min-width="200" />
            <el-table-column label="组合装型号" prop="skuModel" min-width="100" >
              <template #default="{ row }">
                <el-row>
                  <el-col v-for="(item,index) in row.skuModelInfos" :key="index">
                    <span>{{ item.skuModel}}</span>
                  </el-col>
                </el-row>
              </template>
            </el-table-column>
            <el-table-column label="售卖规格单位" prop="skuSpecifications" min-width="100" >
              <template #default="{ row }">
                <el-row>
                  <el-col v-for="(item,index) in row.skuModelInfos" :key="index">
                    <span>{{ item.skuSpecifications}}</span>
                  </el-col>
                </el-row>
              </template>
            </el-table-column>
            <el-table-column label="规格含数量" prop="skuSpecificationsQuantity" min-width="100">
              <template #default="{ row }">
                <el-row>
                  <el-col v-for="(item,index) in row.skuModelInfos" :key="index">
                    <span>{{ item.skuSpecificationsQuantity}}</span>
                  </el-col>
                </el-row>
              </template>
            </el-table-column>
            <el-table-column label="是否赠品" prop="ifGift" min-width="100">
              <template #default="{ row }">
                <el-row>
                  <el-col v-for="(item,index) in row.skuModelInfos" :key="index">
                    <span>{{ item.ifGift}}</span>
                  </el-col>
                </el-row>
              </template>
            </el-table-column>
            <el-table-column label="各平台售卖总价" prop="spuSellPrice" width="100">
              <el-table-column label="淘宝" prop="tbSpuSellPrice" width="100">
                <template #default="{ row }">
                  <span>{{ row.tbSpuSellPrice}}</span>
                </template>
              </el-table-column>
              <el-table-column label="拼多多" prop="pddSpuSellPrice" width="100">
                <template #default="{ row }">
                  <span>{{ row.pddSpuSellPrice}}</span>
                </template>
              </el-table-column>
              <el-table-column label="京东" prop="jdSpuSellPrice" width="100">
                <template #default="{ row }">
                  <span>{{ row.jdSpuSellPrice}}</span>
                </template>
              </el-table-column>
              <el-table-column label="抖音" prop="dySpuSellPrice" width="100">
                <template #default="{ row }">
                  <span>{{ row.dySpuSellPrice}}</span>
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column label="操作" min-width="120" align="center" class-name="small-padding fixed-width">
              <template #default="scope">
                <el-button
                  size="mini"
                  type="text"
                  icon="el-icon-edit"
                  @click="handleSpuEdit(scope.row)"
                >编辑</el-button>
                <el-button
                  size="mini"
                  type="text"
                  icon="el-icon-delete"
                  @click="handleDeleteSpu(scope.row)"
                >删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>

    </el-dialog>

    <el-dialog :title="skuPriceControlUpdateDialog.title" :visible.sync="skuPriceControlUpdateDialog.visible" width="600px" :close-on-click-modal="false">
      <el-form ref="spuInfoUpdate" size="mini" label-width="100px" :model="skuPriceControlUpdateDialog.form" :rules="skuPriceControlUpdateDialog.rules">
        <el-form-item label="平台" prop="ptName">
          <DictSelect
            disabled="true"
            ref="platform"
            type="platform"
            :value.sync="skuPriceControlUpdateDialog.form.ptName"
            :dictFun="dictPlatformFun"
          />
        </el-form-item>
        <el-form-item label="型号" prop="skuModel">
          <DictSelect
            disabled="true"
            ref="skuModel"
            type="sku_model"
            :value.sync="skuPriceControlUpdateDialog.form.skuModel"
            :dictFun="dictSkuModelsFun"
            :init-props="{ label: 'skuModel', value: 'skuModel'}"
            remote
          />
        </el-form-item>
        <el-form-item label="售卖规格单位" prop="saleUnit">
          <el-input v-model="skuPriceControlUpdateDialog.form.saleUnit" placeholder="请输入售卖规格单位"/>
        </el-form-item>
        <el-form-item label="售卖规格单位是否最小单位" label-width="180px" prop="ifMinUnit">
          <el-input v-model="skuPriceControlUpdateDialog.form.ifMinUnit" placeholder="请输入售卖规格单位是否最小单位"/>
        </el-form-item>
        <el-form-item label="售卖规格下限" prop="saleUnitMin">
          <el-input-number v-model="skuPriceControlUpdateDialog.form.saleUnitMin" clearable placeholder="请输入售卖规格下限"/>
        </el-form-item>
        <el-form-item label="售卖规格上限" prop="saleUnitMax">
          <el-input-number v-model="skuPriceControlUpdateDialog.form.saleUnitMax" clearable placeholder="请输入售卖规格上限"/>
        </el-form-item>
        <el-form-item label="售卖单价" prop="unitPrice">
          <el-input v-model="skuPriceControlUpdateDialog.form.unitPrice" clearable placeholder="请输入售卖单价"/>
        </el-form-item>
        <el-form-item label="售卖总价" prop="totalPrice">
          <el-input v-model="skuPriceControlUpdateDialog.form.totalPrice" clearable placeholder="请输入售卖总价"/>
        </el-form-item>
        <el-form-item label="赠品" >
          <el-input v-model="skuPriceControlUpdateDialog.form.gift" placeholder="请输入赠品"/>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button size="mini" @click="skuPriceControlUpdateDialog.visible = false">取消</el-button>
        <el-button size="mini" type="primary" @click="submitSkuPriceControlEdit">确定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="" class="spuInfoAddDialog" :visible.sync="spuInfoUpdateDialog.visible" width="1200px" :close-on-click-modal="false">
      <el-form ref="spuInfoUpdate" size="mini" label-width="100px" :model="spuInfoUpdateDialog.form">
        <el-row>
          <el-col :span = "12">
            <el-form-item label="组合装编码" prop="spuCode">
              <el-input disabled = "true" v-model="spuInfoUpdateDialog.form.spuCode" clearable style="width: 200px"/>
            </el-form-item>
          </el-col>
          <el-col :span = "12">
            <el-form-item label="组合装名称" prop="spuName">
              <el-input v-model="spuInfoUpdateDialog.form.spuName" clearable placeholder="请输入组合装名称" style="width: 200px"/>
            </el-form-item>
          </el-col>
        </el-row>
        <spn>请在下方配置各平台控价，如不填写具体值，代表该组合装不在该平台售卖：</spn>
        <el-row style="margin-top: 2%">
          <el-col :span="6">
            <el-form-item label="淘宝总价">
              <el-input-number v-model="spuInfoUpdateDialog.form.tbSpuSellPrice" clearable placeholder="请输入淘宝总价" style="width: 180px"/>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="拼多多总价">
              <el-input-number v-model="spuInfoUpdateDialog.form.pddSpuSellPrice" clearable placeholder="请输入拼多多总价" style="width: 180px"/>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="京东总价">
              <el-input-number v-model="spuInfoUpdateDialog.form.jdSpuSellPrice" clearable placeholder="请输入京东总价" style="width: 180px"/>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="抖音总价">
              <el-input-number v-model="spuInfoUpdateDialog.form.dySpuSellPrice" clearable placeholder="请输入抖音总价" style="width: 180px"/>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-table
        :data="spuInfoUpdateDialog.form.skuModelInfos"
        size="mini"
        border
        highlight-current-row
        :header-cell-style="{ background: 'rgb(227 224 224)', color: 'black' }"
      >
        <el-table-column label="型号" prop="skuModel">
          <template slot-scope="{ row }">
            <DictSelect
              ref="skuModel"
              type="ec-cargo-no"
              :value.sync="row.skuModel"
              :dictFun="dictSpuSkuModelsFun"
              remote
            />
          </template>
        </el-table-column>
        <el-table-column label="售卖规格单位" prop="skuSpecifications">
          <template slot-scope="{ row }">
            <el-input v-model="row.skuSpecifications" placeholder="请输入售卖规格单位" size="mini"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="规格含数量" prop="skuSpecificationsQuantity">
          <template slot-scope="{ row }">
            <el-input-number v-model="row.skuSpecificationsQuantity" clearable placeholder="请输入规格含数量" />
          </template>
        </el-table-column>
        <el-table-column label="是否赠品" prop="ifGift">
          <template slot-scope="{ row }">
            <el-select v-model="row.ifGift" size="mini">
              <el-option value="是" label="是" />
              <el-option value="否" label="否" />
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
          <template #default="scope">
            <el-button
              size="mini"
              type="text"
              icon="el-icon-delete"
              @click="skuModelEditDeleteLine(scope.$index, scope.row)"
            >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-row :gutter="10" class="mb8">
        <el-col :span="1.5">
          <el-button
            type="text"
            icon="Plus"
            size="mini"
            @click="skuModelEditAddLine"
          >新增型号</el-button>
        </el-col>
      </el-row>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button size="mini" @click="spuInfoUpdateDialog.visible = false">取消</el-button>
        <el-button size="mini" type="primary" @click="submitSpuInfoEdit">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  addSkuModelTask,
  deleteSkuModelTask,
  deleteSkuPriceControl,
  deleteSpu,
  editSkuPriceControl,
  exportOnSaleGoods,
  exportPriceControlTraceNotice,
  exportPriceNotice,
  getControlPriceOptions,
  getSkuModelInfoList,
  getSkuModelsMatchSkuUrlCount,
  getSpuSkuModelSelectList,
  getTechMatchTagOptions,
  getTechSkuPriceControlOptions,
  getTechSpuPriceControlOptions,
  getUserModelDefaultOptions,
  pageControlPrice,
  pageControlPriceTrace,
  pagePriceNotice,
  pageTechMatch,
  priceControlBatchConfirm,
  priceControlConfirm,
  queryLowControlPricePageList,
  querySkuModelPageList,
  querySpuInfo,
  queryTaskPageList,
  skuPriceControlTraceCancel,
  skuPriceControlTraceGenerate,
  techSpuPriceControlPageList,
  updatePriceNotice,
  updateSpu,
  updateTechMatch,
  getUserPlatformDefaultOptions
} from "@/api/eCommerce";
import DictSelect from "@/components/DictSelect";
import Pagination from "@/components/Pagination";
import { deepClone, parseTime } from "@/utils";
import { Notification } from "element-ui";
import InHandsPrice from "./InHandsPrice";

export default {
  name: "PriceAnalysis",
  components: { Pagination, DictSelect, InHandsPrice },
  data() {
    return {
      homeLoading: false,
      tabName: 'Low',
      dictFun: getControlPriceOptions,
      dictSkuModelsFun: getSkuModelInfoList,
      dictSpuFun: getTechSpuPriceControlOptions,
      dictSpuSkuModelsFun: getSpuSkuModelSelectList,
      dictPlatformFun: getTechSkuPriceControlOptions,
      inHandsPriceVisibile: false,
      pageGoodsData: {
        total: 0,
        rows: [],
        selections: [],
      },
      pageNoticeData: {
        total: 0,
        rows: [],
        selections: [],
      },
      pageTraceData: {
        total: 0,
        rows: [],
        selections: [],
      },
      pageLowData: {
        total: 0,
        rows: [],
        selections: [],
        tbCount: 0,
        jdCount: 0,
        pddCount: 0,
        dyCount: 0,
        totalCount: 0,
      },
      queryForm: {
        page: 1,
        limit: 10,
        orgNames: [],
        platforms: [],
        shopNames: [],
        raceCodes: [],
        mainCategoryCodes: [],
        midCategoryCodes: [],
        subCategoryCodes: [],
        ecCargoNos: [],
        controlPriceResult: undefined,
        warningPrice: undefined,
        diffPriceStart: undefined,
        diffPriceEnd: undefined,
        priceModifiedDate: this.calculateDefaultDateRange(),
        priceModifiedStartDate: undefined,
        priceModifiedEndDate: undefined,
        order: {
          column: undefined,
          operator: undefined
        },
        skuId: undefined,
        ifConfirm: undefined,
        ifCombined: undefined,
        itemId: undefined,
        traceControlPriceResult: undefined,
        traceSkuId: undefined,
        traceItemId: undefined,
        tag: '全部',
        lowPriceModifiedDate: this.calculateDefaultDateRange(),
      },
      goodsLoading: false,
      noticeLoading: false,
      traceLoading: false,
      lowLoading: false,
      dictParam: {},
      currentRow: {},
      currentIndex: undefined,
      options: {
        controlOptions: [],
      },
      parseTime: parseTime,
      rules: {
        diffPriceStart: [
          {
            validator: (rule, value, callback) => {
              if (value != undefined && this.queryForm.diffPriceStart != undefined && value < this.queryForm.diffPriceStart) {
                callback(new Error('最大值不能小于最小值'));
              } else {
                callback();
              }
            }, trigger: ["blur", "change"]
          }
        ],
      },
      skuModelUpdateDialog: {
        visible: false,
        form: {
          ptName: undefined,
          skuId: undefined,
          shopName: undefined,
          skuModelByPerson: undefined,
          skuSpecificationsByPerson: undefined,
          operateType: undefined,
          finalMatchSkuSpecifications: undefined,
          finalMatchSkuModel: undefined,
          skuPic: undefined,
          ifCombined: undefined,
          skuSpecificationsBySystem: undefined,
        },
        selectSkuModelOptions: [],
        selectSkuModelButton: undefined,
        skuModelName: '',
        shopSkuModelName: '',
      },
      skuModelUpdateOptions: [],
      createTaskDialog: {
        visible: false,
        form: {
          ecCargoNos: [],
          collectFrequency: '单次',
          executeTime: undefined,
          taskTimeRange: undefined,
        },
        matchSkuUrlCount: 0,
      },
      taskScheduleDialog: {
        visible: false,
        form: {
          page: 1,
          limit: 10,
          ecCargoNo: undefined,
        },
        taskData: [],
      },
      pickerOptions: {
        disabledDate: (time) => {
          return time.getTime() < Date.now() - 1 * 24 * 3600 * 1000
        }
      },
      tagOptions: [],
      priceControlListDialog: {
        visible: false,
        saleUnitKj: undefined,
        finalMatchSkuSpecifications: undefined,
        hitFlag: undefined,
        form: {
          page: 1,
          limit: 10,
          skuModel: undefined,
          ptName: undefined,
          discountMinUnitQuantity: undefined,
          ifCombined: undefined,
          controlPriceResult: undefined,
          controlTotalPrice: undefined,
        },
        priceControlList: {
          total: 0,
          rows: [],
        },
      },
      skuPriceControlUpdateDialog: {
        visible: false,
        title: "修改新增单品控价表",
        form: {
          ptName: undefined,
          skuModel: undefined,
          saleUnit: undefined,
          ifMinUnit: undefined,
          saleUnitMin: undefined,
          saleUnitMax: undefined,
          unitPrice: undefined,
          totalPrice: undefined,
          gift: undefined,
        },
        rules: {
          saleUnit: [{required: true, message: '售卖规格单位不能为空！', trigger: 'change'}],
          ifMinUnit: [{required: true, message: '售卖规格单位是否最小单位不能为空！', trigger: 'change'}],
          saleUnitMin: [{required: true, message: '售卖规格下限不能为空！', trigger: 'change'}],
          saleUnitMax: [{required: true, message: '售卖规格上限不能为空！', trigger: 'change'}],
        }
      },
      oldSkuPriceControl: {
        ptName: undefined,
        skuModel: undefined,
        saleUnit: undefined,
        ifMinUnit: undefined,
        saleUnitMin: undefined,
        saleUnitMax: undefined,
      },
      spuInfoUpdateDialog: {
        visible: false,
        form: {
          spuCode: undefined,
          spuName: undefined,
          tbSpuSellPrice: undefined,
          pddSpuSellPrice: undefined,
          jdSpuSellPrice: undefined,
          dySpuSellPrice: undefined,
          skuModelInfos:[],
        },
      },
      selectedRows: {}
    };
  },
  watch: {
  },
  created() {
    getTechMatchTagOptions().then(res => {
      this.tagOptions = res.data;
    });
    getUserModelDefaultOptions().then(res => {
      this.queryForm.ecCargoNos = res.data;
    });
    getUserPlatformDefaultOptions().then(res => {
      this.queryForm.platforms = res.data;
    });
  },
  mounted() {
  },
  methods: {
    openInHandsPriceDialog(row) {
      this.inHandsPriceVisibile = true;

      this.selectedRows = {...row};
    },
    handleQuery() {
      switch (this.tabName) {
        case 'Low':
          this.handleQueryLowControlPricePageList();
          break;
        case 'Goods':
          this.handleQueryGoods();
          break;
        case 'Notice':
          this.handleQueryNotice();
          break;
        case 'Trace':
          this.handleQueryTrace();
          break;
      }
    },
    handleQueryGoods() {
      this.goodsLoading = true;
      const query = Object.assign({}, this.queryForm);
      pageControlPrice(query).then(res => {
        if (res.code !== 200) {
          return false;
        }
        this.goodsLoading = false;
        this.pageGoodsData.rows = res.data?.datas;
        this.pageGoodsData.total = res.data?.total;
        this.$nextTick(() => {
          this.$refs.tableRef.doLayout();
        })
      });
    },
    handleQueryNotice() {
      this.noticeLoading = true;
      const query = Object.assign({}, this.queryForm);
      for (const key in this.queryForm) {
        if (this.queryForm[key]) {
          if (this.queryForm[key] instanceof Array) {
            if (key === 'priceModifiedDate') {
              query.priceModifiedStartDate = this.queryForm[key][0];
              query.priceModifiedEndDate = this.queryForm[key][1];
            }
          }
        }
      }
      pagePriceNotice(query).then(res => {
        if (res.code !== 200) {
          return false;
        }
        this.noticeLoading = false;
        this.pageNoticeData.rows = res.data?.datas;
        this.pageNoticeData.total = res.data?.total;
        this.$nextTick(() => {
          this.$refs.tableRef.doLayout();
        })
      });
    },
    handleSelectionChange(selections) {
      this.pageNoticeData.selections = selections;
    },
    handleFilter() {
      this.queryForm.page = 1;
      this.handleQuery();
    },
    resetQuery() {
      const queryForm = {
        page: 1,
        limit: 10,
        orgNames: [],
        platforms: [],
        shopNames: [],
        raceCodes: [],
        mainCategoryCodes: [],
        midCategoryCodes: [],
        subCategoryCodes: [],
        ecCargoNos: [],
        controlPriceResult: undefined,
        warningPrice: undefined,
        diffPriceStart: undefined,
        diffPriceEnd: undefined,
        priceModifiedDate: this.calculateDefaultDateRange(),
        priceModifiedStartDate: undefined,
        priceModifiedEndDate: undefined,
        order: {
          column: undefined,
          operator: undefined
        },
        skuId: undefined,
        ifConfirm: undefined,
        traceControlPriceResult: undefined,
        traceSkuId: undefined,
        traceItemId: undefined,
        tag: '全部',
        lowPriceModifiedDate: this.calculateDefaultDateRange(),
      };
      this.queryForm = Object.assign({}, queryForm);
      this.$forceUpdate();
    },
    handleSortChange(column) {
      this.queryForm.order.column = column.prop;
      this.queryForm.order.operator = column.order === "ascending" ?"ASC" : "DESC";
      this.handleFilter();
    },
    handleChangeLinkageRef(ref, val, type) {
      this.$nextTick(() => {
        ref.split(',').forEach(e => {
          if (this.$refs[e]) {
            setTimeout(() => {
              this.$refs[e].handleRefChange(val, type);
            }, 100)
            this.$forceUpdate();
          }
        })
      });
    },
    handleEdit(row, index) {
      this.currentRow = deepClone(row);
      this.currentIndex = index;
    },
    submitEdit(row, operateType) {
      updateTechMatch({...row, ...{ operateType: operateType }}).then(res => {
        if (res.code == 200) {
          this.$message.success("操作成功！");
          this.currentIndex = undefined;
          this.currentRow = undefined;
          this.handleFilter();
        }
      })
    },
    handleRowClick(row, type) {
      if (type === 'Goods') {
        this.queryForm.platforms= [row.ptName];
        this.$refs["shop"].remoteMethod('', true);
        this.queryForm.shopNames = [row.shopName];
        this.tabName = 'Notice';
        this.handleQueryNotice();
      }
    },
    handleExportGoods() {
      this.homeLoading = true;
      exportOnSaleGoods(this.queryForm).then(res => {
        if (res.code == 200) {
          if (res.data) {
            const link = document.createElement('a');
            link.href = res.data;
            link.download = "在售商品导出";
            link.target = "_blank"; // 可选，如果希望在新窗口中下载文件，请取消注释此行
            link.click()
          }else {
            Notification.info("无可下载数据！");
          }
        }
        this.homeLoading = false;
      })
    },
    handleExportNotice() {
      this.homeLoading = true;
      const query = Object.assign({}, this.queryForm);
      for (const key in this.queryForm) {
        if (this.queryForm[key]) {
          if (this.queryForm[key] instanceof Array) {
            if (key === 'priceModifiedDate') {
              query.priceModifiedStartDate = this.queryForm[key][0];
              query.priceModifiedEndDate = this.queryForm[key][1];
            }
          }
        }
      }
      exportPriceNotice(query).then(res => {
        if (res.data) {
          const link = document.createElement('a');
          link.href = res.data;
          link.download = "单品控价提醒导出";
          link.target = "_blank"; // 可选，如果希望在新窗口中下载文件，请取消注释此行
          link.click()
        } else {
          Notification.info("无可下载数据！");
        }
        this.homeLoading = false;
      })
    },
    handleUpdatePriceNotice(row) {
      this.timer = setTimeout(() => {
        updatePriceNotice({ remarkUpdateDtos: [row]}).then(res => {
          if (res.code==200) {
            Notification.success("操作成功");
            this.handleFilter();
          }
        })
      }, 1000)
    },
    handleBatchSaveNotice(row) {
      let delParam = [];
      if (row && row.skuId) {
        delParam.push({ skuId: row.skuId, ptName: row.ptName, remark: row.remark});
      } else if (this.pageNoticeData.selections.length > 0) {
        delParam = this.pageNoticeData.selections.map(e => {return { skuId: e.skuId, ptName: e.ptName, remark: null }});
      } else {
        Notification.warning("请至少选择一条数据进行操作！");
        return;
      }
      updatePriceNotice({ remarkUpdateDtos: delParam }).then((res) => {
        if (res.code == 200){
          Notification.success("操作成功！");
          this.handleFilter();
        }
      })
    },
    async handleJumpQuery(skuId) {
      // this.$emit('handleJumpQuery', skuId);
      var parameters = {
        page: 1,
        skuId: skuId,
      }
      const res = await pageTechMatch(parameters);
      const code = res.code;
      if (code === 200) {
        this.handleSkuModelEdit(res.data?.datas[0]);
      }else {
        return false;
      }

    },
    handleDiffPriceStart(){
      if(this.queryForm.diffPriceStart != undefined && this.queryForm.diffPriceEnd != undefined && this.queryForm.diffPriceStart > this.queryForm.diffPriceEnd){
        Notification.warning('最小值不能大于最大值');
        this.queryForm.diffPriceStart = undefined;
        this.queryForm.diffPriceEnd = undefined;
      }
    },
    handleDiffPriceEnd(){
      if(this.queryForm.diffPriceStart != undefined && this.queryForm.diffPriceEnd != undefined && this.queryForm.diffPriceStart > this.queryForm.diffPriceEnd){
        Notification.warning('最小值不能大于最大值');
        this.queryForm.diffPriceStart = undefined;
        this.queryForm.diffPriceEnd = undefined;
        this.$forceUpdate();
      }
    },
    handleMatchConfirm(row) {
      priceControlConfirm(row).then((res) => {
        if (res.code == 200){
          Notification.success("操作成功！");
          this.handleQuery();
        }
      })
    },
    handleBatchMatchConfirm(row) {
      let matchParam = [];
      if (row && row.skuId) {
        matchParam.push({ ptName: row.ptName, skuId: row.skuId, shopName: row.shopName, finalMatch: row.finalMatch, ifShowConfirm: row.ifShowConfirm});
      } else if (this.pageNoticeData.selections.length > 0) {
        matchParam = this.pageNoticeData.selections.map(e => {return { ptName: e.ptName, skuId: e.skuId, shopName: e.shopName, finalMatch: e.finalMatch, ifShowConfirm: e.ifShowConfirm}});
      } else {
        Notification.warning("请至少选择一条数据进行操作！");
        return;
      }
      matchParam = matchParam.filter(item => {
        return item.ifShowConfirm.indexOf('N') !== -1;
      });
      priceControlBatchConfirm({ matchBatchConfirms: matchParam }).then((res) => {
        if (res.code == 200){
          Notification.success("操作成功！");
          this.handleQuery();
        }
      })
    },
    handleSkuModelEdit(row) {
      this.rsetSkuModelUpdateDialogForm();
      this.skuModelUpdateDialog.visible = true;
      this.skuModelUpdateOptions = deepClone(row.skuModelInfoDtos);
      this.skuModelUpdateDialog.form = deepClone(row);
      if(row.finalMatchSkuModel){
        this.skuModelUpdateDialog.form.skuModelByPerson = row.finalMatchSkuModel;
        if(row.finalMatchSkuModelInfoDtos.length > 0){
          this.skuModelUpdateDialog.selectSkuModelOptions = row.finalMatchSkuModelInfoDtos[0].skuUrls;
          this.skuModelUpdateDialog.skuModelName = row.finalMatchSkuModelInfoDtos[0].skuModelName;
        }
        this.skuModelUpdateDialog.selectSkuModelButton = row.finalMatchSkuModel;
      }
      this.skuModelUpdateDialog.form.skuSpecificationsByPerson = row.skuSpecificationsByPerson;
      this.skuModelUpdateDialog.form.skuSpecificationsBySystem = row.skuSpecificationsBySystem;
      this.skuModelUpdateDialog.form.shopName = row.shopName;
      this.skuModelUpdateDialog.shopSkuModelName = row.skuName;
      const resp = this.skuModelUpdateDialog.form.skuModelByPerson
      const parameter = this.skuModelUpdateDialog.form.ptName;
      this.$nextTick(async () => {
        if (this.$refs.skuModel) {
          await this.$refs.skuModel.remoteMethod(resp);
        }
        if (this.$refs.ifSpu) {
          await this.$refs.ifSpu.remoteMethod(parameter)
        }
      });
    },
    rsetSkuModelUpdateDialogForm(){
      this.skuModelUpdateDialog.form = {
        ptName: undefined,
        skuId: undefined,
        shopName: undefined,
        skuModelByPerson: undefined,
        skuSpecificationsByPerson: 1,
        operateType: undefined,
        finalMatchSkuSpecifications: undefined,
        finalMatchSkuModel: undefined,
        skuPic: undefined,
      };
      this.skuModelUpdateDialog.selectSkuModelOptions = [];
      this.skuModelUpdateOptions = [];
      this.selectSkuModelButton = undefined;
      this.skuModelUpdateDialog.skuModelName = '';
      this.skuModelUpdateDialog.shopSkuModelName = '';
    },
    submitSkuModelEdit() {
      const argument = deepClone(this.skuModelUpdateDialog.form);
      argument.operateType = '1';
      updateTechMatch(argument).then(res => {
        if (res.code == 200) {
          this.$message.success("操作成功！");
          this.rsetSkuModelUpdateDialogForm();
          this.skuModelUpdateDialog.visible = false;
          this.handleQuery();
        }
      })
    },
    handSkuModelInfoChange(val){
      if(val===''){
        this.skuModelUpdateDialog.selectSkuModelOptions = [];
        this.skuModelUpdateDialog.selectSkuModelButton = undefined;
        this.skuModelUpdateDialog.skuModelName = '';
      }
      if(this.$refs.skuModel.itemOptions !== null){
        if(this.$refs.skuModel.itemOptions.length > 0){
          const obj = this.$refs.skuModel.itemOptions.find(e => e.skuModel === val);
          this.skuModelUpdateDialog.selectSkuModelOptions = obj.skuUrls;
          this.skuModelUpdateDialog.selectSkuModelButton = obj.skuModel;
          this.skuModelUpdateDialog.skuModelName = obj.skuModelName;
        }
      }
    },
    createSkuModelTask(){
      this.createTaskDialog.form = {
        ecCargoNos: [],
        collectFrequency: '单次',
        executeTime: undefined,
        taskTimeRange: undefined,
      }
      this.createTaskDialog.matchSkuUrlCount = 0;
      this.createTaskDialog.visible = true;
    },
    submitCreateSkuModelTask() {
      let param = {};
      for (const key in this.createTaskDialog.form) {
        if (this.createTaskDialog.form[key]) {
          if (this.createTaskDialog.form[key] instanceof Date) {
            param[key] = parseTime(this.createTaskDialog.form[key]);
          } else if (this.createTaskDialog.form[key] instanceof Array) {
            if (key === 'taskTimeRange') {
              param['startDate'] = this.createTaskDialog.form[key][0];
              param['endDate'] = this.createTaskDialog.form[key][1];
            }else {
              param[key] = this.createTaskDialog.form[key];
            }
          }else {
            param[key] = this.createTaskDialog.form[key];
          }
        }
      }
      addSkuModelTask(param).then(res => {
        if (res && res.code === 200) {
          Notification.success('操作成功！');
          this.createTaskDialog.visible = false;
          this.handleQuery();
        }
      });
    },
    handSkuModelChange(item){
      this.skuModelUpdateDialog.form.skuModelByPerson = item.skuModel;
      this.skuModelUpdateDialog.selectSkuModelOptions = item.skuUrls;
      this.skuModelUpdateDialog.selectSkuModelButton = item.skuModel;
      this.skuModelUpdateDialog.skuModelName = item.skuModelName;
    },
    handTaskSkuModelChange(val){
      if(val){
        if(val.length > 0){
          const argument = {
            ecCargoNos: val,
          }
          getSkuModelsMatchSkuUrlCount(argument).then(res => {
            if (res && res.code === 200) {
              this.createTaskDialog.matchSkuUrlCount = res.data;
            }
          });
        }
      }else {
        this.createTaskDialog.matchSkuUrlCount = 0;
      }
    },
    skuModelTaskScheduleQuery(){
      this.taskScheduleDialog.form = {
        ecCargoNos: [],
      }
      this.handleQueryTaskPages();
      this.taskScheduleDialog.visible = true;
    },
    handleQueryTaskPages() {
      const query = Object.assign({}, this.taskScheduleDialog.form);
      queryTaskPageList(query).then(res => {
        if (res.code !== 200) {
          return false;
        }
        // this.taskScheduleDialog.taskData.rows = res.data?.datas;
        // this.taskScheduleDialog.taskData.total = res.data?.total;
        this.taskScheduleDialog.taskData = res.data;
      });
    },
    deleteSkuModelTask(data) {
      const argument = Object.assign({}, data);
      deleteSkuModelTask(argument).then(res => {
        if (res.code !== 200) {
          return false;
        }
        this.handleQueryTaskPages();
      });
    },
    handleSkuPriceControlTraceGenerate() {
      let traceParam = [];
      if (this.pageNoticeData.selections.length > 0) {
        traceParam = this.pageNoticeData.selections.map(e => {return { ptName: e.ptName, itemId: e.itemId, skuId: e.skuId,
          shopName: e.shopName, currentSellPrice: e.currentSellPrice}});
      } else {
        Notification.warning("请至少选择一条数据进行操作！");
        return;
      }
      skuPriceControlTraceGenerate({ traceGenerateDtos: traceParam }).then((res) => {
        if (res.code == 200){
          Notification.success("操作成功！");
          this.handleQuery();
        }
      })
    },
    handleQueryTrace() {
      this.traceLoading = true;
      const query = Object.assign({}, this.queryForm);
      pageControlPriceTrace(query).then(res => {
        if (res.code !== 200) {
          return false;
        }
        this.traceLoading = false;
        this.pageTraceData.rows = res.data?.datas;
        this.pageTraceData.total = res.data?.total;
        this.$nextTick(() => {
          this.$refs.tableRef.doLayout();
        })
      });
    },
    handleTraceSelectionChange(selections) {
      this.pageTraceData.selections = selections;
    },

    handleSkuPriceControlTraceCancel() {
      let traceCancelParam = [];
      if (this.pageTraceData.selections.length > 0) {
        traceCancelParam = this.pageTraceData.selections.map(e => {return { ptName: e.ptName, itemId: e.itemId, skuId: e.skuId,
          shopName: e.shopName, userName: e.userName}});
      } else {
        Notification.warning("请至少选择一条数据进行操作！");
        return;
      }
      skuPriceControlTraceCancel({ traceCancelDtos: traceCancelParam }).then((res) => {
        if (res.code == 200){
          Notification.success("操作成功！");
          this.handleQuery();
        }
      })
    },
    queryPriceControlList(row){
      this.resetPriceControlListDialogForm();
      this.priceControlListDialog.saleUnitKj = row.saleUnitKj;
      this.priceControlListDialog.finalMatchSkuSpecifications = row.finalMatch.split('\n').length > 1?row.finalMatch.split('\n')[1]:'';
      this.priceControlListDialog.form.skuModel = row.skuModel;
      this.priceControlListDialog.form.ptName = row.ptName;
      this.priceControlListDialog.form.discountMinUnitQuantity = row.discountMinUnitQuantity;
      this.priceControlListDialog.form.ifCombined = row.ifCombined;
      this.priceControlListDialog.form.controlPriceResult = row.controlPriceResult;
      this.priceControlListDialog.form.controlTotalPrice = row.controlTotalPrice;
      if(row.ifCombined === '是' || row.ifCombined === '否'){
        this.handleQuerySkuModelPageList();
      } else if(row.ifCombined !== '是' || row.ifCombined !== '否'){
        this.handleQuerySpuPageList();
      }
    },
    handleQuerySkuModelPageList() {
      const query = Object.assign({}, this.priceControlListDialog.form);
      querySkuModelPageList(query).then(res => {
        if (res.code !== 200) {
          return false;
        }
        this.priceControlListDialog.priceControlList.rows = res.data?.datas;
        if(this.priceControlListDialog.priceControlList.rows.length > 0){
          this.priceControlListDialog.hitFlag = this.priceControlListDialog.priceControlList.rows[0].hitFlag;
        }
        this.priceControlListDialog.priceControlList.total = res.data?.total;
        this.priceControlListDialog.visible = true;
        if(this.$refs.priceControlListTable){
          this.$refs.priceControlListTable.doLayout()
        }
      });
    },
    resetPriceControlListDialogForm(){
      this.priceControlListDialog.form = {
        page: 1,
        limit: 10,
        skuModel: undefined,
        ptName: undefined,
        discountMinUnitQuantity: undefined,
        ifCombined: undefined,
        controlPriceResult: undefined,
        controlTotalPrice: undefined,
      };
      this.priceControlListDialog.visible = false;
      this.priceControlListDialog.saleUnitKj = undefined;
      this.priceControlListDialog.finalMatchSkuSpecifications = undefined;
      this.priceControlListDialog.hitFlag = undefined;
      this.priceControlListDialog.priceControlList = {
        total: 0,
          rows: [],
      }
    },
    handleDeleteSkuPriceControl(data) {
      const argument = Object.assign({}, data);
      this.$confirm('请确认是否删除该数据！', '提示', { type: 'warning' }).then(() => {
        deleteSkuPriceControl(argument).then(res => {
          if (res.code !== 200) {
            return false;
          }
          this.handleQuerySkuModelPageList();
        });
      }).catch(() => {
      })
    },
    handleSkuPriceControlEdit(data) {
      this.rsetSkuPriceControlUpdateDialogForm();
      this.skuPriceControlUpdateDialog.visible = true;
      this.skuPriceControlUpdateDialog.form = deepClone(data);
      this.oldSkuPriceControl = deepClone(data);
    },
    rsetSkuPriceControlUpdateDialogForm(){
      this.skuPriceControlUpdateDialog.form = {
        ptName: undefined,
        skuModel: undefined,
        saleUnit: undefined,
        ifMinUnit: undefined,
        saleUnitMin: undefined,
        saleUnitMax: undefined,
        unitPrice: undefined,
        totalPrice: undefined,
        gift: undefined,
      };
      this.oldSkuPriceControl = {
        ptName: undefined,
        skuModel: undefined,
        saleUnit: undefined,
        ifMinUnit: undefined,
        saleUnitMin: undefined,
        saleUnitMax: undefined,
      };
      this.skuPriceControlUpdateDialog.visible = false;
    },
    submitSkuPriceControlEdit() {
      const argument = deepClone(this.skuPriceControlUpdateDialog.form);
      argument.oldSpuModel = this.oldSpuModel;
      argument.oldSkuPriceControl = this.oldSkuPriceControl;
      editSkuPriceControl(argument).then(res => {
        if (res.code === 200) {
          this.$message.success("操作成功！");
          this.rsetSkuPriceControlUpdateDialogForm();
          this.skuPriceControlUpdateDialog.visible = false;
          this.handleQuerySkuModelPageList();
        }
      })
    },

    handleQuerySpuPageList() {
      var parameters = {
        spuCode: this.priceControlListDialog.form.ifCombined
      }
      techSpuPriceControlPageList(parameters).then(res => {
        if (res.code !== 200) {
          return false;
        }
        this.priceControlListDialog.priceControlList.rows = res.data?.datas;
        this.priceControlListDialog.visible = true;
        if(this.$refs.spuPriceControlListTable){
          this.$refs.spuPriceControlListTable.doLayout()
        }
      });
    },
    handleDeleteSpu(data) {
      const argument = Object.assign({}, data);
      this.$confirm('请确认是否删除该数据！', '提示', { type: 'warning' }).then(() => {
        deleteSpu(argument).then(res => {
          if (res.code !== 200) {
            return false;
          }
          this.handleQuerySpuPageList();
        });
      }).catch(() => {
      })
    },
    handleSpuEdit(data) {
      this.rsetSpuInfoUpdateDialogForm();
      this.spuInfoUpdateDialog.visible = true;
      const argument = deepClone(data);
      querySpuInfo(argument).then(res => {
        if (res.code === 200) {
          this.spuInfoUpdateDialog.form = deepClone(res.data);
        }else {
          Notification.error(res.msg);
        }
      })
    },
    rsetSpuInfoUpdateDialogForm(){
      this.spuInfoUpdateDialog.form = {
        spuCode: undefined,
        spuName: undefined,
        tbSpuSellPrice: undefined,
        pddSpuSellPrice: undefined,
        jdSpuSellPrice: undefined,
        dySpuSellPrice: undefined,
        skuModelInfos:[],
      };
      this.spuInfoUpdateDialog.visible = false;
    },
    submitSpuInfoEdit() {
      const argument = deepClone(this.spuInfoUpdateDialog.form);
      updateSpu(argument).then(res => {
        if (res.code === 200) {
          this.$message.success("操作成功！");
          this.rsetSpuInfoUpdateDialogForm();
          this.spuInfoUpdateDialog.visible = false;
          this.handleQuerySpuPageList();
        }
      })
    },
    skuModelEditAddLine(){
      var newValue = {
        skuModel: undefined,
        skuSpecifications: undefined,
        skuSpecificationsQuantity: undefined,
        ifGift: '否',
      };
      this.spuInfoUpdateDialog.form.skuModelInfos.push(newValue);
    },
    skuModelEditDeleteLine(index){
      this.spuInfoUpdateDialog.form.skuModelInfos.splice(index, 1)
    },
    tableRowClassName({row, rowIndex}) {
      if (row.hitFlag === 'Y') {
        return 'warning-row';
      }
      return '';
    },
    handlePriceControlTraceExportNotice() {
      this.homeLoading = true;
      const query = Object.assign({}, this.queryForm);
      exportPriceControlTraceNotice(query).then(res => {
        if (res.data) {
          const link = document.createElement('a');
          link.href = res.data;
          link.download = "控价追踪导出";
          link.target = "_blank"; // 可选，如果希望在新窗口中下载文件，请取消注释此行
          link.click()
        } else {
          Notification.info("无可下载数据！");
        }
        this.homeLoading = false;
      })
    },
    handleQueryLowControlPricePageList() {
      this.lowLoading = true;
      const query = Object.assign({}, this.queryForm);
      for (const key in this.queryForm) {
        if (this.queryForm[key]) {
          if (this.queryForm[key] instanceof Array) {
            if (key === 'lowPriceModifiedDate') {
              query.priceModifiedStartDate = this.queryForm[key][0];
              query.priceModifiedEndDate = this.queryForm[key][1];
            }
          }
        }
      }
      queryLowControlPricePageList(query).then(res => {
        if (res.code !== 200) {
          return false;
        }
        this.lowLoading = false;
        this.pageLowData.rows = res.data?.datas;
        this.pageLowData.total = res.data?.total;
        this.pageLowData.tbCount = res.data?.tbCount;
        this.pageLowData.jdCount = res.data?.jdCount;
        this.pageLowData.pddCount = res.data?.pddCount;
        this.pageLowData.dyCount = res.data?.dyCount;
        this.pageLowData.totalCount = res.data?.totalCount;
        this.$nextTick(() => {
          this.$refs.lowTableRef.doLayout();
        })
      });
    },
    handleLowControlPriceClick(skuModel, ptName, type) {
      if (type === 'Low') {
        if(ptName){
          this.queryForm.platforms = [ptName];
        }
        this.$refs["shop"].remoteMethod('', true);
        this.queryForm.controlPriceResult = '价格低于控价';
        this.queryForm.ecCargoNos = [skuModel];
        this.tabName = 'Notice';
        this.handleQueryNotice();
      }
    },
    calculateDefaultDateRange() {
      const start = this.getDateBefore(7);
      const end = this.getToday();
      return [start, end];
    },
    getDateBefore(days) {
      const date = new Date();
      date.setDate(date.getDate() - days);
      return this.formatDate(date);
    },
    getToday() {
      return this.formatDate(new Date());
    },
    formatDate(date) {
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
    }
  },
};
</script>


<style lang="scss" scoped>

::v-deep .el-table__header .el-table-column--selection .cell .el-checkbox:after {
  content: "本页全选";
  color: black;
  font-weight: bold;
  font-size: 12px;
  margin-left: 5px;
}

.button-online {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.selected {
  color: #409EFF;
  border-color: #c6e2ff;
  background-color: #ecf5ff;
}

.background-box {
  background-color: #c7c70a;
  border: 1px solid #ccc;
  padding: 10px 20px;
  display: inline-block;
}

.spuInfoAddDialog {
  ::v-deep {
    .el-dialog__body .el-form-item--mini .el-form-item__label {
      text-align: left;
    }
  }
}
::v-deep.el-table .warning-row {
  background: #fac858;
}
</style>
