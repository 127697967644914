<template>
  <div style="padding: 20px">
    <div class="mg-search" style="padding-bottom: 15px">
      <el-form ref="queryFormRef" size="mini" label-width="auto" label-position="right" :inline="true" :model="queryForm" @keyup.enter.native="handleQuery">
        <el-form-item v-if="!defaultOrgCode" label="分公司">
          <DictSelect
            ref="org"
            type="org"
            :value.sync="queryForm.orgCode"
            linkageRef="dc"
            init
            :initOptions="orgDictInitOptions"
            fullLabel
            clearable
            placeholder="请选择分公司"
            @changeLinkageRef="handleQueryChangeLinkageRef"
          />
        </el-form-item>
        <el-form-item label="配送中心">
          <DictSelect
            ref="dc"
            type="dc"
            :value.sync="queryForm.dcCode"
            :dictFun="dictFun"
            parentLinkageRef
            fullLabel
            @getOtherJsonParam="(callback) => callback(queryForm)"
          />
        </el-form-item>
        <el-form-item label="">
          <el-input v-model="queryForm.allName" placeholder="请输入用户账号或姓名" clearable />
        </el-form-item>
        <el-form-item>
          <el-button class="form-btn-item" size="mini" icon="el-icon-search" type="primary" @click="handleFilter">查询</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-refresh" type="primary" @click="resetQuery">重置</el-button>
          <el-button v-if="orgDictInitOptions.length > 0" class="form-btn-item" size="mini" icon="el-icon-plus" type="primary" @click="handleEdit">新增组织人员</el-button>
          <el-button v-if="orgDictInitOptions.length > 0" class="form-btn-item" size="mini" icon="el-icon-upload" type="primary" @click="() => {
            importChooseDialog.visible= true;
            importChooseDialog.importAction = 'add';
          }">分公司组织导入</el-button>
          <el-button v-if="orgDictInitOptions.length > 0" class="form-btn-item" size="mini" icon="el-icon-download" type="primary" @click="handleExport">分公司组织导出</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :data="pageData.rows"
      height="calc(100vh - 150px)"
      v-loading="loading"
      size="mini"
      border
      :header-cell-style="{ background: 'rgb(227 224 224)', color: 'black' }"
      row-class-name="rowClassName"
      @cell-click="handleCellClick"
      :span-method="objectSpanMethod"
    >
      <el-table-column label="分公司" prop="orgCode" width="100">
        <template #default="{ row }">
          <span>{{ row.orgCode + "-" + row.orgName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="总经理" prop="name1" width="150" align="center" class-name="columnClass">
        <template #default="{ row }">
          <div style="color: #0040ff;vertical-align: middle;" v-if=" row.userName1">
            <div v-for="(item, index) in row.userName1.split(',')" :key="index">
              <span>{{ item + '-' + row.name1.split(',')[index]}}</span><br>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="大区经理" prop="name2" width="150" align="center">
        <template #default="{ row }">
          <div style="color: #0040ff;vertical-align: middle;" v-if=" row.userName2">
            <div v-for="(item, index) in row.userName2.split(',')" :key="index">
              <span>{{ item + '-' + row.name2.split(',')[index]}}</span><br>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="配送中心" prop="dcCode" width="220" align="center">
        <template #default="{ row }">
          <span>{{ row.dcCode ? (row.dcCode + "-" + (row.dcName? row.dcName: '')) : '' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="配送中心负责人" prop="name3" width="150" align="center">
        <template #default="{ row }">
          <div  v-if=" row.userName3" style="color: #0040ff;vertical-align: middle;">
            <div v-for="(item, index) in row.userName3.split(',')" :key="index">
              <span>{{ item + '-' + row.name3.split(',')[index]}}</span><br>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="区域经理" prop="name4" width="150" align="center">
        <template #default="{ row }">
          <div style="color: #0040ff;vertical-align: middle;" v-if="row.name4">
            <div v-for="(item, index) in row.userName4.split(',')" :key="index">
              <span>{{ item + '-' + row.name4.split(',')[index]}}</span><br>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="业务经理" prop="name5" width="150" align="center">
          <template #default="{ row }">
            <div style="color: #0040ff;vertical-align: middle;" v-if=" row.userName5">
              <div v-for="(item, index) in row.userName5.split(',')">
                <span>{{ item + '-' + row.name5.split(',')[index]}}</span><br>
              </div>
            </div>
          </template>
        </el-table-column>
      <el-table-column label="业务员账号" prop="name6" width="150" align="center">
        <template #default="{ row }">
          <span style="color: #0040ff" v-if="row.name6">
            <span v-for="(item,index) in row.name6.split(',')" :key="item">
             {{ row.userName6.split(',')[index] ? row.userName6.split(',')[index] + '-': '' }} {{item}}<br>
            </span>
          </span>
        </template>
      </el-table-column>
    </el-table>
    <Pagination :total="pageData.total" :page.sync="queryForm.current" :limit.sync="queryForm.size" @pagination="handleQuery" />

    <el-dialog :title="editDialog.form.id?'编辑组织人员':'新增组织人员'" :visible.sync="editDialog.visible" append-to-body width="400px" :close-on-click-modal="false">
      <el-form ref="editFormRef" size="mini" label-width="80px" label-position="right" :inline="true" :model="editDialog.form" :rules="editDialog.rules">
        <el-form-item label="分公司" prop="orgCode">
          <DictSelect
            ref="org"
            type="org"
            :disabled="editDialog.form.id!==undefined"
            :value.sync="editDialog.form.orgCode"
            linkageRef="dc"
            init
            :initOptions="orgDictInitOptions"
            fullLabel
            :clearable="false"
            placeholder="请选择分公司"
            @changeLinkageRef="handleChangeLinkageRef"
          />
        </el-form-item>
        <el-form-item label="岗位" prop="positionId">
          <el-select v-model="editDialog.form.positionId" @change="val => handPositionChange(val, 'position')">
            <el-option v-for="item in positionOptions" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item v-show="editDialog.form.positionId >= 3" label="配送中心" prop="dcCode">
          <el-select v-show="editDialog.form.positionId && editDialog.form.positionId === 3" v-model="editDialog.form.dcCode" filterable clearable @change="val => handPositionChange(val, 'dc')">
            <el-option v-for="item in allDcOptions" :key="item.value" :label="item.dcCode + '-' + item.dcName" :value="item.dcCode" />
          </el-select>
          <el-select v-show="editDialog.form.positionId && editDialog.form.positionId > 3" v-model="editDialog.form.dcCode" filterable clearable @change="val => handPositionChange(val, 'dc')">
            <el-option v-for="item in branchDcOptions" :key="item.value" :label="item.value + '-' + item.label" :value="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item v-show="editDialog.form.positionId > 1" label="选择上级">
          <el-select v-model="editDialog.form.parentId" filterable @change="val => {$forceUpdate();}">
            <el-option v-for="item in parentOptions" :key="item.id" :label="item.userName + (item.nickName ? '-' + item.nickName: '')" :value="item.id" />
          </el-select>
        </el-form-item>
        <el-form-item v-if="editDialog.form.positionId<6" label="是否主要负责人" label-width="120px">
          <el-radio-group v-model="editDialog.form.principal" :disabled="editDialog.principalDisabled && editDialog.form.positionId<5 && editDialog.form.positionId !==2"
                          @input="() => {handleGetDeputy(); $forceUpdate();}">
            <el-radio :label="1">是</el-radio>
            <el-radio :label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="editDialog.form.positionId > 1 && editDialog.form.positionId<6 && editDialog.form.principal!==1" label="负责人">
          <el-select v-model="editDialog.form.principalId" filterable clearable @change="val => {$forceUpdate();}">
            <el-option v-for="item in editDialog.deputyList" :key="item.id" :label="item.nickName + (item.positionName ? '-' + item.positionName: '')" :value="item.id" />
          </el-select>
        </el-form-item>
        <el-form-item label="新增用户">
          <DictSelect
            ref="user"
            type="user"
            :disabled="editDialog.form.id!==undefined"
            :value.sync="editDialog.form.userName"
            :dictFun="dictUserFun"
            fullLabel
            remote
            version="v3"
            placeholder="请输入用户账号或姓名"
            :init-props="{ label: 'nickName', value: 'userName'}"
            @getOtherJsonParam="(callback) => callback({ pageSize: 50, pageNum: 1})"
            @change="handleRespChange"
          />
        </el-form-item>
        <el-form-item label="岗位名称">
          <el-input v-model="editDialog.form.positionName" placeholder="请输入岗位名称" clearable @input="val => {
            editDialog.form.positionName = val
            $forceUpdate();
          }" />
        </el-form-item>
        <el-form-item label="团队名称">
          <el-input v-model="editDialog.form.teamName" placeholder="请输入团队名称" clearable @input="val => {
            editDialog.form.teamName = val
            $forceUpdate();
          }"  />
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="editDialog.form.remark" type="textarea" placeholder="请输入备注" clearable @input="val => {
            editDialog.form.remark = val
            $forceUpdate();
          }"  />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button :disabled="buttonLoading" type="primary" size="mini" @click="handleSave">确定</el-button>
        <el-button type="primary" size="mini" @click="editDialog.visible = false">关闭</el-button>
      </div>
    </el-dialog>

    <el-dialog title="岗位人员" :visible.sync="viewDialog.visible" append-to-body width="1000px" :close-on-click-modal="false">
      <el-table
        ref="viewTableRef"
        :data="viewDialog.list"
        height="500px"
        v-loading="viewDialog.loading"
        size="mini"
        border
        style="margin-top: 5px"
      >
        <el-table-column v-if="viewDialog.queryParam.positionId < 6" label="主负责人" prop="orgName" width="80">
          <template #default="{ row }">
            <span :style="row.principal===1 && row.positionId <6? {'font-weight': 600}: ''">{{ row.principal===1?'是':'否' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="用户" prop="nickName" width="150">
          <template #default="{ row }">
            <span :style="row.principal===1 && row.positionId <6? {'font-weight': 600}: ''">{{ row.userName + '-' + row.nickName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="关联组织" prop="orgName" width="200" >
          <template #default="{ row }">
            <span :style="row.principal===1 && row.positionId <6? {'font-weight': 600}: ''" v-if="row.positionId<3">{{ row.orgCode + '-' + row.orgName }}</span>
            <span v-else :style="row.principal===1 && row.positionId <6? {'font-weight': 600}: ''" >{{ row.dcCode ? (row.dcCode + "-" + (row.dcName? row.dcName: '')) : '' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="上级" prop="parentName" width="150">
          <template #default="{ row }">
            <span :style="row.principal===1 && row.positionId <6? {'font-weight': 600}: ''">{{ (row.parentUserName && row.parentName) ? (row.parentUserName + '-' + row.parentName): '' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="岗位" prop="positionName" width="100">
          <template #default="{ row }">
            <span :style="row.principal===1 && row.positionId <6? {'font-weight': 600}: ''">{{ row.positionName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="备注" prop="remark" show-overflow-tooltip>
          <template #default="{ row }">
            <span :style="row.principal===1 && row.positionId <6? {'font-weight': 600}: ''">{{ row.remark }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="180">
          <template #default="{ row }">
            <el-button size="mini" type="text" style="border: 0px;" @click="handleEdit(row)">编辑</el-button>
            <el-button size="mini" type="text" style="border: 0px;" @click="handleDelRow(row)">删除</el-button>
            <el-button v-if="row.positionId < 6" :disabled="row.principal===1 && row.positionId <6" size="mini" type="text" style="border: 0px;" @click="handleChangeMain(row)">设为主负责人</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="text-align: center;border: 1px dashed skyblue;"> <el-button size="mini" type="text" style="border: 0px; width: 100%" @click="handleAdd">新增</el-button></div>
      <div slot="footer" class="dialog-footer" style="text-align: right">
<!--        <el-button type="primary" size="mini" @click="handleSave">确定</el-button>-->
        <el-button type="primary" size="mini" @click="viewDialog.visible = false">关闭</el-button>
      </div>
    </el-dialog>

    <el-dialog title="分公司组织导入" :visible.sync="importChooseDialog.visible" append-to-body width="500px" :close-on-click-modal="false">
      <div>
        <span style="font-weight: 600">导入方式：</span>
        <el-radio-group v-model="importChooseDialog.importAction">
          <el-radio label="add">新增
          <div style="color: gray !important;margin:10px">
            每个管理层节点第一个人默认为主负责人，其他和后续新增均为助理。
          </div></el-radio>
          <el-radio label="override">覆盖
            <div style="color: gray !important;margin:15px">
            将会清空本分公司所有组织数据，以本次导入为准。
          </div></el-radio>
        </el-radio-group>
      </div>
      <div slot="footer" class="dialog-footer" style="text-align: right">
                <el-button type="primary" size="mini" @click="handleImport">确定</el-button>
        <el-button type="primary" size="mini" @click="importChooseDialog.visible = false">关闭</el-button>
      </div>
    </el-dialog>

    <ImportDialog :importBaseParams="importBaseParams(importDialog.importBaseParams)" @handleImportCallBack="handleImportData" append-to-body/>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import { pageOrgBranch, dictSelect, saveOrgBranch, checkMain, listBranch, delBranch, importBranch, exportBranch, branchDict, userSelect, changeMain } from "@/api/appTask";
import DictSelect from "@/components/DictSelect";
import { deepClone, excelExport } from "@/utils";
import {Notification} from "element-ui";

export default {
  name: "OrgBranch",
  components: { Pagination, DictSelect, ImportDialog: () => import("@/components/ImportDialog") },
  data() {
    return {
      pageData: {
        total: 0,
        rows: [],
        selections: [],
      },
      queryForm: {
        current: 1,
        size: 10,
        orgCode: undefined,
        allName: undefined,
      },
      loading: false,
      dictFun: dictSelect,
      dictUserFun: userSelect,
      orgDictInitOptions: [],
      positionOptions: [
        { value: 1, label : '总经理'},
        { value: 2, label : '大区经理'},
        { value: 3, label : '配送中心负责人'},
        { value: 4, label : '区域经理'},
        { value: 5, label : '业务经理'},
        { value: 6, label : '业务员'},
      ],
      allDcOptions: [],
      branchDcOptions: [],
      parentOptions: [],
      defaultOrgCode: undefined,
      editDialog: {
        visible: false,
        principalDisabled: true,
        rules: {
          orgCode: [{ required: true, message: ' ', trigger: 'change' },],
          positionId: [{ required: true, message: ' ', trigger: 'change' },],
          positionName: [{ required: true, message: ' ', trigger: 'change' },],
          dcCode: [{ required: true, message: ' ', trigger: 'change' },],
        },
        deputyList: [],
        form: {
          orgCode: undefined,
          dcCode: undefined,
          positionId: undefined,
          userName: undefined,
          nickName: undefined,
          positionName: undefined,
          teamName: undefined,
          principal: 0,
          parentId: undefined,
          principalId: undefined,
          remark: undefined,
          parentName: undefined,
          parentPositionName: undefined,
        }
      },
      exportHeaders:
        {
          orgCode: '分公司编码',
          manageUser: '总经理',
          regionUser: '大区经理',
          realDcCode: '配送中心',
          dcUser: '配送中心负责人',
          areaUser: '区域经理',
          businessManager: '业务经理',
          businessUser: '业务员',
          errorMsg: '校验信息',
        },
      importDialog: {
        currentImportType: "orgBranch",
        importBaseParams: [
          {
            visible: false,
            title: "分公司组织导入",
            type: "orgBranch",
            tipsText: "<li>每个岗位可以有多个人员(逗号隔开)，每个管理层节点第一个人默认为主负责人其他和后续新增均为助理。</li><br><li>表格填写均为聚宝盆数字账号。</li>",
            templateSampleHead: [{label: "分公司编码", prop: "orgCode", minWidth: "80px"},
              {label: "总经理", prop: "manageUser", minWidth: "80px"},
              {label: "大区经理", prop: "regionUser", minWidth: "80px"},
              {label: "配送中心", prop: "realDcCode", minWidth: "80px"},
              {label: "配送中心负责人", prop: "dcUser", minWidth: "80px"},
              {label: "区域经理", prop: "areaUser", minWidth: "80px"},
              {label: "业务经理", prop: "businessManager", minWidth: "80px"},
              {label: "业务员", prop: "businessUser", minWidth: "80px"},],
            templateSampleLists: [
              {
                orgCode: '099',
                manageUser: '总经理账号',
                regionUser: '大区经理账号',
                realDcCode: '配送中心编码',
                dcUser: '配送中心负责人账号',
                areaUser: '区域经理账号',
                businessManager: '业务经理账号',
                businessUser: '业务员账号'
              }
            ],
            previewDataLength: 10,
            apiParams: "importList",
            templateFileDownloadUrl: process.env.VUE_APP_OSS_TEMPLATE_PATH + '/%E5%88%86%E5%85%AC%E5%8F%B8%E7%BB%84%E7%BB%87%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx',
          }
        ],
      },
      importChooseDialog: {
        visible: false,
        importAction: 'add',
      },
      proMap: {
        name1: 1,
        name2: 2,
        name3: 3,
        name4: 4,
        name5: 5,
        name6: 6,
      },
      principalMap: {
        name1: 'p1Id',
        name2: 'p2Id',
        name3: 'p3Id',
        name4: 'p4Id',
        name5: 'p5Id',
        name6: 'p6Id',
      },
      viewDialog: {
        visible: false,
        loading: false,
        queryParam: {
          orgCode: undefined,
          dcCode: undefined,
          positionId: undefined,
        },
        list: [],
      },
      buttonLoading: false,
      colFields: ['orgCode','p1Id', 'p2Id','dcCode','p3Id','p4Id', 'p5Id', 'p6Id'],
      spanArr: []
    };
  },
  computed: {
    importBaseParams() {
      const importDialog = this.importDialog;
      return importParams => {
        return importParams.find(item => item.type === importDialog.currentImportType);
      };
    },
  },
  created() {
    dictSelect('org').then(res => {
      this.orgDictInitOptions = res.data;
      if (res.data.length > 0) {
        if (res.data.length === 1) {
          this.defaultOrgCode = res.data[0].value;
          this.queryForm.orgCode = this.defaultOrgCode;
          this.$refs['dc'].handleRefChange(this.defaultOrgCode, 'auto');
        }
        this.handleFilter();
      }
    });
  },
  mounted() {

  },
  methods: {
    handleQuery() {
      this.loading = true;
      const query = Object.assign({}, this.queryForm);
      pageOrgBranch(query).then(res => {
        if (!res.data) {
          return false;
        }
        this.getSpanArr(res.data.records);
        this.loading = false;
        this.pageData.rows = res.data?.records;
        this.pageData.total = res.data?.total;
      });
    },
    handleFilter() {
      this.queryForm.current = 1;
      this.handleQuery();
    },
    resetQuery() {
      const queryForm = {
        current: 1,
        size: 10,
      };
      this.queryForm = Object.assign({}, queryForm);
      this.$forceUpdate();
    },
    getSpanArr(list) {
      for (let i = 0; i < list.length; i++) {
        let row = i;
        // let col = i % this.colCount;
        if (row === 0) {
          // i 表示行 j表示列
          for (let j = 0; j < this.colFields.length; j++) {
            this.spanArr[i * this.colFields.length + j] = {
              rowspan: 1,
              colspan: 1,
            };
          }
        } else {
          for (let j = 0; j < this.colFields.length; j++) {
            // 当前和上一次的一样
            // 1. 合并所有列的相同数据单元格
            if (
              list[row][this.colFields[j]] ===
              list[row - 1][this.colFields[j]]
            ) {
              let beforeItem =
                this.spanArr[(row - 1) * this.colFields.length + j];
              this.spanArr[row * this.colFields.length + j] = {
                rowspan: 1 + beforeItem.rowspan, // 合并几行
                colspan: 1, // 合并几列,我这里只是跨行合并,不跨列合并,所以用的1
              };
              beforeItem.rowspan = 0;
              beforeItem.colspan = 0;
            } else {
              // rowspan 和 colspan 都为1表格此单元格不合并
              this.spanArr[row * this.colFields.length + j] = {
                rowspan: 1,
                colspan: 1,
              };
            }
          }
        }
      }
      // 对数据进行倒序
      let stack = [];
      for (let i = 0; i < this.colFields.length; i++) {
        for (let j = 0; j < list.length; j++) {
          // console.log("i=" + i + " j=" + j);
          // i 表示列 j表示行
          if (j === 0) {
            if (this.spanArr[j * this.colFields.length + i].rowspan === 0) {
              stack.push(this.spanArr[j * this.colFields.length + i]);
            }
          } else {
            if (this.spanArr[j * this.colFields.length + i].rowspan === 0) {
              stack.push(this.spanArr[j * this.colFields.length + i]);
            } else {
              stack.push(this.spanArr[j * this.colFields.length + i]);
              while (stack.length > 0) {
                let pop = stack.pop();
                let len = stack.length;
                this.spanArr[(j - len) * this.colFields.length + i] = pop;
              }
            }
          }
        }
      }
      // console.log(this.spanArr);
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0 || columnIndex ==3 || columnIndex == 7) {
            return {
              rowspan: 1,
              colspan: 1
            }
          }
      // console.log(this.spanArr[rowIndex * this.colFields.length + columnIndex]);
      return this.spanArr[rowIndex * this.colFields.length + columnIndex];
    },
    setDataSpan(list) {
      for (let i = 0; i < list.length; i++) {
        for (let j = 1; j < 7; j++) {
          list[i]['userNameSpan' + j] = {rowspan: 1, colspan: 1}
          if (i > 0) {
            try {
              if (list[i]['userName' + j] === list[i - 1]['userName' + j]) {
                list[i]['userNameSpan' + j] = {
                  rowspan: list[i - 1]['userNameSpan' + j].rowspan + 1,
                  colspan: 1
                }
                list[i - 1]['userNameSpan' + j] = {
                  rowspan: 0,
                  colspan: 1
                }
              }
            } catch (e) {
              console.log(e, i , j)
            }
          }
        }
      }
    },
    // objectSpanMethod({ row, column, rowIndex, columnIndex }) {
    //   if (columnIndex === 0 || columnIndex ==3) {
    //     return {
    //       rowspan: 1,
    //       colspan: 1
    //     }
    //   }
    //   return this.spanArr[rowIndex][columnIndex]
    // },
    handleEdit(row) {
      if (row) {
        if (row.positionId > 3) {
          branchDict('branch-dc?query=' + row.orgCode).then(res => {
            this.branchDcOptions = res.data;
          })
        } else if (row.positionId === 3) {
          branchDict('all-dc?query=' + row.orgCode).then(res => {
            this.allDcOptions = res.data;
          })
        }
        if (row.positionId) {
          row.positionName = this.positionOptions.find(e => e.value === row.positionId).label;
        }
        if (row.positionId > 1) {
          let param = {
            positionId: row.positionId -1,
            dcCode: row.dcCode,
            orgCode: row.orgCode,
            principal: 1,
          };
          if (param.positionId < 3) {
            param.dcCode = 'ALL';
          }
          listBranch(param).then(res => {
            this.parentOptions = res.data;
          })
        }
        const resp = row.userName?row.userName: '';
        this.$nextTick(() => {
          this.getUser(resp, row);
        })
        this.editDialog.form = deepClone(row);
        this.handleGetDeputy(row);
        this.$forceUpdate();
      } else {
        const data = {
          orgCode: this.defaultOrgCode,
          dcCode: undefined,
          positionId: undefined,
          userName: undefined,
          nickName: undefined,
          positionName: undefined,
          teamName: undefined,
          principal: 0,
          parentId: undefined,
          principalId: undefined,
          remark: undefined,
          parentName: undefined,
          parentPositionName: undefined
        };
        this.editDialog.form = deepClone(data);
      }
      this.buttonLoading = false;
      this.editDialog.visible = true;
    },
    getUser(resp, row) {
      if (this.$refs.user) {
        new Promise(async (resolve, reject) => {
          await this.$refs.user.remoteMethod(resp)
          resolve()
        }).then(() => {
          this.editDialog.form.userName = row.userName;
          this.editDialog.form.nickName = row.nickName;
          this.editDialog.form.teamName = row.teamName;
          this.$forceUpdate();
        })
      } else {
        setTimeout(() => this.getUser(resp, row), 200)
      }
    },
    handleQueryChangeLinkageRef(ref, val, type, form, dict) {
      this.$nextTick(() => {
        ref.split(',').forEach(e => {
          if (this.$refs[e]) {
            if (form && dict) {
              this[dict] = Object.assign({}, form)
            } else {
              this.dictParam = Object.assign({}, this.queryForm)
            }
            setTimeout(() => {
              this.$refs[e].handleRefChange(val, type);
            }, 100)
            this.$forceUpdate();
          }
        })
      });
    },
    handleChangeLinkageRef(ref, val, type, form, dict) {
      this.$nextTick(() => {
        if (type === 'hand') {
          this.editDialog.form.dcCode = undefined;
          this.editDialog.form.positionId = undefined;
          this.editDialog.form.userName = undefined;
          this.editDialog.form.nickName = undefined;
          this.editDialog.form.positionName = undefined;
          this.editDialog.form.teamName = undefined;
          this.editDialog.form.principal = 0;
          this.editDialog.form.parentId = undefined;
          this.editDialog.form.principalId = undefined;
          this.editDialog.form.remark = undefined;
          this.editDialog.form.parentName = undefined;
          this.editDialog.form.parentPositionName = undefined;
          this.editDialog.form.teamName = undefined;
          this.handleCheckMain();
        }
      });
    },
    handleDcChange() {
      checkMain(this.editDialog.form).then(res => {
        this.editDialog.principalDisabled = res.data;
      })
    },
    handleCheckMain(row) {
      let param = deepClone(this.editDialog.form);
      param.principal =1;
      checkMain(param).then(res => {
        if (!res.data) {
          this.editDialog.form.principal = 1;
          this.editDialog.principalDisabled = true;
        } else {
          this.editDialog.principalDisabled = false;
          if (this.editDialog.form.positionId === 3 || this.editDialog.form.positionId===1) {
            this.editDialog.principalDisabled = true;
          }
          this.editDialog.form.principal = 0;
        }
        this.handleGetDeputy(row);
      })
    },
    handleAdd() {
      const row = {
        parentId: this.viewDialog.queryParam.parentId,
        positionId: this.viewDialog.queryParam.positionId,
        orgCode: this.viewDialog.queryParam.orgCode,
        dcCode: this.viewDialog.queryParam.dcCode,
        principalId: this.viewDialog.queryParam.principalId,
      }
      if (row.positionId) {
        row.positionName = this.positionOptions.find(e => e.value === row.positionId).label;
      }
      row.principal = 0;
      this.handleEdit(row);
      this.handleCheckMain(row);
      this.$forceUpdate();
    },
    handleGetDeputy(row) {
      if (!this.editDialog.form.principal && this.editDialog.form.positionId > 1 && this.editDialog.form.positionId < 6) {
        let param = {orgCode: this.editDialog.form.orgCode, dcCode: this.editDialog.form.dcCode, positionId: this.editDialog.form.positionId, principal: 1};
        listBranch(param).then(res => {
          this.editDialog.deputyList = res.data;
          if (res.data && res.data.length > 0) {
            if (row && row.principalId) {
              this.editDialog.form.principalId = row.principalId;
            } else {
              this.editDialog.form.principalId = res.data[0].id;
            }
          }
        })
      }
    },
    handPositionChange(val, type) {
      this.editDialog.form.principalId = undefined;
      let param = {
        positionId: this.editDialog.form.positionId -1,
        dcCode: this.editDialog.form.dcCode,
        orgCode: this.editDialog.form.orgCode,
        principal: 1,
      };
      if (param.positionId < 3) {
        param.dcCode = 'ALL';
      }
      if (type === 'position') {
        param.parentId = undefined;
        param.dcCode = undefined;
        param.principalId = undefined;
      } else if (type === 'dc') {
        param.principalId = undefined;
      }
      param.principal =1;
      listBranch(param).then(res => {
        this.parentOptions = res.data;
      })
      this.handleCheckMain();
      if (type === 'position') {
        this.branchDcOptions = [];
        if (val > 3) {
          this.editDialog.form.dcCode = undefined;
          branchDict('branch-dc?query=' + this.editDialog.form.orgCode).then(res => {
            this.branchDcOptions = res.data;
          })
        } else if (val === 3) {
          this.editDialog.form.dcCode = undefined;
          branchDict('all-dc?query=' + this.editDialog.form.orgCode).then(res => {
            this.allDcOptions = res.data;
          })
        } else {
          this.editDialog.form.dcCode = 'ALL';
        }
        if (val) {
          const name = this.positionOptions.find(e => e.value === val).label;
          this.editDialog.form.positionName = name
        }
      }

      this.editDialog.form.parentId = undefined;
      this.editDialog.form.principal = 0;
      this.$forceUpdate();
    },
    handleRespChange(val, type) {
      this.$nextTick(() => {
        if (type === 'hand') {
          let user = this.$refs.user.itemOptions.find(e => e.userName === val);
          user = user? user: { nickName: '', userName: ''}
          // this.editDialog.form.userName = user.userName;
          // this.$set(this.editDialog.form, 'userName', user.userName)
          this.editDialog.form.nickName = user.nickName;
          this.editDialog.form.teamName = user.nickName + '团队';
          this.$forceUpdate();
        }
      });
    },
    handleSave() {
      this.$refs.editFormRef.validate(valid => {
        if (valid) {
          if (!this.editDialog.form.positionId > 1 && !this.editDialog.form.parentId) {
            Notification.warning("上级不能为空");
            return;
          }
          if (!this.editDialog.form.userName) {
            Notification.warning("用户不能为空");
            return;
          }
          // if (!this.editDialog.form.dcCode) {
          //   Notification.warning("配送中心不能为空");
          // }
          if (!this.editDialog.form.teamName) {
            Notification.warning("团队名称不能为空");
            return;
          }
          if (!this.editDialog.form.positionName) {
            Notification.warning("岗位名称不能为空");
            return;
          }
          if (this.editDialog.form.positionId === 6) {
            this.editDialog.form.principal = 1;
          }
          this.buttonLoading = true;
          const param = deepClone(this.editDialog.form);
          saveOrgBranch(param).then(res => {
            this.buttonLoading = false;
            if (res.code === 200) {
              Notification.success("操作成功！");
              this.editDialog.visible =false;
                this.handleFilter();
              if (this.viewDialog.visible) {
                this.handleListBranch(this.viewDialog.queryParam);
              }
              this.$refs.editFormRef.resetFields();
            }
          })
        }
      });
    },
    handleImport() {
      this.importChooseDialog.visible = false;
      const currentImportOption = this.importDialog.importBaseParams.find(item => item.type === 'orgBranch');
      this.importDialog.currentImportType = currentImportOption.type;
      currentImportOption.visible = true;
      return false;
    },
    handleImportData(val) {
      this.importDialog.visible = false;
      this.loading = true;
      const data = {
        list: Object.assign([], val["importList"]),
        action: this.importChooseDialog.importAction
      }
      importBranch(data).then(res => {
        this.loading = false;
        if (res.code === 200) {
          if (res.data && res.data.length > 0) {
            Notification.error("导入失败，正在下载导入明细！");
            this.downloadImportResult(res.data)
          } else {
            Notification.success("操作成功！");
            this.handleFilter();
            const currentImportOption = this.importDialog.importBaseParams.find(item => item.type === 'orgBranch');
            this.importDialog.currentImportType = currentImportOption.type;
            currentImportOption.visible = false;
          }
        }
      });
    },
    downloadImportResult(data) {
      excelExport({
        topHeaders: this.exportHeaders,
        data: data,
        title: '分公司组织导入错误明细',
      });
    },
    handleExport() {
      this.loading = true;
      const param = Object.assign({}, this.queryForm);
      exportBranch(param).then(res => {
        this.loading = false;
        if (!res) {
          return
        }
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = window.URL.createObjectURL(new Blob([res]))
        link.setAttribute('download', '分公司组织导出.xlsx')
        document.body.appendChild(link)
        link.click()
        link.remove()
      })
    },
    handleCellClick(row, cell) {
      if (cell.property !== 'dcCode' && cell.property !== 'orgCode') {
        const positionId = this.proMap[cell.property];
        const principalId = this.principalMap[cell.property];
        let parentId;
        switch (cell.property) {
          case 'name2':
            parentId = row.p1Id;
            break;
          case 'name3':
            parentId = row.p2Id;
            break;
          case 'name4':
            parentId = row.p3Id;
            break;
          case 'name5':
            parentId = row.p4Id;
            break;
          case 'name6':
            parentId = row.p5Id;
            break;
        }
        let param = {
          orgCode: row.orgCode,
          dcCode: positionId > 2 ? row.dcCode : 'ALL',
          positionId: positionId,
          principalId: row[principalId],
          parentId: parentId
        }
        this.viewDialog.queryParam = param;
        this.viewDialog.list = [];
        this.handleListBranch(param);
      }
    },
    handleDelRow(row) {
      this.$confirm('请确认是否删除所选的数据?', '提示', { type: 'warning' }).then(() => {
        delBranch(row.id).then(res => {
          if (res.code ===200) {
            Notification.success("操作成功！");
            this.handleFilter();
            this.handleListBranch(this.viewDialog.queryParam);
          }
        })
      }).catch(() => {
      })
    },
    handleListBranch(param) {
      this.viewDialog.loading = true;
      listBranch(param).then(res => {
        this.viewDialog.list = res.data;
        this.viewDialog.visible = true;
        this.viewDialog.loading = false;
      })
    },
    handleChangeMain(row) {
      changeMain(row).then((res) => {
        if (res.code ===200) {
          Notification.success("操作成功！");
            this.handleFilter();
          this.viewDialog.queryParam.principalId = row.id;
          if (row.positionId ==1) {
            this.viewDialog.queryParam.parentId = undefined;
          }
          this.handleListBranch(this.viewDialog.queryParam);
        }
      })
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding-top: 10px;
  padding-bottom: 5px;
}

::v-deep .rowClassName {
  cursor: pointer !important;
}

::v-deep .el-table__header .el-table-column--selection .cell .el-checkbox:after {
  content: "本页全选";
  color: black;
  font-weight: bold;
  font-size: 12px;
  margin-left: 5px;
}

</style>

<style lang="scss">
::v-deep .columnClass {
  color: #0040ff !important;
  vertical-align: middle;
}
</style>
