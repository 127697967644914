var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "new-box" },
    [
      _c(
        "div",
        { staticClass: "mg-search" },
        [
          _c(
            "el-card",
            { staticStyle: { margin: "10px" } },
            [
              _c(
                "SearchFilter",
                {
                  on: {
                    search: function ($event) {
                      return _vm.handleFilter("hand")
                    },
                    reset: _vm.resetQuery,
                  },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.handleFilter.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm.authOrgOptions.length > 1
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "分公司" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                filterable: "",
                                clearable: _vm.authOrgAll,
                                "default-first-option": "",
                              },
                              model: {
                                value: _vm.queryForm.orgCode,
                                callback: function ($$v) {
                                  _vm.$set(_vm.queryForm, "orgCode", $$v)
                                },
                                expression: "queryForm.orgCode",
                              },
                            },
                            _vm._l(_vm.authOrgOptions, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: {
                                  value: item.value,
                                  label: item.value + "-" + item.label,
                                },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    { attrs: { label: "核销名称" } },
                    [
                      _c("el-input", {
                        attrs: {
                          clearable: "",
                          placeholder: "请输入名称关键字",
                        },
                        model: {
                          value: _vm.queryForm.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryForm, "name", $$v)
                          },
                          expression: "queryForm.name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-card",
        { staticClass: "box-card", staticStyle: { margin: "10px" } },
        [
          _c(
            "el-button",
            {
              staticClass: "form-btn-item",
              attrs: { size: "mini", icon: "el-icon-plus", type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.handleAdd("add")
                },
              },
            },
            [_vm._v("新增")]
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "tableRef",
              staticClass: "customer-table",
              staticStyle: { "margin-top": "10px" },
              attrs: {
                data: _vm.pageData.rows,
                border: "",
                size: "small",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "分公司", prop: "title" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.getOrgInfo(row.orgCode))),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", { attrs: { label: "名称", prop: "name" } }),
              _c("el-table-column", {
                attrs: { label: "创建时间", prop: "createTime" },
              }),
              _c("el-table-column", {
                attrs: { label: "创建人", prop: "createUser" },
              }),
              _c("el-table-column", {
                attrs: { label: "操作", width: "250", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        row.createTime &&
                        new Date(row.createTime).getTime() >
                          new Date("2024-05-10").getTime() &&
                        (row.orgCode !== "ALL" ||
                          (row.orgCode === "ALL" && _vm.authOrgAll))
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { border: "0px" },
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleEdit(row)
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("Pagination", {
            attrs: {
              total: _vm.pageData.total,
              page: _vm.queryForm.current,
              limit: _vm.queryForm.size,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.queryForm, "current", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.queryForm, "size", $event)
              },
              pagination: _vm.handleQuery,
            },
          }),
        ],
        1
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            title: _vm.saveDialog.titleMap[_vm.saveDialog.type],
            center: true,
            visible: _vm.saveDialog.visible,
            "append-to-body": "",
            size: "70%",
            direction: "ltr",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.saveDialog, "visible", $event)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "saveFormRef",
              staticStyle: { "padding-left": "20px" },
              attrs: {
                size: "mini",
                inline: true,
                rules: _vm.saveDialog.rules,
                model: _vm.saveDialog.form,
              },
            },
            [
              _c(
                "div",
                { staticStyle: { "overflow-y": "auto" } },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "核销名称", prop: "name" } },
                            [
                              _c("el-input", {
                                staticStyle: { width: "350px" },
                                attrs: {
                                  clearable: "",
                                  placeholder: "请输入核销名称",
                                },
                                model: {
                                  value: _vm.saveDialog.form.name,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.saveDialog.form, "name", $$v)
                                  },
                                  expression: "saveDialog.form.name",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "核销范围", prop: "orgCode" } },
                            [
                              _c(
                                "el-select",
                                {
                                  on: { change: _vm.handleOrgChange },
                                  model: {
                                    value: _vm.saveDialog.form.orgCode,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.saveDialog.form,
                                        "orgCode",
                                        $$v
                                      )
                                    },
                                    expression: "saveDialog.form.orgCode",
                                  },
                                },
                                _vm._l(_vm.authOrgOptions, function (item) {
                                  return _c("el-option", {
                                    key: item.key,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "审核设置",
                                prop: "onlyAuditByOrg",
                              },
                            },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.saveDialog.form.onlyAuditByOrg,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.saveDialog.form,
                                        "onlyAuditByOrg",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "saveDialog.form.onlyAuditByOrg",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: 1 } }, [
                                    _vm._v("仅需分公司审核(总部可抽查)"),
                                  ]),
                                  _c("el-radio", { attrs: { label: 0 } }, [
                                    _vm._v("分公司审核+总部审核"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "门店头图", prop: "orgCode" } },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value:
                                      _vm.saveDialog.form.doorHeadPhotoV3.show,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.saveDialog.form.doorHeadPhotoV3,
                                        "show",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "saveDialog.form.doorHeadPhotoV3.show",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: true } }, [
                                    _vm._v("需要"),
                                  ]),
                                  _c("el-radio", { attrs: { label: false } }, [
                                    _vm._v("不需要"),
                                  ]),
                                ],
                                1
                              ),
                              _vm.saveDialog.form.doorHeadPhotoV3.show
                                ? _c(
                                    "div",
                                    { staticStyle: { "margin-left": "10px" } },
                                    [
                                      _c("label", [_vm._v("照片数范围：")]),
                                      _c("el-input-number", {
                                        staticStyle: { width: "50px" },
                                        attrs: {
                                          clearable: "",
                                          controls: false,
                                          min: 0,
                                          size: "small",
                                        },
                                        model: {
                                          value:
                                            _vm.saveDialog.form.doorHeadPhotoV3
                                              .photoMin,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.saveDialog.form
                                                .doorHeadPhotoV3,
                                              "photoMin",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "saveDialog.form.doorHeadPhotoV3.photoMin",
                                        },
                                      }),
                                      _vm._v("- "),
                                      _c("el-input-number", {
                                        staticStyle: { width: "50px" },
                                        attrs: {
                                          clearable: "",
                                          controls: false,
                                          min: 0,
                                          size: "small",
                                        },
                                        model: {
                                          value:
                                            _vm.saveDialog.form.doorHeadPhotoV3
                                              .photoMax,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.saveDialog.form
                                                .doorHeadPhotoV3,
                                              "photoMax",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "saveDialog.form.doorHeadPhotoV3.photoMax",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "150px" },
                                  attrs: {
                                    placeholder: "上传方式",
                                    multiple: "",
                                  },
                                  model: {
                                    value:
                                      _vm.saveDialog.form.doorHeadPhotoV3
                                        .include,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.saveDialog.form.doorHeadPhotoV3,
                                        "include",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "saveDialog.form.doorHeadPhotoV3.include",
                                  },
                                },
                                _vm._l(_vm.taskPhotosOptions, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "上传次数" } },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.saveDialog.form.multiUpload,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.saveDialog.form,
                                        "multiUpload",
                                        $$v
                                      )
                                    },
                                    expression: "saveDialog.form.multiUpload",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: 0 } }, [
                                    _vm._v("单次上传(可撤回修改)"),
                                  ]),
                                  _c("el-radio", { attrs: { label: 1 } }, [
                                    _vm._v("可多次上传"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._l(
                        _vm.saveDialog.form.taskConfigs,
                        function (t, index) {
                          return _c(
                            "el-col",
                            {
                              key: index,
                              staticStyle: {
                                border: "1px solid #d9cccc",
                                padding: "5px",
                              },
                            },
                            [
                              _c(
                                "el-form-item",
                                [
                                  _c(
                                    "el-row",
                                    [
                                      _c(
                                        "el-row",
                                        [
                                          _c(
                                            "el-col",
                                            {
                                              staticStyle: { color: "#ac7017" },
                                            },
                                            [
                                              _vm._v(
                                                " 任务 " +
                                                  _vm._s(index + 1) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "el-col",
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  staticStyle: {
                                                    display: "inline-flex",
                                                  },
                                                  attrs: { label: "任务" },
                                                },
                                                [
                                                  _c(
                                                    "el-select",
                                                    {
                                                      attrs: { filterable: "" },
                                                      on: {
                                                        change: (val) => {
                                                          t.boostId = undefined
                                                          _vm.listTaskBoost(val)
                                                        },
                                                      },
                                                      model: {
                                                        value: t.mtTaskId,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            t,
                                                            "mtTaskId",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "t.mtTaskId",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.taskOptions,
                                                      function (ele) {
                                                        return _c("el-option", {
                                                          key: ele.mtTaskId,
                                                          attrs: {
                                                            value: ele.mtTaskId,
                                                            label:
                                                              ele.orgName +
                                                              "-" +
                                                              ele.taskName,
                                                          },
                                                        })
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              t.mtTaskId &&
                                              _vm.taskBoostOptions[
                                                t.mtTaskId
                                              ] &&
                                              _vm.taskBoostOptions[t.mtTaskId]
                                                .length > 0
                                                ? _c(
                                                    "el-form-item",
                                                    {
                                                      staticStyle: {
                                                        display: "inline-flex",
                                                      },
                                                      attrs: { label: "激励" },
                                                    },
                                                    [
                                                      _c(
                                                        "el-select",
                                                        {
                                                          attrs: {
                                                            filterable: "",
                                                            clearable: "",
                                                          },
                                                          on: {
                                                            change: () =>
                                                              (_vm.item.boostInfo =
                                                                undefined),
                                                          },
                                                          model: {
                                                            value: t.boostId,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                t,
                                                                "boostId",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "t.boostId",
                                                          },
                                                        },
                                                        _vm._l(
                                                          _vm.taskBoostOptions[
                                                            t.mtTaskId
                                                          ],
                                                          function (ele) {
                                                            return _c(
                                                              "el-option",
                                                              {
                                                                key: ele.id,
                                                                attrs: {
                                                                  value: ele.id,
                                                                  label:
                                                                    ele.name,
                                                                },
                                                              }
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              t.mtTaskId
                                                ? _c(
                                                    "el-form-item",
                                                    [
                                                      _c(
                                                        "el-button",
                                                        {
                                                          staticStyle: {
                                                            border: "0px",
                                                          },
                                                          attrs: {
                                                            type: "text",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleEditBoost(
                                                                t,
                                                                index
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              t.boostId ||
                                                                (t.boostInfo &&
                                                                  t.boostInfo
                                                                    .name)
                                                                ? "编辑激励" +
                                                                    (t.boostInfo &&
                                                                    t.boostInfo
                                                                      .name
                                                                      ? "-(" +
                                                                        t
                                                                          .boostInfo
                                                                          .name +
                                                                        ")"
                                                                      : "")
                                                                : "新建激励"
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-col",
                                            {
                                              staticStyle: {
                                                "margin-left": "20px",
                                              },
                                            },
                                            _vm._l(
                                              t.moduleConfigs,
                                              function (item, mIndex) {
                                                return _c(
                                                  "el-row",
                                                  {
                                                    key: mIndex,
                                                    staticStyle: {
                                                      border: "1px solid gray",
                                                      padding: "5px",
                                                      "margin-top": "10px",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-col",
                                                      {
                                                        staticStyle: {
                                                          color: "#ac7017",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " 模块 " +
                                                            _vm._s(mIndex + 1) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "el-col",
                                                      [
                                                        _c(
                                                          "el-form-item",
                                                          {
                                                            attrs: {
                                                              label: "模块名称",
                                                            },
                                                          },
                                                          [
                                                            _c("el-input", {
                                                              model: {
                                                                value:
                                                                  item.name,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      item,
                                                                      "name",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "item.name",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "el-col",
                                                      [
                                                        _c("div", [
                                                          _c("label", [
                                                            _vm._v(
                                                              "照片项配置"
                                                            ),
                                                          ]),
                                                        ]),
                                                        _vm._l(
                                                          item.photoConfigs,
                                                          function (ele, i) {
                                                            return _c(
                                                              "div",
                                                              {
                                                                key: i,
                                                                staticStyle: {
                                                                  "margin-bottom":
                                                                    "10px",
                                                                  border:
                                                                    "1px solid #aca8a8",
                                                                  padding:
                                                                    "5px",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "el-row",
                                                                  [
                                                                    _c(
                                                                      "el-col",
                                                                      {
                                                                        attrs: {
                                                                          span: 6,
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "el-form-item",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                label:
                                                                                  "配置名",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "el-input",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    placeholder:
                                                                                      "如：上传照片",
                                                                                  },
                                                                                model:
                                                                                  {
                                                                                    value:
                                                                                      ele.name,
                                                                                    callback:
                                                                                      function (
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          ele,
                                                                                          "name",
                                                                                          $$v
                                                                                        )
                                                                                      },
                                                                                    expression:
                                                                                      "ele.name",
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "el-col",
                                                                      {
                                                                        attrs: {
                                                                          span: 6,
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "el-form-item",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                label:
                                                                                  "配置备注",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "el-input",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    placeholder:
                                                                                      "配置的备注信息",
                                                                                  },
                                                                                model:
                                                                                  {
                                                                                    value:
                                                                                      ele.remark,
                                                                                    callback:
                                                                                      function (
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          ele,
                                                                                          "remark",
                                                                                          $$v
                                                                                        )
                                                                                      },
                                                                                    expression:
                                                                                      "ele.remark",
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "el-col",
                                                                      [
                                                                        _c(
                                                                          "el-form-item",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                label:
                                                                                  "照片必填",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "el-radio-group",
                                                                              {
                                                                                model:
                                                                                  {
                                                                                    value:
                                                                                      ele.required,
                                                                                    callback:
                                                                                      function (
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          ele,
                                                                                          "required",
                                                                                          $$v
                                                                                        )
                                                                                      },
                                                                                    expression:
                                                                                      "ele.required",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "el-radio",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        label: true,
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "是"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "el-radio",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        label: false,
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "否"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "el-col",
                                                                      [
                                                                        _c(
                                                                          "el-form-item",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                label:
                                                                                  "类型",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "el-radio-group",
                                                                              {
                                                                                model:
                                                                                  {
                                                                                    value:
                                                                                      ele.showAfter,
                                                                                    callback:
                                                                                      function (
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          ele,
                                                                                          "showAfter",
                                                                                          $$v
                                                                                        )
                                                                                      },
                                                                                    expression:
                                                                                      "ele.showAfter",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "el-radio",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        label: false,
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "上传照片"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "el-radio",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        label: true,
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "打造前/打造后"
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                            _c(
                                                                              "div",
                                                                              [
                                                                                !ele.showAfter
                                                                                  ? _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticStyle:
                                                                                          {
                                                                                            display:
                                                                                              "inline-block",
                                                                                            "margin-left":
                                                                                              "10px",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "label",
                                                                                          [
                                                                                            _vm._v(
                                                                                              "照片数范围："
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                        _c(
                                                                                          "el-input-number",
                                                                                          {
                                                                                            staticStyle:
                                                                                              {
                                                                                                width:
                                                                                                  "50px",
                                                                                              },
                                                                                            attrs:
                                                                                              {
                                                                                                clearable:
                                                                                                  "",
                                                                                                controls: false,
                                                                                                min: 1,
                                                                                                max: 10,
                                                                                                size: "small",
                                                                                              },
                                                                                            model:
                                                                                              {
                                                                                                value:
                                                                                                  ele.photoMin,
                                                                                                callback:
                                                                                                  function (
                                                                                                    $$v
                                                                                                  ) {
                                                                                                    _vm.$set(
                                                                                                      ele,
                                                                                                      "photoMin",
                                                                                                      $$v
                                                                                                    )
                                                                                                  },
                                                                                                expression:
                                                                                                  "ele.photoMin",
                                                                                              },
                                                                                          }
                                                                                        ),
                                                                                        _vm._v(
                                                                                          "- "
                                                                                        ),
                                                                                        _c(
                                                                                          "el-input-number",
                                                                                          {
                                                                                            staticStyle:
                                                                                              {
                                                                                                width:
                                                                                                  "50px",
                                                                                              },
                                                                                            attrs:
                                                                                              {
                                                                                                clearable:
                                                                                                  "",
                                                                                                controls: false,
                                                                                                min: 2,
                                                                                                max: 10,
                                                                                                size: "small",
                                                                                              },
                                                                                            model:
                                                                                              {
                                                                                                value:
                                                                                                  ele.photoMax,
                                                                                                callback:
                                                                                                  function (
                                                                                                    $$v
                                                                                                  ) {
                                                                                                    _vm.$set(
                                                                                                      ele,
                                                                                                      "photoMax",
                                                                                                      $$v
                                                                                                    )
                                                                                                  },
                                                                                                expression:
                                                                                                  "ele.photoMax",
                                                                                              },
                                                                                          }
                                                                                        ),
                                                                                        _c(
                                                                                          "el-input",
                                                                                          {
                                                                                            staticStyle:
                                                                                              {
                                                                                                width:
                                                                                                  "150px",
                                                                                              },
                                                                                            attrs:
                                                                                              {
                                                                                                clearable:
                                                                                                  "",
                                                                                                size: "small",
                                                                                                placeholder:
                                                                                                  "请输入描述",
                                                                                              },
                                                                                            model:
                                                                                              {
                                                                                                value:
                                                                                                  ele.desc,
                                                                                                callback:
                                                                                                  function (
                                                                                                    $$v
                                                                                                  ) {
                                                                                                    _vm.$set(
                                                                                                      ele,
                                                                                                      "desc",
                                                                                                      $$v
                                                                                                    )
                                                                                                  },
                                                                                                expression:
                                                                                                  "ele.desc",
                                                                                              },
                                                                                          }
                                                                                        ),
                                                                                        _c(
                                                                                          "el-select",
                                                                                          {
                                                                                            attrs:
                                                                                              {
                                                                                                multiple:
                                                                                                  "",
                                                                                              },
                                                                                            model:
                                                                                              {
                                                                                                value:
                                                                                                  ele.include,
                                                                                                callback:
                                                                                                  function (
                                                                                                    $$v
                                                                                                  ) {
                                                                                                    _vm.$set(
                                                                                                      ele,
                                                                                                      "include",
                                                                                                      $$v
                                                                                                    )
                                                                                                  },
                                                                                                expression:
                                                                                                  "ele.include",
                                                                                              },
                                                                                          },
                                                                                          _vm._l(
                                                                                            _vm.taskPhotosOptions,
                                                                                            function (
                                                                                              item
                                                                                            ) {
                                                                                              return _c(
                                                                                                "el-option",
                                                                                                {
                                                                                                  key: item.value,
                                                                                                  attrs:
                                                                                                    {
                                                                                                      label:
                                                                                                        item.label,
                                                                                                      value:
                                                                                                        item.value,
                                                                                                    },
                                                                                                }
                                                                                              )
                                                                                            }
                                                                                          ),
                                                                                          1
                                                                                        ),
                                                                                      ],
                                                                                      1
                                                                                    )
                                                                                  : _vm._e(),
                                                                                ele.showAfter
                                                                                  ? _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticStyle:
                                                                                          {
                                                                                            display:
                                                                                              "inline-block",
                                                                                            "margin-left":
                                                                                              "10px",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "div",
                                                                                          [
                                                                                            _c(
                                                                                              "label",
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "打造前照片数范围："
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                            _c(
                                                                                              "el-input-number",
                                                                                              {
                                                                                                staticStyle:
                                                                                                  {
                                                                                                    width:
                                                                                                      "50px",
                                                                                                  },
                                                                                                attrs:
                                                                                                  {
                                                                                                    clearable:
                                                                                                      "",
                                                                                                    controls: false,
                                                                                                    min: 0,
                                                                                                    size: "small",
                                                                                                  },
                                                                                                model:
                                                                                                  {
                                                                                                    value:
                                                                                                      ele.photoMin,
                                                                                                    callback:
                                                                                                      function (
                                                                                                        $$v
                                                                                                      ) {
                                                                                                        _vm.$set(
                                                                                                          ele,
                                                                                                          "photoMin",
                                                                                                          $$v
                                                                                                        )
                                                                                                      },
                                                                                                    expression:
                                                                                                      "ele.photoMin",
                                                                                                  },
                                                                                              }
                                                                                            ),
                                                                                            _vm._v(
                                                                                              "- "
                                                                                            ),
                                                                                            _c(
                                                                                              "el-input-number",
                                                                                              {
                                                                                                staticStyle:
                                                                                                  {
                                                                                                    width:
                                                                                                      "50px",
                                                                                                  },
                                                                                                attrs:
                                                                                                  {
                                                                                                    clearable:
                                                                                                      "",
                                                                                                    controls: false,
                                                                                                    min: 0,
                                                                                                    size: "small",
                                                                                                  },
                                                                                                model:
                                                                                                  {
                                                                                                    value:
                                                                                                      ele.photoMax,
                                                                                                    callback:
                                                                                                      function (
                                                                                                        $$v
                                                                                                      ) {
                                                                                                        _vm.$set(
                                                                                                          ele,
                                                                                                          "photoMax",
                                                                                                          $$v
                                                                                                        )
                                                                                                      },
                                                                                                    expression:
                                                                                                      "ele.photoMax",
                                                                                                  },
                                                                                              }
                                                                                            ),
                                                                                            _c(
                                                                                              "el-input",
                                                                                              {
                                                                                                staticStyle:
                                                                                                  {
                                                                                                    width:
                                                                                                      "150px",
                                                                                                  },
                                                                                                attrs:
                                                                                                  {
                                                                                                    clearable:
                                                                                                      "",
                                                                                                    size: "small",
                                                                                                    placeholder:
                                                                                                      "请输入上传前照片描述",
                                                                                                  },
                                                                                                model:
                                                                                                  {
                                                                                                    value:
                                                                                                      ele.desc,
                                                                                                    callback:
                                                                                                      function (
                                                                                                        $$v
                                                                                                      ) {
                                                                                                        _vm.$set(
                                                                                                          ele,
                                                                                                          "desc",
                                                                                                          $$v
                                                                                                        )
                                                                                                      },
                                                                                                    expression:
                                                                                                      "ele.desc",
                                                                                                  },
                                                                                              }
                                                                                            ),
                                                                                            _c(
                                                                                              "el-select",
                                                                                              {
                                                                                                staticStyle:
                                                                                                  {
                                                                                                    width:
                                                                                                      "150px",
                                                                                                  },
                                                                                                attrs:
                                                                                                  {
                                                                                                    placeholder:
                                                                                                      "上传方式",
                                                                                                    multiple:
                                                                                                      "",
                                                                                                  },
                                                                                                model:
                                                                                                  {
                                                                                                    value:
                                                                                                      ele.include,
                                                                                                    callback:
                                                                                                      function (
                                                                                                        $$v
                                                                                                      ) {
                                                                                                        _vm.$set(
                                                                                                          ele,
                                                                                                          "include",
                                                                                                          $$v
                                                                                                        )
                                                                                                      },
                                                                                                    expression:
                                                                                                      "ele.include",
                                                                                                  },
                                                                                              },
                                                                                              _vm._l(
                                                                                                _vm.taskPhotosOptions,
                                                                                                function (
                                                                                                  item
                                                                                                ) {
                                                                                                  return _c(
                                                                                                    "el-option",
                                                                                                    {
                                                                                                      key: item.value,
                                                                                                      attrs:
                                                                                                        {
                                                                                                          label:
                                                                                                            item.label,
                                                                                                          value:
                                                                                                            item.value,
                                                                                                        },
                                                                                                    }
                                                                                                  )
                                                                                                }
                                                                                              ),
                                                                                              1
                                                                                            ),
                                                                                          ],
                                                                                          1
                                                                                        ),
                                                                                        _c(
                                                                                          "div",
                                                                                          [
                                                                                            _c(
                                                                                              "label",
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "打造后照片数范围："
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                            _c(
                                                                                              "el-input-number",
                                                                                              {
                                                                                                staticStyle:
                                                                                                  {
                                                                                                    width:
                                                                                                      "50px",
                                                                                                  },
                                                                                                attrs:
                                                                                                  {
                                                                                                    clearable:
                                                                                                      "",
                                                                                                    controls: false,
                                                                                                    min: 0,
                                                                                                    size: "small",
                                                                                                  },
                                                                                                model:
                                                                                                  {
                                                                                                    value:
                                                                                                      ele.photoMinAfter,
                                                                                                    callback:
                                                                                                      function (
                                                                                                        $$v
                                                                                                      ) {
                                                                                                        _vm.$set(
                                                                                                          ele,
                                                                                                          "photoMinAfter",
                                                                                                          $$v
                                                                                                        )
                                                                                                      },
                                                                                                    expression:
                                                                                                      "ele.photoMinAfter",
                                                                                                  },
                                                                                              }
                                                                                            ),
                                                                                            _vm._v(
                                                                                              "- "
                                                                                            ),
                                                                                            _c(
                                                                                              "el-input-number",
                                                                                              {
                                                                                                staticStyle:
                                                                                                  {
                                                                                                    width:
                                                                                                      "50px",
                                                                                                  },
                                                                                                attrs:
                                                                                                  {
                                                                                                    clearable:
                                                                                                      "",
                                                                                                    controls: false,
                                                                                                    min: 0,
                                                                                                    size: "small",
                                                                                                  },
                                                                                                model:
                                                                                                  {
                                                                                                    value:
                                                                                                      ele.photoMaxAfter,
                                                                                                    callback:
                                                                                                      function (
                                                                                                        $$v
                                                                                                      ) {
                                                                                                        _vm.$set(
                                                                                                          ele,
                                                                                                          "photoMaxAfter",
                                                                                                          $$v
                                                                                                        )
                                                                                                      },
                                                                                                    expression:
                                                                                                      "ele.photoMaxAfter",
                                                                                                  },
                                                                                              }
                                                                                            ),
                                                                                            _c(
                                                                                              "el-input",
                                                                                              {
                                                                                                staticStyle:
                                                                                                  {
                                                                                                    width:
                                                                                                      "150px",
                                                                                                  },
                                                                                                attrs:
                                                                                                  {
                                                                                                    clearable:
                                                                                                      "",
                                                                                                    size: "small",
                                                                                                    placeholder:
                                                                                                      "请输入上传前照片描述",
                                                                                                  },
                                                                                                model:
                                                                                                  {
                                                                                                    value:
                                                                                                      ele.descAfter,
                                                                                                    callback:
                                                                                                      function (
                                                                                                        $$v
                                                                                                      ) {
                                                                                                        _vm.$set(
                                                                                                          ele,
                                                                                                          "descAfter",
                                                                                                          $$v
                                                                                                        )
                                                                                                      },
                                                                                                    expression:
                                                                                                      "ele.descAfter",
                                                                                                  },
                                                                                              }
                                                                                            ),
                                                                                            _c(
                                                                                              "el-select",
                                                                                              {
                                                                                                staticStyle:
                                                                                                  {
                                                                                                    width:
                                                                                                      "150px",
                                                                                                  },
                                                                                                attrs:
                                                                                                  {
                                                                                                    placeholder:
                                                                                                      "上传方式",
                                                                                                    multiple:
                                                                                                      "",
                                                                                                  },
                                                                                                model:
                                                                                                  {
                                                                                                    value:
                                                                                                      ele.includeAfter,
                                                                                                    callback:
                                                                                                      function (
                                                                                                        $$v
                                                                                                      ) {
                                                                                                        _vm.$set(
                                                                                                          ele,
                                                                                                          "includeAfter",
                                                                                                          $$v
                                                                                                        )
                                                                                                      },
                                                                                                    expression:
                                                                                                      "ele.includeAfter",
                                                                                                  },
                                                                                              },
                                                                                              _vm._l(
                                                                                                _vm.taskPhotosOptions,
                                                                                                function (
                                                                                                  item
                                                                                                ) {
                                                                                                  return _c(
                                                                                                    "el-option",
                                                                                                    {
                                                                                                      key: item.value,
                                                                                                      attrs:
                                                                                                        {
                                                                                                          label:
                                                                                                            item.label,
                                                                                                          value:
                                                                                                            item.value,
                                                                                                        },
                                                                                                    }
                                                                                                  )
                                                                                                }
                                                                                              ),
                                                                                              1
                                                                                            ),
                                                                                          ],
                                                                                          1
                                                                                        ),
                                                                                      ]
                                                                                    )
                                                                                  : _vm._e(),
                                                                              ]
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "el-button",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            border:
                                                                              "0px",
                                                                          },
                                                                        attrs: {
                                                                          type: "text",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            () => {
                                                                              item.photoConfigs.splice(
                                                                                i,
                                                                                1
                                                                              )
                                                                              _vm.$forceUpdate()
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "删除"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "el-button",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            border:
                                                                              "0px",
                                                                          },
                                                                        attrs: {
                                                                          type: "text",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            () => {
                                                                              let photoConfig =
                                                                                _vm.deepClone(
                                                                                  _vm
                                                                                    .saveDialog
                                                                                    .defaultPhotoConfig
                                                                                )
                                                                              photoConfig.id =
                                                                                _vm
                                                                                  .uuidv4()
                                                                                  .replaceAll(
                                                                                    "-",
                                                                                    ""
                                                                                  )
                                                                              item.photoConfigs.push(
                                                                                photoConfig
                                                                              )
                                                                              _vm.$forceUpdate()
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "新增照片配置"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "el-button",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            border:
                                                                              "0px",
                                                                          },
                                                                        attrs: {
                                                                          disabled:
                                                                            i ===
                                                                            0,
                                                                          type: "text",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            () => {
                                                                              item.photoConfigs.splice(
                                                                                i,
                                                                                1
                                                                              )
                                                                              item.photoConfigs.splice(
                                                                                i -
                                                                                  1,
                                                                                0,
                                                                                ele
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "照片配置上移"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "el-button",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            border:
                                                                              "0px",
                                                                          },
                                                                        attrs: {
                                                                          disabled:
                                                                            i ==
                                                                            item
                                                                              .photoConfigs
                                                                              .length -
                                                                              1,
                                                                          type: "text",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            () => {
                                                                              item.photoConfigs.splice(
                                                                                i,
                                                                                1
                                                                              )
                                                                              item.photoConfigs.splice(
                                                                                i +
                                                                                  1,
                                                                                0,
                                                                                ele
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "照片配置下移"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                i !==
                                                                item
                                                                  .photoConfigs
                                                                  .length -
                                                                  1
                                                                  ? _c(
                                                                      "el-divider",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            margin:
                                                                              "5px",
                                                                            width:
                                                                              "50%",
                                                                          },
                                                                      }
                                                                    )
                                                                  : _vm._e(),
                                                              ],
                                                              1
                                                            )
                                                          }
                                                        ),
                                                      ],
                                                      2
                                                    ),
                                                    _c("el-col", [
                                                      _c("div", [
                                                        _c("label", [
                                                          _vm._v(
                                                            "模块-文本项配置"
                                                          ),
                                                        ]),
                                                      ]),
                                                      _c(
                                                        "div",
                                                        [
                                                          _c(
                                                            "el-form-item",
                                                            {
                                                              attrs: {
                                                                label: "配置名",
                                                              },
                                                            },
                                                            [
                                                              _c("el-input", {
                                                                attrs: {
                                                                  clearable: "",
                                                                  placeholder:
                                                                    "如：道具上柜",
                                                                },
                                                                model: {
                                                                  value:
                                                                    item
                                                                      .textConfigs
                                                                      .name,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        item.textConfigs,
                                                                        "name",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "item.textConfigs.name",
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "el-form-item",
                                                            {
                                                              attrs: {
                                                                label:
                                                                  "配置备注",
                                                              },
                                                            },
                                                            [
                                                              _c("el-input", {
                                                                attrs: {
                                                                  clearable: "",
                                                                  placeholder:
                                                                    "如：选填",
                                                                },
                                                                model: {
                                                                  value:
                                                                    item
                                                                      .textConfigs
                                                                      .remark,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        item.textConfigs,
                                                                        "remark",
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "item.textConfigs.remark",
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                          _vm._l(
                                                            item.textConfigs
                                                              .configs,
                                                            function (ele, i) {
                                                              return _c(
                                                                "el-row",
                                                                { key: i },
                                                                [
                                                                  _c(
                                                                    "el-form-item",
                                                                    {
                                                                      attrs: {
                                                                        label:
                                                                          "描述",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "el-input",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              clearable:
                                                                                "",
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                ele.desc,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    ele,
                                                                                    "desc",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "ele.desc",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "el-form-item",
                                                                    {
                                                                      attrs: {
                                                                        label:
                                                                          "类型",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "el-radio-group",
                                                                        {
                                                                          model:
                                                                            {
                                                                              value:
                                                                                ele.type,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    ele,
                                                                                    "type",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "ele.type",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "el-radio",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  label:
                                                                                    "text",
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "文本"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "el-radio",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  label:
                                                                                    "number",
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "数字"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "el-radio",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  label:
                                                                                    "select",
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "选项"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      ele.type ===
                                                                      "select"
                                                                        ? _c(
                                                                            "div",
                                                                            {
                                                                              staticStyle:
                                                                                {
                                                                                  display:
                                                                                    "inline-block",
                                                                                  "margin-left":
                                                                                    "10px",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "label",
                                                                                [
                                                                                  _vm._v(
                                                                                    "选项类型"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "el-select",
                                                                                {
                                                                                  model:
                                                                                    {
                                                                                      value:
                                                                                        ele.chooseType,
                                                                                      callback:
                                                                                        function (
                                                                                          $$v
                                                                                        ) {
                                                                                          _vm.$set(
                                                                                            ele,
                                                                                            "chooseType",
                                                                                            $$v
                                                                                          )
                                                                                        },
                                                                                      expression:
                                                                                        "ele.chooseType",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "el-option",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          label:
                                                                                            "单选",
                                                                                          value:
                                                                                            "single",
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " "
                                                                                  ),
                                                                                  _c(
                                                                                    "el-option",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          label:
                                                                                            "多选",
                                                                                          value:
                                                                                            "multiple",
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                              _c(
                                                                                "label",
                                                                                [
                                                                                  _vm._v(
                                                                                    "选项数据"
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "el-select",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      filterable:
                                                                                        "",
                                                                                      "allow-create":
                                                                                        "",
                                                                                      multiple:
                                                                                        "",
                                                                                      placeholder:
                                                                                        "请输入后手动选择即可",
                                                                                    },
                                                                                  model:
                                                                                    {
                                                                                      value:
                                                                                        ele.chooseText,
                                                                                      callback:
                                                                                        function (
                                                                                          $$v
                                                                                        ) {
                                                                                          _vm.$set(
                                                                                            ele,
                                                                                            "chooseText",
                                                                                            $$v
                                                                                          )
                                                                                        },
                                                                                      expression:
                                                                                        "ele.chooseText",
                                                                                    },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          )
                                                                        : _vm._e(),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "el-form-item",
                                                                    [
                                                                      _c(
                                                                        "el-button",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              border:
                                                                                "0px",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              type: "text",
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              () => {
                                                                                item.textConfigs.configs.splice(
                                                                                  i,
                                                                                  1
                                                                                )
                                                                                _vm.$forceUpdate()
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "删除文本配置"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "el-button",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          border:
                                                                            "0px",
                                                                        },
                                                                      attrs: {
                                                                        disabled:
                                                                          i ===
                                                                          0,
                                                                        type: "text",
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          () => {
                                                                            item.textConfigs.configs.splice(
                                                                              i,
                                                                              1
                                                                            )
                                                                            item.textConfigs.configs.splice(
                                                                              i -
                                                                                1,
                                                                              0,
                                                                              ele
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "模块文本配置上移"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "el-button",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          border:
                                                                            "0px",
                                                                        },
                                                                      attrs: {
                                                                        disabled:
                                                                          i ===
                                                                          item
                                                                            .textConfigs
                                                                            .configs
                                                                            .length -
                                                                            1,
                                                                        type: "text",
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          () => {
                                                                            item.textConfigs.configs.splice(
                                                                              i,
                                                                              1
                                                                            )
                                                                            item.textConfigs.configs.splice(
                                                                              i +
                                                                                1,
                                                                              0,
                                                                              ele
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "模块文本配置下移"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            }
                                                          ),
                                                          _c(
                                                            "el-button",
                                                            {
                                                              staticStyle: {
                                                                border: "0px",
                                                              },
                                                              attrs: {
                                                                type: "text",
                                                              },
                                                              on: {
                                                                click: () => {
                                                                  let textConfig =
                                                                    _vm.deepClone(
                                                                      _vm
                                                                        .saveDialog
                                                                        .defaultTextConfig
                                                                    )
                                                                  textConfig.id =
                                                                    item
                                                                      .textConfigs
                                                                      .configs
                                                                      .length +
                                                                    1
                                                                  item.textConfigs.configs.push(
                                                                    textConfig
                                                                  )
                                                                  _vm.$forceUpdate()
                                                                },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "新增文本配置"
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        2
                                                      ),
                                                    ]),
                                                    _c(
                                                      "el-col",
                                                      [
                                                        _c(
                                                          "el-button",
                                                          {
                                                            staticStyle: {
                                                              border: "0px",
                                                            },
                                                            attrs: {
                                                              disabled:
                                                                t.moduleConfigs
                                                                  .length < 2,
                                                              type: "text",
                                                            },
                                                            on: {
                                                              click: () => {
                                                                t.moduleConfigs.splice(
                                                                  mIndex,
                                                                  1
                                                                )
                                                                _vm.$forceUpdate()
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "删除模块配置"
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "el-button",
                                                          {
                                                            staticStyle: {
                                                              border: "0px",
                                                            },
                                                            attrs: {
                                                              type: "text",
                                                            },
                                                            on: {
                                                              click: () => {
                                                                let tmp =
                                                                  _vm.deepClone(
                                                                    _vm.defaultModuleConfig
                                                                  )
                                                                tmp.id = _vm
                                                                  .uuidv4()
                                                                  .replaceAll(
                                                                    "-",
                                                                    ""
                                                                  )
                                                                t.moduleConfigs.push(
                                                                  tmp
                                                                )
                                                                _vm.$forceUpdate()
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "新增模块配置"
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "el-button",
                                                          {
                                                            staticStyle: {
                                                              border: "0px",
                                                            },
                                                            attrs: {
                                                              disabled:
                                                                mIndex === 0,
                                                              type: "text",
                                                            },
                                                            on: {
                                                              click: () => {
                                                                t.moduleConfigs.splice(
                                                                  mIndex,
                                                                  1
                                                                )
                                                                t.moduleConfigs.splice(
                                                                  mIndex - 1,
                                                                  0,
                                                                  item
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "模块配置上移"
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "el-button",
                                                          {
                                                            staticStyle: {
                                                              border: "0px",
                                                            },
                                                            attrs: {
                                                              disabled:
                                                                mIndex ===
                                                                t.moduleConfigs
                                                                  .length -
                                                                  1,
                                                              type: "text",
                                                            },
                                                            on: {
                                                              click: () => {
                                                                t.moduleConfigs.splice(
                                                                  mIndex,
                                                                  1
                                                                )
                                                                t.moduleConfigs.splice(
                                                                  mIndex + 1,
                                                                  0,
                                                                  item
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "模块配置下移"
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                            1
                                          ),
                                          _c(
                                            "el-col",
                                            [
                                              _c(
                                                "el-button",
                                                {
                                                  staticStyle: {
                                                    border: "0px",
                                                  },
                                                  attrs: {
                                                    disabled:
                                                      _vm.saveDialog.form
                                                        .taskConfigs.length < 2,
                                                    type: "text",
                                                  },
                                                  on: {
                                                    click: () => {
                                                      _vm.saveDialog.form.taskConfigs.splice(
                                                        index,
                                                        1
                                                      )
                                                      _vm.$forceUpdate()
                                                    },
                                                  },
                                                },
                                                [_vm._v("删除任务配置")]
                                              ),
                                              _c(
                                                "el-button",
                                                {
                                                  staticStyle: {
                                                    border: "0px",
                                                  },
                                                  attrs: { type: "text" },
                                                  on: {
                                                    click: () => {
                                                      const config =
                                                        _vm.deepClone(
                                                          _vm.defaultTaskConfig
                                                        )
                                                      config.moduleConfigs.forEach(
                                                        (e) =>
                                                          (e.id = _vm
                                                            .uuidv4()
                                                            .replaceAll(
                                                              "-",
                                                              ""
                                                            ))
                                                      )
                                                      _vm.saveDialog.form.taskConfigs.push(
                                                        config
                                                      )
                                                      _vm.$forceUpdate()
                                                    },
                                                  },
                                                },
                                                [_vm._v("新增任务配置")]
                                              ),
                                              _c(
                                                "el-button",
                                                {
                                                  staticStyle: {
                                                    border: "0px",
                                                  },
                                                  attrs: { type: "text" },
                                                  on: {
                                                    click: () => {
                                                      let obj = _vm.deepClone(
                                                        _vm.saveDialog.form
                                                          .taskConfigs[index]
                                                      )
                                                      obj.boostId = undefined
                                                      obj.moduleConfigs.forEach(
                                                        (e) => {
                                                          e.id = _vm
                                                            .uuidv4()
                                                            .replaceAll("-", "")
                                                          e.photoConfigs.forEach(
                                                            (p) => {
                                                              p.id = _vm
                                                                .uuidv4()
                                                                .replaceAll(
                                                                  "-",
                                                                  ""
                                                                )
                                                            }
                                                          )
                                                        }
                                                      )
                                                      _vm.saveDialog.form.taskConfigs.push(
                                                        obj
                                                      )
                                                      _vm.$forceUpdate()
                                                    },
                                                  },
                                                },
                                                [_vm._v("复制并新增任务配置")]
                                              ),
                                              _c(
                                                "el-button",
                                                {
                                                  staticStyle: {
                                                    border: "0px",
                                                  },
                                                  attrs: {
                                                    disabled: index === 0,
                                                    type: "text",
                                                  },
                                                  on: {
                                                    click: () => {
                                                      _vm.saveDialog.form.taskConfigs.splice(
                                                        index,
                                                        1
                                                      )
                                                      _vm.saveDialog.form.taskConfigs.splice(
                                                        index - 1,
                                                        0,
                                                        t
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("任务配置上移")]
                                              ),
                                              _c(
                                                "el-button",
                                                {
                                                  staticStyle: {
                                                    border: "0px",
                                                  },
                                                  attrs: {
                                                    disabled:
                                                      index ===
                                                      _vm.saveDialog.form
                                                        .taskConfigs.length -
                                                        1,
                                                    type: "text",
                                                  },
                                                  on: {
                                                    click: () => {
                                                      _vm.saveDialog.form.taskConfigs.splice(
                                                        index,
                                                        1
                                                      )
                                                      _vm.saveDialog.form.taskConfigs.splice(
                                                        index + 1,
                                                        0,
                                                        t
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("任务配置下移")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }
                      ),
                      _c("el-col", [
                        _c("div", [_c("label", [_vm._v("核销-文本项配置")])]),
                        _c(
                          "div",
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "配置名" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "如：道具上柜",
                                  },
                                  model: {
                                    value: _vm.saveDialog.form.textConfigs.name,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.saveDialog.form.textConfigs,
                                        "name",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "saveDialog.form.textConfigs.name",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              { attrs: { label: "配置备注" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "如：选填",
                                  },
                                  model: {
                                    value:
                                      _vm.saveDialog.form.textConfigs.remark,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.saveDialog.form.textConfigs,
                                        "remark",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "saveDialog.form.textConfigs.remark",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._l(
                              _vm.saveDialog.form.textConfigs.configs,
                              function (ele, i) {
                                return _c(
                                  "el-row",
                                  { key: i },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "描述" } },
                                      [
                                        _c("el-input", {
                                          attrs: { clearable: "" },
                                          model: {
                                            value: ele.desc,
                                            callback: function ($$v) {
                                              _vm.$set(ele, "desc", $$v)
                                            },
                                            expression: "ele.desc",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-form-item",
                                      { attrs: { label: "类型" } },
                                      [
                                        _c(
                                          "el-radio-group",
                                          {
                                            model: {
                                              value: ele.type,
                                              callback: function ($$v) {
                                                _vm.$set(ele, "type", $$v)
                                              },
                                              expression: "ele.type",
                                            },
                                          },
                                          [
                                            _c(
                                              "el-radio",
                                              { attrs: { label: "text" } },
                                              [_vm._v("文本")]
                                            ),
                                            _c(
                                              "el-radio",
                                              { attrs: { label: "number" } },
                                              [_vm._v("数字")]
                                            ),
                                            _c(
                                              "el-radio",
                                              { attrs: { label: "select" } },
                                              [_vm._v("选项")]
                                            ),
                                          ],
                                          1
                                        ),
                                        ele.type === "select"
                                          ? _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  display: "inline-block",
                                                  "margin-left": "10px",
                                                },
                                              },
                                              [
                                                _c("label", [
                                                  _vm._v("选项类型"),
                                                ]),
                                                _c(
                                                  "el-select",
                                                  {
                                                    model: {
                                                      value: ele.chooseType,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          ele,
                                                          "chooseType",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "ele.chooseType",
                                                    },
                                                  },
                                                  [
                                                    _c("el-option", {
                                                      attrs: {
                                                        label: "单选",
                                                        value: "single",
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("el-option", {
                                                      attrs: {
                                                        label: "多选",
                                                        value: "multiple",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c("label", [
                                                  _vm._v("选项数据"),
                                                ]),
                                                _c("el-select", {
                                                  attrs: {
                                                    filterable: "",
                                                    "allow-create": "",
                                                    multiple: "",
                                                    placeholder:
                                                      "请输入后手动选择即可",
                                                  },
                                                  model: {
                                                    value: ele.chooseText,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        ele,
                                                        "chooseText",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "ele.chooseText",
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-form-item",
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            staticStyle: { border: "0px" },
                                            attrs: { type: "text" },
                                            on: {
                                              click: () => {
                                                _vm.saveDialog.form.textConfigs.configs.splice(
                                                  i,
                                                  1
                                                )
                                                _vm.$forceUpdate()
                                              },
                                            },
                                          },
                                          [_vm._v("删除")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              }
                            ),
                            _c(
                              "el-button",
                              {
                                staticStyle: { border: "0px" },
                                attrs: { type: "text" },
                                on: {
                                  click: () => {
                                    let textConfig = _vm.deepClone(
                                      _vm.saveDialog.defaultTextConfig
                                    )
                                    textConfig.id = _vm
                                      .uuidv4()
                                      .replaceAll("-", "")
                                    _vm.saveDialog.form.textConfigs.configs.push(
                                      textConfig
                                    )
                                    _vm.$forceUpdate()
                                  },
                                },
                              },
                              [_vm._v("新增文本配置")]
                            ),
                          ],
                          2
                        ),
                      ]),
                    ],
                    2
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: {
                "text-align": "right",
                position: "absolute",
                right: "50px",
                bottom: "50px",
              },
            },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "mini",
                    disabled: _vm.submitDisabled,
                  },
                  on: { click: _vm.handleSubmit },
                },
                [_vm._v("提交")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.saveDialog.visible = false
                    },
                  },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "操作结果",
            visible: _vm.noticeDialog.visible,
            "append-to-body": "",
            width: "700px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.noticeDialog, "visible", $event)
            },
          },
        },
        [
          _c("div", {
            staticStyle: { "font-size": "16px" },
            domProps: { innerHTML: _vm._s(_vm.noticeDialog.msg) },
          }),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.noticeDialog.visible = false
                    },
                  },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm.currentBoost.show
        ? _c("JL", {
            attrs: {
              "task-id": _vm.currentBoost.taskId,
              "task-info": _vm.currentBoost.taskInfo,
              "import-scope": _vm.currentBoost.importScope,
              "component-type": "HX",
              "component-boost-id": _vm.currentBoost.boostId,
              modules: _vm.currentBoost.modules,
              "boost-info": _vm.currentBoost.boostInfo,
            },
            on: { handleHxBoostChange: _vm.handleHxBoostChange },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }