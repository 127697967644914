var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-tabs",
    {
      on: { "tab-click": (val) => _vm.handleTabClick(val.name) },
      model: {
        value: _vm.tabName,
        callback: function ($$v) {
          _vm.tabName = $$v
        },
        expression: "tabName",
      },
    },
    [
      _c(
        "el-tab-pane",
        { attrs: { name: "skuPriceControl", label: "单品控价表" } },
        [_c("TechSkuPriceControl", { ref: "SkuPriceControl" })],
        1
      ),
      _c(
        "el-tab-pane",
        { attrs: { name: "spuPriceControl", label: "组合装控价" } },
        [_c("TechSpuPriceControl", { ref: "spuPriceControl" })],
        1
      ),
      _c(
        "el-tab-pane",
        {
          attrs: {
            name: "spuSpecifications",
            label: "包装（套装）转化关系维护",
          },
        },
        [
          _c("TechSpuSpecifications", {
            ref: "spuSpecifications",
            on: { handleJumpQuery: _vm.handleJumpQuery },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }