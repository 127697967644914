var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "new-box" },
    [
      _c(
        "div",
        { staticClass: "mg-search" },
        [
          _c(
            "el-card",
            { staticStyle: { margin: "10px" } },
            [
              _c(
                "SearchFilter",
                { on: { search: _vm.handleQuery, reset: _vm.resetQuery } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "分公司" } },
                    [
                      _c("DictSelect", {
                        ref: "org",
                        attrs: {
                          type: "ht-org",
                          value: _vm.queryForm.orgCode,
                          linkageRef: "ht-dc,ht-shop,ht-resp",
                          dictFun: _vm.dictFun,
                          fullLabel: "",
                          clearable: _vm.orgAllAuth,
                          init: true,
                          "init-options": this.options.orgOptions,
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(_vm.queryForm, "orgCode", $event)
                          },
                          changeLinkageRef: _vm.handleChangeLinkageRef,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "配送中心" } },
                    [
                      _c("DictSelect", {
                        ref: "ht-dc",
                        attrs: {
                          type: "ht-dc",
                          value: _vm.queryForm.dcCode,
                          dictFun: _vm.dictFun,
                          parentLinkageRef: "",
                          linkageRef: "ht-shop,ht-resp",
                          fullLabel: "",
                          "collapse-tags": "",
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(_vm.queryForm, "dcCode", $event)
                          },
                          getOtherJsonParam: (callback) =>
                            callback(_vm.dictParam),
                          change: function ($event) {
                            _vm.dictParam = Object.assign({}, _vm.queryForm)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "店铺" } },
                    [
                      _c("DictSelect", {
                        ref: "ht-shop",
                        attrs: {
                          type: "ht-shop",
                          value: _vm.queryForm.shopCode,
                          dictFun: _vm.dictFun,
                          parentLinkageRef: "",
                          fullLabel: "",
                          remote: "",
                          "init-props": { label: "label", value: "fullCode" },
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(_vm.queryForm, "shopCode", $event)
                          },
                          getOtherJsonParam: (callback) =>
                            callback(Object.assign({}, _vm.queryForm)),
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "包干负责人" } },
                    [
                      _c("DictSelect", {
                        ref: "ht-resp",
                        attrs: {
                          type: "ht-resp",
                          fullLabel: "",
                          value: _vm.queryForm.responsible,
                          dictFun: _vm.dictFun,
                          parentLinkageRef: "",
                        },
                        on: {
                          getOtherJsonParam: (callback) =>
                            callback(_vm.dictParam),
                          "update:value": function ($event) {
                            return _vm.$set(
                              _vm.queryForm,
                              "responsible",
                              $event
                            )
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.options.hxOptions.length > 0
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "核销名称" } },
                        [
                          _c("DictSelect", {
                            ref: "hx",
                            attrs: {
                              type: "hx",
                              value: _vm.queryForm.id,
                              init: "",
                              "init-options": _vm.options.hxOptions,
                              clearable: false,
                              initProps: { label: "name", value: "id" },
                            },
                            on: {
                              "update:value": function ($event) {
                                return _vm.$set(_vm.queryForm, "id", $event)
                              },
                              change: _vm.handleHxChange,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.options.hxOptions.length > 0
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "阵地" } },
                        [
                          _c("DictSelect", {
                            ref: "module",
                            attrs: {
                              type: "module",
                              value: _vm.queryForm.moduleIds,
                              multiple: "",
                              init: "",
                              "init-options": _vm.options.moduleOptions,
                              clearable: false,
                            },
                            on: {
                              "update:value": function ($event) {
                                return _vm.$set(
                                  _vm.queryForm,
                                  "moduleIds",
                                  $event
                                )
                              },
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    { attrs: { label: "提交时间" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          type: "daterange",
                          "start-placeholder": "开始时间",
                          "end-placeholder": "结束时间",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          "default-time": ["00:00:00", "23:59:59"],
                        },
                        model: {
                          value: _vm.queryDate,
                          callback: function ($$v) {
                            _vm.queryDate = $$v
                          },
                          expression: "queryDate",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "审批状态" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "", placeholder: "请选择" },
                          model: {
                            value: _vm.queryForm.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryForm, "status", $$v)
                            },
                            expression: "queryForm.status",
                          },
                        },
                        _vm._l(_vm.options.statusOptions, function (item, key) {
                          return _c("el-option", {
                            key: key,
                            attrs: { label: item.text, value: item.status },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-card",
        { staticClass: "box-card", staticStyle: { margin: "10px" } },
        [
          _c(
            "div",
            { staticStyle: { margin: "0 0 10px 0" } },
            [
              _vm.orgAuth || _vm.headAuth
                ? _c(
                    "el-button",
                    {
                      staticClass: "form-btn-item",
                      attrs: { size: "mini", type: "success" },
                      on: {
                        click: function ($event) {
                          return _vm.handleBatchAudit("1")
                        },
                      },
                    },
                    [_vm._v("批量通过")]
                  )
                : _vm._e(),
              _vm.orgAuth || _vm.headAuth
                ? _c(
                    "el-button",
                    {
                      staticClass: "form-btn-item",
                      attrs: { size: "mini", type: "danger" },
                      on: {
                        click: function ($event) {
                          return _vm.handleBatchAudit("2")
                        },
                      },
                    },
                    [_vm._v("批量驳回")]
                  )
                : _vm._e(),
              _c(
                "el-button",
                {
                  staticClass: "form-btn-item",
                  attrs: { size: "mini", type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.handleExport(undefined)
                    },
                  },
                },
                [_vm._v("导出")]
              ),
              _c(
                "el-popover",
                {
                  attrs: { placement: "right", width: "600", trigger: "click" },
                  on: { show: _vm.handlePageDownload },
                },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 8 } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请选择",
                                  },
                                  model: {
                                    value: _vm.downloadData.queryForm.status,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.downloadData.queryForm,
                                        "status",
                                        $$v
                                      )
                                    },
                                    expression: "downloadData.queryForm.status",
                                  },
                                },
                                _vm._l(
                                  _vm.downloadData.downloadStatusMap,
                                  function (item, key) {
                                    return _c("el-option", {
                                      key: key,
                                      attrs: { label: item, value: key },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 3 } },
                            [
                              _c(
                                "el-button",
                                {
                                  staticClass: "form-btn-item",
                                  attrs: {
                                    size: "mini",
                                    icon: "el-icon-search",
                                    type: "primary",
                                  },
                                  on: { click: _vm.handlePageDownload },
                                },
                                [_vm._v("刷新")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-table",
                        {
                          directives: [
                            {
                              name: "loading",
                              rawName: "v-loading",
                              value: _vm.downloadData.loading,
                              expression: "downloadData.loading",
                            },
                          ],
                          attrs: { data: _vm.downloadData.rows, size: "mini" },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              width: "300",
                              property: "fileName",
                              label: "文件名",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              width: "140",
                              property: "createTime",
                              label: "时间",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              width: "80",
                              property: "status",
                              label: "状态",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ row }) {
                                  return [
                                    _c(
                                      "el-tag",
                                      {
                                        attrs: {
                                          type: _vm.$options.filters.downloadStatusFilter(
                                            row.status
                                          ),
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.downloadData
                                                .downloadStatusMap[row.status]
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: { label: "操作", width: "80" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ row }) {
                                  return [
                                    row.status === "1"
                                      ? _c(
                                          "el-link",
                                          {
                                            attrs: {
                                              type: "primary",
                                              target: "_blank",
                                              href: row.downloadUrl,
                                            },
                                          },
                                          [_vm._v("下载")]
                                        )
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                      _c("Pagination", {
                        attrs: {
                          total: _vm.downloadData.total,
                          page: _vm.downloadData.queryForm.current,
                          pageSizes: [3, 5, 10],
                          limit: _vm.downloadData.queryForm.size,
                        },
                        on: {
                          "update:page": function ($event) {
                            return _vm.$set(
                              _vm.downloadData.queryForm,
                              "current",
                              $event
                            )
                          },
                          "update:limit": function ($event) {
                            return _vm.$set(
                              _vm.downloadData.queryForm,
                              "size",
                              $event
                            )
                          },
                          pagination: _vm.handlePageDownload,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "form-btn-item",
                      staticStyle: { "margin-left": "10px" },
                      attrs: {
                        slot: "reference",
                        size: "mini",
                        type: "primary",
                      },
                      slot: "reference",
                    },
                    [_vm._v("查看导出列表")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "table",
              staticClass: "customer-table",
              attrs: {
                data: _vm.pageData.rows,
                size: "small",
                border: "",
                "highlight-current-row": "",
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "selection",
                  width: "60",
                  align: "center",
                  selectable: (row, index) => {
                    return (
                      _vm.computeButtonShow(row.status, "pass") ||
                      _vm.computeButtonShow(row.status, "reject")
                    )
                  },
                },
              }),
              _c("el-table-column", {
                attrs: { label: "核销名称", prop: "name", width: "160px" },
              }),
              _c("el-table-column", {
                attrs: { label: "分公司", prop: "shop", width: "120px" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(row.orgCode + "-" + row.orgName)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "所属配送中心", prop: "dcName", width: "180" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(row.dcCode + "-" + row.dcName)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "店铺", prop: "shop", width: "220" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(row.shopCode + "-" + row.shopName)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "提交人", prop: "createUser", width: "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              row.userName
                                ? row.userName +
                                    (row.userName ? "-" + row.nickName : "")
                                : ""
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "提交时间", prop: "createTime", width: "180" },
              }),
              _c("el-table-column", {
                attrs: { label: "审批状态", prop: "status", width: "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "el-tag",
                          {
                            attrs: {
                              type: _vm.$options.filters.statusFilter(
                                row.status
                              ),
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.getRowLabel(row)) + " ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "操作", width: "140", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "div",
                          [
                            _vm.computeButtonShow(row.status, "pass") ||
                            _vm.computeButtonShow(row.status, "reject")
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini", type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleAudit(row, "audit")
                                      },
                                    },
                                  },
                                  [_vm._v("审批")]
                                )
                              : _vm._e(),
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleAudit(row, "view")
                                  },
                                },
                              },
                              [_vm._v("查看")]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "照片", width: _vm.computeMaxWidth },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return _vm._l(row.photos, function (item, index) {
                        return _c(
                          "el-image",
                          {
                            key: index,
                            staticStyle: {
                              display: "inline-block",
                              height: "80px",
                              width: "60px",
                            },
                            attrs: {
                              src: item,
                              "preview-src-list": row.photos,
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "image-slot",
                                attrs: { slot: "error" },
                                slot: "error",
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-picture-outline",
                                }),
                              ]
                            ),
                          ]
                        )
                      })
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("Pagination", {
            attrs: {
              total: _vm.pageData.total,
              page: _vm.queryForm.current,
              pageSizes: [10, 20, 50, 100, 200],
              limit: _vm.queryForm.size,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.queryForm, "current", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.queryForm, "size", $event)
              },
              pagination: _vm.handleQuery,
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          ref: "editDialogRef",
          attrs: {
            title: _vm.editDialog.type === "view" ? "查看" : "审批",
            visible: _vm.editDialog.visible,
            "append-to-body": "",
            width: "800px",
            "close-on-click-modal": false,
            tabindex: "1",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.editDialog, "visible", $event)
            },
            opened: _vm.onDialogOpened,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "common-form",
              attrs: {
                size: "mini",
                inline: "",
                rules: _vm.editDialog.rules,
                "label-width": "160px",
                model: _vm.editDialog.form,
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-row",
                { staticClass: "common-form-row" },
                [
                  !_vm.editDialog.form.responsible
                    ? _c(
                        "el-col",
                        {
                          staticStyle: {
                            color: "red",
                            "font-size": "16px",
                            "text-align": "center",
                          },
                        },
                        [
                          _vm._v(
                            "当前店铺未绑定包干负责人,请尽快维护,以免影响相关奖励发放"
                          ),
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "el-descriptions",
                    { attrs: { title: "终端详情" } },
                    [
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "分公司" } },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.editDialog.form.orgCode +
                                "-" +
                                _vm.editDialog.form.orgName
                            )
                          ),
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "配送中心" } },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.editDialog.form.dcCode +
                                "-" +
                                _vm.editDialog.form.dcName
                            )
                          ),
                        ]
                      ),
                      _c("el-descriptions-item", { attrs: { label: "店铺" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.editDialog.form.shopCode +
                              "-" +
                              _vm.editDialog.form.shopName
                          )
                        ),
                      ]),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "包干负责人" } },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.editDialog.form.responsible
                                ? _vm.editDialog.form.responsible +
                                    "-" +
                                    _vm.editDialog.form.responsibler
                                : ""
                            )
                          ),
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "是否重点店铺" } },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.editDialog.form.ifOptimumShop === "1"
                                ? "是"
                                : "否"
                            )
                          ),
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "终端容量标签" } },
                        [_vm._v(_vm._s(_vm.editDialog.form.shopCapacity))]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "提交人" } },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.editDialog.form.userName +
                                "-" +
                                _vm.editDialog.form.nickName
                            )
                          ),
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "提交时间" } },
                        [_vm._v(_vm._s(_vm.editDialog.form.createTime))]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-descriptions",
                    { attrs: { title: "核销信息" } },
                    [
                      _c(
                        "el-descriptions-item",
                        { attrs: { label: "门头照片", span: 3 } },
                        [
                          _vm.getConfigVersion() === "V3" &&
                          _vm.editDialog.hxForm.contentMap &&
                          _vm.editDialog.hxForm.contentMap.doorHeadPhotoV3 &&
                          _vm.parseJsonData(
                            _vm.editDialog.hxForm.contentMap.doorHeadPhotoV3,
                            "show"
                          )
                            ? _c(
                                "div",
                                _vm._l(
                                  _vm.getModulePhotos(
                                    _vm.editDialog.hxForm.contentMap
                                      .doorHeadPhotoV3
                                  ),
                                  function (item, index) {
                                    return _c(
                                      "el-image",
                                      {
                                        key: index,
                                        staticStyle: {
                                          height: "80px",
                                          margin: "5px",
                                        },
                                        attrs: {
                                          "preview-src-list":
                                            _vm.getModulePhotos(
                                              _vm.editDialog.hxForm.contentMap
                                                .doorHeadPhotoV3
                                            ),
                                          src: item,
                                          lazy: "",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "image-slot",
                                            attrs: { slot: "error" },
                                            slot: "error",
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "el-icon-picture-outline",
                                            }),
                                          ]
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                1
                              )
                            : _vm.editDialog.hxForm.contentMap &&
                              _vm.editDialog.hxForm.contentMap.doorHeadPhoto
                            ? _c(
                                "div",
                                _vm._l(
                                  _vm.editDialog.hxForm.contentMap
                                    .doorHeadPhoto,
                                  function (item, index) {
                                    return _c(
                                      "el-image",
                                      {
                                        key: index,
                                        staticStyle: {
                                          height: "80px",
                                          margin: "5px",
                                        },
                                        attrs: {
                                          "preview-src-list": [item],
                                          src: item,
                                          lazy: "",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "image-slot",
                                            attrs: { slot: "error" },
                                            slot: "error",
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "el-icon-picture-outline",
                                            }),
                                          ]
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                1
                              )
                            : _vm._e(),
                        ]
                      ),
                      _vm._l(_vm.getRealTasks(), function (item, index) {
                        return _c(
                          "el-descriptions-item",
                          {
                            key: index,
                            attrs: { label: "任务" + (index + 1), span: 18 },
                          },
                          [
                            _c(
                              "div",
                              [
                                _c("label", [_vm._v(_vm._s(item.taskName))]),
                                _vm.getConfigVersion(item.mtTaskId) !== "V2" &&
                                item.boostDetail
                                  ? _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          "background-color": "#FCF7EE",
                                          "border-radius": "5px",
                                          margin: "10px",
                                          padding: "5px",
                                        },
                                      },
                                      [
                                        _c(
                                          "p",
                                          { staticStyle: { color: "#A56321" } },
                                          [
                                            _c("strong", [
                                              _vm._v(
                                                _vm._s(
                                                  item.boostDetail.boostName
                                                ) + "："
                                              ),
                                            ]),
                                            _vm._v(
                                              _vm._s(
                                                item.boostDetail
                                                  .boostDescription
                                              )
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          _vm._l(
                                            item.boostDetail.list,
                                            function (m) {
                                              return _c(
                                                "p",
                                                { key: m.measure },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(m.measureName)
                                                    ),
                                                  ]),
                                                  _c(
                                                    "span",
                                                    {
                                                      style:
                                                        m.measureData >=
                                                        m.boostMeasureData
                                                          ? { color: "red" }
                                                          : {
                                                              color: "#3B8DDA",
                                                            },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            m.measureData
                                                              ? m.measureData
                                                              : 0
                                                          ) +
                                                          " / " +
                                                          _vm._s(
                                                            m.boostMeasureData
                                                              ? m.boostMeasureData
                                                              : 0
                                                          )
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.measureMap[m.measure]
                                                        .unit
                                                    ) + " "
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _c("span", [_vm._v("上传照片")]),
                                _vm._l(
                                  _vm.getTaskBoostPhoto(
                                    item.mtTaskId,
                                    item.boostId
                                  ),
                                  function (e, indx) {
                                    return _c("div", { key: indx }, [
                                      _c("label", [_vm._v(_vm._s(e.name))]),
                                      _vm.editDialog.hxForm.firstModules.indexOf(
                                        e.id
                                      ) > -1
                                        ? _c(
                                            "label",
                                            {
                                              staticStyle: {
                                                color: "red",
                                                "margin-left": "5px",
                                              },
                                            },
                                            [_vm._v("首次打造")]
                                          )
                                        : _vm._e(),
                                      _vm.getConfigVersion(item.mtTaskId) ===
                                      "V3"
                                        ? _c(
                                            "div",
                                            _vm._l(
                                              e.photoConfigs,
                                              function (p, pIndex) {
                                                return _c(
                                                  "div",
                                                  { key: pIndex },
                                                  [
                                                    _c(
                                                      "p",
                                                      {
                                                        staticStyle: {
                                                          margin: "8px 0px",
                                                          "font-size": "13px",
                                                          color: "#858585",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(p.name) + " "
                                                        ),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(p.remark)
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          display:
                                                            "inline-block",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "p",
                                                          {
                                                            staticStyle: {
                                                              margin: "8px 0px",
                                                              "font-size":
                                                                "13px",
                                                              color: "#858585",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(p.desc)
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._l(
                                                          p.photos,
                                                          function (
                                                            im,
                                                            imIndex
                                                          ) {
                                                            return _c(
                                                              "div",
                                                              {
                                                                key: imIndex,
                                                                staticStyle: {
                                                                  display:
                                                                    "inline-block",
                                                                },
                                                                attrs: {
                                                                  title:
                                                                    _vm.getPhotoTitle(
                                                                      _vm.translateResourceV3(
                                                                        [im],
                                                                        "image"
                                                                      )[0]
                                                                    ),
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "el-image",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        height:
                                                                          "80px",
                                                                        margin:
                                                                          "5px",
                                                                      },
                                                                    attrs: {
                                                                      src: _vm.translateResourceV3(
                                                                        [im],
                                                                        "image"
                                                                      )[0],
                                                                      lazy: "",
                                                                      "preview-src-list":
                                                                        _vm.translateResourceV3(
                                                                          p.photos,
                                                                          "image"
                                                                        ),
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "image-slot",
                                                                        attrs: {
                                                                          slot: "error",
                                                                        },
                                                                        slot: "error",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "i",
                                                                          {
                                                                            staticClass:
                                                                              "el-icon-picture-outline",
                                                                          }
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          }
                                                        ),
                                                      ],
                                                      2
                                                    ),
                                                    p.showAfter
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticStyle: {
                                                              display:
                                                                "inline-block",
                                                              "margin-left":
                                                                "20px",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "p",
                                                              {
                                                                staticStyle: {
                                                                  margin:
                                                                    "8px 0px",
                                                                  "font-size":
                                                                    "13px",
                                                                  color:
                                                                    "#858585",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    p.descAfter
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._l(
                                                              p.photosAfter,
                                                              function (
                                                                im,
                                                                imIndex
                                                              ) {
                                                                return _c(
                                                                  "div",
                                                                  {
                                                                    key: imIndex,
                                                                    staticStyle:
                                                                      {
                                                                        display:
                                                                          "inline-block",
                                                                      },
                                                                    attrs: {
                                                                      title:
                                                                        _vm.getPhotoTitle(
                                                                          _vm.translateResourceV3(
                                                                            [
                                                                              im,
                                                                            ],
                                                                            "image"
                                                                          )[0]
                                                                        ),
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "el-image",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            height:
                                                                              "80px",
                                                                            margin:
                                                                              "5px",
                                                                          },
                                                                        attrs: {
                                                                          src: _vm.translateResourceV3(
                                                                            [
                                                                              im,
                                                                            ],
                                                                            "image"
                                                                          )[0],
                                                                          lazy: "",
                                                                          "preview-src-list":
                                                                            _vm.translateResourceV3(
                                                                              p.photosAfter,
                                                                              "image"
                                                                            ),
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            staticClass:
                                                                              "image-slot",
                                                                            attrs:
                                                                              {
                                                                                slot: "error",
                                                                              },
                                                                            slot: "error",
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "i",
                                                                              {
                                                                                staticClass:
                                                                                  "el-icon-picture-outline",
                                                                              }
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              }
                                                            ),
                                                          ],
                                                          2
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          )
                                        : _vm._e(),
                                      _vm.getConfigVersion(item.mtTaskId) ===
                                      "V2"
                                        ? _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "background-color": "#FCF7EE",
                                                "border-radius": "5px",
                                                margin: "10px",
                                                padding: "5px",
                                                width: "400px",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-row",
                                                [
                                                  _c(
                                                    "el-col",
                                                    {
                                                      staticStyle: {
                                                        "background-color":
                                                          "#FCF0DB",
                                                        "border-radius": "4px",
                                                        padding: "5px",
                                                      },
                                                      attrs: { span: 6 },
                                                    },
                                                    [_vm._v("本月进货额")]
                                                  ),
                                                  _c(
                                                    "el-col",
                                                    {
                                                      staticStyle: {
                                                        "background-color":
                                                          "#FCF0DB",
                                                        "border-radius": "4px",
                                                        padding: "5px",
                                                      },
                                                      attrs: { span: 6 },
                                                    },
                                                    [_vm._v("近90天进货金额")]
                                                  ),
                                                  _c(
                                                    "el-col",
                                                    {
                                                      staticStyle: {
                                                        "background-color":
                                                          "#FCF0DB",
                                                        "border-radius": "4px",
                                                        padding: "5px",
                                                      },
                                                      attrs: { span: 6 },
                                                    },
                                                    [_vm._v("近90天上柜款数")]
                                                  ),
                                                  _c(
                                                    "el-col",
                                                    {
                                                      staticStyle: {
                                                        "background-color":
                                                          "#FCF0DB",
                                                        "border-radius": "4px",
                                                        padding: "5px",
                                                      },
                                                      attrs: { span: 6 },
                                                    },
                                                    [_vm._v("阵地款数目标")]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "el-row",
                                                [
                                                  _c(
                                                    "el-col",
                                                    {
                                                      staticStyle: {
                                                        "background-color":
                                                          "#FCF0DB",
                                                        "border-radius": "4px",
                                                        padding: "5px",
                                                      },
                                                      attrs: { span: 6 },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          item.boostDetail
                                                            .shopByJhTotal
                                                            ? item.boostDetail
                                                                .shopByJhTotal
                                                            : 0
                                                        ) + " 元"
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "el-col",
                                                    {
                                                      staticStyle: {
                                                        "background-color":
                                                          "#FCF0DB",
                                                        "border-radius": "4px",
                                                        padding: "5px",
                                                      },
                                                      attrs: { span: 6 },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          item.boostDetail
                                                            .shopJhTotal
                                                            ? item.boostDetail
                                                                .shopJhTotal
                                                            : 0
                                                        ) + " 元"
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "el-col",
                                                    {
                                                      staticStyle: {
                                                        "background-color":
                                                          "#FCF0DB",
                                                        "border-radius": "4px",
                                                        padding: "5px",
                                                      },
                                                      attrs: { span: 6 },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          item.boostDetail
                                                            .shopJhKss
                                                            ? item.boostDetail
                                                                .shopJhKss
                                                            : 0
                                                        ) + " 款"
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "el-col",
                                                    {
                                                      staticStyle: {
                                                        "background-color":
                                                          "#FCF0DB",
                                                        "border-radius": "4px",
                                                        padding: "5px",
                                                      },
                                                      attrs: { span: 6 },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          item.boostDetail
                                                            .moduleTarget
                                                            ? item.boostDetail
                                                                .moduleTarget
                                                            : 0
                                                        ) + " 款"
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c("div", [
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      display: "inline-block",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "p",
                                                      {
                                                        staticStyle: {
                                                          margin: "8px 0px",
                                                          "font-size": "13px",
                                                          color: "#858585",
                                                        },
                                                      },
                                                      [_vm._v(_vm._s(e.desc))]
                                                    ),
                                                    _vm._l(
                                                      e.photos,
                                                      function (im, imIndex) {
                                                        return _c(
                                                          "div",
                                                          {
                                                            key: imIndex,
                                                            staticStyle: {
                                                              display:
                                                                "inline-block",
                                                            },
                                                            attrs: {
                                                              title:
                                                                _vm.getPhotoTitle(
                                                                  im
                                                                ),
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "el-image",
                                                              {
                                                                staticStyle: {
                                                                  height:
                                                                    "80px",
                                                                  margin: "5px",
                                                                },
                                                                attrs: {
                                                                  src: im,
                                                                  lazy: "",
                                                                  "preview-src-list":
                                                                    e.photos,
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "image-slot",
                                                                    attrs: {
                                                                      slot: "error",
                                                                    },
                                                                    slot: "error",
                                                                  },
                                                                  [
                                                                    _c("i", {
                                                                      staticClass:
                                                                        "el-icon-picture-outline",
                                                                    }),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      }
                                                    ),
                                                  ],
                                                  2
                                                ),
                                                e.showAfter
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          display:
                                                            "inline-block",
                                                          "margin-left": "20px",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "p",
                                                          {
                                                            staticStyle: {
                                                              margin: "8px 0px",
                                                              "font-size":
                                                                "13px",
                                                              color: "#858585",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                e.descAfter
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._l(
                                                          e.photosAfter,
                                                          function (
                                                            im,
                                                            imIndex
                                                          ) {
                                                            return _c(
                                                              "div",
                                                              {
                                                                key: imIndex,
                                                                staticStyle: {
                                                                  display:
                                                                    "inline-block",
                                                                },
                                                                attrs: {
                                                                  title:
                                                                    _vm.getPhotoTitle(
                                                                      im
                                                                    ),
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "el-image",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        height:
                                                                          "80px",
                                                                        margin:
                                                                          "5px",
                                                                      },
                                                                    attrs: {
                                                                      src: im,
                                                                      lazy: "",
                                                                      "preview-src-list":
                                                                        e.photosAfter,
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "image-slot",
                                                                        attrs: {
                                                                          slot: "error",
                                                                        },
                                                                        slot: "error",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "i",
                                                                          {
                                                                            staticClass:
                                                                              "el-icon-picture-outline",
                                                                          }
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          }
                                                        ),
                                                      ],
                                                      2
                                                    )
                                                  : _vm._e(),
                                              ]),
                                            ],
                                            1
                                          )
                                        : _c("div", [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  display: "inline-block",
                                                },
                                              },
                                              [
                                                _c(
                                                  "p",
                                                  {
                                                    staticStyle: {
                                                      margin: "8px 0px",
                                                      "font-size": "13px",
                                                      color: "#858585",
                                                    },
                                                  },
                                                  [_vm._v(_vm._s(e.desc))]
                                                ),
                                                _vm._l(
                                                  e.photos,
                                                  function (im, imIndex) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        key: imIndex,
                                                        staticStyle: {
                                                          display:
                                                            "inline-block",
                                                        },
                                                        attrs: {
                                                          title:
                                                            _vm.getPhotoTitle(
                                                              im
                                                            ),
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "el-image",
                                                          {
                                                            staticStyle: {
                                                              height: "80px",
                                                              margin: "5px",
                                                            },
                                                            attrs: {
                                                              src: im,
                                                              lazy: "",
                                                              "preview-src-list":
                                                                e.photos,
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "image-slot",
                                                                attrs: {
                                                                  slot: "error",
                                                                },
                                                                slot: "error",
                                                              },
                                                              [
                                                                _c("i", {
                                                                  staticClass:
                                                                    "el-icon-picture-outline",
                                                                }),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                              ],
                                              2
                                            ),
                                            e.showAfter
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      display: "inline-block",
                                                      "margin-left": "20px",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "p",
                                                      {
                                                        staticStyle: {
                                                          margin: "8px 0px",
                                                          "font-size": "13px",
                                                          color: "#858585",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(e.descAfter)
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._l(
                                                      e.photosAfter,
                                                      function (im, imIndex) {
                                                        return _c(
                                                          "div",
                                                          {
                                                            key: imIndex,
                                                            staticStyle: {
                                                              display:
                                                                "inline-block",
                                                            },
                                                            attrs: {
                                                              title:
                                                                _vm.getPhotoTitle(
                                                                  im
                                                                ),
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "el-image",
                                                              {
                                                                staticStyle: {
                                                                  height:
                                                                    "80px",
                                                                  margin: "5px",
                                                                },
                                                                attrs: {
                                                                  src: im,
                                                                  lazy: "",
                                                                  "preview-src-list":
                                                                    e.photosAfter,
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "image-slot",
                                                                    attrs: {
                                                                      slot: "error",
                                                                    },
                                                                    slot: "error",
                                                                  },
                                                                  [
                                                                    _c("i", {
                                                                      staticClass:
                                                                        "el-icon-picture-outline",
                                                                    }),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      }
                                                    ),
                                                  ],
                                                  2
                                                )
                                              : _vm._e(),
                                          ]),
                                    ])
                                  }
                                ),
                              ],
                              2
                            ),
                          ]
                        )
                      }),
                      _vm._l(
                        _vm.getRealHxTaskData(_vm.editDialog.hxForm),
                        function (item, index) {
                          return _c(
                            "el-descriptions-item",
                            {
                              key: index,
                              attrs: { label: "任务" + (index + 1), span: 18 },
                            },
                            [
                              _c(
                                "div",
                                _vm._l(item.boosts, function (e, indx) {
                                  return _c(
                                    "el-row",
                                    {
                                      key: indx,
                                      staticStyle: {
                                        width: "100%",
                                        display: "block",
                                        flex: "1",
                                      },
                                    },
                                    [
                                      _c(
                                        "p",
                                        { staticStyle: { color: "#A56321" } },
                                        [
                                          _c("strong", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getTaskInfo(item, e.id)
                                                  .boostDetail.boostName
                                              ) + "："
                                            ),
                                          ]),
                                          _vm._v(
                                            _vm._s(
                                              _vm.getTaskInfo(item, e.id)
                                                .boostDetail.boostDescription
                                            )
                                          ),
                                        ]
                                      ),
                                      _vm.getTaskInfo(item, e.id).boostDetail
                                        ? _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "background-color": "#FCF7EE",
                                                "border-radius": "5px",
                                                margin: "10px",
                                                padding: "5px",
                                              },
                                            },
                                            _vm._l(
                                              _vm.getTaskInfo(item, e.id)
                                                .boostDetail.list,
                                              function (m, mIndex) {
                                                return _c(
                                                  "p",
                                                  { key: mIndex },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(m.measureName)
                                                      ),
                                                    ]),
                                                    _c(
                                                      "span",
                                                      {
                                                        style:
                                                          m.measureData >=
                                                          m.boostMeasureData
                                                            ? { color: "red" }
                                                            : {
                                                                color:
                                                                  "#3B8DDA",
                                                              },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              m.measureData
                                                                ? m.measureData
                                                                : 0
                                                            ) +
                                                            " / " +
                                                            _vm._s(
                                                              m.boostMeasureData
                                                                ? m.boostMeasureData
                                                                : 0
                                                            )
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.measureMap[
                                                          m.measure
                                                        ].unit
                                                      ) + " "
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          )
                                        : _vm._e(),
                                      _vm._l(
                                        e.moduleConfigs,
                                        function (md, mdIndex) {
                                          return _c(
                                            "div",
                                            { key: mdIndex },
                                            [
                                              _c("label", [
                                                _vm._v(_vm._s(md.name)),
                                              ]),
                                              _vm.editDialog.hxForm.firstModules.indexOf(
                                                md.id
                                              ) > -1
                                                ? _c(
                                                    "label",
                                                    {
                                                      staticStyle: {
                                                        color: "red",
                                                        "margin-left": "5px",
                                                      },
                                                    },
                                                    [_vm._v("首次打造")]
                                                  )
                                                : _vm._e(),
                                              _vm._l(
                                                _vm.getResourceV3(
                                                  md.id,
                                                  "photoConfigs"
                                                ),
                                                function (p, pIndex) {
                                                  return _c(
                                                    "div",
                                                    { key: pIndex },
                                                    [
                                                      _c(
                                                        "p",
                                                        {
                                                          staticStyle: {
                                                            margin: "8px 0px",
                                                            "font-size": "13px",
                                                            color: "#858585",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(p.name) + " "
                                                          ),
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(p.remark)
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticStyle: {
                                                            display:
                                                              "inline-block",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "p",
                                                            {
                                                              staticStyle: {
                                                                margin:
                                                                  "8px 0px",
                                                                "font-size":
                                                                  "13px",
                                                                color:
                                                                  "#858585",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(p.desc)
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._l(
                                                            p.photos,
                                                            function (
                                                              im,
                                                              imIndex
                                                            ) {
                                                              return _c(
                                                                "div",
                                                                {
                                                                  key: imIndex,
                                                                  staticStyle: {
                                                                    display:
                                                                      "inline-block",
                                                                  },
                                                                  attrs: {
                                                                    title:
                                                                      _vm.getPhotoTitle(
                                                                        _vm.translateResourceV3(
                                                                          [im],
                                                                          "image"
                                                                        )[0]
                                                                      ),
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "el-image",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          height:
                                                                            "80px",
                                                                          margin:
                                                                            "5px",
                                                                        },
                                                                      attrs: {
                                                                        src: _vm.translateResourceV3(
                                                                          [im],
                                                                          "image"
                                                                        )[0],
                                                                        lazy: "",
                                                                        "preview-src-list":
                                                                          _vm.translateResourceV3(
                                                                            p.photos,
                                                                            "image"
                                                                          ),
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "image-slot",
                                                                          attrs:
                                                                            {
                                                                              slot: "error",
                                                                            },
                                                                          slot: "error",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "i",
                                                                            {
                                                                              staticClass:
                                                                                "el-icon-picture-outline",
                                                                            }
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            }
                                                          ),
                                                        ],
                                                        2
                                                      ),
                                                      p.showAfter
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticStyle: {
                                                                display:
                                                                  "inline-block",
                                                                "margin-left":
                                                                  "20px",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "p",
                                                                {
                                                                  staticStyle: {
                                                                    margin:
                                                                      "8px 0px",
                                                                    "font-size":
                                                                      "13px",
                                                                    color:
                                                                      "#858585",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      p.descAfter
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._l(
                                                                p.photosAfter,
                                                                function (
                                                                  im,
                                                                  imIndex
                                                                ) {
                                                                  return _c(
                                                                    "div",
                                                                    {
                                                                      key: imIndex,
                                                                      staticStyle:
                                                                        {
                                                                          display:
                                                                            "inline-block",
                                                                        },
                                                                      attrs: {
                                                                        title:
                                                                          _vm.getPhotoTitle(
                                                                            _vm.translateResourceV3(
                                                                              [
                                                                                im,
                                                                              ],
                                                                              "image"
                                                                            )[0]
                                                                          ),
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "el-image",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              height:
                                                                                "80px",
                                                                              margin:
                                                                                "5px",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              src: _vm.translateResourceV3(
                                                                                [
                                                                                  im,
                                                                                ],
                                                                                "image"
                                                                              )[0],
                                                                              lazy: "",
                                                                              "preview-src-list":
                                                                                _vm.translateResourceV3(
                                                                                  p.photosAfter,
                                                                                  "image"
                                                                                ),
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "image-slot",
                                                                              attrs:
                                                                                {
                                                                                  slot: "error",
                                                                                },
                                                                              slot: "error",
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "i",
                                                                                {
                                                                                  staticClass:
                                                                                    "el-icon-picture-outline",
                                                                                }
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                }
                                                              ),
                                                            ],
                                                            2
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  )
                                                }
                                              ),
                                              md.textConfigs
                                                ? _c(
                                                    "el-row",
                                                    [
                                                      _c("el-col", [
                                                        _c(
                                                          "label",
                                                          {
                                                            staticStyle: {
                                                              "font-size":
                                                                "16px",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  md.textConfigs
                                                                    .name
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                        _c("span", [
                                                          _vm._v(
                                                            _vm._s(
                                                              md.textConfigs
                                                                .remark
                                                            )
                                                          ),
                                                        ]),
                                                      ]),
                                                      _c(
                                                        "el-col",
                                                        {
                                                          staticStyle: {
                                                            "margin-left":
                                                              "20px",
                                                          },
                                                          attrs: { span: 18 },
                                                        },
                                                        _vm._l(
                                                          _vm.getResourceV3(
                                                            md.id,
                                                            "textConfigs"
                                                          ).configs,
                                                          function (t) {
                                                            return _c(
                                                              "el-col",
                                                              { key: t.id },
                                                              [
                                                                _c(
                                                                  "el-checkbox",
                                                                  {
                                                                    staticClass:
                                                                      "checked-class",
                                                                    attrs: {
                                                                      disabled:
                                                                        "",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        t.checked,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            t,
                                                                            "checked",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "t.checked",
                                                                    },
                                                                  }
                                                                ),
                                                                _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      t.desc
                                                                    )
                                                                  ),
                                                                ]),
                                                                t.chooseType !=
                                                                "select"
                                                                  ? _c("span", [
                                                                      t.count !=
                                                                      undefined
                                                                        ? _c(
                                                                            "span",
                                                                            {
                                                                              staticStyle:
                                                                                {
                                                                                  "margin-left":
                                                                                    "10px",
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    item.count
                                                                                  )
                                                                              ),
                                                                            ]
                                                                          )
                                                                        : _vm._e(),
                                                                    ])
                                                                  : _c(
                                                                      "span",
                                                                      [
                                                                        _c(
                                                                          "el-select",
                                                                          {
                                                                            model:
                                                                              {
                                                                                value:
                                                                                  t.count,
                                                                                callback:
                                                                                  function (
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      t,
                                                                                      "count",
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                expression:
                                                                                  "t.count",
                                                                              },
                                                                          },
                                                                          _vm._l(
                                                                            t.chooseText,
                                                                            function (
                                                                              c
                                                                            ) {
                                                                              return _c(
                                                                                "el-option",
                                                                                {
                                                                                  key: c,
                                                                                  attrs:
                                                                                    {
                                                                                      label:
                                                                                        c,
                                                                                      value:
                                                                                        c,
                                                                                    },
                                                                                }
                                                                              )
                                                                            }
                                                                          ),
                                                                          1
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                              ],
                                                              1
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ],
                                            2
                                          )
                                        }
                                      ),
                                      _c("div"),
                                    ],
                                    2
                                  )
                                }),
                                1
                              ),
                            ]
                          )
                        }
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { staticStyle: { display: "inline-block" } },
                [
                  _vm.getConfigVersion() === "V3" &&
                  _vm.editDialog.hxForm.contentMap.textConfigs
                    ? _c(
                        "el-row",
                        {},
                        [
                          _c("el-col", [
                            _c(
                              "label",
                              { staticStyle: { "font-size": "16px" } },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.editDialog.hxForm.contentMap
                                        .textConfigs.name
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.editDialog.hxForm.contentMap.textConfigs
                                    .remark
                                )
                              ),
                            ]),
                          ]),
                          _c(
                            "el-col",
                            {
                              staticStyle: { "margin-left": "20px" },
                              attrs: { span: 18 },
                            },
                            _vm._l(
                              _vm.editDialog.hxForm.contentMap.textConfigs
                                .configs,
                              function (item) {
                                return _c(
                                  "el-col",
                                  { key: item.id },
                                  [
                                    _c("el-checkbox", {
                                      staticClass: "checked-class",
                                      attrs: { disabled: "" },
                                      model: {
                                        value: item.checked,
                                        callback: function ($$v) {
                                          _vm.$set(item, "checked", $$v)
                                        },
                                        expression: "item.checked",
                                      },
                                    }),
                                    _c("span", [_vm._v(_vm._s(item.desc))]),
                                    item.chooseType != "select"
                                      ? _c("span", [
                                          item.value != undefined
                                            ? _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "margin-left": "10px",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " + _vm._s(item.value)
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ])
                                      : _c(
                                          "span",
                                          [
                                            _c(
                                              "el-select",
                                              {
                                                model: {
                                                  value: item.value,
                                                  callback: function ($$v) {
                                                    _vm.$set(item, "value", $$v)
                                                  },
                                                  expression: "item.value",
                                                },
                                              },
                                              _vm._l(
                                                item.chooseText,
                                                function (c) {
                                                  return _c("el-option", {
                                                    key: c,
                                                    attrs: {
                                                      label: c,
                                                      value: c,
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                  ],
                                  1
                                )
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.getConfigVersion() !== "V3" &&
                  _vm.editDialog.hxForm.contentMap.materialUsage
                    ? _c(
                        "el-row",
                        [
                          _c("el-col", { attrs: { span: 3 } }, [
                            _c(
                              "label",
                              { staticStyle: { "font-size": "16px" } },
                              [_vm._v(" 道具使用 ")]
                            ),
                          ]),
                          _c(
                            "el-col",
                            { attrs: { span: 18 } },
                            _vm._l(
                              _vm.editDialog.hxForm.contentMap.materialUsage,
                              function (item) {
                                return _c(
                                  "el-col",
                                  { key: item.id },
                                  [
                                    _c("el-checkbox", {
                                      staticClass: "checked-class",
                                      attrs: { disabled: "" },
                                      model: {
                                        value: item.checked,
                                        callback: function ($$v) {
                                          _vm.$set(item, "checked", $$v)
                                        },
                                        expression: "item.checked",
                                      },
                                    }),
                                    _c("span", [_vm._v(_vm._s(item.name))]),
                                    _vm._v(" "),
                                    item.count != undefined
                                      ? _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "margin-left": "10px",
                                            },
                                          },
                                          [_vm._v(" " + _vm._s(item.count))]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-row",
                { staticStyle: { "margin-top": "10px" } },
                [
                  _c(
                    "el-table",
                    {
                      attrs: {
                        data: _vm.editDialog.hxForm.opList,
                        size: "mini",
                        height: 200,
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: "操作人",
                          prop: "userName",
                          width: "180",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      row.userName
                                        ? row.userName +
                                            (row.userName
                                              ? "-" + row.nickName
                                              : "")
                                        : ""
                                    )
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "操作", prop: "opType", width: "180" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.statusActionMap[row.opType])
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: { label: "备注", prop: "remark", width: "180" },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "时间",
                          prop: "createTime",
                          width: "180",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "审核说明", "label-width": "80px" } },
                    [
                      _c("el-input", {
                        attrs: {
                          clearable: "",
                          placeholder: "请输入",
                          type: "textarea",
                        },
                        model: {
                          value: _vm.editDialog.form.remark,
                          callback: function ($$v) {
                            _vm.$set(_vm.editDialog.form, "remark", $$v)
                          },
                          expression: "editDialog.form.remark",
                        },
                      }),
                      _c("el-input", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: false,
                            expression: "false",
                          },
                        ],
                        attrs: {
                          clearable: "",
                          placeholder: "请输入",
                          type: "textarea",
                        },
                        model: {
                          value: _vm.editDialog.form.remark,
                          callback: function ($$v) {
                            _vm.$set(_vm.editDialog.form, "remark", $$v)
                          },
                          expression: "editDialog.form.remark",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          !_vm.editDialog.disabled
            ? _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  staticStyle: { "text-align": "right" },
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "mini" },
                      on: {
                        click: function ($event) {
                          _vm.editDialog.visible = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                  _vm.editDialog.type == "audit" &&
                  _vm.computeButtonShow(_vm.editDialog.form.status, "pass")
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "success", size: "mini" },
                          on: {
                            click: function ($event) {
                              return _vm.submitAudit(1)
                            },
                          },
                        },
                        [_vm._v("通过")]
                      )
                    : _vm._e(),
                  _vm.editDialog.type == "audit" &&
                  _vm.computeButtonShow(_vm.editDialog.form.status, "reject")
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "danger", size: "mini" },
                          on: {
                            click: function ($event) {
                              return _vm.submitAudit(2)
                            },
                          },
                        },
                        [_vm._v("驳回")]
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "同步",
            visible: _vm.exportDialog.visible,
            width: "700px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.exportDialog, "visible", $event)
            },
          },
        },
        [
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  staticClass: "common-form",
                  attrs: {
                    size: "mini",
                    inline: "",
                    "label-width": "160px",
                    model: _vm.exportDialog.form,
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "分公司" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "200px" },
                              attrs: {
                                filterable: "",
                                "default-first-option": "",
                                placeholder: "不选默认导出全部",
                                multiple: "",
                                "collapse-tags": true,
                              },
                              model: {
                                value: _vm.exportDialog.orgCodes,
                                callback: function ($$v) {
                                  _vm.$set(_vm.exportDialog, "orgCodes", $$v)
                                },
                                expression: "exportDialog.orgCodes",
                              },
                            },
                            _vm._l(this.options.orgOptions, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: {
                                  value: item.value,
                                  label: item.value + "-" + item.label,
                                },
                              })
                            }),
                            1
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: () => {
                                  _vm.exportDialog.orgCodes = [].concat(
                                    this.options.orgOptions.map((e) => e.value)
                                  )
                                },
                              },
                            },
                            [_vm._v("全选")]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: () => {
                                  _vm.exportDialog.orgCodes = []
                                },
                              },
                            },
                            [_vm._v("取消全选")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { staticStyle: { margin: "10px" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.handleExportSpecial },
                    },
                    [_vm._v("导出")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small" },
                      on: {
                        click: function ($event) {
                          _vm.exportDialog.visible = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }