<template>
    <div class="new-box">
        <div class="mg-search">
            <el-card style="margin: 10px">
                <SearchFilter @search="FormData.page = 1; handleFilter();" label-width="150px" @reset="resetQuery"
                    @keyup.enter.native="FormData.page = 1; handleFilter();">
                    <el-form-item label="月份">
                        <el-select v-model="FormData.mth" placeholder="请选择">
                            <el-option v-for="item in mth" :key="item.code" :label="item.name" :value="item.code">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="赛道">
                        <el-select v-model="FormData.newRaceNames" filterable multiple collapse-tags clearable
                            placeholder="请选择">
                            <el-option v-for="item in race" :key="item.code" :label="item.name" :value="item.code">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="大类">
                        <el-select v-model="FormData.newMainCategoryNames" filterable multiple collapse-tags clearable
                            placeholder="请选择" @change="changeCategories">
                            <el-option v-for="(item, index) in mainCategory" :key="index" :label="item.category"
                                :value="item.category">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="中类">
                        <el-select v-model="FormData.newMidCategoryNames" filterable multiple collapse-tags clearable
                            placeholder="请选择" @change="changeMediumClass">
                            <el-option v-for="(item, index) in midCategory" :key="index" :label="item.category"
                                :value="item.category">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="小类">
                        <el-select v-model="FormData.newSubCategoryNames" filterable multiple collapse-tags clearable
                            placeholder="请选择">
                            <el-option v-for="(item, index) in subCategory" :key="index" :label="item.category"
                                :value="item.category">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="是否显示近3月增长率">
                        <el-select v-model="growthRateShow" placeholder="请选择">
                            <el-option label="否" :value="1"> 否 </el-option>
                            <el-option label="是" :value="0"> 是</el-option>
                        </el-select>
                    </el-form-item>
                </SearchFilter>
            </el-card>
        </div>
        <el-card style="margin: 10px" class="box-card">
            <el-button type="primary" size="small" @click="download" :loading="exportLoading">导出</el-button>
            <el-table :data="tableData" style="width: 100%; margin-top: 10px;" border size="small" :key="getRandom(20)"
                v-loading="loading" ref="table" :header-cell-style="{ 'text-align': 'center', 'background': '#eef1f6' }"
                :cell-style="{ 'text-align': 'center' }">
                <el-table-column fixed prop="newRaceName" label="赛道">
                </el-table-column>
                <el-table-column fixed prop="newMainCategoryName" label="大类">
                </el-table-column>
                <el-table-column fixed prop="newMidCategoryName" label="中类" >
                </el-table-column>
                <el-table-column fixed prop="newSubCategoryName" label="小类">
                    <template slot-scope="scope">
                        <div @click="jumpToSkuDimension(scope.row.newSubCategoryName)" style="cursor: pointer; color: #409EFF;">
                            {{ scope.row.newSubCategoryName }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column :label="`整体${startMonth}-${endMonth}月销售（万元）`">
                    <el-table-column prop="goal" label="目标" sortable>
                        <template slot-scope="scope">
                            {{ scope.row.goal ? Math.round(scope.row.goal) : '-' }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="wcl" sortable label="完成率" >
                        <template slot-scope="scope">
                            <div :style="{ color: scope.row.wcl < 0 ? 'red' : '' }">
                                {{ scope.row.wcl ? Math.round(scope.row.wcl * 100) + '%' : '-' }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="bnJhTotal" sortable label="本年">
                        <template slot-scope="scope">
                            {{ Math.round(scope.row.bnJhTotal) }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="zl" sortable label="同比增量">
                        <template slot-scope="scope">
                            <div :style="{ color: scope.row.zl < 0 ? 'red' : '' }">
                                {{ Math.round(scope.row.zl) }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="zzl" sortable label="增长率">
                        <template slot-scope="scope">
                            <div :style="{ color: formatPercentage(scope.row, 'zzl').color }">
                                {{ formatPercentage(scope.row, 'zzl').percentage }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="zzlSsy" sortable label="上上月" v-if="growthRateShow == 0">
                        <template slot-scope="scope">
                            <div :style="{ color: formatPercentage(scope.row, 'zzlSsy').color }">
                                {{ formatPercentage(scope.row, 'zzlSsy').percentage }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="zzlSy" sortable label="上月" v-if="growthRateShow == 0">
                        <template slot-scope="scope">
                            <div :style="{ color: formatPercentage(scope.row, 'zzlSy').color }">
                                {{ formatPercentage(scope.row, 'zzlSy').percentage }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="zzlBy" sortable label="本月" v-if="growthRateShow == 0">
                        <template slot-scope="scope">
                            <div :style="{ color: formatPercentage(scope.row, 'zzlBy').color }">
                                {{ formatPercentage(scope.row, 'zzlBy').percentage }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="近3月趋势" width="120">
                        <template slot-scope="scope">
                            <TrendCharts :first="scope.row.zzlSsy" :second="scope.row.zzlSy" :third="scope.row.zzlBy"
                                :easyKey="getRandom(20)" :month="years"></TrendCharts>
                        </template>
                    </el-table-column>
                </el-table-column>
                <el-table-column :label="`线下${startMonth}-${endMonth}月销售（万元）`">
                    <el-table-column prop="bnOfflineJhTotal" sortable label="本年">
                        <template slot-scope="scope">
                            {{ Math.round(scope.row.bnOfflineJhTotal) }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="offlineZb" sortable label="销售占比">
                        <template slot-scope="scope">
                            <div :style="{ color: formatPercentage(scope.row, 'offlineZb').color }">
                                {{ formatPercentage(scope.row, 'offlineZb').percentage }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="offlineZl" sortable label="同比增量">
                        <template slot-scope="scope">
                            <div :style="{ color: scope.row.offlineZl < 0 ? 'red' : '' }">
                                {{ Math.round(scope.row.offlineZl) }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="offlineZlZb" sortable label="增量占比">
                        <template slot-scope="scope">
                            <div :style="{ color: formatPercentage(scope.row, 'offlineZlZb').color }">
                                {{ formatPercentage(scope.row, 'offlineZlZb').percentage }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="offlineZzl" sortable label="增长率">
                        <template slot-scope="scope">
                            <div :style="{ color: formatPercentage(scope.row, 'offlineZzl').color }">
                                {{ formatPercentage(scope.row, 'offlineZzl').percentage }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="offlineZzlSsy" sortable label="上上月" v-if="growthRateShow == 0">
                        <template slot-scope="scope">
                            <div :style="{ color: formatPercentage(scope.row, 'offlineZzlSsy').color }">
                                {{ formatPercentage(scope.row, 'offlineZzlSsy').percentage }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="offlineZzlSy" sortable label="上月" v-if="growthRateShow == 0">
                        <template slot-scope="scope">
                            <div :style="{ color: formatPercentage(scope.row, 'offlineZzlSy').color }">
                                {{ formatPercentage(scope.row, 'offlineZzlSy').percentage }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="offlineZzlBy" sortable label="本月" v-if="growthRateShow == 0">
                        <template slot-scope="scope">
                            <div :style="{ color: formatPercentage(scope.row, 'offlineZzlBy').color }">
                                {{ formatPercentage(scope.row, 'offlineZzlBy').percentage }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="近3月趋势" width="120">
                        <template slot-scope="scope">
                            <TrendCharts :first="scope.row.offlineZzlSsy" :second="scope.row.offlineZzlSy"
                                :third="scope.row.offlineZzlBy" :easyKey="getRandom(20)" :month="years"></TrendCharts>
                        </template>
                    </el-table-column>
                </el-table-column>
                <el-table-column :label="`线上${startMonth}-${endMonth}月销售（万元）`">
                    <el-table-column prop="bnOnlineJhTotal" sortable label="本年">
                        <template slot-scope="scope">
                            {{ Math.round(scope.row.bnOnlineJhTotal) }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="onlineZb" sortable label="销售占比">
                        <template slot-scope="scope">
                            <div :style="{ color: formatPercentage(scope.row, 'onlineZb').color }">
                                {{ formatPercentage(scope.row, 'onlineZb').percentage }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="onlineZl" sortable label="同比增量">
                        <template slot-scope="scope">
                            <div :style="{ color: scope.row.onlineZl < 0 ? 'red' : '' }">
                                {{ Math.round(scope.row.onlineZl) }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="onlineZlZb" sortable label="增量占比">
                        <template slot-scope="scope">
                            <div :style="{ color: formatPercentage(scope.row, 'onlineZlZb').color }">
                                {{ formatPercentage(scope.row, 'onlineZlZb').percentage }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="onlineZzl" sortable label="增长率">
                        <template slot-scope="scope">
                            <div :style="{ color: formatPercentage(scope.row, 'onlineZzl').color }">
                                {{ formatPercentage(scope.row, 'onlineZzl').percentage }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="onlineZzlSsy" sortable label="上上月" v-if="growthRateShow == 0">
                        <template slot-scope="scope">
                            <div :style="{ color: formatPercentage(scope.row, 'onlineZzlSsy').color }">
                                {{ formatPercentage(scope.row, 'onlineZzlSsy').percentage }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="onlineZzlSy" sortable label="上月" v-if="growthRateShow == 0">
                        <template slot-scope="scope">
                            <div :style="{ color: formatPercentage(scope.row, 'onlineZzlSy').color }">
                                {{ formatPercentage(scope.row, 'onlineZzlSy').percentage }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="onlineZzlBy" sortable label="本月" v-if="growthRateShow == 0">
                        <template slot-scope="scope">
                            <div :style="{ color: formatPercentage(scope.row, 'onlineZzlBy').color }">
                                {{ formatPercentage(scope.row, 'onlineZzlBy').percentage }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="近3月趋势" width="120">
                        <template slot-scope="scope">
                            <TrendCharts :first="scope.row.onlineZzlSsy" :second="scope.row.onlineZzlSy"
                                :third="scope.row.onlineZzlBy" :easyKey="getRandom(20)" :month="years"></TrendCharts>
                        </template>
                    </el-table-column>
                </el-table-column>
            </el-table>
            <Pagination :total="total" :page.sync="FormData.page" :limit.sync="FormData.limit"
                @pagination="handleFilter" />
        </el-card>
    </div>
</template>

<script>
import SearchFilter from "@/components/SearchFilter/index";
import Pagination from "@/components/Pagination";
import TrendCharts from "./trendCharts.vue";
import { smallClassDimensions, dropDownOptions, exportSmallClassDimensions, selectOptions } from "@/api/sales";
export default {
    components: {
        SearchFilter,
        Pagination,
        TrendCharts
    },
    props: {
        propName: String,
        time: String
    },
    data() {
        return {
            FormData: {
                page: 1,
                limit: 10,
                mth: "",
                newRaceNames: "",
                newMainCategoryNames: "",
                newMidCategoryNames: "",
                newSubCategoryNames: "",
            },
            tableData: [],
            total: 0,
            years: "",
            growthRateShow: 1,
            loading: false,
            exportLoading: false,
            midCategory: [],
            mainCategory: [],
            subCategory: [],
            startMonth: "",
            endMonth: "",
            race: [],
            mth: [],

        };
    },

    created() {
        if (this.propName) this.FormData.newMidCategoryNames = [this.propName]; this.FormData.mth = this.time

    },
    mounted() {
        this.setCurrentMonthRange();
        this.dropDownOptionsList();
        this.getData()
    },
    methods: {
        getRandom(num) {
            return Math.floor((Math.random() + Math.floor(Math.random() * 9 + 1)) * Math.pow(10, num - 1));
        },
        formatPercentage(row, propName) {
            const value = row[propName];
            const percentage = value ? (value * 100).toFixed(1) + '%' : '0.0%';
            const color = value && value * 100 < 0 ? 'red' : '';
            return { percentage, color };
        },
        formatYearMonth(yearMonthStr) {
            // 提取月份  
            const month = yearMonthStr.substring(4, 6);
            const startMonth = '1';
            const endMonth = month.replace(/^0+/, '')
            return [
                `${startMonth}`,
                `${endMonth}`
            ];
        },
        getCurrentYearMonthString() {
            const date = new Date();
            const year = date.getFullYear();
            const month = date.getMonth() + 1;
            const toMonth = `${year}${month.toString().padStart(2, "0")}`;
            return toMonth
        },
        setCurrentMonthRange() {
            if(!this.time){
              this.FormData.mth = this.getCurrentYearMonthString();
            }
            const [start, end] = this.formatYearMonth(this.FormData.mth);
            this.startMonth = start
            this.endMonth = end
            this.years = this.FormData.mth;
        },

        dropDownOptionsList() {
            const promises = [
                dropDownOptions({ tableType: "subCategory", fieldType: "mth" }).then(res => res.data),
                dropDownOptions({ tableType: "subCategory", fieldType: "race" }).then(res => res.data),
                // dropDownOptions({ tableType: "subCategory", fieldType: "mainCategory" }).then(res => res.data),
                // dropDownOptions({ tableType: "subCategory", fieldType: "midCategory" }).then(res => res.data),
                // dropDownOptions({ tableType: "subCategory", fieldType: "subCategory" }).then(res => res.data),
                selectOptions().then(res => res.data)
            ];
            // mainCategory, midCategory, subCategory
            Promise.all(promises).then(([mth, race, mainCategory]) => {
                this.mth = mth;
                this.race = race;
                this.mainCategory = mainCategory;
                this.mediumClassAndSmallClassDataProcessing()
                // this.midCategory = midCategory;
                // this.subCategory = subCategory;
            }).catch(error => {
                console.error('加载数据出错:', error);
            });
        },

        getData() {
            this.loading = true;
            smallClassDimensions(this.FormData).then((res) => {
                this.tableData = res.data.datas;
                this.total = res.data.total;
                this.loading = false;
            })
        },
        download() {
            this.exportLoading = true;
            exportSmallClassDimensions(this.FormData).then((res) => {
                const link = document.createElement('a')
                link.style.display = 'none'
                link.href = window.URL.createObjectURL(new Blob([res]))
                link.setAttribute('download', '小类维度报表.xlsx')
                document.body.appendChild(link)
                link.click()
                link.remove()
            }).finally(() => {
                this.exportLoading = false
            })
        },
        handleFilter() {
            const [start, end] = this.formatYearMonth(this.FormData.mth);
            this.startMonth = start
            this.endMonth = end
            this.years = this.FormData.mth;
            this.getData();
        },
        changeCategories() {
            this.midCategory = [];
            this.subCategory = [];
            this.FormData.newMidCategoryNames = null;
            this.FormData.newSubCategoryNames = null;
            if (this.FormData.newMainCategoryNames.length != 0) {
                for (const item of this.mainCategory) {
                    for (const item1 of this.FormData.newMainCategoryNames) {
                        if (item.category == item1) {
                            this.midCategory.push(...item.subCategorys)
                        }
                    }
                }
            } else {
                this.mediumClassAndSmallClassDataProcessing();
            }
        },
        changeMediumClass() {
            this.subCategory = [];
            this.FormData.newSubCategoryNames = null;
            if (this.FormData.newMainCategoryNames.length != 0 || this.FormData.newMidCategoryNames.length != 0) {
                for (const item of this.midCategory) {
                    for (const item1 of this.FormData.newMidCategoryNames) {
                        if (item.category == item1) {
                            this.subCategory.push(...item.subCategorys)
                        }
                    }
                }
            } else {
                this.mediumClassAndSmallClassDataProcessing();
            }
        },

        mediumClassAndSmallClassDataProcessing() {
            this.mainCategory.forEach(item => {
                if (item.subCategorys) {
                    this.midCategory.push(...item.subCategorys);
                }
            });
            this.midCategory.forEach(midItem => {
                if (midItem.subCategorys) {
                    this.subCategory.push(...midItem.subCategorys);
                }
            });
        },
        jumpToSkuDimension(raceName){
            this.$emit('callbackNameType', raceName, 'singles', this.FormData.mth);
        },
        resetQuery() {
            this.FormData = {
                page: 1,
                limit: 10,
                mth: this.getCurrentYearMonthString(),
            };
            this.growthRateShow = 1;
            this.getData();
        }

    },
};
</script>

<style lang='scss' scoped>
:deep(.el-table .caret-wrapper){
    width: 10px !important;
}
</style>