<template>
  <div style="padding: 20px" v-loading="homeLoading">
    <div class="mg-search" style="padding-bottom: 15px">
      <el-form ref="queryFormRef" size="mini" label-width="auto" :inline="true" :model="queryForm" @keyup.enter.native="handleQuery">
        <el-form-item label="平台">
          <DictSelect
            ref="platform"
            type="platform"
            :value.sync="queryForm.platforms"
            multiple
            :dictFun="dictPlatformFun"
          />
        </el-form-item>
        <el-form-item label="型号">
          <DictSelect
            ref="skuModel"
            type="skuModel"
            parentLinkageRef
            :value.sync="queryForm.skuModels"
            :dictFun="dictSkuModelsFun"
            multiple
            remote
          />
        </el-form-item>
        <el-form-item>
          <el-button class="form-btn-item" size="mini" icon="el-icon-search" type="primary" @click="handleFilter">查询</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-plus" type="primary" @click="handleSkuPriceControlAdd">单行新增</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-upload" type="primary" @click="handleSkuPriceControlImportNotice">批量导入</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-download" type="primary" @click="handleSkuPriceControlExportNotice">批量导出</el-button>
        </el-form-item>
      </el-form>
    </div>

    <el-table
      ref="tableRef"
      :data="pageData.rows"
      height="calc(100vh - 250px)"
      v-loading="loading"
      size="mini"
      border
      highlight-current-row
      :header-cell-style="{ background: 'rgb(235 235 235)', color: 'black',textAlign: 'center', verticalAlign: 'middle' }"
      @selection-change="handleSelectionChange">
<!--      <el-table-column type="selection" width="90"></el-table-column>-->
      <el-table-column label="平台" prop="ptName" min-width="100" />
      <el-table-column label="型号" prop="skuModel" min-width="100" />
      <el-table-column label="售卖规格单位" prop="saleUnit" min-width="100" />
      <el-table-column label="售卖规格单位是否最小单位" prop="ifMinUnit" min-width="120" />
      <el-table-column label="售卖规格下限" prop="saleUnitMin" min-width="100" />
      <el-table-column label="售卖规格上限" prop="saleUnitMax" min-width="100" />
      <el-table-column label="售卖单价" prop="unitPrice" min-width="100" />
      <el-table-column label="售卖总价" prop="totalPrice" min-width="100" />
      <el-table-column label="赠品" prop="gift" min-width="100" />
      <el-table-column label="操作" min-width="120" align="center" class-name="small-padding fixed-width">
        <template #default="scope">
          <el-button
            size="mini"
            type="text"
            icon="el-icon-edit"
            @click="handleSkuPriceControlEdit(scope.row)"
          >编辑</el-button>
          <el-button
            size="mini"
            type="text"
            icon="el-icon-delete"
            @click="handleDeleteSkuPriceControl(scope.row)"
          >删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Pagination :total="pageData.total" :page.sync="queryForm.page" :limit.sync="queryForm.limit" @pagination="handleQuery" />

    <el-dialog title="单品控价表导入" :visible.sync="skuPriceControlImportDialog.visible" append-to-body width="400px">
      <el-upload
        ref="skuPriceControlUpload"
        class="upload-demo"
        drag
        :limit="1"
        action="#"
        :auto-upload="false"
        :file-list="skuPriceControlImportDialog.fileList"
        :on-change="(file, fileList) => handleSkuPriceControlFileChange(file, fileList, 'change')"
        :on-remove="(file, fileList) => handleSkuPriceControlFileChange(file, fileList, 'remove')"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">
          <span>仅允许导入xls、xlsx格式文件。</span>
          <el-link type="primary" :underline="false" style="font-size:12px;vertical-align: baseline;" target="_blank" :href="skuPriceControlImportDialog.template">下载模板</el-link>
        </div>
      </el-upload>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button type="primary" size="mini" :disabled="skuPriceControlSubmitDisabled" @click="submitSkuPriceControlImport">确定</el-button>
        <el-button type="primary" size="mini" @click="skuPriceControlImportDialog.visible = false">取消</el-button>
      </div>
    </el-dialog>

    <el-dialog :title="skuPriceControlAddDialog.title" :visible.sync="skuPriceControlAddDialog.visible" width="600px" :close-on-click-modal="false">
      <el-form ref="spuInfoUpdate" size="mini" label-width="100px" :model="skuPriceControlAddDialog.form" :rules="skuPriceControlAddDialog.rules">
        <el-form-item label="平台" prop="ptName">
          <DictSelect
            ref="platform"
            type="platform"
            :value.sync="skuPriceControlAddDialog.form.ptName"
            :dictFun="dictPlatformFun"
          />
        </el-form-item>
        <el-form-item label="型号" prop="skuModel">
          <DictSelect
            ref="skuModel"
            type="sku_model"
            :value.sync="skuPriceControlAddDialog.form.skuModel"
            :dictFun="dictSkuModelFun"
            :init-props="{ label: 'skuModel', value: 'skuModel'}"
            remote
          />
        </el-form-item>
        <el-form-item label="售卖规格单位" prop="saleUnit">
          <el-input v-model="skuPriceControlAddDialog.form.saleUnit" placeholder="请输入售卖规格单位"/>
        </el-form-item>
        <el-form-item label="售卖规格单位是否最小单位" label-width="180px" prop="ifMinUnit">
          <el-input v-model="skuPriceControlAddDialog.form.ifMinUnit" placeholder="请输入售卖规格单位是否最小单位"/>
        </el-form-item>
        <el-form-item label="售卖规格下限" prop="saleUnitMin">
          <el-input-number v-model="skuPriceControlAddDialog.form.saleUnitMin" clearable placeholder="请输入售卖规格下限"/>
        </el-form-item>
        <el-form-item label="售卖规格上限" prop="saleUnitMax">
          <el-input-number v-model="skuPriceControlAddDialog.form.saleUnitMax" clearable placeholder="请输入售卖规格上限"/>
        </el-form-item>
        <el-form-item label="售卖单价" prop="unitPrice">
          <el-input v-model="skuPriceControlAddDialog.form.unitPrice" clearable placeholder="请输入售卖单价"/>
        </el-form-item>
        <el-form-item label="售卖总价" prop="totalPrice">
          <el-input v-model="skuPriceControlAddDialog.form.totalPrice" clearable placeholder="请输入售卖总价"/>
        </el-form-item>
        <el-form-item label="赠品" >
          <el-input v-model="skuPriceControlAddDialog.form.gift" placeholder="请输入赠品"/>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button size="mini" @click="skuPriceControlAddDialog.visible = false">取消</el-button>
        <el-button size="mini" type="primary" @click="submitAddSkuPriceControlForm">确定</el-button>
      </div>
    </el-dialog>

    <el-dialog :title="skuPriceControlUpdateDialog.title" :visible.sync="skuPriceControlUpdateDialog.visible" width="600px" :close-on-click-modal="false">
      <el-form ref="spuInfoUpdate" size="mini" label-width="100px" :model="skuPriceControlUpdateDialog.form" :rules="skuPriceControlUpdateDialog.rules">
        <el-form-item label="平台" prop="ptName">
          <DictSelect
            disabled="true"
            ref="platform"
            type="platform"
            :value.sync="skuPriceControlUpdateDialog.form.ptName"
            :dictFun="dictPlatformFun"
          />
        </el-form-item>
        <el-form-item label="型号" prop="skuModel">
          <DictSelect
            disabled="true"
            ref="skuModel"
            type="sku_model"
            :value.sync="skuPriceControlUpdateDialog.form.skuModel"
            :dictFun="dictSkuModelFun"
            :init-props="{ label: 'skuModel', value: 'skuModel'}"
            remote
          />
        </el-form-item>
        <el-form-item label="售卖规格单位" prop="saleUnit">
          <el-input v-model="skuPriceControlUpdateDialog.form.saleUnit" placeholder="请输入售卖规格单位"/>
        </el-form-item>
        <el-form-item label="售卖规格单位是否最小单位" label-width="180px" prop="ifMinUnit">
          <el-input v-model="skuPriceControlUpdateDialog.form.ifMinUnit" placeholder="请输入售卖规格单位是否最小单位"/>
        </el-form-item>
        <el-form-item label="售卖规格下限" prop="saleUnitMin">
          <el-input-number v-model="skuPriceControlUpdateDialog.form.saleUnitMin" clearable placeholder="请输入售卖规格下限"/>
        </el-form-item>
        <el-form-item label="售卖规格上限" prop="saleUnitMax">
          <el-input-number v-model="skuPriceControlUpdateDialog.form.saleUnitMax" clearable placeholder="请输入售卖规格上限"/>
        </el-form-item>
        <el-form-item label="售卖单价" prop="unitPrice">
          <el-input v-model="skuPriceControlUpdateDialog.form.unitPrice" clearable placeholder="请输入售卖单价"/>
        </el-form-item>
        <el-form-item label="售卖总价" prop="totalPrice">
          <el-input v-model="skuPriceControlUpdateDialog.form.totalPrice" clearable placeholder="请输入售卖总价"/>
        </el-form-item>
        <el-form-item label="赠品" >
          <el-input v-model="skuPriceControlUpdateDialog.form.gift" placeholder="请输入赠品"/>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button size="mini" @click="skuPriceControlUpdateDialog.visible = false">取消</el-button>
        <el-button size="mini" type="primary" @click="submitSkuPriceControlEdit">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import {
  techEyeSkuPriceControlPageList,
  getTechSkuPriceControlOptions,
  getTechPriceControlSkuModelInfoList,
  exportTechSkuPriceControl,
  skuPriceControlImport,
  deleteSkuPriceControl, getSkuModelInfoList, addSkuPriceControl, editSkuPriceControl
} from "@/api/eCommerce";
import { deepClone } from "@/utils"
import { Notification } from "element-ui";
import DictSelect from "@/components/DictSelect";
import {delBanner} from "@/api/appTask";

export default {
  name: "TechSkuPriceControl",
  components: { Pagination, DictSelect},
  data() {
    return {
      homeLoading: false,
      dictPlatformFun: getTechSkuPriceControlOptions,
      dictSkuModelsFun: getTechPriceControlSkuModelInfoList,
      dictSkuModelFun: getSkuModelInfoList,
      pageData: {
        total: 0,
        rows: [],
        selections: [],
      },
      pageNoticeData: {
        total: 0,
        rows: [],
        selections: [],
      },
      queryForm: {
        page: 1,
        limit: 10,
        platforms: [],
        skuModels: [],
      },
      loading: false,
      skuPriceControlImportDialog: {
        visible: false,
        fileList: [],
        accept: ['.xls', '.xlsx'],
        title: '导入单品控价表',
        template: process.env.VUE_APP_OSS_TEMPLATE_PATH +'/%E5%8D%95%E5%93%81%E6%8E%A7%E4%BB%B7%E6%8F%90%E9%86%92%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx'
      },
      skuPriceControlSubmitDisabled: false,
      skuPriceControlAddDialog: {
        visible: false,
        title: "新增单品控价表",
        form: {
          ptName: undefined,
          skuModel: undefined,
          saleUnit: undefined,
          ifMinUnit: undefined,
          saleUnitMin: undefined,
          saleUnitMax: undefined,
          unitPrice: undefined,
          totalPrice: undefined,
          gift: undefined,
        },
        rules: {
          ptName: [{required: true, message: '平台不能为空！', trigger: 'change'}],
          skuModel: [{required: true, message: '型号不能为空！', trigger: 'change'}],
          saleUnit: [{required: true, message: '售卖规格单位不能为空！', trigger: 'change'}],
          ifMinUnit: [{required: true, message: '售卖规格单位是否最小单位不能为空！', trigger: 'change'}],
          saleUnitMin: [{required: true, message: '售卖规格下限不能为空！', trigger: 'change'}],
          saleUnitMax: [{required: true, message: '售卖规格上限不能为空！', trigger: 'change'}],
        }
      },
      skuPriceControlUpdateDialog: {
        visible: false,
        title: "修改新增单品控价表",
        form: {
          ptName: undefined,
          skuModel: undefined,
          saleUnit: undefined,
          ifMinUnit: undefined,
          saleUnitMin: undefined,
          saleUnitMax: undefined,
          unitPrice: undefined,
          totalPrice: undefined,
          gift: undefined,
        },
        rules: {
          saleUnit: [{required: true, message: '售卖规格单位不能为空！', trigger: 'change'}],
          ifMinUnit: [{required: true, message: '售卖规格单位是否最小单位不能为空！', trigger: 'change'}],
          saleUnitMin: [{required: true, message: '售卖规格下限不能为空！', trigger: 'change'}],
          saleUnitMax: [{required: true, message: '售卖规格上限不能为空！', trigger: 'change'}],
        }
      },
      oldSkuPriceControl: {
        ptName: undefined,
        skuModel: undefined,
        saleUnit: undefined,
        ifMinUnit: undefined,
        saleUnitMin: undefined,
        saleUnitMax: undefined,
      },
    };
  },
  watch: {
  },
  created() {
    this.handleFilter();
  },
  mounted() {
  },
  methods: {
    handleQuery() {
      this.loading = true;
      const query = Object.assign({}, this.queryForm);
      techEyeSkuPriceControlPageList(query).then(res => {
        if (res.code !== 200) {
          return false;
        }
        this.loading = false;
        this.pageData.rows = res.data?.datas;
        this.pageData.total = res.data?.total;
        this.$nextTick(() => {
          this.$refs.tableRef.doLayout();
        })
      });
    },
    handleFilter() {
      this.queryForm.page = 1;
      this.handleQuery();
    },
    resetQuery() {
      const queryForm = {
        page: 1,
        limit: 10,
        platforms: [],
        skuModels: [],
      };
      this.queryForm = Object.assign({}, queryForm);
      this.$forceUpdate();
    },
    handleJumpQuery() {
      this.handleFilter();
    },
    handleSkuPriceControlImportNotice() {
      this.skuPriceControlImportDialog.fileList = [];
      if (this.$refs.upload) {
        this.$nextTick(() => {
          this.$refs.upload.clearFiles();
        })
      }
      this.skuPriceControlImportDialog.visible = true;
      this.skuPriceControlSubmitDisabled = false;
    },
    handleSkuPriceControlFileChange(file, fileList, type) {
      if (type === 'remove') {
        this.skuPriceControlImportDialog.fileList = fileList;
        return false;
      }
      const suffix =
        file.name.indexOf(".") > 0
          ? file.name.substring(file.name.lastIndexOf("."))
          : undefined;
      if (this.skuPriceControlImportDialog.accept.indexOf(suffix) < 0) {
        this.$message.error("文件格式不支持");
        this.skuPriceControlImportDialog.fileList = [];
      } else {
        this.skuPriceControlImportDialog.fileList = fileList;
      }
    },
    submitSkuPriceControlImport() {
      if (this.skuPriceControlImportDialog.fileList.length === 0) {
        Notification.warning('请先上传文件！');
        return false;
      }
      let fd = new FormData();
      this.skuPriceControlImportDialog.fileList.forEach(item=>{
        //文件信息中raw才是真的文件
        fd.append("file",item.raw);
      })
      this.spuSpecificationsSubmitDisabled = true;
      skuPriceControlImport(fd).then((res) => {
        if (res.code === 200) {
          if (res.data) {
            Notification.error("导入失败，存在错误数据，正在导出，请稍候！");
            window.open(res.data, '_blank');
          } else {
            Notification.success("操作成功!");
            this.skuPriceControlImportDialog.visible = false;
            this.handleFilter()
          }
        }
        this.spuSpecificationsSubmitDisabled = false;
        this.$refs.upload.clearFiles();
      })
    },
    handleSkuPriceControlExportNotice() {
      this.homeLoading = true;
      exportTechSkuPriceControl(this.queryForm).then(res => {
        if (res.data) {
          const link = document.createElement('a');
          link.href = res.data;
          link.download = "单品控价表导出";
          link.target = "_blank"; // 可选，如果希望在新窗口中下载文件，请取消注释此行
          link.click()
        } else {
          Notification.info("无可下载数据！");
        }
        this.homeLoading = false;
      })
    },
    handleSkuPriceControlAdd() {
      this.rsetSkuPriceControlAddDialogForm();
      this.skuPriceControlAddDialog.visible = true;
    },
    submitAddSkuPriceControlForm() {
      const argument = deepClone(this.skuPriceControlAddDialog.form);
      addSkuPriceControl(argument).then(res => {
        if (res.code === 200) {
          if (res.data) {
            Notification.error(res.data);
          } else {
            this.$message.success("操作成功！");
            this.rsetSkuPriceControlAddDialogForm();
            this.skuPriceControlAddDialog.visible = false;
            this.handleQuery();
          }
        }
      })
    },
    rsetSkuPriceControlAddDialogForm(){
      this.skuPriceControlImportDialog.form = {
        ptName: undefined,
        skuModel: undefined,
        saleUnit: undefined,
        ifMinUnit: undefined,
        saleUnitMin: undefined,
        saleUnitMax: undefined,
        unitPrice: undefined,
        totalPrice: undefined,
        gift: undefined,
      };
      this.skuPriceControlImportDialog.visible = false;
    },
    handleDeleteSkuPriceControl(data) {
      const argument = Object.assign({}, data);
      this.$confirm('请确认是否删除该数据！', '提示', { type: 'warning' }).then(() => {
        deleteSkuPriceControl(argument).then(res => {
          if (res.code !== 200) {
            return false;
          }
          this.handleQuery();
        });
      }).catch(() => {
      })
    },
    handleSkuPriceControlEdit(data) {
      this.rsetSkuPriceControlUpdateDialogForm();
      this.skuPriceControlUpdateDialog.visible = true;
      this.skuPriceControlUpdateDialog.form = deepClone(data);
      this.oldSkuPriceControl = deepClone(data);
    },
    rsetSkuPriceControlUpdateDialogForm(){
      this.skuPriceControlUpdateDialog.form = {
        ptName: undefined,
        skuModel: undefined,
        saleUnit: undefined,
        ifMinUnit: undefined,
        saleUnitMin: undefined,
        saleUnitMax: undefined,
        unitPrice: undefined,
        totalPrice: undefined,
        gift: undefined,
      };
      this.oldSkuPriceControl = {
        ptName: undefined,
        skuModel: undefined,
        saleUnit: undefined,
        ifMinUnit: undefined,
        saleUnitMin: undefined,
        saleUnitMax: undefined,
      };
      this.skuPriceControlUpdateDialog.visible = false;
    },
    submitSkuPriceControlEdit() {
      const argument = deepClone(this.skuPriceControlUpdateDialog.form);
      argument.oldSpuModel = this.oldSpuModel;
      argument.oldSkuPriceControl = this.oldSkuPriceControl;
      editSkuPriceControl(argument).then(res => {
        if (res.code === 200) {
          this.$message.success("操作成功！");
          this.rsetSkuPriceControlUpdateDialogForm();
          this.skuPriceControlUpdateDialog.visible = false;
          this.handleQuery();
        }
      })
    },
    handleSelectionChange(selections) {
      this.pageNoticeData.selections = selections;
    },
  },
};
</script>

<style lang="scss" scoped>
  ::v-deep .el-table__header .el-table-column--selection .cell .el-checkbox:after {
    content: "本页全选";
    color: black;
    font-weight: bold;
    font-size: 12px;
    margin-left: 5px;
  }
</style>
