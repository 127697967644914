<template>
  <div class="new-box">
    <div class="mg-search" >
        <el-card style="margin: 10px;">
      <!-- <el-form ref="queryFormRef" size="mini" label-width="auto" :inline="true" :model="queryForm"> -->
        <SearchFilter @search="handleQuery" @reset="resetQuery">
        <el-form-item label="分公司">
          <DictSelect
            ref="org"
            type="ht-org"
            :value.sync="queryForm.orgCode"
            linkageRef="ht-dc,ht-shop,ht-resp"
            :dictFun="dictFun"
            fullLabel
            :clearable="orgAllAuth"
            :init="true"
            :init-options="this.options.orgOptions"
            @changeLinkageRef="handleChangeLinkageRef"
          />
        </el-form-item>
        <el-form-item label="配送中心">
          <DictSelect
            ref="ht-dc"
            type="ht-dc"
            :value.sync="queryForm.dcCode"
            :dictFun="dictFun"
            parentLinkageRef
            linkageRef="ht-shop,ht-resp"
            fullLabel
            collapse-tags
            @getOtherJsonParam="(callback) => callback(dictParam)"
            @change="dictParam = Object.assign({}, queryForm)"
          />
        </el-form-item>
        <el-form-item label="店铺">
          <DictSelect
            ref="ht-shop"
            type="ht-shop"
            :value.sync="queryForm.shopCode"
            :dictFun="dictFun"
            parentLinkageRef
            fullLabel
            remote
            :init-props="{ label: 'label', value: 'fullCode'}"
            @getOtherJsonParam="(callback) => callback(Object.assign({}, queryForm))"
          />
        </el-form-item>
        <el-form-item label="包干负责人">
          <DictSelect type="ht-resp" ref="ht-resp" @getOtherJsonParam="(callback) => callback(dictParam)" fullLabel :value.sync="queryForm.responsible" :dictFun="dictFun" parentLinkageRef />
        </el-form-item>
        <el-form-item label="核销名称" v-if="options.hxOptions.length > 0">
          <DictSelect  type="hx" ref="hx" :value.sync="queryForm.id" init :init-options="options.hxOptions" :clearable="false" :initProps="{ label: 'name', value: 'id'}" @change="handleHxChange" />
        </el-form-item>
        <el-form-item label="阵地" v-if="options.hxOptions.length > 0">
          <DictSelect  type="module" ref="module" :value.sync="queryForm.moduleIds" multiple init :init-options="options.moduleOptions" :clearable="false" />
        </el-form-item>
        <el-form-item label="提交时间">
          <el-date-picker
            v-model="queryDate"
            type="daterange"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="审批状态">
          <el-select v-model="queryForm.status" clearable placeholder="请选择">
            <el-option v-for="(item, key) in options.statusOptions" :label="item.text" :value="item.status" :key="key" />
          </el-select>
        </el-form-item>
        <!-- <el-form-item>
          <el-button class="form-btn-item" size="mini" icon="el-icon-search" type="primary" @click="handleQuery">搜索</el-button>
          <el-button v-if="orgAuth || headAuth" class="form-btn-item" size="mini" type="success" @click="handleBatchAudit('1')">批量通过</el-button>
          <el-button v-if="orgAuth || headAuth" class="form-btn-item" size="mini" type="danger" @click="handleBatchAudit('2')">批量驳回</el-button>
          <el-button class="form-btn-item" size="mini" type="primary" @click="handleExport(undefined)">导出</el-button>
          <el-popover
            placement="right"
            width="600"
            trigger="click"
            @show="handlePageDownload">
            <div>
              <el-row>
                <el-col :span="8">
                  <el-select v-model="downloadData.queryForm.status" clearable placeholder="请选择">
                    <el-option v-for="(item, key) in downloadData.downloadStatusMap" :label="item" :value="key" :key="key" />
                  </el-select>
                </el-col>
                <el-col :span="3">
                  <el-button class="form-btn-item" size="mini" icon="el-icon-search" type="primary" @click="handlePageDownload">刷新</el-button>
                </el-col>
              </el-row>
              <el-table :data="downloadData.rows" v-loading="downloadData.loading" size="mini">
                <el-table-column width="300" property="fileName" label="文件名"></el-table-column>
                <el-table-column width="140" property="createTime" label="时间"></el-table-column>
                <el-table-column width="80" property="status" label="状态">
                  <template #default="{ row }">
                    <el-tag :type="$options.filters.downloadStatusFilter(row.status)">
                      {{downloadData.downloadStatusMap[row.status] }}
                    </el-tag>
                  </template>
                </el-table-column>
                <el-table-column label="操作" width="80">
                  <template #default="{ row }">
                    <el-link v-if="row.status === '1'" type="primary" target="_blank" :href="row.downloadUrl">下载</el-link>
                  </template>
                </el-table-column>
              </el-table>
              <Pagination :total="downloadData.total" :page.sync="downloadData.queryForm.current" :pageSizes="[3, 5, 10]" :limit.sync="downloadData.queryForm.size" @pagination="handlePageDownload" />
            </div>
            <el-button class="form-btn-item" size="mini" slot="reference" type="primary" style="margin-left: 10px">查看导出列表</el-button>
          </el-popover>
        </el-form-item> -->
    </SearchFilter>
      <!-- </el-form> -->
    </el-card>
    </div>
    <el-card style="margin: 10px;" class="box-card">
        <div style="margin: 0 0 10px 0;">
        <el-button v-if="orgAuth || headAuth" class="form-btn-item" size="mini" type="success" @click="handleBatchAudit('1')">批量通过</el-button>
          <el-button v-if="orgAuth || headAuth" class="form-btn-item" size="mini" type="danger" @click="handleBatchAudit('2')">批量驳回</el-button>
          <el-button class="form-btn-item" size="mini" type="primary" @click="handleExport(undefined)">导出</el-button>
          <el-popover
            placement="right"
            width="600"
            trigger="click"
            @show="handlePageDownload">
            <div>
              <el-row>
                <el-col :span="8">
                  <el-select v-model="downloadData.queryForm.status" clearable placeholder="请选择">
                    <el-option v-for="(item, key) in downloadData.downloadStatusMap" :label="item" :value="key" :key="key" />
                  </el-select>
                </el-col>
                <el-col :span="3">
                  <el-button class="form-btn-item" size="mini" icon="el-icon-search" type="primary" @click="handlePageDownload">刷新</el-button>
                </el-col>
              </el-row>
              <el-table :data="downloadData.rows" v-loading="downloadData.loading" size="mini">
                <el-table-column width="300" property="fileName" label="文件名"></el-table-column>
                <el-table-column width="140" property="createTime" label="时间"></el-table-column>
                <el-table-column width="80" property="status" label="状态">
                  <template #default="{ row }">
                    <el-tag :type="$options.filters.downloadStatusFilter(row.status)">
                      {{downloadData.downloadStatusMap[row.status] }}
                    </el-tag>
                  </template>
                </el-table-column>
                <el-table-column label="操作" width="80">
                  <template #default="{ row }">
                    <el-link v-if="row.status === '1'" type="primary" target="_blank" :href="row.downloadUrl">下载</el-link>
                  </template>
                </el-table-column>
              </el-table>
              <Pagination :total="downloadData.total" :page.sync="downloadData.queryForm.current" :pageSizes="[3, 5, 10]" :limit.sync="downloadData.queryForm.size" @pagination="handlePageDownload" />
            </div>
            <el-button class="form-btn-item" size="mini" slot="reference" type="primary" style="margin-left: 10px">查看导出列表</el-button>
          </el-popover>
        </div>
    <el-table
      :data="pageData.rows"
      v-loading="loading"
            size="small"
            class="customer-table"
            border
      ref="table"
      highlight-current-row
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="60" align="center" :selectable="(row, index) => {
          return computeButtonShow(row.status, 'pass') || computeButtonShow(row.status, 'reject');
      }" />
      <el-table-column label="核销名称" prop="name" width="160px"/>
      <el-table-column label="分公司" prop="shop" width="120px">
        <template #default="{ row }">
          <span>{{ row.orgCode + "-" + row.orgName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="所属配送中心" prop="dcName" width="180">
        <template #default="{ row }">
          <span>{{ row.dcCode + "-" + row.dcName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="店铺" prop="shop" width="220">
        <template #default="{ row }">
          <span>{{ row.shopCode + "-" + row.shopName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="提交人" prop="createUser" width="120">
        <template #default="{ row }">
          <span>{{ row.userName ? (row.userName + (row.userName ? '-' + row.nickName: '')):'' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="提交时间" prop="createTime" width="180" />
      <el-table-column label="审批状态" prop="status" width="120">
        <template #default="{ row }">
          <el-tag :type="$options.filters.statusFilter(row.status)">
            {{  getRowLabel(row) }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="140" align="center">
        <template #default="{ row }">
          <div>
            <el-button v-if="computeButtonShow(row.status, 'pass') ||computeButtonShow(row.status, 'reject')" size="mini" type="text" @click="handleAudit(row, 'audit')">审批</el-button>
            <el-button size="mini" type="text" @click="handleAudit(row, 'view')">查看</el-button>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="照片" :width="computeMaxWidth">
        <template #default="{ row }">
          <el-image v-for="(item,index) in row.photos" :src="item" :key="index" :preview-src-list="row.photos" style="display: inline-block; height: 80px; width:60px">
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
        </template>
      </el-table-column>
    </el-table>
    <Pagination :total="pageData.total" :page.sync="queryForm.current" :pageSizes="[10, 20, 50, 100, 200]" :limit.sync="queryForm.size" @pagination="handleQuery" />
</el-card>
    <el-dialog :title="editDialog.type === 'view'? '查看': '审批'"  :visible.sync="editDialog.visible" append-to-body width="800px" :close-on-click-modal="false" @opened="onDialogOpened" ref="editDialogRef" tabindex="1" >
      <el-form ref="form" size="mini" inline :rules="editDialog.rules" label-width="160px" :model="editDialog.form" class="common-form" @submit.native.prevent>
        <el-row class="common-form-row">
          <el-col v-if="!editDialog.form.responsible" style="color: red; font-size: 16px; text-align: center">当前店铺未绑定包干负责人,请尽快维护,以免影响相关奖励发放</el-col>
          <el-descriptions title="终端详情">
            <el-descriptions-item label="分公司">{{ editDialog.form.orgCode + '-' + editDialog.form.orgName}}</el-descriptions-item>
            <el-descriptions-item label="配送中心">{{ editDialog.form.dcCode + '-' + editDialog.form.dcName}}</el-descriptions-item>
            <el-descriptions-item label="店铺">{{ editDialog.form.shopCode + '-' + editDialog.form.shopName}}</el-descriptions-item>
            <el-descriptions-item label="包干负责人">{{ editDialog.form.responsible ? (editDialog.form.responsible + '-' + editDialog.form.responsibler): '' }}</el-descriptions-item>
            <el-descriptions-item label="是否重点店铺">{{ editDialog.form.ifOptimumShop ==='1' ? '是':'否'  }}</el-descriptions-item>
            <el-descriptions-item label="终端容量标签">{{ editDialog.form.shopCapacity }}</el-descriptions-item>
            <el-descriptions-item label="提交人">{{ editDialog.form.userName + '-' + editDialog.form.nickName }}</el-descriptions-item>
            <el-descriptions-item label="提交时间">{{ editDialog.form.createTime  }}</el-descriptions-item>
          </el-descriptions>
          <el-descriptions title="核销信息">
            <el-descriptions-item label="门头照片" :span="3">
              <div v-if="getConfigVersion()==='V3' && editDialog.hxForm.contentMap && editDialog.hxForm.contentMap.doorHeadPhotoV3 && parseJsonData(editDialog.hxForm.contentMap.doorHeadPhotoV3, 'show')">
                <el-image v-for="(item,index) in getModulePhotos(editDialog.hxForm.contentMap.doorHeadPhotoV3)" :preview-src-list="getModulePhotos(editDialog.hxForm.contentMap.doorHeadPhotoV3)" :key="index" :src="item" lazy style="height: 80px;margin: 5px;">
                  <div slot="error" class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                  </div>
                </el-image>
              </div>
              <div v-else-if="editDialog.hxForm.contentMap && editDialog.hxForm.contentMap.doorHeadPhoto">
                <el-image v-for="(item,index) in editDialog.hxForm.contentMap.doorHeadPhoto" :preview-src-list="[item]" :key="index" :src="item" lazy style="height: 80px;margin: 5px;">
                  <div slot="error" class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                  </div>
                </el-image>
              </div>
            </el-descriptions-item>
            <el-descriptions-item  v-for="(item,index) in getRealTasks()" :key="index" :label="'任务' + (index+1)" :span=18>
              <div>
                <label>{{ item.taskName }}</label>
                <div v-if="getConfigVersion(item.mtTaskId) !== 'V2' && item.boostDetail" style="background-color: #FCF7EE ; border-radius: 5px; margin: 10px;padding: 5px;">
                  <p style="color: #A56321"><strong>{{ item.boostDetail.boostName }}：</strong>{{ item.boostDetail.boostDescription }}</p>
                  <div>
                    <p v-for="m in item.boostDetail.list" :key="m.measure">
                      <span>{{ m.measureName }}</span><span :style="m.measureData >= m.boostMeasureData ? { color: 'red'}: {color: '#3B8DDA'}">
                        {{ m.measureData? m.measureData: 0 }} / {{ m.boostMeasureData? m.boostMeasureData: 0 }}</span>{{ measureMap[m.measure].unit }}
                    </p>
                  </div>
                </div>
                <span>上传照片</span>
                <div v-for="(e, indx) in getTaskBoostPhoto(item.mtTaskId, item.boostId)" :key="indx">
                  <label>{{ e.name }}</label><label v-if="editDialog.hxForm.firstModules.indexOf(e.id) > -1" style="color:red; margin-left: 5px">首次打造</label>
                  <div v-if="getConfigVersion(item.mtTaskId) === 'V3'">
                    <div v-for="(p,pIndex) in e.photoConfigs" :key="pIndex">
                      <p style="margin: 8px 0px;font-size: 13px;color: #858585;">{{ p.name }} <span>{{ p.remark }}</span></p>
                      <div style="display: inline-block">
                        <p style="margin: 8px 0px;font-size: 13px;color: #858585;">{{ p.desc }}</p>
                        <div v-for="(im, imIndex) in p.photos" :title="getPhotoTitle(translateResourceV3([im], 'image')[0])" :key="imIndex" style="display: inline-block" >
                          <el-image :src="translateResourceV3([im], 'image')[0]" lazy style="height: 80px;margin: 5px;" :preview-src-list="translateResourceV3(p.photos, 'image')" >
                            <div slot="error" class="image-slot">
                              <i class="el-icon-picture-outline"></i>
                            </div>
                          </el-image>
                        </div>
                      </div>
                      <div v-if="p.showAfter" style="display: inline-block; margin-left: 20px">
                        <p style="margin: 8px 0px;font-size: 13px;color: #858585;">{{ p.descAfter }}</p>
                        <div v-for="(im, imIndex) in p.photosAfter" :title="getPhotoTitle(translateResourceV3([im], 'image')[0])" :key="imIndex" style="display: inline-block" >
                          <el-image :src="translateResourceV3([im], 'image')[0]" lazy style="height: 80px;margin: 5px;" :preview-src-list="translateResourceV3(p.photosAfter, 'image')" >
                            <div slot="error" class="image-slot">
                              <i class="el-icon-picture-outline"></i>
                            </div>
                          </el-image>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="getConfigVersion(item.mtTaskId) === 'V2'" style="background-color: #FCF7EE ; border-radius: 5px; margin: 10px;padding: 5px;width: 400px">
                    <el-row>
                      <el-col :span="6" style="background-color: #FCF0DB; border-radius: 4px; padding: 5px;">本月进货额</el-col>
                      <el-col :span="6" style="background-color: #FCF0DB; border-radius: 4px; padding: 5px;">近90天进货金额</el-col>
                      <el-col :span="6" style="background-color: #FCF0DB; border-radius: 4px; padding: 5px;">近90天上柜款数</el-col>
                      <el-col :span="6" style="background-color: #FCF0DB; border-radius: 4px; padding: 5px;">阵地款数目标</el-col>
                    </el-row>
                    <el-row>
                      <el-col :span="6" style="background-color: #FCF0DB; border-radius: 4px; padding: 5px;">{{ item.boostDetail.shopByJhTotal?item.boostDetail.shopByJhTotal: 0 }}  元</el-col>
                      <el-col :span="6" style="background-color: #FCF0DB; border-radius: 4px; padding: 5px;">{{ item.boostDetail.shopJhTotal?item.boostDetail.shopJhTotal:0 }} 元</el-col>
                      <el-col :span="6" style="background-color: #FCF0DB; border-radius: 4px; padding: 5px;">{{ item.boostDetail.shopJhKss?item.boostDetail.shopJhKss:0 }} 款</el-col>
                      <el-col :span="6" style="background-color: #FCF0DB; border-radius: 4px; padding: 5px;">{{ item.boostDetail.moduleTarget?item.boostDetail.moduleTarget:0 }} 款</el-col>
                    </el-row>
                    <div>
                      <div style="display: inline-block">
                        <p style="margin: 8px 0px;font-size: 13px;color: #858585;">{{ e.desc }}</p>
                        <div v-for="(im, imIndex) in e.photos" :title="getPhotoTitle(im)" :key="imIndex" style="display: inline-block" >
                          <el-image :src="im" lazy style="height: 80px;margin: 5px;" :preview-src-list="e.photos" >
                            <div slot="error" class="image-slot">
                              <i class="el-icon-picture-outline"></i>
                            </div>
                          </el-image>
                        </div>
                      </div>
                      <div v-if="e.showAfter" style="display: inline-block; margin-left: 20px">
                        <p style="margin: 8px 0px;font-size: 13px;color: #858585;">{{ e.descAfter }}</p>
                        <div v-for="(im, imIndex) in e.photosAfter" :title="getPhotoTitle(im)" :key="imIndex" style="display: inline-block" >
                          <el-image :src="im" lazy style="height: 80px;margin: 5px;" :preview-src-list="e.photosAfter" >
                            <div slot="error" class="image-slot">
                              <i class="el-icon-picture-outline"></i>
                            </div>
                          </el-image>
                        </div>
                      </div>
                    </div>
                    <!--                    <p style="background-color: #FCF0DB; border-radius: 4px; width: fit-content; padding: 5px;"><span>本月进货额</span><span>{{ item.jhDataDto.byJh }}  元</span></p>-->
                    <!--                    <p style="background-color: #FCF0DB; border-radius: 4px; width: fit-content; padding: 5px;"><span>近90天进货金额</span><span>{{ item.jhDataDto.jhRecent90 }} 元</span></p>-->
                    <!--                    <p style="background-color: #FCF0DB; border-radius: 4px; width: fit-content; padding: 5px;"><span>近90天上柜款数</span><span>{{ item.jhDataDto.sgRecent90 }} 款</span></p>-->
                  </div>
                  <div v-else>
                    <div style="display: inline-block">
                      <p style="margin: 8px 0px;font-size: 13px;color: #858585;">{{ e.desc }}</p>
                      <div v-for="(im, imIndex) in e.photos" :title="getPhotoTitle(im)" :key="imIndex" style="display: inline-block" >
                        <el-image :src="im" lazy style="height: 80px;margin: 5px;" :preview-src-list="e.photos" >
                          <div slot="error" class="image-slot">
                            <i class="el-icon-picture-outline"></i>
                          </div>
                        </el-image>
                      </div>
                    </div>
                    <div v-if="e.showAfter" style="display: inline-block; margin-left: 20px">
                      <p style="margin: 8px 0px;font-size: 13px;color: #858585;">{{ e.descAfter }}</p>
                      <div v-for="(im, imIndex) in e.photosAfter" :title="getPhotoTitle(im)" :key="imIndex" style="display: inline-block" >
                        <el-image :src="im" lazy style="height: 80px;margin: 5px;" :preview-src-list="e.photosAfter" >
                          <div slot="error" class="image-slot">
                            <i class="el-icon-picture-outline"></i>
                          </div>
                        </el-image>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </el-descriptions-item>
            <el-descriptions-item  v-for="(item,index) in getRealHxTaskData(editDialog.hxForm)" :key="index" :label="'任务' + (index+1)" :span=18>
              <div>
               <el-row v-for="(e, indx) in item.boosts" :key="indx" style="width: 100%; display: block; flex: 1">
                  <p style="color: #A56321"><strong>{{ getTaskInfo(item, e.id).boostDetail.boostName }}：</strong>{{ getTaskInfo(item, e.id).boostDetail.boostDescription }}</p>
                  <div v-if="getTaskInfo(item, e.id).boostDetail" style="background-color: #FCF7EE ; border-radius: 5px; margin: 10px;padding: 5px;">
                    <p v-for="(m, mIndex) in getTaskInfo(item, e.id).boostDetail.list" :key="mIndex">
                      <span>{{ m.measureName }}</span><span :style="m.measureData >= m.boostMeasureData ? { color: 'red'}: {color: '#3B8DDA'}">
                    {{ m.measureData? m.measureData: 0 }} / {{ m.boostMeasureData? m.boostMeasureData: 0 }}</span>{{ measureMap[m.measure].unit }}
                    </p>
                  </div>
                  <div v-for="(md, mdIndex) in e.moduleConfigs" :key="mdIndex">
                    <label>{{ md.name }}</label><label v-if="editDialog.hxForm.firstModules.indexOf(md.id) > -1" style="color:red; margin-left: 5px">首次打造</label>
                    <div v-for="(p,pIndex) in getResourceV3(md.id, 'photoConfigs')" :key="pIndex">
                      <p style="margin: 8px 0px;font-size: 13px;color: #858585;">{{ p.name }} <span>{{ p.remark }}</span></p>
                      <div style="display: inline-block">
                        <p style="margin: 8px 0px;font-size: 13px;color: #858585;">{{ p.desc }}</p>
                        <div v-for="(im, imIndex) in p.photos" :title="getPhotoTitle(translateResourceV3([im], 'image')[0])" :key="imIndex" style="display: inline-block" >
                          <el-image :src="translateResourceV3([im], 'image')[0]" lazy style="height: 80px;margin: 5px;" :preview-src-list="translateResourceV3(p.photos, 'image')" >
                            <div slot="error" class="image-slot">
                              <i class="el-icon-picture-outline"></i>
                            </div>
                          </el-image>
                        </div>
                      </div>
                      <div v-if="p.showAfter" style="display: inline-block; margin-left: 20px">
                        <p style="margin: 8px 0px;font-size: 13px;color: #858585;">{{ p.descAfter }}</p>
                        <div v-for="(im, imIndex) in p.photosAfter" :title="getPhotoTitle(translateResourceV3([im], 'image')[0])" :key="imIndex" style="display: inline-block" >
                          <el-image :src="translateResourceV3([im], 'image')[0]" lazy style="height: 80px;margin: 5px;" :preview-src-list="translateResourceV3(p.photosAfter, 'image')" >
                            <div slot="error" class="image-slot">
                              <i class="el-icon-picture-outline"></i>
                            </div>
                          </el-image>
                        </div>
                      </div>
                    </div>
                    <el-row v-if="md.textConfigs">
                      <el-col><label style="font-size: 16px "> {{ md.textConfigs.name }} </label><span>{{ md.textConfigs.remark }}</span></el-col>
                      <el-col :span="18" style="margin-left: 20px">
                        <el-col v-for="t in getResourceV3(md.id, 'textConfigs').configs" :key="t.id"><el-checkbox v-model="t.checked" class="checked-class" disabled /><span>{{ t.desc }}</span>
                          <span v-if="t.chooseType !='select'">
                          <span style="margin-left: 10px" v-if="t.count!=undefined"> {{ item.count }}</span>
                        </span>
                          <span v-else>
                          <el-select v-model="t.count">
                            <el-option v-for="c in t.chooseText" :key="c" :label="c" :value="c" />
                          </el-select>
                        </span>
                        </el-col>
                      </el-col>
                    </el-row>
                  </div>
                  <div>
                  </div>
                </el-row>
              </div>
<!--              <label>{{ getTaskInfo(item, undefined).taskName }}</label>-->
            </el-descriptions-item>
          </el-descriptions>
        </el-row>
        <el-row style="display: inline-block;">
          <el-row v-if="getConfigVersion() === 'V3' && editDialog.hxForm.contentMap.textConfigs" style="">
            <el-col><label style="font-size: 16px;"> {{ editDialog.hxForm.contentMap.textConfigs.name }} </label><span>{{ editDialog.hxForm.contentMap.textConfigs.remark }}</span></el-col>
            <el-col :span="18" style="margin-left: 20px">
              <el-col v-for="item in editDialog.hxForm.contentMap.textConfigs.configs" :key="item.id"><el-checkbox v-model="item.checked" class="checked-class" disabled /><span>{{ item.desc }}</span>
                <span v-if="item.chooseType !='select'">
                  <span style="margin-left: 10px" v-if="item.value!=undefined"> {{ item.value }}</span>
                </span>
                <span v-else>
                  <el-select v-model="item.value">
                    <el-option v-for="c in item.chooseText" :key="c" :label="c" :value="c" />
                  </el-select>
                </span>
              </el-col>
            </el-col>
          </el-row>
          <el-row v-if="getConfigVersion() !== 'V3' && editDialog.hxForm.contentMap.materialUsage">
            <el-col :span="3"><label style="font-size: 16px "> 道具使用 </label></el-col>
            <el-col :span="18">
              <el-col v-for="item in editDialog.hxForm.contentMap.materialUsage" :key="item.id"><el-checkbox v-model="item.checked" class="checked-class" disabled /><span>{{ item.name }}</span> <span style="margin-left: 10px" v-if="item.count!=undefined"> {{ item.count }}</span></el-col>
            </el-col>
          </el-row>
        </el-row>
        <el-row style="margin-top:10px">
          <el-table
            :data="editDialog.hxForm.opList"
            size="mini"
            :height="200"
          >
            <el-table-column label="操作人" prop="userName" width="180">
              <template #default="{ row }">
                <span>{{ row.userName ? (row.userName + (row.userName ? '-' + row.nickName: '')):'' }}</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" prop="opType" width="180">
              <template #default="{ row }">
                <span>{{ statusActionMap[row.opType] }}</span>
              </template>
            </el-table-column>
            <el-table-column label="备注" prop="remark" width="180" />
            <el-table-column label="时间" prop="createTime" width="180" />
          </el-table>
        </el-row>
        <el-row>
          <el-form-item label="审核说明" label-width="80px">
            <el-input clearable v-model="editDialog.form.remark" placeholder="请输入" type="textarea" />
            <el-input v-show="false" clearable v-model="editDialog.form.remark" placeholder="请输入" type="textarea" />
          </el-form-item>
        </el-row>
      </el-form>

      <div slot="footer" class="dialog-footer" style="text-align: right" v-if="!editDialog.disabled">
        <el-button type="primary" size="mini" @click="editDialog.visible = false">取消</el-button>
        <el-button v-if="editDialog.type == 'audit' && computeButtonShow(editDialog.form.status, 'pass')" type="success" size="mini" @click="submitAudit(1)">通过</el-button>
        <el-button v-if="editDialog.type == 'audit' && computeButtonShow(editDialog.form.status, 'reject')"  type="danger" size="mini" @click="submitAudit(2)">驳回</el-button>
      </div>
    </el-dialog>

    <el-dialog title="同步" :visible.sync="exportDialog.visible" width="700px" append-to-body>
      <div>
        <el-form ref="form" size="mini" inline label-width="160px" :model="exportDialog.form" class="common-form">
          <el-row>
            <el-form-item label="分公司">
              <el-select v-model="exportDialog.orgCodes" filterable default-first-option placeholder="不选默认导出全部" multiple :collapse-tags="true" style="width: 200px">
                <el-option v-for="item in this.options.orgOptions" :key="item.value" :value="item.value" :label="item.value + '-' + item.label" />
              </el-select>
              <el-button type="primary" @click="() => {
                exportDialog.orgCodes = [].concat(this.options.orgOptions.map(e => e.value))
              }">全选</el-button>
              <el-button type="primary" @click="() => {
                exportDialog.orgCodes = []
              }">取消全选</el-button>
            </el-form-item>
          </el-row>
        </el-form>
        <el-row style="margin: 10px">
          <el-button size="small" type="primary" @click="handleExportSpecial">导出</el-button>
          <el-button size="small" @click="exportDialog.visible = false">取消</el-button>
        </el-row>
      </div>
    </el-dialog>

  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import { pageHx,getHxDetail,auditBatchHx, auditSingleHx, dictSelect, getUserRoleKey, getHxList, exportVerification,getStatusOptions, getDownloadRecords, getVerificationConf } from "@/api/appTask";
import { parseTime, deepClone } from "@/utils"
import SearchFilter from "@/components/SearchFilter/index";
import { Notification } from "element-ui";
import DictSelect from "@/components/DictSelect";

export default {
  name: "HxAudit",
  components: { Pagination, DictSelect, SearchFilter },
  filters: {
    statusFilter(status) {
      const statusMap = {
        0: "info",
        1: "primary",
        2: "success",
        3: "warning",
        4: "primary",
        5: "primary",
      };
      return statusMap[status];
    },
    downloadStatusFilter(status) {
      const statusMap = {
        "0": "primary",
        "1": "success",
        "2": "error",
      };
      return statusMap[status];
    },
  },
  data() {
    return {
      parseTime,
      pageData: {
        total: 0,
        rows: [],
        selections: [],
      },
      queryForm: {
        current: 1,
        size: 10,
        id: undefined,
        status: undefined,
        orgCode: undefined,
        dcCode: undefined,
        shopCode: undefined,
        startDate: undefined,
        endDate: undefined,
        moduleIds: [],
      },
      queryDate: [],
      loading: false,
      dictParam: {},
      dictFun: dictSelect,
      orgAllAuth: false,
      editDialog: {
        visible: false,
        disabled: false,
        type: 'audit',
        form: {
          id: undefined,
          orgCode: undefined,
          poiCode: undefined,
          dcCode: undefined,
          shopCode: undefined,
          shopCapacity: undefined,
          oldShopCapacity: undefined,
          auditShopCapacity: undefined,
          ifOptimumShop: undefined,
          oldIfOptimumShop: undefined,
          auditIfOptimumShop: undefined,
          deleted: 0,
          status: 0,
          createTime: undefined,
          createUser: undefined,
          updateTime: undefined,
          updateUser: undefined,
          orgName: undefined,
          dcName: undefined,
          shopName: undefined,
          responsibler: undefined,
        },
        hxForm: {
          contentMap: {},
          opList: [],
          configJson: undefined,
          fullOptionList: []
        },
        rules: {
        },
      },
      statusActionMap: {
        "save": '保存',
        "submit": '提交审批',
        "update": '更新',
        "org_pass": '分公司审批通过',
        "pass": "审核通过",
        "reject": "审核驳回",
        "org_reject": '分公司审批驳回',
        "head_pass": '总部审批通过',
        "head_reject": '总部审批驳回',
        "withdraw": '撤回'
      },
      options: {
        statusOptions: [{value: '', label: '全部'},{value: '1', label: '待分公司审批'},{value: '4', label: '分公司审批驳回'},{value: '3', label: '待总部审批'},{value: '2', label: '总部审批通过'},{value: '5', label: '总部审批驳回'}],
        hxOptions: [],
        rowStatusOptions: [],
        moduleOptions: [],
      },
      userRoleKeys: [],
      orgAuth: false,
      headAuth: false,
      downloadData: {
        total: 0,
        loading: false,
        rows: [],
        queryForm: {
          current: 1,
          size: 3,
        },
        downloadStatusMap: {
          "1": "生成成功",
          "0": "生成中",
          "2": "生成失败"
        }
      },
      downloading: false,
      exportDialog: {
        visible: false,
        orgCodes: []
      },
      measureMap: {
        'kss': { unit :'款', label: '上柜款数'},
        'jh_total': { unit :'元', label: '进货金额'},
        'kss_diff': { unit :'款', label: '新增款数'},
        'jh_count': { unit :'个', label: '进货数量'},
        'kss_jbp': { unit :'款', label: '上柜款数'},
        'jh_total_jbp': { unit :'元', label: '进货金额'},
        'kss_diff_jbp': { unit :'款', label: '新增款数'},
        'jh_count_jbp': { unit :'个', label: '进货数量'}
      },
    };
  },
  computed: {
    computeMaxWidth() {
      let maxWidth = 300;
      this.pageData.rows.forEach(e => {
        if (e.photos && e.photos.length > 0) {
          if (e.photos.length * 60 > maxWidth) {
            maxWidth = e.photos.length * 60;
          }
        }
      })
      return maxWidth + 40;
    }
  },
  watch: {
    "queryForm.id": {
      handler(newVal, oldVal) {
        this.options.moduleOptions = [];
        this.queryForm.moduleIds = [];
        if (newVal && newVal !== '') {
          getVerificationConf(newVal).then(res => {
            if (res.data && res.data.configJson) {
              const configJson = JSON.parse(res.data.configJson);
              const associationTask = configJson.associationTask;
              if (associationTask) {
                associationTask.forEach(e => {
                  const version = e.verificationType;
                  if ( e && e.boosts && e.boosts.length > 0) {
                    e.boosts.forEach(b => {
                      if ("V3"== version) {
                        if (b.moduleConfigs.length > 0) {
                          b.moduleConfigs.forEach(c => {
                            this.options.moduleOptions.push({ value: c.id, label: c.name })
                          })
                        }
                      } else {
                        if (b.modules.length > 0) {
                          b.modules.forEach(c => {
                            this.options.moduleOptions.push({ value: c.id, label: c.name })
                          })
                        }
                      }
                    })
                  }
                })
              }
            }
          })
        }
      },
      immediate: true,
      deep:true
    }
  },
  created() {
    const date = new Date();
    const firstDay = new Date(date.getFullYear(), date.getMonth() - 4, 1);
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0, 23, 59, 59);
    this.queryDate = [parseTime(firstDay, '{y}-{m}-{d} {h}:{i}:{s}'), parseTime(lastDay, '{y}-{m}-{d} {h}:{i}:{s}')];
    dictSelect('ht-org-all').then(res => {
      this.orgAllAuth = res.data.filter(e => e.value === 'ALL').length > 0 ? true: false;
      this.options.orgOptions = res.data.filter(e => e.value !== 'ALL');
      if (!this.orgAllAuth) {
        this.queryForm.orgCode = this.options.orgOptions[0].value;
      }
      getHxList().then(res => {
        this.options.hxOptions = res.data;
        if (this.options.hxOptions.length > 0) {
          this.queryForm.id = this.options.hxOptions[0].id;
          getStatusOptions(this.queryForm.id).then(res => {
            this.options.statusOptions = res.data.list.filter(e => e.status!='0');
            this.handleFilter();
          })
        }
      })
    })
    getUserRoleKey().then(res => {
      this.userRoleKeys = res.data;
      if (this.userRoleKeys.indexOf('jbp_hx_audit_head') > -1) {
        this.headAuth = true;
      }
      if (this.userRoleKeys.indexOf('jbp_hx_audit_org') > -1) {
        this.orgAuth = true;
      }
    })
    getDownloadRecords({ status: '0', size: '1'}).then(res => {
      if (res.data.total > 0) {
        this.downloading = true;
      }
    })
  },
  mounted() {
    // this.handleFilter();
    getDownloadRecords(this.downloadData.queryForm).then(res => {
      this.downloadData.rows = res.data.records;
      this.downloadData.total = res.data.total;
    })
  },
  methods: {
    resetQuery(){
        const date = new Date();
        const firstDay = new Date(date.getFullYear(), date.getMonth() - 4, 1);
        const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0, 23, 59, 59);
        this.queryDate = [parseTime(firstDay, '{y}-{m}-{d} {h}:{i}:{s}'), parseTime(lastDay, '{y}-{m}-{d} {h}:{i}:{s}')];
        const queryForm = {
        current: 1,
        size: 10,
        queryForm:this.queryForm.id
      };
      this.queryForm = Object.assign({}, queryForm);
      this.$forceUpdate();
    },
    handleQuery() {
      this.loading = true;
      let query = Object.assign({}, this.queryForm);
      this.setQueryShop(query);
      if (this.queryDate && this.queryDate.length > 0) {
        query.startDate = this.queryDate[0];
        query.endDate = this.queryDate[1];
      } else {
        query.startDate = undefined;
        query.endDate = undefined;
      }
      pageHx(query).then(res => {
        this.loading = false;
        if (!res.data) {
          return false;
        }
        this.pageData.rows = res.data?.records;
        this.pageData.total = res.data?.total;
        this.$refs.table.doLayout();
      });
    },
    setQueryShop(query) {
      if (this.queryForm.shopCode) {
        const shop = this.$refs['ht-shop'].itemOptions.find(e => e.fullCode === this.queryForm.shopCode);
        query.shopCode = undefined;
        if (shop) {
          query.shopCode = shop.value;
          query.dcCode = shop.fullCode.replace("_" +query.shopCode, "");
        }
      }
    },
    handleFilter() {
      this.queryForm.current = 1;
      this.handleQuery();
    },
    // resetQuery() {
    //   const queryForm = {
    //     current: 1,
    //     size: 10,
    //   };
    //   this.queryForm = Object.assign({}, queryForm);
    //   this.$forceUpdate();
    // },
    handlePageDownload() {
      getDownloadRecords(this.downloadData.queryForm).then(res => {
        this.downloadData.rows = res.data.records;
        this.downloadData.total = res.data.total;
      })
    },
    handleSelectionChange(selections) {
      this.pageData.selections = selections;
    },
    handleChangeLinkageRef(ref, val, type, form, dict) {
      this.$nextTick(() => {
        ref.split(',').forEach(e => {
          if (this.$refs[e]) {
            if (form && dict) {
              let tmp = Object.assign({}, form);
              for(const key in this[dict]) {
                this[dict][key] = tmp[key];
              }
            } else {
              this.dictParam = Object.assign({}, this.queryForm)
            }
            setTimeout(() => {
              this.$refs[e].handleRefChange(val, type);
            }, 100)
            this.$forceUpdate();
          } else {
            this.dictParam = Object.assign({}, this.queryForm)
          }
        })
      });
    },
    handleAudit(row, type) {
      this.loading = true;
      this.editDialog.form = deepClone(row);
      this.editDialog.type = type;
      this.editDialog.hxForm = {
        configJson: {},
        contentMap: {
          associationTask: [],
          doorHeadPhoto: [],
          materialUsage: [],
          photos: []
        },
        opList: [],
        relTasks: []
      }
      this.editDialog.disabled = false;
      getHxDetail(row).then(res => {

        if (res.data.contentMap) {
          for (let key in res.data.contentMap) {
            if (res.data.contentMap[key]) {
              res.data.contentMap[key] = JSON.parse(res.data.contentMap[key]);
            }
          }
        }
        if (res.data.configJson) {
          res.data.configJson = JSON.parse(res.data.configJson);
        }
        this.editDialog.hxForm = res.data;
        this.editDialog.visible = true;
        this.loading = false;
      })
    },
    getTaskBoostPhoto(val, boostId) {
      const task = this.editDialog.hxForm.contentMap.associationTask.find(e => e.mtTaskId === val);
      if (this.getConfigVersion() === 'V3') {
        if (task.boosts.length === 1) {
          return task.boosts[0].moduleConfigs;
        }
        return task && task.boosts ?task.boosts.find(e => e.id === boostId + '')?.moduleConfigs : [];
      }
      const booosts = task && task.boosts ?task.boosts.find(e => e.id === boostId + '')?.modules : [];
      return booosts;
    },
    getConfigVersion(val) {
      let task;
      if (this.editDialog.hxForm.contentMap) {
        if (this.editDialog.hxForm.contentMap.textConfigs || this.editDialog.hxForm.contentMap.doorHeadPhotoV3) {
          return "V3"
        }
        if (this.editDialog.hxForm.contentMap.associationTask) {
          if (val) {
            task = this.editDialog.hxForm.contentMap.associationTask.find(e => e.mtTaskId === val);
          } else {
            task = this.editDialog.hxForm.contentMap.associationTask[0];
          }
        }
      }
      return task && task.verificationType ? task.verificationType: "V1";
    },
    submitAudit(type) {
      this.$confirm('请确认是否 ' + (type===2? '驳回':'通过'))
        .then(() => {
          this.editDialog.disabled = true;
          let form = deepClone(this.editDialog.form);
          form.status = type;
          auditSingleHx(form).then(res => {
            this.editDialog.disabled = false;
            if(res && res.code === 200) {
              if (res.data !== '') {
                Notification.warning(res.data);
              } else {
                Notification.success("操作成功！");
              }
              this.editDialog.visible = false;
              this.handleFilter();
            }
          })
        })
        .catch(() => {});

    },
    handleBatchAudit(type) {
      if (this.pageData.selections.length === 0) {
        Notification.warning("请至少选择一条正确的记录！");
        return;
      }
      let data = deepClone(this.pageData.selections);
      if (type === '1') {
        if (data.filter(e => e.status !=='2').length === 0) {
          Notification.warning("请至少选择一条正确的记录，已通过的不可重新通过！");
          return;
        }
        data = data.filter(e => e.status !=='2');
      }
      this.$prompt('请填写审核备注', '请确认是否批量 ' + (type==='2'? '驳回':'通过') + ' 勾选的' + data.length + '条数据！', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        center: true
      }).then(({ value }) => {
        this.editDialog.disabled = true;
        const verificationId = this.pageData.selections[0].id;
        auditBatchHx({ ids: this.pageData.selections.map(e => e.recordId), status: type, remark: value,verificationId:verificationId }).then(res => {
          this.editDialog.disabled = false;
          if(res && res.code === 200) {
            if (res.data !== '') {
              Notification.warning(res.data);
            } else {
              Notification.success("操作成功！");
            }
            this.handleFilter();
          }
        })
      }).catch(() => {
      });
    },
    computeButtonShow(status, type) {
      if (type === 'pass') {
        if (status === '2') { // 通过
          return false;
        } else if (status === '1') { // 待分公司审批
          if (this.orgAuth) {
            return true;
          } else {
            return false;
          }
        } else if (status === '3') { // 待总部审批
          if (this.headAuth) {
            return true;
          } else {
            return false;
          }
        }
      } else if (type === 'reject') {
        if (status === '2' || status === '1' || status === '3') { // 驳回
          if (this.orgAuth || this.headAuth) {
            return true;
          } else {
            return false;
          }
        }
      }
    },
    onDialogOpened() {
      this.$nextTick(() =>{
        this.$refs.editDialogRef.$el.focus()
      })
    },
    handleExport(orgCodes) {
      if (!orgCodes && this.options.orgOptions.length > 1 && !this.exportDialog.visible) {
        this.exportDialog.visible = true;
        return
      }
      this.loading = true;
      let query = Object.assign({}, this.queryForm);
      this.setQueryShop(query);
      if (this.queryDate && this.queryDate.length > 0) {
        query.startDate = this.queryDate[0];
        query.endDate = this.queryDate[1];
      } else {
        query.startDate = undefined;
        query.endDate = undefined;
      }
      query.exportOrgCodes = orgCodes;
      exportVerification(query).then(res => {
        this.loading = false;
        Notification.success("数据下载中，请稍候！");
        this.downloading = true;

        this.exportDialog.visible = false;
        if (this.timeIntevarl) {
          clearInterval(this.timeIntevarl);
        }
        this.timeIntevarl = setInterval(() => {
          if (!this.downloading) {
            if (this.timeIntevarl) {
              clearInterval(this.timeIntevarl);
            }
          }
          getDownloadRecords({ size: '1', type: 'HX'}).then(res => {
            if (res.data.total > 0) {
              if (res.data.records.filter(e => e.status!='0').length > 0) {
                this.downloading = false;
                Notification.success("数据已生成，请查看导出列表！");
                if (this.timeIntevarl) {
                  clearInterval(this.timeIntevarl);
                }
              }
            }
          })
        }, 5000);
      })
    },
    getRowLabel(row) {
      if (row.id === 'bdb028d997021c6982fb363bff803fe9' || row.id==='3f9e6e7745099ee80fee63a75b3ce79c') {
        if (row.status === '2') {
          return '审核通过'
        }
      }
      const status = this.options.statusOptions.find(e => e.status===row.status);
      return  status ? status.text : row.status
    },
    handleHxChange(val) {
      if (val) {
        getStatusOptions(val).then(res => {
          this.queryForm.status = ''
          this.options.statusOptions = res.data.list.filter(e => e.status!='0');
        })
      }
    },
    getPhotoTitle(e) {
      if (this.editDialog.hxForm.contentMap.photos) {
        const photoObj = this.editDialog.hxForm.contentMap.photos.find(p => p.url===e)
        if (photoObj) {
          return "拍摄地理位置: " + photoObj.address + "\n" +  "距离店铺距离: " + photoObj.length + "米（仅供参考）\n" +
                 "拍摄时间:        " + photoObj.createdAt + "\n" + "拍摄提交时间: " + this.editDialog.form.createTime;
        } else {
         return ''
        }
      }
      return ''
    },
    handleExportSpecial() {
      this.handleExport(this.exportDialog.orgCodes)
    },
    getModulePhotos(r) {
      return r && r.value ? r.value.filter(e => e.fileType=='image').map(e => e.path): []
    },
    parseJsonData(v, k) {
      if (v) {
        if (v instanceof String) {
          const json = JSON.parse(v);
          return json[k] ? json[k] : false;
        } else {
          return v[k] ? v[k] : false;
        }
      }
      return false;
    },
    translateResourceV3(photos, type) {
      if (photos) {
        return photos.filter(e => e.fileType===type).map(e => e.path)
      }
      return [];
    },
    getRealTasks() {
      let version;
      if (!this.editDialog.hxForm.configJson || !this.editDialog.hxForm.configJson.associationTask) {
        return [];
      }
      this.editDialog.hxForm.configJson.associationTask.filter(e => {
        if (!version) {
          version = e.verificationType;
        }
      })
      if (version === 'V3') {
        return []
      } else {
        return this.editDialog.hxForm.relTasks;
      }
    },
    getRealHxTaskData(data) {
      if (!data || !data.configJson || !data.configJson.associationTask) {
        return []
      }
      return data.configJson.associationTask.filter(e => e.boosts && e.boosts.length > 0 && e.verificationType === 'V3')
    },
    getTaskInfo(item, boostId) {
      const task = this.editDialog.hxForm.relTasks.find(e => {
        if (boostId) {
          return e.mtTaskId = item.mtTaskId && e.boostId === boostId
        } else {
          return e.mtTaskId === item.mtTaskId
        }
      });
      console.log(task)
      return task? task: {};
    },
    getResourceV3(id, key) {
      if (!this.editDialog.hxForm || !this.editDialog.hxForm.contentMap || !this.editDialog.hxForm.contentMap.associationTask) {
        return []
      }
      let data;
      this.editDialog.hxForm.contentMap.associationTask.filter(e => {
        e.boosts.filter(b => {
          b.moduleConfigs.filter(m => {
            if (m.id === id) {
              data = deepClone(m[key])
            }
          })
        })
      })
      return data;
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding-top: 10px;
  padding-bottom: 5px;
}

.common-form {
  &-row {
    display: flex !important;
    flex-wrap: wrap;
  }
  div .el-form-item {
    display: flex !important;
    ::v-deep {
      .el-form-item__content {
        width: 100%;
        .el-input {
          width: 100%;
        }
        .el-input-number {
          width: 100%;
        }
        .el-select {
          width: 100%;
        }
        .el-date-editor {
          width: 100%;
        }
      }
    }
  }
}
.link-text-class {
  margin-left: 20px;
  color: gray;
}
::v-deep .el-form-item__error {
  top: 70%
}

.form-label-class {
  font-size: 16px;
  font-weight: 500;
  color: #f57d13;
}
::v-deep .checked-class .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: red;
}
//::v-deep .el-descriptions-item__content {
//  display: block !important;
//}
.skuUrlThum {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 70%;

  ::v-deep {
    img {
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }
}
</style>
