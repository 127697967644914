var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "new-box" },
    [
      _c(
        "div",
        { staticClass: "mg-search" },
        [
          _c(
            "el-card",
            { staticStyle: { margin: "10px" } },
            [
              _c(
                "SearchFilter",
                {
                  attrs: { "label-width": "150px" },
                  on: {
                    search: function ($event) {
                      _vm.FormData.page = 1
                      _vm.handleFilter()
                    },
                    reset: _vm.resetQuery,
                  },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      _vm.FormData.page = 1
                      _vm.handleFilter()
                    },
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "月份" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.FormData.mth,
                            callback: function ($$v) {
                              _vm.$set(_vm.FormData, "mth", $$v)
                            },
                            expression: "FormData.mth",
                          },
                        },
                        _vm._l(_vm.mth, function (item) {
                          return _c("el-option", {
                            key: item.code,
                            attrs: { label: item.name, value: item.code },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "是否拆架装" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.FormData.types,
                            callback: function ($$v) {
                              _vm.$set(_vm.FormData, "types", $$v)
                            },
                            expression: "FormData.types",
                          },
                        },
                        [
                          _c("el-option", { attrs: { label: "否", value: 0 } }),
                          _c("el-option", { attrs: { label: "是", value: 1 } }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "赛道" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            filterable: "",
                            multiple: "",
                            "collapse-tags": "",
                            placeholder: "请选择",
                          },
                          model: {
                            value: _vm.FormData.newRaceNames,
                            callback: function ($$v) {
                              _vm.$set(_vm.FormData, "newRaceNames", $$v)
                            },
                            expression: "FormData.newRaceNames",
                          },
                        },
                        _vm._l(_vm.race, function (item) {
                          return _c("el-option", {
                            key: item.code,
                            attrs: { label: item.name, value: item.code },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "大类" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            filterable: "",
                            multiple: "",
                            "collapse-tags": "",
                            placeholder: "请选择",
                          },
                          on: { change: _vm.changeCategories },
                          model: {
                            value: _vm.FormData.newMainCategoryNames,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.FormData,
                                "newMainCategoryNames",
                                $$v
                              )
                            },
                            expression: "FormData.newMainCategoryNames",
                          },
                        },
                        _vm._l(_vm.mainCategory, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: {
                              label: item.category,
                              value: item.category,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "中类" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            filterable: "",
                            multiple: "",
                            "collapse-tags": "",
                            placeholder: "请选择",
                          },
                          on: { change: _vm.changeMediumClass },
                          model: {
                            value: _vm.FormData.newMidCategoryNames,
                            callback: function ($$v) {
                              _vm.$set(_vm.FormData, "newMidCategoryNames", $$v)
                            },
                            expression: "FormData.newMidCategoryNames",
                          },
                        },
                        _vm._l(_vm.midCategory, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: {
                              label: item.category,
                              value: item.category,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "小类" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            filterable: "",
                            multiple: "",
                            "collapse-tags": "",
                            placeholder: "请选择",
                          },
                          model: {
                            value: _vm.FormData.newSubCategoryNames,
                            callback: function ($$v) {
                              _vm.$set(_vm.FormData, "newSubCategoryNames", $$v)
                            },
                            expression: "FormData.newSubCategoryNames",
                          },
                        },
                        _vm._l(_vm.subCategory, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: {
                              label: item.category,
                              value: item.category,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "是否创新中心" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.FormData.isCxzx,
                            callback: function ($$v) {
                              _vm.$set(_vm.FormData, "isCxzx", $$v)
                            },
                            expression: "FormData.isCxzx",
                          },
                        },
                        [
                          _c("el-option", { attrs: { label: "否", value: 0 } }),
                          _c("el-option", { attrs: { label: "是", value: 1 } }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "办公十大爆品品类" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.FormData.ifTop10bp,
                            callback: function ($$v) {
                              _vm.$set(_vm.FormData, "ifTop10bp", $$v)
                            },
                            expression: "FormData.ifTop10bp",
                          },
                        },
                        [
                          _c("el-option", { attrs: { label: "否", value: 0 } }),
                          _c("el-option", { attrs: { label: "是", value: 1 } }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "等级" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            filterable: "",
                            multiple: "",
                            "collapse-tags": "",
                            placeholder: "请选择",
                          },
                          model: {
                            value: _vm.FormData.grades,
                            callback: function ($$v) {
                              _vm.$set(_vm.FormData, "grades", $$v)
                            },
                            expression: "FormData.grades",
                          },
                        },
                        _vm._l(_vm.grade, function (item) {
                          return _c("el-option", {
                            key: item.code,
                            attrs: { label: item.name, value: item.code },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "型号" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入型号" },
                        model: {
                          value: _vm.FormData.skuModel,
                          callback: function ($$v) {
                            _vm.$set(_vm.FormData, "skuModel", $$v)
                          },
                          expression: "FormData.skuModel",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "模具" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入模具" },
                        model: {
                          value: _vm.FormData.moCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.FormData, "moCode", $$v)
                          },
                          expression: "FormData.moCode",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品编码" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入商品编码" },
                        model: {
                          value: _vm.FormData.skuCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.FormData, "skuCode", $$v)
                          },
                          expression: "FormData.skuCode",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "品名" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入品名" },
                        model: {
                          value: _vm.FormData.skuName,
                          callback: function ($$v) {
                            _vm.$set(_vm.FormData, "skuName", $$v)
                          },
                          expression: "FormData.skuName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "是否显示近3月增长率",
                        "lable-width": "150px",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.growthRateShow,
                            callback: function ($$v) {
                              _vm.growthRateShow = $$v
                            },
                            expression: "growthRateShow",
                          },
                        },
                        [
                          _c(
                            "el-option",
                            { attrs: { label: "否", value: 1 } },
                            [_vm._v(" 否 ")]
                          ),
                          _c(
                            "el-option",
                            { attrs: { label: "是", value: 0 } },
                            [_vm._v(" 是")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "是否显示近3月销售",
                        "lable-width": "150px",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.salesShow,
                            callback: function ($$v) {
                              _vm.salesShow = $$v
                            },
                            expression: "salesShow",
                          },
                        },
                        [
                          _c(
                            "el-option",
                            { attrs: { label: "否", value: 1 } },
                            [_vm._v(" 否 ")]
                          ),
                          _c(
                            "el-option",
                            { attrs: { label: "是", value: 0 } },
                            [_vm._v(" 是")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-card",
        { staticClass: "box-card", staticStyle: { margin: "10px" } },
        [
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                size: "small",
                loading: _vm.exportLoading,
              },
              on: { click: _vm.download },
            },
            [_vm._v("导出")]
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              key: _vm.getRandom(20),
              staticStyle: { width: "100%", "margin-top": "10px" },
              attrs: {
                data: _vm.tableData,
                border: "",
                size: "small",
                "header-cell-style": {
                  "text-align": "center",
                  background: "#eef1f6",
                },
                "cell-style": { "text-align": "center" },
              },
            },
            [
              _c("el-table-column", {
                attrs: { fixed: "", prop: "skuCode", label: "商品编码" },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "skuName",
                  label: "品名",
                  width: "150",
                  fixed: "",
                  align: "left",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "skuModel",
                  label: "型号",
                  width: "100",
                  fixed: "",
                  align: "left",
                },
              }),
              _c("el-table-column", {
                attrs: { prop: "moCode", label: "模具" },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "newRaceName",
                  label: "赛道",
                  width: "100",
                  align: "left",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "newMainCategoryName",
                  label: "大类",
                  align: "left",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "newMidCategoryName",
                  label: "中类",
                  width: "120",
                  align: "left",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "newSubCategoryName",
                  label: "小类",
                  width: "120",
                  align: "left",
                },
              }),
              _c("el-table-column", {
                attrs: { prop: "grade", label: "等级" },
              }),
              _c("el-table-column", {
                attrs: { prop: "listingDay", label: "上市日期", width: "100" },
              }),
              _c("el-table-column", {
                attrs: { prop: "ifCxzx", label: "是否创新中心", width: "100" },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "ifTop10bp",
                  label: "办公十个爆品品类",
                  width: "120",
                },
              }),
              _c("el-table-column", {
                attrs: { prop: "bnJhTotal", label: "本年", sortable: "" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " + _vm._s(Math.round(scope.row.bnJhTotal)) + " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "zl", label: "同比增量", sortable: "" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          { style: { color: scope.row.zl < 0 ? "red" : "" } },
                          [_vm._v(" " + _vm._s(Math.round(scope.row.zl)) + " ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "zzl", label: "增长率", sortable: "" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          {
                            style: {
                              color: _vm.formatPercentage(scope.row, "zzl")
                                .color,
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.formatPercentage(scope.row, "zzl")
                                    .percentage
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm.growthRateShow == 0
                ? _c("el-table-column", {
                    attrs: { prop: "ssyZzl", label: "上上月", sortable: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "div",
                                {
                                  style: {
                                    color: _vm.formatPercentage(
                                      scope.row,
                                      "ssyZzl"
                                    ).color,
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.formatPercentage(
                                          scope.row,
                                          "ssyZzl"
                                        ).percentage
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3562634035
                    ),
                  })
                : _vm._e(),
              _vm.growthRateShow == 0
                ? _c("el-table-column", {
                    attrs: { prop: "syZzl", label: "上月", sortable: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "div",
                                {
                                  style: {
                                    color: _vm.formatPercentage(
                                      scope.row,
                                      "syZzl"
                                    ).color,
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.formatPercentage(scope.row, "syZzl")
                                          .percentage
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1849254835
                    ),
                  })
                : _vm._e(),
              _vm.growthRateShow == 0
                ? _c("el-table-column", {
                    attrs: { prop: "byZzl", label: "本月", sortable: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "div",
                                {
                                  style: {
                                    color: _vm.formatPercentage(
                                      scope.row,
                                      "byZzl"
                                    ).color,
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.formatPercentage(scope.row, "byZzl")
                                          .percentage
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1258549043
                    ),
                  })
                : _vm._e(),
              _c("el-table-column", {
                attrs: { label: "近3月趋势", width: "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("TrendCharts", {
                          attrs: {
                            first: scope.row.ssyZzl,
                            second: scope.row.syZzl,
                            third: scope.row.byZzl,
                            easyKey: _vm.getRandom(20),
                            month: _vm.years,
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm.salesShow == 0
                ? _c("el-table-column", {
                    attrs: {
                      prop: "ssyJhTotal",
                      label: `${_vm.previousMonths[0].year}年${_vm.previousMonths[0].month}月`,
                      sortable: "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "div",
                                {
                                  style: {
                                    color:
                                      scope.row.ssyJhTotal < 0 ? "red" : "",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(scope.row.ssyJhTotal.toFixed(1)) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      4140436351
                    ),
                  })
                : _vm._e(),
              _vm.salesShow == 0
                ? _c("el-table-column", {
                    attrs: {
                      prop: "syJhTotal",
                      label: `${_vm.previousMonths[1].year}年${_vm.previousMonths[1].month}月`,
                      sortable: "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "div",
                                {
                                  style: {
                                    color: scope.row.syJhTotal < 0 ? "red" : "",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(scope.row.syJhTotal.toFixed(1)) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2137788255
                    ),
                  })
                : _vm._e(),
              _vm.salesShow == 0
                ? _c("el-table-column", {
                    attrs: {
                      prop: "byJhTotal",
                      label: `${_vm.previousMonths[2].year}年${_vm.previousMonths[2].month}月`,
                      sortable: "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "div",
                                {
                                  style: {
                                    color: scope.row.byJhTotal < 0 ? "red" : "",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(scope.row.byJhTotal.toFixed(1)) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      632032095
                    ),
                  })
                : _vm._e(),
              _vm.salesShow == 0
                ? _c("el-table-column", {
                    attrs: {
                      prop: "ssyJhTotalTq",
                      label: `${_vm.lastYearMonths[0].year}年${_vm.lastYearMonths[0].month}月`,
                      sortable: "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "div",
                                {
                                  style: {
                                    color:
                                      scope.row.ssyJhTotalTq < 0 ? "red" : "",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        scope.row.ssyJhTotalTq.toFixed(1)
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2660012447
                    ),
                  })
                : _vm._e(),
              _vm.salesShow == 0
                ? _c("el-table-column", {
                    attrs: {
                      prop: "syJhTotalTq",
                      label: `${_vm.lastYearMonths[1].year}年${_vm.lastYearMonths[1].month}月`,
                      sortable: "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "div",
                                {
                                  style: {
                                    color:
                                      scope.row.syJhTotalTq < 0 ? "red" : "",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(scope.row.syJhTotalTq.toFixed(1)) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      320079903
                    ),
                  })
                : _vm._e(),
              _vm.salesShow == 0
                ? _c("el-table-column", {
                    attrs: {
                      prop: "byJhTotalTq",
                      label: `${_vm.lastYearMonths[2].year}年${_vm.lastYearMonths[2].month}月`,
                      sortable: "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "div",
                                {
                                  style: {
                                    color:
                                      scope.row.byJhTotalTq < 0 ? "red" : "",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(scope.row.byJhTotalTq.toFixed(1)) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1079410527
                    ),
                  })
                : _vm._e(),
              _c("el-table-column", {
                attrs: {
                  prop: "bnOfflineJhTotal",
                  label: "线下本年",
                  sortable: "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(Math.round(scope.row.bnOfflineJhTotal)) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "bnOfflineJhTotalZb",
                  label: "线下销售占比",
                  sortable: "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          {
                            style: {
                              color: _vm.formatPercentage(
                                scope.row,
                                "bnOfflineJhTotalZb"
                              ).color,
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.formatPercentage(
                                    scope.row,
                                    "bnOfflineJhTotalZb"
                                  ).percentage
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "offlineZl",
                  label: "线下同比增量",
                  sortable: "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          {
                            style: {
                              color: scope.row.offlineZl < 0 ? "red" : "",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(Math.round(scope.row.offlineZl)) +
                                " "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "offlineZlAb",
                  label: "线下增量占比",
                  sortable: "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          {
                            style: {
                              color: _vm.formatPercentage(
                                scope.row,
                                "offlineZlAb"
                              ).color,
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.formatPercentage(scope.row, "offlineZlAb")
                                    .percentage
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "offlineZzl",
                  label: "线下增长率",
                  sortable: "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          {
                            style: {
                              color: _vm.formatPercentage(
                                scope.row,
                                "offlineZzl"
                              ).color,
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.formatPercentage(scope.row, "offlineZzl")
                                    .percentage
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm.growthRateShow == 0
                ? _c("el-table-column", {
                    attrs: {
                      prop: "offlineSsyZzl",
                      label: "线下上上月",
                      sortable: "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "div",
                                {
                                  style: {
                                    color: _vm.formatPercentage(
                                      scope.row,
                                      "offlineSsyZzl"
                                    ).color,
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.formatPercentage(
                                          scope.row,
                                          "offlineSsyZzl"
                                        ).percentage
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      566729075
                    ),
                  })
                : _vm._e(),
              _vm.growthRateShow == 0
                ? _c("el-table-column", {
                    attrs: {
                      prop: "offlineSyZzl",
                      label: "线下上月",
                      sortable: "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "div",
                                {
                                  style: {
                                    color: _vm.formatPercentage(
                                      scope.row,
                                      "offlineSyZzl"
                                    ).color,
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.formatPercentage(
                                          scope.row,
                                          "offlineSyZzl"
                                        ).percentage
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      4020491059
                    ),
                  })
                : _vm._e(),
              _vm.growthRateShow == 0
                ? _c("el-table-column", {
                    attrs: {
                      prop: "offlineByZzl",
                      label: "线下本月",
                      sortable: "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "div",
                                {
                                  style: {
                                    color: _vm.formatPercentage(
                                      scope.row,
                                      "offlineByZzl"
                                    ).color,
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.formatPercentage(
                                          scope.row,
                                          "offlineByZzl"
                                        ).percentage
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3790049939
                    ),
                  })
                : _vm._e(),
              _c("el-table-column", {
                attrs: { label: "线下近3月趋势", width: "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("TrendCharts", {
                          attrs: {
                            first: scope.row.offlineSsyZzl,
                            second: scope.row.offlineSyZzl,
                            third: scope.row.offlineByZzl,
                            easyKey: _vm.getRandom(20),
                            month: _vm.years,
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm.salesShow == 0
                ? _c("el-table-column", {
                    attrs: {
                      prop: "ssyOfflineJhTotal",
                      label: `线下${_vm.previousMonths[0].year}年${_vm.previousMonths[0].month}月`,
                      sortable: "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "div",
                                {
                                  style: {
                                    color:
                                      scope.row.ssyOfflineJhTotal < 0
                                        ? "red"
                                        : "",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        scope.row.ssyOfflineJhTotal.toFixed(1)
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      862246047
                    ),
                  })
                : _vm._e(),
              _vm.salesShow == 0
                ? _c("el-table-column", {
                    attrs: {
                      prop: "syOfflineJhTotal",
                      label: `线下${_vm.previousMonths[1].year}年${_vm.previousMonths[1].month}月`,
                      sortable: "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "div",
                                {
                                  style: {
                                    color:
                                      scope.row.syOfflineJhTotal < 0
                                        ? "red"
                                        : "",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        scope.row.syOfflineJhTotal.toFixed(1)
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3663116543
                    ),
                  })
                : _vm._e(),
              _vm.salesShow == 0
                ? _c("el-table-column", {
                    attrs: {
                      prop: "byOfflineJhTotal",
                      label: `线下${_vm.previousMonths[2].year}年${_vm.previousMonths[2].month}月`,
                      sortable: "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "div",
                                {
                                  style: {
                                    color:
                                      scope.row.byOfflineJhTotal < 0
                                        ? "red"
                                        : "",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        scope.row.byOfflineJhTotal.toFixed(1)
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3578662879
                    ),
                  })
                : _vm._e(),
              _vm.salesShow == 0
                ? _c("el-table-column", {
                    attrs: {
                      prop: "ssyOfflineJhTotalTq",
                      label: `线下${_vm.lastYearMonths[0].year}年${_vm.lastYearMonths[0].month}月`,
                      sortable: "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "div",
                                {
                                  style: {
                                    color:
                                      scope.row.ssyOfflineJhTotalTq < 0
                                        ? "red"
                                        : "",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        scope.row.ssyOfflineJhTotalTq.toFixed(1)
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      680465311
                    ),
                  })
                : _vm._e(),
              _vm.salesShow == 0
                ? _c("el-table-column", {
                    attrs: {
                      prop: "syOfflineJhTotalTq",
                      label: `线下${_vm.lastYearMonths[1].year}年${_vm.lastYearMonths[1].month}月`,
                      sortable: "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "div",
                                {
                                  style: {
                                    color:
                                      scope.row.syOfflineJhTotalTq < 0
                                        ? "red"
                                        : "",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        scope.row.syOfflineJhTotalTq.toFixed(1)
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      563198751
                    ),
                  })
                : _vm._e(),
              _vm.salesShow == 0
                ? _c("el-table-column", {
                    attrs: {
                      prop: "byOfflineJhTotalTq",
                      label: `线下${_vm.lastYearMonths[2].year}年${_vm.lastYearMonths[2].month}月`,
                      sortable: "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "div",
                                {
                                  style: {
                                    color:
                                      scope.row.byOfflineJhTotalTq < 0
                                        ? "red"
                                        : "",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        scope.row.byOfflineJhTotalTq.toFixed(1)
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2082953407
                    ),
                  })
                : _vm._e(),
              _c("el-table-column", {
                attrs: {
                  prop: "bnOnlineJhTotal",
                  label: "线上本年",
                  sortable: "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(Math.round(scope.row.bnOnlineJhTotal)) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "bnOnlineJhTotalZb",
                  label: "线上销售占比",
                  sortable: "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          {
                            style: {
                              color: _vm.formatPercentage(
                                scope.row,
                                "bnOnlineJhTotalZb"
                              ).color,
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.formatPercentage(
                                    scope.row,
                                    "bnOnlineJhTotalZb"
                                  ).percentage
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "onlineZl",
                  label: "线上同比增量",
                  sortable: "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          {
                            style: {
                              color: scope.row.onlineZl < 0 ? "red" : "",
                            },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(Math.round(scope.row.onlineZl)) + " "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "onlineZlZb",
                  label: "线上增量占比",
                  sortable: "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          {
                            style: {
                              color: _vm.formatPercentage(
                                scope.row,
                                "onlineZlZb"
                              ).color,
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.formatPercentage(scope.row, "onlineZlZb")
                                    .percentage
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "onlineZzl", label: "线上增长率", sortable: "" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          {
                            style: {
                              color: _vm.formatPercentage(
                                scope.row,
                                "onlineZzl"
                              ).color,
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.formatPercentage(scope.row, "onlineZzl")
                                    .percentage
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm.growthRateShow == 0
                ? _c("el-table-column", {
                    attrs: {
                      prop: "onlineSsyZzl",
                      label: "线上上上月",
                      sortable: "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "div",
                                {
                                  style: {
                                    color: _vm.formatPercentage(
                                      scope.row,
                                      "onlineSsyZzl"
                                    ).color,
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.formatPercentage(
                                          scope.row,
                                          "onlineSsyZzl"
                                        ).percentage
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3994274067
                    ),
                  })
                : _vm._e(),
              _vm.growthRateShow == 0
                ? _c("el-table-column", {
                    attrs: {
                      prop: "onlineSyZzl",
                      label: "线上上月",
                      sortable: "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "div",
                                {
                                  style: {
                                    color: _vm.formatPercentage(
                                      scope.row,
                                      "onlineSyZzl"
                                    ).color,
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.formatPercentage(
                                          scope.row,
                                          "onlineSyZzl"
                                        ).percentage
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2801355315
                    ),
                  })
                : _vm._e(),
              _vm.growthRateShow == 0
                ? _c("el-table-column", {
                    attrs: {
                      prop: "onlineByZzl",
                      label: "线上本月",
                      sortable: "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "div",
                                {
                                  style: {
                                    color: _vm.formatPercentage(
                                      scope.row,
                                      "onlineByZzl"
                                    ).color,
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.formatPercentage(
                                          scope.row,
                                          "onlineByZzl"
                                        ).percentage
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      142308787
                    ),
                  })
                : _vm._e(),
              _c("el-table-column", {
                attrs: { label: "线上近3月趋势", width: "120" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("TrendCharts", {
                          attrs: {
                            first: scope.row.onlineSsyZzl,
                            second: scope.row.onlineSyZzl,
                            third: scope.row.onlineByZzl,
                            easyKey: _vm.getRandom(20),
                            month: _vm.years,
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm.salesShow == 0
                ? _c("el-table-column", {
                    attrs: {
                      prop: "ssyOnlineJhTotal",
                      label: `线上${_vm.previousMonths[0].year}年${_vm.previousMonths[0].month}月`,
                      sortable: "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "div",
                                {
                                  style: {
                                    color:
                                      scope.row.ssyOnlineJhTotal < 0
                                        ? "red"
                                        : "",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        scope.row.ssyOnlineJhTotal.toFixed(1)
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      819168863
                    ),
                  })
                : _vm._e(),
              _vm.salesShow == 0
                ? _c("el-table-column", {
                    attrs: {
                      prop: "syOnlineJhTotal",
                      label: `线上${_vm.previousMonths[1].year}年${_vm.previousMonths[1].month}月`,
                      sortable: "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "div",
                                {
                                  style: {
                                    color:
                                      scope.row.syOnlineJhTotal < 0
                                        ? "red"
                                        : "",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        scope.row.syOnlineJhTotal.toFixed(1)
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3358947423
                    ),
                  })
                : _vm._e(),
              _vm.salesShow == 0
                ? _c("el-table-column", {
                    attrs: {
                      prop: "byOnlineJhTotal",
                      label: `线上${_vm.previousMonths[2].year}年${_vm.previousMonths[2].month}月`,
                      sortable: "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "div",
                                {
                                  style: {
                                    color:
                                      scope.row.byOnlineJhTotal < 0
                                        ? "red"
                                        : "",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        scope.row.byOnlineJhTotal.toFixed(1)
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      389966047
                    ),
                  })
                : _vm._e(),
              _vm.salesShow == 0
                ? _c("el-table-column", {
                    attrs: {
                      prop: "ssyOnlineJhTotalTq",
                      label: `线上${_vm.lastYearMonths[0].year}年${_vm.lastYearMonths[0].month}月`,
                      sortable: "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "div",
                                {
                                  style: {
                                    color:
                                      scope.row.ssyOnlineJhTotalTq < 0
                                        ? "red"
                                        : "",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        scope.row.ssyOnlineJhTotalTq.toFixed(1)
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1707682047
                    ),
                  })
                : _vm._e(),
              _vm.salesShow == 0
                ? _c("el-table-column", {
                    attrs: {
                      prop: "syOnlineJhTotalTq",
                      label: `线上${_vm.lastYearMonths[1].year}年${_vm.lastYearMonths[1].month}月`,
                      sortable: "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "div",
                                {
                                  style: {
                                    color:
                                      scope.row.syOnlineJhTotalTq < 0
                                        ? "red"
                                        : "",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        scope.row.syOnlineJhTotalTq.toFixed(1)
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1467066975
                    ),
                  })
                : _vm._e(),
              _vm.salesShow == 0
                ? _c("el-table-column", {
                    attrs: {
                      prop: "byOnlineJhTotalTq",
                      label: `线上${_vm.lastYearMonths[2].year}年${_vm.lastYearMonths[2].month}月`,
                      sortable: "",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "div",
                                {
                                  style: {
                                    color:
                                      scope.row.byOnlineJhTotalTq < 0
                                        ? "red"
                                        : "",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        scope.row.byOnlineJhTotalTq.toFixed(1)
                                      ) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3404248351
                    ),
                  })
                : _vm._e(),
            ],
            1
          ),
          _c("Pagination", {
            attrs: {
              total: _vm.total,
              page: _vm.FormData.page,
              limit: _vm.FormData.limit,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.FormData, "page", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.FormData, "limit", $event)
              },
              pagination: _vm.handleFilter,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }