var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "new-box" },
    [
      _c(
        "div",
        { staticClass: "mg-search" },
        [
          _c(
            "el-card",
            { staticStyle: { margin: "10px" } },
            [
              _c(
                "SearchFilter",
                {
                  on: { search: _vm.handleQuery, reset: _vm.resetQuery },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.handleQuery.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "关键词" } },
                    [
                      _c("el-input", {
                        attrs: { clearable: "", placeholder: "请输入关键词" },
                        model: {
                          value: _vm.queryForm.keyWord,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryForm, "keyWord", $$v)
                          },
                          expression: "queryForm.keyWord",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "账号或姓名" } },
                    [
                      _c("DictSelect", {
                        ref: "user",
                        attrs: {
                          type: "user",
                          value: _vm.queryForm.userName,
                          dictFun: _vm.dictUserFun,
                          fullLabel: "",
                          remote: "",
                          version: "v3",
                          "allow-create": "",
                          placeholder: "请输入账号或姓名",
                          "init-props": {
                            label: "nickName",
                            value: "userName",
                          },
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(_vm.queryForm, "userName", $event)
                          },
                          getOtherJsonParam: (callback) =>
                            callback({ pageSize: 50, pageNum: 1 }),
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "状态", prop: "status" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "" },
                          model: {
                            value: _vm.queryForm.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryForm, "status", $$v)
                            },
                            expression: "queryForm.status",
                          },
                        },
                        _vm._l(_vm.options.queryStatusOptions, function (e) {
                          return _c("el-option", {
                            key: e.value,
                            attrs: { label: e.label, value: e.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-card",
        { staticClass: "box-card", staticStyle: { margin: "10px" } },
        [
          _vm.authButton
            ? _c(
                "el-button",
                {
                  staticClass: "form-btn-item",
                  attrs: {
                    size: "mini",
                    icon: "el-icon-plus",
                    type: "primary",
                  },
                  on: { click: _vm.handleEdit },
                },
                [_vm._v("创建红包")]
              )
            : _vm._e(),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "table",
              staticClass: "customer-table",
              staticStyle: { "margin-top": "10px" },
              attrs: {
                data: _vm.pageData.rows,
                border: "",
                size: "small",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "ID", prop: "id", width: "60" },
              }),
              _c("el-table-column", {
                attrs: { label: "红包名称", prop: "name", width: "200" },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "已领取金额",
                  prop: "amountDetail",
                  width: "140",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(row.amountDetail.split("/")[0])),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "总金额", prop: "amountDetail" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(row.amountDetail.split("/")[1])),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "已领取个数",
                  prop: "countDetail",
                  width: "100",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(row.countDetail.split("/")[0])),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "未领取个数",
                  prop: "noAcceptCount",
                  width: "100",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "领取失败个数",
                  prop: "acceptFailedCount",
                  width: "120",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "总个数", prop: "countDetail" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(row.countDetail.split("/")[1])),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "状态", prop: "statusText" },
              }),
              _c("el-table-column", {
                attrs: { label: "创建者", prop: "createBy", width: "180" },
              }),
              _c("el-table-column", {
                attrs: { label: "创建时间", prop: "createTime", width: "180" },
              }),
              _c("el-table-column", {
                attrs: { label: "编辑时间", prop: "updateTime", width: "180" },
              }),
              _c("el-table-column", {
                attrs: { label: "过期时间", prop: "endDate", width: "180" },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  width: "200",
                  align: "center",
                  fixed: "right",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "div",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleEditRow(row.id, "view")
                                  },
                                },
                              },
                              [_vm._v("查看")]
                            ),
                            _c(
                              "el-button",
                              {
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleEditRow(row.id, "edit")
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            ),
                            row.status !== 5 && row.status !== 3
                              ? _c(
                                  "el-button",
                                  {
                                    staticStyle: { border: "0px" },
                                    attrs: { size: "mini", type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.redPacketRefuse(row.id)
                                      },
                                    },
                                  },
                                  [_vm._v("作废")]
                                )
                              : _vm._e(),
                            _c(
                              "el-button",
                              {
                                staticStyle: { border: "0" },
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.toDetail(row)
                                  },
                                },
                              },
                              [_vm._v("明细")]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("Pagination", {
            attrs: {
              total: _vm.pageData.total,
              page: _vm.queryForm.page,
              limit: _vm.queryForm.limit,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.queryForm, "page", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.queryForm, "limit", $event)
              },
              pagination: _vm.handleQuery,
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.editDialog.titleMap[_vm.editDialog.type],
            visible: _vm.editDialog.visible,
            "append-to-body": "",
            width: "800px",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.editDialog, "visible", $event)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "common-form",
              attrs: {
                size: "mini",
                inline: "",
                rules: _vm.editDialog.rules,
                "label-width": "160px",
                model: _vm.editDialog.form,
              },
            },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    border: "solid 1px rgb(78 72 72 / 10%)",
                    padding: "10px",
                  },
                },
                [
                  _c(
                    "span",
                    { staticStyle: { color: "#1482f0", "font-size": "24px" } },
                    [_vm._v("基本信息")]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "红包名称", prop: "name" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "400px" },
                        attrs: {
                          clearable: "",
                          placeholder: "建议13字以内",
                          disabled:
                            _vm.editDialog.disabled &&
                            _vm.editDialog.type !== "edit",
                        },
                        model: {
                          value: _vm.editDialog.form.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.editDialog.form, "name", $$v)
                          },
                          expression: "editDialog.form.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "红包备注", prop: "remark" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "400px" },
                        attrs: {
                          clearable: "",
                          placeholder: "建议13字以内",
                          disabled:
                            _vm.editDialog.disabled &&
                            _vm.editDialog.type !== "edit",
                        },
                        model: {
                          value: _vm.editDialog.form.remark,
                          callback: function ($$v) {
                            _vm.$set(_vm.editDialog.form, "remark", $$v)
                          },
                          expression: "editDialog.form.remark",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "发放主体", prop: "mchidType" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "400px" },
                          attrs: { disabled: _vm.editDialog.disabled },
                          model: {
                            value: _vm.editDialog.form.mchidType,
                            callback: function ($$v) {
                              _vm.$set(_vm.editDialog.form, "mchidType", $$v)
                            },
                            expression: "editDialog.form.mchidType",
                          },
                        },
                        _vm._l(_vm.options.mchidTypeOptions, function (e) {
                          return _c("el-option", {
                            key: e.value,
                            attrs: { label: e.label, value: e.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "红包标题", prop: "title" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "400px" },
                        attrs: {
                          clearable: "",
                          placeholder: "建议13字以内",
                          maxlength: "25",
                          "show-word-limit": "",
                          disabled:
                            _vm.editDialog.disabled &&
                            _vm.editDialog.type !== "edit",
                        },
                        model: {
                          value: _vm.editDialog.form.title,
                          callback: function ($$v) {
                            _vm.$set(_vm.editDialog.form, "title", $$v)
                          },
                          expression: "editDialog.form.title",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "领取有效期", prop: "acceptTime" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          disabled:
                            _vm.editDialog.disabled &&
                            _vm.editDialog.type !== "edit",
                          type: "datetimerange",
                          "range-separator": "至",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          "start-placeholder": "开始日期",
                          "end-placeholder": "结束日期",
                        },
                        model: {
                          value: _vm.editDialog.form.acceptTime,
                          callback: function ($$v) {
                            _vm.$set(_vm.editDialog.form, "acceptTime", $$v)
                          },
                          expression: "editDialog.form.acceptTime",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.editDialog.type !== "edit"
                    ? _c(
                        "div",
                        { staticStyle: { width: "100%" } },
                        [
                          !_vm.editDialog.disabled
                            ? _c(
                                "el-form-item",
                                { attrs: { label: "发放类型" } },
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      on: { change: _vm.handleSendTypeChange },
                                      model: {
                                        value: _vm.editDialog.form.sendType,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.editDialog.form,
                                            "sendType",
                                            $$v
                                          )
                                        },
                                        expression: "editDialog.form.sendType",
                                      },
                                    },
                                    _vm._l(
                                      _vm.options.sendTypeOptions,
                                      function (item) {
                                        return _c(
                                          "el-radio",
                                          {
                                            key: item.value,
                                            attrs: { label: item.value },
                                          },
                                          [_vm._v(_vm._s(item.label))]
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "background-color": "#e5e9ec",
                                "padding-top": "5px",
                                "padding-left": "5px",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.editDialog.form.sendType === 1,
                                      expression:
                                        "editDialog.form.sendType===1",
                                    },
                                  ],
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "请选择分公司(单选)" } },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "300px" },
                                          attrs: { filterable: "" },
                                          on: { change: _vm.handleOrgChange },
                                          model: {
                                            value: _vm.editDialog.selectedOrg,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.editDialog,
                                                "selectedOrg",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "editDialog.selectedOrg",
                                          },
                                        },
                                        _vm._l(
                                          _vm.editDialog.orgLists,
                                          function (e) {
                                            return _c("el-option", {
                                              key: e.orgCode,
                                              attrs: {
                                                label: e.orgName,
                                                value: e.orgCode,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "请选择任务(单选)" } },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "300px" },
                                          attrs: { filterable: "" },
                                          on: {
                                            change: _vm.handleLoadBoostBouns,
                                          },
                                          model: {
                                            value: _vm.editDialog.selectedTask,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.editDialog,
                                                "selectedTask",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "editDialog.selectedTask",
                                          },
                                        },
                                        _vm._l(
                                          _vm.editDialog.taskLists,
                                          function (e) {
                                            return _c("el-option", {
                                              key: e.mtTaskId,
                                              attrs: {
                                                label: e.taskName,
                                                value: e.mtTaskId,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "业务员所属分公司" } },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: {
                                            width: "300px",
                                            "margin-right": "10px",
                                          },
                                          attrs: {
                                            "collapse-tags": "",
                                            filterable: "",
                                            multiple: "",
                                          },
                                          on: {
                                            change: _vm.handleLoadBoostBouns,
                                          },
                                          model: {
                                            value:
                                              _vm.editDialog.selectedUserOrg,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.editDialog,
                                                "selectedUserOrg",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "editDialog.selectedUserOrg",
                                          },
                                        },
                                        _vm._l(
                                          _vm.editDialog.orgLists,
                                          function (e) {
                                            return _c("el-option", {
                                              key: e.orgCode,
                                              attrs: {
                                                label: e.orgName,
                                                value: e.orgCode,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                      _c(
                                        "el-button",
                                        {
                                          staticClass: "form-btn-item",
                                          attrs: {
                                            size: "mini",
                                            type: "primary",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleCheckAll(true)
                                            },
                                          },
                                        },
                                        [_vm._v("全选")]
                                      ),
                                      _c(
                                        "el-button",
                                        {
                                          staticClass: "form-btn-item",
                                          attrs: {
                                            size: "mini",
                                            type: "primary",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleCheckAll(false)
                                            },
                                          },
                                        },
                                        [_vm._v("取消全选")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "关键字搜索" } },
                                    [
                                      _c("el-input", {
                                        staticStyle: { width: "300px" },
                                        attrs: {
                                          clearable: "",
                                          placeholder:
                                            "请输入关键字/姓名/账号，多个以空格分割",
                                        },
                                        model: {
                                          value: _vm.editDialog.keyWord,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.editDialog,
                                              "keyWord",
                                              $$v
                                            )
                                          },
                                          expression: "editDialog.keyWord",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("span", [
                                    _vm._v(
                                      "预计发放总金额：" +
                                        _vm._s(
                                          _vm.editDialog.form.predictAmount1
                                        )
                                    ),
                                  ]),
                                  _c(
                                    "el-table",
                                    {
                                      directives: [
                                        {
                                          name: "loading",
                                          rawName: "v-loading",
                                          value: _vm.editDialog.loading,
                                          expression: "editDialog.loading",
                                        },
                                      ],
                                      ref: "singleTable",
                                      staticStyle: {
                                        width: "100%",
                                        "margin-top": "10px",
                                      },
                                      attrs: {
                                        data: _vm.filterTableData,
                                        height: "200px",
                                      },
                                    },
                                    [
                                      _c("el-table-column", {
                                        attrs: {
                                          property: "username",
                                          label: "用户账号",
                                          align: "center",
                                        },
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          property: "name",
                                          label: "用户姓名",
                                          align: "center",
                                        },
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          property: "amount",
                                          label: "红包金额(元)",
                                          align: "center",
                                        },
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          label: "操作",
                                          width: "160",
                                          align: "center",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "el-button",
                                                        {
                                                          staticStyle: {
                                                            border: "0",
                                                          },
                                                          attrs: {
                                                            size: "mini",
                                                            type: "text",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.deleteAcceptUser(
                                                                scope.row.id
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("删除")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          3910130310
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.editDialog.form.sendType === 2,
                                      expression:
                                        "editDialog.form.sendType===2",
                                    },
                                  ],
                                  staticStyle: { padding: "10px" },
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      "预计发放总金额：" +
                                        _vm._s(
                                          _vm.editDialog.form.predictAmount2
                                        )
                                    ),
                                  ]),
                                  _c(
                                    "el-table",
                                    {
                                      ref: "singleTable",
                                      staticStyle: {
                                        width: "100%",
                                        "margin-top": "10px",
                                      },
                                      attrs: {
                                        data: _vm.editDialog.form.sendObjects2,
                                        height: "200px",
                                      },
                                    },
                                    [
                                      _c("el-table-column", {
                                        attrs: {
                                          property: "username",
                                          label: "用户账号",
                                          align: "center",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c("DictSelect", {
                                                    ref: "user" + scope.$index,
                                                    attrs: {
                                                      type: "user",
                                                      value: scope.row.username,
                                                      dictFun: _vm.dictUserFun,
                                                      fullLabel: "",
                                                      remote: "",
                                                      version: "v3",
                                                      "allow-create": "",
                                                      placeholder:
                                                        "请输入账号或姓名",
                                                      "init-props": {
                                                        label: "nickName",
                                                        value: "userName",
                                                      },
                                                      size: "mini",
                                                    },
                                                    on: {
                                                      "update:value": function (
                                                        $event
                                                      ) {
                                                        return _vm.$set(
                                                          scope.row,
                                                          "username",
                                                          $event
                                                        )
                                                      },
                                                      getOtherJsonParam: (
                                                        callback
                                                      ) =>
                                                        callback({
                                                          pageSize: 50,
                                                          pageNum: 1,
                                                        }),
                                                    },
                                                  }),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          1826108366
                                        ),
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          property: "amount",
                                          label: "红包金额(元)",
                                          align: "center",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c("el-input-number", {
                                                    attrs: {
                                                      autocomplete: "off",
                                                      size: "small",
                                                      max: 500,
                                                      controls: false,
                                                      placeholder:
                                                        "请输入红包金额",
                                                    },
                                                    on: {
                                                      change:
                                                        _vm.calculatePredictAmount,
                                                    },
                                                    model: {
                                                      value: scope.row.amount,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          scope.row,
                                                          "amount",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "scope.row.amount",
                                                    },
                                                  }),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          2097722987
                                        ),
                                      }),
                                      _c("el-table-column", {
                                        attrs: {
                                          label: "操作",
                                          align: "center",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (scope) {
                                                return [
                                                  _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "el-button",
                                                        {
                                                          staticStyle: {
                                                            border: "0",
                                                          },
                                                          attrs: {
                                                            size: "mini",
                                                            type: "text",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.deleteAcceptUser(
                                                                scope.row.id
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("删除")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          3910130310
                                        ),
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticStyle: { "margin-top": "10px" } },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "primary",
                                            size: "mini",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.addAcceptUser()
                                            },
                                          },
                                        },
                                        [_vm._v("添加用户")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.editDialog.form.sendType === 3,
                                      expression:
                                        "editDialog.form.sendType===3",
                                    },
                                  ],
                                  staticStyle: { padding: "10px" },
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "primary", size: "mini" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.importAcceptUser()
                                        },
                                      },
                                    },
                                    [_vm._v("导入")]
                                  ),
                                  _c(
                                    "span",
                                    { staticStyle: { "margin-left": "10px" } },
                                    [
                                      _vm._v(
                                        "预计发放总金额：" +
                                          _vm._s(
                                            _vm.editDialog.form.predictAmount3
                                          )
                                      ),
                                    ]
                                  ),
                                  _vm.editDialog.form.sendObjects3 &&
                                  _vm.editDialog.form.sendObjects3.length > 0
                                    ? _c(
                                        "el-table",
                                        {
                                          ref: "singleTable2",
                                          staticClass: "previewTable-lists",
                                          attrs: {
                                            data: _vm.editDialog.form
                                              .sendObjects3,
                                            stripe: "",
                                            border: "",
                                            height: "200px",
                                            size: "mini",
                                          },
                                        },
                                        [
                                          _c("el-table-column", {
                                            attrs: {
                                              property: "username",
                                              label: "用户账号",
                                              align: "center",
                                            },
                                          }),
                                          _c("el-table-column", {
                                            attrs: {
                                              property: "nickname",
                                              label: "用户姓名",
                                              align: "center",
                                            },
                                          }),
                                          _c("el-table-column", {
                                            attrs: {
                                              property: "amount",
                                              label: "红包金额(元)",
                                              align: "center",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          ),
                          _vm.editDialog.disabled
                            ? _c(
                                "el-form-item",
                                { attrs: { label: "发放对象" } },
                                [
                                  _c(
                                    "div",
                                    { staticStyle: { width: "82%" } },
                                    [
                                      !_vm.editDialog.disabled
                                        ? _c(
                                            "el-button",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.importAcceptUser()
                                                },
                                              },
                                            },
                                            [_vm._v("导入")]
                                          )
                                        : _vm._e(),
                                      _vm.editDialog.selectedOrg &&
                                      _vm.editDialog.selectedTask
                                        ? _c(
                                            "div",
                                            [
                                              _c("span", [_vm._v("分公司")]),
                                              _c(
                                                "el-select",
                                                {
                                                  attrs: {
                                                    filterable: "",
                                                    disabled:
                                                      _vm.editDialog.disabled,
                                                  },
                                                  on: {
                                                    change: _vm.handleOrgChange,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.editDialog
                                                        .selectedOrg,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.editDialog,
                                                        "selectedOrg",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "editDialog.selectedOrg",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.editDialog.orgLists,
                                                  function (e) {
                                                    return _c("el-option", {
                                                      key: e.orgCode,
                                                      attrs: {
                                                        label: e.orgName,
                                                        value: e.orgCode,
                                                      },
                                                    })
                                                  }
                                                ),
                                                1
                                              ),
                                              _c("span", [_vm._v("任务")]),
                                              _c(
                                                "el-select",
                                                {
                                                  attrs: {
                                                    filterable: "",
                                                    disabled:
                                                      _vm.editDialog.disabled,
                                                  },
                                                  on: {
                                                    change:
                                                      _vm.handleLoadBoostBouns,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.editDialog
                                                        .selectedTask,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.editDialog,
                                                        "selectedTask",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "editDialog.selectedTask",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.editDialog.taskLists,
                                                  function (e) {
                                                    return _c("el-option", {
                                                      key: e.mtTaskId,
                                                      attrs: {
                                                        label: e.taskName,
                                                        value: e.mtTaskId,
                                                      },
                                                    })
                                                  }
                                                ),
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c("span", [
                                        _vm._v(
                                          " 预计发放总金额：" +
                                            _vm._s(
                                              _vm.editDialog.form.predictAmount
                                            )
                                        ),
                                      ]),
                                      _vm.editDialog.form.sendObjects &&
                                      _vm.editDialog.form.sendObjects.length > 0
                                        ? _c(
                                            "el-table",
                                            {
                                              ref: "singleTable3",
                                              staticClass: "previewTable-lists",
                                              attrs: {
                                                data: _vm.editDialog.form
                                                  .sendObjects,
                                                stripe: "",
                                                border: "",
                                                height: "200px",
                                                size: "mini",
                                              },
                                            },
                                            [
                                              _c("el-table-column", {
                                                attrs: {
                                                  property: "username",
                                                  label: "用户账号",
                                                  align: "center",
                                                },
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  property: "amount",
                                                  label: "红包金额(元)",
                                                  align: "center",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
          _vm.editDialog.type !== "view"
            ? _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  staticStyle: { "text-align": "right" },
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "mini" },
                      on: {
                        click: function ($event) {
                          _vm.editDialog.visible = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        disabled: _vm.editDialog.form.test,
                        type: "primary",
                        size: "mini",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm(undefined)
                        },
                      },
                    },
                    [_vm._v("确定")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "红包明细",
            visible: _vm.detailDialog.visible,
            "append-to-body": "",
            width: "80%",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.detailDialog, "visible", $event)
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "mg-search",
              staticStyle: { "padding-bottom": "15px" },
            },
            [
              _c(
                "el-form",
                {
                  ref: "queryFormRef",
                  attrs: {
                    size: "mini",
                    "label-width": "auto",
                    inline: true,
                    model: _vm.queryForm,
                  },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.handleDetailQuery.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "领取状态", prop: "status" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "", multiple: "" },
                          model: {
                            value: _vm.detailDialog.queryForm.status,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.detailDialog.queryForm,
                                "status",
                                $$v
                              )
                            },
                            expression: "detailDialog.queryForm.status",
                          },
                        },
                        _vm._l(
                          _vm.options.queryDetailStatusOptions,
                          function (e) {
                            return _c("el-option", {
                              key: e.value,
                              attrs: { label: e.label, value: e.value },
                            })
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "用户", prop: "usernames" } },
                    [
                      _c("DictSelect", {
                        ref: "user",
                        attrs: {
                          type: "user",
                          value: _vm.detailDialog.queryForm.usernames,
                          dictFun: _vm.dictUserFun,
                          fullLabel: "",
                          multiple: "",
                          remote: "",
                          "allow-create": "",
                          version: "v3",
                          placeholder: "请输入账号或姓名",
                          "init-props": {
                            label: "nickName",
                            value: "userName",
                          },
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(
                              _vm.detailDialog.queryForm,
                              "usernames",
                              $event
                            )
                          },
                          getOtherJsonParam: (callback) =>
                            callback({ pageSize: 50, pageNum: 1 }),
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "账号状态" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "" },
                          model: {
                            value: _vm.detailDialog.queryForm.userStatus,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.detailDialog.queryForm,
                                "userStatus",
                                $$v
                              )
                            },
                            expression: "detailDialog.queryForm.userStatus",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "正常", value: "1" },
                          }),
                          _c("el-option", {
                            attrs: { label: "关闭", value: "2" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "所属分公司" } },
                    [
                      _c("DictSelect", {
                        ref: "org",
                        attrs: {
                          type: "org",
                          value: _vm.detailDialog.queryForm.orgCodes,
                          multiple: "",
                          dictFun: _vm.dictSelect,
                          fullLabel: "",
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(
                              _vm.detailDialog.queryForm,
                              "orgCodes",
                              $event
                            )
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "微信OpenId" } },
                    [
                      _c("el-input", {
                        attrs: {
                          size: "mini",
                          clearable: "",
                          placeholder: "请输入",
                        },
                        model: {
                          value: _vm.detailDialog.queryForm.acceptOpenid,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.detailDialog.queryForm,
                              "acceptOpenid",
                              $$v
                            )
                          },
                          expression: "detailDialog.queryForm.acceptOpenid",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "微信付款单号" } },
                    [
                      _c("el-input", {
                        attrs: {
                          size: "mini",
                          clearable: "",
                          placeholder: "请输入",
                        },
                        model: {
                          value: _vm.detailDialog.queryForm.paymentNo,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.detailDialog.queryForm,
                              "paymentNo",
                              $$v
                            )
                          },
                          expression: "detailDialog.queryForm.paymentNo",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "form-btn-item",
                          attrs: {
                            size: "mini",
                            icon: "el-icon-search",
                            type: "primary",
                          },
                          on: { click: _vm.handleDetailQuery },
                        },
                        [_vm._v("搜索")]
                      ),
                      _c(
                        "el-button",
                        {
                          staticClass: "form-btn-item",
                          attrs: {
                            size: "mini",
                            icon: "el-icon-download",
                            type: "primary",
                          },
                          on: { click: _vm.handleExportDetail },
                        },
                        [_vm._v("导出")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.detailDialog.loading,
                  expression: "detailDialog.loading",
                },
              ],
              ref: "detailTable",
              attrs: {
                data: _vm.detailDialog.detailPageData.rows,
                height: "calc(100vh - 350px)",
                size: "mini",
                border: "",
                "highlight-current-row": "",
                "header-cell-style": {
                  background: "rgb(235 235 235)",
                  color: "black",
                  textAlign: "center",
                  verticalAlign: "middle",
                },
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "用户账号", prop: "username", width: "100" },
              }),
              _c("el-table-column", {
                attrs: { label: "用户姓名", prop: "nickname", width: "130" },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "账号状态",
                  prop: "userStatusText",
                  width: "80",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "用户手机号", prop: "phoneNum", width: "130" },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "用户所属分公司",
                  prop: "orgName",
                  width: "130",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "红包金额", prop: "amount", width: "130" },
              }),
              _c("el-table-column", {
                attrs: { label: "领取状态", prop: "statusText", width: "130" },
              }),
              _c("el-table-column", {
                attrs: { label: "领取时间", prop: "acceptTime", width: "160" },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "微信OpenId",
                  prop: "acceptOpenid",
                  width: "160",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "聚宝盆单号",
                  prop: "partnerTradeNo",
                  width: "160",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "微信付款单号",
                  prop: "paymentNo",
                  width: "160",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "操作", width: "260", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "div",
                          [
                            row.status !== 1 && row.status !== 2
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini", type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.redPacketDetailRefuse(
                                          row.id,
                                          1
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("作废")]
                                )
                              : _vm._e(),
                            row.status === 3
                              ? _c(
                                  "el-button",
                                  {
                                    staticStyle: { border: "0" },
                                    attrs: { size: "mini", type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.redPacketDetailRefuse(
                                          row.id,
                                          4
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("重试")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("Pagination", {
            attrs: {
              total: _vm.detailDialog.detailPageData.total,
              page: _vm.detailDialog.queryForm.page,
              limit: _vm.detailDialog.queryForm.limit,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.detailDialog.queryForm, "page", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.detailDialog.queryForm, "limit", $event)
              },
              pagination: _vm.handleDetailQuery,
            },
          }),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.detailDialog.visible = false
                      _vm.handleQuery()
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("ImportDialog", {
        attrs: {
          importBaseParams: _vm.importDialog.importBaseParams,
          "append-to-body": "",
        },
        on: { handleImportCallBack: _vm.handleImportData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }