var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { width: "100px", height: "36px", margin: "0 auto" } },
    [
      _vm.easyKey
        ? _c("div", {
            staticClass: "es",
            staticStyle: { width: "100%", height: "100%", margin: "0 auto" },
            attrs: { id: "main_" + _vm.easyKey },
          })
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }