var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "new-box" },
    [
      _c(
        "div",
        { staticClass: "mg-search" },
        [
          _c(
            "el-card",
            { staticStyle: { margin: "10px" } },
            [
              _c(
                "SearchFilter",
                {
                  on: {
                    search: function ($event) {
                      return _vm.handleFilter("hand")
                    },
                    reset: _vm.resetQuery,
                  },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.handleFilter.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品类型" } },
                    [
                      _c(
                        "el-select",
                        {
                          model: {
                            value: _vm.queryForm.itemType,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryForm, "itemType", $$v)
                            },
                            expression: "queryForm.itemType",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "全部", value: null },
                          }),
                          _c("el-option", {
                            attrs: { label: "晨光商品", value: 1 },
                          }),
                          _c("el-option", {
                            attrs: { label: "非晨光商品", value: 2 },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "内容" } },
                    [
                      _c("el-input", {
                        attrs: {
                          clearable: "",
                          placeholder: "请输入商品内容关键字",
                        },
                        model: {
                          value: _vm.queryForm.experienceContent,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryForm, "experienceContent", $$v)
                          },
                          expression: "queryForm.experienceContent",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "反馈时间", prop: "rangeTime" } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "500px" },
                        attrs: {
                          type: "datetimerange",
                          format: "yyyy-MM-dd",
                          "value-format": "yyyy-MM-dd",
                        },
                        model: {
                          value: _vm.rangeTime,
                          callback: function ($$v) {
                            _vm.rangeTime = $$v
                          },
                          expression: "rangeTime",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-card",
        { staticClass: "box-card", staticStyle: { margin: "10px" } },
        [
          _c(
            "el-button",
            {
              staticClass: "form-btn-item",
              attrs: {
                size: "mini",
                icon: "el-icon-download",
                type: "primary",
              },
              on: { click: _vm.handleExperienceListExport },
            },
            [_vm._v("导出")]
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "tableRef",
              staticClass: "customer-table",
              staticStyle: { "margin-top": "10px" },
              attrs: {
                data: _vm.pageData.rows,
                size: "small",
                border: "",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "内容",
                  prop: "experienceContent",
                  width: "200",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("div", { staticStyle: { "text-align": "left" } }, [
                          _c("span", [_vm._v(_vm._s(row.experienceContent))]),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "图片/视频", width: "300", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "div",
                          { staticStyle: { "text-align": "left" } },
                          _vm._l(
                            row?.experienceMedias
                              ?.filter((i) => i.mediaType === "image")
                              ?.map((i) => i.mediaUrl),
                            function (item) {
                              return _c("el-image", {
                                key: item,
                                staticStyle: { height: "80px", margin: "5px" },
                                attrs: {
                                  "preview-src-list": [item],
                                  src: item,
                                  lazy: "",
                                },
                              })
                            }
                          ),
                          1
                        ),
                        _c(
                          "div",
                          { staticStyle: { "text-align": "left" } },
                          _vm._l(
                            row?.experienceMedias
                              ?.filter((i) => i.mediaType === "video")
                              ?.map((i) => i.mediaUrl),
                            function (item) {
                              return _c("video", {
                                key: item,
                                staticStyle: { margin: "5px" },
                                attrs: {
                                  src: item,
                                  width: "200px",
                                  height: "160px",
                                  controls: "",
                                },
                              })
                            }
                          ),
                          0
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "商品类型", prop: "itemTypeStr", width: "100" },
              }),
              _c("el-table-column", {
                attrs: { label: "商品信息", prop: "skuInfos", width: "200" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "div",
                          { staticStyle: { "white-space": "pre-line" } },
                          [_vm._v(_vm._s(row.skuInfos))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "反馈时间", prop: "createTime", width: "150" },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "反馈人姓名",
                  prop: "feedbackUserName",
                  width: "150",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "反馈人分公司",
                  prop: "feedbackUserOrgName",
                  width: "150",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "反馈人手机号",
                  prop: "feedbackUserMobile",
                  width: "150",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "反馈人角色",
                  prop: "feedbackUserType",
                  width: "150",
                },
              }),
            ],
            1
          ),
          _c("Pagination", {
            attrs: {
              total: _vm.pageData.total,
              page: _vm.queryForm.page,
              limit: _vm.queryForm.limit,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.queryForm, "page", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.queryForm, "limit", $event)
              },
              pagination: _vm.handleQuery,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }