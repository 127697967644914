<template>
    <el-dialog title="到手价" :visible.sync="dialogVisible" @close="closeDialog" width="700px">
        <div class="couponInfo">
            <div class="left">
                <img :src="coupon" width="60" height="50" />
                <h3>优惠信息</h3>
            </div>
            <div class="right">
                <span class="item" v-if="yhPriceDetailData.yhMessageSkuAll">
                    <span class="label">【商品券】：</span>
                    <span class="value">{{ yhPriceDetailData.yhMessageSkuAll }}</span>
                </span>
                <span class="item" v-if="yhPriceDetailData.giftMoney">
                    <span class="label">【礼金】：</span>
                    <span class="value">{{ yhPriceDetailData.giftMoney }}</span>
                </span>
                <span class="item" v-if="yhPriceDetailData.yhMessageShopAll">
                    <span class="label">【店铺券】：</span>
                    <span class="value">{{ yhPriceDetailData.yhMessageShopAll }}</span>
                </span>
                <span class="item" v-if="yhPriceDetailData.yhMessagePlatAll">
                    <span class="label">【跨店活动】：</span>
                    <span class="value">{{ yhPriceDetailData.yhMessagePlatAll }}</span>
                </span>
            </div>
        </div>
        <div class="flowChart">
            <div class="floor1">
                <div class="item">
                    <span>原价：{{ yhPriceDetailData.price }}</span>
                    <!-- <span>券后：{{ yhPriceDetailData.couponPrice }}</span> -->
                </div>
            </div>
            <div class="floor2">
                <div class="item" v-if="yhPriceDetailData.yhMessageSku"> 
                    <span>【商品券】：{{ yhPriceDetailData.yhMessageSku }}</span>
                    <span>-{{ yhPriceDetailData.yhAmountSku }}元</span>
                </div>
                <div class="item" v-if="yhPriceDetailData.yhMessageShop">
                    <span>【店铺券】：{{ yhPriceDetailData.yhMessageShop }}</span>
                    <span>-{{ yhPriceDetailData.yhAmountShop }}元</span>
                </div>
                <div class="item" v-if="yhPriceDetailData.yhMessagePlat">
                    <span>【跨店活动】：{{ yhPriceDetailData.yhMessagePlat }}</span>
                    <span>-{{ yhPriceDetailData.yhAmountPlat }}元</span>
                </div>
            </div>
            <div class="floor3">
                <div class="item">
                    <span>-礼金{{ yhPriceDetailData.giftMoney }}元</span>
                </div>
            </div>
            <div class="floor4">
                <div class="item">
                    <span>到手价：{{ yhPriceDetailData.yhPrice }}元</span>
                </div>
            </div>
        </div>
    </el-dialog>
</template>

<script>
import { yhPriceDetail } from "@/api/eCommerce";
import coupon from "@/assets/images/coupon.png";
export default {
  name: "InHandsPrice",
  props: ['ptName','shopName','skuId'],
  data() {
    return {
        dialogVisible: false,
        yhPriceDetailData: {}, 
        coupon: coupon
    }
  },
  created() {
    this.dialogVisible = true;
    this.getYhPriceDetail()
  },
  methods: {
    getYhPriceDetail() {
        // const loading = this.$loading({
        //   lock: true,
        //   text: 'Loading',
        //   spinner: 'el-icon-loading',
        //   background: 'rgba(0, 0, 0, 0.7)',
        //   zIndex: 9999
        // });
        yhPriceDetail({ platName:this.ptName, shopName:this.shopName, skuId:this.skuId }).then(res => {
            if (res.code !== 200) {
                return false;
            }
            this.yhPriceDetailData = res.data;
            console.log('yhPriceDetailData',this.yhPriceDetailData)

            // loading.close();
        })
    },
    closeDialog() {
        this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.couponInfo {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    border: 1px dashed;
    .left {
        width: 100px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        h3 {
            margin: 8px 0;
        }
    }
    .right {
        flex: 1;
        display: flex;
        flex-direction: column;
        .item {
            line-height: 25px;
            .label {
                display: inline-block;
                width: 8em;
                text-align: right;
                margin-right: 10px;
            }
        }
    }
}
.flowChart {
    border: 1px dashed;
    padding: 15px;
    margin-top: 30px;
    text-align: center;
    .item {
        padding: 5px 15px;
        border: 1px solid;
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        line-height: 20px;
        border-radius: 4px;
    }
    .floor2 {
        border: 1px dashed red;
        border-radius: 4px;
        padding: 20px;
        display: flex;
        margin: 20px 0;
        gap: 20px;
        .item  {
            border-radius: 24px;
        }
    }
    .floor4 {
        margin-top: 20px;
    }
    .floor1,.floor2,.floor3 {
        position: relative;
        display: flex;
        justify-content: center;
        &::after {
            content: "";
            width: 10px;
            height: 20px;
            -webkit-clip-path: polygon(35% 0%, 65% 0%, 65% 75%, 100% 75%, 50% 100%, 0% 75%, 35% 75%, 35% 0%);
            clip-path: polygon(35% 0%, 65% 0%, 65% 75%, 100% 75%, 50% 100%, 0% 75%, 35% 75%, 35% 0%);
            display: inline-block;
            background: #333;
            position: absolute;
            bottom: -20px;
        }
    }
}
</style>