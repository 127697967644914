var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { padding: "20px" } },
    [
      _c(
        "div",
        { staticClass: "mg-search", staticStyle: { "padding-bottom": "15px" } },
        [
          _c(
            "el-form",
            {
              ref: "queryFormRef",
              attrs: {
                size: "mini",
                "label-width": "auto",
                "label-position": "right",
                inline: true,
                model: _vm.queryForm,
              },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.handleQuery.apply(null, arguments)
                },
              },
            },
            [
              !_vm.defaultOrgCode
                ? _c(
                    "el-form-item",
                    { attrs: { label: "分公司" } },
                    [
                      _c("DictSelect", {
                        ref: "org",
                        attrs: {
                          type: "org",
                          value: _vm.queryForm.orgCode,
                          linkageRef: "dc",
                          init: "",
                          initOptions: _vm.orgDictInitOptions,
                          fullLabel: "",
                          clearable: "",
                          placeholder: "请选择分公司",
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(_vm.queryForm, "orgCode", $event)
                          },
                          changeLinkageRef: _vm.handleQueryChangeLinkageRef,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "配送中心" } },
                [
                  _c("DictSelect", {
                    ref: "dc",
                    attrs: {
                      type: "dc",
                      value: _vm.queryForm.dcCode,
                      dictFun: _vm.dictFun,
                      parentLinkageRef: "",
                      fullLabel: "",
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(_vm.queryForm, "dcCode", $event)
                      },
                      getOtherJsonParam: (callback) => callback(_vm.queryForm),
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入用户账号或姓名",
                      clearable: "",
                    },
                    model: {
                      value: _vm.queryForm.allName,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "allName", $$v)
                      },
                      expression: "queryForm.allName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "form-btn-item",
                      attrs: {
                        size: "mini",
                        icon: "el-icon-search",
                        type: "primary",
                      },
                      on: { click: _vm.handleFilter },
                    },
                    [_vm._v("查询")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "form-btn-item",
                      attrs: {
                        size: "mini",
                        icon: "el-icon-refresh",
                        type: "primary",
                      },
                      on: { click: _vm.resetQuery },
                    },
                    [_vm._v("重置")]
                  ),
                  _vm.orgDictInitOptions.length > 0
                    ? _c(
                        "el-button",
                        {
                          staticClass: "form-btn-item",
                          attrs: {
                            size: "mini",
                            icon: "el-icon-plus",
                            type: "primary",
                          },
                          on: { click: _vm.handleEdit },
                        },
                        [_vm._v("新增组织人员")]
                      )
                    : _vm._e(),
                  _vm.orgDictInitOptions.length > 0
                    ? _c(
                        "el-button",
                        {
                          staticClass: "form-btn-item",
                          attrs: {
                            size: "mini",
                            icon: "el-icon-upload",
                            type: "primary",
                          },
                          on: {
                            click: () => {
                              _vm.importChooseDialog.visible = true
                              _vm.importChooseDialog.importAction = "add"
                            },
                          },
                        },
                        [_vm._v("分公司组织导入")]
                      )
                    : _vm._e(),
                  _vm.orgDictInitOptions.length > 0
                    ? _c(
                        "el-button",
                        {
                          staticClass: "form-btn-item",
                          attrs: {
                            size: "mini",
                            icon: "el-icon-download",
                            type: "primary",
                          },
                          on: { click: _vm.handleExport },
                        },
                        [_vm._v("分公司组织导出")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: {
            data: _vm.pageData.rows,
            height: "calc(100vh - 150px)",
            size: "mini",
            border: "",
            "header-cell-style": {
              background: "rgb(227 224 224)",
              color: "black",
            },
            "row-class-name": "rowClassName",
            "span-method": _vm.objectSpanMethod,
          },
          on: { "cell-click": _vm.handleCellClick },
        },
        [
          _c("el-table-column", {
            attrs: { label: "分公司", prop: "orgCode", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(row.orgCode + "-" + row.orgName)),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "总经理",
              prop: "name1",
              width: "150",
              align: "center",
              "class-name": "columnClass",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    row.userName1
                      ? _c(
                          "div",
                          {
                            staticStyle: {
                              color: "#0040ff",
                              "vertical-align": "middle",
                            },
                          },
                          _vm._l(
                            row.userName1.split(","),
                            function (item, index) {
                              return _c("div", { key: index }, [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      item + "-" + row.name1.split(",")[index]
                                    )
                                  ),
                                ]),
                                _c("br"),
                              ])
                            }
                          ),
                          0
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "大区经理",
              prop: "name2",
              width: "150",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    row.userName2
                      ? _c(
                          "div",
                          {
                            staticStyle: {
                              color: "#0040ff",
                              "vertical-align": "middle",
                            },
                          },
                          _vm._l(
                            row.userName2.split(","),
                            function (item, index) {
                              return _c("div", { key: index }, [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      item + "-" + row.name2.split(",")[index]
                                    )
                                  ),
                                ]),
                                _c("br"),
                              ])
                            }
                          ),
                          0
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "配送中心",
              prop: "dcCode",
              width: "220",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          row.dcCode
                            ? row.dcCode + "-" + (row.dcName ? row.dcName : "")
                            : ""
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "配送中心负责人",
              prop: "name3",
              width: "150",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    row.userName3
                      ? _c(
                          "div",
                          {
                            staticStyle: {
                              color: "#0040ff",
                              "vertical-align": "middle",
                            },
                          },
                          _vm._l(
                            row.userName3.split(","),
                            function (item, index) {
                              return _c("div", { key: index }, [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      item + "-" + row.name3.split(",")[index]
                                    )
                                  ),
                                ]),
                                _c("br"),
                              ])
                            }
                          ),
                          0
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "区域经理",
              prop: "name4",
              width: "150",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    row.name4
                      ? _c(
                          "div",
                          {
                            staticStyle: {
                              color: "#0040ff",
                              "vertical-align": "middle",
                            },
                          },
                          _vm._l(
                            row.userName4.split(","),
                            function (item, index) {
                              return _c("div", { key: index }, [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      item + "-" + row.name4.split(",")[index]
                                    )
                                  ),
                                ]),
                                _c("br"),
                              ])
                            }
                          ),
                          0
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "业务经理",
              prop: "name5",
              width: "150",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    row.userName5
                      ? _c(
                          "div",
                          {
                            staticStyle: {
                              color: "#0040ff",
                              "vertical-align": "middle",
                            },
                          },
                          _vm._l(
                            row.userName5.split(","),
                            function (item, index) {
                              return _c("div", [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      item + "-" + row.name5.split(",")[index]
                                    )
                                  ),
                                ]),
                                _c("br"),
                              ])
                            }
                          ),
                          0
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "业务员账号",
              prop: "name6",
              width: "150",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    row.name6
                      ? _c(
                          "span",
                          { staticStyle: { color: "#0040ff" } },
                          _vm._l(row.name6.split(","), function (item, index) {
                            return _c("span", { key: item }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    row.userName6.split(",")[index]
                                      ? row.userName6.split(",")[index] + "-"
                                      : ""
                                  ) +
                                  " " +
                                  _vm._s(item)
                              ),
                              _c("br"),
                            ])
                          }),
                          0
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("Pagination", {
        attrs: {
          total: _vm.pageData.total,
          page: _vm.queryForm.current,
          limit: _vm.queryForm.size,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.queryForm, "current", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.queryForm, "size", $event)
          },
          pagination: _vm.handleQuery,
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.editDialog.form.id ? "编辑组织人员" : "新增组织人员",
            visible: _vm.editDialog.visible,
            "append-to-body": "",
            width: "400px",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.editDialog, "visible", $event)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "editFormRef",
              attrs: {
                size: "mini",
                "label-width": "80px",
                "label-position": "right",
                inline: true,
                model: _vm.editDialog.form,
                rules: _vm.editDialog.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "分公司", prop: "orgCode" } },
                [
                  _c("DictSelect", {
                    ref: "org",
                    attrs: {
                      type: "org",
                      disabled: _vm.editDialog.form.id !== undefined,
                      value: _vm.editDialog.form.orgCode,
                      linkageRef: "dc",
                      init: "",
                      initOptions: _vm.orgDictInitOptions,
                      fullLabel: "",
                      clearable: false,
                      placeholder: "请选择分公司",
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(_vm.editDialog.form, "orgCode", $event)
                      },
                      changeLinkageRef: _vm.handleChangeLinkageRef,
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "岗位", prop: "positionId" } },
                [
                  _c(
                    "el-select",
                    {
                      on: {
                        change: (val) =>
                          _vm.handPositionChange(val, "position"),
                      },
                      model: {
                        value: _vm.editDialog.form.positionId,
                        callback: function ($$v) {
                          _vm.$set(_vm.editDialog.form, "positionId", $$v)
                        },
                        expression: "editDialog.form.positionId",
                      },
                    },
                    _vm._l(_vm.positionOptions, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.editDialog.form.positionId >= 3,
                      expression: "editDialog.form.positionId >= 3",
                    },
                  ],
                  attrs: { label: "配送中心", prop: "dcCode" },
                },
                [
                  _c(
                    "el-select",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.editDialog.form.positionId &&
                            _vm.editDialog.form.positionId === 3,
                          expression:
                            "editDialog.form.positionId && editDialog.form.positionId === 3",
                        },
                      ],
                      attrs: { filterable: "", clearable: "" },
                      on: {
                        change: (val) => _vm.handPositionChange(val, "dc"),
                      },
                      model: {
                        value: _vm.editDialog.form.dcCode,
                        callback: function ($$v) {
                          _vm.$set(_vm.editDialog.form, "dcCode", $$v)
                        },
                        expression: "editDialog.form.dcCode",
                      },
                    },
                    _vm._l(_vm.allDcOptions, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: {
                          label: item.dcCode + "-" + item.dcName,
                          value: item.dcCode,
                        },
                      })
                    }),
                    1
                  ),
                  _c(
                    "el-select",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.editDialog.form.positionId &&
                            _vm.editDialog.form.positionId > 3,
                          expression:
                            "editDialog.form.positionId && editDialog.form.positionId > 3",
                        },
                      ],
                      attrs: { filterable: "", clearable: "" },
                      on: {
                        change: (val) => _vm.handPositionChange(val, "dc"),
                      },
                      model: {
                        value: _vm.editDialog.form.dcCode,
                        callback: function ($$v) {
                          _vm.$set(_vm.editDialog.form, "dcCode", $$v)
                        },
                        expression: "editDialog.form.dcCode",
                      },
                    },
                    _vm._l(_vm.branchDcOptions, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: {
                          label: item.value + "-" + item.label,
                          value: item.value,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.editDialog.form.positionId > 1,
                      expression: "editDialog.form.positionId > 1",
                    },
                  ],
                  attrs: { label: "选择上级" },
                },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { filterable: "" },
                      on: {
                        change: (val) => {
                          _vm.$forceUpdate()
                        },
                      },
                      model: {
                        value: _vm.editDialog.form.parentId,
                        callback: function ($$v) {
                          _vm.$set(_vm.editDialog.form, "parentId", $$v)
                        },
                        expression: "editDialog.form.parentId",
                      },
                    },
                    _vm._l(_vm.parentOptions, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: {
                          label:
                            item.userName +
                            (item.nickName ? "-" + item.nickName : ""),
                          value: item.id,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm.editDialog.form.positionId < 6
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "是否主要负责人",
                        "label-width": "120px",
                      },
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: {
                            disabled:
                              _vm.editDialog.principalDisabled &&
                              _vm.editDialog.form.positionId < 5 &&
                              _vm.editDialog.form.positionId !== 2,
                          },
                          on: {
                            input: () => {
                              _vm.handleGetDeputy()
                              _vm.$forceUpdate()
                            },
                          },
                          model: {
                            value: _vm.editDialog.form.principal,
                            callback: function ($$v) {
                              _vm.$set(_vm.editDialog.form, "principal", $$v)
                            },
                            expression: "editDialog.form.principal",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("是"),
                          ]),
                          _c("el-radio", { attrs: { label: 0 } }, [
                            _vm._v("否"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.editDialog.form.positionId > 1 &&
              _vm.editDialog.form.positionId < 6 &&
              _vm.editDialog.form.principal !== 1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "负责人" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { filterable: "", clearable: "" },
                          on: {
                            change: (val) => {
                              _vm.$forceUpdate()
                            },
                          },
                          model: {
                            value: _vm.editDialog.form.principalId,
                            callback: function ($$v) {
                              _vm.$set(_vm.editDialog.form, "principalId", $$v)
                            },
                            expression: "editDialog.form.principalId",
                          },
                        },
                        _vm._l(_vm.editDialog.deputyList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: {
                              label:
                                item.nickName +
                                (item.positionName
                                  ? "-" + item.positionName
                                  : ""),
                              value: item.id,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "新增用户" } },
                [
                  _c("DictSelect", {
                    ref: "user",
                    attrs: {
                      type: "user",
                      disabled: _vm.editDialog.form.id !== undefined,
                      value: _vm.editDialog.form.userName,
                      dictFun: _vm.dictUserFun,
                      fullLabel: "",
                      remote: "",
                      version: "v3",
                      placeholder: "请输入用户账号或姓名",
                      "init-props": { label: "nickName", value: "userName" },
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(_vm.editDialog.form, "userName", $event)
                      },
                      getOtherJsonParam: (callback) =>
                        callback({ pageSize: 50, pageNum: 1 }),
                      change: _vm.handleRespChange,
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "岗位名称" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入岗位名称", clearable: "" },
                    on: {
                      input: (val) => {
                        _vm.editDialog.form.positionName = val
                        _vm.$forceUpdate()
                      },
                    },
                    model: {
                      value: _vm.editDialog.form.positionName,
                      callback: function ($$v) {
                        _vm.$set(_vm.editDialog.form, "positionName", $$v)
                      },
                      expression: "editDialog.form.positionName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "团队名称" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入团队名称", clearable: "" },
                    on: {
                      input: (val) => {
                        _vm.editDialog.form.teamName = val
                        _vm.$forceUpdate()
                      },
                    },
                    model: {
                      value: _vm.editDialog.form.teamName,
                      callback: function ($$v) {
                        _vm.$set(_vm.editDialog.form, "teamName", $$v)
                      },
                      expression: "editDialog.form.teamName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "备注" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      placeholder: "请输入备注",
                      clearable: "",
                    },
                    on: {
                      input: (val) => {
                        _vm.editDialog.form.remark = val
                        _vm.$forceUpdate()
                      },
                    },
                    model: {
                      value: _vm.editDialog.form.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.editDialog.form, "remark", $$v)
                      },
                      expression: "editDialog.form.remark",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    disabled: _vm.buttonLoading,
                    type: "primary",
                    size: "mini",
                  },
                  on: { click: _vm.handleSave },
                },
                [_vm._v("确定")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.editDialog.visible = false
                    },
                  },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "岗位人员",
            visible: _vm.viewDialog.visible,
            "append-to-body": "",
            width: "1000px",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.viewDialog, "visible", $event)
            },
          },
        },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.viewDialog.loading,
                  expression: "viewDialog.loading",
                },
              ],
              ref: "viewTableRef",
              staticStyle: { "margin-top": "5px" },
              attrs: {
                data: _vm.viewDialog.list,
                height: "500px",
                size: "mini",
                border: "",
              },
            },
            [
              _vm.viewDialog.queryParam.positionId < 6
                ? _c("el-table-column", {
                    attrs: { label: "主负责人", prop: "orgName", width: "80" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c(
                                "span",
                                {
                                  style:
                                    row.principal === 1 && row.positionId < 6
                                      ? { "font-weight": 600 }
                                      : "",
                                },
                                [
                                  _vm._v(
                                    _vm._s(row.principal === 1 ? "是" : "否")
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      545447201
                    ),
                  })
                : _vm._e(),
              _c("el-table-column", {
                attrs: { label: "用户", prop: "nickName", width: "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "span",
                          {
                            style:
                              row.principal === 1 && row.positionId < 6
                                ? { "font-weight": 600 }
                                : "",
                          },
                          [_vm._v(_vm._s(row.userName + "-" + row.nickName))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "关联组织", prop: "orgName", width: "200" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        row.positionId < 3
                          ? _c(
                              "span",
                              {
                                style:
                                  row.principal === 1 && row.positionId < 6
                                    ? { "font-weight": 600 }
                                    : "",
                              },
                              [_vm._v(_vm._s(row.orgCode + "-" + row.orgName))]
                            )
                          : _c(
                              "span",
                              {
                                style:
                                  row.principal === 1 && row.positionId < 6
                                    ? { "font-weight": 600 }
                                    : "",
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    row.dcCode
                                      ? row.dcCode +
                                          "-" +
                                          (row.dcName ? row.dcName : "")
                                      : ""
                                  )
                                ),
                              ]
                            ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "上级", prop: "parentName", width: "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "span",
                          {
                            style:
                              row.principal === 1 && row.positionId < 6
                                ? { "font-weight": 600 }
                                : "",
                          },
                          [
                            _vm._v(
                              _vm._s(
                                row.parentUserName && row.parentName
                                  ? row.parentUserName + "-" + row.parentName
                                  : ""
                              )
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "岗位", prop: "positionName", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "span",
                          {
                            style:
                              row.principal === 1 && row.positionId < 6
                                ? { "font-weight": 600 }
                                : "",
                          },
                          [_vm._v(_vm._s(row.positionName))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "备注",
                  prop: "remark",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "span",
                          {
                            style:
                              row.principal === 1 && row.positionId < 6
                                ? { "font-weight": 600 }
                                : "",
                          },
                          [_vm._v(_vm._s(row.remark))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "操作", width: "180" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "el-button",
                          {
                            staticStyle: { border: "0px" },
                            attrs: { size: "mini", type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleEdit(row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _c(
                          "el-button",
                          {
                            staticStyle: { border: "0px" },
                            attrs: { size: "mini", type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleDelRow(row)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                        row.positionId < 6
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { border: "0px" },
                                attrs: {
                                  disabled:
                                    row.principal === 1 && row.positionId < 6,
                                  size: "mini",
                                  type: "text",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleChangeMain(row)
                                  },
                                },
                              },
                              [_vm._v("设为主负责人")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticStyle: {
                "text-align": "center",
                border: "1px dashed skyblue",
              },
            },
            [
              _c(
                "el-button",
                {
                  staticStyle: { border: "0px", width: "100%" },
                  attrs: { size: "mini", type: "text" },
                  on: { click: _vm.handleAdd },
                },
                [_vm._v("新增")]
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.viewDialog.visible = false
                    },
                  },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "分公司组织导入",
            visible: _vm.importChooseDialog.visible,
            "append-to-body": "",
            width: "500px",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.importChooseDialog, "visible", $event)
            },
          },
        },
        [
          _c(
            "div",
            [
              _c("span", { staticStyle: { "font-weight": "600" } }, [
                _vm._v("导入方式："),
              ]),
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.importChooseDialog.importAction,
                    callback: function ($$v) {
                      _vm.$set(_vm.importChooseDialog, "importAction", $$v)
                    },
                    expression: "importChooseDialog.importAction",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: "add" } }, [
                    _vm._v("新增 "),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          color: "gray !important",
                          margin: "10px",
                        },
                      },
                      [
                        _vm._v(
                          " 每个管理层节点第一个人默认为主负责人，其他和后续新增均为助理。 "
                        ),
                      ]
                    ),
                  ]),
                  _c("el-radio", { attrs: { label: "override" } }, [
                    _vm._v("覆盖 "),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          color: "gray !important",
                          margin: "15px",
                        },
                      },
                      [
                        _vm._v(
                          " 将会清空本分公司所有组织数据，以本次导入为准。 "
                        ),
                      ]
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: { click: _vm.handleImport },
                },
                [_vm._v("确定")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.importChooseDialog.visible = false
                    },
                  },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ]
      ),
      _c("ImportDialog", {
        attrs: {
          importBaseParams: _vm.importBaseParams(
            _vm.importDialog.importBaseParams
          ),
          "append-to-body": "",
        },
        on: { handleImportCallBack: _vm.handleImportData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }