<template>
  <div style="padding: 20px">
    <div class="mg-search" >
      <el-card style="margin: 10px;">
        <SearchFilter  @search="handleFilter" @reset="resetQuery" @keyup.enter.native="handleQuery">
          <el-form-item v-if="options.orgOptions.length > 0" label="分公司">
            <DictSelect
              ref="org"
              type="org"
              :value.sync="queryForm.orgCode"
              fullLabel
              :init-options="options.orgOptions"
              init
              :clearable="false"
            />
          </el-form-item>
          <el-form-item label="指标名称">
            <el-input v-model="queryForm.quotaName" clearable />
          </el-form-item>
        </SearchFilter>
      </el-card>
    </div>
    <el-card style="margin: 10px;" class="box-card">
      <div style="margin: 0 0 10px 0;">
        <el-button size="mini" type="primary" style="border: 0px;" icon="el-icon-plus" @click="handleEditQuota()">新增</el-button>
      </div>
      <el-table
        :data="pageData.rows"
        height="calc(100vh - 350px)"
        v-loading="loading"
        size="mini"
        border
        ref="table"
        highlight-current-row
        :header-cell-style="{ background: 'rgb(235 235 235)', color: 'black',textAlign: 'center', verticalAlign: 'middle' }"
      >
        <el-table-column label="指标名称" prop="quotaName" width="200" />
        <el-table-column label="指标类型" prop="nickName" width="100">
          <template #default="{ row }">
            <span>{{ row.quotaTypeCode === 'CUSTOM' ? '自定义': '通用' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="APP显示模板" prop="quotaTpl" width="300" />
        <el-table-column label="单位" prop="unit" width="80" />
        <el-table-column label="备注" prop="remark" width="400" />
        <el-table-column label="操作" width="120" align="center">
          <template #default="{ row }">
            <el-button :disabled="row.quotaTypeCode !== 'CUSTOM'" size="mini" type="text" style="border: 0px;" @click="handleEditQuota(row)">编辑</el-button>
            <el-button :disabled="row.quotaTypeCode !== 'CUSTOM'" size="mini" type="text" style="border: 0px;" @click="handleRemoveQuota(row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <Pagination :total="pageData.total" :page.sync="queryForm.current" :limit.sync="queryForm.size" @pagination="handleQuery" />
    </el-card>

    <el-dialog :title="saveDialog.type === 'add'? '新增指标配置': '编辑指标配置'" :visible.sync="saveDialog.visible" width="500px" append-to-body :close-on-click-modal="false">
      <el-form ref="saveForm" :model="saveDialog.form" :inline="true" :rules="saveDialog.rules" size="mini" label-width="120px">
        <el-form-item label="分公司" prop="orgCode">
          <DictSelect
            ref="org"
            type="org"
            :value.sync="saveDialog.form.orgCode"
            fullLabel
            :init-options="options.orgOptions"
            init
            :clearable="false"
            style="width: 200px"
          />
        </el-form-item>
        <el-form-item label="指标名称" prop="quotaName">
          <el-input v-model="saveDialog.form.quotaName" style="width: 200px" clearable />
        </el-form-item>
        <el-form-item label="单位" prop="unit">
          <el-input v-model="saveDialog.form.unit" style="width: 200px" placeholder="如: 个/家/元/万元等" clearable />
        </el-form-item>
        <el-form-item label="APP显示模板" prop="quotaTpl">
          <el-input v-model="saveDialog.form.quotaTpl" style="width: 200px" placeholder="如: 签到家数${param}家, 只可填写一个动态参数" clearable />
          <el-button :disabled="saveDialog.form.quotaTpl.indexOf('${param}') > -1" size="mini" type="text" style="border: 0px;" @click="() => {
            saveDialog.form.quotaTpl+='${param}';
          }">添加参数</el-button>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input v-model="saveDialog.form.remark" style="width: 200px" type="textarea" clearable />
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button size="mini" @click="saveDialog.visible = false">取 消</el-button>
        <el-button size="mini" type="primary" @click="submitForm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import { dictSelect, userSelect, pageJxQuota, saveJxQuota, removeQuota } from "@/api/appTask";
import { Notification } from "element-ui";
import {parseTime, deepClone, excelExport} from "@/utils";
import DictSelect from "@/components/DictSelect";
import SearchFilter from "@/components/SearchFilter/index";

export default {
  name: "UserTag",
  components: {  Pagination, DictSelect, SearchFilter },
  props: {
    // 导入弹窗基础参数
    orgCode: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      dictFun: dictSelect,
      dictUserFun: userSelect,
      pageData: {
        total: 0,
        rows: [],
        selections: [],
      },
      queryForm: {
        current: 1,
        size: 10,
        quotaName: undefined,
        orgCode: undefined,
      },
      loading: false,
      saveDialog: {
        visible: false,
        loading: false,
        type: undefined,
        form: {
          quotaName: undefined,
          quotaCode: undefined,
          quotaTpl: '',
          quotaTypeCode: undefined,
          quotaType: undefined,
          unit: undefined,
          remark: undefined
        },
        rules: {
          orgCode: [{ required: true, message: " ", trigger: "change" }],
          quotaName: [{ required: true, message: " ", trigger: "change" }],
          quotaTpl: [{ required: true, message: " ", trigger: "change" }],
          unit: [{ required: true, message: " ", trigger: "change" }],
        },
      },
      jumpVal: undefined,
      noticeDialog: {
        visible: false,
        msg: undefined,
      },
      currentRow: {},
      options: {
        orgOptions: [],
      },
    };
  },
  watch: {
    "queryForm.orgCode": {
      handler(nV, oV) {
        this.handleFilter();
      },
      deep: true,
      immediate: true
    },
    orgCode: {
      handler(nV, oV) {
        if (nV) {
          this.queryForm.orgCode = nV;
        }
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    dictSelect('ht-org').then(res => {
      this.options.orgOptions = res.data;
      if (res.data.length > 0) {
        this.queryForm.orgCode = res.data[0].value;
      }
    })
  },
  methods: {
    handleQuery() {
      this.loading = true;
      const query = Object.assign({}, this.queryForm);
      pageJxQuota(query).then(res => {
        if (!res.data) {
          return false;
        }
        this.loading = false;
        this.pageData.rows = res.data.records;
        this.pageData.total = res.data.total;
      });
    },
    handleFilter() {
      this.queryForm.page = 1;
      this.handleQuery();
    },
    resetQuery() {
      this.queryForm = {
        current: 1,
        size: 10,
        quotaName: undefined,
        orgCode: this.queryForm.orgCode,
      }
    },
    handleEditQuota(row) {
      if (row) {
        this.saveDialog.form = deepClone(row);
        this.saveDialog.type = 'edit';
      } else {
        this.saveDialog.type = 'add';
        this.saveDialog.form = {
          orgCode: this.queryForm.orgCode,
          quotaName: undefined,
          quotaCode: undefined,
          quotaTpl: '',
          quotaTypeCode: 'CUSTOM',
          quotaType: '自定义',
          unit: undefined,
          remark: undefined
        };
      }
      this.$forceUpdate();
      this.saveDialog.visible = true;
    },
    handleRemoveQuota(row) {
      this.$confirm('请确认是否删除该数据！', '提示', { type: 'warning' }).then(() => {
        removeQuota(row.id).then(res=> {
          if (res.code === 200) {
            Notification.success('操作成功!');
            this.handleFilter();
          }
        })
      }).catch(() => {
      })
    },
    submitForm() {
      this.$refs.saveForm.validate(valid => {
        if (valid) {
          let form = deepClone(this.saveDialog.form);
          saveJxQuota(form).then(res=> {
            if (res.code === 200) {
              Notification.success('操作成功!');
              this.saveDialog.visible = false;
              this.handleFilter();
            }
          })
        }
      })
    },
  }
}
</script>

<style scoped>

</style>
