var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.homeLoading,
          expression: "homeLoading",
        },
      ],
      staticStyle: { padding: "20px" },
    },
    [
      _c(
        "div",
        { staticClass: "mg-search", staticStyle: { "padding-bottom": "15px" } },
        [
          _c(
            "div",
            { staticStyle: { float: "right" } },
            [
              _c(
                "el-dropdown",
                [
                  _c("el-button", { attrs: { type: "primary" } }, [
                    _vm._v(" 触发指定型号刷新 "),
                  ]),
                  _c(
                    "el-dropdown-menu",
                    { attrs: { slot: "dropdown" }, slot: "dropdown" },
                    [
                      _c(
                        "el-dropdown-item",
                        {
                          nativeOn: {
                            click: function ($event) {
                              return _vm.createSkuModelTask.apply(
                                null,
                                arguments
                              )
                            },
                          },
                        },
                        [_vm._v("新建任务")]
                      ),
                      _c(
                        "el-dropdown-item",
                        {
                          nativeOn: {
                            click: function ($event) {
                              return _vm.skuModelTaskScheduleQuery.apply(
                                null,
                                arguments
                              )
                            },
                          },
                        },
                        [_vm._v("任务进度")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form",
            {
              ref: "queryFormRef",
              attrs: {
                size: "mini",
                "label-width": "auto",
                inline: true,
                model: _vm.queryForm,
              },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.handleQuery.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "分公司" } },
                [
                  _c("DictSelect", {
                    attrs: {
                      type: "org",
                      value: _vm.queryForm.orgNames,
                      dictFun: _vm.dictFun,
                      multiple: "",
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(_vm.queryForm, "orgNames", $event)
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "平台" } },
                [
                  _c("DictSelect", {
                    ref: "platform",
                    attrs: {
                      type: "platform",
                      value: _vm.queryForm.platforms,
                      multiple: "",
                      linkageRef: "shop",
                      dictFun: _vm.dictFun,
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(_vm.queryForm, "platforms", $event)
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "店铺名称" } },
                [
                  _c("DictSelect", {
                    ref: "shop",
                    attrs: {
                      type: "shop",
                      parentLinkageRef: "",
                      value: _vm.queryForm.shopNames,
                      dictFun: _vm.dictFun,
                      multiple: "",
                      remote: "",
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(_vm.queryForm, "shopNames", $event)
                      },
                      getOtherJsonParam: (callback) =>
                        callback({ platforms: _vm.queryForm.platforms }),
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "赛道" } },
                [
                  _c("DictSelect", {
                    ref: "race",
                    attrs: {
                      type: "race",
                      value: _vm.queryForm.raceCodes,
                      dictFun: _vm.dictFun,
                      multiple: "",
                      linkageRef: "main",
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(_vm.queryForm, "raceCodes", $event)
                      },
                      changeLinkageRef: _vm.handleChangeLinkageRef,
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "大类" } },
                [
                  _c("DictSelect", {
                    ref: "main",
                    attrs: {
                      type: "main",
                      linkageRef: "mid",
                      parentLinkageRef: "",
                      value: _vm.queryForm.mainCategoryCodes,
                      dictFun: _vm.dictFun,
                      multiple: "",
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(
                          _vm.queryForm,
                          "mainCategoryCodes",
                          $event
                        )
                      },
                      getOtherJsonParam: (callback) =>
                        callback({ ..._vm.queryForm }),
                      changeLinkageRef: _vm.handleChangeLinkageRef,
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "中类" } },
                [
                  _c("DictSelect", {
                    ref: "mid",
                    attrs: {
                      type: "mid",
                      parentLinkageRef: "",
                      value: _vm.queryForm.midCategoryCodes,
                      dictFun: _vm.dictFun,
                      linkageRef: "sub",
                      multiple: "",
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(
                          _vm.queryForm,
                          "midCategoryCodes",
                          $event
                        )
                      },
                      getOtherJsonParam: (callback) =>
                        callback({ ..._vm.queryForm }),
                      changeLinkageRef: _vm.handleChangeLinkageRef,
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "小类" } },
                [
                  _c("DictSelect", {
                    ref: "sub",
                    attrs: {
                      type: "sub",
                      parentLinkageRef: "",
                      value: _vm.queryForm.subCategoryCodes,
                      dictFun: _vm.dictFun,
                      multiple: "",
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(
                          _vm.queryForm,
                          "subCategoryCodes",
                          $event
                        )
                      },
                      getOtherJsonParam: (callback) =>
                        callback({ ..._vm.queryForm }),
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "商品型号" } },
                [
                  _c("DictSelect", {
                    attrs: {
                      type: "sku",
                      value: _vm.queryForm.ecCargoNos,
                      dictFun: _vm.dictFun,
                      remote: "",
                      multiple: "",
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(_vm.queryForm, "ecCargoNos", $event)
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "快捷筛选" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.queryForm.tag,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "tag", $$v)
                        },
                        expression: "queryForm.tag",
                      },
                    },
                    _vm._l(_vm.tagOptions, function (item) {
                      return _c("el-option", {
                        key: item,
                        attrs: { label: item, value: item },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "form-btn-item",
                      attrs: {
                        size: "mini",
                        icon: "el-icon-search",
                        type: "primary",
                      },
                      on: { click: _vm.handleFilter },
                    },
                    [_vm._v("查询")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "form-btn-item",
                      attrs: {
                        size: "mini",
                        icon: "el-icon-refresh",
                        type: "primary",
                      },
                      on: { click: _vm.resetQuery },
                    },
                    [_vm._v("重置")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-tabs",
        {
          on: {
            "tab-click": (val) => {
              // handleQuery();
              _vm.handleFilter()
            },
          },
          model: {
            value: _vm.tabName,
            callback: function ($$v) {
              _vm.tabName = $$v
            },
            expression: "tabName",
          },
        },
        [
          _c("el-tab-pane", { attrs: { label: "型号低价汇总", name: "Low" } }),
          _c("el-tab-pane", {
            attrs: { label: "店铺在售商品信息", name: "Goods" },
          }),
          _c("el-tab-pane", {
            attrs: { label: "单品控价提醒", name: "Notice" },
          }),
          _c("el-tab-pane", { attrs: { label: "控价追踪", name: "Trace" } }),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.tabName === "Low",
              expression: "tabName==='Low'",
            },
          ],
        },
        [
          _c(
            "el-form",
            {
              ref: "queryNoticeFormRef",
              staticStyle: { padding: "10px" },
              attrs: {
                rules: _vm.rules,
                size: "mini",
                "label-width": "auto",
                inline: true,
                model: _vm.queryForm,
              },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.handleQuery.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "价格更新时间" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      size: "mini",
                      type: "daterange",
                      "range-separator": "至",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                      "value-format": "yyyy-MM-dd",
                    },
                    model: {
                      value: _vm.queryForm.lowPriceModifiedDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "lowPriceModifiedDate", $$v)
                      },
                      expression: "queryForm.lowPriceModifiedDate",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "form-btn-item",
                      attrs: {
                        size: "mini",
                        icon: "el-icon-search",
                        type: "primary",
                      },
                      on: { click: _vm.handleFilter },
                    },
                    [_vm._v("查询")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.lowLoading,
                  expression: "lowLoading",
                },
              ],
              ref: "lowTableRef",
              attrs: {
                data: _vm.pageLowData.rows,
                height: "calc(100vh - 250px)",
                size: "mini",
                border: "",
                "highlight-current-row": "",
                "header-cell-style": {
                  background: "rgb(235 235 235)",
                  color: "black",
                  textAlign: "center",
                  verticalAlign: "middle",
                },
              },
              on: { "sort-change": _vm.handleSortChange },
            },
            [
              _c("el-table-column", {
                attrs: { label: "型号", prop: "skuModel", "min-width": "30" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        row.skuModel === "总计"
                          ? _c(
                              "span",
                              {
                                staticStyle: {
                                  "font-size": "14px",
                                  "font-weight": "bold",
                                },
                              },
                              [_vm._v(_vm._s(row.skuModel))]
                            )
                          : _c("span", [_vm._v(_vm._s(row.skuModel))]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "型号名称",
                  prop: "skuModelName",
                  "min-width": "80",
                },
              }),
              _c(
                "el-table-column",
                {
                  attrs: {
                    label: "低于控价SKU数",
                    prop: "totalCount",
                    width: "100",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "合计", prop: "totalCount", width: "100" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            row.skuModel !== "总计"
                              ? _c(
                                  "a",
                                  {
                                    staticStyle: {
                                      color: "#6a6ae9",
                                      "font-size": "12px",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleLowControlPriceClick(
                                          row.skuModel,
                                          undefined,
                                          "Low"
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(row.totalCount))]
                                )
                              : _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      "font-size": "14px",
                                      "font-weight": "bold",
                                    },
                                  },
                                  [_vm._v(_vm._s(row.totalCount))]
                                ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "淘宝", prop: "tbCount", width: "100" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            row.skuModel !== "总计"
                              ? _c(
                                  "a",
                                  {
                                    staticStyle: {
                                      color: "#6a6ae9",
                                      "font-size": "12px",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleLowControlPriceClick(
                                          row.skuModel,
                                          "淘宝",
                                          "Low"
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(row.tbCount))]
                                )
                              : _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      "font-size": "14px",
                                      "font-weight": "bold",
                                    },
                                  },
                                  [_vm._v(_vm._s(row.tbCount))]
                                ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "拼多多", prop: "pddCount", width: "100" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            row.skuModel !== "总计"
                              ? _c(
                                  "a",
                                  {
                                    staticStyle: {
                                      color: "#6a6ae9",
                                      "font-size": "12px",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleLowControlPriceClick(
                                          row.skuModel,
                                          "拼多多",
                                          "Low"
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(row.pddCount))]
                                )
                              : _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      "font-size": "14px",
                                      "font-weight": "bold",
                                    },
                                  },
                                  [_vm._v(_vm._s(row.pddCount))]
                                ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "京东", prop: "jdCount", width: "100" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            row.skuModel !== "总计"
                              ? _c(
                                  "a",
                                  {
                                    staticStyle: {
                                      color: "#6a6ae9",
                                      "font-size": "12px",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleLowControlPriceClick(
                                          row.skuModel,
                                          "京东",
                                          "Low"
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(row.jdCount))]
                                )
                              : _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      "font-size": "14px",
                                      "font-weight": "bold",
                                    },
                                  },
                                  [_vm._v(_vm._s(row.jdCount))]
                                ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "抖音", prop: "dyCount", width: "100" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function ({ row }) {
                          return [
                            row.skuModel !== "总计"
                              ? _c(
                                  "a",
                                  {
                                    staticStyle: {
                                      color: "#6a6ae9",
                                      "font-size": "12px",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleLowControlPriceClick(
                                          row.skuModel,
                                          "抖音",
                                          "Low"
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(row.dyCount))]
                                )
                              : _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      "font-size": "14px",
                                      "font-weight": "bold",
                                    },
                                  },
                                  [_vm._v(_vm._s(row.dyCount))]
                                ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("Pagination", {
            attrs: {
              total: _vm.pageLowData.total,
              page: _vm.queryForm.page,
              limit: _vm.queryForm.limit,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.queryForm, "page", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.queryForm, "limit", $event)
              },
              pagination: _vm.handleQuery,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.tabName === "Goods",
              expression: "tabName==='Goods'",
            },
          ],
        },
        [
          _c(
            "div",
            { staticStyle: { padding: "10px" } },
            [
              _c(
                "el-button",
                {
                  staticClass: "form-btn-item",
                  attrs: {
                    size: "mini",
                    icon: "el-icon-download",
                    type: "primary",
                  },
                  on: { click: _vm.handleExportGoods },
                },
                [_vm._v("导出")]
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.goodsLoading,
                  expression: "goodsLoading",
                },
              ],
              ref: "tableRef",
              attrs: {
                data: _vm.pageGoodsData.rows,
                height: "calc(100vh - 250px)",
                size: "mini",
                border: "",
                "highlight-current-row": "",
                "header-cell-style": {
                  background: "rgb(235 235 235)",
                  color: "black",
                  textAlign: "center",
                  verticalAlign: "middle",
                },
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "平台", prop: "ptName", "min-width": "80" },
              }),
              _c("el-table-column", {
                attrs: { label: "分公司", prop: "orgName", "min-width": "80" },
              }),
              _c("el-table-column", {
                attrs: { label: "店铺名称", prop: "shopName", width: "200" },
              }),
              _c("el-table-column", {
                attrs: { label: "店铺性质", prop: "shopType", width: "200" },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "晨光在售链接数",
                  prop: "saleSkuUrlCount",
                  width: "140",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "晨光SKU数",
                  prop: "saleSkuIdCount",
                  "min-width": "140",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "a",
                          {
                            staticStyle: {
                              color: "#6a6ae9",
                              "font-size": "12px",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleRowClick(row, "Goods")
                              },
                            },
                          },
                          [_vm._v(_vm._s(row.saleSkuIdCount))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("Pagination", {
            attrs: {
              total: _vm.pageGoodsData.total,
              page: _vm.queryForm.page,
              limit: _vm.queryForm.limit,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.queryForm, "page", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.queryForm, "limit", $event)
              },
              pagination: _vm.handleQuery,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.tabName == "Notice",
              expression: "tabName=='Notice'",
            },
          ],
        },
        [
          _c(
            "el-form",
            {
              ref: "queryNoticeFormRef",
              staticStyle: { padding: "10px" },
              attrs: {
                rules: _vm.rules,
                size: "mini",
                "label-width": "auto",
                inline: true,
                model: _vm.queryForm,
              },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.handleQuery.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "商品ID" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "" },
                    model: {
                      value: _vm.queryForm.itemId,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "itemId", $$v)
                      },
                      expression: "queryForm.itemId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "SKUID" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "" },
                    model: {
                      value: _vm.queryForm.skuId,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "skuId", $$v)
                      },
                      expression: "queryForm.skuId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "是否已确认" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "" },
                      model: {
                        value: _vm.queryForm.ifConfirm,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "ifConfirm", $$v)
                        },
                        expression: "queryForm.ifConfirm",
                      },
                    },
                    [
                      _c("el-option", { attrs: { value: "Y", label: "是" } }),
                      _c("el-option", { attrs: { value: "N", label: "否" } }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "警示价" } },
                [
                  _c("el-input-number", {
                    attrs: { clerable: "", step: 2, min: 0, controls: false },
                    model: {
                      value: _vm.queryForm.warningPrice,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "warningPrice", $$v)
                      },
                      expression: "queryForm.warningPrice",
                    },
                  }),
                  _vm._v("元 "),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "控价结果" } },
                [
                  _c("DictSelect", {
                    attrs: {
                      type: "controlPrice",
                      value: _vm.queryForm.controlPriceResult,
                      dictFun: _vm.dictFun,
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(
                          _vm.queryForm,
                          "controlPriceResult",
                          $event
                        )
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "价差" } },
                [
                  _c("el-input-number", {
                    staticStyle: { width: "100px" },
                    attrs: { clearable: "" },
                    on: { input: _vm.handleDiffPriceStart },
                    model: {
                      value: _vm.queryForm.diffPriceStart,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "diffPriceStart", $$v)
                      },
                      expression: "queryForm.diffPriceStart",
                    },
                  }),
                  _vm._v(" % 至  "),
                  _c(
                    "el-input-number",
                    {
                      staticStyle: { width: "100px" },
                      attrs: { clearable: "" },
                      on: { input: _vm.handleDiffPriceEnd },
                      model: {
                        value: _vm.queryForm.diffPriceEnd,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "diffPriceEnd", $$v)
                        },
                        expression: "queryForm.diffPriceEnd",
                      },
                    },
                    [_c("template", { slot: "suffix" }, [_vm._v("%")])],
                    2
                  ),
                  _vm._v(" % "),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "价格更新时间" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      size: "mini",
                      type: "daterange",
                      "range-separator": "至",
                      "start-placeholder": "开始日期",
                      "end-placeholder": "结束日期",
                      "value-format": "yyyy-MM-dd",
                    },
                    model: {
                      value: _vm.queryForm.priceModifiedDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "priceModifiedDate", $$v)
                      },
                      expression: "queryForm.priceModifiedDate",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "是否组装", prop: "ifCombined" } },
                [
                  _c("DictSelect", {
                    ref: "ifSpu",
                    staticStyle: { width: "500px" },
                    attrs: {
                      type: "ifSpu",
                      value: _vm.queryForm.ifCombined,
                      dictFun: _vm.dictSpuFun,
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(_vm.queryForm, "ifCombined", $event)
                      },
                      getOtherJsonParam: (callback) =>
                        callback({ platforms: _vm.queryForm.platforms }),
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "form-btn-item",
                      attrs: {
                        size: "mini",
                        icon: "el-icon-search",
                        type: "primary",
                      },
                      on: { click: _vm.handleFilter },
                    },
                    [_vm._v("查询")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { padding: "10px" } },
            [
              _c(
                "el-button",
                {
                  staticClass: "form-btn-item",
                  attrs: {
                    size: "mini",
                    icon: "el-icon-download",
                    type: "primary",
                  },
                  on: { click: _vm.handleExportNotice },
                },
                [_vm._v("导出")]
              ),
              _c(
                "el-button",
                {
                  staticClass: "form-btn-item",
                  attrs: {
                    size: "mini",
                    icon: "el-icon-delete-solid",
                    type: "primary",
                  },
                  on: { click: _vm.handleBatchSaveNotice },
                },
                [_vm._v("清除所选项的备注")]
              ),
              _c(
                "el-button",
                {
                  staticClass: "form-btn-item",
                  attrs: { size: "mini", type: "primary" },
                  on: { click: _vm.handleBatchMatchConfirm },
                },
                [_vm._v("批量确认匹配")]
              ),
              _c(
                "el-button",
                {
                  staticClass: "form-btn-item",
                  attrs: { size: "mini", type: "primary" },
                  on: { click: _vm.handleSkuPriceControlTraceGenerate },
                },
                [_vm._v("生成控价追踪表")]
              ),
              _c(
                "el-popover",
                {
                  attrs: { placement: "right", width: "600", trigger: "hover" },
                },
                [
                  _c("div", { staticStyle: { "overflow-y": "scroll" } }, [
                    _c("label", [_vm._v("功能说明：")]),
                    _c("br"),
                    _vm._v(
                      " 1.页面商品需要有到SKU的对应关系，系统会根据页面信息及图片识别。如未识别上需要人工点击【匹配】按钮进行维护"
                    ),
                    _c("br"),
                    _vm._v(
                      " 2.控价计算结果需要依赖 控价表，用户可以点击 【导出控价表】获取当前控价信息。通过【导入控价表】可以覆盖最新控价信息。"
                    ),
                    _c("br"),
                    _vm._v(
                      ' 3.控价到型号、规格、售卖数量上下限。型号需要完全一致，规格如果是一级规格（支、块、套、卡、本、包、袋、件、桶、本体、把、箱、张、个、筒）会自动转成"个"匹配上。'
                    ),
                    _c("br"),
                    _vm._v(
                      " 4.价差=(当前售价-控价总价)/控价总价，可以通过筛选项查找价差范围，如价差非常大，可能匹配关系异常，建议复核"
                    ),
                    _c("br"),
                    _vm._v(
                      " 5.警示价:即低于筛选低于警示价的所有型号，您可以针对指定型号或某个品类进行操作，快速找到低价品。"
                    ),
                    _c("br"),
                    _c("br"),
                    _c("label", [_vm._v("控价逻辑：")]),
                    _c("br"),
                    _vm._v(
                      " 1.在控价表中的型号及规格范围，用 [页面售价] 与 [符合规格数量的控价] 对比"
                    ),
                    _c("br"),
                    _vm._v(
                      " 2.如控价表中没有的型号，则以 [零售价*页面售卖数量] 的9折对比 [页面售价]"
                    ),
                    _c("br"),
                    _c("br"),
                    _c("label", [_vm._v("控价结果解释：")]),
                    _c("br"),
                    _vm._v(" 1.符合控价： 价格高于控价"),
                    _c("br"),
                    _vm._v(" 2.价格低于控价： 价格低于控价"),
                    _c("br"),
                    _vm._v(
                      " 3.不符合控价售卖规格：控价表中没有该型号页面售卖的规格"
                    ),
                    _c("br"),
                    _vm._v(
                      " 4.匹配关系缺失：页面商品型号或规格未知，点击【匹配】维护 "
                    ),
                    _c("br"),
                  ]),
                  _c(
                    "el-link",
                    {
                      staticStyle: { "margin-left": "10px" },
                      attrs: {
                        slot: "reference",
                        icon: "el-icon-question",
                        type: "info",
                      },
                      slot: "reference",
                    },
                    [_vm._v("使用说明")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.noticeLoading,
                  expression: "noticeLoading",
                },
              ],
              ref: "tableRef",
              attrs: {
                data: _vm.pageNoticeData.rows,
                height: "calc(100vh - 250px)",
                size: "mini",
                border: "",
                "highlight-current-row": "",
                "header-cell-style": {
                  background: "rgb(235 235 235)",
                  color: "black",
                  textAlign: "center",
                  verticalAlign: "middle",
                },
              },
              on: {
                "sort-change": _vm.handleSortChange,
                "selection-change": _vm.handleSelectionChange,
              },
            },
            [
              _c("el-table-column", {
                attrs: { fixed: "", type: "selection", width: "90" },
              }),
              _c("el-table-column", {
                attrs: { label: "平台", prop: "ptName", "min-width": "60" },
              }),
              _c("el-table-column", {
                attrs: { label: "分公司", prop: "orgName", "min-width": "80" },
              }),
              _c("el-table-column", {
                attrs: { label: "店铺名称", prop: "shopName", width: "200" },
              }),
              _c("el-table-column", {
                attrs: { label: "页面标题", prop: "title", width: "200" },
              }),
              _c("el-table-column", {
                attrs: { label: "商品ID", prop: "itemId", "min-width": "100" },
              }),
              _c("el-table-column", {
                attrs: { label: "SKUID", prop: "skuId", "min-width": "100" },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "商品信息",
                  prop: "skuName",
                  "min-width": "200",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "a",
                          {
                            staticStyle: {
                              color: "#6a6ae9",
                              "font-size": "12px",
                            },
                            attrs: { target: "_blank", href: row.skuUrl },
                          },
                          [_vm._v(_vm._s(row.skuName))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "商品图片", prop: "skuPic", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("el-image", {
                          staticStyle: { height: "80px", margin: "5px" },
                          attrs: {
                            "preview-src-list": [row.skuPic],
                            src: row.skuPic,
                            lazy: "",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "匹配关系",
                  prop: "finalMatch",
                  "min-width": "200",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        row.finalMatch
                          ? _c(
                              "div",
                              _vm._l(
                                row.finalMatch.split("\n"),
                                function (item) {
                                  return _c("p", { key: item }, [
                                    _vm._v(" " + _vm._s(item)),
                                  ])
                                }
                              ),
                              0
                            )
                          : _vm._e(),
                        _c(
                          "el-button",
                          {
                            staticClass: "form-btn-item",
                            attrs: { size: "mini", type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleJumpQuery(row.skuId)
                              },
                            },
                          },
                          [_vm._v("匹配")]
                        ),
                        row.ifShowConfirm === "N"
                          ? _c(
                              "el-button",
                              {
                                staticClass: "form-btn-item",
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleMatchConfirm(row)
                                  },
                                },
                              },
                              [_vm._v("确认")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "价差",
                  prop: "diffPrice",
                  sortable: "",
                  "min-width": "80",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        row.diffPrice != undefined
                          ? _c(
                              "span",
                              {
                                style:
                                  row.diffPrice < 0 ? { color: "red" } : {},
                              },
                              [_vm._v(_vm._s(row.diffPrice + "%"))]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "控价结果",
                  prop: "controlPriceResult",
                  "min-width": "80",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "span",
                          {
                            style:
                              row.controlTotalPrice > row.currentSellPrice
                                ? { color: "red" }
                                : {},
                          },
                          [_vm._v(_vm._s(row.controlPriceResult))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "价格更新时间",
                  prop: "priceModified",
                  sortable: "",
                  "min-width": "140",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "控价单价",
                  prop: "controlMinUnitPrice",
                  "min-width": "80",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("div", [
                          _c("span", [_vm._v(_vm._s(row.controlMinUnitPrice))]),
                        ]),
                        _c(
                          "el-button",
                          {
                            staticClass: "form-btn-item",
                            attrs: { size: "mini", type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.queryPriceControlList(row)
                              },
                            },
                          },
                          [_vm._v("查询")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "x 控价规格",
                  prop: "saleUnitKj",
                  "min-width": "80",
                },
              }),
              _c(
                "el-table-column",
                {
                  attrs: {
                    label: "= 控价总价",
                    prop: "controlTotalPrice",
                    "min-width": "80",
                  },
                },
                [
                  _c("template", { slot: "header" }, [
                    _c(
                      "div",
                      { staticStyle: { "background-color": "#99cbff" } },
                      [_vm._v("= 控价总价")]
                    ),
                  ]),
                ],
                2
              ),
              _c(
                "el-table-column",
                {
                  attrs: {
                    label: "当前售价",
                    prop: "currentSellPrice",
                    "min-width": "80",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          row.screenshotImageUrl
                            ? _c(
                                "el-popover",
                                {
                                  attrs: {
                                    placement: "top-start",
                                    width: "80",
                                    trigger: "hover",
                                  },
                                },
                                [
                                  row.screenshotImageUrl
                                    ? _c("el-image", {
                                        staticStyle: {
                                          height: "80px",
                                          margin: "5px",
                                        },
                                        attrs: {
                                          "preview-src-list": [
                                            row.screenshotImageUrl,
                                          ],
                                          src: row.screenshotImageUrl,
                                        },
                                      })
                                    : _vm._e(),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        slot: "reference",
                                        type: "text",
                                      },
                                      slot: "reference",
                                    },
                                    [_vm._v(_vm._s(row.currentSellPrice))]
                                  ),
                                ],
                                1
                              )
                            : _c("span", [
                                _vm._v(_vm._s(row.currentSellPrice)),
                              ]),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c("template", { slot: "header" }, [
                    _c(
                      "div",
                      { staticStyle: { "background-color": "#99cbff" } },
                      [_vm._v("当前售价")]
                    ),
                  ]),
                ],
                2
              ),
              _c("el-table-column", {
                attrs: { label: "到手价", prop: "yhPrice", "min-width": "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.openInHandsPriceDialog(row)
                              },
                            },
                          },
                          [_vm._v(_vm._s(row.yhPrice))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "控价赠品说明",
                  prop: "controlPriceGiftExplain",
                  "min-width": "80",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "备注", prop: "orgName", "min-width": "140" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("el-input", {
                          attrs: {
                            size: "mini",
                            placeholder: "输入后自动保存",
                          },
                          on: {
                            input: function ($event) {
                              return _vm.handleUpdatePriceNotice(row)
                            },
                          },
                          model: {
                            value: row.remark,
                            callback: function ($$v) {
                              _vm.$set(row, "remark", $$v)
                            },
                            expression: "row.remark",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("Pagination", {
            attrs: {
              total: _vm.pageNoticeData.total,
              page: _vm.queryForm.page,
              limit: _vm.queryForm.limit,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.queryForm, "page", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.queryForm, "limit", $event)
              },
              pagination: _vm.handleQuery,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.tabName === "Trace",
              expression: "tabName==='Trace'",
            },
          ],
        },
        [
          _c(
            "el-form",
            {
              ref: "queryTraceFormRef",
              staticStyle: { padding: "10px" },
              attrs: {
                rules: _vm.rules,
                size: "mini",
                "label-width": "auto",
                inline: true,
                model: _vm.queryForm,
              },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.handleQuery.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "商品ID" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "" },
                    model: {
                      value: _vm.queryForm.traceItemId,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "traceItemId", $$v)
                      },
                      expression: "queryForm.traceItemId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "SKUID" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "" },
                    model: {
                      value: _vm.queryForm.traceSkuId,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "traceSkuId", $$v)
                      },
                      expression: "queryForm.traceSkuId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "控价结果" } },
                [
                  _c("DictSelect", {
                    attrs: {
                      type: "controlPrice",
                      value: _vm.queryForm.traceControlPriceResult,
                      dictFun: _vm.dictFun,
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(
                          _vm.queryForm,
                          "traceControlPriceResult",
                          $event
                        )
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "form-btn-item",
                      attrs: {
                        size: "mini",
                        icon: "el-icon-search",
                        type: "primary",
                      },
                      on: { click: _vm.handleFilter },
                    },
                    [_vm._v("查询")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "form-btn-item",
                      attrs: { size: "mini", type: "primary" },
                      on: { click: _vm.handleSkuPriceControlTraceCancel },
                    },
                    [_vm._v("取消跟进")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "form-btn-item",
                      attrs: {
                        size: "mini",
                        icon: "el-icon-download",
                        type: "primary",
                      },
                      on: { click: _vm.handlePriceControlTraceExportNotice },
                    },
                    [_vm._v("导出")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.traceLoading,
                  expression: "traceLoading",
                },
              ],
              ref: "tableRef",
              attrs: {
                data: _vm.pageTraceData.rows,
                height: "calc(100vh - 250px)",
                size: "mini",
                border: "",
                "highlight-current-row": "",
                "header-cell-style": {
                  background: "rgb(235 235 235)",
                  color: "black",
                  textAlign: "center",
                  verticalAlign: "middle",
                },
              },
              on: {
                "sort-change": _vm.handleSortChange,
                "selection-change": _vm.handleTraceSelectionChange,
              },
            },
            [
              _c("el-table-column", {
                attrs: { fixed: "", type: "selection", width: "90" },
              }),
              _c("el-table-column", {
                attrs: { label: "平台", prop: "ptName", "min-width": "60" },
              }),
              _c("el-table-column", {
                attrs: { label: "分公司", prop: "orgName", "min-width": "80" },
              }),
              _c("el-table-column", {
                attrs: { label: "店铺名称", prop: "shopName", width: "200" },
              }),
              _c("el-table-column", {
                attrs: { label: "页面标题", prop: "title", width: "200" },
              }),
              _c("el-table-column", {
                attrs: { label: "商品ID", prop: "itemId", "min-width": "100" },
              }),
              _c("el-table-column", {
                attrs: { label: "SKUID", prop: "skuId", "min-width": "100" },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "商品信息",
                  prop: "skuName",
                  "min-width": "200",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "a",
                          {
                            staticStyle: {
                              color: "#6a6ae9",
                              "font-size": "12px",
                            },
                            attrs: { target: "_blank", href: row.skuUrl },
                          },
                          [_vm._v(_vm._s(row.skuName))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "商品图片", prop: "skuPic", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("el-image", {
                          staticStyle: { height: "80px", margin: "5px" },
                          attrs: {
                            "preview-src-list": [row.skuPic],
                            src: row.skuPic,
                            lazy: "",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "匹配关系",
                  prop: "finalMatch",
                  "min-width": "200",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        row.finalMatch
                          ? _c(
                              "div",
                              _vm._l(
                                row.finalMatch.split("\n"),
                                function (item) {
                                  return _c("p", { key: item }, [
                                    _vm._v(" " + _vm._s(item)),
                                  ])
                                }
                              ),
                              0
                            )
                          : _vm._e(),
                        _c(
                          "el-button",
                          {
                            staticClass: "form-btn-item",
                            attrs: { size: "mini", type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleJumpQuery(row.skuId)
                              },
                            },
                          },
                          [_vm._v("匹配")]
                        ),
                        row.ifShowConfirm === "N"
                          ? _c(
                              "el-button",
                              {
                                staticClass: "form-btn-item",
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleMatchConfirm(row)
                                  },
                                },
                              },
                              [_vm._v("确认")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "价差",
                  prop: "diffPrice",
                  sortable: "",
                  "min-width": "80",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        row.spreadPrice != undefined
                          ? _c(
                              "span",
                              {
                                style:
                                  row.spreadPrice < 0 ? { color: "red" } : {},
                              },
                              [_vm._v(_vm._s(row.spreadPrice + "%"))]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "控价结果",
                  prop: "controlPriceResult",
                  "min-width": "80",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "span",
                          {
                            style:
                              row.controlTotalPrice > row.currentSellPrice
                                ? { color: "red" }
                                : {},
                          },
                          [_vm._v(_vm._s(row.controlPriceResult))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "价格更新时间",
                  prop: "priceModified",
                  sortable: "",
                  "min-width": "140",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "控价单价",
                  prop: "controlMinUnitPrice",
                  "min-width": "80",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("div", [
                          _c("span", [_vm._v(_vm._s(row.controlMinUnitPrice))]),
                        ]),
                        _c(
                          "el-button",
                          {
                            staticClass: "form-btn-item",
                            attrs: { size: "mini", type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.queryPriceControlList(row)
                              },
                            },
                          },
                          [_vm._v("查询")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "x 控价规格",
                  prop: "saleUnitKj",
                  "min-width": "80",
                },
              }),
              _c(
                "el-table-column",
                {
                  attrs: {
                    label: "= 控价总价",
                    prop: "controlTotalPrice",
                    "min-width": "80",
                  },
                },
                [
                  _c("template", { slot: "header" }, [
                    _c(
                      "div",
                      { staticStyle: { "background-color": "#99cbff" } },
                      [_vm._v("= 控价总价")]
                    ),
                  ]),
                ],
                2
              ),
              _c(
                "el-table-column",
                {
                  attrs: {
                    label: "当前售价",
                    prop: "currentSellPrice",
                    "min-width": "80",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          row.screenshotImageUrl
                            ? _c(
                                "el-popover",
                                {
                                  attrs: {
                                    placement: "top-start",
                                    width: "80",
                                    trigger: "hover",
                                  },
                                },
                                [
                                  row.screenshotImageUrl
                                    ? _c("el-image", {
                                        staticStyle: {
                                          height: "80px",
                                          margin: "5px",
                                        },
                                        attrs: {
                                          "preview-src-list": [
                                            row.screenshotImageUrl,
                                          ],
                                          src: row.screenshotImageUrl,
                                        },
                                      })
                                    : _vm._e(),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        slot: "reference",
                                        type: "text",
                                      },
                                      slot: "reference",
                                    },
                                    [_vm._v(_vm._s(row.currentSellPrice))]
                                  ),
                                ],
                                1
                              )
                            : _c("span", [
                                _vm._v(_vm._s(row.currentSellPrice)),
                              ]),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c("template", { slot: "header" }, [
                    _c(
                      "div",
                      { staticStyle: { "background-color": "#99cbff" } },
                      [_vm._v("当前售价")]
                    ),
                  ]),
                ],
                2
              ),
              _c("el-table-column", {
                attrs: { label: "到手价", prop: "yhPrice", "min-width": "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.openInHandsPriceDialog(row)
                              },
                            },
                          },
                          [_vm._v(_vm._s(row.yhPrice))]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "原售价",
                  prop: "originSellPrice",
                  "min-width": "80",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "价格较加入时变化",
                  prop: "diffPrice",
                  "min-width": "120",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        row.diffPrice != undefined && row.diffPrice > 0
                          ? _c("span", { style: { color: "red" } }, [
                              _vm._v(_vm._s(row.diffPrice)),
                            ])
                          : row.diffPrice != undefined && row.diffPrice < 0
                          ? _c("span", { style: { color: "green" } }, [
                              _vm._v(_vm._s(row.diffPrice)),
                            ])
                          : _c("span", [_vm._v(_vm._s(row.diffPrice))]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "价格更新次数",
                  prop: "collectCount",
                  "min-width": "120",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "符合控价次数",
                  prop: "qualifiedCount",
                  "min-width": "120",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "创建追踪时间",
                  prop: "traceCreateTime",
                  "min-width": "140",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "创建用户",
                  prop: "userName",
                  "min-width": "140",
                },
              }),
            ],
            1
          ),
          _c("Pagination", {
            attrs: {
              total: _vm.pageTraceData.total,
              page: _vm.queryForm.page,
              limit: _vm.queryForm.limit,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.queryForm, "page", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.queryForm, "limit", $event)
              },
              pagination: _vm.handleQuery,
            },
          }),
        ],
        1
      ),
      _vm.inHandsPriceVisibile
        ? _c("InHandsPrice", {
            attrs: {
              ptName: _vm.selectedRows.ptName,
              shopName: _vm.selectedRows.shopName,
              skuId: _vm.selectedRows.skuId,
            },
            on: {
              close: function ($event) {
                _vm.inHandsPriceVisibile = false
              },
            },
          })
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "请选择正确的匹配关系",
            visible: _vm.skuModelUpdateDialog.visible,
            "append-to-body": "",
            width: "1200px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.skuModelUpdateDialog, "visible", $event)
            },
          },
        },
        [
          _c(
            "div",
            { staticStyle: { "overflow-y": "scroll", "max-height": "860px" } },
            [
              _c(
                "el-form",
                {
                  ref: "saveSkuModelUpdateFormRef",
                  attrs: {
                    size: "mini",
                    inline: true,
                    model: _vm.skuModelUpdateDialog.form,
                  },
                },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "el-row",
                        { staticStyle: { width: "1100px" } },
                        [
                          _c(
                            "el-col",
                            [
                              _c("div", [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      "font-size": "16px",
                                      "font-weight": "bold",
                                    },
                                  },
                                  [_vm._v("识别到可能是以下商品,请选择:")]
                                ),
                              ]),
                              _vm._l(
                                _vm.skuModelUpdateOptions,
                                function (item) {
                                  return _c(
                                    "div",
                                    {
                                      key: item.skuModel,
                                      staticStyle: {
                                        display: "inline-block",
                                        "margin-top": "1%",
                                        "margin-left": "1%",
                                      },
                                      attrs: { label: item.skuModel },
                                    },
                                    [
                                      _c(
                                        "el-carousel",
                                        { attrs: { height: "200px" } },
                                        _vm._l(item.skuUrls, function (item1) {
                                          return _c(
                                            "el-carousel-item",
                                            { key: item1 },
                                            [
                                              _c("el-image", {
                                                attrs: {
                                                  "preview-src-list": [item1],
                                                  src: item1,
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        }),
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "button-group" },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              staticClass: "btn button-online",
                                              class: {
                                                selected:
                                                  _vm.skuModelUpdateDialog
                                                    .selectSkuModelButton ===
                                                  item.skuModel,
                                              },
                                              staticStyle: {
                                                width: "200px",
                                                "font-size": "10px",
                                                "margin-top": "1%",
                                              },
                                              attrs: {
                                                title: item.skuModelName,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.handSkuModelChange(
                                                    item
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(_vm._s(item.skuModel)),
                                              _c("br"),
                                              _vm._v(
                                                _vm._s(
                                                  "型号品名：" +
                                                    item.skuModelName
                                                )
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                }
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("div", [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          border: "1px dashed red",
                          "margin-top": "1%",
                          width: "1120px",
                        },
                      },
                      [
                        _c("el-form-item", {
                          staticClass: "redLabel",
                          staticStyle: {
                            "margin-top": "1%",
                            "margin-left": "2%",
                            color: "#ea2838",
                          },
                          attrs: { label: "最终型号" },
                        }),
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { "margin-top": "1%" },
                            attrs: {
                              label: "请从上方选择自动带入型号或下拉直接选择:",
                            },
                          },
                          [
                            _c("DictSelect", {
                              ref: "skuModel",
                              attrs: {
                                type: "sku_model",
                                value:
                                  _vm.skuModelUpdateDialog.form
                                    .skuModelByPerson,
                                dictFun: _vm.dictSkuModelsFun,
                                "init-props": {
                                  label: "skuModel",
                                  value: "skuModel",
                                },
                                remote: "",
                              },
                              on: {
                                "update:value": function ($event) {
                                  return _vm.$set(
                                    _vm.skuModelUpdateDialog.form,
                                    "skuModelByPerson",
                                    $event
                                  )
                                },
                                change: (val) =>
                                  _vm.handSkuModelInfoChange(val),
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "el-row",
                          [
                            _c("el-col", { attrs: { span: 12 } }, [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "inline-block",
                                    "margin-left": "30%",
                                    "margin-top": "1%",
                                    border: "1px dashed black",
                                    height: "350px",
                                  },
                                },
                                [
                                  _c(
                                    "el-carousel",
                                    {
                                      staticStyle: { width: "300px" },
                                      attrs: { height: "300px" },
                                    },
                                    _vm._l(
                                      _vm.skuModelUpdateDialog
                                        .selectSkuModelOptions,
                                      function (item) {
                                        return _c(
                                          "el-carousel-item",
                                          { key: item },
                                          [
                                            _c("el-image", {
                                              attrs: {
                                                "preview-src-list": [item],
                                                src: item,
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        width: "300px",
                                        height: "20px",
                                        "text-align": "center",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: { "font-size": "12px" },
                                        },
                                        [
                                          _vm._v("你最终选择匹配的商品图片"),
                                          _c("br"),
                                          _vm._v(
                                            _vm._s(
                                              "型号品名：" +
                                                _vm.skuModelUpdateDialog
                                                  .skuModelName
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _c("el-col", { attrs: { span: 12 } }, [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "inline-block",
                                    "margin-left": "10%",
                                    "margin-top": "1%",
                                    border: "1px dashed black",
                                    height: "350px",
                                  },
                                },
                                [
                                  _c(
                                    "el-carousel",
                                    {
                                      staticStyle: {
                                        width: "300px",
                                        "text-align": "center",
                                      },
                                      attrs: { height: "300px" },
                                    },
                                    _vm._l(
                                      [_vm.skuModelUpdateDialog.form.skuPic],
                                      function (item) {
                                        return _c(
                                          "el-carousel-item",
                                          { key: item },
                                          [
                                            _c("el-image", {
                                              staticStyle: { height: "300px" },
                                              attrs: {
                                                "preview-src-list": [item],
                                                src: item,
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        height: "20px",
                                        "text-align": "center",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: { "font-size": "12px" },
                                        },
                                        [
                                          _vm._v("店铺商品图片"),
                                          _c("br"),
                                          _vm._v(
                                            _vm._s(
                                              "型号品名：" +
                                                _vm.skuModelUpdateDialog
                                                  .shopSkuModelName
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("div", { staticStyle: { "margin-top": "1%" } }, [
                      _c("div", [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-size": "16px",
                              "font-weight": "bold",
                            },
                          },
                          [_vm._v("识别到规格,请确认或修改:")]
                        ),
                      ]),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            border: "1px dashed red",
                            "margin-top": "1%",
                            width: "1120px",
                          },
                        },
                        [
                          _c("el-form-item", {
                            staticClass: "redLabel",
                            staticStyle: {
                              "margin-top": "1%",
                              "margin-left": "2%",
                            },
                            attrs: { label: "最终规格" },
                          }),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { "margin-top": "1%" },
                              attrs: {
                                label: "识别到规格:",
                                prop: "skuSpecificationsBySystem",
                              },
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.skuModelUpdateDialog.form
                                      .skuSpecificationsBySystem
                                  )
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { "margin-top": "1%" },
                              attrs: {
                                label: "人工维护售卖规格",
                                prop: "skuSpecificationsByPerson",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  clearable: "",
                                  placeholder: "请输入规格",
                                },
                                model: {
                                  value:
                                    _vm.skuModelUpdateDialog.form
                                      .skuSpecificationsByPerson,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.skuModelUpdateDialog.form,
                                      "skuSpecificationsByPerson",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "skuModelUpdateDialog.form.skuSpecificationsByPerson",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { "margin-top": "1%" },
                              attrs: { label: "是否组装", prop: "ifCombined" },
                            },
                            [
                              _c("DictSelect", {
                                ref: "ifSpu",
                                staticStyle: { width: "500px" },
                                attrs: {
                                  type: "ifSpu",
                                  value:
                                    _vm.skuModelUpdateDialog.form.ifCombined,
                                  dictFun: _vm.dictSpuFun,
                                },
                                on: {
                                  "update:value": function ($event) {
                                    return _vm.$set(
                                      _vm.skuModelUpdateDialog.form,
                                      "ifCombined",
                                      $event
                                    )
                                  },
                                  getOtherJsonParam: (callback) =>
                                    callback({
                                      platform:
                                        _vm.skuModelUpdateDialog.form.ptName,
                                    }),
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  staticStyle: {
                    width: "90%",
                    "background-color": "white",
                    height: "30px",
                  },
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "inline-block",
                        float: "right",
                        "margin-top": "1%",
                      },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "mini" },
                          on: {
                            click: function ($event) {
                              _vm.skuModelUpdateDialog.visible = false
                            },
                          },
                        },
                        [_vm._v("取消")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "mini" },
                          on: { click: _vm.submitSkuModelEdit },
                        },
                        [_vm._v("确定")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "操作确认",
            visible: _vm.createTaskDialog.visible,
            "append-to-body": "",
            width: "1000px",
            top: "25vh",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.createTaskDialog, "visible", $event)
            },
          },
        },
        [
          _c("div", { staticStyle: { float: "right" } }, [
            _c(
              "span",
              {
                staticClass: "background-box",
                staticStyle: { "font-size": "15px" },
              },
              [_vm._v("受资源限制，最多存在2个任务")]
            ),
          ]),
          _c("div", { staticStyle: { "margin-top": "2%" } }, [
            _c(
              "span",
              { staticStyle: { "font-size": "15px", "font-weight": "bold" } },
              [_vm._v("请输入需要立刻采集的商品型号（可多选）")]
            ),
          ]),
          _c(
            "el-form",
            {
              ref: "createTaskFormRef",
              staticStyle: { "margin-top": "30px" },
              attrs: {
                size: "mini",
                "label-width": "auto",
                inline: true,
                model: _vm.createTaskDialog.form,
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品型号" } },
                    [
                      _c("DictSelect", {
                        attrs: {
                          type: "sku",
                          value: _vm.createTaskDialog.form.ecCargoNos,
                          dictFun: _vm.dictFun,
                          multiple: "",
                          remote: "",
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(
                              _vm.createTaskDialog.form,
                              "ecCargoNos",
                              $event
                            )
                          },
                          change: (val) => _vm.handTaskSkuModelChange(val),
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        display: "inline-block",
                        float: "none",
                        "line-height": "28px",
                      },
                    },
                    [_vm._v("最多选择十个型号")]
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "选择频次" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          attrs: { size: "mini" },
                          model: {
                            value: _vm.createTaskDialog.form.collectFrequency,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.createTaskDialog.form,
                                "collectFrequency",
                                $$v
                              )
                            },
                            expression:
                              "createTaskDialog.form.collectFrequency",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: "单次" } }),
                          _c("el-radio", { attrs: { label: "每天一次" } }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _vm.createTaskDialog.form.collectFrequency === "每小时一次" ||
                  _vm.createTaskDialog.form.collectFrequency === "每天一次"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "任务时间范围" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              size: "mini",
                              type: "daterange",
                              "range-separator": "至",
                              "start-placeholder": "开始日期",
                              "end-placeholder": "结束日期",
                              "value-format": "yyyy-MM-dd",
                              "picker-options": _vm.pickerOptions,
                            },
                            model: {
                              value: _vm.createTaskDialog.form.taskTimeRange,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.createTaskDialog.form,
                                  "taskTimeRange",
                                  $$v
                                )
                              },
                              expression: "createTaskDialog.form.taskTimeRange",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.createTaskDialog.form.collectFrequency === "每小时一次" ||
                  _vm.createTaskDialog.form.collectFrequency === "每天一次"
                    ? _c(
                        "span",
                        {
                          staticStyle: {
                            display: "inline-block",
                            float: "none",
                            "line-height": "28px",
                          },
                        },
                        [
                          _vm._v(
                            "选择每小时/每天一次时出现，即任务生效日期范围"
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _vm.createTaskDialog.form.collectFrequency === "每天一次"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "任务小时" } },
                        [
                          _c("el-time-select", {
                            attrs: {
                              "picker-options": {
                                start: "00:01",
                                step: "00:01",
                                end: "23:59",
                              },
                              editable: "",
                              placeholder: "选择时间",
                            },
                            model: {
                              value: _vm.createTaskDialog.form.executeTime,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.createTaskDialog.form,
                                  "executeTime",
                                  $$v
                                )
                              },
                              expression: "createTaskDialog.form.executeTime",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.createTaskDialog.form.collectFrequency === "每天一次"
                    ? _c(
                        "span",
                        {
                          staticStyle: {
                            display: "inline-block",
                            float: "none",
                            "line-height": "28px",
                          },
                        },
                        [_vm._v("选择每天一次时出现，即每天几点执行")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticStyle: { "margin-top": "5%" } }, [
            _c(
              "p",
              {
                staticStyle: {
                  "font-size": "12px",
                  "box-sizing": "border-box",
                  color: "#0B1523",
                },
              },
              [
                _vm._v("共选择了 "),
                _c("span", { staticStyle: { color: "#ea2838" } }, [
                  _vm._v(
                    _vm._s(
                      _vm.createTaskDialog.form.ecCargoNos !== undefined
                        ? _vm.createTaskDialog.form.ecCargoNos.length
                        : 0
                    )
                  ),
                ]),
                _vm._v("个 型号，匹配到 "),
                _c("span", { staticStyle: { color: "#ea2838" } }, [
                  _vm._v(_vm._s(_vm.createTaskDialog.matchSkuUrlCount)),
                ]),
                _vm._v(" 条需要更新的商品链接"),
              ]
            ),
          ]),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: { click: _vm.submitCreateSkuModelTask },
                },
                [_vm._v("确定")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.createTaskDialog.visible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "任务管理",
            visible: _vm.taskScheduleDialog.visible,
            "append-to-body": "",
            width: "1200px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.taskScheduleDialog, "visible", $event)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "taskScheduleFormRef",
              attrs: {
                size: "mini",
                "label-width": "auto",
                inline: true,
                model: _vm.taskScheduleDialog.form,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "商品型号" } },
                [
                  _c("DictSelect", {
                    attrs: {
                      type: "sku",
                      value: _vm.taskScheduleDialog.form.ecCargoNo,
                      dictFun: _vm.dictFun,
                      remote: "",
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(
                          _vm.taskScheduleDialog.form,
                          "ecCargoNo",
                          $event
                        )
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "form-btn-item",
                      attrs: {
                        size: "mini",
                        icon: "el-icon-search",
                        type: "primary",
                      },
                      on: { click: _vm.handleQueryTaskPages },
                    },
                    [_vm._v("查询")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              attrs: {
                data: _vm.taskScheduleDialog.taskData,
                size: "mini",
                border: "",
                "highlight-current-row": "",
                "header-cell-style": {
                  background: "rgb(227 224 224)",
                  color: "black",
                },
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "序号", prop: "id", width: "230" },
              }),
              _c("el-table-column", {
                attrs: { label: "采集型号清单", prop: "collectSkuModel" },
              }),
              _c("el-table-column", {
                attrs: { label: "采集频次", prop: "collectFrequency" },
              }),
              _c("el-table-column", {
                attrs: { label: "创建时间", prop: "createTime" },
              }),
              _c("el-table-column", {
                attrs: { label: "任务时间", prop: "taskTime", width: "150" },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "最后一次采集完成时间",
                  prop: "lastCollectFinishTime",
                  width: "150",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "最后一次采集数据量",
                  prop: "lastCollectFinishTime",
                  width: "150",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "今日状态", prop: "status" },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "任务管理",
                  align: "center",
                  "class-name": "small-padding fixed-width",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              type: "text",
                              icon: "el-icon-delete",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.deleteSkuModelTask(scope.row)
                              },
                            },
                          },
                          [_vm._v("删除任务")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "该型号当前控价",
            visible: _vm.priceControlListDialog.visible,
            "append-to-body": "",
            width: "1350px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.priceControlListDialog, "visible", $event)
            },
          },
        },
        [
          _c("div", [
            (_vm.priceControlListDialog.saleUnitKj === null ||
              _vm.priceControlListDialog.saleUnitKj === "") &&
            _vm.priceControlListDialog.form.controlPriceResult !==
              "不符合控价售卖规格"
              ? _c("p", { staticStyle: { "font-size": "16px" } }, [
                  _vm._v(" 该商品控价按零售价9折。零售价为 "),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        (
                          (_vm.priceControlListDialog.form
                            .discountMinUnitQuantity === 0 ||
                          _vm.priceControlListDialog.form
                            .discountMinUnitQuantity === null
                            ? 0
                            : (
                                _vm.priceControlListDialog.form
                                  .controlTotalPrice /
                                _vm.priceControlListDialog.form
                                  .discountMinUnitQuantity
                              ).toFixed(2)) / 0.9
                        ).toFixed(2)
                      )
                    ),
                  ]),
                  _vm._v(" 元，控价金额为 "),
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.priceControlListDialog.form
                          .discountMinUnitQuantity === 0 ||
                          _vm.priceControlListDialog.form
                            .discountMinUnitQuantity === null
                          ? 0
                          : (
                              _vm.priceControlListDialog.form
                                .controlTotalPrice /
                              _vm.priceControlListDialog.form
                                .discountMinUnitQuantity
                            ).toFixed(2)
                      )
                    ),
                  ]),
                  _vm._v(" 元 "),
                ])
              : _vm._e(),
          ]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.priceControlListDialog.form.controlPriceResult ===
                      "不符合控价售卖规格" ||
                    !(
                      _vm.priceControlListDialog.saleUnitKj === null ||
                      _vm.priceControlListDialog.saleUnitKj === ""
                    ),
                  expression:
                    "(priceControlListDialog.form.controlPriceResult === '不符合控价售卖规格') || !(priceControlListDialog.saleUnitKj === null || priceControlListDialog.saleUnitKj === '')",
                },
              ],
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.priceControlListDialog.form.ifCombined === "是" ||
                        _vm.priceControlListDialog.form.ifCombined === "否",
                      expression:
                        "priceControlListDialog.form.ifCombined === '是' || priceControlListDialog.form.ifCombined === '否'",
                    },
                  ],
                },
                [
                  _vm.priceControlListDialog.hitFlag !== undefined &&
                  _vm.priceControlListDialog.hitFlag === "N"
                    ? _c("p", [
                        _vm._v("当前售卖规格 "),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.priceControlListDialog
                                .finalMatchSkuSpecifications
                            )
                          ),
                        ]),
                        _vm._v(" 不符合控价售卖规格"),
                      ])
                    : _vm._e(),
                  _c(
                    "el-table",
                    {
                      ref: "priceControlListTable",
                      attrs: {
                        data: _vm.priceControlListDialog.priceControlList.rows,
                        size: "mini",
                        border: "",
                        "highlight-current-row": "",
                        "row-class-name": _vm.tableRowClassName,
                        "header-cell-style": {
                          background: "rgb(227 224 224)",
                          color: "black",
                        },
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: "平台",
                          prop: "ptName",
                          "min-width": "100",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "型号",
                          prop: "skuModel",
                          "min-width": "100",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "售卖规格单位",
                          prop: "saleUnit",
                          "min-width": "100",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "售卖规格单位是否最小单位",
                          prop: "ifMinUnit",
                          "min-width": "120",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "售卖规格下限",
                          prop: "saleUnitMin",
                          "min-width": "100",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "售卖规格上限",
                          prop: "saleUnitMax",
                          "min-width": "100",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "售卖单价",
                          prop: "unitPrice",
                          "min-width": "100",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "售卖总价",
                          prop: "totalPrice",
                          "min-width": "100",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "赠品",
                          prop: "gift",
                          "min-width": "100",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "操作",
                          "min-width": "120",
                          align: "center",
                          "class-name": "small-padding fixed-width",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      size: "mini",
                                      type: "text",
                                      icon: "el-icon-edit",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleSkuPriceControlEdit(
                                          row
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("编辑")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      size: "mini",
                                      type: "text",
                                      icon: "el-icon-delete",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleDeleteSkuPriceControl(
                                          row
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("删除")]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c("Pagination", {
                    attrs: {
                      total: _vm.priceControlListDialog.priceControlList.total,
                      page: _vm.priceControlListDialog.form.page,
                      limit: _vm.priceControlListDialog.form.limit,
                    },
                    on: {
                      "update:page": function ($event) {
                        return _vm.$set(
                          _vm.priceControlListDialog.form,
                          "page",
                          $event
                        )
                      },
                      "update:limit": function ($event) {
                        return _vm.$set(
                          _vm.priceControlListDialog.form,
                          "limit",
                          $event
                        )
                      },
                      pagination: _vm.handleQuerySkuModelPageList,
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.priceControlListDialog.form.ifCombined !== "是" &&
                        _vm.priceControlListDialog.form.ifCombined !== "否",
                      expression:
                        "priceControlListDialog.form.ifCombined !== '是' && priceControlListDialog.form.ifCombined !== '否'",
                    },
                  ],
                },
                [
                  _c(
                    "el-table",
                    {
                      ref: "spuPriceControlListTable",
                      attrs: {
                        data: _vm.priceControlListDialog.priceControlList.rows,
                        height: "calc(100vh - 250px)",
                        size: "mini",
                        border: "",
                        "highlight-current-row": "",
                        "header-cell-style": {
                          background: "rgb(235 235 235)",
                          color: "black",
                          textAlign: "center",
                          verticalAlign: "middle",
                        },
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: "组合装编码",
                          prop: "spuCode",
                          "min-width": "120",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "组合装名称",
                          prop: "spuName",
                          "min-width": "200",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "组合装型号",
                          prop: "skuModel",
                          "min-width": "100",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                _c(
                                  "el-row",
                                  _vm._l(
                                    row.skuModelInfos,
                                    function (item, index) {
                                      return _c("el-col", { key: index }, [
                                        _c("span", [
                                          _vm._v(_vm._s(item.skuModel)),
                                        ]),
                                      ])
                                    }
                                  ),
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "售卖规格单位",
                          prop: "skuSpecifications",
                          "min-width": "100",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                _c(
                                  "el-row",
                                  _vm._l(
                                    row.skuModelInfos,
                                    function (item, index) {
                                      return _c("el-col", { key: index }, [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(item.skuSpecifications)
                                          ),
                                        ]),
                                      ])
                                    }
                                  ),
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "规格含数量",
                          prop: "skuSpecificationsQuantity",
                          "min-width": "100",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                _c(
                                  "el-row",
                                  _vm._l(
                                    row.skuModelInfos,
                                    function (item, index) {
                                      return _c("el-col", { key: index }, [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              item.skuSpecificationsQuantity
                                            )
                                          ),
                                        ]),
                                      ])
                                    }
                                  ),
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "是否赠品",
                          prop: "ifGift",
                          "min-width": "100",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                _c(
                                  "el-row",
                                  _vm._l(
                                    row.skuModelInfos,
                                    function (item, index) {
                                      return _c("el-col", { key: index }, [
                                        _c("span", [
                                          _vm._v(_vm._s(item.ifGift)),
                                        ]),
                                      ])
                                    }
                                  ),
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c(
                        "el-table-column",
                        {
                          attrs: {
                            label: "各平台售卖总价",
                            prop: "spuSellPrice",
                            width: "100",
                          },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              label: "淘宝",
                              prop: "tbSpuSellPrice",
                              width: "100",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ row }) {
                                  return [
                                    _c("span", [
                                      _vm._v(_vm._s(row.tbSpuSellPrice)),
                                    ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "拼多多",
                              prop: "pddSpuSellPrice",
                              width: "100",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ row }) {
                                  return [
                                    _c("span", [
                                      _vm._v(_vm._s(row.pddSpuSellPrice)),
                                    ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "京东",
                              prop: "jdSpuSellPrice",
                              width: "100",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ row }) {
                                  return [
                                    _c("span", [
                                      _vm._v(_vm._s(row.jdSpuSellPrice)),
                                    ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: {
                              label: "抖音",
                              prop: "dySpuSellPrice",
                              width: "100",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ row }) {
                                  return [
                                    _c("span", [
                                      _vm._v(_vm._s(row.dySpuSellPrice)),
                                    ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                      _c("el-table-column", {
                        attrs: {
                          label: "操作",
                          "min-width": "120",
                          align: "center",
                          "class-name": "small-padding fixed-width",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      size: "mini",
                                      type: "text",
                                      icon: "el-icon-edit",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleSpuEdit(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("编辑")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      size: "mini",
                                      type: "text",
                                      icon: "el-icon-delete",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleDeleteSpu(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("删除")]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.skuPriceControlUpdateDialog.title,
            visible: _vm.skuPriceControlUpdateDialog.visible,
            width: "600px",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(
                _vm.skuPriceControlUpdateDialog,
                "visible",
                $event
              )
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "spuInfoUpdate",
              attrs: {
                size: "mini",
                "label-width": "100px",
                model: _vm.skuPriceControlUpdateDialog.form,
                rules: _vm.skuPriceControlUpdateDialog.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "平台", prop: "ptName" } },
                [
                  _c("DictSelect", {
                    ref: "platform",
                    attrs: {
                      disabled: "true",
                      type: "platform",
                      value: _vm.skuPriceControlUpdateDialog.form.ptName,
                      dictFun: _vm.dictPlatformFun,
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(
                          _vm.skuPriceControlUpdateDialog.form,
                          "ptName",
                          $event
                        )
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "型号", prop: "skuModel" } },
                [
                  _c("DictSelect", {
                    ref: "skuModel",
                    attrs: {
                      disabled: "true",
                      type: "sku_model",
                      value: _vm.skuPriceControlUpdateDialog.form.skuModel,
                      dictFun: _vm.dictSkuModelsFun,
                      "init-props": { label: "skuModel", value: "skuModel" },
                      remote: "",
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(
                          _vm.skuPriceControlUpdateDialog.form,
                          "skuModel",
                          $event
                        )
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "售卖规格单位", prop: "saleUnit" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入售卖规格单位" },
                    model: {
                      value: _vm.skuPriceControlUpdateDialog.form.saleUnit,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.skuPriceControlUpdateDialog.form,
                          "saleUnit",
                          $$v
                        )
                      },
                      expression: "skuPriceControlUpdateDialog.form.saleUnit",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "售卖规格单位是否最小单位",
                    "label-width": "180px",
                    prop: "ifMinUnit",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入售卖规格单位是否最小单位" },
                    model: {
                      value: _vm.skuPriceControlUpdateDialog.form.ifMinUnit,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.skuPriceControlUpdateDialog.form,
                          "ifMinUnit",
                          $$v
                        )
                      },
                      expression: "skuPriceControlUpdateDialog.form.ifMinUnit",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "售卖规格下限", prop: "saleUnitMin" } },
                [
                  _c("el-input-number", {
                    attrs: { clearable: "", placeholder: "请输入售卖规格下限" },
                    model: {
                      value: _vm.skuPriceControlUpdateDialog.form.saleUnitMin,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.skuPriceControlUpdateDialog.form,
                          "saleUnitMin",
                          $$v
                        )
                      },
                      expression:
                        "skuPriceControlUpdateDialog.form.saleUnitMin",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "售卖规格上限", prop: "saleUnitMax" } },
                [
                  _c("el-input-number", {
                    attrs: { clearable: "", placeholder: "请输入售卖规格上限" },
                    model: {
                      value: _vm.skuPriceControlUpdateDialog.form.saleUnitMax,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.skuPriceControlUpdateDialog.form,
                          "saleUnitMax",
                          $$v
                        )
                      },
                      expression:
                        "skuPriceControlUpdateDialog.form.saleUnitMax",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "售卖单价", prop: "unitPrice" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "请输入售卖单价" },
                    model: {
                      value: _vm.skuPriceControlUpdateDialog.form.unitPrice,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.skuPriceControlUpdateDialog.form,
                          "unitPrice",
                          $$v
                        )
                      },
                      expression: "skuPriceControlUpdateDialog.form.unitPrice",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "售卖总价", prop: "totalPrice" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "", placeholder: "请输入售卖总价" },
                    model: {
                      value: _vm.skuPriceControlUpdateDialog.form.totalPrice,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.skuPriceControlUpdateDialog.form,
                          "totalPrice",
                          $$v
                        )
                      },
                      expression: "skuPriceControlUpdateDialog.form.totalPrice",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "赠品" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入赠品" },
                    model: {
                      value: _vm.skuPriceControlUpdateDialog.form.gift,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.skuPriceControlUpdateDialog.form,
                          "gift",
                          $$v
                        )
                      },
                      expression: "skuPriceControlUpdateDialog.form.gift",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.skuPriceControlUpdateDialog.visible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: { click: _vm.submitSkuPriceControlEdit },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "spuInfoAddDialog",
          attrs: {
            title: "",
            visible: _vm.spuInfoUpdateDialog.visible,
            width: "1200px",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.spuInfoUpdateDialog, "visible", $event)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "spuInfoUpdate",
              attrs: {
                size: "mini",
                "label-width": "100px",
                model: _vm.spuInfoUpdateDialog.form,
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "组合装编码", prop: "spuCode" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "200px" },
                            attrs: { disabled: "true", clearable: "" },
                            model: {
                              value: _vm.spuInfoUpdateDialog.form.spuCode,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.spuInfoUpdateDialog.form,
                                  "spuCode",
                                  $$v
                                )
                              },
                              expression: "spuInfoUpdateDialog.form.spuCode",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "组合装名称", prop: "spuName" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "200px" },
                            attrs: {
                              clearable: "",
                              placeholder: "请输入组合装名称",
                            },
                            model: {
                              value: _vm.spuInfoUpdateDialog.form.spuName,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.spuInfoUpdateDialog.form,
                                  "spuName",
                                  $$v
                                )
                              },
                              expression: "spuInfoUpdateDialog.form.spuName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("spn", [
                _vm._v(
                  "请在下方配置各平台控价，如不填写具体值，代表该组合装不在该平台售卖："
                ),
              ]),
              _c(
                "el-row",
                { staticStyle: { "margin-top": "2%" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "淘宝总价" } },
                        [
                          _c("el-input-number", {
                            staticStyle: { width: "180px" },
                            attrs: {
                              clearable: "",
                              placeholder: "请输入淘宝总价",
                            },
                            model: {
                              value:
                                _vm.spuInfoUpdateDialog.form.tbSpuSellPrice,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.spuInfoUpdateDialog.form,
                                  "tbSpuSellPrice",
                                  $$v
                                )
                              },
                              expression:
                                "spuInfoUpdateDialog.form.tbSpuSellPrice",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "拼多多总价" } },
                        [
                          _c("el-input-number", {
                            staticStyle: { width: "180px" },
                            attrs: {
                              clearable: "",
                              placeholder: "请输入拼多多总价",
                            },
                            model: {
                              value:
                                _vm.spuInfoUpdateDialog.form.pddSpuSellPrice,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.spuInfoUpdateDialog.form,
                                  "pddSpuSellPrice",
                                  $$v
                                )
                              },
                              expression:
                                "spuInfoUpdateDialog.form.pddSpuSellPrice",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "京东总价" } },
                        [
                          _c("el-input-number", {
                            staticStyle: { width: "180px" },
                            attrs: {
                              clearable: "",
                              placeholder: "请输入京东总价",
                            },
                            model: {
                              value:
                                _vm.spuInfoUpdateDialog.form.jdSpuSellPrice,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.spuInfoUpdateDialog.form,
                                  "jdSpuSellPrice",
                                  $$v
                                )
                              },
                              expression:
                                "spuInfoUpdateDialog.form.jdSpuSellPrice",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "抖音总价" } },
                        [
                          _c("el-input-number", {
                            staticStyle: { width: "180px" },
                            attrs: {
                              clearable: "",
                              placeholder: "请输入抖音总价",
                            },
                            model: {
                              value:
                                _vm.spuInfoUpdateDialog.form.dySpuSellPrice,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.spuInfoUpdateDialog.form,
                                  "dySpuSellPrice",
                                  $$v
                                )
                              },
                              expression:
                                "spuInfoUpdateDialog.form.dySpuSellPrice",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              attrs: {
                data: _vm.spuInfoUpdateDialog.form.skuModelInfos,
                size: "mini",
                border: "",
                "highlight-current-row": "",
                "header-cell-style": {
                  background: "rgb(227 224 224)",
                  color: "black",
                },
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "型号", prop: "skuModel" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("DictSelect", {
                          ref: "skuModel",
                          attrs: {
                            type: "ec-cargo-no",
                            value: row.skuModel,
                            dictFun: _vm.dictSpuSkuModelsFun,
                            remote: "",
                          },
                          on: {
                            "update:value": function ($event) {
                              return _vm.$set(row, "skuModel", $event)
                            },
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "售卖规格单位", prop: "skuSpecifications" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入售卖规格单位",
                            size: "mini",
                          },
                          model: {
                            value: row.skuSpecifications,
                            callback: function ($$v) {
                              _vm.$set(row, "skuSpecifications", $$v)
                            },
                            expression: "row.skuSpecifications",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "规格含数量",
                  prop: "skuSpecificationsQuantity",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("el-input-number", {
                          attrs: {
                            clearable: "",
                            placeholder: "请输入规格含数量",
                          },
                          model: {
                            value: row.skuSpecificationsQuantity,
                            callback: function ($$v) {
                              _vm.$set(row, "skuSpecificationsQuantity", $$v)
                            },
                            expression: "row.skuSpecificationsQuantity",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "是否赠品", prop: "ifGift" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "el-select",
                          {
                            attrs: { size: "mini" },
                            model: {
                              value: row.ifGift,
                              callback: function ($$v) {
                                _vm.$set(row, "ifGift", $$v)
                              },
                              expression: "row.ifGift",
                            },
                          },
                          [
                            _c("el-option", {
                              attrs: { value: "是", label: "是" },
                            }),
                            _c("el-option", {
                              attrs: { value: "否", label: "否" },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  align: "center",
                  "class-name": "small-padding fixed-width",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              type: "text",
                              icon: "el-icon-delete",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.skuModelEditDeleteLine(
                                  scope.$index,
                                  scope.row
                                )
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "el-row",
            { staticClass: "mb8", attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 1.5 } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "text", icon: "Plus", size: "mini" },
                      on: { click: _vm.skuModelEditAddLine },
                    },
                    [_vm._v("新增型号")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.spuInfoUpdateDialog.visible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: { click: _vm.submitSpuInfoEdit },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }