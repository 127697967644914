<template>
  <div class="new-box">
    <div  class="mg-search">
      <el-card style="margin: 10px;">
        <SearchFilter @search="handleFilter"  @reset="resetQuery" @keyup.enter.native="handleQuery">
          <!-- <el-form ref="queryFormRef" size="mini" label-width="auto" :inline="true" :model="queryForm" @keyup.enter.native="handleQuery"> -->
          <el-form-item label="分公司">
            <DictSelect
              ref="org"
              type="org"
              :value.sync="queryForm.orgCode"
              linkageRef="dc,shop,responsible,area-type,shop-biz,shop-type,shop-capacity,exhibitStatus,businessAreaTypes"
              :dictFun="dictFun"
              fullLabel
              :defaultIndex="0"
              :clearable="queryTop && orgAllAuth ? true: false"
              :init="true"
              :init-options="this.options.orgOptions"
              @changeLinkageRef="handleChangeLinkageRef"
            />
          </el-form-item>
          <el-form-item label="配送中心">
            <DictSelect
              ref="dc"
              type="dc"
              :value.sync="queryForm.dcCode"
              linkageRef="province,responsible"
              :dictFun="dictFun"
              parentLinkageRef
              fullLabel
              multiple
              collapse-tags
              @getOtherJsonParam="(callback) => callback(dictParam)"
              @changeLinkageRef="handleChangeLinkageRef"
            />
          </el-form-item>
          <el-form-item label="省份">
            <DictSelect
              ref="province"
              type="province"
              :value.sync="queryForm.provinceCode"
              linkageRef="city"
              :dictFun="dictFun"
              parentLinkageRef
              @getOtherJsonParam="(callback) => callback(dictParam)"
              @changeLinkageRef="handleChangeLinkageRef"
            />
          </el-form-item>
          <el-form-item label="城市">
            <DictSelect
              ref="city"
              type="city"
              :value.sync="queryForm.cityCode"
              linkageRef="area"
              :dictFun="dictFun"
              parentLinkageRef
              @getOtherJsonParam="(callback) => callback(dictParam)"
              @changeLinkageRef="handleChangeLinkageRef"
            />
          </el-form-item>
          <el-form-item label="区县">
            <DictSelect
              ref="area"
              type="area"
              :value.sync="queryForm.areaCode"
              linkageRef="business"
              :dictFun="dictFun"
              parentLinkageRef
              @getOtherJsonParam="(callback) => callback(dictParam)"
              @changeLinkageRef="handleChangeLinkageRef"
            />
          </el-form-item>
          <el-form-item label="店铺">
            <!--          <DictSelect ref="shopCode" :value.sync="queryForm.shopCode" remote type="shopCode" :dictFun="dictSelect" />-->
            <DictSelect
              ref="shop"
              type="shop"
              :value.sync="queryForm.shopCode"
              :dictFun="dictFun"
              parentLinkageRef
              fullLabel
              remote
              :init-props="{ label: 'label', value: 'fullCode'}"
              @getOtherJsonParam="(callback) => callback(dictParam)"
            />
          </el-form-item>
          <el-form-item label="包干负责人">
            <DictSelect type="responsible" ref="responsible" :always-change="true" @getOtherJsonParam="(callback) => callback(dictParam)" :value.sync="queryForm.responsible" :dictFun="dictFun" parentLinkageRef />
          </el-form-item>
          <el-form-item label="商圈类型">
            <DictSelect ref="area-type" type="area-type" :value.sync="queryForm.businessAreaTypes" multiple singleProps parentLinkageRef @getOtherJsonParam="(callback) => callback({queryTop: queryTop})"  :dict-fun="basicDict"/>
          </el-form-item>
          <el-form-item label="经营类型">
            <DictSelect ref="businessAreaTypes" type="businessAreaTypes" :value.sync="queryForm.businessTypes" multiple singleProps parentLinkageRef @getOtherJsonParam="(callback) => callback({queryTop: queryTop})"  :dict-fun="basicDict"/>
          </el-form-item>
          <el-form-item label="店铺标签">
            <DictSelect ref="shop-capacity" type="shop-capacity" :value.sync="queryForm.shopCapacitys" multiple singleProps  parentLinkageRef @getOtherJsonParam="(callback) => callback({queryTop: queryTop})"  :dict-fun="basicDict"/>
          </el-form-item>
          <el-form-item label="门店类型">
            <DictSelect ref="shop-type" type="shop-type" :value.sync="queryForm.shopTypes" singleProps multiple parentLinkageRef  @getOtherJsonParam="(callback) => callback({queryTop: queryTop})" :dict-fun="basicDict" />
          </el-form-item>
          <el-form-item label="合作类型">
            <DictSelect ref="shop-biz" type="shop-biz" :value.sync="queryForm.shopBiz" singleProps parentLinkageRef @getOtherJsonParam="(callback) => callback({queryTop: queryTop})"  :dict-fun="basicDict"/>
          </el-form-item>
          <el-form-item label="陈列现状">
            <DictSelect ref="exhibitStatus" type="exhibitStatus" :value.sync="queryForm.exhibitStatuss" singleProps multiple parentLinkageRef @getOtherJsonParam="(callback) => callback({queryTop: queryTop})" :dict-fun="basicDict" />
          </el-form-item>
          <el-form-item label="重点店铺">
            <DictSelect ref="tag" type="pre-shop" :value.sync="queryForm.ifOptimumShop" init :init-options="options.defaultOptions"/>
          </el-form-item>
          <el-form-item label="店铺状态">
            <DictSelect  :value.sync="queryForm.lmStatus" init :init-options="options.statusOptions"/>
          </el-form-item>
        </SearchFilter>
      </el-card>
    </div>
    <el-card style="margin: 10px;" class="box-card">
      <el-button v-if="!queryForm.queryTop" class="form-btn-item" size="mini" icon="el-icon-upload" type="primary" @click="handleImport('common')">包干负责人导入</el-button>
      <el-button v-if="!queryForm.queryTop" class="form-btn-item" size="mini" icon="el-icon-upload" type="primary" @click="handleImport('address')">店铺地址导入</el-button>
      <el-button v-if="queryForm.queryTop" class="form-btn-item" size="mini" icon="el-icon-upload" type="primary" @click="handleImport('top')">重点店铺导入</el-button>
      <el-button class="form-btn-item" size="mini" icon="el-icon-download" type="primary" @click="handleExport">导出</el-button>
      <el-button v-if="queryForm.queryTop" class="form-btn-item" size="mini" icon="el-icon-download" type="primary" @click="handleExportSummary()">重点店铺统计导出</el-button>
      <span v-if="queryForm.queryTop" style="margin-left: 10px">仅显示重点终端清单</span>
      <el-table
        style="margin-top: 10px;"
        :data="pageData.rows"
        v-loading="loading"
        class="customer-table"
        border
        size="small"
        ref="shopTableRef"
        highlight-current-row
        @sort-change="handleSortChange"
        @selection-change="handleSelectionChange"
      >
        <el-table-column label="分公司编码/名称" prop="shop" width="140">
          <template #default="{ row }">
            <span>{{ row.orgCode + "-" + row.orgName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="所属配送中心" prop="dcName" width="180">
          <template #default="{ row }">
            <span>{{ row.dcCode + "-" + row.dcName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="店铺编码/名称" prop="shop" width="250">
          <template #default="{ row }">
            <span>{{ row.shopCode + "-" + row.shopName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="省份/城市" prop="provinceName" width="140">
          <template #default="{ row }">
            <span>{{ row.provinceName + "-" + row.cityName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="所属区县" prop="areaName" width="100" />
        <el-table-column label="所属商圈" prop="businessAreaName" width="100" />
        <el-table-column label="商圈类型" prop="businessAreaType" width="80" />
        <el-table-column label="经营类型" prop="businessType" width="150" />
        <el-table-column label="门店类型" prop="shopType" width="100" />
        <el-table-column label="店铺标签" prop="shopCapacity" width="100">
          <template #default="{ row }">
            <span>{{ row.shopCapacity }} </span> <span v-if="row.auditShopCapacity" style="color: red">({{ row.auditShopCapacity }})</span>
          </template>
        </el-table-column>
        <el-table-column label="陈列现状" prop="exhibitStatus" width="100" />
        <el-table-column label="合作类型" prop="shopBiz" width="100" />
        <el-table-column label="消费力" prop="consumeLevel" width="100" />
        <el-table-column label="包干负责人" prop="responsible" width="160">
          <template #default="{ row }">
            <span>{{ (row.userName? row.userName + "-" + row.nickName: '') }}</span>
          </template>
        </el-table-column>
        <el-table-column label="重点店铺" prop="ifOptimumShop" width="100">
          <template #default="{ row }">
            <span>{{ row.ifOptimumShop ==='1'? '是': '否' }}</span>
            <span v-if="row.auditIfOptimumShop" style="color: red">({{ row.auditIfOptimumShop ==='1'? '是': '否' }})</span>
          </template>
        </el-table-column>
        <el-table-column label="店铺状态" prop="lmStatus" width="120">
          <template #default="{ row }">
            <span>{{ row.lmStatus ? (row.lmStatus === '1'? '启用': '禁用'): '' }}</span>
          </template>
        </el-table-column>
        <el-table-column v-if="queryForm.queryTop" label="是否合并" prop="merged" width="120" />
        <el-table-column label="操作" width="100" align="center" fixed="right">
          <template #default="{ row }">
            <div class="button-class">
              <el-button size="mini" type="text" style="border: 0px;" @click="handleEdit(row)">{{ queryForm.queryTop? '查看': '编辑' }}</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <Pagination :total="pageData.total" :page.sync="queryForm.current" :limit.sync="queryForm.size" @pagination="handleQuery" />
    </el-card>
    <el-dialog title="店铺导入" :visible.sync="importDialog.visible" append-to-body width="400px">
      <el-upload
        ref="goodsUpload"
        class="upload-demo"
        drag
        :limit="1"
        action="#"
        :auto-upload="false"
        :file-list="importDialog.fileList"
        :on-change="(file, fileList) => handleFileChange(file, fileList, 'change')"
        :on-remove="(file, fileList) => handleFileChange(file, fileList, 'remove')"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">
          <span>仅允许导入xls、xlsx格式文件。</span>
          <el-link type="primary" :underline="false" style="font-size:12px;vertical-align: baseline;" target="_blank" :href="importDialog.template[importDialog.type]">下载模板</el-link>
          <div v-if="importDialog.type === 'top'" style="color: red">
            填写的配送中心编码和店铺编码均为合并前的编码
          </div>
          <div v-if="importDialog.type === 'address'" style="color: red">
            仅用于导入店铺地址变更
          </div>
        </div>
      </el-upload>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button type="primary" size="mini" :disabled="submitDisabled" @click="submitImport">确定</el-button>
        <el-button type="primary" size="mini" @click="importDialog.visible = false">取消</el-button>
      </div>
    </el-dialog>

    <el-dialog title="店铺更新" :visible.sync="editDialog.visible" append-to-body width="50%" :close-on-click-modal="false">
      <div style="margin-bottom: 10px;">
        合并后店铺数据: {{ editDialog.currentRow.poiCode + '/' + editDialog.currentRow.shopName }}
      </div>
      <el-tabs v-model="editDialog.tabName" type="card">
        <el-tab-pane v-for="item in editDialog.allStore" :name="item.originPoiCode" :key="item.originPoiCode" :label="item.originPoiCode + '/' + item.shopName">
          <el-form :ref="'editForm_' + item.originPoiCode" size="mini" label-width="110px" :inline="true" :model="item" :disabled="queryForm.queryTop!==undefined" :rules="editDialog.rules" class="common-form">
            <el-row :gutter="12" class="common-form-row">
              <el-col :span="8">
                <el-form-item label="分公司">
                  <DictSelect disabled :ref="'form-org_' + item.originPoiCode" type="org" :value.sync="item.orgCode" linkageRef="'form-dc_' + item.originPoiCode"
                              :dictFun="dictFun" fullLabel @changeLinkageRef="(ref, val, type) => handleChangeLinkageRef(ref, val, type, item, 'editFormDictParam')"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="配送中心">
                  <DictSelect disabled :ref="'form-dc_' + item.originPoiCode" type="dc" :value.sync="item.dcCode" :dictFun="dictFun" parentLinkageRef fullLabel
                              @getOtherJsonParam="(callback) => callback(editFormDictParam)" @changeLinkageRef="(ref, val, type) => handleChangeLinkageRef(ref, val, type, item, 'editFormDictParam')"
                  />
                </el-form-item>
              </el-col>

              <el-col :span="8">
                <el-form-item label="店铺编码">
                  <el-input disabled v-model="item.shopCode" placeholder="请输入" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="店铺名称">
                  <el-input v-model="item.shopName" placeholder="请输入" />
                </el-form-item>
              </el-col>
              <el-col v-if="item.ifMain === 1" :span="8">
                <el-form-item label="包干负责人">
                  <DictSelect
                    :ref="'user_' + item.originPoiCode"
                    type="user"
                    :value.sync="item.userName"
                    :dictFun="dictUserFun"
                    fullLabel
                    remote
                    version="v3"
                    placeholder="请输入账号或姓名"
                    :init-props="{ label: 'nickName', value: 'userName'}"
                    @getOtherJsonParam="(callback) => callback({ pageSize: 50, pageNum: 1})"
                    @change="val => handleRespChange(val, item)"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="商圈名称">
                  <el-input v-model="item.businessAreaName" placeholder="请输入" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="商圈类型">
                  <DictSelect ref="area-type" type="area-type" :value.sync="item.businessAreaType" singleProps @getOtherJsonParam="(callback) => callback({queryTop: queryTop, orgCode: item.orgCode})"  :dict-fun="basicDict"/>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="经营类型">
                  <DictSelect ref="businessAreaTypes" type="businessAreaTypes" :value.sync="item.businessType" singleProps @getOtherJsonParam="(callback) => callback({queryTop: queryTop, orgCode: item.orgCode})"  :dict-fun="basicDict"/>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="店铺标签">
                  <DictSelect ref="shop-capacity" type="shop-capacity" :value.sync="item.shopCapacity" :clearable="false" singleProps  @getOtherJsonParam="(callback) => callback({queryTop: queryTop, orgCode: item.orgCode})"  :dict-fun="basicDict"/>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="门店类型">
                  <DictSelect ref="shop-type" type="shop-type" :value.sync="item.shopType" singleProps  @getOtherJsonParam="(callback) => callback({queryTop: queryTop, orgCode: item.orgCode})" :dict-fun="basicDict" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="合作类型">
                  <DictSelect ref="shop-biz" type="shop-biz" :value.sync="item.shopBiz" singleProps @getOtherJsonParam="(callback) => callback({queryTop: queryTop, orgCode: item.orgCode})"  :dict-fun="basicDict"/>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="陈列现状">
                  <DictSelect ref="exhibitStatus" type="exhibitStatus" :value.sync="item.exhibitStatus" singleProps @getOtherJsonParam="(callback) => callback({queryTop: queryTop, orgCode: item.orgCode})" :dict-fun="basicDict" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="是否重点终端" prop="ifOptimumShop">
                  <DictSelect ref="tag" type="tag" :clearable="false" :value.sync="item.ifOptimumShop" init :init-options="options.defaultOptions"/>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="经度">
                  <el-input disabled v-model="item.longitude" placeholder="请输入" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="纬度">
                  <el-input disabled v-model="item.latitude" placeholder="请输入" />
                </el-form-item>
              </el-col>
              <el-col :span="16">
                <el-form-item label="店铺地址" label-width="90px" prop="address">
                  <el-input v-model="item.address" type="textarea" placeholder="请输入店铺具体地址，多个地址以;分隔" @input="item.addressChanged = true" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="年文具体量(万)">
                  <el-input-number v-model="item.xsVolume" :controls="false" :min="1" :precision="4" placeholder="请输入" />
                </el-form-item>
              </el-col>
              <el-col v-if="queryForm.queryTop && item.allStore && item.allStore.length > 1"  :span="12">
                <el-form-item label="合并前店铺数据" label-width="140px">
                  <div v-for="(item,index) in item.mergedShops.filter(e => e.originPoiCode!==item.originPoiCode)" :key="index">
                    <span>配送中心编码：</span><span>{{item.dcCode}}</span>
                    <span style="margin-left: 10px">店铺编码：</span><span>{{item.shopCode}}</span>
                  </div>
                </el-form-item>
              </el-col>
              <el-col  v-if="queryForm.queryTop && item.allStore && item.allStore.length > 1" :span="12">
                <el-form-item label="合并后店铺数据" label-width="140px">
                  <span>配送中心编码：</span><span>{{item.mDcCode}}</span>
                  <span style="margin-left: 10px">店铺编码：</span><span>{{item.mShopCode}}</span>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-tab-pane>
      </el-tabs>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button type="primary" size="mini" @click="editDialog.visible = false">取消</el-button>
        <el-button v-if="!queryForm.queryTop" :disabled="submitDisabled" type="primary" size="mini" @click="submitEdit">确定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="操作提示" :visible.sync="noticeDialog.visible" append-to-body width="700px">
      <div v-html="noticeDialog.msg" style="font-size: 16px;overflow-y: scroll;height: 400px">
      </div>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button v-if="noticeDialog.data && noticeDialog.data.length >0" type="primary" size="mini" @click="downloadImportResult">下载导入结果</el-button>
        <el-button type="primary" size="mini" @click="noticeDialog.visible = false">确定</el-button>
      </div>
    </el-dialog>

  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import { pageStores,  importStoreAddress, importStoreResp, importStoreTop, getStoreInfo, basicDict, topShopDict, updateStore, exportStore, userSelect, dictSelect, getMergedShops, exportStoreSummary, updateStoreResp } from "@/api/appTask";
import { parseTime, excelExport, deepClone } from "@/utils"
import { Notification } from "element-ui";
import SearchFilter from "@/components/SearchFilter/index";
import DictSelect from "@/components/DictSelect";

export default {
  name: "TopShop",
  components: { Pagination, DictSelect, SearchFilter},
  props: {
    queryTop: {
      type: String,
    },
  },
  data() {
    return {
      tabName: 'after',
      pageData: {
        total: 0,
        rows: [],
        selections: [],
      },
      basicDict: basicDict,
      queryForm: {
        current: 1,
        limit: 10,
        queryTop: this.queryTop,
        orgCode: undefined,
        provinceCode: undefined,
        cityCode: undefined,
        dcCode: undefined,
        areaCode: undefined,
        businessAreaName: undefined,
        shopLevel: undefined,
        shopGrade: undefined,
        shopBiz: undefined,
        shopName: undefined,
        potentiallevel: undefined,
        ifOptimumShop: this.queryTop?'1': undefined,
        shopCapacitys: undefined,
        businessAreaTypes: undefined,
        exhibitStatuss: undefined,
        shopType: undefined,
        businessTypes: undefined,
        responsible: undefined
      },
      lrDay: undefined,
      loading: false,
      submitDisabled: false,
      dictFun: topShopDict,
      dictUserFun: userSelect,
      dictSelect,
      dictParam: {},
      formDictParam: {},
      importDialog: {
        visible: false,
        type: 'common',
        fileList: [],
        accept: ['.xls', '.xlsx'],
        title: {
          common: '包干负责人导入',
          top: '重点店铺导入',
          address: '店铺地址修改导入',
        },
        template: {
          common: process.env.VUE_APP_OSS_TEMPLATE_PATH +'/%E5%BA%97%E9%93%BA%E5%8C%85%E5%B9%B2%E8%B4%9F%E8%B4%A3%E4%BA%BA%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx',
          top: process.env.VUE_APP_OSS_TEMPLATE_PATH +'/%E9%87%8D%E7%82%B9%E5%BA%97%E9%93%BA%E6%95%B0%E6%8D%AE%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx',
          address: process.env.VUE_APP_OSS_TEMPLATE_PATH + '%E5%BA%97%E9%93%BA%E5%9C%B0%E5%9D%80%E4%BF%AE%E6%94%B9%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx'
        }
      },
      editDialog: {
        visible: false,
        rules:{
          shopCapacity: [{ required: true, message: ' ', trigger: 'change' },],
          address: [{ required: true, message: ' ', trigger: 'change' },],
          ifOptimumShop: [{ required: true, message: ' ', trigger: 'change' },],
          shopBiz: [{ required: true, message: ' ', trigger: 'change' },],
          shopType: [{ required: true, message: ' ', trigger: 'change' },],
        },
        form: {
          poiCode: undefined,
          orgCode: undefined,
          orgName: undefined,
          dcCode: undefined,
          dcName: undefined,
          shopCode: undefined,
          shopName: undefined,
          provinceCode: undefined,
          provinceName: undefined,
          cityCode: undefined,
          cityName: undefined,
          areaCode: undefined,
          areaName: undefined,
          address: undefined,
          longitude: undefined,
          latitude: undefined,
          mDcCode: undefined,
          mShopCode: undefined,
          xsVolume: undefined,
          businessAreaName: undefined,
          businessAreaType: undefined,
          businessType: undefined,
          shopType: undefined,
          shopCapacity: undefined,
          exhibitStatus: undefined,
          shopBiz: undefined,
          consumeLevel: undefined,
          responsible: undefined,
          ifOptimumShop: undefined,
          createBy: undefined,
          createTime: undefined,
          updateBy: undefined,
          updateTime: undefined,
          userName: undefined,
          nickName: undefined,
          addressChanged: false
        },
        currentRow: {},
        allStore: []
      },
      editFormDictParam: {},
      noticeDialog: {
        visible: false,
        msg: undefined,
        data: undefined
      },
      currentRow: {},
      options: {
        defaultOptions: [{ value: '1', label: '是' }, { value: '0', label: '否'}],
        statusOptions: [{ value: '1', label: '启用' }, { value: '0', label: '禁用'}],
        businessAreaTypes: [],
        consumeLevels: ['高', '低', '空值'],
        businessTypes: [],
        shopTypes: [],
        shopBizs:[],
        shopCapacitys: [],
        exhibitStatus: [],
        responsibles: [],
        orgOptions: []
      },
      optionMap: {},
      orgAllAuth: true,
      respHeaders: {
        dcCode: '配送中心编码',
        shopCode: '门店编码',
        userName: '包干负责人(聚宝盆数字账号)',
        errorMsg: '校验信息',
        warningMsg: '提示信息',
      },
      addressHeaders: {
        dcCode: '配送中心编码',
        shopCode: '门店编码',
        address: '地址',
        errorMsg: '校验信息',
        warningMsg: '提示信息',
      },
      shopHeaders: {
        orgCode: '分公司编码',
        dcCode: '配送中心编码',
        shopCode: '门店编码',
        provinceName: '省份名称',
        cityName: '城市名称',
        areaName: '区名',
        address: '地址',
        mDcCode: '主配送中心编码',
        mShopCode: '主终端编码',
        xsVolume: '年文具体量(万)',
        businessAreaName: '商圈名称',
        businessAreaType: '商圈类型',
        businessType: '经营类型',
        shopType: '门店类型',
        shopCapacity: '终端容量标签',
        exhibitStatus: '陈列现状',
        shopBiz: '店铺类型',
        consumeLevel: '消费力',
        responsible: '包干责任人',
        userName: '包干负责人(聚宝盆数字账号)',
        ifOptimumShop: '是否重点终端',
        errorMsg: '校验信息'
      },
      respDialog: {
        visible: false,
        dcs: [],
        form: {
          dcCode: undefined,
          userName: undefined,
        },
        rules:{
          dcCode: [{ required: true, message: ' ', trigger: 'change' },],
          userName: [{ required: true, message: ' ', trigger: 'change' },],
        },
      }
    };
  },
  watch: {
  },
  created() {
    // basicDict('?queryTop=' + this.queryForm.queryTop).then(res => {
    //   Object.assign(this.options, res.data);
    //   this.optionMap[this.tabName] =  Object.assign(this.options, res.data);
    //   this.$forceUpdate();
    // })
    dictSelect('ht-org-all').then(res => {
      this.orgAllAuth = res.data.filter(e => e.value === 'ALL').length > 0 ? true: false;
      this.options.orgOptions = res.data.filter(e => e.value !== 'ALL');
      this.queryForm.orgCode = this.options.orgOptions[0].value;
      this.handleFilter();
    })
  },
  mounted() {

  },
  methods: {
    handleDateChange(val) {
      if (val) {
        this.queryForm.lrDayBegin = parseTime(val[0], "{y}-{m}-{d}");
        this.queryForm.lrDayEnd = parseTime(val[1], "{y}-{m}-{d}");
      } else {
        this.queryForm.lrDayBegin = undefined;
        this.queryForm.lrDayEnd = undefined;
      }
    },
    setQueryShop(query) {
      if (this.queryForm.dcCode) {
        query.dcCode = this.queryForm.dcCode.join(',')
      }
      if (this.queryForm.shopCode) {
        const shop = this.$refs.shop.itemOptions.find(e => e.fullCode === this.queryForm.shopCode);
        query.shopCode = undefined;
        if (shop) {
          query.shopCode = shop.value;
          query.dcCode = shop.fullCode.replace("_" +query.shopCode, "");
        }
      }
    },
    handleQuery() {
      this.loading = true;
      let query = Object.assign({}, this.queryForm);
      this.setQueryShop(query);
      pageStores(query).then(res => {
        if (res.code !== 200) {
          return false;
        }
        this.loading = false;
        this.pageData.rows = res.data?.records;
        this.pageData.total = res.data?.total;
        this.$refs.shopTableRef.doLayout();
      });
    },
    handleFilter() {
      this.queryForm.page = 1;
      this.handleQuery();
    },
    resetQuery() {
      const queryForm = {
        page: 1,
        limit: 10,
        queryTop: this.queryTop,
        orgCode: undefined,
        provinceCode: undefined,
        cityCode: undefined,
        dcCode: undefined,
        areaCode: undefined,
        businessAreaName: undefined,
        shopLevel: undefined,
        shopGrade: undefined,
        shopBiz: undefined,
        shopName: undefined,
        potentiallevel: undefined,
        ifOptimumShop: this.queryTop?'1': undefined,
        shopCapacitys: undefined,
        businessAreaTypes: undefined,
        exhibitStatuss: undefined,
        shopType: undefined,
        businessTypes: undefined,
        responsible: undefined
      };
      queryForm.orgCode = this.options.orgOptions && this.options.orgOptions.length > 0 ? this.options.orgOptions[0].value: undefined;
      this.queryForm = Object.assign({}, queryForm);
      this.$forceUpdate();
    },
    handleSortChange(data) {
      const { prop, order } = data;
      if (prop && order) {
        this.queryForm.sorts = order === "ascending" ? prop + " asc" : prop + " desc";
      } else {
        this.queryForm.sorts = undefined;
      }
      this.handleFilter();
    },
    handleChangeLinkageRef(ref, val, type, form, dict) {
      this.$nextTick(() => {
        ref.split(',').forEach(e => {
          if (this.$refs[e]) {
            if (form && dict) {
              let tmp = Object.assign({}, form);
              for(const key in this[dict]) {
                this[dict][key] = tmp[key];
              }
            } else {
              this.dictParam = Object.assign({}, this.queryForm)
            }
            setTimeout(() => {
              if (this.$refs[e] && this.$refs[e] instanceof Array) {
                this.$refs[e][0].handleRefChange(val, type);
              } else {
                this.$refs[e].handleRefChange(val, type);
              }
            }, 200)
            this.$forceUpdate();
          } else {
            this.dictParam = Object.assign({}, this.queryForm)
          }
        })
      });
    },
    handleRespChange(val, item) {
      this.$nextTick(() => {
        let user = this.$refs['user_' + this.editDialog.form.originPoiCode][0].itemOptions.find(e => e.userName === val);
        user = user? user: {nickName: '', userName: ''}
        item.responsible = user.nickName;
        item.userName = user.userName;
        item.nickName = user.nickName;
      });
    },
    handleSelectionChange(selections) {
      this.pageData.selections = selections;
    },
    handleImport(type) {
      this.importDialog.type = type;
      this.importDialog.visible = true;
      this.submitDisabled = false;
    },
    handleFileChange(file, fileList, type) {
      if (type === 'remove') {
        this.importDialog.fileList = fileList;
        return false;
      }
      const suffix =
        file.name.indexOf(".") > 0
          ? file.name.substring(file.name.lastIndexOf("."))
          : undefined;
      if (this.importDialog.accept.indexOf(suffix) < 0) {
        this.$message.error("文件格式不支持");
        this.importDialog.fileList = [];
      } else {
        this.importDialog.fileList = fileList;
      }
    },
    submitImport() {
      if (this.importDialog.fileList.length === 0) {
        Notification.warning('请先上传文件！');
        return false;
      }
      let fd = new FormData();
      this.importDialog.fileList.forEach(item=>{
        //文件信息中raw才是真的文件
        fd.append("file",item.raw);
      })
      this.loading = true;
      let fun = importStoreResp;
      if (this.importDialog.type === 'top') {
        fun = importStoreTop;
      } else if (this.importDialog.type === 'address') {
        fun = importStoreAddress
      }
      this.submitDisabled = true;
      this.noticeDialog.data = [];
      fun(fd).then((res) => {
        this.submitDisabled = false;
        let errList = res.data.filter(e => e.errorMsg)
        let warningMsgList = res.data.filter(e => e.warningMsg)
        res.data.forEach(e => {
          if (e.ifOptimumShop === '1') {
            e.ifOptimumShop='是'
          } else if (e.ifOptimumShop === '0') {
            e.ifOptimumShop='否'
          }
        })
        this.noticeDialog.data = res.data;
        let msg;
        // 拼接错误信息
        if (errList.length > 0) {
          msg = "很抱歉操作失败,共" + res.data.length+"条,失败" + errList.length+ "条,具体如下：</br>"
          errList.forEach(e => msg+=  e.errorMsg + "</br>")
          this.noticeDialog.msg = msg;
        } else {
          msg = "操作成功,共" + res.data.length+"条!"
          let mergeShopMsg = "";
          res.data.forEach(s => {
            if (s.ifChangeTop && s.mergedShops && s.mergedShops.length > 0) {
              let shopMsg = "</br>合并后店铺：配送中心编码:" + s.virDcCode + " 店铺编码:" + s.virShopCode + ",合并店铺：";
              s.mergedShops.forEach(m => {
                shopMsg += "</br>配送中心编码:" + m.dcCode + " 店铺编码:" + m.shopCode + ";";
              })
              mergeShopMsg+=shopMsg;
            }
          })
          if (mergeShopMsg!='') {
            msg += "</br>温馨提示：以下店铺存在合并店铺，将合并后店铺改为非重点，需要将合并店铺同时改为非重点店铺，具体店铺如下：" + mergeShopMsg
          }

          // 拼接告警信息
          if (warningMsgList.length > 0) {
            msg += "</br></br>下列店铺当前是禁用状态，只有状态正常后，才能在聚宝盆上看到店铺信息：</br>"
            warningMsgList.filter(e => e.warningMsg.includes('店铺状态禁用')).forEach(e => msg +=  e.warningMsg.replace(";店铺状态禁用","") + "</br>")
          }

          this.noticeDialog.msg = msg;
          this.importDialog.visible = false;
          this.handleFilter();
        }
        this.noticeDialog.visible = true;
        this.loading = false;
        this.$refs.goodsUpload.clearFiles();
      })
    },
    handleEdit(row) {
      this.editFormDictParam = {
        orgCode: row.orgCode,
        dcCode: row.dcCode,
        provinceCode: row.provinceCode,
        cityCode: row.cityCode
      }
      this.editDialog.currentRow = deepClone(row)
      getStoreInfo(row.poiCode, this.queryTop).then(res => {
        if (!res.data || res.data.length === 0) {
          Notification.error('未查询到店铺信息');
          return false;
        }
        res.data.forEach(e => {
          e.importDcCode = e.dcCode;
          e.importShopCode = e.shopCode;
        })
        this.editDialog.allStore = res.data;
        if (!this.queryForm.queryTop) {
          this.editDialog.form = Object.assign({}, res.data.find(e => e.ifMain === 1));
        } else {
          this.editDialog.form = Object.assign({}, res.data[0]);
        }
        this.editDialog.tabName = this.editDialog.form.originPoiCode
        this.editDialog.form.addressChanged = false;
        const resp = this.editDialog.form.userName;
        this.$nextTick(() => {
          if (this.$refs['user_' + this.editDialog.form.originPoiCode]) {
            new Promise(async (resolve, reject) => {
              await this.$refs['user_' + this.editDialog.form.originPoiCode][0].remoteMethod(resp)
              resolve()
            }).then(() => {
              this.editDialog.form.responsible = row.nickName;
              this.editDialog.form.userName = row.userName;
              this.editDialog.form.nickName = row.nickName;
              this.editDialog.allStore.forEach(e => {
                if (e.originPoiCode === this.editDialog.form.originPoiCode) {
                  e.responsible = row.nickName;
                  e.userName = row.userName;
                  e.nickName = row.nickName;
                }
              })
              this.$forceUpdate();
            })
          }
        })
        this.editDialog.visible = true;
      })
      this.submitDisabled = false;
    },
    submitEdit() {
      this.editDialog.allStore.forEach(e => {
        this.$refs['editForm_' + e.originPoiCode][0].validate(valid => {
          if (valid) {
            this.submitDisabled = true;
            updateStore(e).then(res => {
              if (res && res.msg.indexOf("很抱歉") > -1) {
                this.noticeDialog.msg = res.msg;
                this.noticeDialog.visible = true;
              } else if (res.code===200) {
                if (res.msg !== '操作成功') {
                  this.noticeDialog.msg = res.msg;
                  this.noticeDialog.visible = true;
                }
                this.$message.success("操作成功！");
                this.editDialog.visible = false
                this.handleFilter();
              }
              this.submitDisabled = false;
            })
          }
        })
      })
    },
    handleExport() {
      this.loading = true;
      let param = Object.assign({}, this.queryForm, {size: 100000});
      this.setQueryShop(param);
      exportStore(param).then(res => {
        this.loading = false;
        if (!res) {
          return
        }
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = window.URL.createObjectURL(new Blob([res]))
        link.setAttribute('download', this.queryForm.queryTop ? '店铺导出（合并前）.xlsx':'合并后店铺导出.xlsx')
        document.body.appendChild(link)
        link.click()
        link.remove()
      })
    },
    handleExportSummary() {
      this.loading = true;
      exportStoreSummary().then(res => {
        this.loading = false;
        if (!res) {
          return
        }
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = window.URL.createObjectURL(new Blob([res]))
        link.setAttribute('download', '重点店铺统计导出.xlsx')
        document.body.appendChild(link)
        link.click()
        link.remove()
      })
    },
    downloadImportResult() {
      let headers = this.respHeaders;
      let title = '包干负责人导入明细';
      if (this.importDialog.type === 'top') {
        headers = this.shopHeaders;
        title = '重点店铺导入明细';
      } else if (this.importDialog.type === 'address') {
        headers = this.addressHeaders;
        title = '店铺地址修改导出明细';
      }
      excelExport({
        topHeaders: headers,
        data: this.noticeDialog.data,
        title: title,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
// ::v-deep .el-dialog__body {
//   padding-top: 10px;
//   padding-bottom: 5px;
// }

// ::v-deep .el-input__inner {
//   padding-left: 5px !important;
//   //max-width: 200px !important;
// }
.checkNumber {
  font-size: 16px;
  margin-bottom: 5px;
}

// ::v-deep {
//   .icon-check-class {
//     color: blue;
//     cursor: pointer;
//     font-size: 18px !important;
//   }
//   .icon-close-class {
//     color: gray;
//     cursor: pointer;
//     font-size: 18px !important;
//   }
// }

</style>

<!-- <style lang="scss" scoped src='@/style/common/index.scss'></style> -->
