var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.fullscreen.lock",
          value: _vm.homeLoading,
          expression: "homeLoading",
          modifiers: { fullscreen: true, lock: true },
        },
      ],
      staticStyle: { padding: "20px" },
    },
    [
      _vm.componentType === "JL"
        ? _c(
            "div",
            [
              _c(
                "el-tabs",
                {
                  model: {
                    value: _vm.tabName,
                    callback: function ($$v) {
                      _vm.tabName = $$v
                    },
                    expression: "tabName",
                  },
                },
                [
                  _c("el-tab-pane", {
                    attrs: { label: "业务激励列表", name: "salesman" },
                  }),
                  _c("el-tab-pane", {
                    attrs: { label: "终端激励列表", name: "shop" },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "mg-search",
                  staticStyle: { "padding-bottom": "15px" },
                },
                [
                  _c(
                    "el-form",
                    {
                      ref: "queryFormRef",
                      attrs: {
                        size: "mini",
                        "label-width": "auto",
                        inline: true,
                        model: _vm.queryForm,
                      },
                      nativeOn: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.handleQuery.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "form-btn-item",
                              attrs: { size: "mini", type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleEdit()
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.tabName === "salesman"
                                    ? "创建业务奖励"
                                    : "创建终端奖励"
                                )
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  ref: "table",
                  attrs: {
                    data: _vm.pageData.rows,
                    height: "calc(100vh - 250px)",
                    size: "mini",
                    border: "",
                    "highlight-current-row": "",
                    "header-cell-style": {
                      background: "rgb(235 235 235)",
                      color: "black",
                      textAlign: "center",
                      verticalAlign: "middle",
                    },
                  },
                  on: {
                    "sort-change": _vm.handleSortChange,
                    "selection-change": _vm.handleSelectionChange,
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "ID", prop: "id", width: "50" },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "奖励标题", prop: "name", width: "300" },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "创建者", prop: "createUser", width: "80" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "类型",
                      prop: "businessType",
                      width: "120",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    row.boostTarget === "shop"
                                      ? "店铺激励"
                                      : "业务激励"
                                  )
                                ),
                              ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1540947066
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "编辑时间",
                      prop: "createTime",
                      width: "160",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "操作", width: "100", align: "center" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ row }) {
                            return [
                              _c(
                                "div",
                                { staticClass: "button-class" },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: { border: "0px" },
                                      attrs: { size: "mini", type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleEdit(row)
                                        },
                                      },
                                    },
                                    [_vm._v("编辑")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: { border: "0px" },
                                      attrs: { size: "mini", type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleDelBoost(row)
                                        },
                                      },
                                    },
                                    [_vm._v("删除")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3186504263
                    ),
                  }),
                ],
                1
              ),
              _c("Pagination", {
                attrs: {
                  total: _vm.pageData.total,
                  page: _vm.queryForm.current,
                  limit: _vm.queryForm.size,
                },
                on: {
                  "update:page": function ($event) {
                    return _vm.$set(_vm.queryForm, "current", $event)
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.queryForm, "size", $event)
                  },
                  pagination: _vm.handleQuery,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-drawer",
        {
          attrs: {
            visible: _vm.jlDrawer.visible,
            "append-to-body": "",
            size: "70%",
            direction: "rtl",
            "close-on-click-modal": false,
            wrapperClosable: false,
            "show-close": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.jlDrawer, "visible", $event)
            },
          },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [
                  _c(
                    "span",
                    {
                      attrs: {
                        role: "heading",
                        title: _vm.jlDrawer.title[_vm.jlDrawer.type],
                      },
                    },
                    [_vm._v(_vm._s(_vm.jlDrawer.title[_vm.jlDrawer.type]))]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "el-drawer__close-btn",
                      attrs: { type: "button" },
                      on: { click: _vm.handleCloseDrawer },
                    },
                    [
                      _c("i", {
                        staticClass: "el-dialog__close el-icon el-icon-close",
                      }),
                    ]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            "el-form",
            {
              ref: "jlFormRef",
              staticClass: "common-form",
              attrs: {
                size: "mini",
                "label-width": "120px",
                inline: true,
                model: _vm.jlDrawer.form,
                rules: _vm.jlDrawer.rules,
              },
            },
            [
              _c(
                "el-row",
                { staticClass: "common-form-row", attrs: { gutter: 12 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "奖励名称", prop: "name" } },
                        [
                          _c("el-input", {
                            attrs: {
                              "show-word-limit": "",
                              placeholder: "6个字以内，例如“必备佳装“",
                            },
                            model: {
                              value: _vm.jlDrawer.form.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.jlDrawer.form, "name", $$v)
                              },
                              expression: "jlDrawer.form.name",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 18 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "奖励描述", prop: "description" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入奖励描述" },
                            model: {
                              value: _vm.jlDrawer.form.description,
                              callback: function ($$v) {
                                _vm.$set(_vm.jlDrawer.form, "description", $$v)
                              },
                              expression: "jlDrawer.form.description",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "radio-class",
                          staticStyle: { "padding-right": "22px" },
                          attrs: { label: "店铺范围" },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              on: {
                                input: (val) => {
                                  _vm.jlDrawer.form.shopCapacities = []
                                  _vm.jlDrawer.form.shopLevels = []
                                },
                              },
                              model: {
                                value: _vm.jlDrawer.form.allShops,
                                callback: function ($$v) {
                                  _vm.$set(_vm.jlDrawer.form, "allShops", $$v)
                                },
                                expression: "jlDrawer.form.allShops",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: "2" } }, [
                                _vm._v("全部任务店铺"),
                              ]),
                              _c("el-radio", { attrs: { label: "3" } }, [
                                _vm._v("指定类型店铺"),
                              ]),
                              _vm.jlDrawer.form.allShops === "3"
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: { display: "inline-block" },
                                    },
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "120px" },
                                          attrs: {
                                            size: "mini",
                                            multiple: "",
                                            clearable: "",
                                            filterable: "",
                                            placeholder: "店铺类型（多选）",
                                          },
                                          model: {
                                            value:
                                              _vm.jlDrawer.form.shopCapacities,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.jlDrawer.form,
                                                "shopCapacities",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "jlDrawer.form.shopCapacities",
                                          },
                                        },
                                        _vm._l(
                                          _vm.options.capacityOptions,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item,
                                              attrs: {
                                                value: item,
                                                label: item,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "120px" },
                                          attrs: {
                                            size: "mini",
                                            multiple: "",
                                            clearable: "",
                                            filterable: "",
                                            placeholder: "店铺等级（多选）",
                                          },
                                          model: {
                                            value: _vm.jlDrawer.form.shopLevels,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.jlDrawer.form,
                                                "shopLevels",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "jlDrawer.form.shopLevels",
                                          },
                                        },
                                        _vm._l(
                                          _vm.options.shopLevelOptions,
                                          function (item) {
                                            return _c("el-option", {
                                              key: item,
                                              attrs: {
                                                value: item,
                                                label: item,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "radio-class",
                          staticStyle: { "padding-right": "22px" },
                          attrs: { label: "任务时间" },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              on: { input: (val) => {} },
                              model: {
                                value: _vm.jlDrawer.form.taskTime,
                                callback: function ($$v) {
                                  _vm.$set(_vm.jlDrawer.form, "taskTime", $$v)
                                },
                                expression: "jlDrawer.form.taskTime",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: "1" } }, [
                                _vm._v("与主任务一致"),
                              ]),
                              _c("el-radio", { attrs: { label: "2" } }, [
                                _vm._v("自定义"),
                              ]),
                              _vm.jlDrawer.form.taskTime === "2"
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: { display: "inline-block" },
                                    },
                                    [
                                      _c("el-date-picker", {
                                        attrs: {
                                          type: "daterange",
                                          "start-placeholder": "开始时间",
                                          "end-placeholder": "结束时间",
                                          format: "yyyy-MM-dd",
                                          "default-time": [
                                            "00:00:00",
                                            "23:59:59",
                                          ],
                                        },
                                        on: {
                                          change: (val) =>
                                            _vm.handleDateChange(val),
                                        },
                                        model: {
                                          value: _vm.jlDrawer.form.taskDate,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.jlDrawer.form,
                                              "taskDate",
                                              $$v
                                            )
                                          },
                                          expression: "jlDrawer.form.taskDate",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "radio-class",
                          staticStyle: { "padding-right": "22px" },
                          attrs: { label: "是否核销审核" },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.jlDrawer.form.ifNeedAudit,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.jlDrawer.form,
                                    "ifNeedAudit",
                                    $$v
                                  )
                                },
                                expression: "jlDrawer.form.ifNeedAudit",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: "N" } }, [
                                _vm._v("否"),
                              ]),
                              _c("el-radio", { attrs: { label: "Y" } }, [
                                _vm._v("是"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "radio-class",
                          staticStyle: { "padding-right": "22px" },
                          attrs: { label: "是否核销拍照" },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.jlDrawer.form.ifNeedPicture,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.jlDrawer.form,
                                    "ifNeedPicture",
                                    $$v
                                  )
                                },
                                expression: "jlDrawer.form.ifNeedPicture",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: "N" } }, [
                                _vm._v("否"),
                              ]),
                              _c("el-radio", { attrs: { label: "Y" } }, [
                                _vm._v("是"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "radio-class",
                          staticStyle: { "padding-right": "22px" },
                          attrs: { label: "排除店铺范围" },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { size: "mini", type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleAddExcludeShop()
                                },
                              },
                            },
                            [_vm._v("选择排除店铺范围")]
                          ),
                          _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.jlDrawer.form.excludeShops.length) +
                                " "
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "奖励类型" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "120px" },
                              attrs: {
                                size: "mini",
                                placeholder: "请选择（单选）",
                              },
                              on: {
                                change: (val) => {
                                  if (val !== "none") {
                                    _vm.jlDrawer.form.rules = [
                                      this.getDefaultRules(val),
                                    ]
                                  } else {
                                    _vm.jlDrawer.form.rules = []
                                  }
                                  _vm.form.roofOfShop = undefined
                                },
                              },
                              model: {
                                value: _vm.jlDrawer.form.boostType,
                                callback: function ($$v) {
                                  _vm.$set(_vm.jlDrawer.form, "boostType", $$v)
                                },
                                expression: "jlDrawer.form.boostType",
                              },
                            },
                            _vm._l(
                              _vm.computeBoostTypes("target"),
                              function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    value: item.value,
                                    label: item.label,
                                  },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.jlDrawer.form.boostType !== "none" &&
                  _vm.jlDrawer.form.rules &&
                  _vm.jlDrawer.form.rules.length > 0
                    ? _c(
                        "el-col",
                        [
                          _c("div", { staticClass: "mid-title" }, [
                            _vm._v("奖励条件设置"),
                          ]),
                          _vm._l(
                            _vm.jlDrawer.form.rules,
                            function (item, index) {
                              return _c(
                                "el-row",
                                {
                                  key: index,
                                  staticStyle: { "margin-top": "20px" },
                                },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 23 } },
                                    [
                                      _vm.jlDrawer.form.boostType === "grad"
                                        ? _c(
                                            "el-row",
                                            {
                                              staticStyle: {
                                                "margin-left": "5%",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "min-desc" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      "奖励档位" + (index + 1)
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "el-row",
                                        {
                                          staticStyle: {
                                            border: "1px solid",
                                            "margin-left": "5%",
                                            "margin-bottom": "-1px",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-col",
                                            {
                                              staticClass: "div-column1",
                                              attrs: { span: 2 },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "font-weight": "600",
                                                    "font-size": "15px",
                                                  },
                                                },
                                                [_vm._v("条件")]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "el-col",
                                            {
                                              staticClass: "div-column2",
                                              attrs: { span: 22 },
                                            },
                                            [
                                              _c(
                                                "el-row",
                                                { staticClass: "row-column" },
                                                [
                                                  _c(
                                                    "el-col",
                                                    { attrs: { span: 2 } },
                                                    [
                                                      _vm.jlDrawer.form
                                                        .boostType !== "per"
                                                        ? _c(
                                                            "div",
                                                            [
                                                              _c(
                                                                "el-select",
                                                                {
                                                                  attrs: {
                                                                    size: "mini",
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      item.conditionMode,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          item,
                                                                          "conditionMode",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "item.conditionMode",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "el-option",
                                                                    {
                                                                      attrs: {
                                                                        label:
                                                                          "且",
                                                                        value:
                                                                          "and",
                                                                      },
                                                                    }
                                                                  ),
                                                                  _c(
                                                                    "el-option",
                                                                    {
                                                                      attrs: {
                                                                        label:
                                                                          "或",
                                                                        value:
                                                                          "or",
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  ),
                                                  _c(
                                                    "el-col",
                                                    { attrs: { span: 20 } },
                                                    [
                                                      _c(
                                                        "el-row",
                                                        {
                                                          staticStyle: {
                                                            "margin-left": "5%",
                                                          },
                                                        },
                                                        [
                                                          _vm._l(
                                                            item.conditions,
                                                            function (
                                                              ele,
                                                              cIndx
                                                            ) {
                                                              return _c(
                                                                "el-col",
                                                                { key: cIndx },
                                                                [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      "指标 "
                                                                    ),
                                                                  ]),
                                                                  _c(
                                                                    "el-select",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          width:
                                                                            "80px",
                                                                        },
                                                                      attrs: {
                                                                        size: "mini",
                                                                      },
                                                                      on: {
                                                                        change:
                                                                          (
                                                                            val
                                                                          ) =>
                                                                            (ele.measureName =
                                                                              _vm.options.defaultQuotaOptions.find(
                                                                                (
                                                                                  o
                                                                                ) =>
                                                                                  o.value ==
                                                                                  val
                                                                              ).label),
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          ele.measure,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              ele,
                                                                              "measure",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "ele.measure",
                                                                      },
                                                                    },
                                                                    _vm._l(
                                                                      _vm
                                                                        .measureOptions[
                                                                        index
                                                                      ][cIndx],
                                                                      function (
                                                                        e
                                                                      ) {
                                                                        return _c(
                                                                          "el-option",
                                                                          {
                                                                            key: e.value,
                                                                            attrs:
                                                                              {
                                                                                value:
                                                                                  e.value,
                                                                                label:
                                                                                  e.label,
                                                                              },
                                                                          }
                                                                        )
                                                                      }
                                                                    ),
                                                                    1
                                                                  ),
                                                                  ele.measure !=
                                                                  "boost_reach"
                                                                    ? _c(
                                                                        "div",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              display:
                                                                                "inline-block",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm
                                                                            .jlDrawer
                                                                            .form
                                                                            .boostType ===
                                                                          "full"
                                                                            ? _c(
                                                                                "div",
                                                                                {
                                                                                  staticStyle:
                                                                                    {
                                                                                      display:
                                                                                        "inline-block",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "el-input",
                                                                                    {
                                                                                      staticStyle:
                                                                                        {
                                                                                          width:
                                                                                            "120px",
                                                                                        },
                                                                                      attrs:
                                                                                        {
                                                                                          clearable:
                                                                                            "",
                                                                                          size: "mini",
                                                                                          placeholder:
                                                                                            "指标名称",
                                                                                        },
                                                                                      model:
                                                                                        {
                                                                                          value:
                                                                                            ele.measureName,
                                                                                          callback:
                                                                                            function (
                                                                                              $$v
                                                                                            ) {
                                                                                              _vm.$set(
                                                                                                ele,
                                                                                                "measureName",
                                                                                                $$v
                                                                                              )
                                                                                            },
                                                                                          expression:
                                                                                            "ele.measureName",
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                  _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticStyle:
                                                                                        {
                                                                                          "margin-left":
                                                                                            "5px",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          "≥"
                                                                                        )
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                  _c(
                                                                                    "el-input-number",
                                                                                    {
                                                                                      staticStyle:
                                                                                        {
                                                                                          width:
                                                                                            "60px",
                                                                                        },
                                                                                      attrs:
                                                                                        {
                                                                                          size: "small",
                                                                                          min: 0,
                                                                                          precision: 0,
                                                                                          controls: false,
                                                                                        },
                                                                                      model:
                                                                                        {
                                                                                          value:
                                                                                            ele.numA,
                                                                                          callback:
                                                                                            function (
                                                                                              $$v
                                                                                            ) {
                                                                                              _vm.$set(
                                                                                                ele,
                                                                                                "numA",
                                                                                                $$v
                                                                                              )
                                                                                            },
                                                                                          expression:
                                                                                            "ele.numA",
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                  _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticStyle:
                                                                                        {
                                                                                          "margin-left":
                                                                                            "5px",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          ele.measure
                                                                                            ? _vm.options.defaultQuotaOptions.find(
                                                                                                (
                                                                                                  o
                                                                                                ) =>
                                                                                                  o.value ==
                                                                                                  ele.measure
                                                                                              )
                                                                                                .unit
                                                                                            : ""
                                                                                        )
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              )
                                                                            : _vm._e(),
                                                                          _vm
                                                                            .jlDrawer
                                                                            .form
                                                                            .boostType ===
                                                                          "per"
                                                                            ? _c(
                                                                                "div",
                                                                                {
                                                                                  staticStyle:
                                                                                    {
                                                                                      display:
                                                                                        "inline-block",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "el-input",
                                                                                    {
                                                                                      staticStyle:
                                                                                        {
                                                                                          width:
                                                                                            "120px",
                                                                                        },
                                                                                      attrs:
                                                                                        {
                                                                                          clearable:
                                                                                            "",
                                                                                          size: "mini",
                                                                                          placeholder:
                                                                                            "指标名称",
                                                                                        },
                                                                                      model:
                                                                                        {
                                                                                          value:
                                                                                            ele.measureName,
                                                                                          callback:
                                                                                            function (
                                                                                              $$v
                                                                                            ) {
                                                                                              _vm.$set(
                                                                                                ele,
                                                                                                "measureName",
                                                                                                $$v
                                                                                              )
                                                                                            },
                                                                                          expression:
                                                                                            "ele.measureName",
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                  _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticStyle:
                                                                                        {
                                                                                          "margin-left":
                                                                                            "5px",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          "每达成"
                                                                                        )
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                  _c(
                                                                                    "el-input-number",
                                                                                    {
                                                                                      staticStyle:
                                                                                        {
                                                                                          width:
                                                                                            "60px",
                                                                                        },
                                                                                      attrs:
                                                                                        {
                                                                                          size: "small",
                                                                                          min: 0,
                                                                                          precision: 0,
                                                                                          controls: false,
                                                                                        },
                                                                                      model:
                                                                                        {
                                                                                          value:
                                                                                            ele.numA,
                                                                                          callback:
                                                                                            function (
                                                                                              $$v
                                                                                            ) {
                                                                                              _vm.$set(
                                                                                                ele,
                                                                                                "numA",
                                                                                                $$v
                                                                                              )
                                                                                            },
                                                                                          expression:
                                                                                            "ele.numA",
                                                                                        },
                                                                                    }
                                                                                  ),
                                                                                  _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticStyle:
                                                                                        {
                                                                                          "margin-left":
                                                                                            "5px",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          ele.measure
                                                                                            ? _vm.options.defaultQuotaOptions.find(
                                                                                                (
                                                                                                  o
                                                                                                ) =>
                                                                                                  o.value ==
                                                                                                  ele.measure
                                                                                              )
                                                                                                .unit
                                                                                            : ""
                                                                                        )
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              )
                                                                            : _vm._e(),
                                                                          _vm
                                                                            .jlDrawer
                                                                            .form
                                                                            .boostType ===
                                                                            "grad" &&
                                                                          ele.measure !=
                                                                            "boost_reach"
                                                                            ? _c(
                                                                                "div",
                                                                                {
                                                                                  staticStyle:
                                                                                    {
                                                                                      display:
                                                                                        "inline-block",
                                                                                    },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " 奖励类型 "
                                                                                  ),
                                                                                  _c(
                                                                                    "el-select",
                                                                                    {
                                                                                      staticStyle:
                                                                                        {
                                                                                          width:
                                                                                            "80px",
                                                                                        },
                                                                                      attrs:
                                                                                        {
                                                                                          size: "mini",
                                                                                        },
                                                                                      model:
                                                                                        {
                                                                                          value:
                                                                                            ele.operation,
                                                                                          callback:
                                                                                            function (
                                                                                              $$v
                                                                                            ) {
                                                                                              _vm.$set(
                                                                                                ele,
                                                                                                "operation",
                                                                                                $$v
                                                                                              )
                                                                                            },
                                                                                          expression:
                                                                                            "ele.operation",
                                                                                        },
                                                                                    },
                                                                                    _vm._l(
                                                                                      _vm.computeJlType(
                                                                                        index,
                                                                                        cIndx
                                                                                      ),
                                                                                      function (
                                                                                        e
                                                                                      ) {
                                                                                        return _c(
                                                                                          "el-option",
                                                                                          {
                                                                                            key: e.value,
                                                                                            attrs:
                                                                                              {
                                                                                                value:
                                                                                                  e.value,
                                                                                                label:
                                                                                                  e.label,
                                                                                              },
                                                                                          }
                                                                                        )
                                                                                      }
                                                                                    ),
                                                                                    1
                                                                                  ),
                                                                                  ele.operation &&
                                                                                  ele.operation.indexOf(
                                                                                    "a,b"
                                                                                  ) >
                                                                                    -1
                                                                                    ? _c(
                                                                                        "div",
                                                                                        {
                                                                                          staticStyle:
                                                                                            {
                                                                                              display:
                                                                                                "inline-block",
                                                                                              "margin-left":
                                                                                                "5px",
                                                                                            },
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "el-input-number",
                                                                                            {
                                                                                              staticStyle:
                                                                                                {
                                                                                                  width:
                                                                                                    "60px",
                                                                                                },
                                                                                              attrs:
                                                                                                {
                                                                                                  size: "small",
                                                                                                  min: 0,
                                                                                                  precision: 0,
                                                                                                  controls: false,
                                                                                                },
                                                                                              model:
                                                                                                {
                                                                                                  value:
                                                                                                    ele.numA,
                                                                                                  callback:
                                                                                                    function (
                                                                                                      $$v
                                                                                                    ) {
                                                                                                      _vm.$set(
                                                                                                        ele,
                                                                                                        "numA",
                                                                                                        $$v
                                                                                                      )
                                                                                                    },
                                                                                                  expression:
                                                                                                    "ele.numA",
                                                                                                },
                                                                                            }
                                                                                          ),
                                                                                          _c(
                                                                                            "span",
                                                                                            [
                                                                                              _vm._v(
                                                                                                " " +
                                                                                                  _vm._s(
                                                                                                    ele.operation &&
                                                                                                      ele.operation.indexOf(
                                                                                                        "[a,b"
                                                                                                      ) >
                                                                                                        -1
                                                                                                      ? "≤"
                                                                                                      : "<"
                                                                                                  ) +
                                                                                                  " "
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                          _c(
                                                                                            "span",
                                                                                            {
                                                                                              staticStyle:
                                                                                                {
                                                                                                  "margin-left":
                                                                                                    "5px",
                                                                                                },
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                _vm._s(
                                                                                                  ele.measureName
                                                                                                    ? ele.measureName
                                                                                                    : ""
                                                                                                )
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                          _c(
                                                                                            "span",
                                                                                            [
                                                                                              _vm._v(
                                                                                                " " +
                                                                                                  _vm._s(
                                                                                                    ele.operation &&
                                                                                                      ele.operation.indexOf(
                                                                                                        "a,b]"
                                                                                                      ) >
                                                                                                        -1
                                                                                                      ? "≤"
                                                                                                      : "<"
                                                                                                  ) +
                                                                                                  " "
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                          _c(
                                                                                            "el-input-number",
                                                                                            {
                                                                                              staticStyle:
                                                                                                {
                                                                                                  width:
                                                                                                    "60px",
                                                                                                },
                                                                                              attrs:
                                                                                                {
                                                                                                  size: "small",
                                                                                                  min: 0,
                                                                                                  precision: 0,
                                                                                                  controls: false,
                                                                                                },
                                                                                              model:
                                                                                                {
                                                                                                  value:
                                                                                                    ele.numB,
                                                                                                  callback:
                                                                                                    function (
                                                                                                      $$v
                                                                                                    ) {
                                                                                                      _vm.$set(
                                                                                                        ele,
                                                                                                        "numB",
                                                                                                        $$v
                                                                                                      )
                                                                                                    },
                                                                                                  expression:
                                                                                                    "ele.numB",
                                                                                                },
                                                                                            }
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    : _c(
                                                                                        "div",
                                                                                        {
                                                                                          staticStyle:
                                                                                            {
                                                                                              display:
                                                                                                "inline-block",
                                                                                              "margin-left":
                                                                                                "5px",
                                                                                            },
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "span",
                                                                                            {
                                                                                              staticStyle:
                                                                                                {
                                                                                                  "margin-left":
                                                                                                    "5px",
                                                                                                },
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                _vm._s(
                                                                                                  ele.measure
                                                                                                    ? _vm.measureOptions[
                                                                                                        index
                                                                                                      ][
                                                                                                        cIndx
                                                                                                      ].find(
                                                                                                        (
                                                                                                          m
                                                                                                        ) =>
                                                                                                          m.value ===
                                                                                                          ele.measure
                                                                                                      )
                                                                                                        .label
                                                                                                    : ""
                                                                                                )
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                          _c(
                                                                                            "span",
                                                                                            [
                                                                                              _vm._v(
                                                                                                " " +
                                                                                                  _vm._s(
                                                                                                    "≥"
                                                                                                  ) +
                                                                                                  " "
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                          _c(
                                                                                            "el-input-number",
                                                                                            {
                                                                                              staticStyle:
                                                                                                {
                                                                                                  width:
                                                                                                    "60px",
                                                                                                },
                                                                                              attrs:
                                                                                                {
                                                                                                  size: "small",
                                                                                                  min: 0,
                                                                                                  precision: 0,
                                                                                                  controls: false,
                                                                                                },
                                                                                              model:
                                                                                                {
                                                                                                  value:
                                                                                                    ele.numA,
                                                                                                  callback:
                                                                                                    function (
                                                                                                      $$v
                                                                                                    ) {
                                                                                                      _vm.$set(
                                                                                                        ele,
                                                                                                        "numA",
                                                                                                        $$v
                                                                                                      )
                                                                                                    },
                                                                                                  expression:
                                                                                                    "ele.numA",
                                                                                                },
                                                                                            }
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                ],
                                                                                1
                                                                              )
                                                                            : _vm._e(),
                                                                        ]
                                                                      )
                                                                    : _c(
                                                                        "div",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              display:
                                                                                "inline-block",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "el-select",
                                                                            {
                                                                              staticStyle:
                                                                                {
                                                                                  width:
                                                                                    "320px",
                                                                                },
                                                                              attrs:
                                                                                {
                                                                                  multiple:
                                                                                    "",
                                                                                  filterable:
                                                                                    "",
                                                                                  remote:
                                                                                    "",
                                                                                  loading:
                                                                                    _vm.remoteLoading,
                                                                                  size: "mini",
                                                                                  "remote-method":
                                                                                    (
                                                                                      val
                                                                                    ) =>
                                                                                      _vm.handleTaskBoostOptions(
                                                                                        index,
                                                                                        cIndx,
                                                                                        val,
                                                                                        ""
                                                                                      ),
                                                                                  placeholder:
                                                                                    "请输入任务或阵地名称搜索",
                                                                                },
                                                                              model:
                                                                                {
                                                                                  value:
                                                                                    ele
                                                                                      .configJson
                                                                                      .relatedBoost,
                                                                                  callback:
                                                                                    function (
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.$set(
                                                                                        ele.configJson,
                                                                                        "relatedBoost",
                                                                                        $$v
                                                                                      )
                                                                                    },
                                                                                  expression:
                                                                                    "ele.configJson.relatedBoost",
                                                                                },
                                                                            },
                                                                            _vm._l(
                                                                              _vm.getTaskBoostOptions(
                                                                                index,
                                                                                cIndx
                                                                              ),
                                                                              function (
                                                                                b
                                                                              ) {
                                                                                return _c(
                                                                                  "el-option",
                                                                                  {
                                                                                    key: b.id,
                                                                                    attrs:
                                                                                      {
                                                                                        label:
                                                                                          b.taskName +
                                                                                          "-" +
                                                                                          b.name,
                                                                                        value:
                                                                                          b.id,
                                                                                      },
                                                                                  }
                                                                                )
                                                                              }
                                                                            ),
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                  _c(
                                                                    "el-select",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          width:
                                                                            "110px",
                                                                          "margin-left":
                                                                            "20px",
                                                                        },
                                                                      attrs: {
                                                                        size: "mini",
                                                                        placeholder:
                                                                          "商品范围",
                                                                      },
                                                                      on: {
                                                                        change:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            ele.skus =
                                                                              []
                                                                          },
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          ele
                                                                            .configJson
                                                                            .allSkus,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              ele.configJson,
                                                                              "allSkus",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "ele.configJson.allSkus",
                                                                      },
                                                                    },
                                                                    _vm._l(
                                                                      _vm
                                                                        .options
                                                                        .skuOptions,
                                                                      function (
                                                                        e
                                                                      ) {
                                                                        return _c(
                                                                          "el-option",
                                                                          {
                                                                            key: e.value,
                                                                            attrs:
                                                                              {
                                                                                value:
                                                                                  e.value,
                                                                                label:
                                                                                  e.label,
                                                                              },
                                                                          }
                                                                        )
                                                                      }
                                                                    ),
                                                                    1
                                                                  ),
                                                                  ele.configJson &&
                                                                  ele.configJson
                                                                    .allSkus ===
                                                                    "3"
                                                                    ? _c(
                                                                        "el-button",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              "margin-left":
                                                                                "10px",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              size: "mini",
                                                                              type: "primary",
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.handleChooseSkus(
                                                                                  index,
                                                                                  cIndx
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "选择商品范围"
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  ele.configJson &&
                                                                  ele.configJson
                                                                    .allSkus ===
                                                                    "4"
                                                                    ? _c(
                                                                        "el-select",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              width:
                                                                                "200px",
                                                                              "margin-left":
                                                                                "10px",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              size: "mini",
                                                                              multiple:
                                                                                "",
                                                                              clearable:
                                                                                "",
                                                                              filterable:
                                                                                "",
                                                                              placeholder:
                                                                                "选择阵地/标签",
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .jlDrawer
                                                                                  .form
                                                                                  .rules[
                                                                                  index
                                                                                ]
                                                                                  .conditions[
                                                                                  cIndx
                                                                                ]
                                                                                  .configJson
                                                                                  .tags,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm
                                                                                      .jlDrawer
                                                                                      .form
                                                                                      .rules[
                                                                                      index
                                                                                    ]
                                                                                      .conditions[
                                                                                      cIndx
                                                                                    ]
                                                                                      .configJson,
                                                                                    "tags",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "jlDrawer.form.rules[index].conditions[cIndx].configJson.tags",
                                                                            },
                                                                        },
                                                                        _vm._l(
                                                                          _vm
                                                                            .options
                                                                            .skuTagOptions,
                                                                          function (
                                                                            item
                                                                          ) {
                                                                            return _c(
                                                                              "el-option",
                                                                              {
                                                                                key: item,
                                                                                attrs:
                                                                                  {
                                                                                    value:
                                                                                      item,
                                                                                    label:
                                                                                      item,
                                                                                  },
                                                                              }
                                                                            )
                                                                          }
                                                                        ),
                                                                        1
                                                                      )
                                                                    : _vm._e(),
                                                                  item
                                                                    .conditions
                                                                    .length > 1
                                                                    ? _c(
                                                                        "el-button",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              size: "mini",
                                                                              type: "text",
                                                                              icon: "el-icon-delete",
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.handleRemoveCondition(
                                                                                  index,
                                                                                  cIndx
                                                                                )
                                                                              },
                                                                          },
                                                                        }
                                                                      )
                                                                    : _vm._e(),
                                                                ],
                                                                1
                                                              )
                                                            }
                                                          ),
                                                          _c(
                                                            "el-col",
                                                            [
                                                              _vm.jlDrawer.form
                                                                .boostType !==
                                                              "per"
                                                                ? _c(
                                                                    "el-button",
                                                                    {
                                                                      attrs: {
                                                                        size: "mini",
                                                                        type: "text",
                                                                        icon: "el-icon-circle-plus-outline",
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.handleAddCondition(
                                                                              index
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "添加条件"
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        2
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "el-col",
                                                    { attrs: { span: 1 } },
                                                    [
                                                      _vm.jlDrawer.form.rules
                                                        .length > 1 && index > 0
                                                        ? _c("el-button", {
                                                            attrs: {
                                                              size: "mini",
                                                              type: "text",
                                                              icon: "el-icon-delete",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.handleRemoveRule(
                                                                  index
                                                                )
                                                              },
                                                            },
                                                          })
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 23 } },
                                    [
                                      _c(
                                        "el-row",
                                        {
                                          staticStyle: {
                                            border: "1px solid",
                                            "margin-left": "5%",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-col",
                                            {
                                              staticClass: "div-column1",
                                              attrs: { span: 2 },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "font-weight": "600",
                                                    "font-size": "15px",
                                                  },
                                                },
                                                [_vm._v("奖励")]
                                              ),
                                            ]
                                          ),
                                          _vm.jlDrawer.form.bonusTarget ===
                                          "salesman"
                                            ? _c(
                                                "el-col",
                                                {
                                                  staticClass: "div-column3",
                                                  attrs: { span: 20 },
                                                },
                                                [
                                                  _c(
                                                    "el-row",
                                                    {
                                                      staticStyle: {
                                                        "text-align": "center",
                                                      },
                                                    },
                                                    [
                                                      _vm.jlDrawer.form
                                                        .boostType === "full"
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticStyle: {
                                                                display:
                                                                  "inline-block",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "el-select",
                                                                {
                                                                  staticStyle: {
                                                                    width:
                                                                      "100px",
                                                                  },
                                                                  attrs: {
                                                                    size: "mini",
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      item.boostWay,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          item,
                                                                          "boostWay",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "item.boostWay",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "el-option",
                                                                    {
                                                                      attrs: {
                                                                        label:
                                                                          "正常奖励",
                                                                        value:
                                                                          "1",
                                                                      },
                                                                    }
                                                                  ),
                                                                  _c(
                                                                    "el-option",
                                                                    {
                                                                      attrs: {
                                                                        label:
                                                                          "百分比奖励",
                                                                        value:
                                                                          "2",
                                                                      },
                                                                    }
                                                                  ),
                                                                  _c(
                                                                    "el-option",
                                                                    {
                                                                      attrs: {
                                                                        label:
                                                                          "百分比仅奖励超出部分",
                                                                        value:
                                                                          "3",
                                                                      },
                                                                    }
                                                                  ),
                                                                  _c(
                                                                    "el-option",
                                                                    {
                                                                      attrs: {
                                                                        label:
                                                                          "满(每奖励)",
                                                                        value:
                                                                          "4",
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              item.boostWay !==
                                                              "1"
                                                                ? _c(
                                                                    "span",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          "margin-left":
                                                                            "5px",
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "关联奖励指标"
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                              item.boostWay &&
                                                              item.boostWay !==
                                                                "1"
                                                                ? _c(
                                                                    "el-select",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          width:
                                                                            "80px",
                                                                        },
                                                                      attrs: {
                                                                        size: "mini",
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          item.virRelConditionId,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              item,
                                                                              "virRelConditionId",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "item.virRelConditionId",
                                                                      },
                                                                    },
                                                                    _vm._l(
                                                                      _vm.getConditions(
                                                                        item
                                                                      ),
                                                                      function (
                                                                        c
                                                                      ) {
                                                                        return _c(
                                                                          "el-option",
                                                                          {
                                                                            key: c.value,
                                                                            attrs:
                                                                              {
                                                                                value:
                                                                                  c.value,
                                                                                label:
                                                                                  c.label,
                                                                              },
                                                                          }
                                                                        )
                                                                      }
                                                                    ),
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      item.boostWay === "1" &&
                                                      (!item.modules ||
                                                        item.modules.length ===
                                                          0)
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticStyle: {
                                                                display:
                                                                  "inline-block",
                                                              },
                                                            },
                                                            [
                                                              _vm.jlDrawer.form
                                                                .boostType ===
                                                              "full"
                                                                ? _c(
                                                                    "div",
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "单店奖励"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "el-input-number",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              width:
                                                                                "60px",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              size: "small",
                                                                              min: 0,
                                                                              precision: 1,
                                                                              controls: false,
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                item.bonus,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    item,
                                                                                    "bonus",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "item.bonus",
                                                                            },
                                                                        }
                                                                      ),
                                                                      _vm._v(
                                                                        "元"
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                              _vm.jlDrawer.form
                                                                .boostType ===
                                                              "grad"
                                                                ? _c(
                                                                    "div",
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "奖励"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " 奖励"
                                                                      ),
                                                                      _c(
                                                                        "el-input-number",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              width:
                                                                                "60px",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              size: "small",
                                                                              min: 0,
                                                                              precision: 1,
                                                                              controls: false,
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                item.bonus,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    item,
                                                                                    "bonus",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "item.bonus",
                                                                            },
                                                                        }
                                                                      ),
                                                                      _vm._v(
                                                                        "元"
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                              _vm.jlDrawer.form
                                                                .boostType ===
                                                              "per"
                                                                ? _c(
                                                                    "div",
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "奖励"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "el-input-number",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              width:
                                                                                "60px",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              size: "small",
                                                                              min: 0,
                                                                              precision: 1,
                                                                              controls: false,
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                item.bonus,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    item,
                                                                                    "bonus",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "item.bonus",
                                                                            },
                                                                        }
                                                                      ),
                                                                      _vm._v(
                                                                        "元 "
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            " 奖励封顶"
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "el-input-number",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              width:
                                                                                "60px",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              size: "small",
                                                                              min: 0,
                                                                              precision: 1,
                                                                              controls: false,
                                                                              max: 999999,
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .jlDrawer
                                                                                  .form
                                                                                  .roofOfShop,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm
                                                                                      .jlDrawer
                                                                                      .form,
                                                                                    "roofOfShop",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "jlDrawer.form.roofOfShop",
                                                                            },
                                                                        }
                                                                      ),
                                                                      _vm._v(
                                                                        "元"
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      ["2", "3", "4"].indexOf(
                                                        item.boostWay
                                                      ) > -1
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticStyle: {
                                                                display:
                                                                  "inline-block",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      "达成后按照"
                                                                    ),
                                                                  ]),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "strong",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          "font-size":
                                                                            "14px",
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          item.virRelConditionId
                                                                            ? _vm
                                                                                .getConditions(
                                                                                  item
                                                                                )
                                                                                .find(
                                                                                  (
                                                                                    e
                                                                                  ) =>
                                                                                    e.value ===
                                                                                    item.virRelConditionId
                                                                                )
                                                                                ?.label
                                                                            : ""
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  ["4"].indexOf(
                                                                    item.boostWay
                                                                  ) > -1
                                                                    ? _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            " 每达成"
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  _c(
                                                                    "el-input-number",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          width:
                                                                            "70px",
                                                                        },
                                                                      attrs: {
                                                                        size: "small",
                                                                        min: 0,
                                                                        precision: 2,
                                                                        controls: false,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          item.boostNum,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              item,
                                                                              "boostNum",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "item.boostNum",
                                                                      },
                                                                    }
                                                                  ),
                                                                  ["4"].indexOf(
                                                                    item.boostWay
                                                                  ) > -1 &&
                                                                  item.virRelConditionId
                                                                    ? _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                item.virRelConditionId
                                                                                  ? _vm
                                                                                      .getConditions(
                                                                                        item
                                                                                      )
                                                                                      .find(
                                                                                        (
                                                                                          e
                                                                                        ) =>
                                                                                          e.value ===
                                                                                          item.virRelConditionId
                                                                                      )
                                                                                      ?.unit
                                                                                  : ""
                                                                              ) +
                                                                              " 奖励 "
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  ["4"].indexOf(
                                                                    item.boostWay
                                                                  ) > -1
                                                                    ? _c(
                                                                        "el-input-number",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              width:
                                                                                "70px",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              size: "small",
                                                                              min: 0,
                                                                              precision: 2,
                                                                              controls: false,
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                item.bonus,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    item,
                                                                                    "bonus",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "item.bonus",
                                                                            },
                                                                        }
                                                                      )
                                                                    : _vm._e(),
                                                                  ["4"].indexOf(
                                                                    item.boostWay
                                                                  ) > -1
                                                                    ? _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "元"
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  [
                                                                    "2",
                                                                    "3",
                                                                  ].indexOf(
                                                                    item.boostWay
                                                                  ) > -1
                                                                    ? _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "% 奖励,"
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      " 封顶"
                                                                    ),
                                                                  ]),
                                                                  _c(
                                                                    "el-input-number",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          width:
                                                                            "70px",
                                                                        },
                                                                      attrs: {
                                                                        size: "small",
                                                                        min: 0,
                                                                        precision: 1,
                                                                        controls: false,
                                                                        max: 999999,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .jlDrawer
                                                                            .form
                                                                            .roofOfShop,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm
                                                                                .jlDrawer
                                                                                .form,
                                                                              "roofOfShop",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "jlDrawer.form.roofOfShop",
                                                                      },
                                                                    }
                                                                  ),
                                                                  _vm._v("元 "),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        width: "70%",
                                                        "text-align": "center",
                                                      },
                                                    },
                                                    [
                                                      _vm._l(
                                                        item.modules,
                                                        function (m) {
                                                          return _c(
                                                            "el-row",
                                                            { key: m.id },
                                                            [
                                                              _c(
                                                                "el-col",
                                                                {
                                                                  staticStyle: {
                                                                    "margin-top":
                                                                      "7px",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "strong",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          "font-size":
                                                                            "14px",
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          m.name
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  [
                                                                    "2",
                                                                    "3",
                                                                    "4",
                                                                  ].indexOf(
                                                                    item.boostWay
                                                                  ) < 0
                                                                    ? _c(
                                                                        "div",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              display:
                                                                                "inline-block",
                                                                              "margin-left":
                                                                                "10px",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              staticStyle:
                                                                                {
                                                                                  "margin-left":
                                                                                    "5px",
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " 奖励 "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "el-input-number",
                                                                            {
                                                                              staticStyle:
                                                                                {
                                                                                  width:
                                                                                    "60px",
                                                                                },
                                                                              attrs:
                                                                                {
                                                                                  size: "small",
                                                                                  controls: false,
                                                                                  min: 0,
                                                                                },
                                                                              model:
                                                                                {
                                                                                  value:
                                                                                    m.moduleMoney,
                                                                                  callback:
                                                                                    function (
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.$set(
                                                                                        m,
                                                                                        "moduleMoney",
                                                                                        $$v
                                                                                      )
                                                                                    },
                                                                                  expression:
                                                                                    "m.moduleMoney",
                                                                                },
                                                                            }
                                                                          ),
                                                                          _vm._v(
                                                                            " 元 "
                                                                          ),
                                                                        ],
                                                                        1
                                                                      )
                                                                    : _vm._e(),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        }
                                                      ),
                                                      _c(
                                                        "el-row",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "show",
                                                              rawName: "v-show",
                                                              value:
                                                                item.modules &&
                                                                item.modules
                                                                  .length > 1,
                                                              expression:
                                                                "item.modules && item.modules.length>1",
                                                            },
                                                          ],
                                                        },
                                                        [
                                                          _c(
                                                            "el-col",
                                                            {
                                                              staticStyle: {
                                                                "margin-top":
                                                                  "7px",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "以上均达成 "
                                                              ),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticStyle: {
                                                                    "margin-left":
                                                                      "10px",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " 奖励 "
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "el-input-number",
                                                                {
                                                                  staticStyle: {
                                                                    width:
                                                                      "60px",
                                                                  },
                                                                  attrs: {
                                                                    size: "small",
                                                                    controls: false,
                                                                    min: 0,
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      item.fullModuleBonus,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          item,
                                                                          "fullModuleBonus",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "item.fullModuleBonus",
                                                                  },
                                                                }
                                                              ),
                                                              _vm._v("元 "),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    2
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.jlDrawer.form.bonusTarget ===
                                          "shop"
                                            ? _c(
                                                "el-col",
                                                {
                                                  staticClass: "div-column2",
                                                  staticStyle: {
                                                    display: "block",
                                                    "padding-left": "10%",
                                                  },
                                                  attrs: { span: 20 },
                                                },
                                                [
                                                  _c(
                                                    "el-row",
                                                    {
                                                      staticStyle: {
                                                        display: "block",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "el-col",
                                                        { attrs: { span: 3 } },
                                                        [_vm._v("数量")]
                                                      ),
                                                      _c(
                                                        "el-col",
                                                        { attrs: { span: 4 } },
                                                        [_vm._v("单位")]
                                                      ),
                                                      _c(
                                                        "el-col",
                                                        { attrs: { span: 6 } },
                                                        [_vm._v("奖励内容")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "el-row",
                                                    {
                                                      staticStyle: {
                                                        display: "block",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "el-col",
                                                        { attrs: { span: 3 } },
                                                        [
                                                          _c(
                                                            "el-input-number",
                                                            {
                                                              staticStyle: {
                                                                width: "60px",
                                                              },
                                                              attrs: {
                                                                size: "small",
                                                                min: 0,
                                                                precision: 0,
                                                                controls: false,
                                                              },
                                                              model: {
                                                                value:
                                                                  item.bonus,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      item,
                                                                      "bonus",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "item.bonus",
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "el-col",
                                                        { attrs: { span: 4 } },
                                                        [
                                                          _c("el-input", {
                                                            staticStyle: {
                                                              width: "80px",
                                                            },
                                                            attrs: {
                                                              size: "mini",
                                                              placeholder:
                                                                "元/款/架等",
                                                            },
                                                            model: {
                                                              value: item.unit,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    item,
                                                                    "unit",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "item.unit",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "el-col",
                                                        { attrs: { span: 6 } },
                                                        [
                                                          _c("el-input", {
                                                            staticStyle: {
                                                              width: "180px",
                                                            },
                                                            attrs: {
                                                              size: "mini",
                                                              clearable: "",
                                                              placeholder:
                                                                "例如“滞销品回收费用支持”",
                                                            },
                                                            model: {
                                                              value:
                                                                item.bonusText,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    item,
                                                                    "bonusText",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "item.bonusText",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "el-row",
                                                    {
                                                      staticStyle: {
                                                        display: "block",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "el-col",
                                                        {
                                                          staticClass:
                                                            "min-desc",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "App显示预览: 预计可得 " +
                                                              _vm._s(
                                                                _vm.verifyUndefined(
                                                                  item.bonus
                                                                ) +
                                                                  "" +
                                                                  _vm.verifyUndefined(
                                                                    item.unit
                                                                  ) +
                                                                  "" +
                                                                  _vm.verifyUndefined(
                                                                    item.bonusText
                                                                  )
                                                              )
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }
                          ),
                          _vm.jlDrawer.form.boostType === "grad"
                            ? _c(
                                "div",
                                { staticStyle: { "margin-left": "10%" } },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        size: "mini",
                                        type: "text",
                                        icon: "el-icon-circle-plus-outline",
                                      },
                                      on: {
                                        click: () =>
                                          _vm.jlDrawer.form.rules.push(
                                            this.getDefaultRules()
                                          ),
                                      },
                                    },
                                    [_vm._v("添加奖励档位")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm.jlDrawer.form.boostType === "none"
                    ? _c("el-col", [
                        _c("div", { staticClass: "mid-title" }, [
                          _vm._v("奖励条件设置"),
                        ]),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "margin-left": "5%",
                              "margin-top": "15px",
                            },
                          },
                          [
                            _c("span", { staticClass: "min-desc" }, [
                              _vm._v("App上仅展示奖励描述: "),
                            ]),
                            _c("span", [
                              _vm._v(_vm._s(_vm.jlDrawer.form.description)),
                            ]),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _vm.jlDrawer.form.bonusTarget === "salesman"
                    ? _c("el-col", [
                        _c(
                          "div",
                          { staticStyle: { "margin-bottom": "15px" } },
                          [
                            _c("span", { staticClass: "mid-title" }, [
                              _vm._v("店铺数奖励 "),
                            ]),
                            _vm._v(" "),
                            _c("span", { staticClass: "min-desc" }, [
                              _vm._v(
                                "选配，指单个业务员满足以上条件的店铺数量"
                              ),
                            ]),
                          ]
                        ),
                        _c("div", { staticStyle: { "margin-left": "5%" } }, [
                          _c(
                            "div",
                            [
                              _c(
                                "el-checkbox",
                                {
                                  model: {
                                    value: _vm.jlDrawer.form.minShop,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.jlDrawer.form,
                                        "minShop",
                                        $$v
                                      )
                                    },
                                    expression: "jlDrawer.form.minShop",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-weight": "400",
                                        "font-size": "16px",
                                      },
                                    },
                                    [_vm._v("奖励起步线 ")]
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "inline-block",
                                    "margin-left": "10px",
                                  },
                                },
                                [
                                  _c("span", [
                                    _vm._v("满足以上条件的店铺数 < "),
                                  ]),
                                  _c("el-input-number", {
                                    staticStyle: { width: "60px" },
                                    attrs: {
                                      size: "small",
                                      min: 1,
                                      precision: 0,
                                      controls: false,
                                    },
                                    model: {
                                      value: _vm.jlDrawer.form.minShopNum,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.jlDrawer.form,
                                          "minShopNum",
                                          $$v
                                        )
                                      },
                                      expression: "jlDrawer.form.minShopNum",
                                    },
                                  }),
                                  _vm._v("家 则不发放以上任何奖励"),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c(
                                "el-checkbox",
                                {
                                  model: {
                                    value: _vm.jlDrawer.form.otherJl,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.jlDrawer.form,
                                        "otherJl",
                                        $$v
                                      )
                                    },
                                    expression: "jlDrawer.form.otherJl",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-weight": "400",
                                        "font-size": "16px",
                                      },
                                    },
                                    [_vm._v("额外奖励 ")]
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "inline-block",
                                    width: "50%",
                                    "margin-left": "10px",
                                  },
                                },
                                [
                                  _c("span", [_vm._v("满足以上条件的店铺数 ")]),
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "60px" },
                                      attrs: { size: "mini", placeholder: "" },
                                      on: {
                                        change: () => {
                                          _vm.jlDrawer.form.gradExpJson = [
                                            _vm.deepClone(
                                              _vm.jlDrawer.defaultGradExp
                                            ),
                                          ]
                                          _vm.jlDrawer.form.modelFullBonus =
                                            undefined
                                          _vm.jlDrawer.form.modelFullShops =
                                            undefined
                                          _vm.jlDrawer.form.modelPerShops =
                                            undefined
                                          _vm.jlDrawer.form.modelPerBonus =
                                            undefined
                                        },
                                      },
                                      model: {
                                        value: _vm.jlDrawer.form.shopsBoostType,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.jlDrawer.form,
                                            "shopsBoostType",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "jlDrawer.form.shopsBoostType",
                                      },
                                    },
                                    _vm._l(
                                      _vm.computeBoostTypes("always"),
                                      function (item) {
                                        return _c("el-option", {
                                          key: item.value,
                                          attrs: {
                                            value: item.value,
                                            label: item.shorLabel,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                  _vm.jlDrawer.form.shopsBoostType === "grad"
                                    ? _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            display: "table",
                                            "margin-left": "10%",
                                          },
                                        },
                                        [
                                          _vm.jlDrawer.form.gradExpJson &&
                                          _vm.jlDrawer.form.gradExpJson.length >
                                            0
                                            ? _c(
                                                "el-row",
                                                _vm._l(
                                                  _vm.jlDrawer.form.gradExpJson,
                                                  function (item, index) {
                                                    return _c(
                                                      "el-col",
                                                      { key: index },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              "档位" +
                                                                (index + 1)
                                                            ) +
                                                            " "
                                                        ),
                                                        _c("el-input-number", {
                                                          staticStyle: {
                                                            width: "60px",
                                                          },
                                                          attrs: {
                                                            size: "small",
                                                            min: 1,
                                                            precision: 0,
                                                            controls: false,
                                                          },
                                                          model: {
                                                            value: item.numA,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                item,
                                                                "numA",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "item.numA",
                                                          },
                                                        }),
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              " ≤ 店铺数 < "
                                                            ) +
                                                            " "
                                                        ),
                                                        _c("el-input-number", {
                                                          staticStyle: {
                                                            width: "60px",
                                                          },
                                                          attrs: {
                                                            size: "small",
                                                            min: 1,
                                                            precision: 0,
                                                            controls: false,
                                                          },
                                                          model: {
                                                            value: item.numB,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                item,
                                                                "numB",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "item.numB",
                                                          },
                                                        }),
                                                        _c("span", [
                                                          _vm._v("，额外奖励"),
                                                        ]),
                                                        _c("el-input-number", {
                                                          staticStyle: {
                                                            width: "60px",
                                                          },
                                                          attrs: {
                                                            size: "small",
                                                            min: 1,
                                                            precision: 0,
                                                            controls: false,
                                                          },
                                                          model: {
                                                            value: item.bonus,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                item,
                                                                "bonus",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "item.bonus",
                                                          },
                                                        }),
                                                        _vm._v("元 "),
                                                        _vm.jlDrawer.form
                                                          .gradExpJson.length >
                                                        1
                                                          ? _c("el-button", {
                                                              attrs: {
                                                                size: "mini",
                                                                type: "text",
                                                                icon: "el-icon-delete",
                                                              },
                                                              on: {
                                                                click: () =>
                                                                  _vm.jlDrawer.form.gradExpJson.splice(
                                                                    index,
                                                                    1
                                                                  ),
                                                              },
                                                            })
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            : _vm._e(),
                                          _c(
                                            "el-row",
                                            [
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    size: "mini",
                                                    type: "text",
                                                    icon: "el-icon-circle-plus-outline",
                                                  },
                                                  on: {
                                                    click: () =>
                                                      _vm.jlDrawer.form.gradExpJson.push(
                                                        _vm.deepClone(
                                                          _vm.jlDrawer
                                                            .defaultGradExp
                                                        )
                                                      ),
                                                  },
                                                },
                                                [_vm._v("添加奖励档位")]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.jlDrawer.form.shopsBoostType === "full"
                                    ? _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            display: "inline-block",
                                          },
                                        },
                                        [
                                          _c("el-input-number", {
                                            staticStyle: { width: "60px" },
                                            attrs: {
                                              size: "small",
                                              min: 1,
                                              precision: 0,
                                              controls: false,
                                            },
                                            model: {
                                              value:
                                                _vm.jlDrawer.form
                                                  .modelFullShops,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.jlDrawer.form,
                                                  "modelFullShops",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "jlDrawer.form.modelFullShops",
                                            },
                                          }),
                                          _vm._v(" 家，额外奖励 "),
                                          _c("el-input-number", {
                                            staticStyle: { width: "60px" },
                                            attrs: {
                                              size: "small",
                                              min: 1,
                                              precision: 0,
                                              controls: false,
                                            },
                                            model: {
                                              value:
                                                _vm.jlDrawer.form
                                                  .modelFullBonus,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.jlDrawer.form,
                                                  "modelFullBonus",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "jlDrawer.form.modelFullBonus",
                                            },
                                          }),
                                          _vm._v("元 "),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.jlDrawer.form.shopsBoostType === "per"
                                    ? _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            display: "inline-block",
                                          },
                                        },
                                        [
                                          _c("el-input-number", {
                                            staticStyle: { width: "60px" },
                                            attrs: {
                                              size: "small",
                                              min: 1,
                                              precision: 0,
                                              controls: false,
                                            },
                                            model: {
                                              value:
                                                _vm.jlDrawer.form.modelPerShops,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.jlDrawer.form,
                                                  "modelPerShops",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "jlDrawer.form.modelPerShops",
                                            },
                                          }),
                                          _vm._v(" 家，额外奖励 "),
                                          _c("el-input-number", {
                                            staticStyle: { width: "60px" },
                                            attrs: {
                                              size: "small",
                                              min: 1,
                                              precision: 0,
                                              controls: false,
                                            },
                                            model: {
                                              value:
                                                _vm.jlDrawer.form.modelPerBonus,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.jlDrawer.form,
                                                  "modelPerBonus",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "jlDrawer.form.modelPerBonus",
                                            },
                                          }),
                                          _vm._v("元 "),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { float: "right", width: "20%" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: { click: _vm.handleCloseDrawer },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: { click: _vm.submitEdit },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "商品选择",
            visible: _vm.skuDialog.visible,
            "append-to-body": "",
            width: "700px",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.skuDialog, "visible", $event)
            },
            close: () => {
              if (_vm.$refs.skuItemRef) {
                _vm.$refs.skuItemRef.reset()
              }
            },
          },
        },
        [
          _c(
            "div",
            [
              _c("Items", {
                ref: "skuItemRef",
                attrs: {
                  "task-id": _vm.taskId,
                  skus: _vm.skuDialog.skus,
                  scope: _vm.importScope + "",
                  "page-sku": _vm.pageTaskGoods,
                  boost: true,
                },
                on: {
                  "update:taskId": function ($event) {
                    _vm.taskId = $event
                  },
                  "update:task-id": function ($event) {
                    _vm.taskId = $event
                  },
                  "update:skus": function ($event) {
                    return _vm.$set(_vm.skuDialog, "skus", $event)
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.skuDialog.visible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: { click: _vm.submitTaskSku },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "排除店铺选择",
            visible: _vm.excludeShopDialog.visible,
            "append-to-body": "",
            width: "900px",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.excludeShopDialog, "visible", $event)
            },
            close: () => {
              if (_vm.$refs.shopItemRef) {
                _vm.$refs.shopItemRef.reset()
              }
            },
          },
        },
        [
          _c(
            "div",
            [
              _c("Items", {
                ref: "shopItemRef",
                attrs: {
                  "task-id": _vm.taskId,
                  compType: "shop",
                  shops: _vm.excludeShopDialog.shops,
                  scope: _vm.importScope + "",
                  boost: true,
                },
                on: {
                  "update:taskId": function ($event) {
                    _vm.taskId = $event
                  },
                  "update:task-id": function ($event) {
                    _vm.taskId = $event
                  },
                  "update:shops": function ($event) {
                    return _vm.$set(_vm.excludeShopDialog, "shops", $event)
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.excludeShopDialog.visible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: { click: _vm.submitExcludeShop },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "提示",
            visible: _vm.noticeDialog.visible,
            "append-to-body": "",
            width: "700px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.noticeDialog, "visible", $event)
            },
          },
        },
        [
          _c("div", {
            staticStyle: { "font-size": "16px" },
            domProps: { innerHTML: _vm._s(_vm.noticeDialog.msg) },
          }),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      _vm.noticeDialog.visible = false
                    },
                  },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }