var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "new-box" },
    [
      _c(
        "div",
        { staticClass: "mg-search" },
        [
          _c(
            "el-card",
            { staticStyle: { margin: "10px" } },
            [
              _c(
                "SearchFilter",
                {
                  on: { search: _vm.handleFilter, reset: _vm.resetQuery },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.handleQuery.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "分公司" } },
                    [
                      _c("DictSelect", {
                        ref: "org",
                        attrs: {
                          type: "order-org",
                          value: _vm.queryForm.orgCode,
                          linkageRef: "dc",
                          dictFun: _vm.dictFun,
                          fullLabel: "",
                          "default-first-option": "",
                          "default-index": 0,
                          clearable: false,
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(_vm.queryForm, "orgCode", $event)
                          },
                          changeLinkageRef: _vm.handleChangeLinkageRef,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "配送中心" } },
                    [
                      _c("DictSelect", {
                        ref: "dc",
                        attrs: {
                          type: "order-dc",
                          placeholder: "全部",
                          value: _vm.queryForm.dcCode,
                          dictFun: _vm.dictFun,
                          parentLinkageRef: "",
                          fullLabel: "",
                          clearable: true,
                          alwaysChange: "",
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(_vm.queryForm, "dcCode", $event)
                          },
                          getOtherJsonParam: (callback) =>
                            callback({ orgCode: _vm.queryForm.orgCode }),
                          changeLinkageRef: _vm.handleChangeLinkageRef,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-card",
        { staticClass: "box-card", staticStyle: { margin: "10px" } },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "customer-table",
              attrs: {
                data: _vm.pageData.rows,
                size: "small",
                border: "",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "分公司编码", prop: "orgCode", width: "150" },
              }),
              _c("el-table-column", {
                attrs: { label: "分公司名", prop: "orgName", width: "150" },
              }),
              _c("el-table-column", {
                attrs: { label: "配送中心编码", prop: "dcCode", width: "150" },
              }),
              _c("el-table-column", {
                attrs: { label: "配送中心名称", prop: "dcName", width: "200" },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "清单商品数量",
                  prop: "skuCount",
                  width: "150",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "补货周期" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        row.supplyTimeType === "week"
                          ? _c("span", [
                              _vm._v(
                                "每周" +
                                  _vm._s(
                                    _vm.replaceWeekDay(row.supplyTimeDayWeek)
                                  )
                              ),
                            ])
                          : _vm._e(),
                        row.supplyTimeType === "mth"
                          ? _c("span", [
                              _vm._v("每月" + _vm._s(row.supplyTimeDay) + "号"),
                            ])
                          : _vm._e(),
                        row.supplyTimeType === "half-mth"
                          ? _c("span", [_vm._v("每半月")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "补货规格", prop: "supplySpec" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.options.supplySpecOptions.filter(
                                (m) => m.value === row.supplySpec
                              )[0]?.label
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "起订量",
                  prop: "supplyThreshold",
                  width: "180",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        row.supplySpec === "bottom"
                          ? _c("span", [
                              _vm._v(
                                "<" +
                                  _vm._s(row.supplyThreshold) +
                                  "箱" +
                                  _vm._s(
                                    row.supplyBoxTransMid === 1
                                      ? "按中包装补货"
                                      : "不补货"
                                  )
                              ),
                              _c("br"),
                              _vm._v(
                                "≥" + _vm._s(row.supplyThreshold) + "箱补一箱"
                              ),
                            ])
                          : _vm._e(),
                        row.supplySpec === "mid"
                          ? _c("span", [_vm._v("1中包装")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "补货模式", prop: "supplyDays" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              row.modeType === "1" ? "自动补货" : "手动补货"
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "最后操作人",
                  prop: "updateUser",
                  width: "200",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(row.updateUser)),
                          _c("br"),
                          _vm._v(_vm._s(row.updateTime)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  width: "280px",
                  align: "center",
                  fixed: "right",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.openSkuDetail(row)
                              },
                            },
                          },
                          [_vm._v("商品清单")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.openConfig(row)
                              },
                            },
                          },
                          [_vm._v("补货设置")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.openSuggest(row)
                              },
                            },
                          },
                          [_vm._v("补货商品推荐")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.openSupplyConfirm(row)
                              },
                            },
                          },
                          [_vm._v("补货确认单")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.openLog(row)
                              },
                            },
                          },
                          [_vm._v("操作日志")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("Pagination", {
            attrs: {
              total: _vm.pageData.total,
              page: _vm.queryForm.current,
              limit: _vm.queryForm.size,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.queryForm, "current", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.queryForm, "size", $event)
              },
              pagination: _vm.handleQuery,
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "商品清单",
            visible: _vm.skuDetail.visible,
            "append-to-body": "",
            width: "1200px",
            height: "600px",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.skuDetail, "visible", $event)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "skuDetail",
              attrs: {
                size: "mini",
                "label-width": "auto",
                inline: true,
                model: _vm.skuDetail.queryForm,
              },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.handleQuerySkuDetail.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "分公司" } },
                [
                  _c("DictSelect", {
                    ref: "skuDetailOrg",
                    attrs: {
                      type: "order-org",
                      value: _vm.skuDetail.queryForm.orgCode,
                      linkageRef: "skuDetailDc",
                      dictFun: _vm.dictFun,
                      fullLabel: "",
                      "default-first-option": "",
                      "default-index": 0,
                      clearable: false,
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(
                          _vm.skuDetail.queryForm,
                          "orgCode",
                          $event
                        )
                      },
                      changeLinkageRef: _vm.handleChangeLinkageRef,
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "配送中心" } },
                [
                  _c("DictSelect", {
                    ref: "skuDetailDc",
                    attrs: {
                      type: "order-dc",
                      placeholder: "全部",
                      value: _vm.skuDetail.queryForm.dcCode,
                      dictFun: _vm.dictFun,
                      parentLinkageRef: "",
                      fullLabel: "",
                      "default-index": 0,
                      clearable: true,
                      alwaysChange: "",
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(
                          _vm.skuDetail.queryForm,
                          "dcCode",
                          $event
                        )
                      },
                      getOtherJsonParam: (callback) =>
                        callback({ orgCode: _vm.skuDetail.queryForm.orgCode }),
                      changeLinkageRef: _vm.handleChangeLinkageRef,
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "档位" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "", placeholder: "全部" },
                      model: {
                        value: _vm.skuDetail.queryForm.skuLevel,
                        callback: function ($$v) {
                          _vm.$set(_vm.skuDetail.queryForm, "skuLevel", $$v)
                        },
                        expression: "skuDetail.queryForm.skuLevel",
                      },
                    },
                    _vm._l(_vm.options.skuLevelOptions, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "商品分类" } },
                [
                  _c("GoodCategory", {
                    on: { selected: _vm.querySkuTreeNodeClick },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "是否238" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "", placeholder: "全部" },
                      model: {
                        value: _vm.skuDetail.queryForm.if238,
                        callback: function ($$v) {
                          _vm.$set(_vm.skuDetail.queryForm, "if238", $$v)
                        },
                        expression: "skuDetail.queryForm.if238",
                      },
                    },
                    _vm._l(_vm.options.if238Options, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "新老品" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "", placeholder: "全部" },
                      model: {
                        value: _vm.skuDetail.queryForm.isNewSku,
                        callback: function ($$v) {
                          _vm.$set(_vm.skuDetail.queryForm, "isNewSku", $$v)
                        },
                        expression: "skuDetail.queryForm.isNewSku",
                      },
                    },
                    _vm._l(_vm.options.isNewSkuOptions, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "搜索商品" } },
                [
                  _c("el-input", {
                    attrs: {
                      size: "mini",
                      clearable: "",
                      placeholder: "输入商品名、商品编码",
                    },
                    model: {
                      value: _vm.skuDetail.queryForm.searchKey,
                      callback: function ($$v) {
                        _vm.$set(_vm.skuDetail.queryForm, "searchKey", $$v)
                      },
                      expression: "skuDetail.queryForm.searchKey",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "form-btn-item",
                      attrs: {
                        size: "mini",
                        icon: "el-icon-search",
                        type: "primary",
                      },
                      on: { click: _vm.handleFilterSkuDetail },
                    },
                    [_vm._v("查询")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "form-btn-item",
                      attrs: {
                        size: "mini",
                        icon: "el-icon-plus",
                        type: "primary",
                      },
                      on: {
                        click: function ($event) {
                          _vm.skuDetail.importDialog.visible = true
                        },
                      },
                    },
                    [_vm._v("导入商品")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "form-btn-item",
                      attrs: {
                        size: "mini",
                        icon: "el-icon-download",
                        type: "primary",
                      },
                      on: { click: _vm.handleExportSkuDetail },
                    },
                    [_vm._v("导出商品")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "form-btn-item edit",
                      attrs: {
                        size: "mini",
                        icon: "el-icon-s-operation",
                        type: "warning",
                      },
                      on: { click: _vm.openBatchUpdateSku },
                    },
                    [_vm._v("批量编辑")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "form-btn-item edit",
                      attrs: {
                        size: "mini",
                        icon: "el-icon-delete",
                        type: "warning",
                      },
                      on: { click: _vm.handleBatchRemoveSku },
                    },
                    [_vm._v("批量删除")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.skuDetail.loading,
                  expression: "skuDetail.loading",
                },
              ],
              attrs: {
                data: _vm.skuDetail.pageData.rows,
                height: "calc(100vh - 250px)",
                size: "mini",
                border: "",
                "highlight-current-row": "",
                "header-cell-style": {
                  background: "rgb(227 224 224)",
                  color: "black",
                },
                "max-height": "340px",
              },
              on: {
                "sort-change": _vm.handleSortChange,
                "selection-change": _vm.handleSelectionChange,
              },
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "30" },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "分公司编码",
                  prop: "orgCode",
                  width: "80",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "分公司名",
                  prop: "orgName",
                  width: "80",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "配送中心编码",
                  prop: "dcCode",
                  width: "90",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "配送中心名称",
                  prop: "dcName",
                  width: "90",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "商品编码",
                  prop: "skuCode",
                  width: "80",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "商品名",
                  prop: "skuName",
                  width: "300",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "档位",
                  prop: "skuLevel",
                  width: "40",
                  align: "center",
                },
              }),
              _c(
                "el-table-column",
                {
                  attrs: {
                    label: "近半年销量",
                    prop: "last180XsCount",
                    width: "100",
                    align: "center",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function ({ row }) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(row.last180XsCountBox.toFixed(2)) +
                                "箱(" +
                                _vm._s(row.last180XsShopCount) +
                                "客户) "
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c(
                    "template",
                    { slot: "header" },
                    [
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            content: "不含大仓出货数据",
                            placement: "top-start",
                          },
                        },
                        [
                          _c("span", [
                            _vm._v("近半年销量"),
                            _c("i", { staticClass: "el-icon-question" }),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
              _c("el-table-column", {
                attrs: {
                  label: "配送中心库存",
                  prop: "dcKcCountBox",
                  sortable: "custom",
                  width: "120",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(row.dcKcCountBox.toFixed(2)) + "箱 "),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "库存够卖天数",
                  prop: "kcCanSaleDay",
                  sortable: "custom",
                  width: "120",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("span", [_vm._v(_vm._s(row.kcCanSaleDay) + "天 ")]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "日常补货阈值",
                  prop: "supplyDays",
                  width: "100",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              row.supplyNumType === 0
                                ? row.supplyDays + "天"
                                : row.supplyBoxNum + "箱"
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "学汛补货阈值",
                  prop: "xxSupplyDays",
                  width: "100",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              row.supplyNumType === 0
                                ? row.xxSupplyDays + "天"
                                : row.xxSupplyBoxNum + "箱"
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "修改人",
                  prop: "updateUser",
                  width: "100",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "修改时间",
                  prop: "updateTime",
                  width: "150",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  width: "100",
                  align: "center",
                  fixed: "right",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "el-button",
                          {
                            staticStyle: {
                              border: "0px",
                              width: "20%",
                              "margin-right": "15px",
                            },
                            attrs: { size: "mini", type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleEditSku(row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _c(
                          "el-button",
                          {
                            staticStyle: { border: "0px", width: "20%" },
                            attrs: { size: "mini", type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleRemoveSku(row)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("Pagination", {
            attrs: {
              total: _vm.skuDetail.pageData.total,
              page: _vm.skuDetail.queryForm.page,
              limit: _vm.skuDetail.queryForm.limit,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.skuDetail.queryForm, "page", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.skuDetail.queryForm, "limit", $event)
              },
              pagination: _vm.handleQuerySkuDetail,
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "编辑商品",
            visible: _vm.skuDetail.saveDialog.visible,
            "append-to-body": "",
            width: "400px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.skuDetail.saveDialog, "visible", $event)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "editSku",
              staticClass: "common-form",
              attrs: {
                size: "mini",
                inline: "",
                rules: _vm.skuDetail.saveDialog.rules,
                "label-width": "160px",
                model: _vm.skuDetail.saveDialog.form,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "档位" } },
                [
                  _c(
                    "el-select",
                    {
                      model: {
                        value: _vm.skuDetail.saveDialog.form.skuLevel,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.skuDetail.saveDialog.form,
                            "skuLevel",
                            $$v
                          )
                        },
                        expression: "skuDetail.saveDialog.form.skuLevel",
                      },
                    },
                    _vm._l(_vm.options.skuLevelOptions, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "补货阈值" } },
                [
                  _c(
                    "el-select",
                    {
                      model: {
                        value: _vm.skuDetail.saveDialog.form.supplyNumType,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.skuDetail.saveDialog.form,
                            "supplyNumType",
                            $$v
                          )
                        },
                        expression: "skuDetail.saveDialog.form.supplyNumType",
                      },
                    },
                    _vm._l(_vm.options.supplyNumTypeOptions, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "日常补货", prop: "supplyDays" } },
                [
                  _vm.skuDetail.saveDialog.form.supplyNumType === 0
                    ? _c(
                        "el-col",
                        { attrs: { span: 10 } },
                        [
                          _c("el-input", {
                            attrs: { type: "number" },
                            nativeOn: {
                              input: function ($event) {
                                _vm.skuDetail.saveDialog.form.supplyDays =
                                  _vm.skuDetail.saveDialog.form.supplyDays.replace(
                                    /^(0+)|[^\d]+/g,
                                    ""
                                  )
                              },
                            },
                            model: {
                              value: _vm.skuDetail.saveDialog.form.supplyDays,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.skuDetail.saveDialog.form,
                                  "supplyDays",
                                  $$v
                                )
                              },
                              expression:
                                "skuDetail.saveDialog.form.supplyDays",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.skuDetail.saveDialog.form.supplyNumType === 0
                    ? _c("el-col", { attrs: { span: 5 } }, [_vm._v("天库存")])
                    : _vm._e(),
                  _vm.skuDetail.saveDialog.form.supplyNumType === 1
                    ? _c(
                        "el-col",
                        { attrs: { span: 10 } },
                        [
                          _c("el-input", {
                            attrs: { type: "number" },
                            nativeOn: {
                              input: function ($event) {
                                _vm.skuDetail.saveDialog.form.supplyBoxNum =
                                  _vm.skuDetail.saveDialog.form.supplyBoxNum.replace(
                                    /^(0+)|[^\d]+/g,
                                    ""
                                  )
                              },
                            },
                            model: {
                              value: _vm.skuDetail.saveDialog.form.supplyBoxNum,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.skuDetail.saveDialog.form,
                                  "supplyBoxNum",
                                  $$v
                                )
                              },
                              expression:
                                "skuDetail.saveDialog.form.supplyBoxNum",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.skuDetail.saveDialog.form.supplyNumType === 1
                    ? _c("el-col", { attrs: { span: 5 } }, [_vm._v("箱")])
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "学汛补货", prop: "xxSupplyDays" } },
                [
                  _vm.skuDetail.saveDialog.form.supplyNumType === 0
                    ? _c(
                        "el-col",
                        { attrs: { span: 10 } },
                        [
                          _c("el-input", {
                            attrs: { type: "number" },
                            nativeOn: {
                              input: function ($event) {
                                _vm.skuDetail.saveDialog.form.xxSupplyDays =
                                  _vm.skuDetail.saveDialog.form.xxSupplyDays.replace(
                                    /^(0+)|[^\d]+/g,
                                    ""
                                  )
                              },
                            },
                            model: {
                              value: _vm.skuDetail.saveDialog.form.xxSupplyDays,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.skuDetail.saveDialog.form,
                                  "xxSupplyDays",
                                  $$v
                                )
                              },
                              expression:
                                "skuDetail.saveDialog.form.xxSupplyDays",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.skuDetail.saveDialog.form.supplyNumType === 0
                    ? _c("el-col", { attrs: { span: 5 } }, [_vm._v("天库存")])
                    : _vm._e(),
                  _vm.skuDetail.saveDialog.form.supplyNumType === 1
                    ? _c(
                        "el-col",
                        { attrs: { span: 10 } },
                        [
                          _c("el-input", {
                            attrs: { type: "number" },
                            nativeOn: {
                              input: function ($event) {
                                _vm.skuDetail.saveDialog.form.xxSupplyBoxNum =
                                  _vm.skuDetail.saveDialog.form.xxSupplyBoxNum.replace(
                                    /^(0+)|[^\d]+/g,
                                    ""
                                  )
                              },
                            },
                            model: {
                              value:
                                _vm.skuDetail.saveDialog.form.xxSupplyBoxNum,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.skuDetail.saveDialog.form,
                                  "xxSupplyBoxNum",
                                  $$v
                                )
                              },
                              expression:
                                "skuDetail.saveDialog.form.xxSupplyBoxNum",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.skuDetail.saveDialog.form.supplyNumType === 1
                    ? _c("el-col", { attrs: { span: 5 } }, [_vm._v("箱")])
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "修改人", prop: "updateUser" } },
                [
                  _c("span", [
                    _vm._v(_vm._s(_vm.skuDetail.saveDialog.form.updateUser)),
                  ]),
                ]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "修改时间", prop: "updateTime" } },
                [
                  _c("span", [
                    _vm._v(_vm._s(_vm.skuDetail.saveDialog.form.updateTime)),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "center" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: { click: _vm.submitEditSku },
                },
                [_vm._v("确定")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.skuDetail.saveDialog.visible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "批量编辑商品",
            visible: _vm.skuDetail.batchUpdate.visible,
            "append-to-body": "",
            width: "400px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.skuDetail.batchUpdate, "visible", $event)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "batchUpdateSku",
              staticClass: "common-form",
              attrs: {
                size: "mini",
                inline: "",
                rules: _vm.skuDetail.batchUpdate.rules,
                "label-width": "160px",
                model: _vm.skuDetail.batchUpdate.form,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "档位" } },
                [
                  _c(
                    "el-select",
                    {
                      model: {
                        value: _vm.skuDetail.batchUpdate.form.skuLevel,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.skuDetail.batchUpdate.form,
                            "skuLevel",
                            $$v
                          )
                        },
                        expression: "skuDetail.batchUpdate.form.skuLevel",
                      },
                    },
                    _vm._l(_vm.options.skuLevelOptions, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "补货阈值" } },
                [
                  _c(
                    "el-select",
                    {
                      model: {
                        value: _vm.skuDetail.batchUpdate.form.supplyNumType,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.skuDetail.batchUpdate.form,
                            "supplyNumType",
                            $$v
                          )
                        },
                        expression: "skuDetail.batchUpdate.form.supplyNumType",
                      },
                    },
                    _vm._l(_vm.options.supplyNumTypeOptions, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "日常补货", prop: "supplyDays" } },
                [
                  _vm.skuDetail.batchUpdate.form.supplyNumType === 0
                    ? _c(
                        "el-col",
                        { attrs: { span: 10 } },
                        [
                          _c("el-input", {
                            attrs: { type: "number" },
                            nativeOn: {
                              input: function ($event) {
                                _vm.skuDetail.batchUpdate.form.supplyDays =
                                  _vm.skuDetail.batchUpdate.form.supplyDays.replace(
                                    /^(0+)|[^\d]+/g,
                                    ""
                                  )
                              },
                            },
                            model: {
                              value: _vm.skuDetail.batchUpdate.form.supplyDays,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.skuDetail.batchUpdate.form,
                                  "supplyDays",
                                  $$v
                                )
                              },
                              expression:
                                "skuDetail.batchUpdate.form.supplyDays",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.skuDetail.batchUpdate.form.supplyNumType === 0
                    ? _c("el-col", { attrs: { span: 5 } }, [_vm._v("天库存")])
                    : _vm._e(),
                  _vm.skuDetail.batchUpdate.form.supplyNumType === 1
                    ? _c(
                        "el-col",
                        { attrs: { span: 10 } },
                        [
                          _c("el-input", {
                            attrs: { type: "number" },
                            nativeOn: {
                              input: function ($event) {
                                _vm.skuDetail.batchUpdate.form.supplyBoxNum =
                                  _vm.skuDetail.batchUpdate.form.supplyBoxNum.replace(
                                    /^(0+)|[^\d]+/g,
                                    ""
                                  )
                              },
                            },
                            model: {
                              value:
                                _vm.skuDetail.batchUpdate.form.supplyBoxNum,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.skuDetail.batchUpdate.form,
                                  "supplyBoxNum",
                                  $$v
                                )
                              },
                              expression:
                                "skuDetail.batchUpdate.form.supplyBoxNum",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.skuDetail.batchUpdate.form.supplyNumType === 1
                    ? _c("el-col", { attrs: { span: 5 } }, [_vm._v("箱")])
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "学汛补货", prop: "xxSupplyDays" } },
                [
                  _vm.skuDetail.batchUpdate.form.supplyNumType === 0
                    ? _c(
                        "el-col",
                        { attrs: { span: 10 } },
                        [
                          _c("el-input", {
                            attrs: { type: "number" },
                            nativeOn: {
                              input: function ($event) {
                                _vm.skuDetail.batchUpdate.form.xxSupplyDays =
                                  _vm.skuDetail.batchUpdate.form.xxSupplyDays.replace(
                                    /^(0+)|[^\d]+/g,
                                    ""
                                  )
                              },
                            },
                            model: {
                              value:
                                _vm.skuDetail.batchUpdate.form.xxSupplyDays,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.skuDetail.batchUpdate.form,
                                  "xxSupplyDays",
                                  $$v
                                )
                              },
                              expression:
                                "skuDetail.batchUpdate.form.xxSupplyDays",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.skuDetail.batchUpdate.form.supplyNumType === 0
                    ? _c("el-col", { attrs: { span: 5 } }, [_vm._v("天库存")])
                    : _vm._e(),
                  _vm.skuDetail.batchUpdate.form.supplyNumType === 1
                    ? _c(
                        "el-col",
                        { attrs: { span: 10 } },
                        [
                          _c("el-input", {
                            attrs: { type: "number" },
                            nativeOn: {
                              input: function ($event) {
                                _vm.skuDetail.batchUpdate.form.xxSupplyBoxNum =
                                  _vm.skuDetail.batchUpdate.form.xxSupplyBoxNum.replace(
                                    /^(0+)|[^\d]+/g,
                                    ""
                                  )
                              },
                            },
                            model: {
                              value:
                                _vm.skuDetail.batchUpdate.form.xxSupplyBoxNum,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.skuDetail.batchUpdate.form,
                                  "xxSupplyBoxNum",
                                  $$v
                                )
                              },
                              expression:
                                "skuDetail.batchUpdate.form.xxSupplyBoxNum",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.skuDetail.batchUpdate.form.supplyNumType === 1
                    ? _c("el-col", { attrs: { span: 5 } }, [_vm._v("箱")])
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "center" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: { click: _vm.submitBatchUpdateSku },
                },
                [_vm._v("确定")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.skuDetail.batchUpdate.visible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.skuDetail.importDialog.title,
            visible: _vm.skuDetail.importDialog.visible,
            "append-to-body": "",
            width: "1000px",
            height: "800px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.skuDetail.importDialog, "visible", $event)
            },
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                {
                  staticStyle: { "font-weight": "bold" },
                  attrs: { span: 5, align: "right" },
                },
                [_vm._v("文件：")]
              ),
              _c(
                "el-col",
                { attrs: { span: 10 } },
                [
                  _c(
                    "el-upload",
                    {
                      ref: "goodsUpload",
                      staticClass: "upload-demo",
                      attrs: {
                        drag: "",
                        limit: 1,
                        action: "#",
                        "auto-upload": false,
                        "file-list": _vm.skuDetail.importDialog.fileList,
                        "on-change": (file, fileList) =>
                          _vm.handleFileChange(file, fileList, "change"),
                        "on-remove": (file, fileList) =>
                          _vm.handleFileChange(file, fileList, "remove"),
                      },
                    },
                    [
                      _c("i", { staticClass: "el-icon-upload" }),
                      _c("div", { staticClass: "el-upload__text" }, [
                        _vm._v("将文件拖到此处，或"),
                        _c("em", [_vm._v("点击上传")]),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "el-upload__tip",
                          attrs: { slot: "tip" },
                          slot: "tip",
                        },
                        [
                          _c("span", [_vm._v("仅允许导入xls、xlsx格式文件。")]),
                          _c(
                            "el-link",
                            {
                              staticStyle: {
                                "font-size": "12px",
                                "vertical-align": "baseline",
                              },
                              attrs: {
                                type: "primary",
                                underline: false,
                                target: "_blank",
                                href: _vm.skuDetail.importDialog
                                  .templateFileDownloadUrl,
                              },
                            },
                            [_vm._v("下载模板")]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { staticStyle: { "margin-top": "20px" } },
            [
              _c("el-col", { attrs: { span: 15, offset: 5, align: "left" } }, [
                _c(
                  "div",
                  { staticClass: "previewTable previewTable-sample" },
                  [
                    _c("mg-signboard", {
                      attrs: { title: "文件上传模板示例" },
                    }),
                    _c(
                      "el-table",
                      {
                        staticClass: "previewTable-lists",
                        attrs: {
                          data: _vm.skuDetail.importDialog.templateSampleLists,
                          stripe: "",
                          border: "",
                          size: "mini",
                        },
                      },
                      [
                        _vm._l(
                          _vm.skuDetail.importDialog.templateSampleHead,
                          function (item, index) {
                            return [
                              _c(
                                "el-table-column",
                                _vm._b(
                                  {
                                    key: index,
                                    attrs: {
                                      "show-overflow-tooltip": "",
                                      align: "center",
                                    },
                                  },
                                  "el-table-column",
                                  item,
                                  false
                                ),
                                [
                                  _c(
                                    "template",
                                    { slot: "header" },
                                    [
                                      item.prop === "skuLevel"
                                        ? _c(
                                            "el-tooltip",
                                            {
                                              staticClass: "item",
                                              attrs: {
                                                effect: "dark",
                                                content:
                                                  "A档(长线品，不经常调整)，B档(经常调整的商品，需定期维护)",
                                                placement: "top-start",
                                              },
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(_vm._s(item.label)),
                                                _c("i", {
                                                  staticClass:
                                                    "el-icon-question",
                                                }),
                                              ]),
                                            ]
                                          )
                                        : _c("span", [
                                            _vm._v(_vm._s(item.label)),
                                          ]),
                                    ],
                                    1
                                  ),
                                ],
                                2
                              ),
                            ]
                          }
                        ),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "el-row",
            { staticStyle: { "margin-top": "20px" } },
            [
              _c(
                "el-col",
                {
                  staticStyle: { "font-weight": "bold" },
                  attrs: { span: 5, align: "right" },
                },
                [_vm._v("导入方式：")]
              ),
              _c(
                "el-col",
                { attrs: { span: 10 } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.skuDetail.importDialog.importType,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.skuDetail.importDialog,
                            "importType",
                            $$v
                          )
                        },
                        expression: "skuDetail.importDialog.importType",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("新增")]),
                      _c("el-radio", { attrs: { label: 2 } }, [_vm._v("覆盖")]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { staticStyle: { "margin-top": "20px", "margin-bottom": "20px" } },
            [
              _c(
                "el-col",
                { attrs: { span: 10, offset: 5, align: "left" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini", type: "success" },
                      on: { click: _vm.handleImport },
                    },
                    [_vm._v("导入")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini", type: "info" },
                      on: {
                        click: function ($event) {
                          _vm.skuDetail.importDialog.visible = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "补货设置",
            visible: _vm.supplyConfig.visible,
            width: "400px",
            "append-to-body": "",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.supplyConfig, "visible", $event)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "supplyConfig",
              staticClass: "common-form",
              attrs: {
                inline: "",
                model: _vm.supplyConfig.form,
                "label-width": "100px",
                rules: _vm.supplyConfig.rules,
              },
            },
            [
              _c(
                "el-row",
                { staticClass: "common-form-row" },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24, offset: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "", prop: "modeType", size: "mini" },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              attrs: { "text-color": "white", fill: "red" },
                              model: {
                                value: _vm.supplyConfig.form.modeType,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.supplyConfig.form,
                                    "modeType",
                                    $$v
                                  )
                                },
                                expression: "supplyConfig.form.modeType",
                              },
                            },
                            [
                              _c("el-radio-button", { attrs: { label: "1" } }, [
                                _vm._v("自动补货"),
                              ]),
                              _c("el-radio-button", { attrs: { label: "2" } }, [
                                _vm._v("手动补货"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              this.supplyConfig.form.modeType === "2"
                ? _c(
                    "el-row",
                    { staticClass: "common-form-row" },
                    [
                      _c(
                        "el-col",
                        {
                          staticStyle: {
                            "font-weight": "bold",
                            color: "red",
                            "margin-bottom": "20px",
                            "margin-top": "20px",
                          },
                          attrs: { span: 20, offset: 4 },
                        },
                        [_vm._v("请在聚宝盆App上手动确认发起补货单")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-row",
                { staticClass: "bh-row" },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "bh-label",
                      attrs: { span: 10, align: "right" },
                    },
                    [_vm._v("补货周期：")]
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 4 } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { size: "mini" },
                          model: {
                            value: _vm.supplyConfig.form.supplyTimeType,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.supplyConfig.form,
                                "supplyTimeType",
                                $$v
                              )
                            },
                            expression: "supplyConfig.form.supplyTimeType",
                          },
                        },
                        _vm._l(
                          _vm.options.supplyTimeTypeOptions,
                          function (item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.label, value: item.value },
                            })
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.supplyConfig.form.supplyTimeType === "week"
                    ? _c(
                        "el-col",
                        { key: "supplyTimeDayListForWeek", attrs: { span: 6 } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { multiple: "", size: "mini" },
                              model: {
                                value:
                                  _vm.supplyConfig.form
                                    .supplyTimeDayListForWeek,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.supplyConfig.form,
                                    "supplyTimeDayListForWeek",
                                    $$v
                                  )
                                },
                                expression:
                                  "supplyConfig.form.supplyTimeDayListForWeek",
                              },
                            },
                            _vm._l(
                              _vm.options.supplyTimeDayOptions,
                              function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.supplyConfig.form.supplyTimeType === "mth"
                    ? _c(
                        "el-col",
                        { key: "supplyTimeDay", attrs: { span: 6 } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { size: "mini" },
                              model: {
                                value: _vm.supplyConfig.form.supplyTimeDay,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.supplyConfig.form,
                                    "supplyTimeDay",
                                    $$v
                                  )
                                },
                                expression: "supplyConfig.form.supplyTimeDay",
                              },
                            },
                            _vm._l(
                              _vm.options.supplyTimeMonthDayOptions,
                              function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "bh-row" },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "bh-label",
                      attrs: { span: 10, align: "right" },
                    },
                    [_vm._v("补货规格：")]
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 5 } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { size: "mini" },
                          on: {
                            change: function ($event) {
                              return _vm.selectTrigger(
                                _vm.supplyConfig.form.supplySpec
                              )
                            },
                          },
                          model: {
                            value: _vm.supplyConfig.form.supplySpec,
                            callback: function ($$v) {
                              _vm.$set(_vm.supplyConfig.form, "supplySpec", $$v)
                            },
                            expression: "supplyConfig.form.supplySpec",
                          },
                        },
                        _vm._l(_vm.options.supplySpecOptions, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.supplyConfig.form.supplySpec === "bottom"
                ? _c(
                    "el-row",
                    { staticClass: "bh-row" },
                    [
                      _c(
                        "el-col",
                        {
                          staticClass: "bh-label",
                          attrs: { span: 10, align: "right" },
                        },
                        [_vm._v("阈值：")]
                      ),
                      _c(
                        "el-col",
                        {
                          staticStyle: { "margin-top": "5px" },
                          attrs: { span: 1.5, align: "right" },
                        },
                        [_vm._v("<")]
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 4 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { prop: "supplyThreshold", size: "mini" },
                            },
                            [
                              _c("el-input", {
                                attrs: { type: "number" },
                                model: {
                                  value: _vm.supplyConfig.form.supplyThreshold,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.supplyConfig.form,
                                      "supplyThreshold",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "supplyConfig.form.supplyThreshold",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        {
                          staticStyle: { "margin-top": "5px" },
                          attrs: { span: 1 },
                        },
                        [_vm._v("箱")]
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { size: "mini" },
                              model: {
                                value: _vm.supplyConfig.form.supplyBoxTransMid,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.supplyConfig.form,
                                    "supplyBoxTransMid",
                                    $$v
                                  )
                                },
                                expression:
                                  "supplyConfig.form.supplyBoxTransMid",
                              },
                            },
                            _vm._l(
                              _vm.options.supplySpecThresholdOptions,
                              function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.supplyConfig.form.supplySpec === "bottom"
                ? _c(
                    "el-row",
                    { staticClass: "bh-row" },
                    [
                      _c(
                        "el-col",
                        {
                          staticStyle: { "margin-top": "5px" },
                          attrs: { offset: 10, span: 1.5, align: "right" },
                        },
                        [_vm._v(">=")]
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 4 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { prop: "supplyThreshold", size: "mini" },
                            },
                            [
                              _c("el-input", {
                                attrs: { type: "number" },
                                model: {
                                  value: _vm.supplyConfig.form.supplyThreshold,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.supplyConfig.form,
                                      "supplyThreshold",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "supplyConfig.form.supplyThreshold",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.supplyConfig.form.supplySpec === "bottom"
                        ? _c(
                            "el-col",
                            {
                              staticStyle: { "margin-top": "5px" },
                              attrs: { span: 6 },
                            },
                            [_vm._v("箱则补1箱")]
                          )
                        : _c(
                            "el-col",
                            {
                              staticStyle: { "margin-top": "5px" },
                              attrs: { span: 4 },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.options.supplySpecOptions.filter(
                                      (m) =>
                                        m.value ===
                                        _vm.supplyConfig.form.supplySpec
                                    )[0].label
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                    ],
                    1
                  )
                : _c(
                    "el-row",
                    { staticClass: "bh-row" },
                    [
                      _c(
                        "el-col",
                        {
                          staticClass: "bh-label",
                          attrs: { span: 10, align: "right" },
                        },
                        [_vm._v("起订量：")]
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 4 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { prop: "supplyThreshold", size: "mini" },
                            },
                            [
                              _c("el-input", {
                                attrs: { type: "number" },
                                model: {
                                  value: _vm.supplyConfig.form.supplyThreshold,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.supplyConfig.form,
                                      "supplyThreshold",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "supplyConfig.form.supplyThreshold",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.supplyConfig.form.supplySpec === "bottom"
                        ? _c(
                            "el-col",
                            {
                              staticStyle: { "margin-top": "5px" },
                              attrs: { span: 6 },
                            },
                            [_vm._v("箱则补1箱")]
                          )
                        : _vm._e(),
                      _c(
                        "el-col",
                        {
                          staticStyle: { "margin-top": "5px" },
                          attrs: { span: 4 },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.options.supplySpecOptions.filter(
                                  (m) =>
                                    m.value === _vm.supplyConfig.form.supplySpec
                                )[0].label
                              ) +
                              " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
              _c(
                "el-row",
                { staticClass: "bh-row" },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "bh-label",
                      attrs: { span: 10, align: "right" },
                    },
                    [_vm._v("修改人：")]
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-top": "5px" },
                      attrs: { span: 5 },
                    },
                    [
                      _c("span", { staticStyle: { "margin-top": "5px" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.supplyConfig.form.updateUser === null
                              ? _vm.supplyConfig.form.createUser
                              : _vm.supplyConfig.form.updateUser
                          )
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                { staticClass: "bh-row" },
                [
                  _c(
                    "el-col",
                    {
                      staticClass: "bh-label",
                      attrs: { span: 10, align: "right" },
                    },
                    [_vm._v("修改时间：")]
                  ),
                  _c(
                    "el-col",
                    {
                      staticStyle: { "margin-top": "5px" },
                      attrs: { span: 12 },
                    },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.supplyConfig.form.updateTime === null
                              ? _vm.supplyConfig.form.createTime
                              : _vm.supplyConfig.form.updateTime
                          )
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.supplyConfig.visible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: { click: _vm.submitSupplyConfig },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.supplySuggest.visible,
            "custom-class": "previewDialog",
            "append-to-body": "",
            width: "1000px",
            "close-on-click-modal": false,
            "max-height": "400px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.supplySuggest, "visible", $event)
            },
          },
        },
        [
          _c(
            "el-tabs",
            { attrs: { type: "border-card", value: "first", stretch: true } },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "238及其他重点", name: "first" } },
                [
                  _c(
                    "el-form",
                    {
                      attrs: {
                        size: "mini",
                        "label-width": "auto",
                        inline: true,
                        model: _vm.supplySuggest.queryForm1,
                      },
                      nativeOn: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.querySuggest1.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c(
                        "el-row",
                        { staticClass: "common-form-row" },
                        [
                          _c(
                            "el-col",
                            { staticClass: "bh-tip", attrs: { span: 24 } },
                            [
                              _vm._v(
                                "总部推荐清单，含238商品及其他重点产品，可供参考挑选需自动补货的商品"
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品分类" } },
                        [
                          _c("GoodCategory", {
                            on: { selected: _vm.supplySuggestTreeNodeClick1 },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "是否238" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { clearable: "", placeholder: "全部" },
                              model: {
                                value: _vm.supplySuggest.queryForm1.if238,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.supplySuggest.queryForm1,
                                    "if238",
                                    $$v
                                  )
                                },
                                expression: "supplySuggest.queryForm1.if238",
                              },
                            },
                            _vm._l(_vm.options.if238Options, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "新老品" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { clearable: "", placeholder: "全部" },
                              model: {
                                value: _vm.supplySuggest.queryForm1.isNewSku,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.supplySuggest.queryForm1,
                                    "isNewSku",
                                    $$v
                                  )
                                },
                                expression: "supplySuggest.queryForm1.isNewSku",
                              },
                            },
                            _vm._l(
                              _vm.options.isNewSkuOptions,
                              function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "搜索商品" } },
                        [
                          _c("el-input", {
                            attrs: {
                              size: "mini",
                              clearable: "",
                              placeholder: "输入商品名、商品编码",
                            },
                            model: {
                              value: _vm.supplySuggest.queryForm1.searchKey,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.supplySuggest.queryForm1,
                                  "searchKey",
                                  $$v
                                )
                              },
                              expression: "supplySuggest.queryForm1.searchKey",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "form-btn-item",
                              attrs: {
                                size: "mini",
                                icon: "el-icon-search",
                                type: "primary",
                              },
                              on: { click: _vm.querySuggest1 },
                            },
                            [_vm._v("查询")]
                          ),
                          _c(
                            "el-button",
                            {
                              staticClass: "form-btn-item",
                              attrs: {
                                size: "mini",
                                icon: "el-icon-download",
                                type: "primary",
                              },
                              on: { click: _vm.handleExportSuggest1 },
                            },
                            [_vm._v("导出")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-table",
                    {
                      attrs: {
                        data: _vm.supplySuggest.data1,
                        "v-loading": _vm.supplySuggest.loading,
                        size: "mini",
                        height: "300px",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: "排序",
                          prop: "sort",
                          width: "50",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "商品编码",
                          prop: "skuCode",
                          width: "80",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "所属型号",
                          prop: "jbpSpuCode",
                          width: "80",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "商品名",
                          prop: "skuName",
                          width: "200",
                          align: "center",
                        },
                      }),
                      _c(
                        "el-table-column",
                        {
                          attrs: { width: "100", align: "center" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({ row }) {
                                return [
                                  _c("span", [
                                    _vm._v(_vm._s(row.l180XsCount)),
                                    _c("br"),
                                    _vm._v(
                                      "(" +
                                        _vm._s(row.l180XsCountBox.toFixed(2)) +
                                        "箱)"
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ]),
                        },
                        [
                          _c(
                            "template",
                            { slot: "header" },
                            [
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    effect: "dark",
                                    content: "不含大仓出货数据",
                                    placement: "top-start",
                                  },
                                },
                                [
                                  _c("span", [
                                    _vm._v("近半年销量"),
                                    _c("i", {
                                      staticClass: "el-icon-question",
                                    }),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                      _c("el-table-column", {
                        attrs: {
                          label: "近半年销售店铺数",
                          prop: "l180XsShopCount",
                          width: "120",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "月均销量",
                          prop: "monthlyXsCountBox",
                          width: "100",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(row.monthlyXsCountBox.toFixed(2)) +
                                      "箱"
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "配送中心库存",
                          prop: "dcKcCountBox",
                          width: "100",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(row.dcKcCountBox.toFixed(2)) + "箱"
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "分公司库存",
                          prop: "orgKcStr",
                          width: "80",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "新老品",
                          prop: "isNew",
                          width: "80",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "是否238",
                          prop: "is238",
                          width: "80",
                          align: "center",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("Pagination", {
                    attrs: {
                      total: _vm.supplySuggest.pageData1.total,
                      page: _vm.supplySuggest.queryForm1.page,
                      limit: _vm.supplySuggest.queryForm1.limit,
                    },
                    on: {
                      "update:page": function ($event) {
                        return _vm.$set(
                          _vm.supplySuggest.queryForm1,
                          "page",
                          $event
                        )
                      },
                      "update:limit": function ($event) {
                        return _vm.$set(
                          _vm.supplySuggest.queryForm1,
                          "limit",
                          $event
                        )
                      },
                      pagination: _vm.querySuggest1,
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "其他商品", name: "second" } },
                [
                  _c(
                    "el-form",
                    {
                      attrs: {
                        size: "mini",
                        "label-width": "auto",
                        inline: true,
                        model: _vm.supplySuggest.queryForm,
                      },
                      nativeOn: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.querySuggest.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c(
                        "el-row",
                        { staticClass: "common-form-row" },
                        [
                          _c(
                            "el-col",
                            { staticClass: "bh-tip", attrs: { span: 24 } },
                            [
                              _vm._v(
                                "本配送中心近半年(180天)销量前1000名的商品清单(SKU)，可供参考挑选需自动补货的商品"
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "商品分类" } },
                        [
                          _c("GoodCategory", {
                            on: { selected: _vm.supplySuggestTreeNodeClick },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "是否238" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { clearable: "", placeholder: "全部" },
                              model: {
                                value: _vm.supplySuggest.queryForm.if238,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.supplySuggest.queryForm,
                                    "if238",
                                    $$v
                                  )
                                },
                                expression: "supplySuggest.queryForm.if238",
                              },
                            },
                            _vm._l(_vm.options.if238Options, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "新老品" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { clearable: "", placeholder: "全部" },
                              model: {
                                value: _vm.supplySuggest.queryForm.isNewSku,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.supplySuggest.queryForm,
                                    "isNewSku",
                                    $$v
                                  )
                                },
                                expression: "supplySuggest.queryForm.isNewSku",
                              },
                            },
                            _vm._l(
                              _vm.options.isNewSkuOptions,
                              function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "搜索商品" } },
                        [
                          _c("el-input", {
                            attrs: {
                              size: "mini",
                              clearable: "",
                              placeholder: "输入商品名、商品编码",
                            },
                            model: {
                              value: _vm.supplySuggest.queryForm.searchKey,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.supplySuggest.queryForm,
                                  "searchKey",
                                  $$v
                                )
                              },
                              expression: "supplySuggest.queryForm.searchKey",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "form-btn-item",
                              attrs: {
                                size: "mini",
                                icon: "el-icon-search",
                                type: "primary",
                              },
                              on: { click: _vm.querySuggest },
                            },
                            [_vm._v("查询")]
                          ),
                          _c(
                            "el-button",
                            {
                              staticClass: "form-btn-item",
                              attrs: {
                                size: "mini",
                                icon: "el-icon-download",
                                type: "primary",
                              },
                              on: { click: _vm.handleExportSuggest },
                            },
                            [_vm._v("导出")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-table",
                    {
                      attrs: {
                        data: _vm.supplySuggest.data,
                        "v-loading": _vm.supplySuggest.loading,
                        size: "mini",
                        height: "300px",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          label: "排序",
                          prop: "sort",
                          width: "50",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "商品编码",
                          prop: "skuCode",
                          width: "80",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "所属型号",
                          prop: "jbpSpuCode",
                          width: "80",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "商品名",
                          prop: "skuName",
                          width: "200",
                          align: "center",
                        },
                      }),
                      _c(
                        "el-table-column",
                        {
                          attrs: {
                            label: "近半年销量",
                            prop: "l180XsCountBox",
                            width: "100",
                            align: "center",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({ row }) {
                                return [
                                  _c("span", [
                                    _vm._v(_vm._s(row.l180XsCount)),
                                    _c("br"),
                                    _vm._v(
                                      "(" +
                                        _vm._s(row.l180XsCountBox.toFixed(2)) +
                                        "箱)"
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ]),
                        },
                        [
                          _c(
                            "template",
                            { slot: "header" },
                            [
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    effect: "dark",
                                    content: "不含大仓出货数据",
                                    placement: "top-start",
                                  },
                                },
                                [
                                  _c("span", [
                                    _vm._v("近半年销量"),
                                    _c("i", {
                                      staticClass: "el-icon-question",
                                    }),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                      _c("el-table-column", {
                        attrs: {
                          label: "近半年销售店铺数",
                          prop: "l180XsShopCount",
                          width: "120",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "月均销量",
                          prop: "monthlyXsCountBox",
                          width: "100",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(row.monthlyXsCountBox.toFixed(2)) +
                                      "箱"
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "配送中心库存",
                          prop: "dcKcCountBox",
                          width: "100",
                          align: "center",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function ({ row }) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(row.dcKcCountBox.toFixed(2)) + "箱"
                                  ),
                                ]),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "分公司库存",
                          prop: "orgKcStr",
                          width: "80",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "新老品",
                          prop: "isNew",
                          width: "80",
                          align: "center",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "是否238",
                          prop: "is238",
                          width: "80",
                          align: "center",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("Pagination", {
                    attrs: {
                      total: _vm.supplySuggest.pageData.total,
                      page: _vm.supplySuggest.queryForm.page,
                      limit: _vm.supplySuggest.queryForm.limit,
                    },
                    on: {
                      "update:page": function ($event) {
                        return _vm.$set(
                          _vm.supplySuggest.queryForm,
                          "page",
                          $event
                        )
                      },
                      "update:limit": function ($event) {
                        return _vm.$set(
                          _vm.supplySuggest.queryForm,
                          "limit",
                          $event
                        )
                      },
                      pagination: _vm.querySuggest,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "补货确认单",
            visible: _vm.supplyConfirm.visible,
            "append-to-body": "",
            width: "1000px",
            "close-on-click-modal": false,
            "max-height": "400px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.supplyConfirm, "visible", $event)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "supplyConfirm",
              attrs: {
                size: "mini",
                "label-width": "auto",
                inline: true,
                model: _vm.supplyConfirm.queryForm,
              },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.handleQueryRestocking.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "el-row",
                { staticClass: "common-form-row" },
                [
                  _c("el-col", { staticClass: "bh-tip", attrs: { span: 24 } }, [
                    _vm._v(
                      "如补货模式为手动补货，请在聚宝盆App上确认发起补货单（自动补货则无需确认）"
                    ),
                  ]),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "档位" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "", placeholder: "全部" },
                      model: {
                        value: _vm.supplyConfirm.queryForm.skuLevel,
                        callback: function ($$v) {
                          _vm.$set(_vm.supplyConfirm.queryForm, "skuLevel", $$v)
                        },
                        expression: "supplyConfirm.queryForm.skuLevel",
                      },
                    },
                    _vm._l(_vm.options.skuLevelOptions, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "商品分类" } },
                [
                  _c("GoodCategory", {
                    on: { selected: _vm.supplyConfirmTreeNodeClick },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "是否238" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "", placeholder: "全部" },
                      model: {
                        value: _vm.supplyConfirm.queryForm.if238,
                        callback: function ($$v) {
                          _vm.$set(_vm.supplyConfirm.queryForm, "if238", $$v)
                        },
                        expression: "supplyConfirm.queryForm.if238",
                      },
                    },
                    _vm._l(_vm.options.if238Options, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "新老品" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "", placeholder: "全部" },
                      model: {
                        value: _vm.supplyConfirm.queryForm.isNewSku,
                        callback: function ($$v) {
                          _vm.$set(_vm.supplyConfirm.queryForm, "isNewSku", $$v)
                        },
                        expression: "supplyConfirm.queryForm.isNewSku",
                      },
                    },
                    _vm._l(_vm.options.isNewSkuOptions, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "搜索商品" } },
                [
                  _c("el-input", {
                    attrs: {
                      size: "mini",
                      clearable: "",
                      placeholder: "输入商品名、商品编码",
                    },
                    model: {
                      value: _vm.supplyConfirm.queryForm.searchKey,
                      callback: function ($$v) {
                        _vm.$set(_vm.supplyConfirm.queryForm, "searchKey", $$v)
                      },
                      expression: "supplyConfirm.queryForm.searchKey",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "form-btn-item",
                      attrs: {
                        size: "mini",
                        icon: "el-icon-search",
                        type: "primary",
                      },
                      on: { click: _vm.handleQueryRestocking },
                    },
                    [_vm._v("查询")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "form-btn-item",
                      attrs: {
                        size: "mini",
                        icon: "el-icon-download",
                        type: "primary",
                      },
                      on: { click: _vm.handleExportRestocking },
                    },
                    [_vm._v("导出")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticStyle: {
                "font-size": "16px",
                float: "right",
                color: "red",
              },
            },
            [
              _vm._v(
                "汇总：" +
                  _vm._s(_vm.supplyConfirm.skuCount) +
                  "个sku, 共计￥" +
                  _vm._s(_vm.supplyConfirm.saleTotal)
              ),
            ]
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.supplyConfirm.loading,
                  expression: "supplyConfirm.loading",
                },
              ],
              attrs: {
                data: _vm.supplyConfirm.data,
                size: "mini",
                height: "300px",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "商品编码",
                  prop: "skuCode",
                  width: "100",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "商品名", prop: "skuName", align: "center" },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "本次预计补货数量(PCS)",
                  prop: "status",
                  width: "140",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        row.supplyNumType === 0
                          ? _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v(_vm._s(row.skuNum)),
                              _c("br"),
                              _vm._v(
                                "(" +
                                  _vm._s(
                                    parseInt(
                                      row.unFixedSupplyNum / row.bottomSpec
                                    ) > 0
                                      ? parseInt(
                                          row.unFixedSupplyNum / row.bottomSpec
                                        ) + "箱"
                                      : ""
                                  ) +
                                  _vm._s(
                                    (
                                      (row.unFixedSupplyNum % row.bottomSpec) /
                                      row.midSpec
                                    ).toFixed(2) > 0
                                      ? Math.ceil(
                                          (row.unFixedSupplyNum %
                                            row.bottomSpec) /
                                            row.midSpec
                                        ) + "中包装"
                                      : ""
                                  ) +
                                  ")"
                              ),
                            ])
                          : _vm._e(),
                        row.supplyNumType === 1
                          ? _c("span", { staticStyle: { color: "red" } }, [
                              _vm._v(_vm._s(row.skuNum)),
                              _c("br"),
                              _vm._v(
                                "(" +
                                  _vm._s(row.currentSupplyBoxNum + "箱") +
                                  ")"
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "本次预计补货金额",
                  prop: "status",
                  width: "120",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v(
                            "￥" + _vm._s((row.skuNum * row.price).toFixed(2))
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "档位",
                  prop: "skuLevel",
                  width: "40",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [_c("span", [_vm._v(_vm._s(row.skuLevel) + "档")])]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: _vm.currentSupplyType,
                  width: "100",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              row.supplyNumType === 0
                                ? row.currentSupplyDays + "天"
                                : _vm.isXx()
                                ? row.xxSupplyBoxNum
                                : row.supplyBoxNum + "箱"
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "近半年日均销量(PCS)",
                  prop: "dailyAvgXsCount",
                  width: "140",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(row.dailyAvgXsCount.toFixed(2))),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "我未发给终端",
                  prop: "wfCntBox",
                  width: "100",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(row.wfCntBox?.toFixed(2)) + "箱"),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "配送中心库存",
                  prop: "dcKcCount",
                  width: "100",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(row.dcKcCountBox?.toFixed(2)) + "箱"),
                          _c("br"),
                          _vm._v("(" + _vm._s(row.dcKcCount) + ")"),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "够卖天数",
                  prop: "dcKcCount",
                  width: "100",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("span", [
                          _vm._v("够卖" + _vm._s(row.kcCanSaleDay) + "天"),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "分公司未发给我(PCS)",
                  prop: "orgWfCountBox",
                  width: "140",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(row.orgWfCountBox?.toFixed(0))),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "分公司在途(PCS)",
                  prop: "cgZtCnt",
                  width: "120",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("span", [_vm._v(_vm._s(row.cgZtCnt?.toFixed(0)))]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "分公司库存",
                  prop: "orgKcStr",
                  width: "100",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "规格",
                  prop: "spec",
                  width: "100",
                  align: "center",
                },
              }),
            ],
            1
          ),
          _c("Pagination", {
            attrs: {
              total: _vm.supplyConfirm.pageData.total,
              page: _vm.supplyConfirm.queryForm.page,
              limit: _vm.supplyConfirm.queryForm.limit,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.supplyConfirm.queryForm, "page", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.supplyConfirm.queryForm, "limit", $event)
              },
              pagination: _vm.handleQueryRestocking,
            },
          }),
        ],
        1
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            title: "配置日志",
            visible: _vm.openLogParam.visible,
            "append-to-body": "",
            size: "70%",
            "destroy-on-close": "",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.openLogParam, "visible", $event)
            },
          },
        },
        [
          _c("AutoSupplyConfigLog", {
            attrs: {
              param: {
                orgCode: this.openLogParam.orgCode,
                dcCode: this.openLogParam.dcCode,
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }