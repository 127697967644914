import {axiosGet, axiosPost, axiosPut, axiosDelete, axiosDownload} from "@/utils/request";

/**
 * ----------------------------- 备货申请 -----------------------------
 */

// 分页
export function pageStockUpData(data) {
  return axiosPost('/stock-up/page', data, 1);
}
// 前6月对应名称
export function getSixMthFields() {
  return axiosGet('/stock-up/fields', {}, 1);
}
export function getDictData(data, json) {
  return axiosPost('/stock-up/dict/' + data, json, 1);
}
export function saveStockUp(data) {
  return axiosPut('/stock-up', data, 1);
}
export function exportData(data) {
  return axiosDownload("/stock-up/export", data, 1);
}
export function importData(data) {
  return axiosDownload("/stock-up/import", data, 1);
}
// 查询总部销售报表商品维度
export function productDimensions(data) {
    return axiosPost('/hqAnalysis/listHqXsSkuDimensionAnalysises', data, 9);
  }
// 查询总部销售报表赛道维度
export function trackDimension(data) {
    return axiosPost('/hqAnalysis/listHqXsRaceDimensionAnalysises', data, 9);
  }
// 查询总部销售报表下拉选项
export function dropDownOptions(data) {
    return axiosPost('/hqAnalysis/listHqXsSelectOptions', data, 9);
  }

  // 大类中类小类下拉选项
    export function selectOptions() {
        return axiosPost('/hqAnalysis/listCategories',{}, 9);
    }

// 查询总部销售报表中类维度
export function mediumClassDimensions(data) {
    return axiosPost('/hqAnalysis/listHqXsMidCategoryDimensionAnalysises', data, 9);
  }
//   查询总部销售报表小类维度
export function smallClassDimensions(data) {
    return axiosPost('/hqAnalysis/listHqXsSubCategoryDimensionAnalysises', data, 9);
  }

//   导出总部销售报表商品报表
export function exportProductDimensions(data) {
    return axiosDownload("/hqAnalysis/exportHqXsSkuDimensionAnalysises", data, 9);
  }

//   导出赛道维度报表
export function exportTheTrackDimensionReport(data) {
    return axiosDownload("/hqAnalysis/exportHqXsRaceDimensionAnalysises", data, 9);
  }

//   导出总部销售报表中类维度
export function exportMediumClassDimensions(data) {
    return axiosDownload("/hqAnalysis/exportHqXsMidCategoryDimensionAnalysises", data, 9);
  }
    
    //   导出总部销售报表小类维度
    export function exportSmallClassDimensions(data) {
        return axiosDownload("/hqAnalysis/exportHqXsSubCategoryDimensionAnalysises", data, 9);
    }