<template>
  <div v-loading="loadingPage">
    <div style="padding-bottom: 15px">
      <el-form v-if="type!=='view'" ref="form" :rules="rules" size="mini" :inline="true" :model="form" class="common-form">
        <el-row class="common-form-row">
          <el-col :span="8">
            <el-form-item label="任务名称" prop="taskName" label-width="120px">
              <el-input v-model="form.taskName" placeholder="请输入" clearable/>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="分公司" label-width="120px" prop="checkedOrgCodes">
              <DictSelect v-show="!(form.checkedOrgCodes instanceof Array)" :value.sync="form.checkedOrgCodes" fullLabel init :init-options="initOrgOptions" version="V3" @change="(val, action) => handleOrgChange(action)" />
              <DictSelect v-show="(form.checkedOrgCodes instanceof Array)" :value.sync="form.checkedOrgCodes" collapse-tags multiple fullLabel :clearable="false" init version="V3" :init-options="initOrgOptions" @change="(val, action) => handleOrgChange(action)" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="任务类型" label-width="120px">
              <el-select :disabled="form.status > 2" v-model="form.taskRange" @change="handleRangeChange">
                <el-option value="0" label="阶段任务(短期活动)" />
                <el-option value="1" label="长期任务" />
                <el-option value="2" label="大篷车任务" />
                <el-option value="3" label="大篷车任务-新品" />
                <el-option value="4" label="阵地任务" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="18">
            <el-form-item label="描述" prop="description" label-width="100px">
              <el-input v-model="form.description" class="descWidth" placeholder="建议20字以内" clearable/>
            </el-form-item>
          </el-col>
          <el-col v-if="form.timeType === '1'" :span="12">
            <el-form-item label="任务时间" prop="offsetDays" label-width="110px">
              <el-select v-model="form.timeType" @change="() => form.offsetDays = undefined" style="width: 20%">
                <el-option value="0" label="固定时间" />
                <el-option value="1" label="动态时间" />
              </el-select>
              <el-input-number v-model="form.offsetDays" placeholder="输入天数，输入90即近90天" :controls="false" :min="0" clearable style="width: 75%" />
            </el-form-item>
          </el-col>
          <el-col v-if="form.timeType === '0'" :span="12">
            <el-form-item label="任务时间" prop="startDate" label-width="110px">
              <el-select v-model="form.timeType" @change="() => {form.offsetDays = undefined; form.runFlow = true;}" style="width: 20%">
                <el-option value="0" label="固定时间" />
                <el-option value="1" label="动态时间" />
              </el-select>
              <el-date-picker
                v-model="taskDate"
                type="daterange"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                @change="val => handleDateChange(val, 'task')"
                format="yyyy-MM-dd"
                style="width: 70%"
                :default-time="['00:00:00', '23:59:59']"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col v-if="form.timeType === '0'" :span="12">
            <el-form-item prop="writeOffEndTime" label-width="160px">
              <template #label>
                核销截止时间
                <el-popover
                  placement="top-start"
                  width="300"
                  trigger="hover"
                  content="任务结束后，如还需要在App中展示该任务用来核销，可设定此时间">
                  <i slot="reference" class="el-icon-question" />
                </el-popover>
              </template>
              <el-date-picker
                v-model="form.writeOffEndTime"
                type="date"
                format="yyyy-MM-dd"
                placeholder="请选择"
                @change="val => handleDateChange(val, 'write')"
                value-format="yyyy-MM-dd HH:mm:ss"
                :picker-options="pickerOptions"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col v-if="form.checkedOrgCodes && orgFilterMap[form.checkOrgCode]!=undefined" :span="12">
            <el-form-item label="新增款式对比周期" label-width="160px">
              <div style="display: inline-flex; width: 100%;">
                <el-select v-if="form.checkedOrgCodes instanceof Array && form.checkedOrgCodes.length > 1" v-model="form.compareOrgCode" style="width: 90px;">
                  <el-option v-for="item in form.checkedOrgCodes.filter(e => e!=='ALL')" :key="item" :value="item" :label="item + '-' +allOrgMap[item]" />
                </el-select>
                <el-popover
                  placement="bottom"
                  width="365"
                  style="width: 100%"
                  trigger="click"
                  @show="handlePopoverShow"
                  content="">
                  <el-radio-group v-model="orgFilterMap[form.compareOrgCode].ksCompareChoose" style="font-weight:400 !important;" @input="form.runFlow = true;">
                    <el-row>
                      <el-radio label="0">前180天</el-radio>
                    </el-row>
                    <el-row>
                      <el-radio label="1">自定义<el-popover
                        placement="top-start"
                        width="300"
                        trigger="hover"
                        content="在该时间段内未进过货的款式，活动期间进货即算作新增（仅能选择活动开始前的时间范围）">
                        <i slot="reference" class="el-icon-question" />
                      </el-popover></el-radio>
                      <el-date-picker
                        v-model="orgFilterMap[form.compareOrgCode].ksCompareDate"
                        type="daterange"
                        start-placeholder="开始时间"
                        end-placeholder="结束时间"
                        format="yyyy-MM-dd"
                        style="width: 200px"
                        :default-time="['00:00:00', '23:59:59']"
                      ></el-date-picker>
                    </el-row>
                  </el-radio-group>
                  <div v-if="(form.checkedOrgCodes instanceof Array) && form.checkedOrgCodes.length >1" style="text-align: center; margin-top: 5px;">
                    <el-button size="mini"  type="text" style="border: 0px;" @click="syncConfigToOther('compare')">一键同步至其他分公司</el-button>
                  </div>
                  <el-input v-if="form.checkedOrgCodes.length > 0" slot="reference" readonly style="width: 100%" v-model="orgCompareShow">
                    <i slot="suffix">
                      <i style="cursor: pointer" class="el-input__icon el-icon-arrow-down" />
                    </i>
                  </el-input>
                </el-popover>
              </div>
            </el-form-item>
          </el-col>

          <el-col v-if="form.checkedOrgCodes && orgFilterMap[form.checkOrgCode]!=undefined" :span="12">
            <el-form-item label="未进货对比周期" label-width="160px">
              <div style="display: inline-flex; width: 100%;">
                <el-select v-if="form.checkedOrgCodes instanceof Array && form.checkedOrgCodes.length > 1" v-model="form.checkOrgCode" style="width: 90px;" @change="val => handlePopoverShow(val)">
                  <el-option v-for="item in form.checkedOrgCodes.filter(e => e!=='ALL')" :key="item" :value="item" :label="item + '-' +allOrgMap[item]" />
                </el-select>
                <el-popover
                  placement="bottom"
                  width="365"
                  style="width: 100%"
                  trigger="click"
                  @show="handlePopoverShow"
                  content="App任务列表中此任务的展示排序，数字越小，排序越靠前，数字可以是负数">
                  <el-table
                    ref="filterTable"
                    :data="queryConfigRows"
                    @selection-change="handleQueryConfigSelectionChange"
                    size="mini"
                    :show-header="false">
                    <el-table-column type="selection" width="40" />
                    <el-table-column width="70" prop="key">
                      <template #default="{ row }">
                          <span v-if="row.key==='固定期'">
                            {{ row.key }}
                            <el-popover
                              placement="top-start"
                              width="300"
                              trigger="hover"
                              content="已进货是固定期之内（如87款配置1-3月），未进货是固定期外">
                              <i slot="reference" class="el-icon-question" />
                            </el-popover>
                          </span>
                        <span v-else>{{ row.key }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column width="210" prop="name">
                      <template #default="{row}">
                        <div v-if="row.key==='固定期'">
                          <el-date-picker
                            v-model="orgFilterMap[form.checkOrgCode].compareDate"
                            type="daterange"
                            start-placeholder="开始时间"
                            end-placeholder="结束时间"
                            format="yyyy-MM-dd"
                            :default-time="['00:00:00', '23:59:59']"
                            size="mini"
                            style="width: 200px"
                            @change="val => handleConfigDateChange(val, row)"
                          />
                          <el-input v-model="orgFilterMap[form.checkOrgCode].customName" size="mini" style="width: 200px" placeholder="自定义名称（如1-3月）8个字内" @input="handleQueryConfigSelectionChange(orgFilterMap[form.checkOrgCode].selections)" />
                        </div>
                      </template>
                    </el-table-column>
                  </el-table>
                  <div v-if="(form.checkedOrgCodes instanceof Array) && form.checkedOrgCodes.length >1" style="text-align: center; margin-top: 5px;">
                    <el-button size="mini"  type="text" style="border: 0px;" @click="syncConfigToOther('query')">一键同步至其他分公司</el-button>
                  </div>
                  <el-input v-if="form.checkedOrgCodes.length > 0" slot="reference" readonly style="width: 100%" v-model="orgFilterMap[form.checkOrgCode].queryConfig">
                    <i slot="suffix">
                      <i style="cursor: pointer" class="el-input__icon el-icon-arrow-down" />
                    </i>
                  </el-input>
                </el-popover>
              </div>
            </el-form-item>
          </el-col>
          <el-col v-if="form.checkedOrgCodes && orgFilterMap[form.showOrgCode]!=undefined" :span="12">
            <el-form-item label-width="140px">
              <template #label>
                是否APP显示
                <el-popover
                  placement="top-start"
                  width="300"
                  trigger="hover"
                  content="控制该任务在APP是否显示">
                  <i slot="reference" class="el-icon-question" />
                </el-popover>
              </template>
              <div style="display: inline-flex; width: 100%;">
                <el-select v-if="form.checkedOrgCodes instanceof Array && form.checkedOrgCodes.length > 1" v-model="form.showOrgCode" style="width: 120px;">
                  <el-option v-for="item in form.checkedOrgCodes.filter(e => e!=='ALL')" :key="item" :value="item" :label="item + '-' +allOrgMap[item]" />
                </el-select>
                <el-select v-model="orgFilterMap[form.showOrgCode].appShow" placeholder="控制该任务在APP是否显示">
                  <el-option :value="1" label="是"></el-option>
                  <el-option :value="0" label="否"></el-option>
                </el-select>
                <div v-if="(form.checkedOrgCodes instanceof Array) && form.checkedOrgCodes.length >1" style="text-align: center; margin-top: 5px;">
                  <el-button size="mini"  type="text" style="border: 0px;" @click="syncConfigToOther('appShow')">一键同步至其他分公司</el-button>
                </div>
              </div>
            </el-form-item>
          </el-col>
          <el-col v-if="form.checkedOrgCodes && orgFilterMap[form.batchOrgCode]!=undefined && specialAuth" :span="12">
            <el-form-item label-width="140px" label="一键加购">
              <div style="display: inline-flex; width: 100%;">
                <el-select v-if="form.checkedOrgCodes instanceof Array && form.checkedOrgCodes.length > 1" v-model="form.batchOrgCode" style="width: 120px;">
                  <el-option v-for="item in form.checkedOrgCodes.filter(e => e!=='ALL')" :key="item" :value="item" :label="item + '-' +allOrgMap[item]" />
                </el-select>
                <el-select v-model="orgFilterMap[form.batchOrgCode].ifBatchAddCart" placeholder="请选择">
                  <el-option value="1" label="是"></el-option>
                  <el-option value="0" label="否"></el-option>
                </el-select>
                <div v-if="(form.checkedOrgCodes instanceof Array) && form.checkedOrgCodes.length >1" style="text-align: center; margin-top: 5px;">
                  <el-button size="mini"  type="text" style="border: 0px;" @click="syncConfigToOther('ifBatchAddCart')">一键同步至其他分公司</el-button>
                </div>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="智能补货" label-width="120px">
              <el-select v-model="form.ifAutoSupply" :disabled="initOrgOptions.length <= 1">
                <el-option :value="0" label="否" />
                <el-option :value="1" label="是" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label-width="120px">
              <template #label>
                拆分架装
                <el-popover
                  placement="top-start"
                  width="300"
                  trigger="hover"
                  content="如架装不在任务中、但架装内有单品在任务中时，进了架装的款数和金额是否需拆分计算到该单品的款数上柜和金额上柜（金额会按架装实际金额按比例折算）">
                  <i slot="reference" class="el-icon-question" />
                </el-popover>
              </template>
              <el-select v-model="form.ifTzSplit">
                <el-option :value="0" label="金额和款数均不拆分" />
                <el-option :value="1" label="金额和款数均拆分计算到单品" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-form-item label-width="160px">
            <template #label>
              对应的联盟活动
              <el-popover
                placement="top-start"
                width="300"
                trigger="hover"
                content="配置后，业务员可以在聚宝盆中分享这个任务给店主，店主打开是联盟的活动⻚⾯。如何获得链接：在联盟App打开对应的活动，点击底部“去分享”→“复制链接”。建议将链接中的shareUserId改为xxx">
                <i slot="reference" class="el-icon-question" />
              </el-popover>
            </template>
            <el-row style="background-color: #ececec; padding:10px;">
              <el-col :span="8">
                <el-form-item label="分享链接主标题" label-width="170px">
                  <el-input v-model="form.shareTitle" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="H5链接" label-width="100px">
                  <el-input v-model="form.h5ShareUrl" />
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="微信分享图" label-width="120px">
                  <OssUpload ref="ossUpload" :value-to.sync="form.weChatShareIcon" list-type="picture-card" :maxFileSize="500" :tipMessageShow="false" :accept-type="['.jpg', '.jpeg', '.gif', '.png']" />
                </el-form-item>
              </el-col>
              <el-button type="text" size="mini" v-if="!share.active" :underline="false" @click="() => share.active = true">展开选填项</el-button>
            </el-row>
            <el-row v-if="share.active" style="background-color: #ececec;">
              <el-row>
                <el-col :span="10">
                  <el-form-item label="分享链接副标题" label-width="170px">
                    <el-input v-model="form.subShareTitle" />
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="10">
                  <el-form-item label="APP分享框标题" label-width="170px">
                    <el-input v-model="form.shareBoxTitle" />
                  </el-form-item>
                </el-col>
                <el-col :span="10">
                  <el-form-item label="APP分享框描述" label-width="170px">
                    <el-input v-model="form.shareBoxDesc" />
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row style="padding-left:10px;">
                <el-col :span="10">
                  <el-form-item label="APP页面图标" label-width="120px">
                    <OssUpload ref="ossUpload" :value-to.sync="form.appShareIcon" list-type="picture-card" :maxFileSize="500" :tipMessageShow="false" :accept-type="['.jpg', '.jpeg', '.gif', '.png']" />
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row style="padding-left:10px;">
                <el-button type="text" size="mini" :underline="false" @click="() => share.active = false">关闭</el-button>
              </el-row>
            </el-row>
          </el-form-item>
        </el-row>
      </el-form>
    </div>

    <div>
      <el-tabs v-model="tabName" @tab-click="(val) => {
        if (val.name !== 'sku') {
          this.handleSetTreeChecked(form);
        }
      }">
        <el-tab-pane label="商品范围" name="sku" />
        <el-tab-pane label="店铺范围" name="shop" />
        <el-tab-pane label="配送中心要货" name="dc" />
      </el-tabs>
      <div>
        <div style="position: relative; z-index: 999;width: 100%;">
          <div v-if="tabName==='sku'" style="float: left; margin: 5px;">
            <div style="display: inline-block">
              <span>添加商品方式 </span>
              <el-select size="mini" v-model="form.importScope" style="width: 90px" @change="handleSkuScopeChange">
                <el-option value="0" label="按商品型号" />
                <el-option value="1" label="按商品编码" />
              </el-select>
            </div>
            <div style="display: inline-block; margin-left: 10px">
              <span>数据统计方式
              <el-popover
                placement="top-start"
                width="300"
                trigger="hover"
                content="例如1个型号的商品下有3个商品编码。按商品型号统计:进货算1款进货；按商品编码统计:进货算3款进货">
                  <i slot="reference" class="el-icon-question" />
                </el-popover></span>
              <el-select size="mini" v-model="form.kssType" style="width: 120px" @change="form.runFlow = true">
                <el-option value="model" label="按商品型号统计" />
                <el-option v-if="form.importScope==='1'" value="sku" label="按商品编码统计" />
              </el-select>
            </div>
          </div>
          <div v-if="tabName==='shop'" style="float: left; margin: 5px;">
            <div style="display: inline-block">
              <span>添加店铺方式 </span>
              <el-select size="mini" v-model="form.shopCheckType" style="width: 110px" @change="handleShopTypeChange">
                <el-option value="0" label="按分公司/配送中心" />
                <el-option value="1" label="按具体店铺" />
              </el-select>
            </div>
            <div v-if="form.shopCheckType=='1'" style="display: inline-block; margin-left: 10px">
              <span>店铺组添加 </span>
              <el-select size="mini" v-model="form.shopGroupId" style="width: 110px">
                <el-option v-for="item in shopGroupOptions" :value="item.id" :label="item.orgName + '-' + item.groupName" :key="item.id" />
              </el-select>

              <el-button :disabled="!form.shopGroupId" size="mini" icon="el-icon-plus" type="primary" style="margin-left: 5px" @click="setGrpShops()">一键添加</el-button>
            </div>
          </div>
          <div style="float: right;margin: 5px;">
            <el-input v-if="tabName === 'sku'" style="width: 120px !important; margin-right: 10px" clearable size="mini" :placeholder="form.importScope === '0'?'商品型号': '商品编码'" v-model="querySkuKeyWord" @keyup.enter.native="handleQuery('skus', 'skuPage')">
              <i slot="suffix" style="cursor: pointer" class="el-input__icon el-icon-search" @click="handleQuery('skus', 'skuPage')" /></el-input>
            <el-input v-if="tabName === 'shop' && form.shopCheckType==='1'" style="width: 120px !important;" size="mini" clearable placeholder="配送中心编码" v-model="queryDcCode" @keyup.enter.native="handleQuery('shops', 'shopPage')">
              <i slot="suffix" style="cursor: pointer" class="el-input__icon el-icon-search" @click="handleQuery('shops', 'shopPage')" /></el-input>
            <el-input v-if="tabName === 'shop' && form.shopCheckType==='1'" style="width: 120px !important;margin-left: 5px; margin-right: 10px" clearable size="mini" placeholder="店铺编码" v-model="queryShopCode" @keyup.enter.native="handleQuery('shops', 'shopPage')">
              <i slot="suffix" style="cursor: pointer" class="el-input__icon el-icon-search" @click="handleQuery('shops', 'shopPage')" /></el-input>
            <el-button v-if="type!=='view' && ((form.shopCheckType==='1' && tabName === 'shop') || tabName === 'sku')" size="mini" icon="el-icon-upload" type="primary"
                       @click="handleImport">{{tabName === "sku"?'导入商品': '导入店铺'}}
            </el-button>
            <el-button v-if="tabName === 'sku' && form.mtTaskId" size="mini" icon="el-icon-download" type="primary"
                       @click="handleExport">导出商品
            </el-button>
            <el-button v-if="tabName === 'sku' && type !=='view'" size="mini" icon="el-icon-plus" type="primary"
                       @click="drawer.visible = true">添加商品
            </el-button>
            <el-button v-if="tabName === 'sku' && type !=='view'" size="mini" type="primary" @click="handleSort">{{dynamicTagHeader}}排序</el-button>
            <el-button v-if="tabName === 'shop' && form.shopCheckType==='1' && type !=='view'" size="mini" icon="el-icon-plus" type="primary"
                       @click="drawer.visible = true">添加店铺
            </el-button>
            <el-button v-if="type!=='view' && ((form.shopCheckType==='1' && tabName === 'shop') || tabName === 'sku')" size="mini" icon="el-icon-remove" type="danger"
                       @click="handleRemoveAll">全部移除
            </el-button>
          </div>
        </div>
        <div v-show="tabName === 'sku'">
          <el-table
            ref="skuTableRef"
            :data="skuPage.rows"
            height="calc(100vh - 400px)"
            v-loading="loading"
            size="mini"
            border
            style="margin-top: 5px"
            highlight-current-row
            :header-cell-style="{ background: 'rgb(227 224 224)', color: 'black' }"
            @selection-change="handleSelectionChange"
          >
            <el-table-column v-show="form.importScope === '1'" label="商品编码" prop="skuCode" width="100"/>
            <el-table-column label="商品型号" prop="skuModel" width="100"/>
            <el-table-column label="款式名称" prop="skuName" show-overflow-tooltip>
              <template slot-scope="scope">
                <span >{{ scope.row.skuName }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="label" width="120">
              <template slot="header">
                <span>{{ this.dynamicTagHeader }}</span>
              </template>
              <template slot-scope="scope">
                <span v-show="type==='view'" >{{ scope.row.label }}</span>
                <div v-show="type==='edit' || type==='add'">
                  <el-input v-model="scope.row.label" size="mini" clearable :placeholder="'请输入' + dynamicTagHeader" @input="form.runFlow = true" />
                </div>
              </template>
            </el-table-column>
            <el-table-column label="自定义商品名称" prop="customModelName" width="120">
              <template slot-scope="scope">
                <span v-show="type==='view'" >{{ scope.row.customModelName }}</span>
                <div v-show="type==='edit' || type==='add'">
                  <el-input v-model="scope.row.customModelName" size="mini" clearable placeholder="请输入名称,同一个型号名称须一致" @input="(val) => handleCustomChange(val, scope.row)" />
                </div>
              </template>
            </el-table-column>
            <el-table-column v-show="form.importScope === '1'" label="规格" prop="spec" width="80"/>
            <el-table-column label="货号" prop="cargoNo" show-overflow-tooltip />
            <el-table-column label="大类" prop="mainCategoryName" width="100"/>
            <el-table-column label="中类" prop="midCategoryName" width="100"/>
            <el-table-column label="小类" prop="subCategoryName" width="100"/>
            <el-table-column v-if="type!=='view'" label="操作" width="70" align="center" fixed="right">
              <template slot-scope="scope">
                <el-button
                  style="border: 0px; color: red"
                  @click.native.prevent="handleRemoveRow(scope.row, 1, 'skus', 'skuPage')"
                  type="text"
                  size="small">
                  移除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <Pagination :total="skuPage.total" :page.sync="skuPage.current" :limit.sync="skuPage.size"
                      @pagination="handleQuery('skus', 'skuPage')"/>
        </div>
        <div v-show="tabName === 'shop'">
          <div v-show="form.shopCheckType==='1'">
            <el-table
              ref="shopTableRef"
              :data="shopPage.rows"
              height="calc(100vh - 300px)"
              v-loading="loading"
              size="mini"
              border
              style="margin-top: 5px"
              highlight-current-row
              :class="(!formLoading && form.shops.length === 0) ? 'table-light-class': ''"
              :header-cell-style="{ background: 'rgb(227 224 224)', color: 'black' }"
              @selection-change="handleSelectionChange"
            >
              <el-table-column label="分公司" prop="orgName" width="100" />
              <el-table-column label="所属配送中心" prop="dcName" width="170">
                <template #default="{ row }">
                  <span>{{ (row.dcCode?row.dcCode:'') + (row.dcName?"-" + row.dcName:'') }}</span>
                </template>
              </el-table-column>
              <el-table-column label="省份/城市" prop="provinceName" width="150">
                <template #default="{ row }">
                  <span>{{ (row.provinceName?row.provinceName:'') + "-" + (row.cityName?  "-" +row.cityName:'') }}</span>
                </template>
              </el-table-column>
              <el-table-column label="所属区县" prop="areaName" width="100" />
              <el-table-column label="店铺编码/名称" prop="shop" width="250">
                <template #default="{ row }">
                  <span>{{ (row.shopCode?row.shopCode:'') + (row.shopName ? "-" + row.shopName: '') }}</span>
                </template>
              </el-table-column>
              <el-table-column label="所属商圈" prop="businessAreaName" width="100" />
              <el-table-column label="区域分组" prop="areaTag" width="80" />
              <el-table-column label="店铺类型" prop="shopCapacity" width="80" />
              <el-table-column label="合作类型" prop="shopBiz" width="70" />
              <el-table-column label="是否重点终端" prop="ifOptimumShop" width="80">
                <template #default="{ row }">
                  <span>{{ row.ifOptimumShop ==='1'? '是': '否' }}</span>
                </template>
              </el-table-column>
              <el-table-column v-if="type!=='view'" label="操作" width="70" align="center" fixed="right">
                <template slot-scope="scope">
                  <el-button
                    style="border: 0px; color: red"
                    @click.native.prevent="handleRemoveRow(scope.row, 1, 'shops', 'shopPage')"
                    type="text"
                    size="small">
                    移除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
            <Pagination :total="shopPage.total" :page.sync="shopPage.current" :limit.sync="shopPage.size"
                        @pagination="handleQuery('shops', 'shopPage')"/>
          </div>
          <div v-show="form.shopCheckType==='0'" style="display: inline-flex; margin-top: 40px; margin-left: -200px;">
            <div style="width: 100px;font-size: 14px; padding: 10px; font-weight: 500">
              所选区域
            </div>
            <div style="height: 300px;overflow-y: scroll;width: 400px;margin: 10px;">
              <el-input placeholder="输入关键字进行过滤" v-model="filterDcText" size="mini" style="position: absolute; z-index: 99; width: 300px;" clearable @input="handleFilterChange" />
              <el-tree
                ref="orgShopDcTreeRef"
                :data="filterOrgDcTree"
                show-checkbox
                node-key="fullCode"
                :accordion="true"
                :filter-node-method="filterOrgDcNode"
                @check="(node, data) => handleOrgDcChange(node, data, 'shops')"
                style="margin-top: 30px"
                :props="{ children: 'children', label: 'label', value: 'value' }">
              </el-tree>
            </div>
          </div>
        </div>
        <div v-show="tabName === 'dc'">
          <el-row>
            <el-col :span="12">
              <div style="background-color: rgb(245 233 210); color: rgb(230 157 35); line-height: 40px; padding-left: 10px; width: fit-content;">
                说明: 此页面选择后，可以在App的该配送中心页通过此任务下单，来向分公司要货。
              </div>
            </el-col>
            <el-col :span="12">
              <label>显示排序号</label>
              <el-input-number v-model="form.dcSort" size="mini" clearable placeholder="请输入排序号,数字越大越靠前" :controls="false" :step="0" />
            </el-col>
          </el-row>
          <div style="display: inline-flex;">
            <div style="width: 100px;font-size: 14px; padding: 10px; font-weight: 500">
              所选区域
            </div>
            <div style="height: 300px;overflow-y: scroll;width: 400px;margin: 10px;">
              <el-input placeholder="输入关键字进行过滤" v-model="filterTaskDcText" size="mini" style="position: absolute; z-index: 99; width: 300px;" clearable @input="handleFilterChange" />
              <el-tree
                ref="orgDcTreeRef"
                :data="filterOrgDcTree"
                show-checkbox
                node-key="fullCode"
                :accordion="true"
                :filter-node-method="filterOrgDcNode"
                @check="(node, data) => handleOrgDcChange(node, data, 'dcs')"
                style="margin-top: 30px"
                :props="{ children: 'children', label: 'label', value: 'value' }">
              </el-tree>
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-drawer :title="tabName==='sku'?'选择商品':'选择店铺'" :visible.sync="drawer.visible" size="70%" direction="rtl"
               append-to-body>
      <el-menu v-if="tabName==='sku' && form.importScope === '0'" :default-active="skuGroups.activeIndex" mode="horizontal" class="el-menu-demo"
               @select="(val, path) => handleMenuSelect(val, path,'skuGroups')">
        <el-menu-item v-for="item in skuGroups.fixed" :key="item.id" :index="item.id"> {{
            item.groupName
          }}
        </el-menu-item>
        <el-submenu v-if="skuGroups.dynamic.length > 0" index="other">
          <template slot="title">{{ skuOtherName }}</template>
          <el-menu-item v-for="item in skuGroups.dynamic" :key="item.id" :index="item.id"> {{
              item.groupName
            }}
          </el-menu-item>
        </el-submenu>
      </el-menu>
      <el-menu v-if="tabName==='shop'" :default-active="shopGroups.activeIndex" mode="horizontal" class="el-menu-demo"
               @select="(val, path) => handleMenuSelect(val, path,'shopGroups')">
        <el-menu-item v-for="item in shopGroups.fixed" :key="item.id" :index="item.id"> {{
            item.groupName
          }}
        </el-menu-item>
        <el-submenu v-if="shopGroups.dynamic.length > 0" index="other">
          <template slot="title">{{ shopOtherName }}</template>
          <el-menu-item v-for="item in shopGroups.dynamic" :key="item.id" :index="item.id"> {{
              item.groupName
            }}
          </el-menu-item>
        </el-submenu>
      </el-menu>
      <el-button style="float: right;top: -35px;position: relative;" size="mini" icon="el-icon-plus" type="primary"
                 @click="handleAddGrp">{{ tabName === 'sku' ? '新建商品组' : '新建店铺组' }}
      </el-button>
      <SkuItem
        v-show="tabName==='sku'"
        :group-id="skuGroups.activeIndex"
        :refresh="skuGroups.refresh"
        :scope="form.importScope"
        @add-items="addItems"
        @close-drawer="drawer.visible = false"
        :show-action="true"
        @getExceptModels="callback => callback(form.exceptModels)"
        @getExceptSkuCodes="callback => callback(form.exceptSkuCodes)"
      />
      <ShopItem
        v-show="tabName==='shop'"
        :org-code="this.form.checkedOrgCodes"
        :group-id="shopGroups.activeIndex"
        :refresh="shopGroups.refresh"
        @add-items="addItems"
        @close-drawer="drawer.visible = false"
        :show-action="true"
        @getExceptCodes="callback => callback(form.exceptCodes)"
      />
    </el-drawer>

    <GrpShop v-show="ifComponent === 'shop'" :component.sync="ifComponent" @dialog-close="handleDialogCloseEvent" />
    <GrpShop v-show="ifComponent === 'sku'" :component.sync="ifComponent" @dialog-close="handleDialogCloseEvent" />

    <ImportDialog :importBaseParams="importBaseParams(importDialog.importBaseParams)"
                  @handleImportCallBack="handleImportData" append-to-body/>

    <el-dialog title="导入结果" :visible.sync="noticeDialog.visible" append-to-body width="700px">
      <div v-html="noticeDialog.msg" style="font-size: 16px">
      </div>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button type="primary" size="mini" @click="downloadImportResult">下载导入结果</el-button>
        <el-button type="primary" size="mini" @click="noticeDialog.visible = false">关闭</el-button>
      </div>
    </el-dialog>

    <el-dialog title="标签排序" :visible.sync="sortDialog.visible" append-to-body width="500px">
      <div>
        <div v-if="sortDialog.sorts.length > 0">
          <el-row v-for="(item,index) in sortDialog.sorts" :key="index" style="background-color: rgb(220 220 220); padding-top: 5px;margin: 5px">
            <el-col :span="12" style="text-align: center">
              {{item.label}}
            </el-col>
            <el-col :span="11">
              <el-button type="text" size="mini" icon="el-icon-upload2" :disabled="index === 0" @click="handleMove('up', index)" />
              <el-button type="text" size="mini" icon="el-icon-download" :disabled="index === sortDialog.sorts.length - 1" @click="handleMove('down', index)" />
              <el-button type="text" size="mini" icon="el-icon-delete" @click="handleMove('remove', index)" />
            </el-col>
          </el-row>
        </div>
        <div v-else style="color: rgb(220 220 220)">
          当前暂无标签
        </div>
      </div>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button type="primary" size="mini" @click="handleConfirmSort">保存</el-button>
        <el-button type="primary" size="mini" @click="sortDialog.visible = false">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {getTaskInfo, dictSelect, pageShopGroup, pageSkuModelGroup, checkSkus, checkShops, exportTaskSku, getOrgDcTree, pageShop} from "@/api/appTask";
import Pagination from "@/components/Pagination";
import SkuItem from "@/views/appTask/skuGroup/SkuItem";
import ShopItem from "@/views/appTask/shopGroup/ShopItem";
import GrpShop from "@/views/appTask/shopGroup";
import GrpSku from "@/views/appTask/skuGroup";
import DictSelect from "@/components/DictSelect";
import {parseTime, deepClone, excelExport} from "@/utils";
import {Message, MessageBox, Notification} from "element-ui";
import OssUpload from "@/components/OssUpload";

export default {
  name: "Edit",
  components: {
    SkuItem,
    ShopItem,
    DictSelect,
    GrpShop,
    GrpSku,
    Pagination,
    OssUpload,
    ImportDialog: () => import("@/components/ImportDialog")
  },
  props: {
    taskId: {
      type: String,
    },
    type: {
      type: String,
    },
    specialAuth: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      loadingPage: false,
      pageData: {
        total: 0,
        rows: [],
        selections: [],
      },
      skuPage: {
        total: 0,
        rows: [],
        current: 1,
        size: 10,
      },
      dynamicTagHeader: '标签',
      shopPage: {
        total: 0,
        current: 1,
        rows: [],
        size: 10,
      },
      filterDcText: undefined,
      filterTaskDcText: undefined,
      formLoading: false,
      formInit: true,
      form: {
        current: 1,
        size: 10,
        taskName: undefined,
        orgCode: undefined,
        orgName: '全国',
        taskType: 1,
        status: 0,
        sort: 0,
        flowJobStatus: 1,
        startDate: undefined,
        endDate: undefined,
        description: undefined,
        skus: [],
        skuSorts: [],
        errorSkus: [],
        repeatSkus: [],
        shops: [],
        exceptCodes: [],
        exceptModels: [],
        exceptSkuCodes: [],
        taskRange: '0',
        ksCompareStartTime: undefined,
        ksCompareEndTime: undefined,
        showInApp: '1',
        ifShowMsg: undefined,
        ifSpStyle: undefined,
        checkedOrgCodes: [],
        checkOrgCode: undefined,
        confOrgList: [],
        scope: '0',
        importScope: '0',
        writeOffEndTime: undefined,
        runFlow: false,
        kssType: 'model',
        tmpSkus: [],
        shopCheckType: "0",
        taskTag: undefined,
        compareOrgCode: undefined,
        showOrgCode: undefined,
        batchOrgCode: undefined,
        offsetDays: undefined,
        timeType: '0',
        shopGroupId: undefined,
        ifAutoSupply: 0,
        ifTzSplit: 1,
        dcSort: 0,
        shareTitle: undefined,
        subShareTitle: undefined,
        h5ShareUrl: undefined,
        weChatShareIcon: undefined,
        appShareIcon: undefined,
        shareBoxTitle: undefined,
        shareBoxDesc: undefined,
      },
      querySkuKeyWord: undefined,
      queryDcCode: undefined,
      queryShopCode: undefined,
      ksDate: [],
      taskDate: [],
      rules: {
        taskName: [{required: true, message: " ", trigger: "change"}],
        checkedOrgCodes: [{required: true, message: " ", trigger: "change"}],
        startDate: [{required: true, message: " ", trigger: "change"}],
        description: [{required: true, message: " ", trigger: "blur"}],
        writeOffEndTime: [{required: true, message: " ", trigger: "blur"}],
        offsetDays: [{required: true, message: " ", trigger: "blur"}],
      },
      loading: false,
      tabName: "sku",
      drawer: {
        visible: false,
      },
      dictFun: dictSelect,
      shopGroups: {
        fixed: [],
        dynamic: [],
        activeIndex: 'ALL',
        activeIndexPath: '更多',
        refresh: false,
      },
      skuGroups: {
        fixed: [],
        dynamic: [],
        activeIndex: 'ALL',
        activeIndexPath: '更多',
        refresh: false,
      },
      fixIndex: 6,
      allArray: [{id: 'ALL', groupName: '全部'}],
      addDialog: {
        visible: false,
        dialogTitle: {
          'sku': '新建商品组',
          'shop': '新建店铺组'
        }
      },
      pickerOptions: {
        disabledDate(date) {
          const time = date.getTime() - window.taskEndDate.getTime()  + 24 * 60 * 60 * 1000;
          return time < 0;
        },
      },
      importDialog: {
        currentImportType: "sku",
        importBaseParams: [
          {
            visible: false,
            title: "任务-商品数据导入",
            type: "sku",
            tipsText: "提示：商品型号与货号至少一个不能为空,同时存在时以商品型号为主。排序（选填，纯数字，如不填按销量排序）",
            templateSampleHead: [
              {label: "商品型号", prop: "skuModel", minWidth: "80px"},
              {label: "货号", prop: "cargoNo", minWidth: "80px"},
              {label: "标签", prop: "label", minWidth: "80px"},
              {label: "排序", prop: "sort", minWidth: "80px"},
              {label: "自定义商品名称", prop: "customModelName", minWidth: "80px"}
            ],
            templateSampleLists: [
              {
                skuModel: "1002E4G",
                cargoNo: "1002E4G",
                label: "测试标签",
                sort: '0',
                customModelName: '自定义商品名称'
              },
            ],
            apiParams: "importList",
            previewDataLength: 10,
            templateFileDownloadUrl: process.env.VUE_APP_OSS_TEMPLATE_PATH + '/%E4%BB%BB%E5%8A%A1-%E5%95%86%E5%93%81%E6%95%B0%E6%8D%AE%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xls',
          },
          {
            visible: false,
            title: "任务-商品数据导入",
            type: "skuCode",
            tipsText: "提示：商品编码不能为空。排序（选填，纯数字，如不填按销量排序）",
            templateSampleHead: [{label: "商品编码", prop: "skuCode", minWidth: "80px"},{label: "标签", prop: "label", minWidth: "80px"},
              {label: "排序", prop: "sort", minWidth: "80px"},{label: "自定义商品名称", prop: "customModelName", minWidth: "80px"}],
            templateSampleLists: [
              {
                skuCode: "609023",
                label: "测试标签",
                sort: '0',
                customModelName: '自定义商品名称'
              },
            ],
            apiParams: "importList",
            previewDataLength: 10,
            templateFileDownloadUrl: process.env.VUE_APP_OSS_TEMPLATE_PATH + '/%E4%BB%BB%E5%8A%A1-%E5%95%86%E5%93%81%E7%BC%96%E7%A0%81%E6%95%B0%E6%8D%AE%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xls',
          },
          {
            visible: false,
            title: "任务-店铺数据导入",
            type: "shop",
            tipsText: "必需字段: 配送中心编码、店铺编码</br>不存在的或近2年无进货店铺数据隔天将在聚宝盆APP显示",
            templateSampleHead: [{label: "配送中心编码", prop: "dcCode", minWidth: "80px"},
              {
                label: "店铺编码",
                prop: "shopCode",
                minWidth: "80px"
              }],
            templateSampleLists: [
              {
                dcCode: "C03017",
                shopCode: "0190",
              },
            ],
            previewDataLength: 10,
            apiParams: "importList",
            templateFileDownloadUrl: process.env.VUE_APP_OSS_TEMPLATE_PATH + '/%E5%BA%97%E9%93%BA%E6%95%B0%E6%8D%AE%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xls',
          }
        ],
      },
      taskTypeOptions: [{value: 0, label: '常规'}, {value: 1, label: '自建'}],
      noticeDialog: {
        visible: false,
        msg: undefined,
      },
      ifComponent: undefined,
      initOrgOptions: [],
      defaultQueryOptions: [],
      queryConfigRows: [],
      orgFilterMap: {},
      allOrgMap: {},
      exportHeaders: {
        skuModel: {
          skuModel: '商品型号',
          cargoNo: '货号',
          label: '标签',
          sort: '排序',
          customModelName: '自定义商品名称',
          errorMsg: '校验结果'
        },
        skuCode: {
          skuCode: '商品编码',
          label: '标签',
          sort: '排序',
          customModelName: '自定义商品名称',
          errorMsg: '校验结果',
        },
        shop: {
          oldDcCode: '配送中心编码',
          oldShopCode: '店铺编码',
          dcName: '配送中心名称',
          shopName: '店铺名称',
          errorMsg: '校验结果'
        }
      },
      orgDcTree: [],
      parseTime: undefined,
      sortDialog: {
        visible: false,
        sorts: [],
        removeSorts: []
      },
      shopGroupOptions: [],
      share: {
        active: false,
      }
    };
  },
  computed: {
    skuOtherName: function () {
      return this.skuGroups.activeIndexPath;
    },
    shopOtherName: function () {
      return this.shopGroups.activeIndexPath;
    },
    "skuPage.total": function () {
      return this.form.skus.length;
    },
    importBaseParams() {
      const importDialog = this.importDialog;
      return importParams => {
        return importParams.find(item => item.type === importDialog.currentImportType);
      };
    },
    formNew() {
      return JSON.parse(JSON.stringify(this.form));
    },
    filterOrgDcTree() {
      return this.orgDcTree.filter(e => this.form.checkedOrgCodes.indexOf(e.value) > -1 || this.form.checkedOrgCodes.indexOf('ALL') > -1);
    },
    orgCompareShow() {
      if (this.form.compareOrgCode) {
        if (this.orgFilterMap[this.form.compareOrgCode].ksCompareChoose === '1' && this.orgFilterMap[this.form.compareOrgCode].ksCompareDate &&  this.orgFilterMap[this.form.compareOrgCode].ksCompareDate.length > 0) {
          return parseTime(this.orgFilterMap[this.form.compareOrgCode].ksCompareDate[0], '{y}-{m}-{d}') + '至' + parseTime(this.orgFilterMap[this.form.compareOrgCode].ksCompareDate[1], '{y}-{m}-{d}');
        } else if (this.orgFilterMap[this.form.compareOrgCode].ksCompareChoose === '0') {
          return "前180天"
        }
      }
      return;
    }
  },
  watch: {
    taskId: {
      handler(newVal) {
        if (this.initOrgOptions.length === 0) {
          this.initOptions().then(async () => this.getDetail(newVal));
        } else {
          this.getDetail(newVal);
        }
      },
      immediate: true,
      deep: true,
    },
    "drawer.visible": {
      handler(newVal) {
        if (newVal) {
          this.getMenuIndex(this.tabName);
          if (this.tabName === 'sku') {
            this.skuGroups.refresh = true;
          } else {
            this.shopGroups.refresh = true;
          }
        } else {
          if (this.tabName === 'sku') {
            this.skuGroups.refresh = false;
          } else {
            this.shopGroups.refresh = false;
          }
        }
      },
    },
    "form.checkedOrgCodes": {
      handler(newVal, oldVal) {
        this.handleDictChange(newVal, oldVal);
        this.handleRestShops();
        if (this.formInit) {
          setTimeout(() => {
            this.formInit = false;
          }, 500)
        }
        this.$nextTick(() => {
          if (this.$refs.orgDcTreeRef) {
            this.$refs.orgDcTreeRef.setCheckedKeys([], true);
          }
        })
      },
    },
    "form.taskRange": {
      handler(newVal, oldVal) {
        if (newVal === '4') {
          this.handleZdTaskRelated();
        } else {
          this.resetDefaultView();
        }
      }
    },
    formNew: {
      handler(newVal, oldVal) {
        if (!newVal.runFlow && !this.formInit && oldVal && newVal.mtTaskId && oldVal.mtTaskId === newVal.mtTaskId) {
          if (oldVal && this.validateEqual(newVal, oldVal)) {
            this.form.runFlow = true;
          }
        }
      },
      immediate: true,
      deep: true,
    },
    "form.orgCode": {
      handler(newVal, oldVal) {
        this.form.shopGroupId = undefined;
        if (newVal!=='ALL') {
          pageShopGroup('?status=1&size=100&orgCode='+(newVal=='ALL'?'':newVal)).then(res => {
            this.shopGroupOptions = res.data.records;
          })
        } else {
          this.shopGroupOptions = []
        }
      },
    },
  },
  created() {
    this.initOptions();
    getOrgDcTree().then(res => {
      this.orgDcTree = res.data;
    })
  },
  mounted() {
    this.getMenuIndex('all');
  },
  methods: {
    validateEqual(newVal, oldVal) {
      return newVal.description === oldVal.description && newVal.taskName===oldVal.taskName && newVal.shareTitle===oldVal.shareTitle && newVal.h5ShareUrl===oldVal.h5ShareUrl && newVal.weChatShareIcon ===oldVal.weChatShareIcon;
    },
    async initOptions() {
      // await dictSelect('query-conf', {}).then(async res => {
      //   this.defaultQueryOptions = res.data;
      // })
      await this.resetConfigRows();
      return new Promise((resolve, reject) => {
        dictSelect('ht-org-all').then(res => {
          this.initOrgOptions = res.data;
          let orgFilterMapSelect = this.initOrgFilterMapSelections(null)
          this.initOrgOptions.forEach(item => {
            this.allOrgMap[item.value] = item.label;
            this.orgFilterMap[item.value] = {
              selections: orgFilterMapSelect.selections,
              compareDate: orgFilterMapSelect.compareDate,
              customName: undefined,
              sort: 1,
              queryConfig: orgFilterMapSelect.queryConfig,
              noValue: undefined,
              ksCompareDate: [],
              ksCompareChoose: '0',
              appShow: 1,
              ifBatchAddCart: '0'
            }
          })
          resolve();
        })
      })
    },
    initOrgFilterMapSelections() {
      let selections;
      let queryConfig;
      let compareDate = [];
      selections = Object.assign([], this.defaultQueryOptions).filter(e => {
        if (e !== '近30天' && e !== '近60天' && e !== '近1年') {
          return true
        }
      }).map(e => {return {'key': e}});
      queryConfig= selections.map(e => e.key).join(',');
      return {selections: selections, queryConfig: queryConfig, compareDate: compareDate};
    },
    refreshOrgFilterMapQueryConfig(checkOrgCode) {
      if (!checkOrgCode || !this.form.startDate || !this.form.endDate) return;
      this.orgFilterMap[this.form.checkOrgCode].compareDate = []
      this.orgFilterMap[this.form.checkOrgCode].compareDate.push(this.form.startDate, this.form.endDate);
      if (this.orgFilterMap[this.form.checkOrgCode].selections.filter(e => e.key === '固定期').length <= 0) {
        this.orgFilterMap[this.form.checkOrgCode].selections.push({key: '固定期'})
      }
      if (this.orgFilterMap[this.form.checkOrgCode].queryConfig) {
        const config = this.orgFilterMap[this.form.checkOrgCode].queryConfig;
        let tmpArray = [];
        config.split(',').forEach(e => {
          if (this.defaultQueryOptions.indexOf(e) > -1) {
            tmpArray.push(e);
          } else {
            if (this.orgFilterMap[this.form.checkOrgCode].customName) {
              tmpArray.splice(0, 0, e);
            } else {
              tmpArray.splice(0, 0, this.getFixConfig());
            }
          }
        })
        this.orgFilterMap[this.form.checkOrgCode].queryConfig = tmpArray.join(',');
      }
      this.handlePopoverShow(checkOrgCode)
    },
    getMenuIndex(type) {
      if (this.tabName === 'sku' && (type === 'all' || type === 'sku')) {
        pageSkuModelGroup('?status=1&size=100').then(res => {
          if (res.data.records.length > this.fixIndex) {
            this.skuGroups.fixed = this.allArray.concat(res.data.records.slice(0, this.fixIndex));
            this.skuGroups.dynamic = res.data.records.slice(this.fixIndex);
          } else {
            this.skuGroups.fixed = this.allArray.concat(res.data.records);
          }
          if (this.skuGroups.activeIndex !== 'ALL') {
            const fixSelect = this.skuGroups.fixed.find(e => e.groupId === this.skuGroups.activeIndex);
            if (!fixSelect) {
              const dynamicSelect = this.skuGroups.dynamic.find(e => e.groupId === this.skuGroups.activeIndex);
              if (!dynamicSelect) {
                this.skuGroups.activeIndex = 'ALL';
              }
            }
          }
        })
      }
      if (this.tabName === 'shop' && (type === 'all' || type === 'shop')) {
        pageShopGroup('?status=1&size=100').then(res => {
          if (res.data.records.length > this.fixIndex) {
            this.shopGroups.fixed = this.allArray.concat(res.data.records.slice(0, this.fixIndex));
            this.shopGroups.dynamic = res.data.records.slice(this.fixIndex);
          } else {
            this.shopGroups.fixed = this.allArray.concat(res.data.records);
          }

          if (this.shopGroups.activeIndex !== 'ALL') {
            const fixSelect = this.shopGroups.fixed.find(e => e.groupId === this.shopGroups.activeIndex);
            if (!fixSelect) {
              const dynamicSelect = this.shopGroups.dynamic.find(e => e.groupId === this.shopGroups.activeIndex);
              if (!dynamicSelect) {
                this.shopGroups.activeIndex = 'ALL';
              }
            }
          }
        })
      }
    },
    getDetail(val) {
      window.taskEndDate = new Date();
      this.reset();
      if (val !== undefined) {
        this.formLoading = true;
        getTaskInfo(val).then(res => {
          if ((res.data.checkedOrgCodes instanceof Array) && res.data.checkedOrgCodes.length === 1) {
            res.data.checkedOrgCodes = res.data.checkedOrgCodes[0];
          }
          res.data.scope = res.data.scope + '';
          res.data.importScope = res.data.importScope + '';
          let tmpForm = deepClone(this.form)
          this.formInit = true;
          tmpForm= Object.assign(tmpForm, res.data);
          if (tmpForm.offsetDays && tmpForm.offsetDays > 0) {
            tmpForm.timeType = '1'
          } else {
            tmpForm.timeType = '0'
          }
          if (tmpForm.writeOffEndTime) {
            window.taskEndDate = new Date(tmpForm.endDate);
          }

          if (res.data.startDate && res.data.endDate) {
            this.taskDate = [res.data.startDate, res.data.endDate];
          }

          if (tmpForm.status === 3) {
            tmpForm.status = 2;
          }
          if (tmpForm.importScope === '0') {
            tmpForm.exceptModels = tmpForm.skus.map(e => e && e.skuModel ? e.skuModel : '');
          } else if (tmpForm.importScope === '1') {
            tmpForm.exceptSkuCodes = tmpForm.skus.map(e => e && e.skuCode ? e.skuCode : '');
          }

          if (!tmpForm.shareIcon) {
            tmpForm.shareIcon = '';
          }
          tmpForm.exceptCodes = tmpForm.shops.map(e => e.dcCode + "-" + e.shopCode);
          tmpForm.confOrgList.forEach(e => {
            const date = e.compareDateStart && e.compareDateEnd ? [e.compareDateStart, e.compareDateEnd] : [];
            let selections = [];
            if (e.queryConfig) {
              e.queryConfig.split(',').forEach(d => {
                if (this.defaultQueryOptions.indexOf(d) > -1) {
                  selections.push({ key: d});
                } else {
                  selections.push({ key: '固定期'});
                }
              })
            }
            const compareChoose = e.ksCompareStartTime && e.ksCompareEndTime
            this.orgFilterMap[e.orgCode] = {
              selections: selections,
              compareDate: date,
              customName: e.customName,
              sort: e.sort,
              queryConfig: e.queryConfig,
              noValue: undefined,
              ksCompareDate: compareChoose ? [e.ksCompareStartTime, e.ksCompareEndTime]: [],
              ksCompareChoose: compareChoose? '1': '0',
              appShow: e.appShow,
              ifBatchAddCart: e.ifBatchAddCart
            }
          })
          this.handleSetTreeChecked(tmpForm)

          this.skuPage.total = tmpForm.skus.length;
          this.shopPage.total = tmpForm.shops.length;

          this.form = tmpForm;
          this.handleQuery('skus', 'skuPage');
          this.handleQuery('shops', 'shopPage');
          // this.$emit('set-size', tmpForm.skus.length, tmpForm.shops.length);
          this.setLength();
          // tmpForm.formInit = false;
          this.$forceUpdate();
          this.formLoading = false;
          this.resetConfigRows();

          setTimeout(() => {
            if (this.formInit) {
            this.formInit = false;
            }
          }, 1000)
        })
        this.resetTree();
      } else {
        this.$emit('set-size', 0, 0);
        this.resetConfigRows();
        if (this.initOrgOptions.length === 1) {
          this.form.checkedOrgCodes = this.initOrgOptions.length > 1 ? [this.initOrgOptions[0].value]: this.initOrgOptions[0].value;
        }
        this.form.shopCheckType = '0'
        this.resetTree();
        setTimeout(() => {
          if (this.formInit) {
            this.formInit = false;
          }
        }, 1000)
      }
    },
    async resetConfigRows() {
      if (this.defaultQueryOptions.length === 0) {
        await dictSelect('query-conf', {}).then(res => {
          this.defaultQueryOptions = res.data;
        })
      }
      this.queryConfigRows = Object.assign([], this.defaultQueryOptions).map(e => {
        return {'key': e}
      });
      if (this.form.taskRange === '0') {
        this.queryConfigRows.push({ 'key': '固定期'});
      } else if (this.form.taskRange === '4') {
        this.queryConfigRows.push({ 'key': '固定期'});
        this.handleZdTaskRelated();
      }
    },
    reset() {
      let form = {
        taskName: undefined,
        orgCode: undefined,
        orgName: undefined,
        taskType: 1,
        sort: 0,
        flowJobStatus: 1,
        startDate: undefined,
        endDate: undefined,
        createDate: undefined,
        status: 0,
        createUser: undefined,
        skus: [],
        shops: [],
        exceptModels: [],
        exceptSkuCodes: [],
        removeSelections: [],
        exceptCodes: [],
        description: undefined,
        ksDate: undefined,
        taskRange: '0',
        ksCompareStartTime: undefined,
        ksCompareEndTime: undefined,
        showInApp: '1',
        ifShowMsg: undefined,
        ifSpStyle: undefined,
        checkedOrgCodes: [],
        checkOrgCode: undefined,
        scope: '0',
        importScope:'0',
        writeOffEndTime: undefined,
        kssType: 'model',
        runFlow: false,
        shareTitle: undefined,
        subShareTitle: undefined,
        h5ShareUrl: undefined,
        weChatShareIcon: undefined,
        appShareIcon: undefined,
        shareBoxTitle: undefined,
        shareBoxDesc: undefined,
        shopCheckType: "0",
        taskTag: undefined,
        tmpSkus: [],
        compareOrgCode: undefined,
        offsetDays: undefined,
        timeType: '0',
        shopGroupId: undefined,
        ifAutoSupply: 0,
        ifTzSplit: 1,
        dcSort: 0,
      };
      this.skuPage = {
        total: 0,
        current: 1,
        rows: [],
        size: 10
      };
      this.shopPage = {
        total: 0,
        current: 1,
        rows: [],
        size: 10
      };
      this.ksDate = [];
      this.taskDate = [];
      this.tabName ='sku';
      this.form = Object.assign({}, form);
      this.querySkuKeyWord = undefined;
      this.queryDcCode = undefined;
      this.queryShopCode = undefined;
      if (this.initOrgOptions.length === 1) {
        this.form.checkedOrgCodes = this.initOrgOptions.length > 1 ? [this.initOrgOptions[0].value]: this.initOrgOptions[0].value;
        this.handleDictChange(this.form.checkedOrgCodes);
      }
      this.resetConfigRows();
      this.$forceUpdate();
    },
    handleDateChange(val, dateType) {
      if (val) {
        if (dateType === 'task') {
          this.form.startDate = parseTime(val[0].getTime(), '{y}-{m}-{d}') + ' 00:00:00';
          this.form.endDate = parseTime(val[1].getTime(), '{y}-{m}-{d}') + ' 23:59:59';
          window.taskEndDate = new Date(this.form.endDate);

          // 新建任务
          if (!this.form.mtTaskId) {
            this.form.writeOffEndTime = parseTime(val[1].getTime() + 7 * 24 * 60 * 60 * 1000, '{y}-{m}-{d}') + ' 23:59:59';
            this.orgFilterMap[this.form.checkOrgCode].ksCompareChoose = undefined;
            this.orgFilterMap[this.form.checkOrgCode].ksCompareDate = [];
            Notification.info("新增对比款式周期已变更，请重新选择！")
            // 更新未进货对比周期
            this.refreshOrgFilterMapQueryConfig(this.form.checkOrgCode)
          }
        }
      } else {
        if (dateType === 'task') {
          this.form.startDate = undefined;
          this.form.endDate = undefined;
        }
      }
    },
    handleSelectionChange(selections) {
      this.pageData.selections = selections;
    },
    async handleImportData(val) {
      this.noticeDialog.msg =""
      if (this.tabName === 'sku') {
        const checkedArray = Object.assign([], this.form.skus);
        checkedArray.forEach(e => e.dataType = '0');
        let tempArray = val["importList"];
        let errorData = [];
        tempArray.forEach(e => {
          if (this.form.importScope === '0' && !e.skuModel && !e.cargoNo) {
            e.errorMsg = '商品型号和货号不可均为空'
            errorData.push(1)
          } else if (this.form.importScope === '1' && !e.skuCode) {
            errorData.push(1)
            e.errorMsg = '商品商品编码不能为空'
          }
          e.dataType = '1';
        })
        let msg = undefined;
        if (this.form.importScope === '1' && errorData.length === tempArray.length) {
          msg = " 导入失败，导入的商品编码不能为空，请填写后重新导入！"
        } else if (this.form.importScope === '0' && errorData.length > 0) {
          msg = " 导入失败，请重新上传，存在" + errorData.length+ " 条数据，商品型号和货号均为空！";
        }
        if (msg) {
          MessageBox.alert(msg, '提示', {
            dangerouslyUseHTMLString: true
          });
          return;
        }
        const param = tempArray.concat(checkedArray);
        await checkSkus(this.form.importScope, { skus: param }).then((res) => {
          const errorCount = res.data.skus.filter(e => e.checkResult === '3' && e.dataType === '1').length;
          this.form.tmpSkus = [].concat(res.data.skus.filter(e => e.dataType === '1'));
          if (errorCount > 0) {
            this.noticeDialog.msg = " 导入失败，请重新上传" + res.data.errorMsg;
            this.noticeDialog.visible = true;
            return;
          } else {
            const repeatCount = res.data.skus.filter(e => e.checkResult === '2' && e.dataType === '1').length;
            const successCount = res.data.skus.filter(e => e.checkResult === '1' && e.dataType === '1').length;
            this.noticeDialog.msg = "导入成功， 共 " + tempArray.length + " 条，成功 " + successCount + " 条，重复 " + repeatCount + " 条数据";
            this.noticeDialog.visible = true;
            this.form.skus = res.data.skus.filter(e => e.dataType === '0' || ( e.dataType === '1' && e.checkResult === '1'));
          }
        })
        if (this.form.importScope === '0') {
          this.form.exceptModels = this.form.skus.map(e => e && e.skuModel ? e.skuModel : '');
        } else if (this.form.importScope === '1') {
          this.form.exceptSkuCodes = this.form.skus.map(e => e && e.skuCode ? e.skuCode : '');
        }
        this.skuPage.total = this.form.skus.length;
      } else if (this.tabName === 'shop') {
        let checkedArray = Object.assign([], this.form.shops).filter(e => e.dcCode != 'ALL');
        checkedArray.forEach(e => e.dataType = '0');
        let tempArray = val["importList"];
        let errorData = [];
        tempArray.forEach(e => {
          if (!e.dcCode || !e.shopCode) {
            errorData.push(1)
          }
          e.dataType = '1';
        })
        if (errorData.length > 0) {
          MessageBox.alert(" 导入失败，请重新上传，存在" + errorData.length + " 条数据，配送中心编码或店铺编码为空", '提示', {
            dangerouslyUseHTMLString: true
          });
          return;
        }
        const param = tempArray.concat(checkedArray);
        await checkShops({shops: param}).then((res) => {
          if (res.data && res.data.length) {
            let successNum = 0;
            let errorNum = 0;
            let errorData = []
            let errorDc = []
            let virtualDcData = [];

            let totalRepeatNum = 0;
            let importCount = 0;
            res.data.filter(e => {
              if (e.dataType === '1') {
                importCount++;
              }
              if (e.checkResult !== '0') {
                successNum++;
              } else {
                errorDc.push(e.oldDcCode);
              }

              if (e.checkResult.indexOf('5') > -1) {
                if (e.checkResult.indexOf('4') > -1) {
                  errorData.push("配送中心编码：" + e.oldDcCode + "(原) -> " + e.dcCode + "(新)；   店铺编码:" + e.oldShopCode + "(原) -> " + e.shopCode + "(新)");
                } else {
                  errorData.push('配送中心编码：' + e.dcCode + "&nbsp;&nbsp;&nbsp;店铺编码: " + e.shopCode);
                }
              }
              if (e.checkResult.indexOf('4') > -1) {
                virtualDcData.push("配送中心编码：" + e.oldDcCode + "(原) -> " + e.dcCode + "(新)；   店铺编码:" + e.oldShopCode + "(原) -> " + e.shopCode + "(新)");
              }

              if (e.checkResult.indexOf('2') > -1 || e.checkResult.indexOf('3') > -1) {
                totalRepeatNum++;
              }

            });

            if (errorDc.length > 0) {
              this.noticeDialog.msg = "以下配送中心无权限或不存在，请确认后重新导入，列表如下：</br>" + errorDc.join('、');
              this.noticeDialog.visible = true;
              return;
            }
            this.noticeDialog.msg = "共 " + importCount + " 条，成功 " + successNum + " 条，重复 " + totalRepeatNum + " 条，" +
              "无法匹配 " + (errorNum) + " 条,</br>";
            if (virtualDcData.length > 0) {
              this.noticeDialog.msg += "</br></br>以下店铺(以'_'分割）存在多个配送中心进货，实际为同一店铺，为在聚宝盆中合并统计，配送中心及店铺编码合并为：</br>" + virtualDcData.join('</br>');
            }
            if (errorData.length > 0) {
              this.noticeDialog.msg += '</br></br>无效店铺列表如下（原因：不存在该店铺或近2年无进货,将在30分钟内自动处理）：</br>' + errorData.join('</br>')
            }
            this.noticeDialog.visible = true;
            this.form.shops = res.data.filter(e => e.dataType === '0' || (e.dataType === '1' && e.checkResult.indexOf('2') < 0 && e.checkResult.indexOf('3') < 0));
          }
        })
        this.shopPage.total = this.form.shops.length;
        this.form.exceptCodes = this.form.shops.map(e => e.dcCode + "-" + e.shopCode);
      }
      const currentImportOption = this.importDialog.importBaseParams.find(item => item.type === this.tabName);
      this.importDialog.currentImportType = currentImportOption.type;
      currentImportOption.visible = false;
      this.$forceUpdate();
      this.setLength();
      this.handleQuery('skus', 'skuPage');
      this.handleQuery('shops', 'shopPage');
    },
    handleCustomChange(val, row) {
      setTimeout(() => {
        this.form.skus.forEach(e => {
          if (e.skuModel == row.skuModel) {
            e.customModelName = val;
          }
        })
        this.$forceUpdate();
        this.handleQuery('skus', 'skuPage');
      }, 200)
      this.form.runFlow = true
    },
    addItems(items, type) {
      if (type === "sku") {
        const newItems = items.filter(e => {
          if (this.form.exceptModels.indexOf(e.skuModel) < 0 && this.form.importScope === '0') {
            return true;
          } else if (this.form.exceptSkuCodes.indexOf(e.skuCode) < 0 && this.form.importScope === '1') {
            return true;
          }
        });
        let modelMap = {};
        this.form.skus.filter(e => e.skuModel && e.customModelName).forEach(e => {
          if (!modelMap[e.skuModel]) {
            modelMap[e.skuModel] = e.customModelName;
          }
        })
        newItems.forEach(e => {
          if (modelMap[e.skuModel]) {
            e.customModelName = modelMap[e.skuModel];
          }
        })
        this.form.skus = this.form.skus.concat(newItems);
        if (this.form.importScope === '0') {
          this.form.exceptModels = Array.from(new Set(this.form.exceptModels.concat(newItems.map(e => e.skuModel))));
        } else if (this.form.importScope === '1') {
          this.form.exceptSkuCodes = Array.from(new Set(this.form.exceptSkuCodes.concat(newItems.map(e => e.skuCode))));
        }

        this.skuPage.total = this.form.skus.length;
        this.handleQuery('skus', 'skuPage');
      } else if (type === "shop") {
        const newItems = items.filter(e => {
          if (this.form.exceptCodes.indexOf(e.dcCode + "-" + e.shopCode) < 0) {
            return true;
          }
        });
        const addOrgCodes = Array.from(new Set(items.map(e => e.orgCode)));
        const orgAll = Array.from(new Set(this.form.shops.filter(e => addOrgCodes.indexOf(e.orgCode) > -1 && e.dcCode === 'ALL')));
        if (orgAll.length > 0) {
          Notification.warning('本次提交会覆盖' + orgAll.map(e => e.orgName).join('、') + '分公司上次配置，以本次所选店铺为准')
        }
        this.form.shops = this.form.shops.filter(e => addOrgCodes.indexOf(e.orgCode) < 0 || (addOrgCodes.indexOf(e.orgCode) > -1 && e.dcCode != 'ALL')).concat(newItems);
        this.form.exceptCodes = this.form.exceptCodes.concat(newItems.map(e => e.dcCode + "-" + e.shopCode));
        this.shopPage.total = this.form.shops.length;
        this.handleQuery('shops', 'shopPage');
      }
      this.setLength();
    },
    handleRestShops() {
      if (this.form.checkedOrgCodes instanceof Array) {
        this.form.shops = this.form.shops.filter(e => this.form.checkedOrgCodes.indexOf(e.orgCode) > -1);
      } else {
        this.form.shops = this.form.shops.filter(e => this.form.checkedOrgCodes === e.orgCode);
      }
      this.form.exceptCodes = this.form.shops.map(e => e.dcCode + "-" + e.shopCode);
      this.shopPage.total = this.form.shops.length;
      this.setLength();
      this.handleQuery('shops', 'shopPage');
    },
    handleDictChange(val, oldVal) {
      if (val) {
        this.$nextTick(() => {
          let orgCodes = [];
          if (this.initOrgOptions.length === 0) {
            return;
          }
          if (this.initOrgOptions.length > 1 && this.form.checkedOrgCodes instanceof Array) {
            if (oldVal && oldVal.length > 1) {
              if (oldVal.indexOf('ALL') > -1 && val.indexOf('ALL') > -1 && val.length < this.initOrgOptions.length) {
                this.form.checkedOrgCodes = this.form.checkedOrgCodes.filter(e => e !== 'ALL');
              } else if (oldVal.indexOf('ALL') === -1 && val.indexOf('ALL') > -1 && val.length < this.initOrgOptions.length) {
                this.form.checkedOrgCodes = this.initOrgOptions.map(e => e.value);
              } else if (oldVal.indexOf('ALL') > -1 && val.indexOf('ALL') < 0 && val.length === this.initOrgOptions.length -1) {
                this.form.checkedOrgCodes = [];
              } else if (oldVal.indexOf('ALL') < 0 && val.indexOf('ALL') < 0 && val.length === this.initOrgOptions.length -1) {
                this.form.checkedOrgCodes = this.initOrgOptions.map(e => e.value);
              }
            } else {
              if (val.indexOf('ALL') > -1 && val.length < this.initOrgOptions.length) {
                this.form.checkedOrgCodes = this.initOrgOptions.map(e => e.value);
              } else if (val.indexOf('ALL') < -1 && val.length === this.initOrgOptions.length -1) {
                this.form.checkedOrgCodes = this.initOrgOptions.map(e => e.value);
              }
            }
            // 多选或包含全国
            if (this.form.checkedOrgCodes.length > 1) {
              // this.form.checkedOrgCodes = this.initOrgOptions.map(e => e.value);
              this.form.orgName = '全国';
            } else if (this.form.checkedOrgCodes.length === 1) {
              const orgInfo = this.initOrgOptions.find(e => e.value === val[0]);
              if (orgInfo) {
                this.form.orgName = orgInfo.label;
              }
            }
            this.form.checkOrgCode = this.form.checkedOrgCodes.find(e => e !== 'ALL');
            this.form.compareOrgCode = this.form.checkedOrgCodes.find(e => e !== 'ALL');
            this.form.showOrgCode = this.form.checkedOrgCodes.find(e => e !== 'ALL');
            this.form.batchOrgCode = this.form.checkedOrgCodes.find(e => e !== 'ALL');
            if (this.form.checkedOrgCodes.length ===  1) {
              const orgInfo = this.initOrgOptions.find(e => e.value === val);
              this.form.orgCode = this.form.checkOrgCode;
              if (orgInfo) {
                this.form.orgName = orgInfo.label;
              }
            }
            orgCodes = Object.assign([], this.form.checkedOrgCodes);
          } else {
            const orgInfo = this.initOrgOptions.find(e => e.value === val);
            this.form.checkOrgCode = orgInfo.value;
            this.form.compareOrgCode = orgInfo.value + '';
            this.form.showOrgCode = orgInfo.value + '';
            this.form.batchOrgCode = orgInfo.value + '';
            this.form.orgCode = orgInfo.value;
            orgCodes.push(orgInfo.value);
            if (orgInfo) {
              this.form.orgName = orgInfo.label;
            }
          }

          let tmpOrgMap = {};
          let initOrgFilterMapSelections = this.initOrgFilterMapSelections();
          orgCodes.forEach(e => {
            if (this.orgFilterMap[e]) {
              tmpOrgMap[e] = Object.assign({}, this.orgFilterMap[e]);
            } else {
              tmpOrgMap[e] = {
                selections: initOrgFilterMapSelections.selections,
                compareDate: initOrgFilterMapSelections.compareDate,
                customName: undefined,
                sort: 1,
                queryConfig: initOrgFilterMapSelections.queryConfig,
                noValue: undefined,
                ksCompareDate: [],
                ksCompareChoose: '0',
                appShow: 1,
                ifBatchAddCart: e.ifBatchAddCart
              }
            }
          })
          this.orgFilterMap = Object.assign({}, tmpOrgMap);
        })
      } else {
        this.form.orgName = undefined;
      }
    },
    handleMenuSelect(val, path, type) {
      this[type].activeIndex = val;
      if (path.length > 1) {
        this[type].activeIndexPath = '更多(' + this[type].dynamic.find(e => e.id === val).groupName + ')';
      } else {
        this[type].activeIndexPath = '更多'
      }
    },
    handleAddGrp() {
      this.ifComponent = this.tabName
    },
    handleQuery(type, page) {
      const filterData = this.form[type].filter(e => {
        if (type === 'skus') {
          if (this.form.importScope === '0') {
            return !this.querySkuKeyWord || e.skuModel.indexOf(this.querySkuKeyWord) > -1;
          } else if (this.form.importScope === '1') {
            return !this.querySkuKeyWord || e.skuCode.indexOf(this.querySkuKeyWord) > -1;
          }
        } else if (type === 'shops') {
          let filterFlag = true;
          if (this.queryDcCode) {
            filterFlag = e.dcCode.indexOf(this.queryDcCode) > -1;
          }
          if (filterFlag && this.queryShopCode) {
            filterFlag = e.shopCode.indexOf(this.queryShopCode) > -1;
          }
          return filterFlag;
        }
        return false;
      })
      const total = this[page].current * this[page].size;
      this[page].total = filterData.length;
      this[page].rows = filterData.slice(this[page].size * (this[page].current - 1), total >= filterData.length ? filterData.length : this[page].current * this[page].size);
      const tableRef = type === 'skus'? 'skuTableRef' : 'shopTableRef';
      this.$nextTick(() => {
        if(this.$refs[tableRef]) {
          this.$refs[tableRef].doLayout();
        }
      })
    },
    handleImport() {
      const type = this.tabName === 'shop' ? 'shop' : (this.form.importScope === '0' && this.tabName === 'sku'? 'sku': 'skuCode')
      const currentImportOption = this.importDialog.importBaseParams.find(item => item.type === type);
      this.importDialog.currentImportType = currentImportOption.type;
      currentImportOption.visible = true;
      return false;
    },
    handleExport() {
      this.loading = true;
      exportTaskSku(this.form.mtTaskId).then(res => {
        this.loading = false;
        if (!res) {
          return
        }
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = window.URL.createObjectURL(new Blob([res]))
        link.setAttribute('download', '任务商品数据导出（' + this.form.taskName + '）.xlsx')
        document.body.appendChild(link)
        link.click()
        link.remove()
      })
    },
    handleRemoveRow(row, deleteCount, type, page) {
      if (type === 'skus') {
        if (this.form.importScope === '0') {
          this.form[type] = this.form[type].filter(e => e.skuModel !== row.skuModel);
        } else if (this.form.importScope === '1') {
          this.form[type] = this.form[type].filter(e => e.skuCode !== row.skuCode);
        }
      } else if (type === 'shops') {
        this.form[type] = this.form[type].filter(e => e.dcCode !== row.dcCode || e.shopCode !== row.shopCode);
      }
      this[page].total = this.form[type].length;
      this.handleQuery(type, page);
      this.setLength();
      if (this.form.importScope === '0') {
        this.form.exceptModels = this.form.skus.map(e => e && e.skuModel ? e.skuModel : '');
      } else if (this.form.importScope === '1') {
        this.form.exceptSkuCodes = this.form.skus.map(e => e && e.skuCode ? e.skuCode : '');
      }
      this.form.exceptCodes = this.form.shops.map(e => e.dcCode + "-" + e.shopCode);
    },
    handleRemoveAll() {
      if (this.tabName === 'sku') {
        this.form.skus = [];
        this.form.exceptModels = [];
        this.form.exceptSkuCodes = [];
        this.handleQuery('skus', 'skuPage');
      } else if (this.tabName === 'shop') {
        this.form.shops = [];
        this.form.exceptCodes = [];
        this.handleQuery('shops', 'shopPage');
      }
      this.form.runFlow = true;
      this.setLength();
    },
    handleDialogCloseEvent(type) {
      this.ifComponent = undefined;
      this.getMenuIndex(type);
    },
    handleRangeChange() {
      this.resetDefaultView()
      // this.form.startDate = undefined;
      // this.form.endDate = undefined;
      // this.taskDate = [];
      // 处理任务类型联动
      if (this.form.taskRange === '0' || this.form.taskRange === '4') {
        if (this.queryConfigRows.at(this.queryConfigRows.length-1).key !== '固定期') {
          this.queryConfigRows.push({ 'key': '固定期'});
        }
      } else {
        // 短期任务和阵地任务支持固定期，其他任务类型去除固定期选项
        if (this.queryConfigRows.at(this.queryConfigRows.length-1).key === '固定期') {
          this.queryConfigRows.splice(this.queryConfigRows.length-1, 1)
        }
        for(const key in this.orgFilterMap) {
          this.orgFilterMap[key].compareDate = [];
          this.orgFilterMap[key].customName = undefined;
          if (this.orgFilterMap[key].queryConfig) {
            let tmp = [];
            this.orgFilterMap[key].queryConfig.split(',').forEach(e => {
              if (this.defaultQueryOptions.indexOf(e) > -1) {
                tmp.push(e);
              }
            });
            this.orgFilterMap[key].queryConfig = tmp.join(',');
          }
        }
      }
    },
    handleZdTaskRelated() {
      this.dynamicTagHeader = '阵地';
      this.importDialog.importBaseParams[0].templateSampleHead = [
          {label: "商品型号", prop: "skuModel", minWidth: "80px"},
          {label: "货号", prop: "cargoNo", minWidth: "80px"},
          {label: "阵地", prop: "label", minWidth: "80px"},
          {label: "排序", prop: "sort", minWidth: "80px"},
          {label: "自定义商品名称", prop: "customModelName", minWidth: "80px"}
        ];
      this.importDialog.importBaseParams[0].templateSampleLists = [{
          skuModel: "1002E4G",
          cargoNo: "1002E4G",
          label: "测试阵地",
          sort: '0',
          customModelName: '自定义商品名称'
        }];
      this.importDialog.importBaseParams[0].templateFileDownloadUrl = process.env.VUE_APP_OSS_TEMPLATE_PATH + '/%e4%bb%bb%e5%8a%a1-%e5%95%86%e5%93%81%e6%95%b0%e6%8d%ae%e5%af%bc%e5%85%a5%e6%a8%a1%e6%9d%bf%ef%bc%88%e9%98%b5%e5%9c%b0%e4%bb%bb%e5%8a%a1%ef%bc%89.xls';
      this.importDialog.importBaseParams[1].templateSampleHead = [
        {label: "商品编码", prop: "skuCode", minWidth: "80px"},
        {label: "阵地", prop: "label", minWidth: "80px"},
        {label: "排序", prop: "sort", minWidth: "80px"},
        {label: "自定义商品名称", prop: "customModelName", minWidth: "80px"}
      ];
      this.importDialog.importBaseParams[1].templateSampleLists = [
        {
          skuCode: "609023",
          label: "测试阵地",
          sort: '0',
          customModelName: '自定义商品名称'
        },
      ];
      this.importDialog.importBaseParams[1].templateFileDownloadUrl = process.env.VUE_APP_OSS_TEMPLATE_PATH + '/%e4%bb%bb%e5%8a%a1-%e5%95%86%e5%93%81%e7%bc%96%e7%a0%81%e6%95%b0%e6%8d%ae%e5%af%bc%e5%85%a5%e6%a8%a1%e6%9d%bf%ef%bc%88%e9%98%b5%e5%9c%b0%e4%bb%bb%e5%8a%a1%ef%bc%89.xls';
    },
    resetDefaultView() {
      // 清除任务标签，任务类型关联标签逻辑后端处理
      this.form.taskTag = '';
      this.dynamicTagHeader = '标签';
      this.importDialog.importBaseParams[0].templateSampleHead = [
        {label: "商品型号", prop: "skuModel", minWidth: "80px"},
        {label: "货号", prop: "cargoNo", minWidth: "80px"},
        {label: "标签", prop: "label", minWidth: "80px"},
        {label: "排序", prop: "sort", minWidth: "80px"},
        {label: "自定义商品名称", prop: "customModelName", minWidth: "80px"}
      ];
      this.importDialog.importBaseParams[0].templateSampleLists = [{
        skuModel: "1002E4G",
        cargoNo: "1002E4G",
        label: "测试标签",
        sort: '0',
        customModelName: '自定义商品名称'
      }];
      this.importDialog.importBaseParams[0].templateFileDownloadUrl = process.env.VUE_APP_OSS_TEMPLATE_PATH + '/%E4%BB%BB%E5%8A%A1-%E5%95%86%E5%93%81%E6%95%B0%E6%8D%AE%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xls';
      this.importDialog.importBaseParams[1].templateSampleHead = [
        {label: "商品编码", prop: "skuCode", minWidth: "80px"},
        {label: "标签", prop: "label", minWidth: "80px"},
        {label: "排序", prop: "sort", minWidth: "80px"},
        {label: "自定义商品名称", prop: "customModelName", minWidth: "80px"}
      ];
      this.importDialog.importBaseParams[1].templateSampleLists = [
        {
          skuCode: "609023",
          label: "测试标签",
          sort: '0',
          customModelName: '自定义商品名称'
        },
      ];
      this.importDialog.importBaseParams[1].templateFileDownloadUrl = process.env.VUE_APP_OSS_TEMPLATE_PATH + '/%E4%BB%BB%E5%8A%A1-%E5%95%86%E5%93%81%E7%BC%96%E7%A0%81%E6%95%B0%E6%8D%AE%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xls';
    },
    handleQueryConfigSelectionChange(selections, targetOrg) {
      const orgCode = targetOrg? targetOrg: this.form.checkOrgCode;
      this.orgFilterMap[orgCode].selections = selections;
      let tmpArray = [];
      selections.forEach(e => {
        if (e.key === '固定期') {
          if (this.orgFilterMap[orgCode].compareDate && this.orgFilterMap[orgCode].compareDate.length >0) {
            if (this.orgFilterMap[orgCode].customName) {
              tmpArray.splice(0, 0, this.orgFilterMap[orgCode].customName);
            } else {
              tmpArray.splice(0, 0, this.getFixConfig());
            }
          } else {
            this.$refs.filterTable.toggleRowSelection(e, false);
            Notification.warning('固定期需要勾选具体的时间范围，否则无法生效！');
            return '';
          }
        } else {
          tmpArray.push(e.key);
        }
      });
      this.orgFilterMap[orgCode].queryConfig = tmpArray.join(',');
      if (!this.form.runFlow) {
        this.form.runFlow = true;
      }
    },
    getFixConfig() {
      return parseTime(this.orgFilterMap[this.form.checkOrgCode].compareDate[0], '{m}.{d}') + '-' + parseTime(this.orgFilterMap[this.form.checkOrgCode].compareDate[1], '{m}.{d}')
    },
    handleSelectable(row, index) {
      return (row.key!=='固定期' ||  (this.orgFilterMap[this.form.checkOrgCode].compareDate && this.orgFilterMap[this.form.checkOrgCode].compareDate.length > 0)) ? true: false;
    },
    handlePopoverShow(val) {
      const orgCode = val? val: this.form.checkOrgCode;
      this.$nextTick(() => {
        const selectRows = this.orgFilterMap[orgCode].selections.map(s => s.key)
        this.queryConfigRows.forEach(e => {
          if (selectRows.indexOf(e.key) > -1) {
            this.$refs.filterTable.toggleRowSelection(e, true);
          } else {
            this.$refs.filterTable.toggleRowSelection(e, false);
          }
        })
        // this.orgFilterMap[this.form.checkOrgCode].selections.forEach(e => {
        //   this.$refs.filterTable.toggleRowSelection(this.queryConfigRows[1], true);
        // })
      })
    },
    handleConfigDateChange(val, row) {
      if (!val || val.length === 0) {
        this.$nextTick(() => {
          this.$refs.filterTable.toggleRowSelection(row, false);
        })
      }
      if (this.orgFilterMap[this.form.checkOrgCode].queryConfig) {
        const config = this.orgFilterMap[this.form.checkOrgCode].queryConfig;
        let tmpArray = [];
        config.split(',').forEach(e => {
          if (this.defaultQueryOptions.indexOf(e) > -1) {
            tmpArray.push(e);
          } else if (val && val.length > 0) {
            if (this.orgFilterMap[this.form.checkOrgCode].customName) {
              tmpArray.splice(0, 0, e);
            } else {
              tmpArray.splice(0, 0, this.getFixConfig());
            }
          }
        })
        this.orgFilterMap[this.form.checkOrgCode].queryConfig = tmpArray.join(',');
      }
    },
    syncConfigToOther(type) {
      let currentOrgCode;
      switch (type) {
        case 'query':
          currentOrgCode = this.form.checkOrgCode;
          break;
        case 'compare':
          currentOrgCode = this.form.compareOrgCode;
          break;
        case 'appShow':
          currentOrgCode = this.form.showOrgCode;
          break;
        case 'ifBatchAddCart':
          currentOrgCode = this.form.batchOrgCode;
          break;
      }
      const currentOrgConfig = Object.assign({}, this.orgFilterMap[currentOrgCode]);
      for (const key in this.orgFilterMap) {
        if (key !== currentOrgCode) {
          if (type === 'query') {
            this.orgFilterMap[key].selections = deepClone(currentOrgConfig.selections);
            this.orgFilterMap[key].compareDate = currentOrgConfig.compareDate;
            this.orgFilterMap[key].customName = currentOrgConfig.customName;
            this.orgFilterMap[key].sort = currentOrgConfig.sort;
            this.orgFilterMap[key].queryConfig = currentOrgConfig.queryConfig;
            this.orgFilterMap[key].noValue = currentOrgConfig.noValue;
            this.orgFilterMap[key].appShow = currentOrgConfig.appShow;
            this.handleQueryConfigSelectionChange(this.orgFilterMap[key].selections, key);

          } else if (type === 'compare') {
            this.orgFilterMap[key].ksCompareDate = currentOrgConfig.ksCompareDate;
            this.orgFilterMap[key].ksCompareChoose = currentOrgConfig.ksCompareChoose;
          } else if (type === 'appShow') {
            this.orgFilterMap[key].appShow = currentOrgConfig.appShow;
          } else if (type === 'ifBatchAddCart') {
            this.orgFilterMap[key].ifBatchAddCart = currentOrgConfig.ifBatchAddCart;
          }
        }
      }
      Notification.success('同步成功！');
    },
    handleSkuScopeChange(val) {
      this.$nextTick(() => {
        this.$refs['skuTableRef'].doLayout();
      })
      if (this.form.skus.length > 0) {
        this.$confirm('切换商品添加方式,会清空已添加的全部商品', '提示', { type: 'warning' }).then(() => {
          this.handleRemoveAll();
          if (val === '1') {
            this.skuGroups.activeIndex = 'ALL';
          } else {
            this.form.kssType = 'model';
          }
        }).catch(() => {
          if (val === '0') {
            this.form.importScope = '1';
          } else {
            this.form.importScope = '0';
          }
          this.$nextTick(() => {
            this.$refs['skuTableRef'].doLayout();
          })
        })
      } else {
        this.handleRemoveAll();
        if (val === '1') {
          this.skuGroups.activeIndex = 'ALL';
        } else {
          this.form.kssType = 'model';
        }
      }
    },
    downloadImportResult() {
      let headers = {};
      let data = [];
      let title = ''
      if (this.tabName === 'shop') {
        headers = this.exportHeaders.shop;
        data = this.form.shops.filter(e => e.dataType === '1');
        title = '任务绑定店铺导入结果';
      } else if (this.tabName === 'sku') {
        data = this.form.tmpSkus.filter(e => e.dataType === '1');
        title = '任务绑定商品导入结果';
        if (this.form.importScope === '0') {
          headers = this.exportHeaders.skuModel;
        } else {
          headers = this.exportHeaders.skuCode;
        }
      }
      excelExport({
        topHeaders: headers,
        data: data,
        title: title,
      });
    },
    handleShopTypeChange(val) {
      this.$nextTick(() => {
        if (this.$refs['shopTableRef']) {
          this.$refs['shopTableRef'].doLayout();
        }
      })
      if (this.form.shops.length > 0) {
        this.$confirm('切换店铺添加方式,会清空已添加的全部店铺', '提示', { type: 'warning' }).then(() => {
          this.handleRemoveAll();
          if (val === '0') {
            this.handleOrgChange('code');
            setTimeout(() => {
              this.handleOrgDcChange(undefined, {checkedNodes: this.$refs.orgShopDcTreeRef.getCheckedNodes()}, 'shops');
            }, 400)
          }
        }).catch(() => {
          if (val === '0') {
            this.form.shopCheckType = '1';
          } else {
            this.form.shopCheckType = '0';
          }
          this.$nextTick(() => {
            this.$refs['shopTableRef'].doLayout();
          })
        })
      } else {
        this.handleRemoveAll();
        if (val === '0') {
          this.handleOrgChange('code')
        }
      }
    },
    handleFilterChange(val) {
      if (this.$refs.orgDcTreeRef) {
        this.$refs.orgDcTreeRef.filter(val);
        if (!val) {
          this.expandTree(false);
        }
      }
      if (this.$refs.orgShopDcTreeRef) {
        this.$refs.orgShopDcTreeRef.filter(val);
        if (!val) {
          this.expandTree(false);
        }
      }
    },
    expandTree(val) {
      var nodes = this.$refs.orgDcTreeRef.store.nodesMap;
      for (var i in nodes) {
        nodes[i].expanded = val;
      }

      var shopNodes = this.$refs.orgShopDcTreeRef.store.nodesMap;
      for (var i in shopNodes) {
        shopNodes[i].expanded = val;
      }
    },
    resetTree() {
      this.filterDcText = undefined;
      this.handleFilterChange(undefined);
    },
    filterOrgDcNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    handleOrgDcChange(node, data, key) {
      let checkedDcCodes = [];
      const defaultData = { provinceCode: 'ALL', cityCode: 'ALL', areaCode: 'ALL', shopCode: 'ALL', shopBiz: 'ALL'}
      let checkOrgCodes = [];
      data.checkedNodes.forEach(e => {
        const orgCode = e.fullCode.split('-')[0];
        if (e.fullCode.split('-').length > 1) {
          const dcCode =e.fullCode.split('-')[1];
          if (checkOrgCodes.indexOf(orgCode) < 0) {
            checkOrgCodes.push(orgCode);
          }
          checkedDcCodes.push({...{orgCode: orgCode, dcCode: dcCode }, ...deepClone(defaultData) });
        } else {
          checkedDcCodes.push({...{orgCode: orgCode, dcCode: 'ALL' }, ...deepClone(defaultData) });
        }
      })
      this.form[key] = [].concat(checkedDcCodes);
      this.setLength();
    },
    handleSetTreeChecked(form) {
      if (form.shops.length > 0){
        if (form.shops.filter(e => e.dcCode!=='ALL' && e.provinceCode==='ALL').length > 0) {
          form.shopCheckType = '0';
          let checkedKeys = [];
          form.shops.forEach(e => {
            checkedKeys.push(e.orgCode+ '-' + e.dcCode)
          })
          this.$nextTick(() => {
            if (this.$refs.orgShopDcTreeRef) {
              this.$refs.orgShopDcTreeRef.setCheckedKeys(checkedKeys, true);
            }
          })
        } else {
          form.shopCheckType = '1';
        }
      }
      if (form.dcs.length > 0) {
        let checkedKeys = [];
        form.dcs.forEach(e => {
          checkedKeys.push(e.orgCode+ '-' + e.dcCode)
        })
        this.$nextTick(() => {
          if (this.$refs.orgDcTreeRef) {
            this.$refs.orgDcTreeRef.setCheckedKeys(checkedKeys, true);
          }
        })
      }
    },
    handleOrgChange(action) {
      setTimeout(() => {
        if ((this.form.shopCheckType === '0' && action==='code') || action==='hand') {
          setTimeout(() => {
            let orgCodes = []
            if (this.form.checkedOrgCodes instanceof Array) {
              orgCodes = deepClone(this.form.checkedOrgCodes)
            } else {
              orgCodes = orgCodes.concat([this.form.checkedOrgCodes]);
            }
            const treeData = this.orgDcTree.filter(e => orgCodes.indexOf(e.value) > -1 || orgCodes.indexOf('ALL') > -1);
            let checkedKeys = [];
            treeData.forEach(e => {
              e.children.forEach(d => {
                checkedKeys.push(d.fullCode);
              })
            })
            this.$nextTick(() => {
              // if (this.$refs.orgDcTreeRef && action === 'hand') {
              //   this.$refs.orgDcTreeRef.setCheckedKeys(checkedKeys, true);
              //   setTimeout(() => {
              //     this.handleOrgDcChange(undefined, {checkedNodes: this.$refs.orgDcTreeRef.getCheckedNodes()}, 'dcs');
              //   }, 400)
              // }
              if (this.$refs.orgShopDcTreeRef) {
                this.$refs.orgShopDcTreeRef.setCheckedKeys(checkedKeys, true);
                setTimeout(() => {
                  this.handleOrgDcChange(undefined, {checkedNodes: this.$refs.orgShopDcTreeRef.getCheckedNodes()}, 'shops');
                }, 400)
              }
            })
          }, 200)
        }
      }, 150)
    },
    setLength() {
      let shopLength = this.form.shopCheckType ==='1'? this.form.shops.length : this.form.shops.filter(e => e.dcCode!='ALL').length
      this.$emit('set-size', this.form.skus.length, shopLength);
    },
    validateFormData() {
      let errorCompareOrgs = [];
      let orgCodes = (typeof this.form.checkedOrgCodes=== 'string') ? [this.form.checkedOrgCodes]: this.form.checkedOrgCodes;
      orgCodes.forEach(e => {
        if (this.orgFilterMap[e].ksCompareChoose === '1' && !(this.orgFilterMap[e].ksCompareDate && this.orgFilterMap[e].ksCompareDate.length > 0)) {
          const orgName = this.initOrgOptions.find(o => e === o.value).label;
          errorCompareOrgs.push(orgName);
        }
      })
      if (errorCompareOrgs.length > 0) {
        if (orgCodes.length > 1) {
          return '以下分公司新增款式对比周期未选择具体时间：' + errorCompareOrgs.join('、');
        } else {
          return "新增款式对比周期未选择具体时间"
        }
      }
      return;
    },
    handleSort() {
      const currentLabels = Array.from(new Set(this.form.skus.filter(e => e.label).map(e => e.label)));
      let labelSorts = this.form.skuSorts.map(e => e.label);
      this.sortDialog.sorts = deepClone(this.form.skuSorts);
      this.sortDialog.sorts = this.form.skuSorts.filter(e => currentLabels.indexOf(e.label) > -1);
      currentLabels.forEach(e => {
        if (labelSorts.indexOf(e) < 0) {
          this.sortDialog.sorts.push({label: e,sort: 999})
        }
      })
      this.sortDialog.removeSorts = [];
      if (this.sortDialog.sorts.length == 0) {
        Notification.warning("当前任务并未配置商品标签！")
        return;
      }
      this.sortDialog.visible = true;
    },
    handleMove(type, index) {
      const item = this.sortDialog.sorts[index];
      if (type === "up") {
        this.sortDialog.sorts.splice(index, 1)
        this.sortDialog.sorts.splice(index - 1, 0, item)
      } else if (type === "down") {
        this.sortDialog.sorts.splice(index, 1)
        this.sortDialog.sorts.splice(index + 1, 0, item)
      } else if (type === "remove") {
        this.sortDialog.sorts.splice(index, 1)
        this.sortDialog.removeSorts.push(item.label);
      }
    },
    handleConfirmSort() {
      if (this.sortDialog.removeSorts.length > 0) {
        this.form.skus.forEach(e => {
          if (e.label && this.sortDialog.removeSorts.indexOf(e.label) > -1) {
            e.label = undefined;
          }
        })
        this.handleQuery('skus', 'skuPage');
      }
      let i = 0;
      this.sortDialog.sorts.filter(e => {
        e.sort = i;
        i++;
      })
      this.form.skuSorts = this.sortDialog.sorts;
      this.sortDialog.visible = false;
    },
    setGrpShops() {
      this.loadingPage = true;
      const query = Object.assign({size: 10000 }, {groupId: this.form.shopGroupId, orgCode: this.form.orgCode}, { exceptShopCodes: this.form.exceptCodes.join(',') });
      pageShop(query).then(res => {
        if (!res.data) {
          return false;
        }
        this.loadingPage = false;
        if (res.data?.total > 10000) {
          Notification.warning("店铺组数据量超过1万家！")
          return ;
        }
        this.addItems(res.data?.records, 'shop');
        Notification.success("操作成功！")
      });
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  //.table-light-class .el-table__empty-block {
  //  font-size: xxx-large;
  //  justify-content: unset;
  //  .el-table__empty-text {
  //    line-height: 60px;
  //    width: 50%;
  //    color: red !important;
  //  }
  //}
  .el-dialog__body {
    padding-top: 10px;
    padding-bottom: 5px;
  }

  .el-drawer__header {
    margin-bottom: 0px;
  }

  .descWidth.el-input__inner {
    padding-left: 5px !important;
    max-width: unset !important;
  }
  .el-upload--picture-card {
    height:30px;
    width: 80px;
    .el-button{
      display: flex;
      margin: 3px auto;
    }
  }
  .el-upload-list__item {
    height:80px;
    width: 60px;
  }
  label.el-radio {
    font-weight: 400;
  }
}

</style>
<style lang="scss" scoped src='@/style/common/index.scss'></style>
