<template>
    <div class="new-box">
        <div class="mg-search">
            <el-card style="margin: 10px">
                <!-- @search="handleFilter" @reset="resetQuery" @keyup.enter.native="handleFilter" -->
                <SearchFilter @search="FormData.page = 1; handleFilter();" label-width="150px" @reset="resetQuery"
                    @keyup.enter.native="FormData.page = 1; handleFilter();">
                    <el-form-item label="月份">
                        <el-select v-model="FormData.mth" placeholder="请选择">
                            <el-option v-for="item in mth" :key="item.code" :label="item.name" :value="item.code">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="是否显示近3月增长率">
                        <el-select v-model="growthRateShow" placeholder="请选择">
                            <el-option label="否" :value="1"> 否 </el-option>
                            <el-option label="是" :value="0"> 是</el-option>
                        </el-select>
                    </el-form-item>
                </SearchFilter>
            </el-card>
        </div>
        <el-card style="margin: 10px" class="box-card">
            <el-button type="primary" size="small" @click="download" :loading="exportLoading">导出</el-button>
            <el-table :data="tableData" style="width: 100%; margin-top: 10px;" border size="small" :key="getRandom(20)"
                ref="table" v-loading="loading" :cell-style="columnStyle"  
                :header-cell-style="{ 'text-align': 'center', 'background': '#eef1f6', }">
                <el-table-column fixed prop="newRaceName" label="赛道"  align="center">
                    <template slot-scope="scope">
                        <div @click="jumpToTheMiddleClassDimension(scope.row.newRaceName)" style="cursor: pointer; color: #409EFF;">
                            {{ scope.row.newRaceName}}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column :label="`整体${startMonth}-${endMonth}月销售（万元）`" align="center">
                    <el-table-column prop="goal" label="目标" sortable  >
                        <template slot-scope="scope">
                            {{ scope.row.goal ? Math.round(scope.row.goal) : '-' }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="wcl" label="完成率" sortable >
                        <template slot-scope="scope">
                            <div :style="{ color: scope.row.wcl < 0 ? 'red' : '' }">
                                {{ scope.row.wcl ? Math.round(scope.row.wcl * 100) + '%' : '-' }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="bnJhTotal" label="本年" sortable>
                        <template slot-scope="scope">
                            {{ Math.round(scope.row.bnJhTotal) }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="zl" label="同比增量" sortable >
                        <template slot-scope="scope">
                            <div :style="{ color: scope.row.zl < 0 ? 'red' : '' }">
                                {{ Math.round(scope.row.zl) }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="zzl" label="增长率" sortable >
                        <template slot-scope="scope">
                            <div :style="{ color: formatPercentage(scope.row, 'zzl').color }">
                                {{ formatPercentage(scope.row, 'zzl').percentage }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="zzlSsy" label="上上月" sortable  v-if="growthRateShow == 0">
                        <template slot-scope="scope">
                            <div :style="{ color: formatPercentage(scope.row, 'zzlSsy').color }">
                                {{ formatPercentage(scope.row, 'zzlSsy').percentage }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="zzlSy" label="上月" sortable v-if="growthRateShow == 0">
                        <template slot-scope="scope">
                            <div :style="{ color: formatPercentage(scope.row, 'zzlSy').color }">
                                {{ formatPercentage(scope.row, 'zzlSy').percentage }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="zzlBy" label="本月" sortable v-if="growthRateShow == 0">
                        <template slot-scope="scope">
                            <div :style="{ color: formatPercentage(scope.row, 'zzlBy').color }">
                                {{ formatPercentage(scope.row, 'zzlBy').percentage }}
                            </div>
                        </template>
                    </el-table-column>

                    <el-table-column label="近3月趋势" width="120">
                        <template slot-scope="scope">
                            <TrendCharts :first="scope.row.zzlSsy" :second="scope.row.zzlSy" :third="scope.row.zzlBy"
                                :month="years" :easyKey="getRandom(20)"></TrendCharts>
                        </template>
                    </el-table-column>
                </el-table-column>
                <el-table-column :label="`线下${startMonth}-${endMonth}月销售（万元）`" align="center">
                    <el-table-column prop="bnOfflineJhTotal" label="本年" sortable >
                        <template slot-scope="scope">
                            {{ Math.round(scope.row.bnOfflineJhTotal) }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="offlineZb" label="销售占比" sortable >
                        <template slot-scope="scope">
                            <div :style="{ color: formatPercentage(scope.row, 'offlineZb').color }">
                                {{ formatPercentage(scope.row, 'offlineZb').percentage }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="offlineZl" label="同比增量" sortable >
                        <template slot-scope="scope">
                            <div :style="{ color: scope.row.offlineZl < 0 ? 'red' : '' }">
                                {{ Math.round(scope.row.offlineZl) }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="offlineZlZb" label="增量占比" sortable >
                        <template slot-scope="scope">
                            <div :style="{ color: formatPercentage(scope.row, 'offlineZlZb').color }">
                                {{ formatPercentage(scope.row, 'offlineZlZb').percentage }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="offlineZzl" label="增长率" sortable >
                        <template slot-scope="scope">
                            <div :style="{ color: formatPercentage(scope.row, 'offlineZzl').color }">
                                {{ formatPercentage(scope.row, 'offlineZzl').percentage }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="offlineZzlSsy" label="上上月" sortable  v-if="growthRateShow == 0">
                        <template slot-scope="scope">
                            <div :style="{ color: formatPercentage(scope.row, 'offlineZzlSsy').color }">
                                {{ formatPercentage(scope.row, 'offlineZzlSsy').percentage }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="offlineZzlSy" label="上月"  sortable v-if="growthRateShow == 0">
                        <template slot-scope="scope">
                            <div :style="{ color: formatPercentage(scope.row, 'offlineZzlSy').color }">
                                {{ formatPercentage(scope.row, 'offlineZzlSy').percentage }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="offlineZzlBy" label="本月"  sortable v-if="growthRateShow == 0">
                        <template slot-scope="scope">
                            <div :style="{ color: formatPercentage(scope.row, 'offlineZzlBy').color }">
                                {{ formatPercentage(scope.row, 'offlineZzlBy').percentage }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="近3月趋势" width="120">
                        <template slot-scope="scope">
                            <TrendCharts :first="scope.row.offlineZzlSsy" :month="years"
                                :second="scope.row.offlineZzlSy" :third="scope.row.offlineZzlBy"
                                :easyKey="getRandom(20)"></TrendCharts>
                        </template>
                    </el-table-column>
                </el-table-column>
                <el-table-column :label="`线上${startMonth}-${endMonth}月销售（万元）`" align="center">
                    <el-table-column prop="bnOnlineJhTotal" label="本年" sortable >
                        <template slot-scope="scope">
                            {{ Math.round(scope.row.bnOnlineJhTotal) }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="onlineZb" label="销售占比" sortable >
                        <template slot-scope="scope">
                            <div :style="{ color: formatPercentage(scope.row, 'onlineZb').color }">
                                {{ formatPercentage(scope.row, 'onlineZb').percentage }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="onlineZl" label="同比增量" sortable >
                        <template slot-scope="scope">
                            <div :style="{ color: scope.row.onlineZl < 0 ? 'red' : '' }">
                                {{ Math.round(scope.row.onlineZl) }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="onlineZlZb" label="增量占比" sortable >
                        <template slot-scope="scope">
                            <div :style="{ color: formatPercentage(scope.row, 'onlineZlZb').color }">
                                {{ formatPercentage(scope.row, 'onlineZlZb').percentage }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="onlineZzl" label="增长率" sortable >
                        <template slot-scope="scope">
                            <div :style="{ color: formatPercentage(scope.row, 'onlineZzl').color }">
                                {{ formatPercentage(scope.row, 'onlineZzl').percentage }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="onlineZzlSsy" label="上上月" sortable  v-if="growthRateShow == 0">
                        <template slot-scope="scope">
                            <div :style="{ color: formatPercentage(scope.row, 'onlineZzlSsy').color }">
                                {{ formatPercentage(scope.row, 'onlineZzlSsy').percentage }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="onlineZzlSy" label="上月" sortable  v-if="growthRateShow == 0">
                        <template slot-scope="scope">
                            <div :style="{ color: formatPercentage(scope.row, 'onlineZzlSy').color }">
                                {{ formatPercentage(scope.row, 'onlineZzlSy').percentage }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="onlineZzlBy" label="本月" sortable  v-if="growthRateShow == 0">
                        <template slot-scope="scope">
                            <div :style="{ color: formatPercentage(scope.row, 'onlineZzlBy').color }">
                                {{ formatPercentage(scope.row, 'onlineZzlBy').percentage }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="近3月趋势" width="120">
                        <template slot-scope="scope">
                            <TrendCharts :first="scope.row.onlineZzlSsy" :month="years" :second="scope.row.onlineZzlSy"
                                :third="scope.row.onlineZzlBy" :easyKey="getRandom(20)">
                            </TrendCharts>
                        </template>
                    </el-table-column>
                </el-table-column>
        
            </el-table>
            <Pagination :total="total" :page.sync="FormData.page" :limit.sync="FormData.limit"
                @pagination="handleFilter" />
        </el-card>
    </div>
</template>

<script>
import { dropDownOptions, exportTheTrackDimensionReport, trackDimension } from "@/api/sales";
import Pagination from "@/components/Pagination";
import SearchFilter from "@/components/SearchFilter/index";
import TrendCharts from "./trendCharts.vue";

export default {
    data() {
        return {
            growthRateShow: 1,
            tableData: [],
            mth: [],
            loading: false,
            exportLoading: false,
            total: 0,
            years: "",
            startMonth: "1",
            endMonth: "",
            FormData: {
                page: 1,
                limit: 10,
                mth: ""
            },
            tableKey: 0
        };
    },
    components: {
        SearchFilter,
        TrendCharts,
        Pagination,
    },

    created() {
        this.setCurrentMonthRange();
        this.dropDownOptionsList()
        this.getData()
    },
    mounted() { },
    methods: {
        getRandom(num) {
            return Math.floor((Math.random() + Math.floor(Math.random() * 9 + 1)) * Math.pow(10, num - 1));
        },
        columnStyle({ rowIndex }) {
            if (rowIndex === 0) {
                return 'background:#f5f7fa; text-align:center;'
            } else {
                return 'text-align:center;'
            }
        },
        formatPercentage(row, propName) {
            const value = row[propName];
            const percentage = value ? (value * 100).toFixed(1) + '%' : '0.0%';
            const color = value && value * 100 < 0 ? 'red' : '';
            return { percentage, color };
        },
        formatYearMonth(yearMonthStr) {
            // 提取月份  
            const month = yearMonthStr.substring(4, 6);
            const startMonth = '1';
            const endMonth = month.replace(/^0+/, '')
            return [
                `${startMonth}`,
                `${endMonth}`
            ];
        },
        getCurrentYearMonthString() {
            const date = new Date();
            const year = date.getFullYear();
            const month = date.getMonth() + 1;
            const toMonth = `${year}${month.toString().padStart(2, "0")}`;
            return toMonth
        },
        dropDownOptionsList() {
            const promises = [
                dropDownOptions({ tableType: "race", fieldType: "mth" }).then(res => res.data),
            ];
            Promise.all(promises).then(([mth]) => {
                this.mth = mth;
            }).catch(error => {
                console.error('加载数据出错:', error);
            });
        },

        setCurrentMonthRange() {
            this.FormData.mth = this.getCurrentYearMonthString();
            const [start, end] = this.formatYearMonth(this.FormData.mth);
            this.startMonth = start
            this.endMonth = end
            this.years = this.FormData.mth;
        },

        getData() {
            this.loading = true
            trackDimension(this.FormData).then((res) => {
                this.tableData = res.data.datas
                this.total = res.data.total
                this.loading = false
            })
        },
        download() {
            this.exportLoading = true;
            exportTheTrackDimensionReport(this.FormData).then((res) => {
                const link = document.createElement('a')
                link.style.display = 'none'
                link.href = window.URL.createObjectURL(new Blob([res]))
                link.setAttribute('download', '赛道维度报表.xlsx')
                document.body.appendChild(link)
                link.click()
                link.remove()
            }).finally(() => {
                this.exportLoading = false
            })
        },
        handleFilter() {
            const [start, end] = this.formatYearMonth(this.FormData.mth);
            this.startMonth = start
            this.endMonth = end
            this.years = this.FormData.mth;
            this.getData();
        },
        jumpToTheMiddleClassDimension(raceName) {
            if(raceName == '合计' || raceName == '其它') return
            this.$emit('callbackNameType', raceName, 'mediumClass', this.FormData.mth);
        },
        resetQuery() {
            this.FormData = {
                page: 1,
                limit: 10,
                mth: this.getCurrentYearMonthString()
            };
            this.growthRateShow = 1;
            this.getData();
        },


    },
};
</script>

<style lang="scss" scoped>
:deep(.el-table .caret-wrapper){
    width: 10px !important;
}
</style>
