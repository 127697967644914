<template>
  <div style="padding: 20px" v-loading="homeLoading">
    <div class="mg-search" style="padding-bottom: 15px">
      <el-form ref="queryFormRef" size="mini" label-width="auto" :inline="true" :model="queryForm" @keyup.enter.native="handleQuery">
        <el-form-item label="型号">
          <DictSelect
            ref="spuModel"
            type="spuModel"
            parentLinkageRef
            :value.sync="queryForm.spuModels"
            :dictFun="dictSpuModelFun"
            multiple
            remote
          />
        </el-form-item>
        <el-form-item>
          <el-button class="form-btn-item" size="mini" icon="el-icon-search" type="primary" @click="handleFilter">查询</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-plus" type="primary" @click="handleSpuSpecificationsAdd">新增</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-upload" type="primary" @click="handleSpuSpecificationsImportNotice">导入包装（套装）转化关系维护</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-download" type="primary" @click="handleSpuSpecificationsExportNotice">导出包装（套装）转化关系维护</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-delete-solid" type="primary" @click="handleBatchDeleteSpuSpecifications">批量删除</el-button>
        </el-form-item>
      </el-form>
    </div>

    <el-table
      ref="tableRef"
      :data="pageData.rows"
      height="calc(100vh - 250px)"
      v-loading="loading"
      size="mini"
      border
      highlight-current-row
      :header-cell-style="{ background: 'rgb(235 235 235)', color: 'black',textAlign: 'center', verticalAlign: 'middle' }"
      @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="90"></el-table-column>
      <el-table-column label="型号" prop="spuModel" min-width="100" />
      <el-table-column label="型号名称" prop="spuModelName" min-width="300" />
      <el-table-column label="子规格" prop="childSpecifications" min-width="50" />
      <el-table-column label="上级规格" prop="superiorsSpecifications" min-width="50" />
      <el-table-column label="比例" prop="ratio" min-width="50" />
      <el-table-column label="零售价" prop="price" min-width="50" />
      <el-table-column label="创建时间" prop="createTime" min-width="100" />
      <el-table-column label="创建人" prop="createNickName" min-width="100" />
      <el-table-column label="操作" min-width="120" align="center" class-name="small-padding fixed-width">
        <template #default="scope">
          <el-button
            size="mini"
            type="text"
            icon="el-icon-edit"
            @click="handleSpuSpecificationsEdit(scope.row)"
          >编辑</el-button>
          <el-button
            size="mini"
            type="text"
            icon="el-icon-delete"
            @click="handleDeleteSpuSpecifications(scope.row)"
          >删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Pagination :total="pageData.total" :page.sync="queryForm.page" :limit.sync="queryForm.limit" @pagination="handleQuery" />

    <el-dialog title="包装（套装）转化关系维护导入" :visible.sync="spuSpecificationsImportDialog.visible" append-to-body width="400px">
      <el-upload
        ref="spuSpecificationsUpload"
        class="upload-demo"
        drag
        :limit="1"
        action="#"
        :auto-upload="false"
        :file-list="spuSpecificationsImportDialog.fileList"
        :on-change="(file, fileList) => handleSpuSpecificationsFileChange(file, fileList, 'change')"
        :on-remove="(file, fileList) => handleSpuSpecificationsFileChange(file, fileList, 'remove')"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">
          <span>仅允许导入xls、xlsx格式文件。</span>
          <el-link type="primary" :underline="false" style="font-size:12px;vertical-align: baseline;" target="_blank" :href="spuSpecificationsImportDialog.template">下载模板</el-link>
        </div>
      </el-upload>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button type="primary" size="mini" :disabled="spuSpecificationsSubmitDisabled" @click="submitSpuSpecificationsImport">确定</el-button>
        <el-button type="primary" size="mini" @click="spuSpecificationsImportDialog.visible = false">取消</el-button>
      </div>
    </el-dialog>

    <el-dialog :title="spuSpecificationsInfoUpdateDialog.title" :visible.sync="spuSpecificationsInfoUpdateDialog.visible" width="600px" :close-on-click-modal="false">
      <el-form ref="spuInfoUpdate" size="mini" label-width="100px" :model="spuSpecificationsInfoUpdateDialog.form" :rules="spuSpecificationsInfoUpdateDialog.rules">
        <el-form-item label="型号" prop="spuModel">
          <DictSelect
            ref="skuModel"
            type="sku_model"
            :value.sync="spuSpecificationsInfoUpdateDialog.form.spuModel"
            :dictFun="dictSkuModelsFun"
            :init-props="{ label: 'skuModel', value: 'skuModel'}"
            remote
          />
        </el-form-item>
        <el-form-item label="子规格" prop="childSpecifications">
          <el-input v-model="spuSpecificationsInfoUpdateDialog.form.childSpecifications" placeholder="请输入上级规格"/>
        </el-form-item>
        <el-form-item label="上级规格" prop="superiorsSpecifications">
          <el-input v-model="spuSpecificationsInfoUpdateDialog.form.superiorsSpecifications" placeholder="请输入上级规格"/>
        </el-form-item>
        <el-form-item label="比例" prop="ratio">
          <el-input-number v-model="spuSpecificationsInfoUpdateDialog.form.ratio" clearable placeholder="请输入比例"/>
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button size="mini" @click="spuSpecificationsInfoUpdateDialog.visible = false">取消</el-button>
        <el-button size="mini" type="primary" @click="submitSpuSpecificationsInfoEdit">确定</el-button>
      </div>
    </el-dialog>
    <el-dialog :title="spuSpecificationsInfoAddDialog.title" :visible.sync="spuSpecificationsInfoAddDialog.visible" width="600px" :close-on-click-modal="false">
      <el-form ref="spuInfoUpdate" size="mini" label-width="100px" :model="spuSpecificationsInfoAddDialog.form" :rules="spuSpecificationsInfoAddDialog.rules">
        <el-form-item label="型号" prop="spuModel">
          <DictSelect
            ref="skuModel"
            type="sku_model"
            :value.sync="spuSpecificationsInfoAddDialog.form.spuModel"
            :dictFun="dictSkuModelsFun"
            :init-props="{ label: 'skuModel', value: 'skuModel'}"
            remote
          />
        </el-form-item>
        <el-form-item label="子规格" prop="childSpecifications">
          <el-input v-model="spuSpecificationsInfoAddDialog.form.childSpecifications" placeholder="请输入上级规格"/>
        </el-form-item>
        <el-form-item label="上级规格" prop="superiorsSpecifications">
          <el-input v-model="spuSpecificationsInfoAddDialog.form.superiorsSpecifications" placeholder="请输入上级规格"/>
        </el-form-item>
        <el-form-item label="比例" prop="ratio">
          <el-input-number v-model="spuSpecificationsInfoAddDialog.form.ratio" clearable placeholder="请输入比例"/>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button size="mini" @click="spuSpecificationsInfoAddDialog.visible = false">取消</el-button>
        <el-button size="mini" type="primary" @click="submitAddSpuSpecificationsForm">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import {
  getTechSpuSpecificationsOptions,
  techSpuSpecificationsPageList,
  deleteSpuSpecifications,
  getSkuModelInfoList,
  updateSpuSpecifications,
  exportSpuSpecificationsNotice,
  addSpuSpecifications,
  dimEcSpuSpecificationsImport, batchDeleteSpuSpecifications
} from "@/api/eCommerce";
import { deepClone } from "@/utils"
import { Notification } from "element-ui";
import DictSelect from "@/components/DictSelect";

export default {
  name: "TechSpuSpecifications",
  components: { Pagination, DictSelect},
  data() {
    return {
      homeLoading: false,
      tabName: 'tm',
      dictSpuModelFun: getTechSpuSpecificationsOptions,
      dictSkuModelsFun: getSkuModelInfoList,
      pageData: {
        total: 0,
        rows: [],
        selections: [],
      },
      pageNoticeData: {
        total: 0,
        rows: [],
        selections: [],
      },
      queryForm: {
        page: 1,
        limit: 10,
        spuModels: [],
      },
      loading: false,
      spuSpecificationsImportDialog: {
        visible: false,
        fileList: [],
        accept: ['.xls', '.xlsx'],
        title: '导入包装（套装）转化关系维护',
        template: process.env.VUE_APP_OSS_TEMPLATE_PATH +'/%E8%B4%A7%E5%93%81%E5%A5%97%E8%A3%85%E7%BB%84%E6%88%90%E5%85%B3%E7%B3%BB%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx'
      },
      spuSpecificationsSubmitDisabled: false,
      spuSpecificationsInfoUpdateDialog: {
        visible: false,
        title: "修改包装（套装）转化关系维护",
        form: {
          spuModel: undefined,
          superiorsSpecifications: undefined,
          childSpecifications: undefined,
          ratio: undefined,
        },
        rules: {
          spuModel: [{required: true, message: '型号不能为空！', trigger: 'change'}],
          superiorsSpecifications: [{required: true, message: '上级规格不能为空！', trigger: 'change'}],
          childSpecifications: [{required: true, message: '子规格不能为空！', trigger: 'change'}],
          ratio: [{required: true, message: '比例不能为空！', trigger: 'change'}],
        }
      },
      oldSpuModel: undefined,
      oldSuperiorsSpecifications: undefined,
      spuSpecificationsInfoAddDialog: {
        visible: false,
        title: "新增包装（套装）转化关系维护",
        form: {
          spuModel: undefined,
          superiorsSpecifications: undefined,
          childSpecifications: undefined,
          ratio: undefined,
        },
        rules: {
          spuModel: [{required: true, message: '型号不能为空！', trigger: 'change'}],
          superiorsSpecifications: [{required: true, message: '上级规格不能为空！', trigger: 'change'}],
          childSpecifications: [{required: true, message: '子规格不能为空！', trigger: 'change'}],
          ratio: [{required: true, message: '比例不能为空！', trigger: 'change'}],
        }
      },
    };
  },
  watch: {
  },
  created() {
    this.handleFilter();
  },
  mounted() {
  },
  methods: {
    handleQuery() {
      this.loading = true;
      const query = Object.assign({}, this.queryForm);
      techSpuSpecificationsPageList(query).then(res => {
        if (res.code !== 200) {
          return false;
        }
        this.loading = false;
        this.pageData.rows = res.data?.datas;
        this.pageData.total = res.data?.total;
        this.$nextTick(() => {
          this.$refs.tableRef.doLayout();
        })
      });
    },
    handleFilter() {
      this.queryForm.page = 1;
      this.handleQuery();
    },
    resetQuery() {
      const queryForm = {
        page: 1,
        limit: 10,
        spuModels: [],
      };
      this.queryForm = Object.assign({}, queryForm);
      this.$forceUpdate();
    },
    handleJumpQuery() {
      this.handleFilter();
    },
    handleSpuSpecificationsImportNotice() {
      this.spuSpecificationsImportDialog.fileList = [];
      if (this.$refs.upload) {
        this.$nextTick(() => {
          this.$refs.upload.clearFiles();
        })
      }
      this.spuSpecificationsImportDialog.visible = true;
      this.spuSpecificationsSubmitDisabled = false;
    },
    submitSpuSpecificationsImport() {
      if (this.spuSpecificationsImportDialog.fileList.length === 0) {
        Notification.warning('请先上传文件！');
        return false;
      }
      let fd = new FormData();
      this.spuSpecificationsImportDialog.fileList.forEach(item=>{
        //文件信息中raw才是真的文件
        fd.append("file",item.raw);
      })
      this.spuSpecificationsSubmitDisabled = true;
      dimEcSpuSpecificationsImport(fd).then((res) => {
        if (res.code === 200) {
          if (res.data) {
            Notification.error("导入失败，存在错误数据，正在导出，请稍候！");
            window.open(res.data, '_blank');
          } else {
            Notification.success("操作成功!");
            this.spuSpecificationsImportDialog.visible = false;
            this.handleFilter()
          }
        }
        this.spuSpecificationsSubmitDisabled = false;
        this.$refs.upload.clearFiles();
      })
    },
    handleSpuSpecificationsFileChange(file, fileList, type) {
      if (type === 'remove') {
        this.spuSpecificationsImportDialog.fileList = fileList;
        return false;
      }
      const suffix =
        file.name.indexOf(".") > 0
          ? file.name.substring(file.name.lastIndexOf("."))
          : undefined;
      if (this.spuSpecificationsImportDialog.accept.indexOf(suffix) < 0) {
        this.$message.error("文件格式不支持");
        this.spuSpecificationsImportDialog.fileList = [];
      } else {
        this.spuSpecificationsImportDialog.fileList = fileList;
      }
    },
    handleSpuSpecificationsExportNotice() {
      this.homeLoading = true;
      exportSpuSpecificationsNotice(this.queryForm).then(res => {
        if (res.data) {
          const link = document.createElement('a');
          link.href = res.data;
          link.download = "包装（套装）转化关系维护导出";
          link.target = "_blank"; // 可选，如果希望在新窗口中下载文件，请取消注释此行
          link.click()
        } else {
          Notification.info("无可下载数据！");
        }
        this.homeLoading = false;
      })
    },
    handleDeleteSpuSpecifications(data) {
      const argument = Object.assign({}, data);
      this.$confirm('请确认是否删除该数据！', '提示', { type: 'warning' }).then(() => {
        deleteSpuSpecifications(argument).then(res => {
          if (res.code !== 200) {
            return false;
          }
          this.handleQuery();
        });
      }).catch(() => {
      })
    },
    handleSpuSpecificationsEdit(data) {
      this.rsetSpuSpecificationsInfoUpdateDialogForm();
      this.spuSpecificationsInfoUpdateDialog.visible = true;
      this.spuSpecificationsInfoUpdateDialog.form = deepClone(data);
      this.oldSpuModel = data.spuModel;
      this.oldSuperiorsSpecifications = data.superiorsSpecifications;
    },
    rsetSpuSpecificationsInfoUpdateDialogForm(){
      this.spuSpecificationsInfoUpdateDialog.form = {
        spuModel: undefined,
        superiorsSpecifications: undefined,
        childSpecifications: undefined,
        ratio: undefined,
      };
      this.oldSpuModel = undefined;
      this.oldSuperiorsSpecifications = undefined;
      this.spuSpecificationsInfoUpdateDialog.visible = false;
    },
    submitSpuSpecificationsInfoEdit() {
      const argument = deepClone(this.spuSpecificationsInfoUpdateDialog.form);
      argument.oldSpuModel = this.oldSpuModel;
      argument.oldSuperiorsSpecifications = this.oldSuperiorsSpecifications;
      updateSpuSpecifications(argument).then(res => {
        if (res.code === 200) {
          this.$message.success("操作成功！");
          this.rsetSpuSpecificationsInfoUpdateDialogForm();
          this.spuSpecificationsInfoUpdateDialog.visible = false;
          this.handleQuery();
        }
      })
    },
    handleSpuSpecificationsAdd() {
      this.rsetSpuSpecificationsInfoAddDialogForm();
      this.spuSpecificationsInfoAddDialog.visible = true;
    },
    submitAddSpuSpecificationsForm() {
      const argument = deepClone(this.spuSpecificationsInfoAddDialog.form);
      addSpuSpecifications(argument).then(res => {
        if (res.code === 200) {
          if (res.data) {
            Notification.error(res.data);
          } else {
            this.$message.success("操作成功！");
            this.rsetSpuSpecificationsInfoAddDialogForm();
            this.spuSpecificationsInfoAddDialog.visible = false;
            this.handleQuery();
          }
        }
      })
    },
    rsetSpuSpecificationsInfoAddDialogForm(){
      this.spuSpecificationsInfoAddDialog.form = {
        spuModel: undefined,
        superiorsSpecifications: undefined,
        childSpecifications: undefined,
        ratio: undefined,
      };
      this.spuSpecificationsInfoAddDialog.visible = false;
    },
    handleSelectionChange(selections) {
      this.pageNoticeData.selections = selections;
    },
    handleBatchDeleteSpuSpecifications(row) {
      let spuSpecificationsParam = [];
      if (row && row.spuModel) {
        spuSpecificationsParam.push({ spuModel: row.spuModel, superiorsSpecifications: row.superiorsSpecifications, childSpecifications: row.childSpecifications});
      } else if (this.pageNoticeData.selections.length > 0) {
        spuSpecificationsParam = this.pageNoticeData.selections.map(e => {return { spuModel: e.spuModel, superiorsSpecifications: e.superiorsSpecifications, childSpecifications: e.childSpecifications}});
      } else {
        Notification.warning("请至少选择一条数据进行操作！");
        return;
      }
      batchDeleteSpuSpecifications({ spuSpecifications: spuSpecificationsParam }).then((res) => {
        if (res.code == 200){
          Notification.success("操作成功！");
          this.handleQuery();
        }
      })
    },
  },
};
</script>

<style lang="scss" scoped>
  ::v-deep .el-table__header .el-table-column--selection .cell .el-checkbox:after {
    content: "本页全选";
    color: black;
    font-weight: bold;
    font-size: 12px;
    margin-left: 5px;
  }
</style>
