<template>
  <div style="padding: 20px">
    <div class="mg-search" style="padding-bottom: 15px">
      <el-form ref="queryFormRef" size="mini" label-width="auto" :inline="true" :model="queryForm" @keyup.enter.native="handleQuery">
        <el-form-item v-if="options.orgOptions.length > 0" label="分公司">
          <DictSelect
            ref="org"
            type="org"
            :value.sync="queryForm.orgCode"
            fullLabel
            :init-options="options.orgOptions"
            init
            :clearable="false"
            disabled
          />
        </el-form-item>
        <el-form-item label="类型">
          <DictSelect
            ref="org"
            type="org"
            :value.sync="queryForm.tagId"
            :init-options="options.tags"
            init
            :init-props="{ value: 'id', label: 'tagName' }"
            :clearable="false"
          />
        </el-form-item>
        <el-form-item>
          <el-input v-model="queryForm.userName" size="mini" clearable placeholder="请输入关键词" />
        </el-form-item>
        <el-form-item>
          <el-button class="form-btn-item" size="mini" icon="el-icon-search" type="primary" @click="handleQuery">查询</el-button>
          <el-button size="mini" type="primary" style="border: 0px;" icon="el-icon-plus" @click="handleEditUserTag()">新增</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-upload" type="primary" @click="() => {
            importDialog.visible = true;
            submitDisabled = false;
          }">导入</el-button>
          <el-button size="mini" type="primary" style="border: 0px;" @click="handleEditTag()">类型配置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :data="pageData.rows"
      height="calc(100vh - 350px)"
      v-loading="loading"
      size="mini"
      border
      ref="table"
      highlight-current-row
      :header-cell-style="{ background: 'rgb(235 235 235)', color: 'black',textAlign: 'center', verticalAlign: 'middle' }"
    >
      <el-table-column label="用户账号" prop="userName" width="100" />
      <el-table-column label="用户姓名" prop="nickName" width="100"/>
      <el-table-column label="配送中心" prop="dcNames" width="280" show-overflow-tooltip />
      <el-table-column label="类型" prop="tagNames" width="280" show-overflow-tooltip />
      <el-table-column label="备注" prop="remark" width="280" show-overflow-tooltip />
      <el-table-column label="操作" width="120" align="center">
        <template #default="{ row }">
          <el-button size="mini" type="text" style="border: 0px;" @click="handleEditUserTag(row)">编辑</el-button>
          <el-button size="mini" type="text" style="border: 0px;" @click="handleRemoveUserTag(row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Pagination :total="pageData.total" :page.sync="queryForm.current" :limit.sync="queryForm.size" @pagination="handleQuery" />

    <el-dialog title="人员配置导入" :visible.sync="importDialog.visible" append-to-body width="400px">
      <el-upload
        ref="goodsUpload"
        class="upload-demo"
        drag
        :limit="1"
        action="#"
        :auto-upload="false"
        :file-list="importDialog.fileList"
        :on-change="(file, fileList) => handleFileChange(file, fileList, 'change')"
        :on-remove="(file, fileList) => handleFileChange(file, fileList, 'remove')"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">
          <span>仅允许导入xls、xlsx格式文件。</span>
          <el-link type="primary" :underline="false" style="font-size:12px;vertical-align: baseline;" target="_blank" :href="importDialog.template">下载模板</el-link>
        </div>
      </el-upload>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button type="primary" size="mini" :disabled="submitDisabled" @click="submitImport">确定</el-button>
        <el-button type="primary" size="mini" @click="importDialog.visible = false">取消</el-button>
      </div>
    </el-dialog>

    <el-dialog title="编辑类型" :visible.sync="tagDialog.visible" append-to-body width="600px" :close-on-click-modal="false">
      <el-row v-for="(item,index) in tagDialog.tags" :key="index">
        <el-col v-if="tagDialog.currentTag === item.id" :span="8">
          <el-input v-model="item.tagName" size="mini" clearable placeholder="请输入类型名称" />
        </el-col>
        <el-col v-else :span="8">{{ item.tagName }}</el-col>
        <el-col :span="8">
          <el-button v-if="tagDialog.currentTag !== item.id" size="mini" type="text" style="border: 0px;" @click="() => {
            tagDialog.currentTag = item.id;
          }">编辑</el-button>
          <el-button v-if="tagDialog.currentTag === item.id" size="mini" type="text" style="border: 0px;" @click="handleSaveTag(item)">保存</el-button>
          <el-button v-if="tagDialog.currentTag === item.id" size="mini" type="text" style="border: 0px;" @click="tagDialog.currentTag = -1">取消</el-button>
          <el-button size="mini" type="text" style="border: 0px;" @click="handleRemoveTag(item, index)">删除</el-button>
        </el-col>
      </el-row>
      <el-row>
        <el-button size="mini" type="text" style="border: 0px;" @click="handleAddTag()">新增</el-button>
      </el-row>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button type="primary" size="mini" @click="tagDialog.visible = false">关闭</el-button>
      </div>
    </el-dialog>

    <el-dialog title="编辑人员配置" :visible.sync="userTagDialog.visible" width="400px" append-to-body :close-on-click-modal="false">
      <el-form ref="userTagForm" :model="userTagDialog.form" :inline="true" :rules="userTagDialog.rules" label-width="120px">
        <el-form-item label="用户" prop="userName">
          <DictSelect
            v-if="!userTagDialog.form.id"
            ref="user"
            type="user"
            :value.sync="userTagDialog.form.userName"
            :dictFun="dictUserFun"
            fullLabel
            remote
            version="v3"
            placeholder="请输入账号或姓名"
            :init-props="{ label: 'nickName', value: 'userName'}"
            size="mini"
            @getOtherJsonParam="(callback) => callback({ pageSize: 50, pageNum: 1})"
          />
          <span v-else>{{ userTagDialog.form.userName }} - {{ userTagDialog.form.nickName }}</span>
        </el-form-item>
        <el-form-item label="管辖配送中心">
          <DictSelect
            ref="org"
            type="dc"
            :value.sync="userTagDialog.form.dcCodes"
            :init-options="options.dcOptions" fullLabel
            init size="mini" multiple
            :clearable="false"
          />
        </el-form-item>
        <el-form-item label="类型" prop="tagIds" size="mini">
          <el-select v-model="userTagDialog.form.tagIds" clearable filterable multiple>
            <el-option v-for="item in options.tags" :label="item.tagName" :value="item.id" :key="item.id" />
          </el-select>
        </el-form-item>
        <el-form-item label="备注" prop="remark" size="mini">
          <el-input v-model="userTagDialog.form.remark" clearable type="textarea" />
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button size="mini" @click="userTagDialog.visible = false">取 消</el-button>
        <el-button size="mini" type="primary" @click="submitUserForm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import { dictSelect, userSelect, pageUserTag, saveUserTag, removeUserTag, getTags, saveTag, removeTag, importUserTag } from "@/api/appTask";
import { Notification } from "element-ui";
import {parseTime, deepClone, excelExport} from "@/utils";
import DictSelect from "@/components/DictSelect";
export default {
  name: "UserTag",
  components: {  Pagination, DictSelect},
  props: {
    // 导入弹窗基础参数
    orgCode: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      dictFun: dictSelect,
      dictUserFun: userSelect,
      pageData: {
        total: 0,
        rows: [],
        selections: [],
      },
      queryForm: {
        current: 1,
        size: 10,
        userName: undefined,
        tagId: undefined,
        orgCode: undefined,
      },
      lrDay: undefined,
      loading: false,
      dictParam: {},
      formDictParam: {},
      userTagDialog: {
        visible: false,
        loading: false,
        type: undefined,
        form: {
          userName: undefined,
          nickName: undefined,
          tagIds: [],
          middles: [],
          remark: undefined
        },
        rules: {
          userName: [{ required: true, message: " ", trigger: "change" }],
          dcCodes: [{ required: true, message: " ", trigger: "change" }]
        },
        defaultTestUsers: [], //
        otherTestUsers: undefined,
        filterDcText: undefined
      },
      jumpVal: undefined,
      noticeDialog: {
        visible: false,
        msg: undefined,
      },
      currentRow: {},
      options: {
        tags: [],
        orgOptions: [],
        dcOptions: []
      },
      exportHeaders: {
        userName: '用户账号',
        dcCodeStrs: '管辖配送中心',
        tagNames: '类型',
        errorMsg: '校验结果',
      },
      submitDisabled: false,
      tagDialog: {
        visible: false,
        tags: [],
        currentTag: -1
      },
      importDialog: {
        visible: false,
        fileList: [],
        accept: ['.xls', '.xlsx'],
        title: '人员配置导入',
        template: process.env.VUE_APP_OSS_TEMPLATE_PATH +'/%E4%BA%BA%E5%91%98%E9%85%8D%E7%BD%AE%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx'
      },
    };
  },
  watch: {
    "queryForm.orgCode": {
      handler(nV, oV) {
        if (nV) {
          dictSelect('dc', { orgCode: nV}).then(res => {
            this.options.dcOptions = res.data;
          })
          this.handleGetTags();
          this.handleFilter();
        }
      },
      deep: true,
      immediate: true
    },
    orgCode: {
      handler(nV, oV) {
        if (nV) {
          this.queryForm.orgCode = nV;
        }
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    dictSelect('org').then(res => {
      this.options.orgOptions = res.data;
      if (res.data.length > 0) {
        // this.queryForm.orgCode = res.data[0].value;
      }
    })
  },
  methods: {
    handleGetTags() {
      this.options.tags = [];
      getTags(this.queryForm.orgCode).then(res => {
        if (res.code == 200) {
          this.options.tags = res.data;
        }
      })
    },
    handleQuery() {
      this.loading = true;
      const query = Object.assign({}, this.queryForm);
      pageUserTag(query).then(res => {
        if (!res.data) {
          return false;
        }
        this.loading = false;
        this.pageData.rows = res.data.records;
        this.pageData.total = res.data.total;
      });
    },
    handleFilter() {
      this.queryForm.page = 1;
      this.handleQuery();
    },
    handleFileChange(file, fileList, type) {
      if (type === 'remove') {
        this.importDialog.fileList = fileList;
        return false;
      }
      const suffix =
        file.name.indexOf(".") > 0
          ? file.name.substring(file.name.lastIndexOf("."))
          : undefined;
      if (this.importDialog.accept.indexOf(suffix) < 0) {
        this.$message.error("文件格式不支持");
        this.importDialog.fileList = [];
      } else {
        this.importDialog.fileList = fileList;
      }
    },
    submitImport() {
      if (this.importDialog.fileList.length === 0) {
        Notification.warning('请先上传文件！');
        return false;
      }
      let fd = new FormData();
      this.importDialog.fileList.forEach(item=>{
        //文件信息中raw才是真的文件
        fd.append("file",item.raw);
      })
      this.submitDisabled = true;
      importUserTag(this.queryForm.orgCode, fd).then((res) => {
        let errList = res.data.filter(e => e.errorMsg)
        if (errList.length > 0) {
          Notification.error("导入失败，存在错误数据，正在导出，请稍候！");
          this.downloadImportResult(res.data)
        } else {
          Notification.success("操作成功,共" + res.data.length+"条!");
          this.importDialog.visible = false;
          this.handleFilter();
        }
        this.submitDisabled = false;
        this.loading = false;
        this.$refs.goodsUpload.clearFiles();
      })
    },
    downloadImportResult(data) {
      excelExport({
        topHeaders: this.exportHeaders,
        data: data,
        title: '分组店铺导入校验结果',
      });
    },
    handleRemoveTag(item, index) {
      if (item.id) {
        this.$confirm('请确认是否删除该类型！', '提示', { type: 'warning' }).then(() => {
          removeTag(item.id).then(res => {
            if (res.code == 200) {
              Notification.success('操作成功!');
              this.tagDialog.tags.splice(index, 1);
              this.handleGetTags();
            }
          })
        }).catch(() => {})
      } else {
        this.tagDialog.tags.splice(index, 1);
        Notification.success('操作成功!');
      }
    },
    handleAddTag() {
      this.tagDialog.tags.push({ id: undefined, tagName: undefined, orgCode: this.queryForm.orgCode })
    },
    handleSaveTag(item) {
      if (!item.tagName) {
        Notification.warning('请填写名称!');
        return;
      }
      saveTag(item).then(res => {
        if (res.code == 200) {
          item.id = res.data;
          Notification.success('操作成功!');
          this.handleGetTags();
        }
      })
    },
    handleEditUserTag(row) {
      const form = {
        id: row? row.id: undefined,
        userName: row ? row.userName: undefined,
        nickName: row ? row.nickName: undefined,
        dcCodes: row && row.dcCodeStrs ? row.dcCodeStrs.split(',').map(e => e): [],
        tagIds: row && row.tagIdStrs ? row.tagIdStrs.split(',').map(e => parseInt(e)): [],
        middles: [],
        remark: row ? row.remark: undefined
      };
      this.userTagDialog.form = deepClone(form);
      this.$forceUpdate();
      this.userTagDialog.visible = true;
    },
    handleRemoveUserTag(row) {
      this.$confirm('请确认是否删除该数据！', '提示', { type: 'warning' }).then(() => {
        removeUserTag(row.id).then(res=> {
          if (res.code == 200) {
            Notification.success('操作成功!');
            this.handleFilter();
          }
        })
      }).catch(() => {
      })
    },
    submitUserForm() {
      this.$refs.userTagForm.validate(valid => {
        if (valid) {
          let form = deepClone(this.userTagDialog.form);
          form.orgCode = this.queryForm.orgCode;
          form.middles = form.tagIds.map(e => {return { tagId: e }});
          saveUserTag(form).then(res=> {
            if (res.code == 200) {
              Notification.success('操作成功!');
              this.userTagDialog.visible = false;
              this.handleFilter();
            }
          })
        }
      })
    },
    handleEditTag() {
      this.tagDialog.visible = true;
      this.tagDialog.tags = deepClone(this.options.tags)
      this.tagDialog.currentTag = undefined;
    }
  }
}
</script>

<style scoped>

</style>
