<template>
  <div style="padding: 15px">
    <div>
      <el-form ref="addForm" inline label-width="auto" size="mini" :model="queryForm">
        <el-form-item label="用户账户" prop="userName">
          <el-input v-model="queryForm.userName" placeholder="请输入用户邮箱前缀" />
        </el-form-item>
        <el-form-item label="平台账号" prop="dept">
          <el-select clearable v-model="queryForm.accountId">
            <el-option v-for="item in accountOptions" :key="item.accountId" :value="item.accountId" :label="item.accountName" />
          </el-select>
        </el-form-item>
        <el-form-item label="平台">
          <el-select clearable v-model="queryForm.platform" placeholder="请选择平台" style="margin-right: 10px">
            <el-option v-for="item in platformOptions" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button class="filter-item" size="mini" type="primary" icon="el-icon-search" style="margin: auto 10px;" @click="handleQuery">查询</el-button>
          <el-button class="filter-item" size="mini" type="primary" icon="el-icon-plus" style="margin: auto 10px; " @click="addDialog.visible = true">新增</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table ref="tt" :data="pageData.rows" v-loading="loading" size="mini" border highlight-current-row>
      <el-table-column label="用户名称" prop="userName" width="200" />
      <el-table-column label="平台" prop="platform" width="200" />
      <el-table-column label="店铺名称" prop="shopName" width="200" />
      <el-table-column label="店铺类型" prop="storeType" width="90" />
      <el-table-column label="账号名称" prop="accountName" width="200" />
      <el-table-column label="账户状态" prop="accountState" width="90">
        <template slot-scope="{ row }">
          <el-tag :type="row.accountState | accountStateFilter">
            {{ accountStateMap[row.accountState] }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="90" text-align="center">
        <template #default="{ row }">
          <el-popconfirm
            confirm-button-text='确定'
            cancel-button-text='取消'
            icon="el-icon-info"
            icon-color="red"
            title="请确认是否删除该条数据"
            @confirm="handleDelete(row)"
          >
            <el-button slot="reference" size="mini" type="danger">删除</el-button>
          </el-popconfirm>

        </template>
      </el-table-column>
    </el-table>
    <Pagination :total="pageData.total" :currentPage="queryForm.current" :pageSize="queryForm.size" @pageChange="handleFilter" />


    <el-dialog title="新增" :visible.sync="addDialog.visible" width="400px">
      <el-form ref="addForm" :rules="addDialog.rules" label-width="auto" size="mini" :model="addDialog.form">
        <el-form-item label="用户账户" prop="userName">
          <el-input v-model="addDialog.form.userName" placeholder="请输入用户邮箱前缀" />
        </el-form-item>
        <el-form-item label="平台账号">
          <el-select v-model="addDialog.form.accountId" placeholder="不选默认全部账号权限" multiple clearable filterable>
            <el-option v-for="item in accountOptions" :key="item.accountId" :value="item.accountId" :label="item.platform + '/'+ item.storeType + '/' + item.shopName + '(' + item.accountName + ')'" />
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button @click="addDialog.visible = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { urlParams } from "@/mixins";
import { addUserAuth, delUserAuth, pageUserAuth, getPlatformData, listAccount } from "@/api/spider";
import Pagination from "../../../components/Element/Pagination.vue";
import {deepClone} from "@/utils";
import { Notification } from "element-ui";

export default {
  components: { Pagination },
  name: "Auth",
  mixins: [urlParams],
  filters: {
    accountStateFilter(state) {
      const stateMap = {
        1: "success",
        0: "danger",
      };
      return stateMap[state];
    },
  },
  data() {
    return {
      loading: false,
      dept: this.$route.query.dept? this.$route.query.dept: 'tech',
      queryForm: {
        current: 1,
        size: 10,
        dept: this.$route.query.dept? this.$route.query.dept: 'tech',
        platform: undefined,
        userName: undefined,
        accountId: undefined
      },
      pageData: {
        total: 0,
        rows: [],
      },
      accountStateMap: { 1: "正常", 0: "停用" },
      addDialog: {
        visible: false,
        form: {
          accountId: undefined,
          userName: undefined,
          resourceType: 'ACCOUNT',
          dept: this.$route.query.dept? this.$route.query.dept: 'tech'
        },
        rules: {
          userName: [{ required: true, message: " ", trigger: "change" }],
          accountId: [{ required: true, message: " ", trigger: "change" }],
        }
      },
      accountOptions: [],
      platformOptions: [],
    };
  },
  created() {
    getPlatformData(this.dept).then(res => {
      this.platformOptions = res.data;
    })
    listAccount({ size: 100, dept: this.dept }).then(res => {
      if (res.code === 200) {
        this.accountOptions = res.data.records;
      }
    })
  },
  mounted() {
    this.handleQuery();
  },
  methods: {
    handleQuery() {
      this.queryForm.current = 1;
      this.handleFilter();
    },
    handleFilter() {
      this.loading = true;
      pageUserAuth(this.queryForm).then(res => {
        this.loading = false;
        this.pageData.rows = res.data?.records;
        this.pageData.total = res.data?.total;
      });
    },
    resetForm() {
      const form = {
        dept: this.$route.query.dept? this.$route.query.dept: 'tech',
        resourceType: 'ACCOUNT',
        accountId: undefined,
        userName: undefined,
      }
      this.addDialog.form = form;
    },
    handleDeptChange(val) {
      if (val) {
        const obj = this.$refs.dept.itemOptions.find(e => e.value === 'val');
        if (obj) {
          this.addDialog.form.deptName = obj.label;
        } else {
          this.addDialog.form.deptName = undefined;
        }
      }
    },
    submit() {
      this.$refs.addForm.validate(valid => {
        if (valid) {
          const obj =  { dept: this.dept, userName: this.addDialog.form.userName, resourceType: 'ACCOUNT' }
          let param = [];
          if (this.addDialog.form.accountId && this.addDialog.form.accountId.length > 0) {
            param = this.addDialog.form.accountId.map(e =>  Object.assign({ accountId: e }, obj))
          } else {
            param = [{ dept: this.dept, userName: this.addDialog.form.userName, resourceType: 'ACCOUNT', accountId: 'ALL' }];
          }
          addUserAuth({ list: param }).then(res => {
            if (res.code === 200) {
              Notification.success('操作成功！');
              this.addDialog.visible = false;
              this.handleQuery();
              this.resetForm();
            }
          })
        }
      })
    },
    handleDelete(row) {
      const param = Object.assign({ resourceType: 'ACCOUNT' }, row);
      delUserAuth(param).then(res => {
        if (res.code === 200) {
          Notification.success('操作成功！');
          this.resetForm();
          this.handleQuery();
        }
      })
    }
  },
};
</script>

<style lang="scss" scoped src="@/style/spider/index.scss"></style>
