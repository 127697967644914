var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { padding: "15px" } },
    [
      _c(
        "div",
        [
          _c(
            "el-form",
            {
              ref: "addForm",
              attrs: {
                inline: "",
                "label-width": "auto",
                size: "mini",
                model: _vm.queryForm,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "用户账户", prop: "userName" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入用户邮箱前缀" },
                    model: {
                      value: _vm.queryForm.userName,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "userName", $$v)
                      },
                      expression: "queryForm.userName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "平台账号", prop: "dept" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "" },
                      model: {
                        value: _vm.queryForm.accountId,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "accountId", $$v)
                        },
                        expression: "queryForm.accountId",
                      },
                    },
                    _vm._l(_vm.accountOptions, function (item) {
                      return _c("el-option", {
                        key: item.accountId,
                        attrs: {
                          value: item.accountId,
                          label: item.accountName,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "平台" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { "margin-right": "10px" },
                      attrs: { clearable: "", placeholder: "请选择平台" },
                      model: {
                        value: _vm.queryForm.platform,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "platform", $$v)
                        },
                        expression: "queryForm.platform",
                      },
                    },
                    _vm._l(_vm.platformOptions, function (item) {
                      return _c("el-option", {
                        key: item,
                        attrs: { label: item, value: item },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "filter-item",
                      staticStyle: { margin: "auto 10px" },
                      attrs: {
                        size: "mini",
                        type: "primary",
                        icon: "el-icon-search",
                      },
                      on: { click: _vm.handleQuery },
                    },
                    [_vm._v("查询")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "filter-item",
                      staticStyle: { margin: "auto 10px" },
                      attrs: {
                        size: "mini",
                        type: "primary",
                        icon: "el-icon-plus",
                      },
                      on: {
                        click: function ($event) {
                          _vm.addDialog.visible = true
                        },
                      },
                    },
                    [_vm._v("新增")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "tt",
          attrs: {
            data: _vm.pageData.rows,
            size: "mini",
            border: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "用户名称", prop: "userName", width: "200" },
          }),
          _c("el-table-column", {
            attrs: { label: "平台", prop: "platform", width: "200" },
          }),
          _c("el-table-column", {
            attrs: { label: "店铺名称", prop: "shopName", width: "200" },
          }),
          _c("el-table-column", {
            attrs: { label: "店铺类型", prop: "storeType", width: "90" },
          }),
          _c("el-table-column", {
            attrs: { label: "账号名称", prop: "accountName", width: "200" },
          }),
          _c("el-table-column", {
            attrs: { label: "账户状态", prop: "accountState", width: "90" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c(
                      "el-tag",
                      {
                        attrs: {
                          type: _vm._f("accountStateFilter")(row.accountState),
                        },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.accountStateMap[row.accountState]) +
                            " "
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "操作", width: "90", "text-align": "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c(
                      "el-popconfirm",
                      {
                        attrs: {
                          "confirm-button-text": "确定",
                          "cancel-button-text": "取消",
                          icon: "el-icon-info",
                          "icon-color": "red",
                          title: "请确认是否删除该条数据",
                        },
                        on: {
                          confirm: function ($event) {
                            return _vm.handleDelete(row)
                          },
                        },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              slot: "reference",
                              size: "mini",
                              type: "danger",
                            },
                            slot: "reference",
                          },
                          [_vm._v("删除")]
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("Pagination", {
        attrs: {
          total: _vm.pageData.total,
          currentPage: _vm.queryForm.current,
          pageSize: _vm.queryForm.size,
        },
        on: { pageChange: _vm.handleFilter },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "新增",
            visible: _vm.addDialog.visible,
            width: "400px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.addDialog, "visible", $event)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "addForm",
              attrs: {
                rules: _vm.addDialog.rules,
                "label-width": "auto",
                size: "mini",
                model: _vm.addDialog.form,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "用户账户", prop: "userName" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入用户邮箱前缀" },
                    model: {
                      value: _vm.addDialog.form.userName,
                      callback: function ($$v) {
                        _vm.$set(_vm.addDialog.form, "userName", $$v)
                      },
                      expression: "addDialog.form.userName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "平台账号" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: "不选默认全部账号权限",
                        multiple: "",
                        clearable: "",
                        filterable: "",
                      },
                      model: {
                        value: _vm.addDialog.form.accountId,
                        callback: function ($$v) {
                          _vm.$set(_vm.addDialog.form, "accountId", $$v)
                        },
                        expression: "addDialog.form.accountId",
                      },
                    },
                    _vm._l(_vm.accountOptions, function (item) {
                      return _c("el-option", {
                        key: item.accountId,
                        attrs: {
                          value: item.accountId,
                          label:
                            item.platform +
                            "/" +
                            item.storeType +
                            "/" +
                            item.shopName +
                            "(" +
                            item.accountName +
                            ")",
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.addDialog.visible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submit } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }