var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { padding: "20px" } },
    [
      _c(
        "div",
        { staticClass: "mg-search", staticStyle: { "padding-bottom": "15px" } },
        [
          _c(
            "el-form",
            {
              ref: "queryFormRef",
              attrs: {
                size: "mini",
                "label-width": "auto",
                inline: true,
                model: _vm.queryForm,
              },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.handleFilter.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "分公司" } },
                [
                  _c("DictSelect", {
                    ref: "org",
                    attrs: {
                      type: "ht-org",
                      value: _vm.queryForm.orgCode,
                      linkageRef: "dc",
                      init: "",
                      initOptions: _vm.orgDictInitOptions,
                      fullLabel: "",
                      clearable: false,
                      placeholder: "请选择分公司",
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(_vm.queryForm, "orgCode", $event)
                      },
                      changeLinkageRef: _vm.handleQueryChangeLinkageRef,
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "配送中心" } },
                [
                  _c("DictSelect", {
                    ref: "dc",
                    attrs: {
                      type: "dc",
                      value: _vm.queryForm.dcCodes,
                      dictFun: _vm.dictFun,
                      parentLinkageRef: "",
                      fullLabel: "",
                      multiple: "",
                      "collapse-tags": "",
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(_vm.queryForm, "dcCodes", $event)
                      },
                      getOtherJsonParam: (callback) => callback(_vm.queryForm),
                      changeLinkageRef: _vm.handleChangeLinkageRef,
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "form-btn-item",
                      attrs: {
                        size: "mini",
                        icon: "el-icon-search",
                        type: "primary",
                      },
                      on: { click: _vm.handleFilter },
                    },
                    [_vm._v("查询")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "form-btn-item",
                      attrs: {
                        size: "mini",
                        icon: "el-icon-plus",
                        type: "primary",
                      },
                      on: { click: _vm.handleDcSystemConfigAdd },
                    },
                    [_vm._v("新增")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: {
            data: _vm.pageData.rows,
            height: "calc(100vh - 150px)",
            size: "mini",
            border: "",
            "highlight-current-row": "",
            "header-cell-style": {
              background: "rgb(227 224 224)",
              color: "black",
            },
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "分公司编码", prop: "orgCode", width: "100" },
          }),
          _c("el-table-column", {
            attrs: { label: "分公司名称", prop: "orgName", width: "200" },
          }),
          _c("el-table-column", {
            attrs: { label: "配送中心编码", prop: "dcCode", width: "100" },
          }),
          _c("el-table-column", {
            attrs: { label: "配送中心名称", prop: "dcName", width: "200" },
          }),
          _c("el-table-column", {
            attrs: { label: "系统名称", prop: "systemName", width: "120" },
          }),
          _c("el-table-column", {
            attrs: { label: "接入方式", prop: "joinMode", width: "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.joinMode === 0
                      ? _c("span", [_vm._v(_vm._s("数据库"))])
                      : _c("span", [_vm._v(_vm._s("接口"))]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "数据库类型", prop: "databaseType", width: "120" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.databaseType === 0
                      ? _c("span", [_vm._v(_vm._s("mysql"))])
                      : _vm._e(),
                    scope.row.databaseType === 1
                      ? _c("span", [_vm._v(_vm._s("oracle"))])
                      : _vm._e(),
                    scope.row.databaseType === 2
                      ? _c("span", [_vm._v(_vm._s("sql server"))])
                      : _c("span", [_vm._v(_vm._s(""))]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "链接地址", prop: "databaseUrl", width: "120" },
          }),
          _c("el-table-column", {
            attrs: { label: "用户名", prop: "databaseUsername", width: "120" },
          }),
          _c("el-table-column", {
            attrs: { label: "密码", prop: "databasePassword", width: "120" },
          }),
          _c("el-table-column", {
            attrs: { label: "库名", prop: "tableSchema", width: "120" },
          }),
          _c("el-table-column", {
            attrs: { label: "店铺维表", prop: "shopTable", width: "120" },
          }),
          _c("el-table-column", {
            attrs: { label: "接口地址", prop: "apiUrl", width: "200" },
          }),
          _c("el-table-column", {
            attrs: {
              label: "关联主键",
              prop: "correlationPrimaryKey",
              width: "120",
            },
          }),
          _c("el-table-column", {
            attrs: { label: "操作", width: "120", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          size: "mini",
                          type: "text",
                          icon: "el-icon-edit",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.handleEditDcSystemConfigForm(scope.row)
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          size: "mini",
                          type: "text",
                          icon: "el-icon-delete",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.handleDeleteDcSystemConfig(scope.row)
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("Pagination", {
        attrs: {
          total: _vm.pageData.total,
          page: _vm.queryForm.current,
          limit: _vm.queryForm.size,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.queryForm, "current", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.queryForm, "size", $event)
          },
          pagination: _vm.handleQuery,
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.dcSystemConfigAddDialog.title,
            visible: _vm.dcSystemConfigAddDialog.visible,
            width: "600px",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.dcSystemConfigAddDialog, "visible", $event)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "spuInfoUpdate",
              attrs: {
                size: "mini",
                "label-width": "100px",
                model: _vm.dcSystemConfigAddDialog.form,
                rules: _vm.dcSystemConfigAddDialog.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "分公司", prop: "orgCode" } },
                [
                  _c("DictSelect", {
                    ref: "addOrg",
                    attrs: {
                      type: "ht-org",
                      value: _vm.dcSystemConfigAddDialog.form.orgCode,
                      linkageRef: "addDc",
                      init: "",
                      initOptions: _vm.orgDictInitOptions,
                      fullLabel: "",
                      clearable: false,
                      placeholder: "请选择分公司",
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(
                          _vm.dcSystemConfigAddDialog.form,
                          "orgCode",
                          $event
                        )
                      },
                      changeLinkageRef: _vm.handleQueryChangeLinkageRef,
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "配送中心", prop: "dcCode" } },
                [
                  _c("DictSelect", {
                    ref: "addDc",
                    attrs: {
                      type: "ht-dc",
                      value: _vm.dcSystemConfigAddDialog.form.dcCode,
                      dictFun: _vm.dictFun,
                      parentLinkageRef: "",
                      fullLabel: "",
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(
                          _vm.dcSystemConfigAddDialog.form,
                          "dcCode",
                          $event
                        )
                      },
                      getOtherJsonParam: (callback) =>
                        callback(_vm.dcSystemConfigAddDialog.form),
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "系统名称", prop: "systemName" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入系统名称" },
                    model: {
                      value: _vm.dcSystemConfigAddDialog.form.systemName,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.dcSystemConfigAddDialog.form,
                          "systemName",
                          $$v
                        )
                      },
                      expression: "dcSystemConfigAddDialog.form.systemName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "接入方式", prop: "joinMode" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { size: "mini", clearable: "" },
                      model: {
                        value: _vm.dcSystemConfigAddDialog.form.joinMode,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.dcSystemConfigAddDialog.form,
                            "joinMode",
                            $$v
                          )
                        },
                        expression: "dcSystemConfigAddDialog.form.joinMode",
                      },
                    },
                    [
                      _c("el-option", { attrs: { value: 0, label: "数据库" } }),
                      _c("el-option", { attrs: { value: 1, label: "接口" } }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.dcSystemConfigAddDialog.form.joinMode === 0
                ? _c(
                    "el-form-item",
                    { attrs: { label: "数据库类型", prop: "databaseType" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { size: "mini", clearable: "" },
                          model: {
                            value:
                              _vm.dcSystemConfigAddDialog.form.databaseType,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.dcSystemConfigAddDialog.form,
                                "databaseType",
                                $$v
                              )
                            },
                            expression:
                              "dcSystemConfigAddDialog.form.databaseType",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { value: 0, label: "mysql" },
                          }),
                          _c("el-option", {
                            attrs: { value: 1, label: "oracle" },
                          }),
                          _c("el-option", {
                            attrs: { value: 2, label: "sql server" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.dcSystemConfigAddDialog.form.joinMode === 0
                ? _c(
                    "el-form-item",
                    { attrs: { label: "链接地址", prop: "databaseUrl" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入链接地址" },
                        model: {
                          value: _vm.dcSystemConfigAddDialog.form.databaseUrl,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.dcSystemConfigAddDialog.form,
                              "databaseUrl",
                              $$v
                            )
                          },
                          expression:
                            "dcSystemConfigAddDialog.form.databaseUrl",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.dcSystemConfigAddDialog.form.joinMode === 0
                ? _c(
                    "el-form-item",
                    { attrs: { label: "用户名", prop: "databaseUsername" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入用户名" },
                        model: {
                          value:
                            _vm.dcSystemConfigAddDialog.form.databaseUsername,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.dcSystemConfigAddDialog.form,
                              "databaseUsername",
                              $$v
                            )
                          },
                          expression:
                            "dcSystemConfigAddDialog.form.databaseUsername",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.dcSystemConfigAddDialog.form.joinMode === 0
                ? _c(
                    "el-form-item",
                    { attrs: { label: "密码", prop: "databasePassword" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入密码" },
                        model: {
                          value:
                            _vm.dcSystemConfigAddDialog.form.databasePassword,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.dcSystemConfigAddDialog.form,
                              "databasePassword",
                              $$v
                            )
                          },
                          expression:
                            "dcSystemConfigAddDialog.form.databasePassword",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "库名", prop: "tableSchema" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入库名" },
                    model: {
                      value: _vm.dcSystemConfigAddDialog.form.tableSchema,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.dcSystemConfigAddDialog.form,
                          "tableSchema",
                          $$v
                        )
                      },
                      expression: "dcSystemConfigAddDialog.form.tableSchema",
                    },
                  }),
                ],
                1
              ),
              _vm.dcSystemConfigAddDialog.form.joinMode === 1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "接口地址", prop: "apiUrl" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入接口地址" },
                        model: {
                          value: _vm.dcSystemConfigAddDialog.form.apiUrl,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.dcSystemConfigAddDialog.form,
                              "apiUrl",
                              $$v
                            )
                          },
                          expression: "dcSystemConfigAddDialog.form.apiUrl",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "店铺维表", prop: "shopTable" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入店铺维表" },
                    model: {
                      value: _vm.dcSystemConfigAddDialog.form.shopTable,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.dcSystemConfigAddDialog.form,
                          "shopTable",
                          $$v
                        )
                      },
                      expression: "dcSystemConfigAddDialog.form.shopTable",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "关联主键", prop: "correlationPrimaryKey" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入关联主键" },
                    model: {
                      value:
                        _vm.dcSystemConfigAddDialog.form.correlationPrimaryKey,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.dcSystemConfigAddDialog.form,
                          "correlationPrimaryKey",
                          $$v
                        )
                      },
                      expression:
                        "dcSystemConfigAddDialog.form.correlationPrimaryKey",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.dcSystemConfigAddDialog.visible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: { click: _vm.submitAddDcSystemConfigForm },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.dcSystemConfigEditDialog.title,
            visible: _vm.dcSystemConfigEditDialog.visible,
            width: "600px",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.dcSystemConfigEditDialog, "visible", $event)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "spuInfoUpdate",
              attrs: {
                size: "mini",
                "label-width": "100px",
                model: _vm.dcSystemConfigEditDialog.form,
                rules: _vm.dcSystemConfigEditDialog.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "分公司", prop: "orgCode" } },
                [
                  _c("DictSelect", {
                    ref: "addOrg",
                    attrs: {
                      type: "ht-org",
                      value: _vm.dcSystemConfigEditDialog.form.orgCode,
                      linkageRef: "addDc",
                      init: "",
                      initOptions: _vm.orgDictInitOptions,
                      fullLabel: "",
                      clearable: false,
                      placeholder: "请选择分公司",
                      disabled: "true",
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(
                          _vm.dcSystemConfigEditDialog.form,
                          "orgCode",
                          $event
                        )
                      },
                      changeLinkageRef: _vm.handleQueryChangeLinkageRef,
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "配送中心", prop: "dcCode" } },
                [
                  _c("DictSelect", {
                    ref: "addDc",
                    attrs: {
                      type: "ht-dc",
                      value: _vm.dcSystemConfigEditDialog.form.dcCode,
                      dictFun: _vm.dictFun,
                      parentLinkageRef: "",
                      fullLabel: "",
                      disabled: "true",
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(
                          _vm.dcSystemConfigEditDialog.form,
                          "dcCode",
                          $event
                        )
                      },
                      getOtherJsonParam: (callback) =>
                        callback(_vm.dcSystemConfigEditDialog.form),
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "系统名称", prop: "systemName" } },
                [
                  _c("el-input", {
                    attrs: { disabled: "true", placeholder: "请输入系统名称" },
                    model: {
                      value: _vm.dcSystemConfigEditDialog.form.systemName,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.dcSystemConfigEditDialog.form,
                          "systemName",
                          $$v
                        )
                      },
                      expression: "dcSystemConfigEditDialog.form.systemName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "接入方式", prop: "joinMode" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { disabled: "true", size: "mini", clearable: "" },
                      model: {
                        value: _vm.dcSystemConfigEditDialog.form.joinMode,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.dcSystemConfigEditDialog.form,
                            "joinMode",
                            $$v
                          )
                        },
                        expression: "dcSystemConfigEditDialog.form.joinMode",
                      },
                    },
                    [
                      _c("el-option", { attrs: { value: 0, label: "数据库" } }),
                      _c("el-option", { attrs: { value: 1, label: "接口" } }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.dcSystemConfigEditDialog.form.joinMode === 0
                ? _c(
                    "el-form-item",
                    { attrs: { label: "数据库类型", prop: "databaseType" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { size: "mini", clearable: "" },
                          model: {
                            value:
                              _vm.dcSystemConfigEditDialog.form.databaseType,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.dcSystemConfigEditDialog.form,
                                "databaseType",
                                $$v
                              )
                            },
                            expression:
                              "dcSystemConfigEditDialog.form.databaseType",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { value: 0, label: "mysql" },
                          }),
                          _c("el-option", {
                            attrs: { value: 1, label: "oracle" },
                          }),
                          _c("el-option", {
                            attrs: { value: 2, label: "sql server" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.dcSystemConfigEditDialog.form.joinMode === 0
                ? _c(
                    "el-form-item",
                    { attrs: { label: "链接地址", prop: "databaseUrl" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入链接地址" },
                        model: {
                          value: _vm.dcSystemConfigEditDialog.form.databaseUrl,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.dcSystemConfigEditDialog.form,
                              "databaseUrl",
                              $$v
                            )
                          },
                          expression:
                            "dcSystemConfigEditDialog.form.databaseUrl",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.dcSystemConfigEditDialog.form.d === 0
                ? _c(
                    "el-form-item",
                    { attrs: { label: "用户名", prop: "databaseUsername" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入用户名" },
                        model: {
                          value:
                            _vm.dcSystemConfigEditDialog.form.databaseUsername,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.dcSystemConfigEditDialog.form,
                              "databaseUsername",
                              $$v
                            )
                          },
                          expression:
                            "dcSystemConfigEditDialog.form.databaseUsername",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.dcSystemConfigEditDialog.form.joinMode === 0
                ? _c(
                    "el-form-item",
                    { attrs: { label: "密码", prop: "databasePassword" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入密码" },
                        model: {
                          value:
                            _vm.dcSystemConfigEditDialog.form.databasePassword,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.dcSystemConfigEditDialog.form,
                              "databasePassword",
                              $$v
                            )
                          },
                          expression:
                            "dcSystemConfigEditDialog.form.databasePassword",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "库名", prop: "tableSchema" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入库名" },
                    model: {
                      value: _vm.dcSystemConfigEditDialog.form.tableSchema,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.dcSystemConfigEditDialog.form,
                          "tableSchema",
                          $$v
                        )
                      },
                      expression: "dcSystemConfigEditDialog.form.tableSchema",
                    },
                  }),
                ],
                1
              ),
              _vm.dcSystemConfigEditDialog.form.joinMode === 1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "接口地址", prop: "apiUrl" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入接口地址" },
                        model: {
                          value: _vm.dcSystemConfigEditDialog.form.apiUrl,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.dcSystemConfigEditDialog.form,
                              "apiUrl",
                              $$v
                            )
                          },
                          expression: "dcSystemConfigEditDialog.form.apiUrl",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "店铺维表", prop: "shopTable" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入店铺维表" },
                    model: {
                      value: _vm.dcSystemConfigEditDialog.form.shopTable,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.dcSystemConfigEditDialog.form,
                          "shopTable",
                          $$v
                        )
                      },
                      expression: "dcSystemConfigEditDialog.form.shopTable",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "关联主键", prop: "correlationPrimaryKey" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入关联主键" },
                    model: {
                      value:
                        _vm.dcSystemConfigEditDialog.form.correlationPrimaryKey,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.dcSystemConfigEditDialog.form,
                          "correlationPrimaryKey",
                          $$v
                        )
                      },
                      expression:
                        "dcSystemConfigEditDialog.form.correlationPrimaryKey",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.dcSystemConfigEditDialog.visible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: { click: _vm.submitEditDcSystemConfigForm },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }