var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.homeLoading,
          expression: "homeLoading",
        },
      ],
      staticStyle: { padding: "20px" },
    },
    [
      _c(
        "div",
        { staticClass: "mg-search", staticStyle: { "padding-bottom": "15px" } },
        [
          _c(
            "el-form",
            {
              ref: "queryFormRef",
              attrs: {
                size: "mini",
                "label-width": "auto",
                inline: true,
                model: _vm.queryForm,
              },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.handleQuery.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "平台" } },
                [
                  _c("DictSelect", {
                    ref: "platform",
                    attrs: {
                      type: "platform",
                      value: _vm.queryForm.platforms,
                      multiple: "",
                      linkageRef: "spuName,spuModel",
                      dictFun: _vm.dictFun,
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(_vm.queryForm, "platforms", $event)
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "组合装名称" } },
                [
                  _c("DictSelect", {
                    ref: "spuName",
                    attrs: {
                      type: "spuName",
                      parentLinkageRef: "",
                      value: _vm.queryForm.spuNames,
                      dictFun: _vm.dictFun,
                      multiple: "",
                      remote: "",
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(_vm.queryForm, "spuNames", $event)
                      },
                      getOtherJsonParam: (callback) =>
                        callback({ platforms: _vm.queryForm.platforms }),
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "型号" } },
                [
                  _c("DictSelect", {
                    ref: "spuModel",
                    attrs: {
                      type: "spuModel",
                      parentLinkageRef: "",
                      value: _vm.queryForm.skuModels,
                      dictFun: _vm.dictFun,
                      multiple: "",
                      remote: "",
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(_vm.queryForm, "skuModels", $event)
                      },
                      getOtherJsonParam: (callback) =>
                        callback({ platforms: _vm.queryForm.platforms }),
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "form-btn-item",
                      attrs: {
                        size: "mini",
                        icon: "el-icon-search",
                        type: "primary",
                      },
                      on: { click: _vm.handleFilter },
                    },
                    [_vm._v("查询")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "form-btn-item",
                      attrs: {
                        size: "mini",
                        icon: "el-icon-plus",
                        type: "primary",
                      },
                      on: { click: _vm.handleSpuAdd },
                    },
                    [_vm._v("新增")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "form-btn-item",
                      attrs: {
                        size: "mini",
                        icon: "el-icon-upload",
                        type: "primary",
                      },
                      on: { click: _vm.handleSpuImportNotice },
                    },
                    [_vm._v("导入组合装控价")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "form-btn-item",
                      attrs: {
                        size: "mini",
                        icon: "el-icon-download",
                        type: "primary",
                      },
                      on: { click: _vm.handleSpuExportNotice },
                    },
                    [_vm._v("导出组合装控价")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "tableRef",
          attrs: {
            data: _vm.pageData.rows,
            height: "calc(100vh - 250px)",
            size: "mini",
            border: "",
            "highlight-current-row": "",
            "header-cell-style": {
              background: "rgb(235 235 235)",
              color: "black",
              textAlign: "center",
              verticalAlign: "middle",
            },
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "组合装编码", prop: "spuCode", "min-width": "120" },
          }),
          _c("el-table-column", {
            attrs: { label: "组合装名称", prop: "spuName", "min-width": "200" },
          }),
          _c("el-table-column", {
            attrs: {
              label: "组合装型号",
              prop: "skuModel",
              "min-width": "100",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c(
                      "el-row",
                      _vm._l(row.skuModelInfos, function (item, index) {
                        return _c("el-col", { key: index }, [
                          _c("span", [_vm._v(_vm._s(item.skuModel))]),
                        ])
                      }),
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "售卖规格单位",
              prop: "skuSpecifications",
              "min-width": "100",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c(
                      "el-row",
                      _vm._l(row.skuModelInfos, function (item, index) {
                        return _c("el-col", { key: index }, [
                          _c("span", [_vm._v(_vm._s(item.skuSpecifications))]),
                        ])
                      }),
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: "规格含数量",
              prop: "skuSpecificationsQuantity",
              "min-width": "100",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c(
                      "el-row",
                      _vm._l(row.skuModelInfos, function (item, index) {
                        return _c("el-col", { key: index }, [
                          _c("span", [
                            _vm._v(_vm._s(item.skuSpecificationsQuantity)),
                          ]),
                        ])
                      }),
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "是否赠品", prop: "ifGift", "min-width": "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c(
                      "el-row",
                      _vm._l(row.skuModelInfos, function (item, index) {
                        return _c("el-col", { key: index }, [
                          _c("span", [_vm._v(_vm._s(item.ifGift))]),
                        ])
                      }),
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c(
            "el-table-column",
            {
              attrs: {
                label: "各平台售卖总价",
                prop: "spuSellPrice",
                width: "100",
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "淘宝", prop: "tbSpuSellPrice", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [_c("span", [_vm._v(_vm._s(row.tbSpuSellPrice))])]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "拼多多",
                  prop: "pddSpuSellPrice",
                  width: "100",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [_c("span", [_vm._v(_vm._s(row.pddSpuSellPrice))])]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "京东", prop: "jdSpuSellPrice", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [_c("span", [_vm._v(_vm._s(row.jdSpuSellPrice))])]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "抖音", prop: "dySpuSellPrice", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [_c("span", [_vm._v(_vm._s(row.dySpuSellPrice))])]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("el-table-column", {
            attrs: {
              label: "操作",
              "min-width": "120",
              align: "center",
              "class-name": "small-padding fixed-width",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          size: "mini",
                          type: "text",
                          icon: "el-icon-edit",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.handleSpuEdit(scope.row)
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          size: "mini",
                          type: "text",
                          icon: "el-icon-delete",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.handleDeleteSpu(scope.row)
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("Pagination", {
        attrs: {
          total: _vm.pageData.total,
          page: _vm.queryForm.page,
          limit: _vm.queryForm.limit,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.queryForm, "page", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.queryForm, "limit", $event)
          },
          pagination: _vm.handleQuery,
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "组合装控价导入",
            visible: _vm.spuImportDialog.visible,
            "append-to-body": "",
            width: "400px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.spuImportDialog, "visible", $event)
            },
          },
        },
        [
          _c(
            "el-upload",
            {
              ref: "spuUpload",
              staticClass: "upload-demo",
              attrs: {
                drag: "",
                limit: 1,
                action: "#",
                "auto-upload": false,
                "file-list": _vm.spuImportDialog.fileList,
                "on-change": (file, fileList) =>
                  _vm.handleSpuFileChange(file, fileList, "change"),
                "on-remove": (file, fileList) =>
                  _vm.handleSpuFileChange(file, fileList, "remove"),
              },
            },
            [
              _c("i", { staticClass: "el-icon-upload" }),
              _c("div", { staticClass: "el-upload__text" }, [
                _vm._v("将文件拖到此处，或"),
                _c("em", [_vm._v("点击上传")]),
              ]),
              _c(
                "div",
                {
                  staticClass: "el-upload__tip",
                  attrs: { slot: "tip" },
                  slot: "tip",
                },
                [
                  _c("span", [_vm._v("仅允许导入xls、xlsx格式文件。")]),
                  _c(
                    "el-link",
                    {
                      staticStyle: {
                        "font-size": "12px",
                        "vertical-align": "baseline",
                      },
                      attrs: {
                        type: "primary",
                        underline: false,
                        target: "_blank",
                        href: _vm.spuImportDialog.template,
                      },
                    },
                    [_vm._v("下载模板")]
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "mini",
                    disabled: _vm.spuSubmitDisabled,
                  },
                  on: { click: _vm.submitSpuImport },
                },
                [_vm._v("确定")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.spuImportDialog.visible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "spuInfoAddDialog",
          attrs: {
            title: _vm.spuInfoAddDialog.title,
            visible: _vm.spuInfoAddDialog.visible,
            width: "1200px",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.spuInfoAddDialog, "visible", $event)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "spuInfoUpdate",
              attrs: {
                size: "mini",
                "label-width": "100px",
                model: _vm.spuInfoAddDialog.form,
                rules: _vm.spuInfoAddDialog.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "组合装名称", prop: "spuName" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: { clearable: "", placeholder: "请输入组合装名称" },
                    model: {
                      value: _vm.spuInfoAddDialog.form.spuName,
                      callback: function ($$v) {
                        _vm.$set(_vm.spuInfoAddDialog.form, "spuName", $$v)
                      },
                      expression: "spuInfoAddDialog.form.spuName",
                    },
                  }),
                ],
                1
              ),
              _c("spn", [
                _vm._v(
                  "请在下方配置各平台控价，如不填写具体值，代表该组合装不在该平台售卖："
                ),
              ]),
              _c(
                "el-row",
                { staticStyle: { "margin-top": "2%" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "淘宝总价" } },
                        [
                          _c("el-input-number", {
                            staticStyle: { width: "180px" },
                            attrs: {
                              clearable: "",
                              placeholder: "请输入淘宝总价",
                            },
                            model: {
                              value: _vm.spuInfoAddDialog.form.tbSpuSellPrice,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.spuInfoAddDialog.form,
                                  "tbSpuSellPrice",
                                  $$v
                                )
                              },
                              expression:
                                "spuInfoAddDialog.form.tbSpuSellPrice",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "拼多多总价" } },
                        [
                          _c("el-input-number", {
                            staticStyle: { width: "180px" },
                            attrs: {
                              clearable: "",
                              placeholder: "请输入拼多多总价",
                            },
                            model: {
                              value: _vm.spuInfoAddDialog.form.pddSpuSellPrice,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.spuInfoAddDialog.form,
                                  "pddSpuSellPrice",
                                  $$v
                                )
                              },
                              expression:
                                "spuInfoAddDialog.form.pddSpuSellPrice",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "京东总价" } },
                        [
                          _c("el-input-number", {
                            staticStyle: { width: "180px" },
                            attrs: {
                              clearable: "",
                              placeholder: "请输入京东总价",
                            },
                            model: {
                              value: _vm.spuInfoAddDialog.form.jdSpuSellPrice,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.spuInfoAddDialog.form,
                                  "jdSpuSellPrice",
                                  $$v
                                )
                              },
                              expression:
                                "spuInfoAddDialog.form.jdSpuSellPrice",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "抖音总价" } },
                        [
                          _c("el-input-number", {
                            staticStyle: { width: "180px" },
                            attrs: {
                              clearable: "",
                              placeholder: "请输入抖音总价",
                            },
                            model: {
                              value: _vm.spuInfoAddDialog.form.dySpuSellPrice,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.spuInfoAddDialog.form,
                                  "dySpuSellPrice",
                                  $$v
                                )
                              },
                              expression:
                                "spuInfoAddDialog.form.dySpuSellPrice",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              attrs: {
                data: _vm.spuInfoAddDialog.form.skuModelInfos,
                size: "mini",
                border: "",
                "highlight-current-row": "",
                "header-cell-style": {
                  background: "rgb(227 224 224)",
                  color: "black",
                },
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "型号", prop: "skuModel" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("DictSelect", {
                          ref: "skuModel",
                          attrs: {
                            type: "ec-cargo-no",
                            value: row.skuModel,
                            dictFun: _vm.dictSkuModelsFun,
                            remote: "",
                          },
                          on: {
                            "update:value": function ($event) {
                              return _vm.$set(row, "skuModel", $event)
                            },
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "售卖规格单位", prop: "skuSpecifications" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入售卖规格单位",
                            size: "mini",
                          },
                          model: {
                            value: row.skuSpecifications,
                            callback: function ($$v) {
                              _vm.$set(row, "skuSpecifications", $$v)
                            },
                            expression: "row.skuSpecifications",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "规格含数量",
                  prop: "skuSpecificationsQuantity",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("el-input-number", {
                          attrs: {
                            clearable: "",
                            placeholder: "请输入规格含数量",
                          },
                          model: {
                            value: row.skuSpecificationsQuantity,
                            callback: function ($$v) {
                              _vm.$set(row, "skuSpecificationsQuantity", $$v)
                            },
                            expression: "row.skuSpecificationsQuantity",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "是否赠品", prop: "ifGift" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "el-select",
                          {
                            attrs: { size: "mini" },
                            model: {
                              value: row.ifGift,
                              callback: function ($$v) {
                                _vm.$set(row, "ifGift", $$v)
                              },
                              expression: "row.ifGift",
                            },
                          },
                          [
                            _c("el-option", {
                              attrs: { value: "是", label: "是" },
                            }),
                            _c("el-option", {
                              attrs: { value: "否", label: "否" },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  align: "center",
                  "class-name": "small-padding fixed-width",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              type: "text",
                              icon: "el-icon-delete",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.deleteSkuModelLine(
                                  scope.$index,
                                  scope.row
                                )
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "el-row",
            { staticClass: "mb8", attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 1.5 } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "text", icon: "Plus", size: "mini" },
                      on: { click: _vm.addSkuModelLine },
                    },
                    [_vm._v("新增型号")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.spuInfoAddDialog.visible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: { click: _vm.submitAddSpuForm },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "spuInfoAddDialog",
          attrs: {
            title: "",
            visible: _vm.spuInfoUpdateDialog.visible,
            width: "1200px",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.spuInfoUpdateDialog, "visible", $event)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "spuInfoUpdate",
              attrs: {
                size: "mini",
                "label-width": "100px",
                model: _vm.spuInfoUpdateDialog.form,
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "组合装编码", prop: "spuCode" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "200px" },
                            attrs: { disabled: true, clearable: "" },
                            model: {
                              value: _vm.spuInfoUpdateDialog.form.spuCode,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.spuInfoUpdateDialog.form,
                                  "spuCode",
                                  $$v
                                )
                              },
                              expression: "spuInfoUpdateDialog.form.spuCode",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "组合装名称", prop: "spuName" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "200px" },
                            attrs: {
                              clearable: "",
                              placeholder: "请输入组合装名称",
                            },
                            model: {
                              value: _vm.spuInfoUpdateDialog.form.spuName,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.spuInfoUpdateDialog.form,
                                  "spuName",
                                  $$v
                                )
                              },
                              expression: "spuInfoUpdateDialog.form.spuName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("spn", [
                _vm._v(
                  "请在下方配置各平台控价，如不填写具体值，代表该组合装不在该平台售卖："
                ),
              ]),
              _c(
                "el-row",
                { staticStyle: { "margin-top": "2%" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "淘宝总价" } },
                        [
                          _c("el-input-number", {
                            staticStyle: { width: "180px" },
                            attrs: {
                              clearable: "",
                              placeholder: "请输入淘宝总价",
                            },
                            model: {
                              value:
                                _vm.spuInfoUpdateDialog.form.tbSpuSellPrice,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.spuInfoUpdateDialog.form,
                                  "tbSpuSellPrice",
                                  $$v
                                )
                              },
                              expression:
                                "spuInfoUpdateDialog.form.tbSpuSellPrice",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "拼多多总价" } },
                        [
                          _c("el-input-number", {
                            staticStyle: { width: "180px" },
                            attrs: {
                              clearable: "",
                              placeholder: "请输入拼多多总价",
                            },
                            model: {
                              value:
                                _vm.spuInfoUpdateDialog.form.pddSpuSellPrice,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.spuInfoUpdateDialog.form,
                                  "pddSpuSellPrice",
                                  $$v
                                )
                              },
                              expression:
                                "spuInfoUpdateDialog.form.pddSpuSellPrice",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "京东总价" } },
                        [
                          _c("el-input-number", {
                            staticStyle: { width: "180px" },
                            attrs: {
                              clearable: "",
                              placeholder: "请输入京东总价",
                            },
                            model: {
                              value:
                                _vm.spuInfoUpdateDialog.form.jdSpuSellPrice,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.spuInfoUpdateDialog.form,
                                  "jdSpuSellPrice",
                                  $$v
                                )
                              },
                              expression:
                                "spuInfoUpdateDialog.form.jdSpuSellPrice",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "抖音总价" } },
                        [
                          _c("el-input-number", {
                            staticStyle: { width: "180px" },
                            attrs: {
                              clearable: "",
                              placeholder: "请输入抖音总价",
                            },
                            model: {
                              value:
                                _vm.spuInfoUpdateDialog.form.dySpuSellPrice,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.spuInfoUpdateDialog.form,
                                  "dySpuSellPrice",
                                  $$v
                                )
                              },
                              expression:
                                "spuInfoUpdateDialog.form.dySpuSellPrice",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              attrs: {
                data: _vm.spuInfoUpdateDialog.form.skuModelInfos,
                size: "mini",
                border: "",
                "highlight-current-row": "",
                "header-cell-style": {
                  background: "rgb(227 224 224)",
                  color: "black",
                },
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "型号", prop: "skuModel" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("DictSelect", {
                          ref: "skuModel",
                          attrs: {
                            type: "ec-cargo-no",
                            value: row.skuModel,
                            dictFun: _vm.dictSkuModelsFun,
                            remote: "",
                          },
                          on: {
                            "update:value": function ($event) {
                              return _vm.$set(row, "skuModel", $event)
                            },
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "售卖规格单位", prop: "skuSpecifications" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入售卖规格单位",
                            size: "mini",
                          },
                          model: {
                            value: row.skuSpecifications,
                            callback: function ($$v) {
                              _vm.$set(row, "skuSpecifications", $$v)
                            },
                            expression: "row.skuSpecifications",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "规格含数量",
                  prop: "skuSpecificationsQuantity",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("el-input-number", {
                          attrs: {
                            clearable: "",
                            placeholder: "请输入规格含数量",
                          },
                          model: {
                            value: row.skuSpecificationsQuantity,
                            callback: function ($$v) {
                              _vm.$set(row, "skuSpecificationsQuantity", $$v)
                            },
                            expression: "row.skuSpecificationsQuantity",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "是否赠品", prop: "ifGift" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "el-select",
                          {
                            attrs: { size: "mini" },
                            model: {
                              value: row.ifGift,
                              callback: function ($$v) {
                                _vm.$set(row, "ifGift", $$v)
                              },
                              expression: "row.ifGift",
                            },
                          },
                          [
                            _c("el-option", {
                              attrs: { value: "是", label: "是" },
                            }),
                            _c("el-option", {
                              attrs: { value: "否", label: "否" },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  align: "center",
                  "class-name": "small-padding fixed-width",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              type: "text",
                              icon: "el-icon-delete",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.skuModelEditDeleteLine(
                                  scope.$index,
                                  scope.row
                                )
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "el-row",
            { staticClass: "mb8", attrs: { gutter: 10 } },
            [
              _c(
                "el-col",
                { attrs: { span: 1.5 } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "text", icon: "Plus", size: "mini" },
                      on: { click: _vm.skuModelEditAddLine },
                    },
                    [_vm._v("新增型号")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.spuInfoUpdateDialog.visible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: { click: _vm.submitSpuInfoEdit },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }