<template>
  <div style="padding: 20px">
    <div class="mg-search" style="padding-bottom: 15px">
      <el-form ref="queryFormRef" size="mini" label-width="auto" :inline="true" :model="queryForm" @keyup.enter.native="handleQuery">
        <el-form-item label="申请序号">
          <el-input v-model="queryForm.id" clearable placeholder="请输入" />
        </el-form-item>
        <el-form-item label="省市">
          <DictSelect
            v-if="options.cities.length > 0"
            ref="province"
            type="province"
            :value.sync="queryForm.region"
            linkageRef="region"
            :dictFun="dictQueryFun"
            singleProps
            @changeLinkageRef="handleChangeLinkageRef"
          />
        </el-form-item>
<!--        <el-form-item label="地区">-->
<!--          <DictSelect-->
<!--            ref="region"-->
<!--            type="region"-->
<!--            :value.sync="queryForm.region"-->
<!--            :dictFun="dictQueryFun"-->
<!--            linkageRef="org"-->
<!--            parentLinkageRef-->
<!--            singleProps-->
<!--            @changeLinkageRef="handleChangeLinkageRef"-->
<!--          />-->
<!--        </el-form-item>-->
        <el-form-item label="签约类型">
          <DictSelect :value.sync="queryForm.signType" init singleProps :init-options="options.signTypeOptions" />
        </el-form-item>
        <el-form-item label="渠道">
          <DictSelect type="channel" :value.sync="queryForm.channel" linkageRef="shopType" init singleProps :init-options="options.channels" @changeLinkageRef="handleChangeLinkageRef" />
        </el-form-item>
        <el-form-item label="店铺类型">
          <DictSelect ref="shopType" type="shopType" :value.sync="queryForm.shopType" singleProps :dictFun="dictQueryFun" parentLinkageRef />
        </el-form-item>
        <el-form-item label="分公司名称">
          <DictSelect
            ref="org"
            type="org"
            :value.sync="queryForm.org"
            :dictFun="dictQueryFun"
            parentLinkageRef
            singleProps
            @changeLinkageRef="handleChangeLinkageRef"
          />
        </el-form-item>
        <el-form-item label="保证金状态">
          <DictSelect :value.sync="queryForm.depositStatus" init singleProps :init-options="options.depositOptions" />
        </el-form-item>
        <el-form-item label="合同截止日期范围">
          <el-date-picker
            v-model="queryForm.contractDate"
            type="daterange"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            @change="handleDateChange($event, 'contact')"
            :default-time="['00:00:00', '23:59:59']"
           />
        </el-form-item>
        <el-form-item label="DRP编码">
          <el-input v-model="queryForm.drpTerminalCode" clearable placeholder="请输入" />
        </el-form-item>
        <el-form-item label="原申请序号">
          <el-input v-model="queryForm.authCode" clearable placeholder="请输入" />
        </el-form-item>
        <el-form-item label="店铺名称">
          <el-input v-model="queryForm.shopName" clearable placeholder="请输入" />
        </el-form-item>
        <el-form-item label="申请时间">
          <el-date-picker
            v-model="queryForm.signDate"
            type="daterange"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            @change="handleDateChange($event, 'sign')"
            :default-time="['00:00:00', '23:59:59']"
          />
        </el-form-item>
        <el-form-item label="拟使用单位">
          <el-input v-model="queryForm.intendedCompany" clearable placeholder="请输入" />
        </el-form-item>
        <el-form-item label="收据编号">
          <el-input v-model="queryForm.receiptNum" clearable placeholder="请输入" />
        </el-form-item>
        <el-form-item label="是否关店">
          <el-select v-model="queryForm.businessStatus" clearable>
            <el-option value="是" label="是" />
            <el-option value="否" label="否" />
          </el-select>
        </el-form-item>
        <el-form-item label="授权状态">
          <el-select v-model="queryForm.authStatus" clearable>
            <el-option v-for="item in options.authStatuss" :key="item" :value="item" :label="item" />
          </el-select>
        </el-form-item>
        <el-form-item label="终端审核">
          <el-select v-model="queryForm.auditStatus" clearable>
            <el-option value="0" label="待审核" />
            <el-option value="1" label="已通过" />
            <el-option value="2" label="已驳回" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button class="form-btn-item" size="mini" icon="el-icon-search" type="primary" @click="handleFilter">查询</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-refresh" type="primary" @click="resetQuery">重置</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-plus" type="primary" @click="handleEdit()">单行新增</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-upload" type="primary" @click="handleImport('main')">授权导入</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-upload" type="primary" @click="handleImport('tm')">终端导入</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-upload" type="primary" @click="handleImport('shop')">店铺导入</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-download" type="primary" @click="handleExport('0')">授权导出</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-download" type="primary" @click="handleExport('1')">终端导出</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-download" type="primary" @click="handleExport('2')">店铺导出</el-button>
          <el-button :disabled="pageData.selections.length === 0" class="form-btn-item" size="mini" icon="el-icon-remove" type="danger" @click="handleDel">删除已选</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table
      ref="tableRef"
      :data="pageData.rows"
      height="calc(100vh - 250px)"
      v-loading="loading"
      size="mini"
      border
      highlight-current-row
      :header-cell-style="{ background: 'rgb(235 235 235)', color: 'black',textAlign: 'center', verticalAlign: 'middle' }"
      @sort-change="handleSortChange"
      @selection-change="handleSelectionChange"
      >
      <el-table-column type="selection" width="90" fixed="left" />
      <el-table-column label="申请序号" prop="id" width="80" fixed="left" />
      <el-table-column label="申请时间" prop="signTime" width="100" fixed="left" />
      <el-table-column label="区域" prop="region" width="100" fixed="left" />
      <el-table-column label="是否绑定终端" prop="ifBind" width="160" />
      <el-table-column label="授权单位" prop="intendedCompany" width="180" fixed="left" />

      <el-table-column label="授权状态" prop="authStatus" width="80" />
      <el-table-column label="新/续签" prop="signType" width="80" />
      <el-table-column label="合同期限" prop="shop" width="140">
        <template #default="{ row }">
          <span>{{ row?.contractDateStart ? (parseTime(row.contractDateStart,'{y}.{m}.{d}') + '-' + parseTime(row?.contractDateEnd,'{y}.{m}.{d}')): '' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="法人" prop="legalPerson" width="80" />
      <el-table-column label="渠道" prop="channel" width="80" />
      <el-table-column label="店铺类型" prop="shopType" width="100" />
      <el-table-column label="是否关店" prop="businessStatus" width="80" />
      <el-table-column label="应收保证金" prop="depositAmount" width="60" />
      <el-table-column label="联系电话" prop="phoneNumber" width="120" />
      <el-table-column label="店铺名称" prop="shopName" width="160" />
      <el-table-column label="分公司名称" prop="org" width="160" />
      <el-table-column label="保证金状态" prop="depositStatus" width="80" />
      <el-table-column label="保证金收取时间" prop="depositCollectDate" width="100" />
      <el-table-column label="实收金额" prop="payAmount" width="60" />
      <el-table-column label="收据编号" prop="receiptNum" width="80" />
      <el-table-column label="保证金退还时间" prop="depositReturnDate" width="100" />
      <el-table-column label="保证金实退" prop="depositBackAmount" width="80" />
      <el-table-column label="保证金结余" prop="depositLeftAmount" width="80" />
      <el-table-column label="保证金备注" prop="depositRemark" width="100" />
      <el-table-column label="原申请序号" prop="authCode" width="80" />
      <el-table-column label="其它备注" prop="remark" width="160" />
      <el-table-column label="操作" width="80" align="center" fixed="right">
        <template #default="{ row }">
          <div class="button-class">
            <el-row>
              <el-col :span="12">
                <el-button size="mini" type="text" style="border: 0px;" @click="handleCopy(row)">新增</el-button>
              </el-col>
              <el-col :span="12">
                <el-button size="mini" type="text" style="border: 0px;" @click="handleEdit(row)">编辑</el-button>
              </el-col>
              <el-col :span="12">
                <el-button size="mini" type="text" style="border: 0px;" @click="handleViewLog(row)">日志</el-button>
              </el-col>
              <el-col :span="12">
                <el-button size="mini" type="text" style="border: 0px; color:red" @click="handleDel(row)">删除</el-button>
              </el-col>
            </el-row>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <Pagination :total="pageData.total" :page.sync="queryForm.current" :limit.sync="queryForm.size" @pagination="handleQuery" />

    <ImportDialog :importBaseParams="importDialog[importDialog.type]" @handleImportCallBack="handleImportData" append-to-body :modal-append-to-body="false" />

    <el-dialog title="新增/编辑" :visible.sync="editDialog.visible" append-to-body width="50%">
      <el-form ref="form" size="mini" label-width="120px" :inline="true" :rules="editDialog.rules" :model="editDialog.form" class="common-form">
        <el-row :gutter="12" class="common-form-row">
          <el-col :span="24">
            <div class="form-title">签约信息</div>
          </el-col>
          <el-col :span="7">
            <el-form-item label="申请时间">
              <el-date-picker
                v-model="editDialog.form.signTime"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="合同起始时间">
              <el-date-picker
                v-model="editDialog.contractDate"
                type="daterange"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                @change="handleDateChange($event, 'save')"
                :default-time="['00:00:00', '23:59:59']"
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="签约类型" prop="signType">
              <DictSelect :value.sync="editDialog.form.signType" init singleProps :init-options="options.signTypeOptions" />
            </el-form-item>
          </el-col>
          <el-col v-if="editDialog.form.id" :span="8">
            <el-form-item label="申请序号">
              <span>{{ editDialog.form.id }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="保证金状态">
              <el-input v-model="editDialog.form.depositStatus" disabled />
<!--              <DictSelect :value.sync="editDialog.form.depositStatus" init singleProps :init-options="options.depositOptions" />-->
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="保证金备注">
              <el-input v-model="editDialog.form.depositRemark" clearable placeholder="请输入" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="拟使用单位">
              <el-input v-model="editDialog.form.intendedCompany" clearable placeholder="请输入" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="应收保证金">
              <el-input-number v-model="editDialog.form.depositAmount" :precision="0" :controls="false" clearable placeholder="请输入" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="收取时间">
              <el-date-picker v-model="editDialog.form.depositCollectDate" type="date" value-format="yyyy-MM-dd" placeholder="选择日期"/>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="退还时间">
              <el-date-picker v-model="editDialog.form.depositReturnDate" type="date" value-format="yyyy-MM-dd " placeholder="选择日期"/>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="授权下发时间">
              <el-date-picker v-model="editDialog.form.authSendDate" type="date" value-format="yyyy-MM-dd " placeholder="选择日期"/>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="实收金额" prop="payAmount">
              <el-input-number v-model="editDialog.form.payAmount" :precision="2" :controls="false" clearable placeholder="请输入" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="保证金实退" prop="depositBackAmount">
              <el-input-number v-model="editDialog.form.depositBackAmount" :precision="2" :controls="false" clearable placeholder="请输入" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="收据编号" prop="receiptNum">
              <el-input v-model="editDialog.form.receiptNum" clearable placeholder="请输入" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="原申请序号">
              <el-input :disabled="currentRow.createTime!==undefined" v-model="editDialog.form.authCode" clearable placeholder="请输入" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="其它备注">
              <el-input v-model="editDialog.form.remark" clearable placeholder="请输入" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="授权状态">
              <el-select v-model="editDialog.form.authStatus" clearable placeholder="请选择">
                <el-option v-for="item in options.authStatuss" :key="item" :value="item" :label="item" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="分公司名称">
              <DictSelect
                ref="orgSave"
                type="orgSave"
                :value.sync="editDialog.form.org"
                :dictFun="dictSaveFun"
                parentLinkageRef
                singleProps
                @changeLinkageRef="handleChangeLinkageRef"
              />
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <div class="form-title">店铺信息</div>
          </el-col>
          <el-col :span="8">
            <el-form-item label="区域" prop="region">
              <DictSelect
                v-if="options.cities.length > 0"
                ref="province"
                type="province"
                :value.sync="editDialog.form.region"
                linkageRef="orgSave"
                :dictFun="dictSaveFun"
                singleProps
                @changeLinkageRef="handleChangeLinkageRef"
              />
            </el-form-item>
          </el-col>

<!--          <el-col :span="8">-->
<!--            <el-form-item label="配送中心编码">-->
<!--              <el-input v-model="editDialog.form.dcCode" clearable placeholder="请输入" />-->
<!--            </el-form-item>-->
<!--          </el-col>-->
<!--          <el-col :span="8">-->
<!--            <el-form-item label="DRP编码">-->
<!--              <el-input v-model="editDialog.form.drpTerminalCode" clearable placeholder="请输入" />-->
<!--            </el-form-item>-->
<!--          </el-col>-->

          <el-col :span="8">
            <el-form-item label="渠道" prop="channel">
              <DictSelect type="channel" :value.sync="editDialog.form.channel" linkageRef="shopTypeSave" init singleProps :init-options="options.channels" @changeLinkageRef="handleChangeLinkageRef" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="店铺类型" prop="shopType">
              <DictSelect ref="shopTypeSave" type="shopTypeSave" :value.sync="editDialog.form.shopType" singleProps :dictFun="dictSaveFun" parentLinkageRef />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="仓库地址">
              <el-input v-model="editDialog.form.stockAddress" clearable placeholder="请输入" />
            </el-form-item>
          </el-col>
<!--          <el-col :span="8">-->
<!--            <el-form-item label="店铺名称">-->
<!--              <el-input v-model="editDialog.form.shopName" clearable placeholder="请输入" />-->
<!--            </el-form-item>-->
<!--          </el-col>-->

          <el-col :span="8">
            <el-form-item label="联系电话">
              <el-input v-model="editDialog.form.phoneNumber" clearable placeholder="请输入" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="法人">
              <el-input v-model="editDialog.form.legalPerson" clearable placeholder="请输入" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="实控人">
              <el-input v-model="editDialog.form.realControlPerson" clearable placeholder="请输入" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="客户类型">
              <el-select v-model="editDialog.form.customType" clearable placeholder="请选择">
                <el-option v-for="item in options.customTypes" :key="item" :value="item" :label="item" />
              </el-select>
            </el-form-item>
          </el-col>
<!--          <el-col :span="8">-->
<!--            <el-form-item label="抬头">-->
<!--              <el-input v-model="editDialog.form.title" clearable placeholder="请输入" />-->
<!--            </el-form-item>-->
<!--          </el-col>-->
<!--          <el-col :span="8">-->
<!--            <el-form-item label="有效期">-->
<!--              <el-input v-model="editDialog.form.validDate" clearable placeholder="请输入" />-->
<!--            </el-form-item>-->
<!--          </el-col>-->
          <el-col :span="8">
            <el-form-item label="是否关店">
              <el-select v-model="editDialog.form.businessStatus" clearable>
                <el-option value="是" label="是" />
                <el-option value="否" label="否" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-tabs v-model="tabName">
          <el-tab-pane label="终端" name="tm" />
          <el-tab-pane label="店铺" name="shop" />
        </el-tabs>
        <div v-if="tabName==='tm'">
          <el-table
            :data="editDialog.form.tms"
            height="250px"
            size="mini"
            border
            ref="tmTable"
            highlight-current-row
            :header-cell-style="{ background: 'rgb(235 235 235)', color: 'black',textAlign: 'center', verticalAlign: 'middle' }"
            @selection-change="handleSelectionChange"
          >
            <el-table-column label="主配送中心编码" prop="mDcCode" width="120">
              <template #default="{ row }">
                <span >{{ row.mainDcCode + (row.mainDcName? '-' + row.mainDcName: '') }}</span>
              </template>
            </el-table-column>
            <el-table-column label="主终端编码" prop="mainDrpTerminalCode" width="120">
              <template #default="{ row }">
                <span >{{ row.mainDrpTerminalCode + (row.mainDrpTerminalName? '-' + row.mainDrpTerminalName: '') }}</span>
              </template>
            </el-table-column>
            <el-table-column label="副配送中心编码" prop="dcCode" width="120">
              <template #default="{row}">
                <span >{{ row.dcCode + (row.dcName? '-' + row.dcName: '') }}</span>
              </template>
            </el-table-column>
            <el-table-column label="副终端编码" prop="drpTerminalCode" width="120">
              <template #default="{row}">
                <span >{{ row.drpTerminalCode + (row.drpTerminalName? '-' + row.drpTerminalName: '') }}</span>
              </template>
            </el-table-column>
            <el-table-column label="审核状态" prop="status" width="120">
              <template #default="{row}">
                <span >{{ auditStatusMap[row.status] }}</span>
              </template>
            </el-table-column>
            <el-table-column label="创建人" prop="createUser"  width="80" />
            <el-table-column label="创建日期" prop="createTime" width="140" />
            <el-table-column label="操作" width="220" align="center" fixed="right">
              <template slot-scope="scope">
                <el-button size="mini" type="primary" @click="handleEditRow('currentTmIndex', scope.$index, scope.row)">编辑</el-button>
<!--                <el-button size="mini" type="primary" :@click="handleEditRow('currentTmIndex', scope.$index, scope.row)">编辑</el-button>-->
                <el-button v-if="auditUsers.indexOf(currentUser)> -1 && scope.row.status!=='1'" size="mini" type="primary" :disabled="!scope.row.createTime" @click="handleAuditTm(scope.row, '1')">通过</el-button>
                <el-button v-if="auditUsers.indexOf(currentUser)> -1 && scope.row.status!=='1'" size="mini" type="primary" :disabled="!scope.row.createTime" @click="handleAuditTm(scope.row, '2')">驳回</el-button>
                <el-button size="mini" type="primary" @click="() => editDialog.form.tms.splice(scope.$index, 1)">移除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <div style="text-align: center; border: 1px skyblue dashed;">
            <el-button size="mini" type="text" style="width: 100%" @click="handleAddRow('tms')">新增</el-button>
          </div>
        </div>
        <div v-if="tabName==='shop'">
          <el-table
            :data="editDialog.form.shops"
            height="250px"
            size="mini"
            border
            ref="shopTable"
            highlight-current-row
            :header-cell-style="{ background: 'rgb(235 235 235)', color: 'black',textAlign: 'center', verticalAlign: 'middle' }"
            @selection-change="handleSelectionChange"
          >
            <el-table-column label="店铺Id" prop="shopId" width="120" />
            <el-table-column label="店铺名称" prop="shopName" width="220" />
            <el-table-column label="创建人" prop="createUser"  width="80" />
            <el-table-column label="创建日期" prop="createTime" width="140" />
            <el-table-column label="操作" width="140" align="center">
              <template slot-scope="scope">
                <el-row>
                  <el-button size="mini" type="primary" @click="handleEditRow('currentShopIndex', scope.$index, scope.row)">编辑</el-button>
                  <el-button size="mini" type="primary"  @click="() => editDialog.form.shops.splice(scope.$index, 1)">移除</el-button>
                </el-row>
              </template>
            </el-table-column>
          </el-table>
          <div style="text-align: center; border: 1px skyblue dashed;">
            <el-button size="mini" type="text" style="width: 100%" @click="handleAddRow('shops')">新增</el-button>
          </div>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button type="primary" size="mini" @click="editDialog.visible = false">取消</el-button>
        <el-button type="primary" size="mini" @click="submitEdit">确定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="编辑" :visible.sync="tmDialog.visible" append-to-body>
      <el-form ref="tmForm" size="mini" label-width="140px" :inline="true" :rules="tmDialog.rules" :model="tmDialog.type.indexOf('tm') > -1 ?tmDialog.tmForm:tmDialog.shopForm" class="common-form">
        <el-row v-if="tmDialog.type.indexOf('tm') > -1" class="common-form-row">
          <el-col :span="12">
            <el-form-item label="主配送中心编码" prop="mainDcCode">
              <DictSelect
                size="mini"
                :ref="'mDc'"
                type="dc"
                :value.sync="tmDialog.tmForm.mainDcCode"
                :linkageRef="'mTm'"
                :dictFun="getTechDict"
                :init-props="{value: 'code', label: 'name'}"
                remote
                @change="handleFormChange"
                fullLabel
                @changeLinkageRef="handleChangeLinkageRef"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="主终端编码" prop="mainDrpTerminalCode">
              <DictSelect
                size="mini"
                :ref="'mTm'"
                type="tm"
                :value.sync="tmDialog.tmForm.mainDrpTerminalCode"
                linkageRef="regionSave"
                :dictFun="getTechDict"
                :init-props="{value: 'code', label: 'name'}"
                remote
                @change="handleFormChange"
                fullLabel
                @getOtherJsonParam="(callback) => callback({dcCode: tmDialog.tmForm.mainDcCode})"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="副配送中心编码" prop="dcCode">
              <DictSelect
                size="mini"
                :ref="'dc'"
                type="dc"
                :value.sync="tmDialog.tmForm.dcCode"
                :linkageRef="'tm'"
                :dictFun="getTechDict"
                :init-props="{value: 'code', label: 'name'}"
                remote
                @change="tmDialog.tmFormstatus='0'"
                fullLabel
                @changeLinkageRef="handleChangeLinkageRef"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="副终端编码" prop="drpTerminalCode">
              <DictSelect
                size="mini"
                :ref="'tm'"
                type="tm"
                :value.sync="tmDialog.tmForm.drpTerminalCode"
                :dictFun="getTechDict"
                :init-props="{value: 'code', label: 'name'}"
                remote
                @change="tmDialog.tmFormstatus='0'"
                fullLabel
                @getOtherJsonParam="(callback) => callback({dcCode: tmDialog.tmForm.dcCode})"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-else class="common-form-row">
          <el-col :span="12">
            <el-form-item label="店铺ID" prop="shopId">
              <el-input v-model=" tmDialog.shopForm.shopId" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="店铺名称" prop="shopName">
              <el-input v-model=" tmDialog.shopForm.shopName" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button type="primary" size="mini" @click="tmDialog.visible = false">取消</el-button>
        <el-button type="primary" size="mini" @click="submitForm">确定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="导入提示" :visible.sync="noticeDialog.visible" append-to-body width="700px">
      <div v-html="noticeDialog.msg" style="font-size: 16px;overflow-y: scroll;max-height: 400px">
      </div>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button type="primary" size="mini" @click="noticeDialog.visible = false">确定</el-button>
      </div>
    </el-dialog>

    <el-drawer title="操作日志" :visible.sync="logDrawer.visible" size="50%" direction="rtl" append-to-body>
      <div style="padding: 10px">
        <el-table
          :data="logDrawer.logs"
          height="calc(100vh - 250px)"
          v-loading="logDrawer.loading"
          size="mini"
          border
          ref="table"
          highlight-current-row
          :header-cell-style="{ background: 'rgb(235 235 235)', color: 'black',textAlign: 'center', verticalAlign: 'middle' }"
        >
          <el-table-column label="明细" prop="content" />
          <el-table-column label="操作时间" prop="opTime" width="140" />
          <el-table-column label="操作人" prop="opUser" width="100" />
        </el-table>
        <Pagination :total="logDrawer.total" :page.sync="logDrawer.current" :limit.sync="logDrawer.size" @pagination="handleViewLog" />
      </div>
    </el-drawer>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import { pageTechData,  getTechBaseOptions, saveTechData, importTechData, exportTechData, deleteTechData, pageActionLog, getDetailTech, auditTechTm, getTechDict,importTechTmData,importTechShopData, getCurrentUser } from "@/api/eCommerce";
import { parseTime, deepClone, excelExport } from "@/utils"
import { Notification } from "element-ui";
import DictSelect from "@/components/DictSelect";

export default {
  name: "E-CommerceTech",
  components: { Pagination, DictSelect, ImportDialog: () => import("@/components/ImportDialog")},
  data() {
    return {
      tabName: 'tm',
      getTechDict,
      auditStatusMap: {
        '0': '待审核',
        '1': '已通过',
        '2': '已驳回',
      },
      pageData: {
        total: 0,
        rows: [],
        selections: [],
      },
      queryForm: {
        current: 1,
        size: 10,
        id: undefined,
        org: undefined,
        province: undefined,
        region: undefined,
        signType: undefined,
        channel: undefined,
        shopType: undefined,
        depositStatus: undefined,
        contractDateEndS: undefined,
        contractDateEndE: undefined,
        shopName: undefined,
        drpTerminalCode: undefined,
        authCode: undefined,
        contractDate: [],
        signDate: [],
        signTimeStart: undefined,
        signTimeEnd: undefined,
        intendedCompany: undefined,
        receiptNum: undefined,
        businessStatus: undefined,
        auditStatus: undefined,
      },
      lrDay: undefined,
      loading: false,
      dictParam: {},
      formDictParam: {},
      editDialog: {
        visible: false,
        currentTmRow: {},
        currentShopRow: {},
        currentTmIndex: undefined,
        currentShopIndex: undefined,
        form: {
          id: undefined,
          org: undefined,
          province: undefined,
          region: undefined,
          signType: undefined,
          channel: undefined,
          shopType: undefined,
          depositStatus: undefined,
          contractDateStart: undefined,
          contractDateEnd: undefined,
          shopName: undefined,
          dcCode: undefined,
          drpTerminalCode: undefined,
          authCode: undefined,
          depositRemark: undefined,
          intendedCompany: undefined,
          depositAmount: undefined,
          depositCollectDate: undefined,
          depositReturnDate: undefined,
          phoneNumber: undefined,
          legalPerson: undefined,
          title: undefined,
          validDate: undefined,
          depositBackAmount: undefined,
          payAmount: undefined,
          receiptNum: undefined,
          businessStatus: undefined,
          realControlPerson: undefined,
          authStatus: undefined,
          stockAddress: undefined,
          customType: undefined,
          tms: [],
          shops: []
        },
        contractDate: [],
        rules: {
          authCode: [{ required: true, message: ' ', trigger: 'change' }],
          org: [{ required: true, message: ' ', trigger: 'change' }],
          province: [{ required: true, message: ' ', trigger: 'change' }],
          region: [{ required: true, message: ' ', trigger: 'change' }],
          channel: [{ required: true, message: ' ', trigger: 'change' }],
          shopType: [{ required: true, message: ' ', trigger: 'change' }],
          depositAmount: [{ required: true, message: ' ', trigger: 'change' }],
          signType: [{ required: true, message: ' ', trigger: 'change' }],
          authStatus: [{ required: true, message: ' ', trigger: 'change' }],
        },
        defaultTm: {
          dcCode: undefined,
          mainDcCode: undefined,
          drpTerminalCode: undefined,
          mainDrpTerminalCode: undefined,
        },
        defaultShop: {
          id: undefined,
          shopId: undefined,
          shopName: undefined
        }
      },
      importDialog: {
        type: "main",
        tipsText: "",
        main: {
          visible: false,
          title: "电商科技线上授权导入",
          templateSampleHead: [
            { label: "申请序号", prop: "id", minWidth: "80px" },
            { label: "申请时间", prop: "signTime", minWidth: "80px" },
            { label: "合同起始时间", prop: "contractDateStart", minWidth: "160px" },
            { label: "合同结束时间", prop: "contractDateEnd", minWidth: "160px" },
            { label: "新签/续签", prop: "signType", minWidth: "160px" },
            { label: "区域", prop: "region", minWidth: "80px" },
            { label: "实控人", prop: "realControlPerson", minWidth: "80px" },
            { label: "客户类型", prop: "customType", minWidth: "80px" },
            { label: "授权单位", prop: "intendedCompany", minWidth: "80px" },
            { label: "渠道", prop: "channel", minWidth: "160px" },
            { label: "店铺类型", prop: "shopType", minWidth: "160px" },
            { label: "授权状态", prop: "authStatus", minWidth: "160px" },
            { label: "授权下发时间", prop: "authSendDate", minWidth: "160px" },
            { label: "仓库地址", prop: "stockAddress", minWidth: "160px" },
            { label: "法人", prop: "legalPerson", minWidth: "160px" },
            { label: "联系电话", prop: "phoneNumber", minWidth: "80px" },
            { label: "应收保证金", prop: "depositAmount", minWidth: "80px" },
            { label: "分公司名称", prop: "org", minWidth: "80px" },
            { label: "保证金收取时间", prop: "depositCollectDate", minWidth: "160px" },
            { label: "实收金额", prop: "payAmount", minWidth: "160px" },
            { label: "收据编号", prop: "receiptNum", minWidth: "160px" },
            { label: "保证金退还时间", prop: "depositReturnDate", minWidth: "160px" },
            { label: "保证金实退", prop: "depositBackAmount", minWidth: "160px" },
            { label: "保证金备注", prop: "depositRemark", minWidth: "160px" },
            { label: "原授权编号", prop: "authCode", minWidth: "80px" },
            { label: "其它备注", prop: "remark", minWidth: "80px" },
          ],
          templateSampleLists: [
            {
              id: '00000001',
              signTime: '2023-01-01',
              org: "分公司名称",
              province: "上海",
              region: '上海',
              signType: '新签',
              contractDateStart: '2023-01-01',
              contractDateEnd: '2023-01-01',
              dcCode: 'C122000',
              drpTerminalCode: '007203',
              authCode: '123213',
              intendedCompany: "单位",
              legalPerson: "0001",
              channel: "天猫",
              shopType: '天猫专卖店',
              businessStatus: '是',
              depositAmount: 500,
              phoneNumber: 13492838293,
              shopName: '店名',
              depositCollectDate: '2023-01-01',
              depositReturnDate: '2023-01-01',
              depositRemark: '备注',
              title: '抬头',
              validDate: '有效期至2023-01-02',
              payAmount: 0,
              receiptNum: 'xx-xx',
              depositBackAmount: 123,
            },
          ],
          templateFileDownloadUrl: process.env.VUE_APP_OSS_TEMPLATE_PATH +'/%E7%94%B5%E5%95%86%E7%A7%91%E6%8A%80%E7%BA%BF%E4%B8%8A%E6%8E%88%E6%9D%83%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BFV2.xlsx',
          apiParams: "importList"
        },
        tm: {
          visible: false,
          title: "科技授权终端导入",
          templateSampleHead: [
            { label: "申请序号", prop: "id", minWidth: "80px" },
            { label: "主配送中心编码", prop: "mainDcCode", minWidth: "80px" },
            { label: "主终端编码", prop: "mainDrpTerminalCode", minWidth: "80px" },
            { label: "副配送中心编码", prop: "dcCode", minWidth: "80px" },
            { label: "副终端编码", prop: "drpTerminalCode", minWidth: "80px" },
          ],
          templateSampleLists: [
            {
              id: '00000001',
              mainDcCode: 'C12222',
              mainDrpTerminalCode: "0001",
              dcCode: "C12222",
              drpTerminalCode: '0001',
            },
          ],
          templateFileDownloadUrl: process.env.VUE_APP_OSS_TEMPLATE_PATH +'/%E7%A7%91%E6%8A%80%E6%8E%88%E6%9D%83%E7%BB%88%E7%AB%AF%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx',
          apiParams: "importList"
        },
        shop: {
          visible: false,
          title: "科技授权店铺导入",
          templateSampleHead: [
            { label: "申请序号", prop: "id", minWidth: "80px" },
            { label: "店铺ID", prop: "shopId", minWidth: "80px" },
            { label: "店铺名称", prop: "shopName", minWidth: "80px" },
          ],
          templateSampleLists: [
            {
              id: '00000001',
              shopId: '123',
              shopName: "测试店铺名称",
            },
          ],
          templateFileDownloadUrl: process.env.VUE_APP_OSS_TEMPLATE_PATH +'/%E7%A7%91%E6%8A%80%E6%8E%88%E6%9D%83%E5%BA%97%E9%93%BA%E5%AF%BC%E5%85%A5.xlsx',
          apiParams: "importList"
        },

      },
      noticeDialog: {
        visible: false,
        msg: undefined,
      },
      tmDialog: {
        visible: false,
        type: 'tm',
        tmForm: {
          id: undefined,
          dcCode: undefined,
          mainDcCode: undefined,
          drpTerminalCode: undefined,
          mainDrpTerminalCode: undefined,
          status: 0
        },
        rules: {
          mainDrpTerminalCode: [{ required: true, message: ' ', trigger: 'change' }],
          drpTerminalCode: [{ required: true, message: ' ', trigger: 'change' }],
          mainDcCode: [{ required: true, message: ' ', trigger: 'change' }],
          dcCode: [{ required: true, message: ' ', trigger: 'change' }],
          shopId: [{ required: true, message: ' ', trigger: 'change' }],
          shopName: [{ required: true, message: ' ', trigger: 'change' }],
        },
        shopForm: {
          id: undefined,
          shopId: undefined,
          shopName: undefined
        }
      },
      currentRow: {},
      options: {
        channels: [],
        channelObj : {},
        shopTypes: [],
        cities: [],
        signTypeOptions: [],
        depositOptions: [],
        customTypes: ["线上分销","分公司自营","科技自营","科技直供","精品直供","办公直供"]
      },
      parseTime: parseTime,
      logDrawer: {
        visible: false,
        loading: false,
        size: 10,
        current: 1,
        total: 0,
        logs: []
      },
      exportHeaders: {
        tm:{
          id: '申请序号',
          mainDcCode: '主配送中心编码',
          mainDrpTerminalCode: "主终端编码",
          dcCode: "副配送中心编码",
          drpTerminalCode: '副终端编码',
          errorMsg: '校验结果',
        },
        shop: {
          id: '申请序号',
          shopId: '店铺ID',
          shopName: "店铺名称",
          errorMsg: '校验结果',
        }
      },
      currentUser: undefined,
      auditUsers: ['wangsiyong', 'qinpangchao', 'zhouchijun','zhouchenguang','lijing04','chenyi']
    };
  },
  watch: {
  },
  created() {
    getCurrentUser().then(res => {
      this.currentUser = res.data
    })
    getTechBaseOptions().then(res => {
      this.options.cities = res.data.cities;
      for(const key in res.data.channels) {
        this.options.channels.push(key);
      }
      this.options.channelObj = res.data.channels;
      this.options.depositOptions = res.data.depositOptions;
      this.options.signTypeOptions = res.data.signTypeOptions;
      this.options.authStatuss = res.data.authStatuss;
      this.options.customTypes = res.data.customTypes;
      this.$forceUpdate();
      this.dictFun("province");
    })
  },
  mounted() {
    this.handleFilter();
  },
  methods: {
    handleDateChange(val, type) {
      if ('contract' === type) {
        if (val) {
          this.queryForm.contractDateEndS = parseTime(val[0], "{y}-{m}-{d}");
          this.queryForm.contractDateEndE = parseTime(val[1], "{y}-{m}-{d}");
        } else {
          this.queryForm.contractDateEndS = undefined;
          this.queryForm.contractDateEndE = undefined;
        }
      } else if (type === 'sign') {
        if (val) {
          this.queryForm.signTimeStart = parseTime(val[0], "{y}-{m}-{d} {h}:{i}:{s}");
          this.queryForm.signTimeEnd = parseTime(val[1], "{y}-{m}-{d} {h}:{i}:{s}");
        } else {
          this.queryForm.signTimeStart = undefined;
          this.queryForm.signTimeEnd = undefined;
        }
      } else if (type === 'save') {
        if (val) {
          this.editDialog.form.contractDateStart = parseTime(val[0], "{y}-{m}-{d}");
          this.editDialog.form.contractDateEnd = parseTime(val[1], "{y}-{m}-{d}");
        } else {
          this.editDialog.form.contractDateStart = undefined;
          this.editDialog.form.contractDateEnd = undefined;
        }
      }
    },
    handleQuery() {
      this.loading = true;
      const query = Object.assign({}, this.queryForm);
      pageTechData(query).then(res => {
        if (res.code !== 200) {
          return false;
        }
        this.loading = false;
        this.pageData.rows = res.data?.records;
        this.pageData.total = res.data?.total;
        this.$nextTick(() => {
          this.$refs.tableRef.doLayout();
        })
      });
    },
    handleFilter() {
      this.queryForm.current = 1;
      this.handleQuery();
    },
    resetQuery() {
      const queryForm = {
        current: 1,
        size: 10,
        id: undefined,
        org: undefined,
        province: undefined,
        region: undefined,
        signType: undefined,
        channel: undefined,
        shopType: undefined,
        depositStatus: undefined,
        contractDateStart: undefined,
        contractDateEnd: undefined,
        shopName: undefined,
        drpTerminalCode: undefined,
        authCode: undefined,
        depositRemark: undefined,
        intendedCompany: undefined,
        depositAmount: undefined,
        depositCollectDate: undefined,
        depositReturnDate: undefined,
        phoneNumber: undefined,
        legalPerson: undefined,
        title: undefined,
        validDate: undefined,
        receiptNum: undefined,
        businessStatus: undefined,
        authStatus: undefined,
        stockAddress: undefined,
        customType: undefined,
        realControlPerson: undefined,
        tms: [],
        shops: []
      };
      this.queryForm = Object.assign({}, queryForm);
      this.$forceUpdate();
    },
    resetSaveForm() {
      const form = {
        id: undefined,
        authCode:undefined,
        signTime: undefined,
        org: undefined,
        province: undefined,
        region: undefined,
        signType: undefined,
        contractDateStart: undefined,
        contractDateEnd: undefined,
        dcCode:null,
        drpTerminalCode: undefined,
        intendedCompany: undefined,
        legalPerson: undefined,
        channel: undefined,
        shopType: undefined,
        depositAmount: undefined,
        phoneNumber: undefined,
        shopName: undefined,
        depositStatus: undefined,
        depositCollectDate: undefined,
        depositReturnDate:null,
        depositRemark:  undefined,
        title: undefined,
        validDate: undefined,
        depositBackAmount: undefined,
        payAmount: undefined,
        receiptNum: undefined,
        businessStatus: undefined,
        authStatus: undefined,
        stockAddress: undefined,
        customType: undefined,
        realControlPerson: undefined,
        tms: [],
        shops: []
      };
      this.editDialog.form = Object.assign({}, form);
      this.$forceUpdate();
    },
    handleSortChange(data) {
      const { prop, order } = data;
      if (prop && order) {
        this.queryForm.sorts = order === "ascending" ? prop + " asc" : prop + " desc";
      } else {
        this.queryForm.sorts = undefined;
      }
      this.handleFilter();
    },
    dictFun(data, form) {
      return new Promise((resolve, reject) => {
        let res = { data: [], code: 200};
        let respData = [];
        if (data.indexOf('province')  === 0) {
          respData = this.options.cities.map(e => e.province + e.city);
        } else if (data.indexOf('region') === 0 || data.indexOf('regionSave') === 0) {
          respData = this.options.cities.filter(e => e.province === form.province).map(e => e.city);
        } else if (data.indexOf('org') === 0 || data.indexOf('orgSave') === 0) {
          respData =this.options.cities.filter(e => e.province+e.city === form.region).map(e => e.orgName);
        } else if (data.indexOf('shopType') === 0 || data.indexOf('shopTypeSave') === 0) {
          if (form.channel && this.options.channelObj[form.channel]) {
            respData = this.options.channelObj[form.channel];
          }
        }
        resolve(Object.assign(res, { data: Array.from(new Set(respData))} ));
      })
    },
    dictSaveFun(data) {
      return this.dictFun(data, this.editDialog.form);
    },
    dictQueryFun(data) {
      return this.dictFun(data, this.queryForm);
    },
    handleChangeLinkageRef(ref, val, type) {
      this.$nextTick(() => {
        ref.split(',').forEach(e => {
          if (this.$refs[e]) {
            setTimeout(() => {
              this.$refs[e].handleRefChange(val, type);
            }, 100)
            this.$forceUpdate();
          }
        })
      });
    },
    handleSelectionChange(selections) {
      this.pageData.selections = selections;
    },
    handleImport(type) {
      this.importDialog.type = type;
      this.importDialog[type].visible = true;
    },
    handleImportData(val) {
      this.importDialog.visible = false;
      this.loading = true;
      const data = {
        list: Object.assign([], val["importList"])
      }
      let fun = importTechData;
      if (this.importDialog.type === 'tm') {
        fun = importTechTmData;
      } else if (this.importDialog.type === 'shop') {
        fun = importTechShopData;
      }
      fun(data).then(res => {
        if (res.code === 200) {
          if (this.importDialog.type == 'tm' || this.editDialog.type === 'shop') {
            if (res.data.filter(e => e.errorMsg).length > 0) {
              let title = '电商科技授权终端导入校验明细';
              let headers = this.exportHeaders.tm;
              if (this.importDialog.type === 'shop') {
                headers = this.exportHeaders.shop;
                title = '电商科技授权店铺导入校验明细';
              }
              Notification.warning("存在错误数据正在导出！！");
              excelExport({
                topHeaders: headers,
                data: res.data,
                title: title,
              });
            } else {
              Notification.success("操作成功！");
              this.handleFilter();
            }
          } else {
            if (res && res.msg.indexOf("很抱歉") > -1) {
              this.noticeDialog.msg = res.msg;
              this.noticeDialog.visible = true;
            } else {
              this.noticeDialog.msg = res.msg;
              this.noticeDialog.visible = true;
              this.importDialog[this.importDialog.type].visible = false;
              this.handleFilter();
            }
          }

        } else {
          this.loading = false;
          // Message.error(res.msg);
        }
      });
    },
    handleEdit(row) {
      this.resetSaveForm();
      if (row) {
        if (row.id) {
          this.currentRow = Object.assign({}, row);
          getDetailTech(row.id).then(res => {
            this.editDialog.form = res.data;
            this.editDialog.contractDate = [];
            if (this.editDialog.form.contractDateStart) {
              this.editDialog.contractDate = [row.contractDateStart, row.contractDateEnd];
            }
            this.$forceUpdate();
            this.editDialog.visible = true;
          })
        } else {
          this.currentRow = Object.assign({}, row);
          this.editDialog.form = this.currentRow;
          this.editDialog.contractDate = [];
          if (this.editDialog.form.contractDateStart) {
            this.editDialog.contractDate = [row.contractDateStart, row.contractDateEnd];
          }
          this.$forceUpdate();
          this.editDialog.visible = true;
        }
      } else {
        this.editDialog.visible = true;
      }
    },
    submitEdit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          saveTechData(this.editDialog.form).then(res => {
            if (res && res.msg.indexOf("很抱歉") > -1) {
              this.noticeDialog.msg = res.msg;
              this.noticeDialog.visible = true;
            } else if (res.code===200) {
              this.$message.success("操作成功！");
              this.editDialog.visible = false
              this.handleFilter();
            }
          })
        }
      })

    },
    handleExport(type) {
      this.loading = true;
      const param = Object.assign({}, this.queryForm, {size: 100000});
      exportTechData(type, param).then(res => {
        this.loading = false;
        if (!res) {
          return
        }
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = window.URL.createObjectURL(new Blob([res]))
        let title = '';
        switch (type) {
          case '0':
            title = '电商科技线上授权后台导出';
            break;
          case '1':
            title = '电商科技线上授权终端数据导出';
            break;
          case '2':
            title = '电商科技线上授权店铺数据导出';
            break;
        }
        link.setAttribute('download', title + '.xlsx')
        document.body.appendChild(link)
        link.click()
        link.remove()
      })
    },
    handleDel(row) {
      let delParam = [];
      if (row && row.id) {
        delParam.push(row.id);
      } else if (this.pageData.selections.length > 0) {
        delParam = Object.assign([], this.pageData.selections.map(e => e.id));
      } else {
        Notification.warning("请至少选择一条数据进行操作！");
        return;
      }
      this.$confirm('请确认是否删除所选的数据', '提示', { type: 'warning' }).then(() => {
        deleteTechData(delParam).then((res) => {
          if (res.code === 200) {
            Notification.success("操作成功！");
            this.handleFilter();
          }
        })
      }).catch(() => {
      })
    },
    handleViewLog(row) {
      if (row) {
        this.currentRow = row;
        this.logDrawer.visible = true;
      }
      const param = { size: this.logDrawer.size, current: this.logDrawer.current, type: 'tech', uk: this.currentRow.id}
      pageActionLog(param).then((res) => {
        if (res && res.code === 200) {
          this.logDrawer.total = res.data.total;
          this.logDrawer.logs = res.data.records;
        }
      })
    },
    handleCopy(row) {
      this.resetSaveForm();
      let tmpRow = {
        id: undefined,
        authCode:undefined,
        signTime: undefined,
        org: row.org,
        province: undefined,
        region: row.region,
        signType: undefined,
        contractDateStart: row.contractDateStart,
        contractDateEnd: row.contractDateEnd,
        dcCode:null,
        drpTerminalCode: undefined,
        intendedCompany: row.intendedCompany,
        legalPerson: undefined,
        channel: undefined,
        shopType: undefined,
        depositAmount: undefined,
        phoneNumber: row.phoneNumber,
        shopName: undefined,
        depositStatus: '已退回',
        depositCollectDate: undefined,
        depositReturnDate:null,
        depositRemark:  undefined,
        title: undefined,
        validDate: undefined,
        depositBackAmount: undefined,
        payAmount: 0,
        receiptNum: undefined,
        businessStatus: undefined,
        authStatus: undefined,
        stockAddress: row.stockAddress,
        realControlPerson: row.realControlPerson,
        customType: row.customType,
        tms: row.tms,
        shops: []
      }
      this.handleEdit(tmpRow);
    },
    handleAuditTm(row, status) {
      let tmpRow = deepClone(row);
      tmpRow.status = status;
      auditTechTm(tmpRow).then(res => {
        Notification.success("操作成功！");
        row.status = status;
      })
    },
    handleEditRow(rowKey, idex, row) {
      this.editDialog[rowKey] = idex;
      if (rowKey === 'currentTmIndex') {
        this.tmDialog.tmForm = deepClone(row);
        this.tmDialog.type = 'tm'
      } else {
        this.tmDialog.shopForm = deepClone(row);
        this.tmDialog.type = 'shop'
      }
      this.tmDialog.visible = true;
    },
    handleAddRow(key) {
      if (key === 'tms') {
        this.tmDialog.tmForm = {
          id: undefined,
          dcCode: undefined,
          mainDcCode: undefined,
          drpTerminalCode: undefined,
          mainDrpTerminalCode: undefined,
          status: 0
        }
        this.tmDialog.type = 'add-tm'
      } else {
        this.tmDialog.shopForm = {
          id: undefined,
          shopId: undefined,
          shopName: undefined
        }
        this.tmDialog.type = 'add-shop'
      }
      this.tmDialog.visible = true;
    },
    submitForm() {
      this.$refs.tmForm.validate(v => {
        if (v) {
          if (this.tmDialog.type.indexOf('tm') > -1) {
            if (this.editDialog.form.tms.filter(e => e.dcCode+'_'+e.drpTerminalCode+'_'+e.mainDcCode+'_'+e.mainDrpTerminalCode
              === this.tmDialog.tmForm.dcCode+'_'+this.tmDialog.tmForm.drpTerminalCode+'_'+this.tmDialog.tmForm.mainDcCode+'_'+this.tmDialog.tmForm.mainDrpTerminalCode).length>0) {
              this.$message.warning("已存在，请勿重复添加！");
              return;
            }
          }
          switch (this.tmDialog.type) {
            case 'tm':
              this.editDialog.form.tms[this.editDialog.currentTmIndex] = deepClone(this.tmDialog.tmForm);
              break;
            case 'add-tm':
              this.editDialog.form.tms.push(deepClone(this.tmDialog.tmForm))
              break;
            case 'shop':
              this.editDialog.form.tms[this.editDialog.currentShopIndex] = deepClone(this.tmDialog.shopForm);
              break;
            case 'add-shop':
              this.editDialog.form.shops.push(deepClone(this.tmDialog.shopForm))
              break;
          }
          this.tmDialog.visible = false;
          this.$forceUpdate();
          this.$refs.tmTable.doLayout();
          this.$refs.shopTable.doLayout();
        }
      })
    },
    handleFormChange() {
      this.tmDialog.tmForm.status = '0';
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding-top: 10px;
  padding-bottom: 5px;
}

::v-deep .el-table__header .el-table-column--selection .cell .el-checkbox:after {
  content: "本页全选";
  color: black;
  font-weight: bold;
  font-size: 12px;
  margin-left: 5px;
}

.form-title {
  font-size: 20px;
  font-weight: 500;
}
</style>

<style lang="scss" scoped src='@/style/common/index.scss'></style>
