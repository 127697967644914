var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "new-box" },
    [
      _c(
        "div",
        { staticClass: "mg-search" },
        [
          _c(
            "el-card",
            { staticStyle: { margin: "10px" } },
            [
              _c(
                "SearchFilter",
                {
                  attrs: { "label-width": "150px" },
                  on: {
                    search: function ($event) {
                      _vm.FormData.page = 1
                      _vm.handleFilter()
                    },
                    reset: _vm.resetQuery,
                  },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      _vm.FormData.page = 1
                      _vm.handleFilter()
                    },
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "月份" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.FormData.mth,
                            callback: function ($$v) {
                              _vm.$set(_vm.FormData, "mth", $$v)
                            },
                            expression: "FormData.mth",
                          },
                        },
                        _vm._l(_vm.mth, function (item) {
                          return _c("el-option", {
                            key: item.code,
                            attrs: { label: item.name, value: item.code },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "是否显示近3月增长率" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.growthRateShow,
                            callback: function ($$v) {
                              _vm.growthRateShow = $$v
                            },
                            expression: "growthRateShow",
                          },
                        },
                        [
                          _c(
                            "el-option",
                            { attrs: { label: "否", value: 1 } },
                            [_vm._v(" 否 ")]
                          ),
                          _c(
                            "el-option",
                            { attrs: { label: "是", value: 0 } },
                            [_vm._v(" 是")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-card",
        { staticClass: "box-card", staticStyle: { margin: "10px" } },
        [
          _c(
            "el-button",
            {
              attrs: {
                type: "primary",
                size: "small",
                loading: _vm.exportLoading,
              },
              on: { click: _vm.download },
            },
            [_vm._v("导出")]
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              key: _vm.getRandom(20),
              ref: "table",
              staticStyle: { width: "100%", "margin-top": "10px" },
              attrs: {
                data: _vm.tableData,
                border: "",
                size: "small",
                "cell-style": _vm.columnStyle,
                "header-cell-style": {
                  "text-align": "center",
                  background: "#eef1f6",
                },
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  fixed: "",
                  prop: "newRaceName",
                  label: "赛道",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              cursor: "pointer",
                              color: "#409EFF",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.jumpToTheMiddleClassDimension(
                                  scope.row.newRaceName
                                )
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(scope.row.newRaceName) + " ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c(
                "el-table-column",
                {
                  attrs: {
                    label: `整体${_vm.startMonth}-${_vm.endMonth}月销售（万元）`,
                    align: "center",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "goal", label: "目标", sortable: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  scope.row.goal
                                    ? Math.round(scope.row.goal)
                                    : "-"
                                ) +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "wcl", label: "完成率", sortable: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              {
                                style: {
                                  color: scope.row.wcl < 0 ? "red" : "",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      scope.row.wcl
                                        ? Math.round(scope.row.wcl * 100) + "%"
                                        : "-"
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "bnJhTotal", label: "本年", sortable: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(Math.round(scope.row.bnJhTotal)) +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "zl", label: "同比增量", sortable: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              {
                                style: { color: scope.row.zl < 0 ? "red" : "" },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(Math.round(scope.row.zl)) + " "
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "zzl", label: "增长率", sortable: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              {
                                style: {
                                  color: _vm.formatPercentage(scope.row, "zzl")
                                    .color,
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.formatPercentage(scope.row, "zzl")
                                        .percentage
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm.growthRateShow == 0
                    ? _c("el-table-column", {
                        attrs: {
                          prop: "zzlSsy",
                          label: "上上月",
                          sortable: "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      style: {
                                        color: _vm.formatPercentage(
                                          scope.row,
                                          "zzlSsy"
                                        ).color,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.formatPercentage(
                                              scope.row,
                                              "zzlSsy"
                                            ).percentage
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1371518067
                        ),
                      })
                    : _vm._e(),
                  _vm.growthRateShow == 0
                    ? _c("el-table-column", {
                        attrs: { prop: "zzlSy", label: "上月", sortable: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      style: {
                                        color: _vm.formatPercentage(
                                          scope.row,
                                          "zzlSy"
                                        ).color,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.formatPercentage(
                                              scope.row,
                                              "zzlSy"
                                            ).percentage
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1008091507
                        ),
                      })
                    : _vm._e(),
                  _vm.growthRateShow == 0
                    ? _c("el-table-column", {
                        attrs: { prop: "zzlBy", label: "本月", sortable: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      style: {
                                        color: _vm.formatPercentage(
                                          scope.row,
                                          "zzlBy"
                                        ).color,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.formatPercentage(
                                              scope.row,
                                              "zzlBy"
                                            ).percentage
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2261262259
                        ),
                      })
                    : _vm._e(),
                  _c("el-table-column", {
                    attrs: { label: "近3月趋势", width: "120" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("TrendCharts", {
                              attrs: {
                                first: scope.row.zzlSsy,
                                second: scope.row.zzlSy,
                                third: scope.row.zzlBy,
                                month: _vm.years,
                                easyKey: _vm.getRandom(20),
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "el-table-column",
                {
                  attrs: {
                    label: `线下${_vm.startMonth}-${_vm.endMonth}月销售（万元）`,
                    align: "center",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "bnOfflineJhTotal",
                      label: "本年",
                      sortable: "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(Math.round(scope.row.bnOfflineJhTotal)) +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "offlineZb",
                      label: "销售占比",
                      sortable: "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              {
                                style: {
                                  color: _vm.formatPercentage(
                                    scope.row,
                                    "offlineZb"
                                  ).color,
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.formatPercentage(
                                        scope.row,
                                        "offlineZb"
                                      ).percentage
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "offlineZl",
                      label: "同比增量",
                      sortable: "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              {
                                style: {
                                  color: scope.row.offlineZl < 0 ? "red" : "",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(Math.round(scope.row.offlineZl)) +
                                    " "
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "offlineZlZb",
                      label: "增量占比",
                      sortable: "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              {
                                style: {
                                  color: _vm.formatPercentage(
                                    scope.row,
                                    "offlineZlZb"
                                  ).color,
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.formatPercentage(
                                        scope.row,
                                        "offlineZlZb"
                                      ).percentage
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "offlineZzl",
                      label: "增长率",
                      sortable: "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              {
                                style: {
                                  color: _vm.formatPercentage(
                                    scope.row,
                                    "offlineZzl"
                                  ).color,
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.formatPercentage(
                                        scope.row,
                                        "offlineZzl"
                                      ).percentage
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm.growthRateShow == 0
                    ? _c("el-table-column", {
                        attrs: {
                          prop: "offlineZzlSsy",
                          label: "上上月",
                          sortable: "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      style: {
                                        color: _vm.formatPercentage(
                                          scope.row,
                                          "offlineZzlSsy"
                                        ).color,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.formatPercentage(
                                              scope.row,
                                              "offlineZzlSsy"
                                            ).percentage
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          3434581235
                        ),
                      })
                    : _vm._e(),
                  _vm.growthRateShow == 0
                    ? _c("el-table-column", {
                        attrs: {
                          prop: "offlineZzlSy",
                          label: "上月",
                          sortable: "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      style: {
                                        color: _vm.formatPercentage(
                                          scope.row,
                                          "offlineZzlSy"
                                        ).color,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.formatPercentage(
                                              scope.row,
                                              "offlineZzlSy"
                                            ).percentage
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1377484851
                        ),
                      })
                    : _vm._e(),
                  _vm.growthRateShow == 0
                    ? _c("el-table-column", {
                        attrs: {
                          prop: "offlineZzlBy",
                          label: "本月",
                          sortable: "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      style: {
                                        color: _vm.formatPercentage(
                                          scope.row,
                                          "offlineZzlBy"
                                        ).color,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.formatPercentage(
                                              scope.row,
                                              "offlineZzlBy"
                                            ).percentage
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          715495443
                        ),
                      })
                    : _vm._e(),
                  _c("el-table-column", {
                    attrs: { label: "近3月趋势", width: "120" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("TrendCharts", {
                              attrs: {
                                first: scope.row.offlineZzlSsy,
                                month: _vm.years,
                                second: scope.row.offlineZzlSy,
                                third: scope.row.offlineZzlBy,
                                easyKey: _vm.getRandom(20),
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "el-table-column",
                {
                  attrs: {
                    label: `线上${_vm.startMonth}-${_vm.endMonth}月销售（万元）`,
                    align: "center",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "bnOnlineJhTotal",
                      label: "本年",
                      sortable: "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(Math.round(scope.row.bnOnlineJhTotal)) +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "onlineZb",
                      label: "销售占比",
                      sortable: "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              {
                                style: {
                                  color: _vm.formatPercentage(
                                    scope.row,
                                    "onlineZb"
                                  ).color,
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.formatPercentage(
                                        scope.row,
                                        "onlineZb"
                                      ).percentage
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "onlineZl",
                      label: "同比增量",
                      sortable: "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              {
                                style: {
                                  color: scope.row.onlineZl < 0 ? "red" : "",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(Math.round(scope.row.onlineZl)) +
                                    " "
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "onlineZlZb",
                      label: "增量占比",
                      sortable: "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              {
                                style: {
                                  color: _vm.formatPercentage(
                                    scope.row,
                                    "onlineZlZb"
                                  ).color,
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.formatPercentage(
                                        scope.row,
                                        "onlineZlZb"
                                      ).percentage
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "onlineZzl", label: "增长率", sortable: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              {
                                style: {
                                  color: _vm.formatPercentage(
                                    scope.row,
                                    "onlineZzl"
                                  ).color,
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.formatPercentage(
                                        scope.row,
                                        "onlineZzl"
                                      ).percentage
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm.growthRateShow == 0
                    ? _c("el-table-column", {
                        attrs: {
                          prop: "onlineZzlSsy",
                          label: "上上月",
                          sortable: "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      style: {
                                        color: _vm.formatPercentage(
                                          scope.row,
                                          "onlineZzlSsy"
                                        ).color,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.formatPercentage(
                                              scope.row,
                                              "onlineZzlSsy"
                                            ).percentage
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2848027475
                        ),
                      })
                    : _vm._e(),
                  _vm.growthRateShow == 0
                    ? _c("el-table-column", {
                        attrs: {
                          prop: "onlineZzlSy",
                          label: "上月",
                          sortable: "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      style: {
                                        color: _vm.formatPercentage(
                                          scope.row,
                                          "onlineZzlSy"
                                        ).color,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.formatPercentage(
                                              scope.row,
                                              "onlineZzlSy"
                                            ).percentage
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1758063027
                        ),
                      })
                    : _vm._e(),
                  _vm.growthRateShow == 0
                    ? _c("el-table-column", {
                        attrs: {
                          prop: "onlineZzlBy",
                          label: "本月",
                          sortable: "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      style: {
                                        color: _vm.formatPercentage(
                                          scope.row,
                                          "onlineZzlBy"
                                        ).color,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.formatPercentage(
                                              scope.row,
                                              "onlineZzlBy"
                                            ).percentage
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2228481523
                        ),
                      })
                    : _vm._e(),
                  _c("el-table-column", {
                    attrs: { label: "近3月趋势", width: "120" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("TrendCharts", {
                              attrs: {
                                first: scope.row.onlineZzlSsy,
                                month: _vm.years,
                                second: scope.row.onlineZzlSy,
                                third: scope.row.onlineZzlBy,
                                easyKey: _vm.getRandom(20),
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("Pagination", {
            attrs: {
              total: _vm.total,
              page: _vm.FormData.page,
              limit: _vm.FormData.limit,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.FormData, "page", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.FormData, "limit", $event)
              },
              pagination: _vm.handleFilter,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }