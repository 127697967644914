var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "new-box" },
    [
      _c(
        "div",
        { staticClass: "mg-search" },
        [
          _c(
            "el-card",
            { staticStyle: { margin: "10px" } },
            [
              _c(
                "SearchFilter",
                {
                  on: { search: _vm.handleQuery, reset: _vm.resetQuery },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.handleQuery.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "分公司" } },
                    [
                      _c("DictSelect", {
                        ref: "org",
                        attrs: {
                          type: "ht-org",
                          value: _vm.queryForm.orgCode,
                          linkageRef: "dc",
                          dictFun: _vm.dictFun,
                          fullLabel: "",
                          init: true,
                          "init-options": this.options.orgOptions,
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(_vm.queryForm, "orgCode", $event)
                          },
                          changeLinkageRef: _vm.handleChangeLinkageRef,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "配送中心" } },
                    [
                      _c("DictSelect", {
                        ref: "dc",
                        attrs: {
                          type: "ht-dc",
                          value: _vm.queryForm.dcCode,
                          dictFun: _vm.dictFun,
                          parentLinkageRef: "",
                          fullLabel: "",
                          "collapse-tags": "",
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(_vm.queryForm, "dcCode", $event)
                          },
                          getOtherJsonParam: (callback) =>
                            callback({ orgCode: this.queryForm.orgCode }),
                          changeLinkageRef: _vm.handleChangeLinkageRef,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "终端" } },
                    [
                      _c(
                        "el-select",
                        {
                          ref: "shop",
                          attrs: {
                            type: "ht-shop",
                            filterable: "",
                            "filter-method": _vm.filterShop,
                          },
                          on: { change: _vm.handleFilter },
                          model: {
                            value: _vm.queryForm.shopName,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryForm, "shopName", $$v)
                            },
                            expression: "queryForm.shopName",
                          },
                        },
                        _vm._l(_vm.options.shopOptions, function (item) {
                          return _c("el-option", {
                            key: item.fullCode,
                            attrs: {
                              value: item.label,
                              label: item.value + "-" + item.label,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品编码" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "商品编码" },
                        model: {
                          value: _vm.queryForm.skuCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryForm, "skuCode", $$v)
                          },
                          expression: "queryForm.skuCode",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "商品型号" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "商品型号" },
                        model: {
                          value: _vm.queryForm.skuModel,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryForm, "skuModel", $$v)
                          },
                          expression: "queryForm.skuModel",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "整单结案状态" } },
                    [
                      _c(
                        "el-select",
                        {
                          model: {
                            value: _vm.queryForm.jaStatus,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryForm, "jaStatus", $$v)
                            },
                            expression: "queryForm.jaStatus",
                          },
                        },
                        _vm._l(
                          _vm.options.orderCaseStatusOptions,
                          function (item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.label, value: item.value },
                            })
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "整单结案原因" } },
                    [
                      _c(
                        "el-select",
                        {
                          model: {
                            value: _vm.queryForm.jaReason,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryForm, "jaReason", $$v)
                            },
                            expression: "queryForm.jaReason",
                          },
                        },
                        _vm._l(
                          _vm.options.orderCaseReasonQueryOptions,
                          function (item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.label, value: item.value },
                            })
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "订单状态" } },
                    [
                      _c(
                        "el-select",
                        {
                          model: {
                            value: _vm.queryForm.orderStatus,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryForm, "orderStatus", $$v)
                            },
                            expression: "queryForm.orderStatus",
                          },
                        },
                        _vm._l(_vm.options.orderStatusOptions, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "订单号" } },
                    [
                      _c("el-input", {
                        attrs: { size: "mini", placeholder: "订单号" },
                        model: {
                          value: _vm.queryForm.orderNo,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryForm, "orderNo", $$v)
                          },
                          expression: "queryForm.orderNo",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "下单时间" } },
                    [
                      _c("el-date-picker", {
                        staticStyle: { width: "350px !important" },
                        attrs: {
                          type: "datetimerange",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          "range-separator": "至",
                          "start-placeholder": "开始时间",
                          "end-placeholder": "结束时间",
                        },
                        model: {
                          value: _vm.queryForm.timeRange,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryForm, "timeRange", $$v)
                          },
                          expression: "queryForm.timeRange",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-card",
        { staticClass: "box=card", staticStyle: { margin: "10px" } },
        [
          _c(
            "el-button",
            {
              staticClass: "form-btn-item",
              attrs: {
                size: "mini",
                icon: "el-icon-download",
                type: "primary",
              },
              on: { click: _vm.handleExport },
            },
            [_vm._v("导出")]
          ),
          _c(
            "el-button",
            {
              staticClass: "form-btn-item",
              attrs: { size: "mini", icon: "el-icon-upload", type: "primary" },
              on: { click: _vm.openFh },
            },
            [_vm._v("批量发货")]
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "table",
              staticClass: "customer-table",
              staticStyle: { "margin-top": "10px" },
              attrs: {
                data: _vm.pageData.rows,
                border: "",
                size: "small",
                "highlight-current-row": "",
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "订单状态", prop: "orderStatus", width: "120" },
              }),
              _c("el-table-column", {
                attrs: { label: "下单时间", prop: "createTime", width: "200" },
              }),
              _c("el-table-column", {
                attrs: { label: "订单号", prop: "orderNo", width: "200" },
              }),
              _c("el-table-column", {
                attrs: { label: "订单金额", prop: "orderMoney", width: "120" },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "整单结案状态",
                  prop: "jaStatus",
                  width: "140",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "整单结案原因", prop: "reason", width: "140" },
              }),
              _c("el-table-column", {
                attrs: { label: "备注", prop: "remark", width: "200" },
              }),
              _c("el-table-column", {
                attrs: { label: "终端编码", prop: "shopCode", width: "120" },
              }),
              _c("el-table-column", {
                attrs: { label: "终端名称", prop: "shopName", width: "140" },
              }),
              _c("el-table-column", {
                attrs: { label: "下单人", prop: "createUser", width: "140" },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "下单人手机号",
                  prop: "phoneNumber",
                  width: "140",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "配送中心编码", prop: "dcCode", width: "140" },
              }),
              _c("el-table-column", {
                attrs: { label: "配送中心名称", prop: "dcName", width: "140" },
              }),
              _c("el-table-column", {
                attrs: { label: "分公司编码", prop: "orgCode", width: "140" },
              }),
              _c("el-table-column", {
                attrs: { label: "分公司名称", prop: "orgName", width: "140" },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  width: "120",
                  fixed: "right",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "div",
                          [
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 6 } },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        staticStyle: { border: "0" },
                                        attrs: { size: "mini", type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.openDetail(row)
                                          },
                                        },
                                      },
                                      [_vm._v("查看明细")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("Pagination", {
            attrs: {
              total: _vm.pageData.total,
              page: _vm.queryForm.current,
              pageSizes: [50, 100, 200],
              limit: _vm.queryForm.size,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.queryForm, "current", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.queryForm, "size", $event)
              },
              pagination: _vm.handleQuery,
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "订单明细",
            visible: _vm.skuDetail.visible,
            "append-to-body": "",
            width: "1000px",
            "close-on-click-modal": false,
            "max-height": "400px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.skuDetail, "visible", $event)
            },
            close: _vm.handleCancel,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "queryFormRef",
              attrs: {
                size: "mini",
                "label-width": "auto",
                inline: true,
                model: _vm.skuDetail.queryForm,
              },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.handleQuerySkuDetail.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "商品编码" } },
                [
                  _c("el-input", {
                    attrs: {
                      size: "mini",
                      clearable: "",
                      placeholder: "输入商品编码",
                    },
                    model: {
                      value: _vm.skuDetail.queryForm.skuCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.skuDetail.queryForm, "skuCode", $$v)
                      },
                      expression: "skuDetail.queryForm.skuCode",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "商品名称" } },
                [
                  _c("el-input", {
                    attrs: {
                      size: "mini",
                      clearable: "",
                      placeholder: "输入商品名称",
                    },
                    model: {
                      value: _vm.skuDetail.queryForm.skuName,
                      callback: function ($$v) {
                        _vm.$set(_vm.skuDetail.queryForm, "skuName", $$v)
                      },
                      expression: "skuDetail.queryForm.skuName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "单品结案状态" } },
                [
                  _c(
                    "el-select",
                    {
                      model: {
                        value: _vm.skuDetail.queryForm.jaStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.skuDetail.queryForm, "jaStatus", $$v)
                        },
                        expression: "skuDetail.queryForm.jaStatus",
                      },
                    },
                    _vm._l(_vm.options.orderCaseStatusOptions, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "form-btn-item",
                      attrs: {
                        size: "mini",
                        icon: "el-icon-search",
                        type: "primary",
                      },
                      on: { click: _vm.handleQuerySkuDetail },
                    },
                    [_vm._v("查询")]
                  ),
                  _vm.skuDetail.operationVisible
                    ? _c(
                        "el-button",
                        {
                          staticClass: "form-btn-item",
                          attrs: {
                            size: "mini",
                            icon: "el-icon-circle-check",
                            type: "danger",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.openCloseCase("batchSingle", null)
                            },
                          },
                        },
                        [_vm._v("批量单品结案")]
                      )
                    : _vm._e(),
                  _vm.skuDetail.operationVisible
                    ? _c(
                        "el-button",
                        {
                          staticClass: "form-btn-item",
                          attrs: {
                            size: "mini",
                            icon: "el-icon-success",
                            type: "danger",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.openCloseCase("order", null)
                            },
                          },
                        },
                        [_vm._v("整单结案")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.skuDetail.loading,
                  expression: "skuDetail.loading",
                },
              ],
              attrs: {
                data: _vm.skuDetail.pageData.rows,
                size: "mini",
                height: "300px",
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "30" },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "商品编码",
                  prop: "skuCode",
                  width: "100",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "属性",
                  prop: "attributeName",
                  width: "60",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "商品型号",
                  prop: "skuModel",
                  width: "80",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "商品名",
                  prop: "skuName",
                  "min-width": "200",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "单价",
                  prop: "finalPrice",
                  width: "40",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "下单数量",
                  prop: "orderNum",
                  width: "80",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "发货数量",
                  prop: "fhNum",
                  width: "80",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "未发数量",
                  prop: "wfNum",
                  width: "80",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("span", [_vm._v(_vm._s(row.orderNum - row.fhNum))]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "结案状态",
                  prop: "jaStatus",
                  width: "80",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "单品结案原因",
                  prop: "reason",
                  width: "150",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "操作", width: "60", fixed: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        row.jaStatus === "未结案" &&
                        _vm.skuDetail.operationVisible
                          ? _c(
                              "el-button",
                              {
                                staticStyle: { border: "0" },
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.openCloseCase("single", row)
                                  },
                                },
                              },
                              [_vm._v("单品结案")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("Pagination", {
            attrs: {
              total: _vm.skuDetail.pageData.total,
              page: _vm.skuDetail.queryForm.page,
              limit: _vm.skuDetail.queryForm.limit,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.skuDetail.queryForm, "page", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.skuDetail.queryForm, "limit", $event)
              },
              pagination: _vm.handleQuerySkuDetail,
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.batchFh.importBaseParams.title,
            visible: _vm.batchFh.importBaseParams.visible,
            "append-to-body": "",
            width: "1000px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.batchFh.importBaseParams, "visible", $event)
            },
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                {
                  staticStyle: { "font-weight": "bold" },
                  attrs: { span: 5, align: "right" },
                },
                [_vm._v("文件：")]
              ),
              _c(
                "el-col",
                { attrs: { span: 10 } },
                [
                  _c(
                    "el-upload",
                    {
                      ref: "upload",
                      staticClass: "upload-demo",
                      attrs: {
                        drag: "",
                        limit: 1,
                        action: "#",
                        "auto-upload": false,
                        "file-list": _vm.batchFh.importBaseParams.fileList,
                        "on-change": (file, fileList) =>
                          _vm.handleUploadFileChange(file, fileList, "change"),
                        "on-remove": (file, fileList) =>
                          _vm.handleUploadFileChange(file, fileList, "remove"),
                      },
                    },
                    [
                      _c("i", { staticClass: "el-icon-upload" }),
                      _c("div", { staticClass: "el-upload__text" }, [
                        _vm._v("将文件拖到此处，或"),
                        _c("em", [_vm._v("点击上传")]),
                      ]),
                      _c(
                        "div",
                        {
                          staticClass: "el-upload__tip",
                          attrs: { slot: "tip" },
                          slot: "tip",
                        },
                        [
                          _c("span", [_vm._v("仅允许导入xls、xlsx格式文件。")]),
                          _c(
                            "el-link",
                            {
                              staticStyle: {
                                "font-size": "12px",
                                "vertical-align": "baseline",
                              },
                              attrs: {
                                type: "primary",
                                underline: false,
                                target: "_blank",
                                href: _vm.batchFh.importBaseParams
                                  .templateFileDownloadUrl,
                              },
                            },
                            [_vm._v("下载模板")]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { staticStyle: { "margin-top": "20px" } },
            [
              _c(
                "el-col",
                { attrs: { span: 15, offset: 5, align: "left" } },
                [
                  _c(
                    "el-link",
                    { attrs: { type: "danger", underline: false } },
                    [
                      _vm._v(
                        "说明：导入成功后，将新增一条发货记录，仅导入本次发货的数量"
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { staticStyle: { "margin-top": "20px", "margin-bottom": "20px" } },
            [
              _c(
                "el-col",
                { attrs: { span: 10, offset: 5, align: "left" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini", type: "success" },
                      on: { click: _vm.handleImportFh },
                    },
                    [_vm._v("导入")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini", type: "info" },
                      on: {
                        click: function ($event) {
                          _vm.batchFh.importBaseParams.visible = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "选择结案原因",
            visible: _vm.orderClose.visible,
            "append-to-body": "",
            width: "400px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.orderClose, "visible", $event)
            },
          },
        },
        [
          _c(
            "el-radio-group",
            {
              model: {
                value: _vm.orderClose.form.jaReason,
                callback: function ($$v) {
                  _vm.$set(_vm.orderClose.form, "jaReason", $$v)
                },
                expression: "orderClose.form.jaReason",
              },
            },
            _vm._l(
              _vm.options.orderCaseReasonOptions.filter(
                (i) => i.value !== undefined
              ),
              function (item) {
                return _c(
                  "el-radio",
                  {
                    key: item.label,
                    staticClass: "select-radio",
                    attrs: { label: item.label },
                  },
                  [
                    _vm._v(" " + _vm._s(item.label) + " "),
                    item.value === "其他"
                      ? _c("el-input", {
                          staticStyle: {
                            width: "200px",
                            "margin-left": "10px",
                          },
                          attrs: { size: "mini" },
                          model: {
                            value: _vm.orderClose.form.otherReason,
                            callback: function ($$v) {
                              _vm.$set(_vm.orderClose.form, "otherReason", $$v)
                            },
                            expression: "orderClose.form.otherReason",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              }
            ),
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "center" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.orderClose.visible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "danger", size: "mini" },
                  on: { click: _vm.handleCloseCase },
                },
                [_vm._v("确定结案")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }