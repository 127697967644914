<template>
  <div class="new-box">
    <div class="mg-search" >
        <el-card style="margin: 10px;">
        <SearchFilter @search="handleQuery"   @reset="resetQuery" @keyup.enter.native="handleQuery">
      <!-- <el-form ref="queryFormRef" size="mini" label-width="auto" :inline="true" :model="queryForm" @keyup.enter.native="handleQuery"> -->

        <el-form-item label="关键词" >
          <el-input v-model="queryForm.keyWord"  clearable placeholder="请输入关键词" />
        </el-form-item>
        <el-form-item label="账号或姓名">
          <DictSelect
            ref="user"
            type="user"
            :value.sync="queryForm.userName"
            :dictFun="dictUserFun"
            fullLabel
            remote
            version="v3"
            allow-create
            placeholder="请输入账号或姓名"
            :init-props="{ label: 'nickName', value: 'userName'}"
            @getOtherJsonParam="(callback) => callback({ pageSize: 50, pageNum: 1})"
          />
        </el-form-item>
        <el-form-item label="状态" prop="status">
          <el-select v-model="queryForm.status" clearable>
            <el-option v-for="e in options.queryStatusOptions" :label="e.label" :value="e.value" :key="e.value" />
          </el-select>
        </el-form-item>
        <!-- <el-form-item>
          <el-button class="form-btn-item" size="mini" icon="el-icon-search" type="primary" @click="handleQuery">搜索</el-button>
        </el-form-item> -->

      <!-- </el-form> -->
    </SearchFilter>
    </el-card>
    </div>
    <el-card style="margin: 10px;" class="box-card">
        <el-button v-if="authButton" class="form-btn-item" size="mini" icon="el-icon-plus" type="primary" @click="handleEdit">创建红包</el-button>
        <el-table
          style="margin-top: 10px;"
          :data="pageData.rows"
          v-loading="loading"
          class="customer-table"
          border
          size="small"
          ref="table"
          highlight-current-row
        >
          <el-table-column label="ID" prop="id" width="60"/>
          <el-table-column label="红包名称" prop="name" width="200"/>
          <el-table-column label="已领取金额" prop="amountDetail"  width="140">
            <template #default="{ row }">
              <span>{{ row.amountDetail.split('/')[0] }}</span>
            </template>
          </el-table-column>
          <el-table-column label="总金额" prop="amountDetail">
            <template #default="{ row }">
              <span>{{ row.amountDetail.split('/')[1] }}</span>
            </template>
          </el-table-column>
          <el-table-column label="已领取个数" prop="countDetail"  width="100">
            <template #default="{ row }">
              <span>{{ row.countDetail.split('/')[0] }}</span>
            </template>
          </el-table-column>
          <el-table-column label="未领取个数" prop="noAcceptCount"   width="100"/>
          <el-table-column label="领取失败个数" prop="acceptFailedCount"    width="120"/>
          <el-table-column label="总个数" prop="countDetail" >
            <template #default="{ row }">
              <span>{{ row.countDetail.split('/')[1] }}</span>
            </template>
          </el-table-column>
          <el-table-column label="状态" prop="statusText" />
          <el-table-column label="创建者" prop="createBy"  width="180"/>
          <el-table-column label="创建时间" prop="createTime" width="180" />
          <el-table-column label="编辑时间" prop="updateTime" width="180" />
          <el-table-column label="过期时间" prop="endDate" width="180" />
          <el-table-column label="操作" width="200" align="center" fixed="right">
            <template #default="{ row }">
              <div>
                <el-button size="mini" type="text" @click="handleEditRow(row.id, 'view')">查看</el-button>
                <el-button size="mini" type="text" @click="handleEditRow(row.id, 'edit')">编辑</el-button>
                <el-button size="mini" v-if="row.status!==5 && row.status!==3" type="text" style="border: 0px;" @click="redPacketRefuse(row.id)">作废</el-button>
                <el-button size="mini" type="text" style="border: 0;" @click="toDetail(row)">明细</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <Pagination :total="pageData.total" :page.sync="queryForm.page" :limit.sync="queryForm.limit" @pagination="handleQuery" />
    </el-card>
    <el-dialog :title="editDialog.titleMap[editDialog.type]"  :visible.sync="editDialog.visible" append-to-body width="800px" :close-on-click-modal="false">
      <el-form ref="form" size="mini" inline :rules="editDialog.rules" label-width="160px" :model="editDialog.form" class="common-form">
          <div style="border: solid 1px rgb(78 72 72 / 10%); padding: 10px">
              <span style="color: #1482f0; font-size: 24px;">基本信息</span>
              <el-form-item label="红包名称" prop="name">
                <el-input style="width: 400px" v-model="editDialog.form.name" clearable placeholder="建议13字以内" :disabled="editDialog.disabled && editDialog.type!=='edit'" />
              </el-form-item>
              <el-form-item label="红包备注" prop="remark">
                <el-input style="width: 400px" v-model="editDialog.form.remark" clearable placeholder="建议13字以内" :disabled="editDialog.disabled && editDialog.type!=='edit'" />
              </el-form-item>
              <el-form-item label="发放主体" prop="mchidType">
                <el-select style="width: 400px" v-model="editDialog.form.mchidType" :disabled="editDialog.disabled" >
                  <el-option v-for="e in options.mchidTypeOptions" :label="e.label" :value="e.value" :key="e.value" />
                </el-select>
              </el-form-item>
              <el-form-item label="红包标题" prop="title">
                <el-input style="width: 400px" v-model="editDialog.form.title" clearable placeholder="建议13字以内" maxlength="25" show-word-limit :disabled="editDialog.disabled && editDialog.type!=='edit'" />
              </el-form-item>
              <el-form-item label="领取有效期" prop="acceptTime">
                <el-date-picker
                  :disabled="editDialog.disabled && editDialog.type!=='edit'"
                  v-model="editDialog.form.acceptTime"
                  type="datetimerange"
                  range-separator="至"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期">
                </el-date-picker>
              </el-form-item>
              <div style="width: 100%" v-if="editDialog.type!=='edit'">
                <el-form-item v-if="!editDialog.disabled" label="发放类型">
                  <el-radio-group v-model="editDialog.form.sendType" @change="handleSendTypeChange">
                    <el-radio v-for="item in options.sendTypeOptions" :label="item.value" :key="item.value">{{ item.label }}</el-radio>
                  </el-radio-group>
                </el-form-item>
                <div style="background-color: #e5e9ec;padding-top: 5px;padding-left: 5px;">
                  <div v-show="editDialog.form.sendType===1">
                      <el-form-item label="请选择分公司(单选)">
                        <el-select style="width: 300px;" filterable v-model="editDialog.selectedOrg" @change="handleOrgChange">
                          <el-option v-for="e in editDialog.orgLists" :label="e.orgName" :value="e.orgCode" :key="e.orgCode" />
                        </el-select>
                      </el-form-item>
                      <el-form-item label="请选择任务(单选)">
                        <el-select style="width: 300px;" filterable v-model="editDialog.selectedTask" @change="handleLoadBoostBouns">
                          <el-option v-for="e in editDialog.taskLists" :label="e.taskName" :value="e.mtTaskId" :key="e.mtTaskId" />
                        </el-select>
                      </el-form-item>
                      <el-form-item label="业务员所属分公司">
                        <el-select collapse-tags style="width: 300px; margin-right: 10px" filterable multiple v-model="editDialog.selectedUserOrg" @change="handleLoadBoostBouns">
                          <el-option v-for="e in editDialog.orgLists" :label="e.orgName" :value="e.orgCode" :key="e.orgCode" />
                        </el-select>
                        <el-button class="form-btn-item" size="mini" type="primary" @click="handleCheckAll(true)">全选</el-button>
                        <el-button class="form-btn-item" size="mini" type="primary" @click="handleCheckAll(false)">取消全选</el-button>
                      </el-form-item>
                      <el-form-item label="关键字搜索">
                        <el-input style="width: 300px;" v-model="editDialog.keyWord" clearable placeholder="请输入关键字/姓名/账号，多个以空格分割"/>
                      </el-form-item>
                      <span>预计发放总金额：{{ editDialog.form.predictAmount1 }}</span>
                      <el-table
                        ref="singleTable"
                        :data="filterTableData"
                        height="200px"
                        v-loading="editDialog.loading"
                        style="width: 100%; margin-top: 10px">
                        <el-table-column property="username" label="用户账号" align="center" />
                        <el-table-column property="name" label="用户姓名" align="center" />
                        <el-table-column property="amount" label="红包金额(元)" align="center" />
  <!--                        <el-table-column property="username" label="用户账号" width="160" align="center">-->
  <!--                          <template slot-scope="scope">-->
  <!--                            <el-input v-model="scope.row.username" autocomplete="off" size="small"-->
  <!--                                      placeholder="请输入用户账号"></el-input>-->
  <!--                          </template>-->
  <!--                        </el-table-column>-->
  <!--                        <el-table-column property="amount" label="红包金额(元)" width="160" align="center">-->
  <!--                          <template slot-scope="scope">-->
  <!--                            <el-input v-model="scope.row.amount" autocomplete="off" size="small" @change="calculatePredictAmount"-->
  <!--                                      placeholder="请输入红包金额"></el-input>-->
  <!--                          </template>-->
  <!--                        </el-table-column>-->
                        <el-table-column label="操作" width="160" align="center">
                          <template slot-scope="scope">
                            <div>
                              <el-button size="mini" type="text" style="border: 0;" @click="deleteAcceptUser(scope.row.id)">删除</el-button>
                            </div>
                          </template>
                        </el-table-column>
                      </el-table>
                    </div>
                  <div v-show="editDialog.form.sendType===2" style="padding: 10px">
                    <span>预计发放总金额：{{ editDialog.form.predictAmount2 }}</span>
                    <el-table
                      ref="singleTable"
                      :data="editDialog.form.sendObjects2"
                      height="200px"
                      style="width: 100%; margin-top: 10px">
                        <el-table-column property="username" label="用户账号" align="center">
                          <template slot-scope="scope">
                            <DictSelect
                              :ref="'user' + scope.$index"
                              type="user"
                              :value.sync="scope.row.username"
                              :dictFun="dictUserFun"
                              fullLabel
                              remote
                              version="v3"
                              allow-create
                              placeholder="请输入账号或姓名"
                              :init-props="{ label: 'nickName', value: 'userName'}"
                              size="mini"
                              @getOtherJsonParam="(callback) => callback({ pageSize: 50, pageNum: 1})"
                            />
                          </template>
                        </el-table-column>
                        <el-table-column property="amount" label="红包金额(元)" align="center">
                          <template slot-scope="scope">
                            <el-input-number v-model="scope.row.amount" autocomplete="off" size="small" :max="500" @change="calculatePredictAmount" :controls="false"
                                      placeholder="请输入红包金额"></el-input-number>
                          </template>
                        </el-table-column>
                        <el-table-column label="操作" align="center">
                          <template slot-scope="scope">
                            <div>
                              <el-button size="mini" type="text" style="border: 0;" @click="deleteAcceptUser(scope.row.id)">删除</el-button>
                            </div>
                          </template>
                        </el-table-column>
                    </el-table>
                    <div style="margin-top: 10px">
                      <el-button type="primary" size="mini" @click="addAcceptUser()">添加用户</el-button>
                    </div>
                  </div>
                  <div v-show="editDialog.form.sendType===3" style="padding: 10px">
                    <el-button type="primary" size="mini" @click="importAcceptUser()">导入</el-button>
                    <span style="margin-left: 10px">预计发放总金额：{{ editDialog.form.predictAmount3 }}</span>
                    <el-table
                      v-if="editDialog.form.sendObjects3 && editDialog.form.sendObjects3.length > 0"
                      ref="singleTable2"
                      :data="editDialog.form.sendObjects3"
                      class="previewTable-lists"
                      stripe
                      border
                      height="200px"
                      size="mini">
                      <el-table-column property="username" label="用户账号" align="center" />
                      <el-table-column property="nickname" label="用户姓名" align="center" />
                      <el-table-column property="amount" label="红包金额(元)" align="center" />
                    </el-table>
                  </div>
                </div>
                <el-form-item label="发放对象" v-if="editDialog.disabled">
                  <div style="width: 82%">
                    <el-button v-if="!editDialog.disabled" @click="importAcceptUser()">导入</el-button>
                    <div v-if="editDialog.selectedOrg && editDialog.selectedTask">
                      <span>分公司</span>
                      <el-select filterable v-model="editDialog.selectedOrg" @change="handleOrgChange" :disabled="editDialog.disabled">
                        <el-option v-for="e in editDialog.orgLists" :label="e.orgName" :value="e.orgCode" :key="e.orgCode" />
                      </el-select>
                      <span>任务</span>
                      <el-select filterable v-model="editDialog.selectedTask" @change="handleLoadBoostBouns" :disabled="editDialog.disabled">
                        <el-option v-for="e in editDialog.taskLists" :label="e.taskName" :value="e.mtTaskId" :key="e.mtTaskId" />
                      </el-select>
                    </div>
                    <span>   预计发放总金额：{{ editDialog.form.predictAmount }}</span>
                    <el-table
                      v-if="editDialog.form.sendObjects && editDialog.form.sendObjects.length > 0"
                      ref="singleTable3"
                      :data="editDialog.form.sendObjects"
                      class="previewTable-lists"
                      stripe
                      border
                      height="200px"
                      size="mini">
                      <el-table-column property="username" label="用户账号" align="center" />
                      <el-table-column property="amount" label="红包金额(元)" align="center" />
                    </el-table>
                  </div>
                </el-form-item>
              </div>
          </div>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: right" v-if="editDialog.type!=='view'">
        <el-button type="primary" size="mini" @click="editDialog.visible = false">取消</el-button>
        <el-button :disabled="editDialog.form.test" type="primary" size="mini" @click="submitForm(undefined)">确定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="红包明细"  :visible.sync="detailDialog.visible" append-to-body width="80%" :close-on-click-modal="false">
      <div class="mg-search" style="padding-bottom: 15px">
        <el-form ref="queryFormRef" size="mini" label-width="auto" :inline="true" :model="queryForm" @keyup.enter.native="handleDetailQuery">
          <el-form-item label="领取状态" prop="status">
            <el-select v-model="detailDialog.queryForm.status" clearable multiple>
              <el-option v-for="e in options.queryDetailStatusOptions" :label="e.label" :value="e.value" :key="e.value" />
            </el-select>
          </el-form-item>
          <el-form-item label="用户" prop="usernames">
            <DictSelect
              ref="user"
              type="user"
              :value.sync="detailDialog.queryForm.usernames"
              :dictFun="dictUserFun"
              fullLabel
              multiple
              remote
              allow-create
              version="v3"
              placeholder="请输入账号或姓名"
              :init-props="{ label: 'nickName', value: 'userName'}"
              @getOtherJsonParam="(callback) => callback({ pageSize: 50, pageNum: 1})"
            />
          </el-form-item>
          <el-form-item label="账号状态">
            <el-select v-model="detailDialog.queryForm.userStatus" clearable>
              <el-option label="正常" value="1" />
              <el-option label="关闭" value="2" />
            </el-select>
          </el-form-item>
          <el-form-item label="所属分公司">
            <DictSelect ref="org" type="org" :value.sync="detailDialog.queryForm.orgCodes" multiple :dictFun="dictSelect" fullLabel />
          </el-form-item>
          <el-form-item label="微信OpenId">
            <el-input v-model="detailDialog.queryForm.acceptOpenid" size="mini" clearable placeholder="请输入" />
          </el-form-item>
          <el-form-item label="微信付款单号">
            <el-input v-model="detailDialog.queryForm.paymentNo" size="mini" clearable placeholder="请输入" />
          </el-form-item>
          <el-form-item>
            <el-button class="form-btn-item" size="mini" icon="el-icon-search" type="primary" @click="handleDetailQuery">搜索</el-button>
            <el-button class="form-btn-item" size="mini" icon="el-icon-download" type="primary" @click="handleExportDetail">导出</el-button>
          </el-form-item>
        </el-form>
      </div>
      <el-table
        :data="detailDialog.detailPageData.rows"
        height="calc(100vh - 350px)"
        v-loading="detailDialog.loading"
        size="mini"
        border
        ref="detailTable"
        highlight-current-row
        :header-cell-style="{ background: 'rgb(235 235 235)', color: 'black',textAlign: 'center', verticalAlign: 'middle' }"
      >
        <el-table-column label="用户账号" prop="username" width="100" />
        <el-table-column label="用户姓名" prop="nickname" width="130"/>
        <el-table-column label="账号状态" prop="userStatusText" width="80" align="center" />
        <el-table-column label="用户手机号" prop="phoneNum" width="130" />
        <el-table-column label="用户所属分公司" prop="orgName" width="130" />
        <el-table-column label="红包金额" prop="amount" width="130" />
        <el-table-column label="领取状态" prop="statusText" width="130" />
        <el-table-column label="领取时间" prop="acceptTime" width="160" />
        <el-table-column label="微信OpenId" prop="acceptOpenid" width="160" />
        <el-table-column label="聚宝盆单号" prop="partnerTradeNo" width="160" />
        <el-table-column label="微信付款单号" prop="paymentNo" width="160" />
        <el-table-column label="操作" width="260" align="center">
          <template #default="{ row }">
            <div>
              <el-button size="mini" v-if="row.status!==1 && row.status!==2" type="text" @click="redPacketDetailRefuse(row.id, 1)">作废</el-button>
              <el-button size="mini" v-if="row.status===3" type="text" style="border: 0;" @click="redPacketDetailRefuse(row.id, 4)">重试</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <Pagination :total="detailDialog.detailPageData.total" :page.sync="detailDialog.queryForm.page" :limit.sync="detailDialog.queryForm.limit" @pagination="handleDetailQuery" />
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button type="primary" size="mini" @click="detailDialog.visible = false;handleQuery()">取消</el-button>
      </div>
    </el-dialog>

    <ImportDialog :importBaseParams="importDialog.importBaseParams"
                  @handleImportCallBack="handleImportData" append-to-body/>

  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import {
  pageRedpacket,
  pageRedpacketDetail,
  saveRedpacket,
  updateRedPacketStatus,
  getRedPacketOrgTaskList,
  getOrgTaskBoostBonusList,
  userSelect,
  topShopDict,
  dictSelect,
  exportPacketDetail,
  getCurrentUser,
  getRedPacketSingle,
  saveRedPacketSingle,
  getUsersInfo
} from "@/api/appTask";
import { parseTime, deepClone, arrayToMap } from "@/utils"
import { Notification } from "element-ui";
import SearchFilter from "@/components/SearchFilter/index";
import { v4 as uuidv4 } from 'uuid';
import DictSelect from "@/components/DictSelect";

export default {
  name: "redPacket",
  components: { Pagination, DictSelect, ImportDialog: () => import("@/components/ImportDialog"), SearchFilter},
  filters: {
    statusFilter(status) {
      const statusMap = {
        1: "primary",
        2: "warning",
        3: "success",
        4: "danger",
        5: "info",
      };
      return statusMap[status];
    },
  },
  data() {
    return {
      dictFun: topShopDict,
      dictUserFun: userSelect,
      dictSelect: dictSelect,
      dictParam: {},
      pageData: {
        total: 0,
        rows: [],
        selections: [],
      },
      queryForm: {
        page: 1,
        limit: 10,
        keyWord: undefined,
        status: undefined,
        userName: undefined,
      },
      loading: false,
      editDialog: {
        disabled: false,
        visible: false,
        loading: false,
        type: undefined,
        currentRow: {},
        imgTypes: ['.jpg', '.jpeg', '.gif', '.png'],
        titleMap : {
          'view': '查看红包',
          'edit': '编辑红包',
          'add': '创建红包'
          },
        form: {
          name: '',
          remark: '',
          mchidType: undefined,
          title: '',
          acceptTime: [],
          startDate: '',
          endDate: '',
          sendType: 1,
          sendObjects: [],
          sendObjects1: [],
          sendObjects2: [],
          sendObjects3: [],
          predictAmount: 0,
          predictAmount1: 0,
          predictAmount2: 0,
          predictAmount3: 0,
        },
        rules: {
          name: [{ required: true, message: " ", trigger: "change" }],
          mchidType: [{ required: true, message: " ", trigger: "change" }],
          title: [{ required: true, message: " ", trigger: "change" }],
          acceptTime: [{ required: true, message: " ", trigger: "change" }],
          sendType: [{ required: true, message: " ", trigger: "change" }],
          remark: [{ required: true, message: " ", trigger: "change" }],
        },
        orgLists: [],
        selectedOrg: "",
        taskLists: [],
        selectedTask: "",
        selectedUserOrg: []
      },
      detailDialog: {
        queryForm: {
          page: 1,
          limit: 10,
          redPacketId: undefined,
          status: undefined,
          usernames: [],
          orgCodes: [],
          acceptOpenid: '',
          paymentNo: '',
          userStatus: undefined,
        },
        visible: false,
        loading: false,
        detailPageData: {
          total: 0,
          rows: [],
          selections: [],
        },
        amountDetail: '',
        countDetail: ''
      },
      currentRow: {},
      options: {
        queryStatusOptions: [
          { label: '进行中', value: 2 },
          { label: '已结束', value: 3 },
          { label: '已作废', value: 5 },
        ],
        mchidTypeOptions: [
          { label: '聚宝盆商户', value: 1 },{ label: '联盟商户', value: 2 }
        ],
        sendTypeOptions: [
          { label: '业务激励', value: 1}, { label: '按用户账号', value: 2}, { label: '导入表格', value: 3}
        ],
        queryDetailStatusOptions: [
          { label: '未领取', value: 0 },
          { label: '已领取', value: 1 },
          { label: '领取失败', value: 3 },
          { label: '已作废', value: 2 },
        ],
      },
      fixUrl: {
        task: '/task/messageNotice?mtTaskId=',
        version: process.env.VUE_APP_JBP_DOWNLOAD,
        course: '/tutorial/detail?id='
      },
      statusMap: {
        1: "等待推送",
        2: "推送中",
        3: "推送成功",
        4: "推送失败",
        5: "已取消",
      },
      authUsers: [],
      defaultUserObj: {
        development: ['wangsiyong'],
        test: ['wangsiyong','tongmeng'],
        uat: ['wangsiyong','tongmeng'],
        production: ['wangsiyong','xiajinlong','tongmeng']
      },
      currentUser: {},
      importDialog: {
        currentImportType: "user",
        importBaseParams: {
          visible: false,
          title: "发放对象导入",
          type: "user",
          tipsText: "导入金额单位为元，保留两位小数",
          templateSampleHead: [
            {label: "用户账号", prop: "username", minWidth: "80px"},
            {label: "红包金额", prop: "amount", minWidth: "80px"}
          ],
          templateSampleLists: [
            {
              username: "aaaaaa",
              amount: 10.01,
            },
          ],
          apiParams: "sendObjects",
          previewDataLength: 10,
          templateFileDownloadUrl: 'https://mg-data-oss.oss-cn-shanghai.aliyuncs.com/public/%E5%8F%91%E6%94%BE%E7%94%A8%E6%88%B7%E5%AF%BC%E5%85%A5.xlsx',
        },
      },
    };
  },
  computed: {
    filterTableData() {
      if (this.editDialog.form.sendObjects1 && this.editDialog.form.sendObjects1.length > 0) {
        if (this.editDialog.keyWord) {
          const keyWords = this.editDialog.keyWord.split(' ').filter(e => e);
          return this.editDialog.form.sendObjects1.filter(e => {
            return keyWords.filter(k => e.username.indexOf(k) >-1 || e.name.indexOf(k) >-1).length > 0
          })
        }
        return [].concat(this.editDialog.form.sendObjects1);
      }
      return [];
    },
    authButton() {
      if (this.currentUser && this.currentUser.userName && this.authUsers.indexOf(this.currentUser.userName) > -1) {
        return true
      }
      return false
    }
  },
  watch: {
    filterTableData: {
      handler(nV, oV) {
        this.calculatePredictAmount();
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    // getCoursePage(parseParam({size: 200, current: 1})).then(res => {
    //   this.options.courseOptions = res.data?.records;
    // });
    getRedPacketOrgTaskList({}).then(res => {
      if (!res.data) {
        return false;
      }
      this.editDialog.orgLists = res.data?.list;
    });
    this.authUsers = this.defaultUserObj[process.env.NODE_ENV];
    getCurrentUser().then(res => this.currentUser = res.data);
  },
  mounted() {
    this.handleFilter();
  },
  methods: {
    handleQuery() {
      this.loading = true;
      const query = Object.assign({}, this.queryForm);
      pageRedpacket(query).then(res => {
        if (!res.data) {
          return false;
        }
        this.loading = false;
        this.pageData.rows = res.data?.list;
        this.pageData.total = res.data?.total;
        this.$refs.table.doLayout();
      });
    },
    handleDetailQuery() {
      this.detailDialog.loading = true;
      const query = Object.assign({}, this.detailDialog.queryForm);
      pageRedpacketDetail(query).then(res => {
        if (!res.data) {
          return false;
        }
        this.detailDialog.loading = false;
        this.detailDialog.detailPageData.rows = res.data?.list;
        this.detailDialog.detailPageData.total = res.data?.total;
        this.$refs.detailTable.doLayout();
      });
    },
    handleFilter() {
      this.queryForm.page = 1;
      this.handleQuery();
    },
    resetQuery() {
      const queryForm = {
        page: 1,
        limit: 10,
        keyWord: undefined,
        status: undefined,
        userName: undefined,
      };
      this.queryForm = Object.assign({}, queryForm);
      this.$forceUpdate();
    },
    handleSortChange(data) {
      const { prop, order } = data;
      if (prop && order) {
        this.queryForm.sorts = order === "ascending" ? prop + " asc" : prop + " desc";
      } else {
        this.queryForm.sorts = undefined;
      }
      this.handleFilter();
    },
    handleEdit() {
      this.editDialog.type = 'add';
      this.editDialog.form = {
        name: '',
        remark: '',
        mchidType: undefined,
        title: '',
        acceptTime: [],
        sendType: 1,
        sendObjects: [],
        sendObjects1: [],
        sendObjects2: [],
        sendObjects3: [],
        predictAmount: 0,
        predictAmount1: 0,
        predictAmount2: 0,
        predictAmount3: 0
      }
      this.editDialog.selectedOrg = '';
      this.editDialog.selectedTask = '';
      this.editDialog.selectedUserOrg = [];
      this.editDialog.showDate = [];
      this.editDialog.disabled = false;
      this.editDialog.visible = true;
    },
    submitForm() {
      // if (!this.editDialog.form.coverImg) {
      //   Notification.info("头图不能为空!");
      //   return;
      // }
      this.$refs.form.validate(valiad => {
        if (valiad) {
          if (this.editDialog.type === 'edit') {
            let data = Object.assign({}, this.editDialog.form);
            data.startDate = this.editDialog.form.acceptTime[0];
            data.endDate = this.editDialog.form.acceptTime[1];
            console.log(data)
            saveRedPacketSingle(data).then(res => {
              if (res.code == 200) {
                Notification.success("操作成功!");
                this.editDialog.visible = false;
                this.handleFilter();
              }
            })
          } else {
            // Notification.info("推送方式不能为空！");
            let data = Object.assign({}, this.editDialog.form);
            data.startDate = this.editDialog.form.acceptTime[0];
            data.endDate = this.editDialog.form.acceptTime[1];
            if (this.editDialog.form.sendType === 1) {
              data.sendObjects = deepClone(this.filterTableData);
            } else if (this.editDialog.form.sendType === 2) {
              data.sendObjects = data.sendObjects2;
            } else if (this.editDialog.form.sendType === 3) {
              data.sendObjects = data.sendObjects3;
            }
            if (this.editDialog.selectedOrg && this.editDialog.selectedTask) {
              data.orgCode = this.editDialog.selectedOrg;
              data.mtTaskId = this.editDialog.selectedTask;
            }
            this.loading = true;
            saveRedpacket(data).then(res => {
              if (res.code === 200) {
                if (res.data) {
                  alert(res.data);
                }
                this.loading = false;
                Notification.success("操作成功!");
                this.editDialog.visible = false;
                this.handleFilter();
              }
            })
          }
        }
      })
    },
    getFixTimeCron(date) {
      const time = parseTime(date, "{y} {m} {d} {h} {i} {s}").replaceAll(' 0', ' ').split(' ');
      return time[5] + ' ' + time[4] + ' ' + time[3] + ' ' + time[2] + ' ' + time[1] + ' ? '  + time[0];
    },
    addAcceptUser() {
      this.editDialog.form.sendObjects2.push({username: '', amount: '', id: uuidv4()})
    },
    deleteAcceptUser(id) {
      if (this.editDialog.form.sendType === 1) {
        this.editDialog.form.sendObjects1 = this.editDialog.form.sendObjects1.filter(item => item.id !== id);
      } else if (this.editDialog.form.sendType === 2) {
        this.editDialog.form.sendObjects2 = this.editDialog.form.sendObjects2.filter(item => item.id !== id);
      }
      this.calculatePredictAmount();
    },
    importAcceptUser() {
      this.importDialog.importBaseParams.visible = true;
    },
    async handleImportData(val) {
      let userObj = {};
      const userNames = Array.from(new Set(val.sendObjects.map(e => e.username)));
      val.sendObjects.map(item => {
        if (userObj[item.username]) {
          userObj[item.username].amount+= item.amount;
        } else {
          userObj[item.username] = deepClone(item);
        }
      });
      let array = [];
      let userMap = {};
      await getUsersInfo({ userNames: userNames}).then(res => {
        if(res.data && res.code === 200) {
          userMap = arrayToMap(res.data, 'userName');
        }
      })
      for (let key in userObj) {
        array.push({ username: key, amount: userObj[key].amount, nickname: userMap[key] ?  userMap[key].nickName: ''});
      }
      let userArr = this.splitAmount(array, 'amount');
      this.editDialog.form.sendObjects3 = userArr;
      this.calculatePredictAmount();
      this.importDialog.importBaseParams.visible = false
    },
    splitAmount(data, amountKey) {
      let userArr = []
      for (let ele of data) {
        const amount = ele[amountKey];
        const num = parseInt(amount/500 + '');
        const leftNum = amount%500;
        if (num > 0) {
          for (let i = 0; i < num; i++) {
            let obj = deepClone(ele);
            obj[amountKey] = 500;
            if (!obj.id) {
              obj.id = uuidv4();
            }
            userArr.push(obj)
          }
        }
        if (leftNum >0) {
          let obj = deepClone(ele);
          obj[amountKey] = leftNum;
          if (!obj.id) {
            obj.id = uuidv4();
          }
          userArr.push(obj)
        }
      }
      return userArr;
    },
    async handleEditRow(id, type) {
      this.editDialog.type = type;
      if (type === 'view') {
        this.editDialog.disabled = true;
        const index = this.pageData.rows.findIndex(item => item.id === id)

        const query = {redPacketId: id, limit: 999999};
        let detailList = [];
        await pageRedpacketDetail(query).then(res => {
          if (res.data) {
            detailList = res.data?.list.map(item => {
              return {username: item.username, amount: item.amount}
            })
          }
        });

        if (index || index === 0) {
          this.editDialog.form = Object.assign({sendType: 3, sendObjects: detailList}, this.pageData.rows[index]);
        }
        this.editDialog.form.acceptTime = [this.pageData.rows[index].startDate, this.pageData.rows[index].endDate]
        this.editDialog.selectedOrg = this.pageData.rows[index].orgCode;
        this.editDialog.selectedTask = this.pageData.rows[index].mtTaskId;

        this.calculatePredictAmount();

        this.editDialog.visible = true;
      } else if (type ==='edit') {
        this.editDialog.disabled = true;
        this.editDialog.form.acceptTime = []
        getRedPacketSingle(id).then(res => {
          if (res.code == 200) {
            this.editDialog.form = Object.assign({}, res.data);
            this.$forceUpdate();
            this.editDialog.visible = true;
            setTimeout(() => {
              this.$set(this.editDialog.form, 'acceptTime', [res.data.startDate, res.data.endDate])
            }, 200)
          }
        })
      }
    },
    redPacketRefuse(id) {
      let data = {redPacketOp: {id, op: 1}}
      updateRedPacketStatus(data).then(res => {
        if (res.code === 200) {
          Notification.success("操作成功!");
          this.handleQuery();
        }
      })
    },
    toDetail(row) {
      this.currentRow = row;
      this.detailDialog.queryForm.status = undefined;
      this.detailDialog.queryForm.page = 1;
      this.detailDialog.queryForm.redPacketId = row.id;
      this.detailDialog.queryForm.usernames = [];
      this.detailDialog.queryForm.orgCodes = [];
      this.detailDialog.queryForm.acceptOpenid = '';
      this.detailDialog.queryForm.paymentNo = '';
      this.handleDetailQuery();
      this.detailDialog.visible = true;
    },
    redPacketDetailRefuse(id, op) {
      let data = {redPacketDetailOp: {id, op}}
      updateRedPacketStatus(data).then(res => {
        if (res.code === 200) {
          Notification.success("操作成功!");
          this.handleDetailQuery();
        }
      })
    },
    calculatePredictAmount() {
      if (this.editDialog.form.sendType === 1) {
        if (this.filterTableData && this.filterTableData.length > 0) {
          this.editDialog.form.predictAmount1 = this.filterTableData
            .map(item => parseFloat(item.amount))
            .filter(item => item && item > 0)
            .reduce((a, b) => a + b).toFixed(2)
        } else {
          this.editDialog.form.predictAmount1 = "0";
        }
      } else if (this.editDialog.form.sendType === 2) {
        if (this.editDialog.form.sendObjects2 && this.editDialog.form.sendObjects2.length > 0) {
          this.editDialog.form.predictAmount2 = this.editDialog.form.sendObjects2
            .map(item => parseFloat(item.amount))
            .filter(item => item && item > 0)
            .reduce((a, b) => a + b).toFixed(2)
        } else {
          this.editDialog.form.predictAmount2 = "0";
        }
      } else if (this.editDialog.form.sendType === 3) {
        if (this.editDialog.form.sendObjects3 && this.editDialog.form.sendObjects3.length > 0) {
          this.editDialog.form.predictAmount3 = this.editDialog.form.sendObjects3
            .map(item => parseFloat(item.amount))
            .filter(item => item && item > 0)
            .reduce((a, b) => a + b).toFixed(2)
        } else {
          this.editDialog.form.predictAmount3 = "0";
        }
      }
      if (this.editDialog.form.sendObjects) {
        if (this.editDialog.form.sendObjects && this.editDialog.form.sendObjects.length > 0) {
          this.editDialog.form.predictAmount = this.editDialog.form.sendObjects
            .map(item => parseFloat(item.amount))
            .filter(item => item && item > 0)
            .reduce((a, b) => a + b).toFixed(2)
        } else {
          this.editDialog.form.predictAmount = "0";
        }
      }
    },
    handleOrgChange() {
      this.editDialog.taskLists = [];
      this.editDialog.selectedTask = '';
      if (this.editDialog.selectedOrg) {
        let orgTasks = this.editDialog.orgLists.filter(item => item.orgCode === this.editDialog.selectedOrg);
        this.editDialog.taskLists = orgTasks[0].tasks;
      }
    },
    handleLoadBoostBouns() {
      if (this.editDialog.selectedOrg && this.editDialog.selectedTask) {
        this.editDialog.loading = true;
        let param = { orgCode: this.editDialog.selectedOrg, mtTaskId: this.editDialog.selectedTask, userOrgs: this.editDialog.selectedUserOrg }
        getOrgTaskBoostBonusList(param).then(res => {
          if (!res.data) {
            return false;
          }
          if (res.data?.list) {
            res.data.list.forEach(item => {
              item.amount = item.bonus;
            })
          }
          this.editDialog.form.sendObjects1 =this.splitAmount(res.data?.list, 'amount');
          this.calculatePredictAmount();
          this.editDialog.loading = false;
          // this.importDialog.importBaseParams.visible = false
        }).finally(res => {
          this.editDialog.loading = false;
        })
      }
    },
    handleSendTypeChange() {
      this.editDialog.form.sendObjects1 = [];
      this.editDialog.form.sendObjects2 = [];
      this.editDialog.form.sendObjects3 = [];
      this.editDialog.form.predictAmount1 = 0;
      this.editDialog.form.predictAmount2 = 0;
      this.editDialog.form.predictAmount3 = 0;
      this.editDialog.selectedOrg= '';
      this.editDialog.selectedTask = '';
      this.editDialog.selectedUserOrg = [];
    },
    handleExportDetail() {
      this.loading = true;
      const query = Object.assign({}, this.detailDialog.queryForm);
      exportPacketDetail(query).then(res => {
        this.loading = false;
        if (!res) {
          return
        }
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = window.URL.createObjectURL(new Blob([res]))
        link.setAttribute('download', '红包明细导出('+ this.currentRow.name +').xlsx')
        document.body.appendChild(link)
        link.click()
        link.remove()
      })
    },
    handleCheckAll(checked) {
      if (checked) {
        this.editDialog.selectedUserOrg = [].concat(this.editDialog.orgLists).filter(e => e.orgCode!='ALL').map(e => e.orgCode);
      } else {
        this.editDialog.selectedUserOrg = [];
      }
      this.handleLoadBoostBouns();
    },
  }
};
</script>

<style lang="scss" scoped>
// ::v-deep .el-dialog__body {
//   padding-top: 10px;
//   padding-bottom: 5px;
// }

// .common-form {
//   &-row {
//     display: flex !important;
//     flex-wrap: wrap;
//   }
//   div .el-form-item {
//     display: flex !important;
//     ::v-deep {
//       .el-form-item__content {
//         width: 100%;
//         .el-input {
//           width: 100%;
//         }
//         .el-input-number {
//           width: 100%;
//         }
//         .el-select {
//           width: 100%;
//         }
//         .el-date-editor {
//           width: 100%;
//         }
//       }
//     }
//   }
// }
.link-text-class {
  margin-left: 20px;
  color: gray;
}
::v-deep .el-form-item__error {
  top: 70%
}
</style>
