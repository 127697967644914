import {
  axiosDelete,
  axiosDeleteWithoutMessage,
  axiosDownload,
  axiosGet,
  axiosPost,
  axiosPostWithoutMessage,
  axiosPut
} from "@/utils/request";

/**
 * 下拉数据接口
 * @param data
 * @returns {*}
 */
export function dictSelect(data, json) {
  return axiosGet("/dict/group/" + data, json, 2);
}

export function shopDict(data, json) {
  return axiosGet("/dict/shop/" + data, null, 2);
}

export function goodsDict(data, json) {
  return axiosGet("/dict/goods/" + data, json, 2);
}

export function topShopDict(data, json) {
  return axiosGet("/key-point/shop/area-select/query/" + data, json, 2);
}

export function shopTargetDict(data, json) {
  return axiosGet("/dict/shop-target/" + data, json, 2);
}

export function appDict(data, json) {
  return axiosGet("/dict/appDict/" + data, json, 2);
}

export function getAppDictData(data) {
  return axiosGet("/dict/appDictData/" + data, {}, 2);
}

export function moveAppDict(type, data) {
  return axiosPost("/dict/appDictData/move/" + type, data, 2);
}

export function updateAppDict(data) {
  return axiosPut("/dict/appDictData", data, 2);
}

export function deleteAppDict(data) {
  return axiosDelete("/dict/appDictData", data, 2);
}

export function userSelect(data, json) {
  return axiosGet("/dict/shop-user/" + data, json, 2);
}
export function branchDict(data, json) {
  return axiosGet("/dict/branchDict/" + data, json, 2);
}

export function getUserRoleKey(data) {
  return axiosGet("/user/role-key", {}, 2);
}

export function getDownloadRecords(data) {
  return axiosPost("/download_record/page", data, 2);
}
// --------------------   商品组接口  -----------------------
/**
 * 商品组分页
 * @param data
 * @returns {*}
 */
export function pageSkuModelGroup(data) {
  return axiosGet("/grpSkuModel/group" + data, null, 2);
}

/**
 * 商品列表分页
 * @param data
 * @returns {*}
 */
export function pageSkuModel(scope, data) {
  return axiosPost("/grpSkuModel/sku/" + scope, data, 2);
}

/**
 * 商品详情
 * @param data
 * @returns {*}
 */
export function getSkuModelGroupInfo(data) {
  return axiosGet("/grpSkuModel/" + data, null, 2);
}

/**
 * 保存
 * @param data
 * @returns {*}
 */
export function saveSkuModelGroup(data) {
  return axiosPost("/grpSkuModel", data, 2);
}

/**
 * 更新
 * @param data
 * @returns {*}
 */
export function updateSkuModelGroup(data) {
  return axiosPut("/grpSkuModel", data, 2);
}

/**
 * 删除
 * @param data
 * @returns {*}
 */
export function deleteSkuModelGroup(data) {
  return axiosDelete("/grpSkuModel/" + data, null, 2);
}

/**
 * 校验商品列表
 * @param data
 * @returns {*}
 */
export function checkSkus(type, data) {
  return axiosPost("/grpSkuModel/check/" + type, data, 2);
}
/**
 * 商品导出
 * @param data
 * @returns {*}
 */
export function exportSkuGroups(data) {
  return axiosDownload("/grpSkuModel/export/" + data, {}, 2);
}
// --------------------   店铺组接口  -----------------------

/**
 * 任务分页
 * @param data
 * @returns {*}
 */
export function pageShopGroup(data) {
  return axiosGet("/grpShop/group" + data, null, 2);
}

/**
 * 任务列表分页
 * @param data
 * @returns {*}
 */
export function pageShop(data) {
  return axiosPost("/grpShop/shop", data, 2);
}

/**
 * 任务详情
 * @param data
 * @returns {*}
 */
export function getShopGroupInfo(data) {
  return axiosGet("/grpShop/" + data, null, 2);
}

/**
 * 保存
 * @param data
 * @returns {*}
 */
export function saveShopGroup(data) {
  return axiosPost("/grpShop", data, 2);
}

/**
 * 更新
 * @param data
 * @returns {*}
 */
export function updateShopGroup(data) {
  return axiosPut("/grpShop", data, 2);
}

/**
 * 删除
 * @param data
 * @returns {*}
 */
export function deleteShopGroup(data) {
  return axiosDelete("/grpShop/" + data, null, 2);
}

/**
 * 校验店铺列表
 * @param data
 * @returns {*}
 */
export function checkShops(data) {
  return axiosPost("/grpShop/check", data, 2);
}

/**
 * 导出店铺组店铺数据
 * @param data
 * @returns {*}
 */
export function exportShopGroups(data) {
  return axiosDownload("/grpShop/export/" + data, {}, 2);
}

// ---------- 任务----------
/**
 * 任务组分页
 * @param data
 * @returns {*}
 */
export function pageTask(data) {
  return axiosGet("/task" + data, null, 2);
}

/**
 * 任务详情
 * @param data
 * @returns {*}
 */
export function getTaskInfo(data) {
  return axiosGet("/task/" + data, null, 2);
}

/**
 * 保存
 * @param data
 * @returns {*}
 */
export function saveTask(data) {
  return axiosPost("/task", data, 2);
}

/**
 * 更新
 * @param data
 * @returns {*}
 */
export function updateTask(data) {
  return axiosPut("/task", data, 2);
}

/**
 * 删除
 * @param data
 * @returns {*}
 */
export function deleteTask(data) {
  return axiosDelete("/task/" + data, null, 2);
}

/**
 * 任务job执行
 * @param data
 * @returns {*}
 */
export function runTaskJob(data) {
  return axiosPost("/task/job/" + data, {}, 2);
}
export function auditTask(data) {
  return axiosPost("/task/audit", data, 2);
}
export function getAuditTaskList(data) {
  return axiosPost("/task/audit-list", data, 2);
}

/**
 * 任务状态变更
 * @param data
 * @returns {*}
 */
export function updateTaskStatus(data) {
  return axiosPut("/task/" + data, {}, 2);
}
export function get238Options(data) {
  return axiosGet("/task/238-options", data, 2);
}

/**
 * 任务目标详情
 * @param data
 * @returns {*}
 */
export function getTaskTargtInfo(data) {
  return axiosPost("/task/target/info" , data, 2);
}

/**
 * 任务复制
 */
export function taskCopy(data) {
  return axiosGet("/task/copy/" + data, null, 2);
}

/**
 * 目标设置
 * @param data
 * @returns {*}
 */
export function updateTaskTarget(data) {
  return axiosPost("/task/target", data, 2);
}
export function saveTaskOrgConf(data) {
  return axiosPost("/task/org-conf", data, 2);
}
export function getTaskOrgConf(data) {
  return axiosGet("/task/org-conf/" + data, {}, 2);
}

export function getTaskByOrg(data) {
  return axiosGet("/task/list" + data, null, 2);
}
export function exportTaskSku(data) {
  return axiosDownload("/task/export-sku/" + data, {}, 2);
}

export function getOrgTaskOption(data) {
  return axiosGet("/task/org-option/" + data, {}, 2);
}

export function getOrgDcTree() {
  return axiosGet("/task/org-dc-tree", {}, 2);
}
// -----------  区域分组
/**
 * 获取当前登录人的信息
 * @returns {*}
 */
export function getCurrentUser() {
  return axiosGet("/user", null, 2);
}

/**
 * 分页查询dim_mg_shop_info数据
 * @param data
 * @returns {*}
 */
export function pageShopCapaticyList(data) {
  return axiosGet("/shop-capacity/list" + data, null, 2);
}

/**
 * 单条修改店铺类型
 * @param data
 * @returns {*}
 */
export function updateShopCapaticy(data) {
  return axiosPost("/shop-capacity/update", data, 2);
}

/**
 * 批量导入bi_app_shop_capacity_config数据
 * @param data
 * @returns {*}
 */
export function importShopCapaticy(data) {
  return axiosPost("/shop-capacity/import", data, 2);
}

/**
 * 分页查询areaTag列表数据
 * @param data
 * @returns {*}
 */
export function pageAreaTagTargetList(data) {
  return axiosPost("/area-group/list", data, 2);
}

/**
 * 分页查询店铺列表数据
 * @param data
 * @returns {*}
 */
export function pageShopTargetList(data) {
  return axiosPost("/shop-target/list", data, 2);
}

/**
 * 批量导入areaTag目标数据
 * @param data
 * @returns {*}
 */
export function importAreaTagTarget(data) {
  return axiosPost("/area-group/import", data, 2);
}


/**
 * 批量导入shoptarget目标数据
 * @param data
 * @returns {*}
 */
export function importShopTarget(data) {
  return axiosPost("/shop-target/import", data, 2);
}

/**
 * 导出区域分组数据
 * @param data
 * @returns {*}
 */
export function exportArea(data) {
  return axiosDownload("/shop-capacity/export", data, 2);
}

export function deleteShopTarget(data) {
  return axiosDelete("/shop-target", data, 2);
}

/**
 * 区域目标导出
 * @param data
 * @returns {*}
 */
export function exportAreaTarget(data) {
  return axiosDownload("/area-group/export", data, 2);
}

/**
 * 店铺目标导出
 * @param data
 * @returns {*}
 */
export function exportShopTarget(data) {
  return axiosDownload("/shop-target/export", data, 2);
}


export function pageDcAreaTag(data) {
  return axiosPost("/shop-area/page", data, 2);
}

export function updateDcAreaTag(data) {
  return axiosPut("/shop-area", data, 2);
}

// ---------------- 商品管理 --------------------------

export function pageGoods(data) {
  return axiosGet("/goods?" + data, {}, 2);
}

export function exportGoods(data) {
  return axiosDownload("/goods/export", data, 2);
}

/**
 * 导入
 * @param data
 * @returns {*}
 */
export function importGoods(data) {
  return axiosPost("/goods/importFile", data, 2);
}

/**
 * 调整排序
 * @param skuModel
 * @param index
 * @returns {*}
 */
export function jumpLocation(skuModel, index) {
  return axiosPost("/goods/jump/" +skuModel + "/" + index, {}, 2);
}

/**
 * 更新一二级品类
 * @param data
 * @returns {*}
 */
export function updateGoods(data) {
  return axiosPut("/goods", data, 2);
}

/**
 * 商品看板
 * @returns {*}
 */
export function getDashBoard() {
  return axiosGet("/goods/dashboard", {}, 2);
}

/**
 * 商品日志
 * @param data
 * @returns {*}
 */
export function getModelLog(data) {
  return axiosGet("/goods/log/" + data, {}, 2);
}

export function pageCategory(data) {
  return axiosGet("/category?" + data, {}, 2);
}

export function delCateGory(data) {
  return axiosDelete("/category", data, 2);
}

export function importCateGory(data) {
  return axiosPost("/category/import", data, 2);
}

export function exportCategory(data) {
  return axiosDownload("/category/export", data, 2);
}
// ---------------------------------------  重点店铺维护 ------------------
/**
 * 重点店铺分页检索
 * @param data
 * @returns {*}
 */
export function pageTopShops(data) {
  return axiosPost("/key-point/shop/list", data, 2);
}

/**
 * 重点店铺导入
 * @param data
 * @returns {*}
 */
export function importTopShops(data, action) {
  return axiosPost("/key-point/shop/import", data, 2);
}
export function importCommonShops(data, action) {
  return axiosPost("/key-point/shop/importFileCommon", data, 2);
}

export function basicDict(type, data) {
  return axiosPost("/key-point/shop/basics-select/query/" + type, data, 2);
}
export function updateShop(data) {
  return axiosPut("/key-point/shop", data, 2);
}
export function exportShop(data) {
  return axiosDownload("/key-point/shop/export", data, 2);
}
export function exportShopSummary(data) {
  return axiosDownload("/key-point/shop/export-summary", {}, 2);
}
export function getMergedShops(data) {
  return axiosPost("/key-point/shop/merged", data, 2);
}
export function updateShopResp(dcCode, userName) {
  return axiosPost("/key-point/shop/updateResp/" + dcCode + "/" + userName, {}, 2);
}
// ----------------- 店铺目标任务-----------------
export function pageShopTagets(data) {
  return axiosGet("/task/shop-target?" + data, {}, 2);
}
export function saveShopTargets(data) {
  return axiosPost("/task/shop-target", data, 2);
}
export function getTotalShopTarget(data) {
  return axiosGet("/task/shop-target/total?" + data, {}, 2);
}
export function exportTaskShopTarget(data) {
  return axiosDownload("/task/shop-target/export", data, 2);
}
export function autoComputeShopTarget(data) {
  return axiosPost("/task/shop-target/compute", data, 2);
}
export function importTaskShopTarget(task, org, data) {
  return axiosPost("/task/shop-target/importFile/" + task + "/" +org, data, 2);
}
export function getSettingCount(data, org) {
  return axiosGet("/task/shop-target/count/" + data + "/" + org, {}, 2);
}
export function changeTargetSetting(data, org, year) {
  return axiosPost("/task/shop-target/change/" + data + "/" + org + "/" + year, {}, 2);
}
export function getShopTargetSetting(taskId, org, ym) {
  return axiosGet("/task/shop-target/setting/" + taskId+ "/" + org + "/" + ym, {}, 2);
}

// -----------------  教程  -----------------
export function getCoursePage(data) {
  return axiosGet("/course/?" + data, {}, 2);
}
export function saveCourse(data) {
  return axiosPost("/course", data, 2);
}

// 获取阿里云ststoken
export function getSTSToken(data) {
  return axiosPost( "/common/getSTSToken", data, 4);
}

export function getCourseCategoryList() {
  return axiosPost("/course/category/list", {}, 2);
}

export function getBizRoleList(data) {
  return axiosPost("/biz/role/list", data, 2);
}

export function saveCourseCategory(data) {
  return axiosPost("/course/category/save", data, 2);
}

export function removeCourseCategory(data) {
  return axiosDelete("/course/category?dictKey="+data, {}, 2);
}

// -------------------- App推送后台 ----------------------
export function pageAppPushRecords(data) {
  return axiosPost("/app-push/page", data, 2);
}

export function saveAppPushTask(data) {
  return axiosPost("/app-push", data, 2);
}
export function cancelPushTask(data) {
  return axiosPost("/app-push/cancel/" + data, {}, 2);
}
export function getAppPushOptions() {
  return axiosGet("/app-push/options", {}, 2);
}

// -------------------红包后台相关------------------------
export function pageRedpacket(data) {
  return axiosPost("/redpacket/list", data, 5);
}

export function pageRedpacketDetail(data) {
  return axiosPost("/redpacket/detail/list", data, 5);
}

export function saveRedpacket(data) {
  return axiosPost("/redpacket/", data, 5);
}

export function updateRedPacketStatus(data) {
  return axiosPost("/redpacket/op", data, 5);
}

export function getRedPacketOrgTaskList(data) {
  return axiosPost("/redpacket/org/task", data, 5);
}

export function getOrgTaskBoostBonusList(data) {
  return axiosPost("/redpacket/boost/bonus-list", data, 5);
}

export function exportPacketDetail(data) {
  return axiosDownload("/redpacket/detail/export", data, 5);
}

export function getRedPacketSingle(data) {
  return axiosPost("/redpacket/getSingle/" + data, {}, 5);
}
export function saveRedPacketSingle(data) {
  return axiosPost("/redpacket/save", data, 5);
}

export function getUsersInfo(data) {
  return axiosPost("/user/users", data, 5);
}
// ------------------ 红包激励 --------------------------------

export function pageUserBoost(data) {
  return axiosPost("/user-boost/page", data, 2);
}

export function getBoost(data) {
  return axiosPost("/user-boost/boost", data, 2);
}

export function getUserBoostDetail(data) {
  return axiosPost("/user-boost/boost/detail", data, 2);
}

export function getBoostShopDetail(data) {
  return axiosPost("/user-boost/boost/terminal/detail", data, 2);
}

export function exportBoostPacket(data) {
  return axiosDownload("/user-boost/boost/export", data, 2);
}
export function getSummaryData(data) {
  return axiosPost("/user-boost/boost/summary", data, 2);
}
export function approvalPacket(data) {
  return axiosPost("/user-boost/boost/approve-packet", data, 2);
}

export function updatePacketAmount(data) {
  return axiosPost("/user-boost/boost/update-amount", data, 2);
}

export function getPacketRecord(data) {
  return axiosGet("/user-boost/boost/packet-record?"+ data, {}, 2);
}
export function batchApprovalRecord(data) {
  return axiosPost("/user-boost/boost/approve-batch", data, 2);
}

export function approvalAllRecord(data) {
  return axiosPost("/user-boost/boost/approve-all", data, 2);
}

export function exportShopBonus(data) {
  return axiosDownload("/user-boost/boost/export-shop", data, 2);
}
export function withdrawBoost(data) {
  return axiosPost("/user-boost/withdraw/" + data, {}, 2);
}
export function getUserBoostDiff(data) {
  return axiosPost("/user-boost/boost/diff", data, 2);
}
export function exportShopBonusV2(type, data) {
  return axiosDownload("/user-boost/boost/export-shop/" + type, data, 2);
}
// ------------  任务激励 ------------------------
export function pageTaskBoost(data) {
  return axiosPost( "/boost/page", data, 2);
}
export function getBoostDetail(data) {
  return axiosGet( "/boost/detail/" + data, {}, 2);
}
export function getTaskShopOptions(data) {
  return axiosPost( "/boost/options/" + data, {}, 2);
}
export function saveBoost(data) {
  return axiosPost( "/boost/save", data, 2);
}
export function getTaskSkuTag(data) {
  return axiosPost( "/boost/list-sku-tag", data, 2);
}
export function delBoost(data) {
  return axiosDelete( "/boost/" + data, {}, 2);
}
export function pageTaskGoods(c, data) {
  return axiosPost( "/boost/page-sku/" + c, data, 2);
}
export function getTaskBoostOptions(c) {
  return axiosGet( "/boost/task-boost-options?" + c,{}, 2);
}
export function getTaskBoostOptionsByTask(c) {
  return axiosGet( "/boost/task-boost-options/" + c, {}, 2);
}
// -------------------- 任务合集 ----------------------
export function listGroupTask(data) {
  return axiosGet("/task-group/group-list" + data, {}, 2);
}
export function listModuleTask(data) {
  return axiosPost("/task-module/list", data, 2);
}
export function saveModuleTask(data, org) {
  return axiosPost("/task-module/save/" + org, data, 2);
}
export function saveGroupTask(data, type, org) {
  return axiosPost("/task-group/" + org + "/" + type, data, 2);
}
export function saveGroupTaskSort(data) {
  return axiosPost("/task-group/sort", data, 2);
}
export function getGroupTaskDetail(data) {
  return axiosGet("/task-group/group-detail/" + data, {}, 2);
}

export function getRootTasks(data) {
  return axiosGet("/task-group/root" + data, {}, 2);
}

// --------------------- 分公司组织 ------------------------
export function pageOrgBranch(data) {
  return axiosPost("/org-branch/page", data, 2);
}
export function getOrgBranchTreeV1(data) {
  return axiosPost("/org-branch/v1/tree", data, 2);
}
export function pageOrgBranchV1(data) {
  return axiosPost("/org-branch/v1/page", data, 2);
}
export function saveTreeSort(data) {
  return axiosPost("/org-branch/v1/tree/sort", data, 2);
}
export function saveOrgBranch(data) {
  return axiosPostWithoutMessage("/org-branch", data, 2);
}
export function checkMain(data) {
  return axiosPost("/org-branch/check-main", data, 2);
}
export function listBranch(data) {
  return axiosPost("/org-branch/list", data, 2);
}
export function delBranch(data) {
  return axiosDeleteWithoutMessage("/org-branch/" + data, {}, 2);
}
export function importBranch(data) {
  return axiosPost("/org-branch/import", data, 2);
}
export function exportBranch(data) {
  return axiosDownload("/org-branch/export", data, 2);
}
export function changeMain(data) {
  return axiosPostWithoutMessage("/org-branch/main", data, 2);
}
export function importBranchV2(action,data) {
  return axiosPost("/org-branch/importFile-v2/" + action, data, 2);
}
export function exportBranchV2(data) {
  return axiosDownload("/org-branch/export-v2", data, 2);
}
export function batchDelBranch(data) {
  return axiosDelete("/org-branch", data, 2);
}

// ------------------- 问卷 -------------------
export function pageSurvey(data) {
  return axiosPost("/survey/page", data, 2);
}
export function saveSurvey(data) {
  return axiosPost("/survey", data, 2);
}
export function exportSurveyData(data) {
  return axiosDownload("/survey/export/" + data, {}, 2);
}
export function getSurveyDetail(data) {
  return axiosGet("/survey/" + data, {}, 2);
}
export function delSurvey(data) {
  return axiosDelete("/survey/" + data, {}, 2);
}
export function exportQuestionData(data) {
  return axiosDownload("/survey/question/export/" + data, {}, 2);
}
export function getModelUrls(data) {
  return axiosPost("/survey/getModelUrls", data, 2);
}
// ------------- 重点店铺审核 ---------------
export function pageShopAudit(data) {
  return axiosPost("/key-point/audit/page", data, 2);
}
export function auditShop(data) {
  return axiosPost("/key-point/audit/audit", data, 2);
}
export function batchAuditShop(data, type) {
  return axiosPost("/key-point/audit/batch-audit/" + type, data, 2);
}
export function getAunAuditSummary(data) {
  return axiosPost("/key-point/audit/summary", data, 2);
}
export function exportShopAuditRecords(data) {
  return axiosDownload("/key-point/audit/export", data, 2);
}
// --------------------任务合集2.0--------------------------
export function listTaskModules(data) {
  return axiosPost("/task-module/list", data, 2);
}
export function saveTaskModules(org, data) {
  return axiosPost("/task-module/save/" + org, data, 2);
}
export function getModuleUnUsedTasks(org) {
  return axiosPost("/task-module/unused/" + org, {}, 2);
}
export function getTaskSkuImage(data) {
  return axiosPost("/task-module/image/" + data, {}, 2);
}
export function syncModule(data) {
  return axiosPost("/task-module/sync", data, 2);
}

// -------------------------核销审核 ------------------------
export function pageHx(data) {
  return axiosPost("/verification/page", data, 2);
}
export function getHxDetail(data) {
  return axiosPost("/verification/detail", data, 2);
}
export function auditSingleHx(data) {
  return axiosPost("/verification/audit_single", data, 2);
}
export function auditBatchHx(data) {
  return axiosPost("/verification/audit_batch", data, 2);
}
export function getHxList() {
  return axiosGet("/verification/list", {}, 2);
}
export function exportVerification(data) {
  return axiosPost("/verification/export", data, 2);
}
export function getStatusOptions(data) {
  return axiosGet("/verification/options/" + data, {}, 2);
}

export function getVerificationOptions() {
  return axiosGet("/verification/options-verification", {}, 2);
}


export function pageVerificationConfs(data) {
  return axiosPost("/verification-config/page", data, 2);
}
export function saveVerificationConf(data) {
  return axiosPost("/verification-config/save", data, 2);
}
export function getVerificationConf(data) {
  return axiosPost("/verification-config/detail/" +data, {}, 2);
}
// ---------------------------任务权限控制----------------------------
export function pageUserAuth(data) {
  return axiosPost("/auth/page", data, 2);
}
export function saveUserAuthBatch(data) {
  return axiosPut("/auth/batch", data, 2);
}
export function saveUserAuth(data) {
  return axiosPut("/auth", data, 2);
}
export function delUserAuth(data) {
  return axiosDelete("/auth", data, 2);
}
export function delUserAuthBatch(data) {
  return axiosDelete("/auth/batch", data, 2);
}
export function getAuthResourceOptions(data) {
  return axiosPost("/auth/options", data, 2);
}

export function getUserResourceRoles(type, userName) {
  return axiosPost("/auth/" + type + "/" + userName, {}, 2);
}

export function importUserAuth(data) {
  return axiosPost("/auth/batch/resources", data, 2);
}

export function pageResource(data) {
  return axiosPost("/auth-resource/page", data, 2);
}
export function saveResource(data) {
  return axiosPut("/auth-resource", data, 2);
}
export function delResource(data) {
  return axiosDelete("/auth-resource", data, 2);
}

// ------------------- 板块 -------------------
export function queryPlatePageList(data) {
  return axiosPost("/plate/list", data, 2);
}

export function updatePlateShow(data) {
  return axiosPost("/plate/show/update", data, 2);
}

export function addPlate(data) {
  return axiosPost("/plate/add", data, 2);
}

export function queryPlateDetail(data) {
  return axiosGet("/plate/" + data, {}, 2);
}

export function editPlate(data) {
  return axiosPost("/plate/edit", data, 2);
}

export function queryPlateColorsList() {
  return axiosGet("/plate/colors/list", {}, 2);
}

export function queryPlateTitleList() {
  return axiosGet("/plate/title/list", {}, 2);
}

// ------------------- 话题 -------------------
export function queryTopicPageList(data) {
  return axiosPost("/topic/list", data, 2);
}

export function updateTopicRecommend(data) {
  return axiosPost("/topic/recommend/update", data, 2);
}

export function updateTopicStatus(data) {
  return axiosPost("/topic/status/update", data, 2);
}

export function queryTopicTitleList() {
  return axiosGet("/topic/title/list", {}, 2);
}

export function batchUpdateTopicStatus(data) {
  return axiosPost("/topic/status/batch-update", data, 2);
}

export function listBoardItemFeedbacks(data) {
  return axiosPost("/forum/listBoardItemFeedbacks", data, 4);
}

export function exportBoardItemFeedbacks(data) {
  return axiosDownload("/forum/exportBoardItemFeedbacks", data, 4);
}

// ------------------- 帖子 -------------------
export function queryExperiencePageList(data) {
  return axiosPost("/experience/list", data, 2);
}

export function updateExperienceShield(data) {
  return axiosPost("/experience/shield/update", data, 2);
}

export function updateExperienceEssenceFlag(data) {
  return axiosPost("/experience/essence_flag/update", data, 2);
}

export function updateExperienceStickyFlag(data) {
  return axiosPost("/experience/sticky_flag/update", data, 2);
}

export function updateExperienceShowInBoard(data) {
  return axiosPost("/experience/show-in-board/update", data, 2);
}

export function experienceListExport(data) {
  return axiosDownload("/experience/export", data, 2);
}

export function experienceListImport(data) {
  return axiosPost("/experience/importFile", data, 2);
}

export function experienceListFailedRecordsExport(data) {
  return axiosDownload("/experience/importFile/export", data, 2);
}

// ------------------- 商品特点 ----------------------
export function pageFeatures(data) {
  return axiosPost("/feature/page", data, 2);
}
export function importFeatures(data) {
  return axiosPost("/feature/import", data, 2);
}
export function saveFeatures(data) {
  return axiosPost("/feature", data, 2);
}
export function dictSkuModels(data) {
  return axiosGet("/grpSkuModel/dictModels" + data, {}, 2);
}
export function getSingle(data) {
  return axiosGet("/feature/" + data, {}, 2);
}
export function delFeature(data) {
  return axiosDelete("/feature/" + data, {}, 2);
}

// --------------------- 商品公共标签 -----------------------
export function pageSkuTags(data) {
  return axiosPost("/skuTag/page", data, 2);
}
export function getSkuTagDetail(data) {
  return axiosGet("/skuTag/" + data, {}, 2);
}
export function saveSkuTag(data) {
  return axiosPost("/skuTag", data, 2);
}
export function delSkuTag(data) {
  return axiosDelete("/skuTag/" + data, {}, 2);
}

// -----------------------商品包--------------------------------
export function pagePackage(data) {
  return axiosPost("/package/page", data, 2);
}
export function getPackageDetail(data) {
  return axiosGet("/package/" + data, {}, 2);
}
export function savePackage(data) {
  return axiosPost("/package/save", data, 2);
}
export function removePackage(data) {
  return axiosDelete("/package/" + data, {}, 2);
}
export function exportSkuPackage(data) {
  return axiosDownload("/package/export-sku/" + data, {}, 2);
}
// --------------------12阵地---------------------
export function pageModuleTargets(data) {
  return axiosPost("/module/page-target", data, 2);
}
export function listModules(data) {
  return axiosPost("/module/list-modules", data, 2);
}
export function importModuleMths(data, action) {
  return axiosPost("/module/import-module/" + action, data, 2);
}
export function importModuleTargets(data, action) {
  return axiosPost("/module/import-target/" + action, data, 2);
}
export function updateModules(data) {
  return axiosPost("/module/mth/update", data, 2);
}
export function updateTargets(data) {
  return axiosPost("/module/target/update", data, 2);
}
export function exportModules(data) {
  return axiosDownload("/module/mth-export", data, 2);
}
export function exportTargets(data) {
  return axiosDownload("/module/target/export", data, 2);
}
export function syncModuleDc(data) {
  return axiosPost("/module/mth/authAllDc", data, 2);
}

// ------------------ appBanner ----------------
export function pageBanners(data) {
  return axiosPost("/banner/page", data, 2);
}
export function saveBanner(data) {
  return axiosPost("/banner", data, 2);
}
export function delBanner(data) {
  return axiosDelete("/banner/" + data, {}, 2);
}
export function batchDelBanner(data) {
  return axiosDelete("/banner" + data, {}, 2);
}
// ---------------------- 渠道核销 ------------------------
export function pageQdHx(data) {
  return axiosPost("/qdHx/page", data, 2);
}
export function pageQdHxModule(data) {
  return axiosPost("/qdHx/module-page", data, 2);
}
export function listQdHxModuleConfig(data) {
  return axiosPost("/qdHx/module-config", data, 2);
}
export function getModuleDetail(data) {
  return axiosPost("/qdHx/module-detail", data, 2);
}
export function auditQdHx(data) {
  return axiosPost("app/qdHx/act/audit", data, 2);
}
export function getQdHxStatus(data) {
  return axiosPost("app/qdHx/act/getHxStatus", data, 2);
}
export function exportQdHxData(data) {
  return axiosDownload("/qdHx/export", data, 2);
}
export function getQueryField(data) {
  return axiosGet("/qdHx/module-query-config/" + data, {}, 2);
}
export function getQhHxBasicOptions() {
  return axiosGet("/qdHx/basic-options", {}, 2);
}
export function getQdHxTable(data) {
  return axiosGet("/qdHx/get-table/" + data, {}, 2);
}
export function getQdHxTableField(data) {
  return axiosGet("/qdHx/get-table-field/" + data, {}, 2);
}
export function getQdHxConfigDetail(data) {
  return axiosGet("/qdHx/" + data, {}, 2);
}
export function saveQdHxConfig(data) {
  return axiosPost("/qdHx", data, 2);
}
// -----------------------商品活动--------------------------------
export function pageActivity(data) {
  return axiosPost("/activity/page", data, 2);
}
export function getActivityDetail(data) {
  return axiosGet("/activity/" + data, {}, 2);
}
export function saveActivity(data) {
  return axiosPost("/activity/save", data, 2);
}
export function removeActivity(data) {
  return axiosDelete("/activity/" + data, {}, 2);
}
export function exportSkuActivity(data) {
  return axiosDownload("/activity/export-sku/" + data, {}, 2);
}
export function hideActivity(id, orgCode, type) {
  return axiosPost("/activity/hide/" + id + '/' + orgCode + '/' + type, {}, 2);
}
// -----------------------238预估补货-重点商品--------------------------------
export function page238Top(data, orgCode, dcCode) {
  return axiosPost("/task-top/page/" + orgCode + "/" + dcCode, data, 2);
}
export function export238Top(data, orgCode, dcCode) {
  return axiosDownload("/task-top/export/" + orgCode + "/" + dcCode, data, 2);
}
export function import238Top(type, data) {
  return axiosPost("/task-top/importFile/" + type, data, 2);
}
export function remove238Top(data) {
  return axiosPost("/task-top/remove", data, 2);
}
export function copy38Top(data) {
  return axiosPost("/task-top/copy", data, 2);
}
// -----------------------店铺合并--------------------------------
export function pageShopMerge(data) {
  return axiosPost("/merge/page", data, 2);
}
export function saveShopMerge(data) {
  return axiosPost("/merge/save", data, 2);
}
export function removeSingleMerge(data) {
  return axiosDelete("/merge/single", data, 2);
}
export function removeShopMerge(data) {
  return axiosDelete("/merge", data, 2);
}
// -----------------------区域分组--------------------------------
export function pageAreaTags(data) {
  return axiosPost("/area-target/area-tag", data, 2);
}
export function pageAreaTagShop(data) {
  return axiosPost("/area-target/shop", data, 2);
}
export function pageAreaShopTarget(data) {
  return axiosPost("/area-target/shop-target", data, 2);
}
export function changeAreaTag(data) {
  return axiosPost("/area-target/change/area-tag", data, 2);
}
export function removeAreaTag(data) {
  return axiosPost("/area-target/remove/area-tag", data, 2);
}
export function getAreaTargetDetail(data) {
  return axiosPost("/area-target/detail/target", data, 2);
}
export function importShopAreaTag(data, action) {
  return axiosPost("/area-target/area-tag/importFile/" + action, data, 2);
}
export function exportShopAreaTag(data) {
  return axiosDownload("/area-target/area-tag/export", data, 2);
}
export function getTargetType(data) {
  return axiosPost("/area-target/target-type", data, 2);
}
export function changeAmountTYpe(data) {
  return axiosPost("/area-target/target-type/change", data, 2);
}
export function saveAreaTargets(data) {
  return axiosPost("/area-target/area-tag/target", data, 2);
}
export function saveAreaShopTargets(data) {
  return axiosPost("/area-target/shop/target", data, 2);
}
export function importAreaTarget(data) {
  return axiosPost("/area-target/area-tag/target/importFile", data, 2);
}
export function importShopTargets(data) {
  return axiosPost("/area-target/shop/target/importFile", data, 2);
}
export function exportAreaTargetData(data) {
  return axiosDownload("/area-target/area-tag/target/export", data, 2);
}
export function exportShopTargetData(data) {
  return axiosDownload("/area-target/shop/target/export", data, 2);
}
export function getDcAreaTagSorts(dcCode) {
  return axiosGet("/area-target/tag-sort/" + dcCode, {}, 2);
}
export function saveDcAreaTagSort(data) {
  return axiosPost("/area-target/tag-sort" , data, 2);
}
// --------------------   路线设置接口  -----------------------
export function importRoute(data) {
  return axiosPost("/route/importFile", data, 2);
}
export function exportRoute(data) {
  return axiosDownload("/route/export", data, 2);
}
export function getRouteList(data) {
  return axiosPost("/route/page", data, 2);
}
export function saveRoute(data) {
  return axiosPost("/route/save", data, 2);
}
export function getRouteSelectList(data) {
  return axiosGet("/dict/group/shop-route" + data, null, 2);
}
export function saveRouteRule(data) {
  return axiosPost("/route/saveRoute", data, 2);
}
export function getRouteRule(data) {
  return axiosPost("/route/getRoute", data, 2);
}
export function getDcRouteList(dcCode) {
  return axiosGet(`/route/getDcRoute/${dcCode}`, null, 2);
}
export function editDcRoute(data) {
  return axiosPost("/route/updateDcRoute", data, 2);
}
export function delDcRoute(data) {
  return axiosPost("/route/changeShopsRoute", data, 2);
}

// -----------------------无库存下单相关接口开始--------------------------------
export function getNoStockOrderDcConfig(data) {
  return axiosPost("/no-stock-order/dc-config/page", data, 2);
}
export function updateDcConfig(data) {
  return axiosPut("/no-stock-order/dc-config/" + data.dcCode, data, 2);
}
export function batchUpdateDcConfig(data) {
  return axiosPut("/no-stock-order/dc-config/batch-update", data, 2);
}
export function importDcConfig(data) {
  return axiosDownload("/no-stock-order/dc-config/importFile", data, 2);
}
export function importDcItem(data, type) {
  return axiosDownload("/no-stock-order/dc-item/importFile?importType=" + type, data, 2);
}
export function getNoStockOrderDcItems(data) {
  return axiosPost("/no-stock-order/dc-item/page", data, 2);
}
export function addDcItem(data) {
  return axiosPost("/no-stock-order/dc-item/add", data, 2);
}
export function deleteDcItem(data) {
  return axiosDelete("/no-stock-order/dc-item/" + data.orgCode + "/" + data.dcCode + "/" + data.skuCode, data, 2);
}
export function exportDcItem(data) {
  return axiosDownload("/no-stock-order/dc-item/export", data, 2);
}
export function getNoStockOrder(data) {
  return axiosPost("/no-stock-order/order/page", data, 2);
}
export function exportNoStockOrder(data) {
  return axiosDownload("/no-stock-order/order/detail/export", data, 2);
}
export function getNoStockOrderDetail(data) {
  return axiosPost("/no-stock-order/order/detail", data, 2);
}

export function noStockOrderClose(data) {
  return axiosPost("/no-stock-order/order/close", data, 2);
}

export function noStockOrderItemClose(data) {
  return axiosPost("/no-stock-order/order/item/close", data, 2);
}

export function uploadNoStockOrderItemFh(data) {
  return axiosPost("/no-stock-order/order/batch/delivery/importFile", data, 2);
}

export function downloadNoStockOrderItemFh(data) {
  return axiosDownload("/no-stock-order/order/batch/delivery/importFile/export", data, 2);
}

export function getNoStockOrderDetailReport(data) {
  return axiosPost("/no-stock-order/order/detail/report", data, 2);
}

export function getNoStockOrderDetailReportOverview(data) {
  return axiosPost("/no-stock-order/order/detail/report/overview", data, 2);
}

export function exportNoStockOrderDetailReport(data) {
  return axiosDownload("/no-stock-order/order/detail/report/export", data, 2);
}
// -----------------------无库存下单相关接口结束--------------------------------
// -----------------------绩效--------------------------------
export function pageTargetData(data) {
  return axiosPost("/target/page", data, 8);
}
export function getTargetDetail(data) {
  return axiosGet("/target/detail/" + data, {}, 8);
}
export function saveTargetData(data) {
  return axiosPost("/target", data, 8);
}
export function importTargetData(type, orgCode, data) {
  return axiosPost("/target/importFile/" + type + "/" + orgCode, data, 8);
}

export function removeTarget(data) {
  return axiosDelete("/target/" + data, {}, 8);
}
export function saveTargetSort(data) {
  return axiosPost("/target/sort", data, 8);
}
export function copyTarget(id, data) {
  return axiosPost("/target/copy/" + id, data, 8);
}
export function pageSummary(id, data) {
  return axiosPost("/target/summary/" + id, data, 8);
}
export function exportSummary(id, data) {
  return axiosDownload("/target/export-summary/" + id, data, 8);
}
export function pageSummaryDetail(id, userName, data) {
  return axiosPost("/target/summary-detail/" + id + "?userName=" + (userName? userName: ''), data, 8);
}
export function exportSummaryDetail(id, userName, data) {
  return axiosDownload("/target/export-summary-detail/" + id + "?userName=" + (userName? userName: ''), data, 8);
}

export function listUserSummary(data) {
  return axiosPost("/target/user-summary/", data, 8);
}
export function exportUserSummary(data) {
  return axiosDownload("/target/export-user-summary/", data, 8);
}
export function listUserTargetSummary(data) {
  return axiosPost("/target/user-target-summary/", data, 8);
}
export function exportUserTargetSummary(data) {
  return axiosDownload("/target/export-user-target-summary/", data, 8);
}



export function getTagUserTree(data) {
  return axiosGet("/jx-user/list/" + data, {}, 8);
}
export function pageUserTag(data) {
  return axiosPost("/jx-user/page", data, 8);
}
export function saveUserTag(data) {
  return axiosPost("/jx-user/save", data, 8);
}
export function removeUserTag(id) {
  return axiosDelete("/jx-user/" + id, {}, 8);
}
export function importUserTag(orgCode, data) {
  return axiosPost("/jx-user/importFile/" + orgCode, data, 8);
}

export function getTags(orgCode) {
  return axiosPost("/jx-tag/list?orgCode=" + orgCode, {}, 8);
}
export function saveTag(data) {
  return axiosPost("/jx-tag", data, 8);
}
export function removeTag(id) {
  return axiosDelete("/jx-tag/" + id, {}, 8);
}

export function getQuotas(orgCode) {
  return axiosGet("/jx-quota/quota/" + orgCode, {}, 8);
}
export function getQuotaLimits(data) {
  return axiosGet("/jx-quota/quota-limit/" + data, {}, 8);
}
export function pageJxQuota(data) {
  return axiosPost("/jx-quota/page", data, 8);
}
export function saveJxQuota(data) {
  return axiosPost("/jx-quota", data, 8);
}
export function removeQuota(id) {
  return axiosDelete("/jx-quota/" + id, {}, 8);
}

export function queryDcSystemConfigPageList(data) {
  return axiosPost("/dc_external_system_config_mapping/page/list", data, 2);
}

export function deleteDcSystemConfig(data) {
  return axiosPost("/dc_external_system_config_mapping/delete", data, 2);
}

export function addDcSystemConfig(data) {
  return axiosPost("/dc_external_system_config_mapping/add", data, 2);
}

export function editDcSystemConfig(data) {
  return axiosPost("/dc_external_system_config_mapping/edit", data, 2);
}

export function queryDcExternalShopConfigPageList(data) {
  return axiosPost("/dc_external_shop_mapping/page/list", data, 2);
}

export function updateDcExternalShopConfig(data) {
  return axiosPost("/dc_external_shop_mapping/edit", data, 2);
}


// -----------------  店铺改造 ---------------
export function pageStores(data) {
  return axiosPost("/store/page", data, 10);
}
export function importStoreAddress(data) {
  return axiosPost("/store/importFile/address", data, 10);
}
export function importStoreResp(data) {
  return axiosPost("/store/importFile/resp", data, 10);
}
export function importStoreTop(data) {
  return axiosPost("/store/importFile/top", data, 10);
}
export function updateStore(data) {
  return axiosPut("/store", data, 10);
}
export function getStoreInfo(poiCode, queryTop) {
  return axiosGet("/store/" + poiCode + "?queryTop=" + queryTop, {}, 10);
}
export function exportStore(data) {
  return axiosDownload("/store/export/", data, 10);
}
export function exportStores(data) {
  return axiosDownload("/store/export", data, 10);
}
export function exportStoreSummary(data) {
  return axiosDownload("/store/export-summary", {}, 10);
}
// ------------- 店铺审核新 -----------------
export function pageShopAuditNew(data) {
  return axiosPost("/store/audit/page", data, 10);
}
export function auditShopNew(data) {
  return axiosPost("/store/audit/audit", data, 10);
}
export function batchAuditShopNew(data, type) {
  return axiosPost("/store/audit/batch-audit/" + type, data, 10);
}
export function getAunAuditSummaryNew(data) {
  return axiosPost("/store/audit/summary", data, 10);
}
