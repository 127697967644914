<template>
  <div class="new-box">
    <div class="mg-search" >
    <el-card style="margin: 10px;">
        <SearchFilter ref="queryFormRef" :model="queryForm"  @search="handleFilter('hand')" @reset="resetQuery" @keyup.enter.native="handleFilter">
      <!-- <el-form ref="queryFormRef" size="mini" label-width="auto" :inline="true" :model="queryForm" @keyup.enter.native="handleFilter"> -->
        <el-form-item label="任务所配分公司" prop="orgCode">
          <el-select v-model="queryForm.orgCode" filterable default-first-option @change="handleOrgChange">
            <el-option v-for="item in options.orgOptions" :key="item.value" :value="item.value" :label="item.value + '-' + item.label" />
          </el-select>
        </el-form-item>
        <el-form-item label="任务"  prop="taskId">
          <el-select v-model="queryForm.taskId" filterable default-first-option>
            <el-option v-for="item in options.taskOptions" :key="item.mtTaskId" :value="item.mtTaskId" :label="'(' + item.orgName + ')'+ item.taskName" />
          </el-select>
        </el-form-item>
        <el-form-item label="业务员所属分公司" prop="authOrgCode">
          <el-select v-model="queryForm.authOrgCode" filterable default-first-option>
            <el-option v-for="item in options.authOrgOptions" :key="item.value" :value="item.value" :label="(item.value?item.value + '-': '') + item.label" />
          </el-select>
        </el-form-item>

        <el-form-item label="配送中心"  prop="dcCode">
          <DictSelect
            ref="dc"
            type="dc"
            :value.sync="queryForm.dcCode"
            :dictFun="dictFun"
            fullLabel
            @getOtherJsonParam="(callback) => callback({ orgCode: queryForm.authOrgCode})"
            parentLinkageRef
          />
        </el-form-item>
        <el-form-item label="审核状态" prop="status">
          <el-select v-model="queryForm.status">
            <el-option v-for="(item,key) in options.approveOptions" :key="key" :value="item.value" :label="item.label" />
          </el-select>
        </el-form-item>
        <el-form-item label="未审核金额" prop="leftSend">
          <el-select v-model="queryForm.leftSend">
            <el-option label="全部" value="" />
            <el-option label="未审核金额>0" value="1" />
            <el-option label="未审核金额<0" value="2" />
          </el-select>
        </el-form-item>
        <el-form-item label="用户账号" prop="userName">
          <el-input v-model="queryForm.userName" clearable placeholder="请输入用户账号/姓名" />
        </el-form-item>
        <el-form-item label="账号状态" prop="userStatus">
          <el-select v-model="queryForm.userStatus">
            <el-option label="全部" value="" />
            <el-option label="正常" value="1" />
            <el-option label="关闭" value="2" />
          </el-select>
        </el-form-item>
        <el-form-item label="红包状态" prop="acceptStatus">
          <el-select v-model="queryForm.acceptStatus">
            <el-option label="全部" value="" />
            <el-option label="待领取" value="0" />
            <el-option label="已领取" value="1" />
            <el-option label="已作废" value="2" />
          </el-select>
        </el-form-item>

        <!-- <el-form-item>
          <el-button class="form-btn-item" size="mini" icon="el-icon-search" type="primary" @click="handleFilter('hand')">查询</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-download" type="primary" @click="handleExport">按用户导出</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-download" type="primary" @click="handleExportShopV2('0')">未审核店铺激励导出</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-download" type="primary" @click="handleExportShopV2('1')">已审核店铺激励导出</el-button>
          <el-button v-if="authUsers.indexOf(currentUser) > -1" class="form-btn-item" size="mini" icon="el-icon-download" type="primary" @click="handleExportShop">按店铺导出</el-button>
        </el-form-item> -->
<!--        <el-form-item>-->
<!--          <template #label>-->
<!--            快捷审核<el-popover placement="top-start" width="200" trigger="hover" content="批量审核符合筛选条件的全部未审核记录"><i slot="reference" class="el-icon-question"/></el-popover>-->
<!--          </template>-->
<!--          <el-button class="form-btn-item" size="mini" type="primary" @click="handleApproveAll('allAgree')">全部通过</el-button>-->
<!--          <el-button class="form-btn-item" size="mini" type="primary" @click="handleApproveAll('allRefuse')">全部不通过</el-button>-->
<!--        </el-form-item>-->
      <!-- </el-form> -->
    </SearchFilter>
    </el-card>
    </div>
    <el-card style="margin: 10px;" class="box-card">
    <div >

    <!-- <div v-if="(queryForm.orgCode!='ALL' || orgAuthAll)" >
      <el-button size="mini" type="primary" @click="handleBatchApprove('batchAgree')">批量通过</el-button>
      <el-button size="mini" type="primary" @click="handleBatchApprove('batchRefuse')">批量不通过</el-button>
    </div> -->
    <div style="margin-bottom: 10px;">
        <span v-if="queryForm.orgCode!='ALL' || orgAuthAll">
      <span>快捷审核<el-popover placement="top-start" width="200" trigger="hover" content="批量审核符合筛选条件的全部未审核记录"><i slot="reference" class="el-icon-question"/></el-popover></span>
      <span>
          <el-button class="form-btn-item" size="mini" type="primary" @click="handleApproveAll('allAgree')">全部通过</el-button>
          <el-button class="form-btn-item" size="mini" type="primary" @click="handleApproveAll('allRefuse')">全部不通过</el-button>
          <el-button size="mini" type="primary" @click="handleBatchApprove('batchAgree')">批量通过</el-button>
          <el-button size="mini" type="primary" @click="handleBatchApprove('batchRefuse')">批量不通过</el-button>
        </span>
    </span>
        <el-button style="margin-left: 10px;" class="form-btn-item" size="mini" icon="el-icon-download" type="primary" @click="handleExport">按用户导出</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-download" type="primary" @click="handleExportShopV2('0')">未审核店铺激励导出</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-download" type="primary" @click="handleExportShopV2('1')">已审核店铺激励导出</el-button>
          <el-button v-if="authUsers.indexOf(currentUser) > -1" class="form-btn-item" size="mini" icon="el-icon-download" type="primary" @click="handleExportShop">按店铺导出</el-button>

    </div>
    <div style="margin-bottom: 10px;">
      <span ><strong>审批金额更新时间：</strong>{{ summaryData.leastTime }}</span>
      <span style="margin: 5px">统计</span>
      <span style="margin: 5px"><strong>用户：</strong> {{summaryData.userNum}}</span>
      <span style="margin: 5px"><strong>业务激励金额：</strong> {{summaryData.boostBonus}}</span>
      <span style="margin: 5px"><strong>发放金额：</strong> {{summaryData.packetAmount}}</span>
      <span style="margin: 5px"><strong>已通过金额：</strong> {{summaryData.approveAmount}}</span>
      <span style="margin: 5px"><strong>已拒绝金额：</strong> {{summaryData.refuseAmount}}</span>
      <span style="margin: 5px"><strong>未审核金额：</strong> {{summaryData.noApproveAmount}}</span>
    </div>
    <!-- <div >
      <span style="margin: 5px">审批金额更新时间：{{ summaryData.leastTime }}</span>
      <span style="margin: 5px">统计</span>
      <span style="margin: 5px">用户： {{summaryData.userNum}}</span>
      <span style="margin: 5px">业务激励金额： {{summaryData.boostBonus}}</span>
      <span style="margin: 5px">发放金额： {{summaryData.packetAmount}}</span>
      <span style="margin: 5px">已通过金额： {{summaryData.approveAmount}}</span>
      <span style="margin: 5px">已拒绝金额： {{summaryData.refuseAmount}}</span>
      <span style="margin: 5px">未审核金额： {{summaryData.noApproveAmount}}</span>
    </div> -->
</div>
    <el-table
      ref="tableRef"
      :data="pageData.rows"
            size="small"
            class="customer-table"
            border
      v-loading="loading"
      highlight-current-row
      @sort-change="handleSortChange"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="50"  :selectable="(row, index) => {
        return row.list.filter(e => e.status===0 && e.packetAmount > 0).length>0
      }" />
      <el-table-column label="用户账号" prop="userName"  width="110"/>
      <el-table-column label="用户姓名" prop="nickName"   width="110"/>
      <el-table-column label="账号状态" prop="statusText"   width="100"/>
      <el-table-column label="用户手机号" prop="phoneNumber"  width="140"/>
      <el-table-column label="所属分公司" prop="orgName"   width="120"/>
      <el-table-column label="配送中心" prop="dcName"   show-overflow-tooltip width="160"/>
      <el-table-column label="业务激励金额（元）" prop="boostBonus"  sortable  width="180">
        <template #default="{ row }">
          <div>
            <span>{{row.boostBonus}}</span>
            <el-button v-if="row.boostBonus && row.boostBonus>0" size="mini" type="text" style="border: 0px; margin-left:5px" @click="handleGetBoost(row)">明细</el-button>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="待审核金额" prop="packetAmount" width="120" >
        <template #default="{ row }">
          <el-row>
            <el-col v-for="(item,index) in row.list" :key="index">
              <span>{{item.packetAmount}}</span>
              <span v-if="item.packetAmount!==item.expectPacketAmount" style="color: red">(修改前 {{ item.expectPacketAmount }})</span>
<!--              <el-button v-if="showCompare(item, row)" size="mini" type="text" style="border: 0px; margin-left:5px" @click="handleGetUserDiff(item)">对比明细</el-button>-->
            </el-col>
          </el-row>
        </template>
      </el-table-column>
      <el-table-column label="审核状态" prop="status" width="120">
        <template #default="{ row }">
          <el-row>
            <el-col v-for="(item,index) in row.list" :key="index">
              <span :style="item.status === 0? {color: '#EE7712'}: ''">{{approveMap[item.status]}}</span>
            </el-col>
          </el-row>
        </template>
      </el-table-column>
      <el-table-column label="红包状态" prop="acceptStatus" width="120" >
        <template #default="{ row }">
          <el-row>
            <el-col v-for="(item,index) in row.list" :key="index">
              <span v-if="item.acceptStatus!==undefined && item.acceptStatus!==null" :class="statusClass[item.acceptStatus]">{{statusMap[item.acceptStatus]}}</span>
              <span v-else>未发放</span>
              <el-popover
                v-if="item.acceptStatus!==undefined && item.acceptStatus!==null"
                placement="right"
                width="300"
                title="详情"
                trigger="click">
                <div style="max-height: 400px !important; overflow-y: auto;">
                  <el-row v-for="(e,index) in item.packets" :key="index">
                    <el-col v-if="item.packets.length > 1"><span style="font-weight: 600;">红包{{ index + 1 }}</span></el-col>
                    <el-col>
                      <span>红包金额（元）：</span><span style="color:red">{{e.amount ? (e.amount/100).toFixed(2): ''}}</span>
                    </el-col>
                    <el-col>
                      <span>发放时间：</span><span>{{e.sendTime}}</span>
                    </el-col>
                    <el-col>
                      <span>领取状态：</span><span>{{statusMap[e.acceptStatus]}}</span> <span v-if="e.acceptStatus===1">{{item.acceptTime}}</span>
                    </el-col>
                  </el-row>
                </div>
                <el-button slot="reference" type="text" size="mini">详情</el-button>
              </el-popover>
            </el-col>
          </el-row>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="260" align="center" fixed="right">
        <template #default="{ row }">
          <el-row>
            <el-col v-for="(item,index) in row.list" :key="index">
              <el-button v-show="item.status===0 && item.packetAmount>0 && (queryForm.orgCode!='ALL' || orgAuthAll)" size="mini" type="text" style="border: 0px;" @click="handleAudit(item, row, 1)">通过</el-button>
              <el-button v-show="item.status===0 && item.packetAmount>0 && (queryForm.orgCode!='ALL' || orgAuthAll)" size="mini" type="text" style="border: 0px;" @click="handleAudit(item, row, 2)">不通过</el-button>
              <span v-show="item.status!==0">{{item.approveUser}} {{ parseTime(item.approveTime, '{y}-{m}-{d}')}}</span>
              <el-popover
                v-show="['1','2'].indexOf(item.status + '') > -1"
                placement="right"
                width="300"
                title="审批详情"
                trigger="click">
                <el-row>
                  <el-col>
                    <span>审批人：</span><span>{{item.approveUser}}</span>
                  </el-col>
                  <el-col>
                    <span>审批时间：</span><span>{{item.approveTime}}</span>
                  </el-col>
                  <el-col>
                    <span>审批金额：</span><span style="color:red; font-size: 18px">{{item.packetAmount}}元</span> <span v-if="item.packetAmount!==item.expectPacketAmount">修改前{{ item.expectPacketAmount }}元</span>
                  </el-col>
                  <el-col>
                    <span>审批备注：</span><span>{{item.remark}}</span>
                  </el-col>
                </el-row>
                <el-button slot="reference" type="text" size="mini">详情</el-button>
              </el-popover>
            </el-col>
          </el-row>
        </template>
      </el-table-column>
    </el-table>
    <Pagination :total="pageData.total" :page.sync="queryForm.current" :limit.sync="queryForm.size" @pagination="handleQuery" />

</el-card>
    <el-dialog title="激励明细" :visible.sync="boostDialog.visible" append-to-body width="500px" :close-on-click-modal="false">
      <div v-if="boostDialog.boosts && boostDialog.boosts.length > 0" style="overflow-y: scroll;height: 500px">
        <el-tabs v-model="boostDialog.tabName">
          <el-tab-pane v-for="item in boostDialog.boosts" :key="item.boostId" :label="item.boostName + '(' + (item.boostBonus?item.boostBonus:0) +')'" :name="item.boostId" style="background-color: #FCF7EE;border-radius: 5px; margin: 10px;padding: 5px;">
            <div style="margin:5px">
              <label style="color: #a18d49;">进货激励：</label><span>{{item.description}}</span>
            </div>
            <div style="margin:10px">
              <label style="color: #a18d49;">达成店铺数：</label><span>{{item.shopsDescription}}</span>
            </div>
            <el-row  style="text-align: center">
              <el-col :span="12" ><label style="text-align: center;color: #a18d49;">达成店铺数</label></el-col><el-col :span="12"><label style="color: #a18d49;">预计业务奖励</label></el-col>
              <el-col :span="12"><span style="color: red;font-size: 18px">{{ item.boostShopCount?item.boostShopCount: 0 }}</span>家</el-col>
              <el-col :span="12"><span style="color: red;font-size: 18px">{{ item.boostBonus?item.boostBonus:0 }}</span>元</el-col>
              <el-col v-if="item.shopsBoostBonus"><span>含达标店铺数额外奖励</span><span style="color: red"> {{ item.shopsBoostBonus }}元</span></el-col>
            </el-row>
          </el-tab-pane>
        </el-tabs>
        <div>
          <el-table :data="boostDialog.boostDetailList"  v-loading="boostDialog.loading" size="mini" max-height="300">
            <el-table-column label="终端名称" prop="shopName">
              <template #default="{ row }">
                  <el-button type="text" @click="() => {
                    shopDetailDialog.shopName = row.shopName;
                    shopDetailDialog.visible =true;
                    const data ={
                      poiCode: row.poiCode,
                      boostId: boostDialog.tabName,
                      mtTaskId: queryForm.taskId,
                      username: row.userName
                    };
                    handleShopDetail(Object.assign(defaultQueryObj, data));
                  }">{{row.shopName}}</el-button>
              </template>
            </el-table-column>
            <el-table-column label="奖励金额" prop="boostBonus" width="100" />
          </el-table>
        </div>
      </div>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button type="primary" size="mini" @click="boostDialog.visible = false">关闭</el-button>
      </div>
    </el-dialog>

    <el-dialog title="店铺激励明细"  :visible.sync="shopDetailDialog.visible" append-to-body width="500px" :close-on-click-modal="false">
      <div style="margin: 5px">
        <span>店铺名称： </span><span>{{shopDetailDialog.shopName}}</span>
      </div>
      <el-tabs v-model="shopDetailDialog.tabName">
        <el-tab-pane v-for="item in shopDetailDialog.list" :key="item.measure" :label="item.measureName" :name="item.measure" style="overflow-y: scroll;height: 400px">
          <el-table :data="item.data"  v-loading="shopDetailDialog.loading" size="mini">
            <el-table-column v-for="e in item.columns" :label="columnMap[e]" :prop="e" :key="e" />
          </el-table>
        </el-tab-pane>
      </el-tabs>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button type="primary" size="mini" @click="shopDetailDialog.visible = false"> 关闭</el-button>
      </div>
    </el-dialog>

    <el-dialog title="红包记录"  :visible.sync="packetDialog.visible" append-to-body width="500px" :close-on-click-modal="false">
      <el-table :data="packetDialog.list"  v-loading="packetDialog.loading" size="mini">
        <el-table-column label="红包金额（元）" prop="amount" width="250" />
        <el-table-column label="发放时间" prop="sendTime" width="280" />
        <el-table-column label="状态" prop="status" width="280">
          <template #default="{ row }">
            <span>{{ statusMap[row.status]}}{{ row.status===1? row.acceptTime:''}} </span>
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button type="primary" size="mini" @click="packetDialog.visible = false">关闭</el-button>
      </div>
    </el-dialog>

    <el-dialog :title="updateDialog.titleMap[updateDialog.title]" :center="true" :visible.sync="updateDialog.visible" append-to-body width="500px" :close-on-click-modal="false">
      <el-form ref="queryForm" size="mini" label-width="auto" :inline="true" :model="updateDialog.form">
        <el-row v-if="updateDialog.title.indexOf('all') < 0 && updateDialog.title.indexOf('batch') < 0">
          <el-col>
            <el-form-item label="用户姓名">
              <span>{{updateDialog.form.nickName}}</span>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item label="用户账号">
              <span>{{updateDialog.form.userName}}</span>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item label="审批金额">
              <span style="color:red">{{updateDialog.form.expectPacketAmount}}元</span>
            </el-form-item>
          </el-col>
          <el-col v-if="updateDialog.form.status===1" style="margin-bottom: 10px">
            <el-form-item label="修改为">
                <el-input-number v-model="updateDialog.form.packetAmount" style="width:90%" :controls="false" :min="0" :precision="2" placeholder="非必填，请谨慎操作" />元
            </el-form-item>
            <div style="font-size: 10px; margin-left: 50px">
              <span>注意修改仅针对本次金额，不会影响以后的发放（修改导致的差额之后不会补发或扣除）。</span>
            </div>
          </el-col>
          <el-col>
            <el-form-item label="审批备注">
              <el-input v-model="updateDialog.form.remark" type="textarea" placeholder="非必填" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-else>
          <el-col style="color: #66b1ff">
            <span>已选择{{updateDialog.allForm.userNum}}人， 业务激励金{{updateDialog.allForm.boostBonus}}元，未审核金额{{updateDialog.allForm.noApproveAmount}}元</span>
          </el-col>
          <el-col>
            <el-form-item label="审核备注">
              <el-input v-model="updateDialog.allForm.remark" type="textarea" placeholder="非必填" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button type="primary" size="mini" @click="handleUpdateAmount()">{{updateDialog.title.indexOf('gree')>-1? '通过': '不通过'}}</el-button>
        <el-button type="primary" size="mini" @click="updateDialog.visible = false">关闭</el-button>
      </div>
    </el-dialog>

    <el-dialog title="查看对比明细" :visible.sync="diffDialog.visible" append-to-body width="1000px" :close-on-click-modal="false">
      <el-tabs v-model="diffDialog.tabName">
        <el-tab-pane label="包干负责人" name="resp" style="overflow-y: scroll;height: 400px">
          <el-table :data="diffDialog.form.diffReps" empty-text="仅2024年1月16号以后审核通过的数据才可进行数据对比" v-loading="shopDetailDialog.loading" size="mini">
            <el-table-column label="所属配送中心" prop="dcName" width="180">
              <template #default="{ row }">
                <span>{{ row.dcCode + "-" + row.dcName }}</span>
              </template>
            </el-table-column>
            <el-table-column label="店铺编码/名称" prop="shop" width="250">
              <template #default="{ row }">
                <span>{{ row.shopCode + "-" + row.shopName }}</span>
              </template>
            </el-table-column>
            <el-table-column label="对比前" align="center">
              <el-table-column label="日期" prop="dateKey" width="100" align="center" />
              <el-table-column label="包干负责人" prop="userName" width="120">
                <template #default="{ row }">
                  <span>{{ (row.userName? row.userName + "-" + row.nickName: '') }}</span>
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column label="对比后" align="center">
              <el-table-column label="日期" prop="afterDateKey" width="100" align="center" />
              <el-table-column label="包干负责人" prop="afterUserName" width="120">
                <template #default="{ row }">
                  <span>{{ (row.afterUserName? row.afterUserName + "-" + row.afterNickName: '') }}</span>
                </template>
              </el-table-column>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="进货数据" name="jh" style="overflow-y: scroll;height: 400px">
          <el-table :data="diffDialog.form.diffReps" empty-text="仅2024年1月16号以后审核通过的数据才可进行数据对比" v-loading="shopDetailDialog.loading" size="mini">
            <el-table-column label="所属配送中心" prop="dcName" width="180">
              <template #default="{ row }">
                <span>{{ row.dcCode + "-" + row.dcName }}</span>
              </template>
            </el-table-column>
            <el-table-column label="店铺编码/名称" prop="shop" width="250">
              <template #default="{ row }">
                <span>{{ row.shopCode + "-" + row.shopName }}</span>
              </template>
            </el-table-column>
            <el-table-column label="对比前" align="center">
              <el-table-column label="日期" prop="dateKey" width="100" align="center" />
              <el-table-column label="进货金额" prop="dqJhTotal" width="100" align="center" />
              <el-table-column label="上柜款数" prop="dqJhKss" width="100" align="center" />
            </el-table-column>
            <el-table-column label="对比后" align="center">
              <el-table-column label="日期" prop="afterDateKey" width="100" align="center" />
              <el-table-column label="进货金额" prop="afterDqJhTotal" width="100" align="center" />
              <el-table-column label="上柜款数" prop="afterDqJhKss" width="100" align="center" />
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import {  dictSelect, pageTask, pageUserBoost, getBoost, getUserBoostDetail, getBoostShopDetail, updatePacketAmount,getCurrentUser,
  approvalPacket, exportBoostPacket, getSummaryData, batchApprovalRecord, getOrgTaskOption,approvalAllRecord, exportShopBonus, getUserBoostDiff, exportShopBonusV2 } from "@/api/appTask";
import { Notification } from "element-ui";
import DictSelect from "@/components/DictSelect";
import SearchFilter from "@/components/SearchFilter/index";
import {deepClone, parseTime} from "../../../utils";
export default {
  name: "BoostPacket",
  components: { Pagination, DictSelect, SearchFilter },
  data() {
    return {
      dictFun: dictSelect,
      deepClone,parseTime,
      statusMap: {
        0: '待领取',
        1: '已领取',
        2: '已作废',
        3: '领取失败',
        4: '部分领取',
      },
      statusClass: {
        0: 'wait-class',
        1: 'accept-class',
        2: 'abodon-class',
        3: 'error-class',
        4: 'blue-class',
      },
      approveMap: {
        0: '未审核',
        1: '已通过',
        2: '已拒绝',
      },
      columnMap: {
        jhTotal: '进货金额',
        jhCount: '进货个数',
        jhKss: '上柜款数',
        jhKssDiff: '新增款数',
        jhTotalJbp: '进货金额(仅聚宝盆)',
        jhCountJbp: '进货个数(仅聚宝盆)',
        jhKssJbp: '上柜款数(仅聚宝盆)',
        jhKssDiffJbp: '新增款数(仅聚宝盆)',
        attributeName: '--'
      },
      isExpansion: true,
      pageData: {
        total: 0,
        rows: [],
        selections: [],
      },
      queryForm: {
        current: 1,
        size: 10,
        taskId: undefined,
        orgCode: undefined,
        leftSend: '',
        userName: undefined,
        status: '',
        sorts: undefined,
        authOrgCode: '',
        userStatus: '',
        acceptStatus: ''
      },
      lastQueryForm: {
        current: 1,
        size: 10,
        taskId: undefined,
        orgCode: undefined,
        leftSend: '',
        userName: undefined,
        status: '',
        sorts: undefined,
        authOrgCode: '',
      },
      summaryData: {
        userNum: undefined,
        boostBonus: undefined,
        packetAmount: undefined,
        noApproveAmount: undefined,
        approveAmount: undefined,
        leastTime: undefined,
        refuseAmount: undefined,
      },
      loading: false,
      dictParam: {},
      boostDialog: {
        visible: false,
        loading: false,
        tabName: undefined,
        boosts: [],
        boostDetailList: [],
      },
      shopDetailDialog: {
        visible: false,
        loading: false,
        shopName: undefined,
        list: []
      },
      packetDialog: {
        visible: false,
        list: []
      },
      approveDialog: {
        visible: false,
        form: {
          id: undefined,
          remark: undefined,
          approveUser: undefined,
          approveTime: undefined,
          packetAmount: undefined,
          expectPacketAmount: undefined,
        }
      },
      updateDialog: {
        visible: false,
        title: 'agree',
        titleMap: {
          'agree': '确认通过',
          'refuse': '确认不通过?',
          'batchAgree': '批量通过',
          'batchRefuse': '批量不通过',
          'allAgree': '全量通过',
          'allRefuse': '全量不通过',
        },
        form: {
          id: undefined,
          packetAmount: undefined,
          updatePacketAmount: undefined,
          userName: undefined,
          nickName: undefined,
          remark: undefined,
        },
        allForm: {
          noApproveAmount: undefined,
          userNum: undefined,
          boostBonus: undefined,
          remark: undefined,
        }
      },
      currentRow: {},
      options: {
        orgOptions: [],
        taskOptions: [],
        authOrgOptions: [],
        approveOptions: [{value: '', label: '全部'},{value: '0', label: '未审核'}, {value: '2', label: '已拒绝'}, {value: '3', label: '已通过已发放'}, {value: '4', label: '已通过未发放'}]
      },
      defaultQueryObj: {
        dcCode: 'ALL',
        areaCode: 'ALL',
        provinceCode: 'ALL',
        cityCode: 'ALL',
      },
      orgAuthAll: false,
      diffDialog: {
        visible: false,
        tabName: 'resp',
        form: {
          diffReps: [],
          diffJhs: []
        }
      },
      currentUser: undefined,
      authUsers: [],
      defaultUserObj: {
        development: ['wangsiyong'],
        test: ['wangsiyong', 'tongmeng'],
        uat: ['wangsiyong', 'tongmeng', 'yuanzhengliang'],
        production: ['wangsiyong', 'xiajinlong', 'tongmeng', 'yuanzhengliang']
      },
    };
  },
  computed: {},
  watch: {
    "queryForm.orgCode": {
      handler(nV, oV) {
        if (nV) {
          this.handleOrgChange(nV);
        }
      },
      deep: true,
    },
    "queryForm.authOrgCode": {
      handler(nV, oV) {
        if (nV) {
          this.handleChangeLinkageRef('dc', nV, 'hand');
        }
      },
      deep: true,
    },
    "boostDialog.tabName": {
      handler(nV, oV) {
        if (nV) {
          this.handleGetBoostDetail(nV);
        }
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    dictSelect("ht-org-all").then(res => {
      if (res.data.filter(e => e.value == 'ALL').length > 0) {
        this.orgAuthAll = true
        this.options.authOrgOptions = [{value: '', label: '全国'}].concat(res.data.filter(o => o.value !== 'ALL'))
      } else {
        res.data = [{value: 'ALL', label: '全国'}].concat(res.data)
        this.options.authOrgOptions = res.data.filter(o => o.value !== 'ALL');
        this.queryForm.authOrgCode = this.options.authOrgOptions[0].value
      }
      this.options.orgOptions = res.data;
      this.queryForm.orgCode = res.data[0].value;
    });
    this.authUsers = this.defaultUserObj[process.env.NODE_ENV];
    getCurrentUser().then(res => this.currentUser = res.data.userName);
  },
  mounted() {
  },
  methods: {
    resetQuery() {
      const queryForm = {
        current: 1,
        size: 10,
      };
      this.queryForm = Object.assign({}, queryForm);
      this.$forceUpdate();
    },
    handleQuery(action) {
      this.loading = true;
      this.isExpansion = true;
      const param = deepClone(this.queryForm);
      this.lastQueryForm = deepClone(this.queryForm)

      pageUserBoost(param).then(res => {
        if (!res.data) {
          return false;
        }
        this.loading = false;
        res.data.records.forEach(record => {
          record.updateAmount = record.packetAmount;
        })
        this.pageData.rows = res.data?.records;

        this.pageData.total = res.data?.total;
        this.$refs.tableRef.doLayout();
      });
      getSummaryData(param).then(res => {
        if (res.data) {
          this.summaryData = res.data;
        } else {
          this.summaryData = {
            userNum: undefined,
            boostCount: undefined,
            packetAmount: undefined,
            noApproveAmount: undefined,
            approveAmount: undefined,
            leastTime: undefined,
          }
        }
      })
    },
    handleFilter(action) {
      this.queryForm.page = 1;
      this.handleQuery(action);
    },
    toggleRowExpansion() {
      this.isExpansion = !this.isExpansion;
      this.toggleRowExpansionAll(this.pageData.rows, this.isExpansion);
    },
    toggleRowExpansionAll(data, isExpansion) {
      data.forEach((item) => {
        this.$refs.tableRef.toggleRowExpansion(item, isExpansion);
      });
    },
    handleGetBoost(row) {
      this.currentRow = deepClone(row);
      this.boostDialog.boosts = [];
      const data = {
        userName: this.currentRow.userName,
        mtTaskId: this.currentRow.mtTaskId,
        orgCode: this.queryForm.orgCode
      }
      getBoost(data).then(res => {
        if (res.data && res.data.boosts.length > 0) {
          this.boostDialog.boosts = res.data.boosts;
          this.boostDialog.tabName = res.data.boosts[0].boostId;
          this.handleGetBoostDetail(this.boostDialog.tabName);
        }
        this.boostDialog.visible = true;
      })
    },
    handleOrgChange(val) {
      getOrgTaskOption(val).then(res => {
        if (res.data) {
          this.options.taskOptions = res.data;
          if (this.options.taskOptions.length > 0) {
            this.queryForm.taskId = this.options.taskOptions[0].mtTaskId;
          } else {
            this.queryForm.taskId = undefined;
          }
          this.handleFilter();
        }
      })
    },
    handleSortChange(data) {
      const {prop, order} = data;
      if (prop && order) {
        this.queryForm.sorts = order === "ascending" ? prop + " asc" : prop + " desc";
      } else {
        this.queryForm.sorts = undefined;
      }
      this.handleFilter();
    },
    handleGetBoostDetail(val) {
      this.boostDialog.boostDetailList = [];
      const data = deepClone(this.currentRow);
      const param = Object.assign(this.defaultQueryObj, {
        userName: data.userName,
        mtTaskId: this.currentRow.mtTaskId,
        boostId: val,
        orgCode: this.queryForm.orgCode
      });
      getUserBoostDetail(param).then(res => {
        if (res.data && res.data.list.length > 0 && res.data.boostId === this.boostDialog.tabName) {
          this.boostDialog.boostDetailList = res.data.list;
        }
      })
    },
    handleShopDetail(data) {
      this.shopDetailDialog.list = [];
      data.username = data.userName;
      getBoostShopDetail(data).then(res => {
        if (res.data && res.data.boosts && res.data.boosts.length > 0) {
          this.shopDetailDialog.list = res.data.boosts[0].boostRules;
        }
        this.shopDetailDialog.visible = true;
      })
    },
    // handleGetPacket(val) {
    // getPacketRecord('taskId=' + val.mtTaskId + '&userName=' + val.userName).then(res => {
    //   this.packetDialog.list = res.data;
    //   this.packetDialog.visible = true;
    // })
    // },
    handleApprove(action, data) {
      let form = {status: action, id: data.id}
      if (action === 1) {
        form.remark = '审核通过'
        approvalPacket(form).then(res => {
          if (res.code === 200) {
            Notification.success("操作成功！");
            this.handleQuery();
          }
        })
      } else {
        this.$prompt('请填写审核备注', '确认不通过？', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          center: true
        }).then(({ value }) => {
          form.remark = value;
          approvalPacket(form).then(res => {
            if (res.code === 200) {
              Notification.success("操作成功！");
              this.handleQuery();
            }
          })
        }).catch(() => {
        });
      }
    },
    handleSelectionChange(selections) {
      this.pageData.selections = selections;
    },
    handleAudit(item, row, action) {
      this.updateDialog.form = {
        id: item.id,
        expectPacketAmount: item.expectPacketAmount,
        packetAmount: undefined,
        status: action,
        userName: row.userName,
        nickName: row.nickName,
        remark: undefined
      }
      if (action === 1) {
        this.updateDialog.title = 'agree';
      } else {
        this.updateDialog.title = 'refuse';
      }
      this.updateDialog.visible = true;
    },
    handleUpdateAmount() {
      if (this.updateDialog.title.indexOf('all') < 0 && this.updateDialog.title.indexOf('batch') < 0) {
        if (this.updateDialog.form.status === 1) {
          if (this.updateDialog.form.packetAmount <= 0) {
            Notification.warning("金额不能为0，可以选择不通过此条审批!");
            return;
          }
        }
        const data = {
          id: this.updateDialog.form.id,
          packetAmount: this.updateDialog.form.packetAmount ? this.updateDialog.form.packetAmount : this.updateDialog.form.expectPacketAmount,
          remark: this.updateDialog.form.remark,
          status: this.updateDialog.form.status
        }
        updatePacketAmount(data).then(res => {
          if (res.code === 200) {
            Notification.success("操作成功！");
            this.handleQuery();
            this.updateDialog.visible = false;
          }
        })
      } else if (this.updateDialog.title.indexOf('batch') < 0) {
        this.handleAllApprove();
      } else {
        this.handleApproveBatch();
      }
    },
    handleApproveAll(action) {
      getSummaryData(this.lastQueryForm).then(res => {
        this.updateDialog.title = action;
        if (res.data) {
          this.updateDialog.allForm = {
            noApproveAmount: res.data.noApproveAmount,
            userNum: res.data.userNum,
            boostBonus: res.data.boostBonus,
            remark: undefined,
          }
        } else {
          this.updateDialog.allForm = {
            userNum: undefined,
            boostCount: undefined,
            noApproveAmount: undefined,
            remark: undefined,
          }
        }
        this.updateDialog.visible = true;
      })
    },
    handleAllApprove() {
      let param = {approveStatus: (this.updateDialog.title === 'allAgree' ? 1 : 2)};
      param.remark = this.updateDialog.allForm.remark;
      approvalAllRecord(Object.assign(param, this.lastQueryForm)).then(res => {
        if (res.code === 200) {
          Notification.success("操作成功！");
          this.handleQuery();
          this.updateDialog.visible = false;
        }
      })
    },
    handleApproveBatch() {
      let param = {status: this.updateDialog.title === 'batchAgree' ? 1 : 2};
      param.userNames = this.pageData.selections.map(e => e.userName);
      param.taskId = this.pageData.selections[0].mtTaskId;
      batchApprovalRecord(param).then(res => {
        if (res.code === 200) {
          Notification.success("操作成功！");
          this.handleQuery();
          this.updateDialog.visible = false;
        }
      })
    },
    handleBatchApprove(action) {
      let param = {};
      if (this.pageData.selections.length > 0) {
        param.userNames = this.pageData.selections.map(e => e.userName);
        param.taskId = this.pageData.selections[0].mtTaskId;
      } else {
        Notification.warning("请至少选择一条数据进行操作！");
        return;
      }
      param = Object.assign(param, this.lastQueryForm)
      getSummaryData(param).then(res => {
        this.updateDialog.title = action;
        if (res.data) {
          this.updateDialog.allForm = {
            noApproveAmount: res.data.noApproveAmount,
            userNum: res.data.userNum,
            boostBonus: res.data.boostBonus,
            remark: undefined,
          }
        } else {
          this.updateDialog.allForm = {
            userNum: undefined,
            boostCount: undefined,
            noApproveAmount: undefined,
            remark: undefined,
          }
        }
        this.updateDialog.visible = true;
      })
    },
    handleExport() {
      this.loading = true;
      exportBoostPacket(deepClone(this.queryForm)).then(res => {
        this.loading = false;
        if (!res) {
          return
        }
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = window.URL.createObjectURL(new Blob([res]))
        link.setAttribute('download', '激励红包数据导出.xlsx')
        document.body.appendChild(link)
        link.click()
        link.remove()
      })
    },
    handleExportShop() {
      this.loading = true;
      exportShopBonus(deepClone(this.queryForm)).then(res => {
        this.loading = false;
        if (!res) {
          return
        }
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = window.URL.createObjectURL(new Blob([res]))
        link.setAttribute('download', '激励店铺导出.xlsx')
        document.body.appendChild(link)
        link.click()
        link.remove()
      })
    },
    handleExportShopV2(type) {
      this.loading = true;
      exportShopBonusV2(type, deepClone(this.queryForm)).then(res => {
        this.loading = false;
        if (!res) {
          return
        }
        const title = type === '0' ? '未审核店铺激励导出' : '已审核店铺激励导出';
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = window.URL.createObjectURL(new Blob([res]))
        link.setAttribute('download', title + '.xlsx')
        document.body.appendChild(link)
        link.click()
        link.remove()
      })
    },
    handleGetUserDiff(row) {
      getUserBoostDiff(row).then(res => {
        this.diffDialog.visible = true;
        this.diffDialog.form = res.data;
        this.diffDialog.tabName = 'resp';
      })
    },
    showCompare(item, row) {
      if (item.packetAmount && item.packetAmount < 0) {
        return row.list.filter(e => e.redPacketDetailId && new Date(e.createTime).getTime() > new Date('2024-01-06').getTime()).length > 0
      }
      return false
    },
    handleChangeLinkageRef(ref, val, type) {
      this.$nextTick(() => {
        ref.split(',').forEach(e => {
          if (this.$refs[e]) {
            setTimeout(() => {
              this.$refs[e].handleRefChange(val, type);
            }, 100)
            this.$forceUpdate();
          }
        })
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding-top: 10px;
  padding-bottom: 5px;
}

.common-form {
  &-row {
    display: flex !important;
    flex-wrap: wrap;
  }
  div .el-form-item {
    display: flex !important;
    ::v-deep {
      .el-form-item__content {
        width: 100%;
        .el-input {
          width: 100%;
        }
        .el-input-number {
          width: 100%;
        }
        .el-select {
          width: 100%;
        }
        .el-date-editor {
          width: 100%;
        }
      }
    }
  }
}

.label-class {
  font-weight: 600;
  font-size: 15px;
  color: #ac7017;
}
.wait-class {
  color: #EE7712
}
.abodon-class {
  color: #e6a23c;
}
.accept-class {
  color: #67c23a;
}
.blue-class {
  color: #409eff;
}
.error-class {
  color: #f56c6c;
}
</style>
