var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loadingPage,
          expression: "loadingPage",
        },
      ],
    },
    [
      _c(
        "div",
        { staticStyle: { "padding-bottom": "15px" } },
        [
          _vm.type !== "view"
            ? _c(
                "el-form",
                {
                  ref: "form",
                  staticClass: "common-form",
                  attrs: {
                    rules: _vm.rules,
                    size: "mini",
                    inline: true,
                    model: _vm.form,
                  },
                },
                [
                  _c(
                    "el-row",
                    { staticClass: "common-form-row" },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "任务名称",
                                prop: "taskName",
                                "label-width": "120px",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入", clearable: "" },
                                model: {
                                  value: _vm.form.taskName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "taskName", $$v)
                                  },
                                  expression: "form.taskName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "分公司",
                                "label-width": "120px",
                                prop: "checkedOrgCodes",
                              },
                            },
                            [
                              _c("DictSelect", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: !(
                                      _vm.form.checkedOrgCodes instanceof Array
                                    ),
                                    expression:
                                      "!(form.checkedOrgCodes instanceof Array)",
                                  },
                                ],
                                attrs: {
                                  value: _vm.form.checkedOrgCodes,
                                  fullLabel: "",
                                  init: "",
                                  "init-options": _vm.initOrgOptions,
                                  version: "V3",
                                },
                                on: {
                                  "update:value": function ($event) {
                                    return _vm.$set(
                                      _vm.form,
                                      "checkedOrgCodes",
                                      $event
                                    )
                                  },
                                  change: (val, action) =>
                                    _vm.handleOrgChange(action),
                                },
                              }),
                              _c("DictSelect", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.form.checkedOrgCodes instanceof Array,
                                    expression:
                                      "(form.checkedOrgCodes instanceof Array)",
                                  },
                                ],
                                attrs: {
                                  value: _vm.form.checkedOrgCodes,
                                  "collapse-tags": "",
                                  multiple: "",
                                  fullLabel: "",
                                  clearable: false,
                                  init: "",
                                  version: "V3",
                                  "init-options": _vm.initOrgOptions,
                                },
                                on: {
                                  "update:value": function ($event) {
                                    return _vm.$set(
                                      _vm.form,
                                      "checkedOrgCodes",
                                      $event
                                    )
                                  },
                                  change: (val, action) =>
                                    _vm.handleOrgChange(action),
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "任务类型",
                                "label-width": "120px",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { disabled: _vm.form.status > 2 },
                                  on: { change: _vm.handleRangeChange },
                                  model: {
                                    value: _vm.form.taskRange,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "taskRange", $$v)
                                    },
                                    expression: "form.taskRange",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: {
                                      value: "0",
                                      label: "阶段任务(短期活动)",
                                    },
                                  }),
                                  _c("el-option", {
                                    attrs: { value: "1", label: "长期任务" },
                                  }),
                                  _c("el-option", {
                                    attrs: { value: "2", label: "大篷车任务" },
                                  }),
                                  _c("el-option", {
                                    attrs: {
                                      value: "3",
                                      label: "大篷车任务-新品",
                                    },
                                  }),
                                  _c("el-option", {
                                    attrs: { value: "4", label: "阵地任务" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 18 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "描述",
                                prop: "description",
                                "label-width": "100px",
                              },
                            },
                            [
                              _c("el-input", {
                                staticClass: "descWidth",
                                attrs: {
                                  placeholder: "建议20字以内",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.form.description,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "description", $$v)
                                  },
                                  expression: "form.description",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.form.timeType === "1"
                        ? _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "任务时间",
                                    prop: "offsetDays",
                                    "label-width": "110px",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "20%" },
                                      on: {
                                        change: () =>
                                          (_vm.form.offsetDays = undefined),
                                      },
                                      model: {
                                        value: _vm.form.timeType,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "timeType", $$v)
                                        },
                                        expression: "form.timeType",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: {
                                          value: "0",
                                          label: "固定时间",
                                        },
                                      }),
                                      _c("el-option", {
                                        attrs: {
                                          value: "1",
                                          label: "动态时间",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("el-input-number", {
                                    staticStyle: { width: "75%" },
                                    attrs: {
                                      placeholder: "输入天数，输入90即近90天",
                                      controls: false,
                                      min: 0,
                                      clearable: "",
                                    },
                                    model: {
                                      value: _vm.form.offsetDays,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "offsetDays", $$v)
                                      },
                                      expression: "form.offsetDays",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.form.timeType === "0"
                        ? _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "任务时间",
                                    prop: "startDate",
                                    "label-width": "110px",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      staticStyle: { width: "20%" },
                                      on: {
                                        change: () => {
                                          _vm.form.offsetDays = undefined
                                          _vm.form.runFlow = true
                                        },
                                      },
                                      model: {
                                        value: _vm.form.timeType,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.form, "timeType", $$v)
                                        },
                                        expression: "form.timeType",
                                      },
                                    },
                                    [
                                      _c("el-option", {
                                        attrs: {
                                          value: "0",
                                          label: "固定时间",
                                        },
                                      }),
                                      _c("el-option", {
                                        attrs: {
                                          value: "1",
                                          label: "动态时间",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("el-date-picker", {
                                    staticStyle: { width: "70%" },
                                    attrs: {
                                      type: "daterange",
                                      "start-placeholder": "开始时间",
                                      "end-placeholder": "结束时间",
                                      format: "yyyy-MM-dd",
                                      "default-time": ["00:00:00", "23:59:59"],
                                    },
                                    on: {
                                      change: (val) =>
                                        _vm.handleDateChange(val, "task"),
                                    },
                                    model: {
                                      value: _vm.taskDate,
                                      callback: function ($$v) {
                                        _vm.taskDate = $$v
                                      },
                                      expression: "taskDate",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.form.timeType === "0"
                        ? _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    prop: "writeOffEndTime",
                                    "label-width": "160px",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "label",
                                        fn: function () {
                                          return [
                                            _vm._v(" 核销截止时间 "),
                                            _c(
                                              "el-popover",
                                              {
                                                attrs: {
                                                  placement: "top-start",
                                                  width: "300",
                                                  trigger: "hover",
                                                  content:
                                                    "任务结束后，如还需要在App中展示该任务用来核销，可设定此时间",
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "el-icon-question",
                                                  attrs: { slot: "reference" },
                                                  slot: "reference",
                                                }),
                                              ]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    4215204653
                                  ),
                                },
                                [
                                  _c("el-date-picker", {
                                    attrs: {
                                      type: "date",
                                      format: "yyyy-MM-dd",
                                      placeholder: "请选择",
                                      "value-format": "yyyy-MM-dd HH:mm:ss",
                                      "picker-options": _vm.pickerOptions,
                                    },
                                    on: {
                                      change: (val) =>
                                        _vm.handleDateChange(val, "write"),
                                    },
                                    model: {
                                      value: _vm.form.writeOffEndTime,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form,
                                          "writeOffEndTime",
                                          $$v
                                        )
                                      },
                                      expression: "form.writeOffEndTime",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.form.checkedOrgCodes &&
                      _vm.orgFilterMap[_vm.form.checkOrgCode] != undefined
                        ? _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "新增款式对比周期",
                                    "label-width": "160px",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "inline-flex",
                                        width: "100%",
                                      },
                                    },
                                    [
                                      _vm.form.checkedOrgCodes instanceof
                                        Array &&
                                      _vm.form.checkedOrgCodes.length > 1
                                        ? _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "90px" },
                                              model: {
                                                value: _vm.form.compareOrgCode,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "compareOrgCode",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "form.compareOrgCode",
                                              },
                                            },
                                            _vm._l(
                                              _vm.form.checkedOrgCodes.filter(
                                                (e) => e !== "ALL"
                                              ),
                                              function (item) {
                                                return _c("el-option", {
                                                  key: item,
                                                  attrs: {
                                                    value: item,
                                                    label:
                                                      item +
                                                      "-" +
                                                      _vm.allOrgMap[item],
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "el-popover",
                                        {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            placement: "bottom",
                                            width: "365",
                                            trigger: "click",
                                            content: "",
                                          },
                                          on: { show: _vm.handlePopoverShow },
                                        },
                                        [
                                          _c(
                                            "el-radio-group",
                                            {
                                              staticStyle: {
                                                "font-weight": "400 !important",
                                              },
                                              on: {
                                                input: function ($event) {
                                                  _vm.form.runFlow = true
                                                },
                                              },
                                              model: {
                                                value:
                                                  _vm.orgFilterMap[
                                                    _vm.form.compareOrgCode
                                                  ].ksCompareChoose,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.orgFilterMap[
                                                      _vm.form.compareOrgCode
                                                    ],
                                                    "ksCompareChoose",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "orgFilterMap[form.compareOrgCode].ksCompareChoose",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-row",
                                                [
                                                  _c(
                                                    "el-radio",
                                                    { attrs: { label: "0" } },
                                                    [_vm._v("前180天")]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "el-row",
                                                [
                                                  _c(
                                                    "el-radio",
                                                    { attrs: { label: "1" } },
                                                    [
                                                      _vm._v("自定义"),
                                                      _c(
                                                        "el-popover",
                                                        {
                                                          attrs: {
                                                            placement:
                                                              "top-start",
                                                            width: "300",
                                                            trigger: "hover",
                                                            content:
                                                              "在该时间段内未进过货的款式，活动期间进货即算作新增（仅能选择活动开始前的时间范围）",
                                                          },
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "el-icon-question",
                                                            attrs: {
                                                              slot: "reference",
                                                            },
                                                            slot: "reference",
                                                          }),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c("el-date-picker", {
                                                    staticStyle: {
                                                      width: "200px",
                                                    },
                                                    attrs: {
                                                      type: "daterange",
                                                      "start-placeholder":
                                                        "开始时间",
                                                      "end-placeholder":
                                                        "结束时间",
                                                      format: "yyyy-MM-dd",
                                                      "default-time": [
                                                        "00:00:00",
                                                        "23:59:59",
                                                      ],
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.orgFilterMap[
                                                          _vm.form
                                                            .compareOrgCode
                                                        ].ksCompareDate,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.orgFilterMap[
                                                            _vm.form
                                                              .compareOrgCode
                                                          ],
                                                          "ksCompareDate",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "orgFilterMap[form.compareOrgCode].ksCompareDate",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm.form.checkedOrgCodes instanceof
                                            Array &&
                                          _vm.form.checkedOrgCodes.length > 1
                                            ? _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "text-align": "center",
                                                    "margin-top": "5px",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-button",
                                                    {
                                                      staticStyle: {
                                                        border: "0px",
                                                      },
                                                      attrs: {
                                                        size: "mini",
                                                        type: "text",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.syncConfigToOther(
                                                            "compare"
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "一键同步至其他分公司"
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.form.checkedOrgCodes.length > 0
                                            ? _c(
                                                "el-input",
                                                {
                                                  staticStyle: {
                                                    width: "100%",
                                                  },
                                                  attrs: {
                                                    slot: "reference",
                                                    readonly: "",
                                                  },
                                                  slot: "reference",
                                                  model: {
                                                    value: _vm.orgCompareShow,
                                                    callback: function ($$v) {
                                                      _vm.orgCompareShow = $$v
                                                    },
                                                    expression:
                                                      "orgCompareShow",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "i",
                                                    {
                                                      attrs: { slot: "suffix" },
                                                      slot: "suffix",
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "el-input__icon el-icon-arrow-down",
                                                        staticStyle: {
                                                          cursor: "pointer",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.form.checkedOrgCodes &&
                      _vm.orgFilterMap[_vm.form.checkOrgCode] != undefined
                        ? _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "未进货对比周期",
                                    "label-width": "160px",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "inline-flex",
                                        width: "100%",
                                      },
                                    },
                                    [
                                      _vm.form.checkedOrgCodes instanceof
                                        Array &&
                                      _vm.form.checkedOrgCodes.length > 1
                                        ? _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "90px" },
                                              on: {
                                                change: (val) =>
                                                  _vm.handlePopoverShow(val),
                                              },
                                              model: {
                                                value: _vm.form.checkOrgCode,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "checkOrgCode",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.checkOrgCode",
                                              },
                                            },
                                            _vm._l(
                                              _vm.form.checkedOrgCodes.filter(
                                                (e) => e !== "ALL"
                                              ),
                                              function (item) {
                                                return _c("el-option", {
                                                  key: item,
                                                  attrs: {
                                                    value: item,
                                                    label:
                                                      item +
                                                      "-" +
                                                      _vm.allOrgMap[item],
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "el-popover",
                                        {
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            placement: "bottom",
                                            width: "365",
                                            trigger: "click",
                                            content:
                                              "App任务列表中此任务的展示排序，数字越小，排序越靠前，数字可以是负数",
                                          },
                                          on: { show: _vm.handlePopoverShow },
                                        },
                                        [
                                          _c(
                                            "el-table",
                                            {
                                              ref: "filterTable",
                                              attrs: {
                                                data: _vm.queryConfigRows,
                                                size: "mini",
                                                "show-header": false,
                                              },
                                              on: {
                                                "selection-change":
                                                  _vm.handleQueryConfigSelectionChange,
                                              },
                                            },
                                            [
                                              _c("el-table-column", {
                                                attrs: {
                                                  type: "selection",
                                                  width: "40",
                                                },
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  width: "70",
                                                  prop: "key",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function ({ row }) {
                                                        return [
                                                          row.key === "固定期"
                                                            ? _c(
                                                                "span",
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        row.key
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                  _c(
                                                                    "el-popover",
                                                                    {
                                                                      attrs: {
                                                                        placement:
                                                                          "top-start",
                                                                        width:
                                                                          "300",
                                                                        trigger:
                                                                          "hover",
                                                                        content:
                                                                          "已进货是固定期之内（如87款配置1-3月），未进货是固定期外",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c("i", {
                                                                        staticClass:
                                                                          "el-icon-question",
                                                                        attrs: {
                                                                          slot: "reference",
                                                                        },
                                                                        slot: "reference",
                                                                      }),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    row.key
                                                                  )
                                                                ),
                                                              ]),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  4255514535
                                                ),
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  width: "210",
                                                  prop: "name",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function ({ row }) {
                                                        return [
                                                          row.key === "固定期"
                                                            ? _c(
                                                                "div",
                                                                [
                                                                  _c(
                                                                    "el-date-picker",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          width:
                                                                            "200px",
                                                                        },
                                                                      attrs: {
                                                                        type: "daterange",
                                                                        "start-placeholder":
                                                                          "开始时间",
                                                                        "end-placeholder":
                                                                          "结束时间",
                                                                        format:
                                                                          "yyyy-MM-dd",
                                                                        "default-time":
                                                                          [
                                                                            "00:00:00",
                                                                            "23:59:59",
                                                                          ],
                                                                        size: "mini",
                                                                      },
                                                                      on: {
                                                                        change:
                                                                          (
                                                                            val
                                                                          ) =>
                                                                            _vm.handleConfigDateChange(
                                                                              val,
                                                                              row
                                                                            ),
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .orgFilterMap[
                                                                            _vm
                                                                              .form
                                                                              .checkOrgCode
                                                                          ]
                                                                            .compareDate,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm
                                                                                .orgFilterMap[
                                                                                _vm
                                                                                  .form
                                                                                  .checkOrgCode
                                                                              ],
                                                                              "compareDate",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "orgFilterMap[form.checkOrgCode].compareDate",
                                                                      },
                                                                    }
                                                                  ),
                                                                  _c(
                                                                    "el-input",
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          width:
                                                                            "200px",
                                                                        },
                                                                      attrs: {
                                                                        size: "mini",
                                                                        placeholder:
                                                                          "自定义名称（如1-3月）8个字内",
                                                                      },
                                                                      on: {
                                                                        input:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.handleQueryConfigSelectionChange(
                                                                              _vm
                                                                                .orgFilterMap[
                                                                                _vm
                                                                                  .form
                                                                                  .checkOrgCode
                                                                              ]
                                                                                .selections
                                                                            )
                                                                          },
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .orgFilterMap[
                                                                            _vm
                                                                              .form
                                                                              .checkOrgCode
                                                                          ]
                                                                            .customName,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm
                                                                                .orgFilterMap[
                                                                                _vm
                                                                                  .form
                                                                                  .checkOrgCode
                                                                              ],
                                                                              "customName",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "orgFilterMap[form.checkOrgCode].customName",
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  581004760
                                                ),
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm.form.checkedOrgCodes instanceof
                                            Array &&
                                          _vm.form.checkedOrgCodes.length > 1
                                            ? _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "text-align": "center",
                                                    "margin-top": "5px",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-button",
                                                    {
                                                      staticStyle: {
                                                        border: "0px",
                                                      },
                                                      attrs: {
                                                        size: "mini",
                                                        type: "text",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.syncConfigToOther(
                                                            "query"
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "一键同步至其他分公司"
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.form.checkedOrgCodes.length > 0
                                            ? _c(
                                                "el-input",
                                                {
                                                  staticStyle: {
                                                    width: "100%",
                                                  },
                                                  attrs: {
                                                    slot: "reference",
                                                    readonly: "",
                                                  },
                                                  slot: "reference",
                                                  model: {
                                                    value:
                                                      _vm.orgFilterMap[
                                                        _vm.form.checkOrgCode
                                                      ].queryConfig,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.orgFilterMap[
                                                          _vm.form.checkOrgCode
                                                        ],
                                                        "queryConfig",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "orgFilterMap[form.checkOrgCode].queryConfig",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "i",
                                                    {
                                                      attrs: { slot: "suffix" },
                                                      slot: "suffix",
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "el-input__icon el-icon-arrow-down",
                                                        staticStyle: {
                                                          cursor: "pointer",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.form.checkedOrgCodes &&
                      _vm.orgFilterMap[_vm.form.showOrgCode] != undefined
                        ? _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { "label-width": "140px" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "label",
                                        fn: function () {
                                          return [
                                            _vm._v(" 是否APP显示 "),
                                            _c(
                                              "el-popover",
                                              {
                                                attrs: {
                                                  placement: "top-start",
                                                  width: "300",
                                                  trigger: "hover",
                                                  content:
                                                    "控制该任务在APP是否显示",
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "el-icon-question",
                                                  attrs: { slot: "reference" },
                                                  slot: "reference",
                                                }),
                                              ]
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    78367190
                                  ),
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "inline-flex",
                                        width: "100%",
                                      },
                                    },
                                    [
                                      _vm.form.checkedOrgCodes instanceof
                                        Array &&
                                      _vm.form.checkedOrgCodes.length > 1
                                        ? _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "120px" },
                                              model: {
                                                value: _vm.form.showOrgCode,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "showOrgCode",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.showOrgCode",
                                              },
                                            },
                                            _vm._l(
                                              _vm.form.checkedOrgCodes.filter(
                                                (e) => e !== "ALL"
                                              ),
                                              function (item) {
                                                return _c("el-option", {
                                                  key: item,
                                                  attrs: {
                                                    value: item,
                                                    label:
                                                      item +
                                                      "-" +
                                                      _vm.allOrgMap[item],
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "el-select",
                                        {
                                          attrs: {
                                            placeholder:
                                              "控制该任务在APP是否显示",
                                          },
                                          model: {
                                            value:
                                              _vm.orgFilterMap[
                                                _vm.form.showOrgCode
                                              ].appShow,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.orgFilterMap[
                                                  _vm.form.showOrgCode
                                                ],
                                                "appShow",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "orgFilterMap[form.showOrgCode].appShow",
                                          },
                                        },
                                        [
                                          _c("el-option", {
                                            attrs: { value: 1, label: "是" },
                                          }),
                                          _c("el-option", {
                                            attrs: { value: 0, label: "否" },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm.form.checkedOrgCodes instanceof
                                        Array &&
                                      _vm.form.checkedOrgCodes.length > 1
                                        ? _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "text-align": "center",
                                                "margin-top": "5px",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-button",
                                                {
                                                  staticStyle: {
                                                    border: "0px",
                                                  },
                                                  attrs: {
                                                    size: "mini",
                                                    type: "text",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.syncConfigToOther(
                                                        "appShow"
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("一键同步至其他分公司")]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.form.checkedOrgCodes &&
                      _vm.orgFilterMap[_vm.form.batchOrgCode] != undefined &&
                      _vm.specialAuth
                        ? _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    "label-width": "140px",
                                    label: "一键加购",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "inline-flex",
                                        width: "100%",
                                      },
                                    },
                                    [
                                      _vm.form.checkedOrgCodes instanceof
                                        Array &&
                                      _vm.form.checkedOrgCodes.length > 1
                                        ? _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "120px" },
                                              model: {
                                                value: _vm.form.batchOrgCode,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "batchOrgCode",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.batchOrgCode",
                                              },
                                            },
                                            _vm._l(
                                              _vm.form.checkedOrgCodes.filter(
                                                (e) => e !== "ALL"
                                              ),
                                              function (item) {
                                                return _c("el-option", {
                                                  key: item,
                                                  attrs: {
                                                    value: item,
                                                    label:
                                                      item +
                                                      "-" +
                                                      _vm.allOrgMap[item],
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "el-select",
                                        {
                                          attrs: { placeholder: "请选择" },
                                          model: {
                                            value:
                                              _vm.orgFilterMap[
                                                _vm.form.batchOrgCode
                                              ].ifBatchAddCart,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.orgFilterMap[
                                                  _vm.form.batchOrgCode
                                                ],
                                                "ifBatchAddCart",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "orgFilterMap[form.batchOrgCode].ifBatchAddCart",
                                          },
                                        },
                                        [
                                          _c("el-option", {
                                            attrs: { value: "1", label: "是" },
                                          }),
                                          _c("el-option", {
                                            attrs: { value: "0", label: "否" },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm.form.checkedOrgCodes instanceof
                                        Array &&
                                      _vm.form.checkedOrgCodes.length > 1
                                        ? _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "text-align": "center",
                                                "margin-top": "5px",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-button",
                                                {
                                                  staticStyle: {
                                                    border: "0px",
                                                  },
                                                  attrs: {
                                                    size: "mini",
                                                    type: "text",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.syncConfigToOther(
                                                        "ifBatchAddCart"
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("一键同步至其他分公司")]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "智能补货",
                                "label-width": "120px",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    disabled: _vm.initOrgOptions.length <= 1,
                                  },
                                  model: {
                                    value: _vm.form.ifAutoSupply,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "ifAutoSupply", $$v)
                                    },
                                    expression: "form.ifAutoSupply",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: { value: 0, label: "否" },
                                  }),
                                  _c("el-option", {
                                    attrs: { value: 1, label: "是" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { "label-width": "120px" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "label",
                                    fn: function () {
                                      return [
                                        _vm._v(" 拆分架装 "),
                                        _c(
                                          "el-popover",
                                          {
                                            attrs: {
                                              placement: "top-start",
                                              width: "300",
                                              trigger: "hover",
                                              content:
                                                "如架装不在任务中、但架装内有单品在任务中时，进了架装的款数和金额是否需拆分计算到该单品的款数上柜和金额上柜（金额会按架装实际金额按比例折算）",
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "el-icon-question",
                                              attrs: { slot: "reference" },
                                              slot: "reference",
                                            }),
                                          ]
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                1350569914
                              ),
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  model: {
                                    value: _vm.form.ifTzSplit,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "ifTzSplit", $$v)
                                    },
                                    expression: "form.ifTzSplit",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    attrs: {
                                      value: 0,
                                      label: "金额和款数均不拆分",
                                    },
                                  }),
                                  _c("el-option", {
                                    attrs: {
                                      value: 1,
                                      label: "金额和款数均拆分计算到单品",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { "label-width": "160px" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "label",
                                fn: function () {
                                  return [
                                    _vm._v(" 对应的联盟活动 "),
                                    _c(
                                      "el-popover",
                                      {
                                        attrs: {
                                          placement: "top-start",
                                          width: "300",
                                          trigger: "hover",
                                          content:
                                            "配置后，业务员可以在聚宝盆中分享这个任务给店主，店主打开是联盟的活动⻚⾯。如何获得链接：在联盟App打开对应的活动，点击底部“去分享”→“复制链接”。建议将链接中的shareUserId改为xxx",
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-question",
                                          attrs: { slot: "reference" },
                                          slot: "reference",
                                        }),
                                      ]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            3358963241
                          ),
                        },
                        [
                          _c(
                            "el-row",
                            {
                              staticStyle: {
                                "background-color": "#ececec",
                                padding: "10px",
                              },
                            },
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "分享链接主标题",
                                        "label-width": "170px",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        model: {
                                          value: _vm.form.shareTitle,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "shareTitle",
                                              $$v
                                            )
                                          },
                                          expression: "form.shareTitle",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "H5链接",
                                        "label-width": "100px",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        model: {
                                          value: _vm.form.h5ShareUrl,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "h5ShareUrl",
                                              $$v
                                            )
                                          },
                                          expression: "form.h5ShareUrl",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 8 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "微信分享图",
                                        "label-width": "120px",
                                      },
                                    },
                                    [
                                      _c("OssUpload", {
                                        ref: "ossUpload",
                                        attrs: {
                                          "value-to": _vm.form.weChatShareIcon,
                                          "list-type": "picture-card",
                                          maxFileSize: 500,
                                          tipMessageShow: false,
                                          "accept-type": [
                                            ".jpg",
                                            ".jpeg",
                                            ".gif",
                                            ".png",
                                          ],
                                        },
                                        on: {
                                          "update:valueTo": function ($event) {
                                            return _vm.$set(
                                              _vm.form,
                                              "weChatShareIcon",
                                              $event
                                            )
                                          },
                                          "update:value-to": function ($event) {
                                            return _vm.$set(
                                              _vm.form,
                                              "weChatShareIcon",
                                              $event
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              !_vm.share.active
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: "text",
                                        size: "mini",
                                        underline: false,
                                      },
                                      on: {
                                        click: () => (_vm.share.active = true),
                                      },
                                    },
                                    [_vm._v("展开选填项")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm.share.active
                            ? _c(
                                "el-row",
                                {
                                  staticStyle: {
                                    "background-color": "#ececec",
                                  },
                                },
                                [
                                  _c(
                                    "el-row",
                                    [
                                      _c(
                                        "el-col",
                                        { attrs: { span: 10 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "分享链接副标题",
                                                "label-width": "170px",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                model: {
                                                  value: _vm.form.subShareTitle,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form,
                                                      "subShareTitle",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "form.subShareTitle",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-row",
                                    [
                                      _c(
                                        "el-col",
                                        { attrs: { span: 10 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "APP分享框标题",
                                                "label-width": "170px",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                model: {
                                                  value: _vm.form.shareBoxTitle,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form,
                                                      "shareBoxTitle",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "form.shareBoxTitle",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-col",
                                        { attrs: { span: 10 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "APP分享框描述",
                                                "label-width": "170px",
                                              },
                                            },
                                            [
                                              _c("el-input", {
                                                model: {
                                                  value: _vm.form.shareBoxDesc,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form,
                                                      "shareBoxDesc",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "form.shareBoxDesc",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-row",
                                    { staticStyle: { "padding-left": "10px" } },
                                    [
                                      _c(
                                        "el-col",
                                        { attrs: { span: 10 } },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label: "APP页面图标",
                                                "label-width": "120px",
                                              },
                                            },
                                            [
                                              _c("OssUpload", {
                                                ref: "ossUpload",
                                                attrs: {
                                                  "value-to":
                                                    _vm.form.appShareIcon,
                                                  "list-type": "picture-card",
                                                  maxFileSize: 500,
                                                  tipMessageShow: false,
                                                  "accept-type": [
                                                    ".jpg",
                                                    ".jpeg",
                                                    ".gif",
                                                    ".png",
                                                  ],
                                                },
                                                on: {
                                                  "update:valueTo": function (
                                                    $event
                                                  ) {
                                                    return _vm.$set(
                                                      _vm.form,
                                                      "appShareIcon",
                                                      $event
                                                    )
                                                  },
                                                  "update:value-to": function (
                                                    $event
                                                  ) {
                                                    return _vm.$set(
                                                      _vm.form,
                                                      "appShareIcon",
                                                      $event
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-row",
                                    { staticStyle: { "padding-left": "10px" } },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "text",
                                            size: "mini",
                                            underline: false,
                                          },
                                          on: {
                                            click: () =>
                                              (_vm.share.active = false),
                                          },
                                        },
                                        [_vm._v("关闭")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "el-tabs",
            {
              on: {
                "tab-click": (val) => {
                  if (val.name !== "sku") {
                    this.handleSetTreeChecked(_vm.form)
                  }
                },
              },
              model: {
                value: _vm.tabName,
                callback: function ($$v) {
                  _vm.tabName = $$v
                },
                expression: "tabName",
              },
            },
            [
              _c("el-tab-pane", { attrs: { label: "商品范围", name: "sku" } }),
              _c("el-tab-pane", { attrs: { label: "店铺范围", name: "shop" } }),
              _c("el-tab-pane", {
                attrs: { label: "配送中心要货", name: "dc" },
              }),
            ],
            1
          ),
          _c("div", [
            _c(
              "div",
              {
                staticStyle: {
                  position: "relative",
                  "z-index": "999",
                  width: "100%",
                },
              },
              [
                _vm.tabName === "sku"
                  ? _c(
                      "div",
                      { staticStyle: { float: "left", margin: "5px" } },
                      [
                        _c(
                          "div",
                          { staticStyle: { display: "inline-block" } },
                          [
                            _c("span", [_vm._v("添加商品方式 ")]),
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "90px" },
                                attrs: { size: "mini" },
                                on: { change: _vm.handleSkuScopeChange },
                                model: {
                                  value: _vm.form.importScope,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "importScope", $$v)
                                  },
                                  expression: "form.importScope",
                                },
                              },
                              [
                                _c("el-option", {
                                  attrs: { value: "0", label: "按商品型号" },
                                }),
                                _c("el-option", {
                                  attrs: { value: "1", label: "按商品编码" },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "inline-block",
                              "margin-left": "10px",
                            },
                          },
                          [
                            _c(
                              "span",
                              [
                                _vm._v("数据统计方式 "),
                                _c(
                                  "el-popover",
                                  {
                                    attrs: {
                                      placement: "top-start",
                                      width: "300",
                                      trigger: "hover",
                                      content:
                                        "例如1个型号的商品下有3个商品编码。按商品型号统计:进货算1款进货；按商品编码统计:进货算3款进货",
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "el-icon-question",
                                      attrs: { slot: "reference" },
                                      slot: "reference",
                                    }),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "120px" },
                                attrs: { size: "mini" },
                                on: {
                                  change: function ($event) {
                                    _vm.form.runFlow = true
                                  },
                                },
                                model: {
                                  value: _vm.form.kssType,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "kssType", $$v)
                                  },
                                  expression: "form.kssType",
                                },
                              },
                              [
                                _c("el-option", {
                                  attrs: {
                                    value: "model",
                                    label: "按商品型号统计",
                                  },
                                }),
                                _vm.form.importScope === "1"
                                  ? _c("el-option", {
                                      attrs: {
                                        value: "sku",
                                        label: "按商品编码统计",
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm.tabName === "shop"
                  ? _c(
                      "div",
                      { staticStyle: { float: "left", margin: "5px" } },
                      [
                        _c(
                          "div",
                          { staticStyle: { display: "inline-block" } },
                          [
                            _c("span", [_vm._v("添加店铺方式 ")]),
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "110px" },
                                attrs: { size: "mini" },
                                on: { change: _vm.handleShopTypeChange },
                                model: {
                                  value: _vm.form.shopCheckType,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "shopCheckType", $$v)
                                  },
                                  expression: "form.shopCheckType",
                                },
                              },
                              [
                                _c("el-option", {
                                  attrs: {
                                    value: "0",
                                    label: "按分公司/配送中心",
                                  },
                                }),
                                _c("el-option", {
                                  attrs: { value: "1", label: "按具体店铺" },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm.form.shopCheckType == "1"
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "inline-block",
                                  "margin-left": "10px",
                                },
                              },
                              [
                                _c("span", [_vm._v("店铺组添加 ")]),
                                _c(
                                  "el-select",
                                  {
                                    staticStyle: { width: "110px" },
                                    attrs: { size: "mini" },
                                    model: {
                                      value: _vm.form.shopGroupId,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "shopGroupId", $$v)
                                      },
                                      expression: "form.shopGroupId",
                                    },
                                  },
                                  _vm._l(_vm.shopGroupOptions, function (item) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        value: item.id,
                                        label:
                                          item.orgName + "-" + item.groupName,
                                      },
                                    })
                                  }),
                                  1
                                ),
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: { "margin-left": "5px" },
                                    attrs: {
                                      disabled: !_vm.form.shopGroupId,
                                      size: "mini",
                                      icon: "el-icon-plus",
                                      type: "primary",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.setGrpShops()
                                      },
                                    },
                                  },
                                  [_vm._v("一键添加")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    )
                  : _vm._e(),
                _c(
                  "div",
                  { staticStyle: { float: "right", margin: "5px" } },
                  [
                    _vm.tabName === "sku"
                      ? _c(
                          "el-input",
                          {
                            staticStyle: {
                              width: "120px !important",
                              "margin-right": "10px",
                            },
                            attrs: {
                              clearable: "",
                              size: "mini",
                              placeholder:
                                _vm.form.importScope === "0"
                                  ? "商品型号"
                                  : "商品编码",
                            },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.handleQuery("skus", "skuPage")
                              },
                            },
                            model: {
                              value: _vm.querySkuKeyWord,
                              callback: function ($$v) {
                                _vm.querySkuKeyWord = $$v
                              },
                              expression: "querySkuKeyWord",
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "el-input__icon el-icon-search",
                              staticStyle: { cursor: "pointer" },
                              attrs: { slot: "suffix" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleQuery("skus", "skuPage")
                                },
                              },
                              slot: "suffix",
                            }),
                          ]
                        )
                      : _vm._e(),
                    _vm.tabName === "shop" && _vm.form.shopCheckType === "1"
                      ? _c(
                          "el-input",
                          {
                            staticStyle: { width: "120px !important" },
                            attrs: {
                              size: "mini",
                              clearable: "",
                              placeholder: "配送中心编码",
                            },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.handleQuery("shops", "shopPage")
                              },
                            },
                            model: {
                              value: _vm.queryDcCode,
                              callback: function ($$v) {
                                _vm.queryDcCode = $$v
                              },
                              expression: "queryDcCode",
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "el-input__icon el-icon-search",
                              staticStyle: { cursor: "pointer" },
                              attrs: { slot: "suffix" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleQuery("shops", "shopPage")
                                },
                              },
                              slot: "suffix",
                            }),
                          ]
                        )
                      : _vm._e(),
                    _vm.tabName === "shop" && _vm.form.shopCheckType === "1"
                      ? _c(
                          "el-input",
                          {
                            staticStyle: {
                              width: "120px !important",
                              "margin-left": "5px",
                              "margin-right": "10px",
                            },
                            attrs: {
                              clearable: "",
                              size: "mini",
                              placeholder: "店铺编码",
                            },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                )
                                  return null
                                return _vm.handleQuery("shops", "shopPage")
                              },
                            },
                            model: {
                              value: _vm.queryShopCode,
                              callback: function ($$v) {
                                _vm.queryShopCode = $$v
                              },
                              expression: "queryShopCode",
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "el-input__icon el-icon-search",
                              staticStyle: { cursor: "pointer" },
                              attrs: { slot: "suffix" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleQuery("shops", "shopPage")
                                },
                              },
                              slot: "suffix",
                            }),
                          ]
                        )
                      : _vm._e(),
                    _vm.type !== "view" &&
                    ((_vm.form.shopCheckType === "1" &&
                      _vm.tabName === "shop") ||
                      _vm.tabName === "sku")
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              icon: "el-icon-upload",
                              type: "primary",
                            },
                            on: { click: _vm.handleImport },
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.tabName === "sku" ? "导入商品" : "导入店铺"
                              ) + " "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.tabName === "sku" && _vm.form.mtTaskId
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              icon: "el-icon-download",
                              type: "primary",
                            },
                            on: { click: _vm.handleExport },
                          },
                          [_vm._v("导出商品 ")]
                        )
                      : _vm._e(),
                    _vm.tabName === "sku" && _vm.type !== "view"
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              icon: "el-icon-plus",
                              type: "primary",
                            },
                            on: {
                              click: function ($event) {
                                _vm.drawer.visible = true
                              },
                            },
                          },
                          [_vm._v("添加商品 ")]
                        )
                      : _vm._e(),
                    _vm.tabName === "sku" && _vm.type !== "view"
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "primary" },
                            on: { click: _vm.handleSort },
                          },
                          [_vm._v(_vm._s(_vm.dynamicTagHeader) + "排序")]
                        )
                      : _vm._e(),
                    _vm.tabName === "shop" &&
                    _vm.form.shopCheckType === "1" &&
                    _vm.type !== "view"
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              icon: "el-icon-plus",
                              type: "primary",
                            },
                            on: {
                              click: function ($event) {
                                _vm.drawer.visible = true
                              },
                            },
                          },
                          [_vm._v("添加店铺 ")]
                        )
                      : _vm._e(),
                    _vm.type !== "view" &&
                    ((_vm.form.shopCheckType === "1" &&
                      _vm.tabName === "shop") ||
                      _vm.tabName === "sku")
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              size: "mini",
                              icon: "el-icon-remove",
                              type: "danger",
                            },
                            on: { click: _vm.handleRemoveAll },
                          },
                          [_vm._v("全部移除 ")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.tabName === "sku",
                    expression: "tabName === 'sku'",
                  },
                ],
              },
              [
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loading,
                        expression: "loading",
                      },
                    ],
                    ref: "skuTableRef",
                    staticStyle: { "margin-top": "5px" },
                    attrs: {
                      data: _vm.skuPage.rows,
                      height: "calc(100vh - 400px)",
                      size: "mini",
                      border: "",
                      "highlight-current-row": "",
                      "header-cell-style": {
                        background: "rgb(227 224 224)",
                        color: "black",
                      },
                    },
                    on: { "selection-change": _vm.handleSelectionChange },
                  },
                  [
                    _c("el-table-column", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.form.importScope === "1",
                          expression: "form.importScope === '1'",
                        },
                      ],
                      attrs: {
                        label: "商品编码",
                        prop: "skuCode",
                        width: "100",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "商品型号",
                        prop: "skuModel",
                        width: "100",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "款式名称",
                        prop: "skuName",
                        "show-overflow-tooltip": "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("span", [_vm._v(_vm._s(scope.row.skuName))]),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c(
                      "el-table-column",
                      {
                        attrs: { prop: "label", width: "120" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.type === "view",
                                        expression: "type==='view'",
                                      },
                                    ],
                                  },
                                  [_vm._v(_vm._s(scope.row.label))]
                                ),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value:
                                          _vm.type === "edit" ||
                                          _vm.type === "add",
                                        expression:
                                          "type==='edit' || type==='add'",
                                      },
                                    ],
                                  },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        size: "mini",
                                        clearable: "",
                                        placeholder:
                                          "请输入" + _vm.dynamicTagHeader,
                                      },
                                      on: {
                                        input: function ($event) {
                                          _vm.form.runFlow = true
                                        },
                                      },
                                      model: {
                                        value: scope.row.label,
                                        callback: function ($$v) {
                                          _vm.$set(scope.row, "label", $$v)
                                        },
                                        expression: "scope.row.label",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      },
                      [
                        _c("template", { slot: "header" }, [
                          _c("span", [_vm._v(_vm._s(this.dynamicTagHeader))]),
                        ]),
                      ],
                      2
                    ),
                    _c("el-table-column", {
                      attrs: {
                        label: "自定义商品名称",
                        prop: "customModelName",
                        width: "120",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.type === "view",
                                      expression: "type==='view'",
                                    },
                                  ],
                                },
                                [_vm._v(_vm._s(scope.row.customModelName))]
                              ),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.type === "edit" ||
                                        _vm.type === "add",
                                      expression:
                                        "type==='edit' || type==='add'",
                                    },
                                  ],
                                },
                                [
                                  _c("el-input", {
                                    attrs: {
                                      size: "mini",
                                      clearable: "",
                                      placeholder:
                                        "请输入名称,同一个型号名称须一致",
                                    },
                                    on: {
                                      input: (val) =>
                                        _vm.handleCustomChange(val, scope.row),
                                    },
                                    model: {
                                      value: scope.row.customModelName,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          scope.row,
                                          "customModelName",
                                          $$v
                                        )
                                      },
                                      expression: "scope.row.customModelName",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.form.importScope === "1",
                          expression: "form.importScope === '1'",
                        },
                      ],
                      attrs: { label: "规格", prop: "spec", width: "80" },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "货号",
                        prop: "cargoNo",
                        "show-overflow-tooltip": "",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "大类",
                        prop: "mainCategoryName",
                        width: "100",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "中类",
                        prop: "midCategoryName",
                        width: "100",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "小类",
                        prop: "subCategoryName",
                        width: "100",
                      },
                    }),
                    _vm.type !== "view"
                      ? _c("el-table-column", {
                          attrs: {
                            label: "操作",
                            width: "70",
                            align: "center",
                            fixed: "right",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c(
                                      "el-button",
                                      {
                                        staticStyle: {
                                          border: "0px",
                                          color: "red",
                                        },
                                        attrs: { type: "text", size: "small" },
                                        nativeOn: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.handleRemoveRow(
                                              scope.row,
                                              1,
                                              "skus",
                                              "skuPage"
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(" 移除 ")]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2253429640
                          ),
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _c("Pagination", {
                  attrs: {
                    total: _vm.skuPage.total,
                    page: _vm.skuPage.current,
                    limit: _vm.skuPage.size,
                  },
                  on: {
                    "update:page": function ($event) {
                      return _vm.$set(_vm.skuPage, "current", $event)
                    },
                    "update:limit": function ($event) {
                      return _vm.$set(_vm.skuPage, "size", $event)
                    },
                    pagination: function ($event) {
                      return _vm.handleQuery("skus", "skuPage")
                    },
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.tabName === "shop",
                    expression: "tabName === 'shop'",
                  },
                ],
              },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.form.shopCheckType === "1",
                        expression: "form.shopCheckType==='1'",
                      },
                    ],
                  },
                  [
                    _c(
                      "el-table",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.loading,
                            expression: "loading",
                          },
                        ],
                        ref: "shopTableRef",
                        class:
                          !_vm.formLoading && _vm.form.shops.length === 0
                            ? "table-light-class"
                            : "",
                        staticStyle: { "margin-top": "5px" },
                        attrs: {
                          data: _vm.shopPage.rows,
                          height: "calc(100vh - 300px)",
                          size: "mini",
                          border: "",
                          "highlight-current-row": "",
                          "header-cell-style": {
                            background: "rgb(227 224 224)",
                            color: "black",
                          },
                        },
                        on: { "selection-change": _vm.handleSelectionChange },
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            label: "分公司",
                            prop: "orgName",
                            width: "100",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "所属配送中心",
                            prop: "dcName",
                            width: "170",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({ row }) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        (row.dcCode ? row.dcCode : "") +
                                          (row.dcName ? "-" + row.dcName : "")
                                      )
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "省份/城市",
                            prop: "provinceName",
                            width: "150",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({ row }) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        (row.provinceName
                                          ? row.provinceName
                                          : "") +
                                          "-" +
                                          (row.cityName
                                            ? "-" + row.cityName
                                            : "")
                                      )
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "所属区县",
                            prop: "areaName",
                            width: "100",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "店铺编码/名称",
                            prop: "shop",
                            width: "250",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({ row }) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        (row.shopCode ? row.shopCode : "") +
                                          (row.shopName
                                            ? "-" + row.shopName
                                            : "")
                                      )
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ]),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "所属商圈",
                            prop: "businessAreaName",
                            width: "100",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "区域分组",
                            prop: "areaTag",
                            width: "80",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "店铺类型",
                            prop: "shopCapacity",
                            width: "80",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "合作类型",
                            prop: "shopBiz",
                            width: "70",
                          },
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "是否重点终端",
                            prop: "ifOptimumShop",
                            width: "80",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function ({ row }) {
                                return [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        row.ifOptimumShop === "1" ? "是" : "否"
                                      )
                                    ),
                                  ]),
                                ]
                              },
                            },
                          ]),
                        }),
                        _vm.type !== "view"
                          ? _c("el-table-column", {
                              attrs: {
                                label: "操作",
                                width: "70",
                                align: "center",
                                fixed: "right",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-button",
                                          {
                                            staticStyle: {
                                              border: "0px",
                                              color: "red",
                                            },
                                            attrs: {
                                              type: "text",
                                              size: "small",
                                            },
                                            nativeOn: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.handleRemoveRow(
                                                  scope.row,
                                                  1,
                                                  "shops",
                                                  "shopPage"
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(" 移除 ")]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                402664936
                              ),
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c("Pagination", {
                      attrs: {
                        total: _vm.shopPage.total,
                        page: _vm.shopPage.current,
                        limit: _vm.shopPage.size,
                      },
                      on: {
                        "update:page": function ($event) {
                          return _vm.$set(_vm.shopPage, "current", $event)
                        },
                        "update:limit": function ($event) {
                          return _vm.$set(_vm.shopPage, "size", $event)
                        },
                        pagination: function ($event) {
                          return _vm.handleQuery("shops", "shopPage")
                        },
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.form.shopCheckType === "0",
                        expression: "form.shopCheckType==='0'",
                      },
                    ],
                    staticStyle: {
                      display: "inline-flex",
                      "margin-top": "40px",
                      "margin-left": "-200px",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          width: "100px",
                          "font-size": "14px",
                          padding: "10px",
                          "font-weight": "500",
                        },
                      },
                      [_vm._v(" 所选区域 ")]
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          height: "300px",
                          "overflow-y": "scroll",
                          width: "400px",
                          margin: "10px",
                        },
                      },
                      [
                        _c("el-input", {
                          staticStyle: {
                            position: "absolute",
                            "z-index": "99",
                            width: "300px",
                          },
                          attrs: {
                            placeholder: "输入关键字进行过滤",
                            size: "mini",
                            clearable: "",
                          },
                          on: { input: _vm.handleFilterChange },
                          model: {
                            value: _vm.filterDcText,
                            callback: function ($$v) {
                              _vm.filterDcText = $$v
                            },
                            expression: "filterDcText",
                          },
                        }),
                        _c("el-tree", {
                          ref: "orgShopDcTreeRef",
                          staticStyle: { "margin-top": "30px" },
                          attrs: {
                            data: _vm.filterOrgDcTree,
                            "show-checkbox": "",
                            "node-key": "fullCode",
                            accordion: true,
                            "filter-node-method": _vm.filterOrgDcNode,
                            props: {
                              children: "children",
                              label: "label",
                              value: "value",
                            },
                          },
                          on: {
                            check: (node, data) =>
                              _vm.handleOrgDcChange(node, data, "shops"),
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.tabName === "dc",
                    expression: "tabName === 'dc'",
                  },
                ],
              },
              [
                _c(
                  "el-row",
                  [
                    _c("el-col", { attrs: { span: 12 } }, [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            "background-color": "rgb(245 233 210)",
                            color: "rgb(230 157 35)",
                            "line-height": "40px",
                            "padding-left": "10px",
                            width: "fit-content",
                          },
                        },
                        [
                          _vm._v(
                            " 说明: 此页面选择后，可以在App的该配送中心页通过此任务下单，来向分公司要货。 "
                          ),
                        ]
                      ),
                    ]),
                    _c(
                      "el-col",
                      { attrs: { span: 12 } },
                      [
                        _c("label", [_vm._v("显示排序号")]),
                        _c("el-input-number", {
                          attrs: {
                            size: "mini",
                            clearable: "",
                            placeholder: "请输入排序号,数字越大越靠前",
                            controls: false,
                            step: 0,
                          },
                          model: {
                            value: _vm.form.dcSort,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "dcSort", $$v)
                            },
                            expression: "form.dcSort",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c("div", { staticStyle: { display: "inline-flex" } }, [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        width: "100px",
                        "font-size": "14px",
                        padding: "10px",
                        "font-weight": "500",
                      },
                    },
                    [_vm._v(" 所选区域 ")]
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        height: "300px",
                        "overflow-y": "scroll",
                        width: "400px",
                        margin: "10px",
                      },
                    },
                    [
                      _c("el-input", {
                        staticStyle: {
                          position: "absolute",
                          "z-index": "99",
                          width: "300px",
                        },
                        attrs: {
                          placeholder: "输入关键字进行过滤",
                          size: "mini",
                          clearable: "",
                        },
                        on: { input: _vm.handleFilterChange },
                        model: {
                          value: _vm.filterTaskDcText,
                          callback: function ($$v) {
                            _vm.filterTaskDcText = $$v
                          },
                          expression: "filterTaskDcText",
                        },
                      }),
                      _c("el-tree", {
                        ref: "orgDcTreeRef",
                        staticStyle: { "margin-top": "30px" },
                        attrs: {
                          data: _vm.filterOrgDcTree,
                          "show-checkbox": "",
                          "node-key": "fullCode",
                          accordion: true,
                          "filter-node-method": _vm.filterOrgDcNode,
                          props: {
                            children: "children",
                            label: "label",
                            value: "value",
                          },
                        },
                        on: {
                          check: (node, data) =>
                            _vm.handleOrgDcChange(node, data, "dcs"),
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            title: _vm.tabName === "sku" ? "选择商品" : "选择店铺",
            visible: _vm.drawer.visible,
            size: "70%",
            direction: "rtl",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.drawer, "visible", $event)
            },
          },
        },
        [
          _vm.tabName === "sku" && _vm.form.importScope === "0"
            ? _c(
                "el-menu",
                {
                  staticClass: "el-menu-demo",
                  attrs: {
                    "default-active": _vm.skuGroups.activeIndex,
                    mode: "horizontal",
                  },
                  on: {
                    select: (val, path) =>
                      _vm.handleMenuSelect(val, path, "skuGroups"),
                  },
                },
                [
                  _vm._l(_vm.skuGroups.fixed, function (item) {
                    return _c(
                      "el-menu-item",
                      { key: item.id, attrs: { index: item.id } },
                      [_vm._v(" " + _vm._s(item.groupName) + " ")]
                    )
                  }),
                  _vm.skuGroups.dynamic.length > 0
                    ? _c(
                        "el-submenu",
                        { attrs: { index: "other" } },
                        [
                          _c("template", { slot: "title" }, [
                            _vm._v(_vm._s(_vm.skuOtherName)),
                          ]),
                          _vm._l(_vm.skuGroups.dynamic, function (item) {
                            return _c(
                              "el-menu-item",
                              { key: item.id, attrs: { index: item.id } },
                              [_vm._v(" " + _vm._s(item.groupName) + " ")]
                            )
                          }),
                        ],
                        2
                      )
                    : _vm._e(),
                ],
                2
              )
            : _vm._e(),
          _vm.tabName === "shop"
            ? _c(
                "el-menu",
                {
                  staticClass: "el-menu-demo",
                  attrs: {
                    "default-active": _vm.shopGroups.activeIndex,
                    mode: "horizontal",
                  },
                  on: {
                    select: (val, path) =>
                      _vm.handleMenuSelect(val, path, "shopGroups"),
                  },
                },
                [
                  _vm._l(_vm.shopGroups.fixed, function (item) {
                    return _c(
                      "el-menu-item",
                      { key: item.id, attrs: { index: item.id } },
                      [_vm._v(" " + _vm._s(item.groupName) + " ")]
                    )
                  }),
                  _vm.shopGroups.dynamic.length > 0
                    ? _c(
                        "el-submenu",
                        { attrs: { index: "other" } },
                        [
                          _c("template", { slot: "title" }, [
                            _vm._v(_vm._s(_vm.shopOtherName)),
                          ]),
                          _vm._l(_vm.shopGroups.dynamic, function (item) {
                            return _c(
                              "el-menu-item",
                              { key: item.id, attrs: { index: item.id } },
                              [_vm._v(" " + _vm._s(item.groupName) + " ")]
                            )
                          }),
                        ],
                        2
                      )
                    : _vm._e(),
                ],
                2
              )
            : _vm._e(),
          _c(
            "el-button",
            {
              staticStyle: {
                float: "right",
                top: "-35px",
                position: "relative",
              },
              attrs: { size: "mini", icon: "el-icon-plus", type: "primary" },
              on: { click: _vm.handleAddGrp },
            },
            [
              _vm._v(
                _vm._s(_vm.tabName === "sku" ? "新建商品组" : "新建店铺组") +
                  " "
              ),
            ]
          ),
          _c("SkuItem", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.tabName === "sku",
                expression: "tabName==='sku'",
              },
            ],
            attrs: {
              "group-id": _vm.skuGroups.activeIndex,
              refresh: _vm.skuGroups.refresh,
              scope: _vm.form.importScope,
              "show-action": true,
            },
            on: {
              "add-items": _vm.addItems,
              "close-drawer": function ($event) {
                _vm.drawer.visible = false
              },
              getExceptModels: (callback) => callback(_vm.form.exceptModels),
              getExceptSkuCodes: (callback) =>
                callback(_vm.form.exceptSkuCodes),
            },
          }),
          _c("ShopItem", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.tabName === "shop",
                expression: "tabName==='shop'",
              },
            ],
            attrs: {
              "org-code": this.form.checkedOrgCodes,
              "group-id": _vm.shopGroups.activeIndex,
              refresh: _vm.shopGroups.refresh,
              "show-action": true,
            },
            on: {
              "add-items": _vm.addItems,
              "close-drawer": function ($event) {
                _vm.drawer.visible = false
              },
              getExceptCodes: (callback) => callback(_vm.form.exceptCodes),
            },
          }),
        ],
        1
      ),
      _c("GrpShop", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.ifComponent === "shop",
            expression: "ifComponent === 'shop'",
          },
        ],
        attrs: { component: _vm.ifComponent },
        on: {
          "update:component": function ($event) {
            _vm.ifComponent = $event
          },
          "dialog-close": _vm.handleDialogCloseEvent,
        },
      }),
      _c("GrpShop", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.ifComponent === "sku",
            expression: "ifComponent === 'sku'",
          },
        ],
        attrs: { component: _vm.ifComponent },
        on: {
          "update:component": function ($event) {
            _vm.ifComponent = $event
          },
          "dialog-close": _vm.handleDialogCloseEvent,
        },
      }),
      _c("ImportDialog", {
        attrs: {
          importBaseParams: _vm.importBaseParams(
            _vm.importDialog.importBaseParams
          ),
          "append-to-body": "",
        },
        on: { handleImportCallBack: _vm.handleImportData },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "导入结果",
            visible: _vm.noticeDialog.visible,
            "append-to-body": "",
            width: "700px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.noticeDialog, "visible", $event)
            },
          },
        },
        [
          _c("div", {
            staticStyle: { "font-size": "16px" },
            domProps: { innerHTML: _vm._s(_vm.noticeDialog.msg) },
          }),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: { click: _vm.downloadImportResult },
                },
                [_vm._v("下载导入结果")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.noticeDialog.visible = false
                    },
                  },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "标签排序",
            visible: _vm.sortDialog.visible,
            "append-to-body": "",
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.sortDialog, "visible", $event)
            },
          },
        },
        [
          _c("div", [
            _vm.sortDialog.sorts.length > 0
              ? _c(
                  "div",
                  _vm._l(_vm.sortDialog.sorts, function (item, index) {
                    return _c(
                      "el-row",
                      {
                        key: index,
                        staticStyle: {
                          "background-color": "rgb(220 220 220)",
                          "padding-top": "5px",
                          margin: "5px",
                        },
                      },
                      [
                        _c(
                          "el-col",
                          {
                            staticStyle: { "text-align": "center" },
                            attrs: { span: 12 },
                          },
                          [_vm._v(" " + _vm._s(item.label) + " ")]
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 11 } },
                          [
                            _c("el-button", {
                              attrs: {
                                type: "text",
                                size: "mini",
                                icon: "el-icon-upload2",
                                disabled: index === 0,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.handleMove("up", index)
                                },
                              },
                            }),
                            _c("el-button", {
                              attrs: {
                                type: "text",
                                size: "mini",
                                icon: "el-icon-download",
                                disabled:
                                  index === _vm.sortDialog.sorts.length - 1,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.handleMove("down", index)
                                },
                              },
                            }),
                            _c("el-button", {
                              attrs: {
                                type: "text",
                                size: "mini",
                                icon: "el-icon-delete",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.handleMove("remove", index)
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  1
                )
              : _c("div", { staticStyle: { color: "rgb(220 220 220)" } }, [
                  _vm._v(" 当前暂无标签 "),
                ]),
          ]),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: { click: _vm.handleConfirmSort },
                },
                [_vm._v("保存")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.sortDialog.visible = false
                    },
                  },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }