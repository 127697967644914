<template>
    <div style="width: 100px; height: 36px; margin: 0 auto;">
        <div :id="'main_' + easyKey" style="width: 100%; height: 100%; margin: 0 auto;" class="es" v-if="easyKey"></div>
    </div>
</template>

<script>

import * as echarts from 'echarts';
export default {
    props: {
        first: Number,
        second: Number,
        third: Number,
        easyKey: Number,
        month: String
    },
    data() {
        return {
            lastYearMonths: [],
        };
    },
    watch:{
        easyKey(){
            this.$nextTick(() => {
            this.initEcharts()
        })
        }
    },
    components: {

    },
    created() {
        this.$nextTick(() => {
            this.initEcharts()
        })
    },
    mounted() {
        this.lastYearMonths = this.getCurrentYearMonthAndPreviousTwoMonths();
    },
    methods: {

        initEcharts() {
            // 新建一个promise对象
            let newPromise = new Promise((resolve) => {
                resolve()
            })
            //然后异步执行echarts的初始化函数
            newPromise.then(() => {
                //	此dom为echarts图标展示dom
                // echarts.init(DOm)
 
                  this.drawChart();
    
               
            })
        },

        generateDataItem(valueProp, yearMonthStr) {
            const value = (this[valueProp] * 100).toFixed(1);
            const color = value >= 0 ? 'green' : 'red';
            return {
                value: value,
                itemStyle: { color: color, yearMonthStr: yearMonthStr },

            };
        },


        getCurrentYearMonthAndPreviousTwoMonths() {

            const currentYear = this.month.slice(0, 4);
            const currentMonth = this.month.slice(4);

            const previousMonths = [];
            for (let i = 0; i <= 2; i++) {
                const month = (parseInt(currentMonth) - i + 12) % 12 || 12;
                const year = parseInt(currentMonth) - i < 1 ? currentYear - 1 : currentYear;
                previousMonths.push({
                    years: year.toString().slice(-2) + month.toString().padStart(2, '0'),
                });
            }
            return previousMonths.reverse();
        },


        drawChart() {
            // 基于准备好的dom，初始化echarts实例  这个和上面的main对应
            // console.log(document.getElementById("main_" + this.easyKey))
            echarts.dispose(document.getElementById("main_" + this.easyKey))
            if(!document.getElementById("main_" + this.easyKey)) return
            let myChart = echarts.init(document.getElementById("main_" + this.easyKey));
            // 指定图表的配置项和数据
            myChart.setOption({
                xAxis: {
                    show: false, //取消显示坐标轴,坐标轴刻度,坐标值(如果是y轴,默认的网格线也会取消显示
                    type: 'category',
                },
                yAxis: {
                    axisLabel: { // 取消显示坐标值
                        show: false
                    },
                    splitLine: { //取消网格线
                        show: false
                    },
                    type: 'value'
                },
                grid: {
                    left: "conter",
                    right: "conter",
                    bottom: "10%",
                    top: "30%",
                    width: "110px",
                    height: "35px",
                    containLabel: true,
                },

                tooltip: {
                    trigger: 'axis',
                    formatter: function (params) {
                        let color = params[0].value >= 0 ? 'green' : 'red';
                        let str = '<div class="tooltip-div" style="color:' + color + ';  box-sizing:border-box; border-radius:4px; position:relative;">' +
                            "<div style='display:flex; align-items:center; '>" +
                            params[0].data.itemStyle.yearMonthStr + '：'+ params[0].value + "%" +
                            "</div>" +
                            "</div>";
                        return str;
                    },
                    position: function (point, params, dom, rect, size) {
                        var x = point[0];
                        var chartWidth = size.contentSize[0];
                        var viewWidth = size.viewSize[0];
                        // 如果 tooltip 会超出右侧边界，则将其移动到左侧  
                        if (x + chartWidth > viewWidth) {
                            x = x - chartWidth - 10; // 留出一些空间  
                        }
                        // 如果 tooltip 会超出左侧边界，则将其移动到右侧（这里是一个简单的示例，可能需要根据实际情况调整）  
                        if (x < 0) {
                            x = 10; // 确保有足够的空间  
                        }

                        return [-100, 4];
                    }
                },
                series: [
                    {
                        data: [this.generateDataItem('first', this.lastYearMonths[0].years), this.generateDataItem('second', this.lastYearMonths[1].years), this.generateDataItem('third', this.lastYearMonths[2].years)],
                        itemStyle: {
                            normal: {
                                lineStyle: {
                                    color: "#e3e3e3"//折线的颜色
                                }
                            }
                        },

                        type: 'line'
                    }
                ]
            });
        },

    },
};
</script>

<style lang='scss' scoped>
.es {
    :deep(div) {
        font-size: 12px !important;
        padding: 2px 4px !important;

        // left: -10px !important;
        .tooltip-div {
            left: 0 !important;
            padding: 0 !important;
        }
    }

}
</style>