<template>
  <div class="new-box">
    <div class="mg-search" >
        <el-card style="margin: 10px;">
        <SearchFilter  @search="handleQuery" @reset="resetQuery" @keyup.enter.native="handleQuery">
      <!-- <el-form ref="queryFormRef" size="mini" label-width="auto" :inline="true" :model="queryForm" @keyup.enter.native="handleQuery"> -->
        <el-form-item label="分公司">
          <DictSelect
            ref="org"
            type="order-org"
            :value.sync="queryForm.orgCode"
            linkageRef="dc"
            :dictFun="dictFun"
            fullLabel
            default-first-option
            :default-index="0"
            :clearable="false"
            @changeLinkageRef="handleChangeLinkageRef"
          />
        </el-form-item>
        <el-form-item label="配送中心">
          <DictSelect
            ref="dc"
            type="order-dc"
            :value.sync="queryForm.dcCode"
            :dictFun="dictFun"
            parentLinkageRef
            fullLabel
            :clearable="true"
            :default-index="0"
            alwaysChange
            @getOtherJsonParam="(callback) => callback({orgCode:queryForm.orgCode})"
            @changeLinkageRef="handleChangeLinkageRef"
          />
        </el-form-item>
        <el-form-item label="类型">
          <el-select v-model="queryForm.type" clearable placeholder="全部">
            <el-option v-for="item in options.typeOptions" :label="item.label" :value="item.value" :key="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item label="商品编码">
          <el-input v-model="queryForm.skuCode" clearable />
        </el-form-item>
        <el-form-item label="rid">
          <el-input v-model="queryForm.rid" clearable/>
        </el-form-item>
        <el-form-item label="时间范围">
          <el-date-picker
            v-model="queryForm.timeRange"
            type="daterange"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
            start-placeholder="开始时间"
            end-placeholder="结束时间">
          </el-date-picker>
        </el-form-item>
        <!-- <el-form-item>
          <el-button class="form-btn-item" size="mini" icon="el-icon-search" type="primary" @click="handleQuery">查询</el-button>
        </el-form-item> -->
      <!-- </el-form> -->
    </SearchFilter>
    </el-card>
    </div>
    <el-card style="margin: 10px;" class="box-card">
    <el-table
      :data="pageData.rows"
      class="customer-table"
      border
            size="small"
      v-loading="loading"
      highlight-current-row
    >
      <el-table-column type="index" label="序号" width="60"  />
      <el-table-column label="请求id" prop="rid"  />
      <el-table-column label="类型" prop="type"   />
      <el-table-column label="分公司" prop="orgCode"  />
      <el-table-column label="配送中心" prop="dcCode"  />
      <el-table-column label="商品编码" prop="skuCode"   />
      <el-table-column label="操作人" prop="createUser"  />
      <el-table-column label="操作时间" prop="createTime" />
      <el-table-column label="请求参数" prop="request" min-width="550" >
        <template slot-scope="scope">
          {{unpackage(scope.row.request)}}
        </template>
      </el-table-column>
    </el-table>
    <Pagination :total="pageData.total" :page.sync="queryForm.current" :limit.sync="queryForm.size" @pagination="handleQuery" />
</el-card>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import {dictSelect, getCurrentUser} from "@/api/appTask";
import DictSelect from "@/components/DictSelect";
import SearchFilter from "@/components/SearchFilter/index";
import {
  getAutoSupplyLog,
} from "@/api/biOrg";

export default {
  name: "AutoSupplyConfigLog",
  components: {Pagination, DictSelect, SearchFilter },
  props: {
    param: {
      type: Object,
      default() {
        return {
          orgCode: undefined,
          dcCode: undefined,
        }
      }
    }
  },
  data() {
    return {
      pageData: {
        total: 0,
        rows: [],
        selections: [],
      },
      queryForm: {
        current: 1,
        size: 10,
        orgCode: undefined,
        dcCode: undefined,
        skuCode: undefined,
        type: undefined,
        rid: undefined,
        startTime: undefined,
        endTime: undefined,
        timeRange: []
      },
      options: {
        typeOptions: [
          {value: '设置配送中心配置', label: '设置配送中心配置'},
          {value: '保存商品配置', label: '保存商品配置'},
          {value: '删除商品配置', label: '删除商品配置'},
          {value: '提交补货单', label: '提交补货单'},
          // {value: '批量设置商品配置', label: '批量设置商品配置'},
          // {value: '批量导入商品配置', label: '批量导入商品配置'},
        ],
      },
      loading: false,
      dictFun: dictSelect,
      currentRow: {},
      noticeDialog: {
        visible: false,
        msg: undefined,
      },
      requestChineseKeys: {
        orgCode: '分公司编码',
        dcCode: '配送中心编码',
        skuCode: '商品编码',
        batchSetUp: '批量设置',
        supplyDays: '日常补货天数',
        xxSupplyDays: '学汛补货天数',
        supplySpec: '补货规格(mid-中包装,bottom-箱)',
        supplyThreshold: '补货规格阈值',
        supplyBoxTransMid: '补货不足阈值转为中包装补(1-是,0-否)',
        modeType: '补货模式类型(1-自动补货,2-手动补货)',
        autoSwitch: '自动补货是否开启(1-是,0-否)',
        supplyTimeType: '补货周期时间类型(week-每周,mth-每月,half-mth-每半月)',
        supplyTimeDayListForWeek: '周补货周期时间',
        supplyTimeDay: '补货周期时间',
        skuList: '补货商品列表',
        requiredDeliveryTime: '要求到货时间',
        deliveryAddress: '收货地址',
        remark: '订单备注',
        supplyNumType: '补货阈值类型(0-按日均预估，1-按箱数补货)',
        supplyBoxNum: '日常补货箱数',
        xxSupplyBoxNum: '学汛补货箱数',
      },
    };
  },
  watch: {
    'queryForm.dcCode' () {
      if (this.queryForm.orgCode && this.queryForm.dcCode) {
        this.handleFilter();
      }
    }
  },
  created() {
    getCurrentUser().then(res => this.currentUser = res.data);
    this.queryForm.orgCode = this.param.orgCode;
    this.queryForm.dcCode = this.param.dcCode;
    this.handleFilter();
  },
  methods: {
    resetQuery(){
        const queryForm = {
        current: 1,
        size: 10,
        orgCode: this.queryForm.orgCode,
        dcCode: this.queryForm.dcCode,
        timeRange: []
      };
      this.queryForm = Object.assign({}, queryForm);
      this.$forceUpdate();
    },
    unpackage(str) {
      const obj = JSON.parse(str);
      let newJsonObject = {};
      for (let key in obj) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.requestChineseKeys.hasOwnProperty(key)) {
          newJsonObject[this.requestChineseKeys[key]] = obj[key];
        }
      }
      return newJsonObject;
    },
    handleQuery() {
      this.loading = true;
      if (this.queryForm.timeRange) {
        if (this.queryForm.timeRange.length > 0) {
          this.queryForm.startTime = this.queryForm.timeRange[0];
        }
        if (this.queryForm.timeRange.length > 1) {
          this.queryForm.endTime = this.queryForm.timeRange[1];
        }
      } else {
        this.queryForm.startTime = undefined;
        this.queryForm.endTime = undefined;
      }
      getAutoSupplyLog(this.queryForm).then(res => {
        this.loading = false;
        if (res.code === 200) {
          this.pageData.rows = res.data?.records;
          this.pageData.total = res.data?.total;
        }
      });
    },
    handleFilter() {
      this.queryForm.current = 1;
      this.queryForm.timeRange = [];
      this.handleQuery();
    },

    handleResetFields() {
      const form = {
        orgCode: undefined,
        dcCode: undefined,
        skuList: []
      };
      this.saveDialog.form = Object.assign({}, form);
      this.$forceUpdate();
    },
    handleChangeLinkageRef(ref, val, type) {
      this.$nextTick(() => {
        ref.split(',').forEach(e => {
          if (this.$refs[e]) {
            this.shopJsonParam = Object.assign({}, this.queryForm)
            setTimeout(() => {
              this.$refs[e].handleRefChange(val, type);
            }, 100)
            this.$forceUpdate();
          }
        })
      });
    }
  },
};
</script>

<style lang="scss">
// ::v-deep {
//   .el-dialog__body {
//     padding-top: 10px;
//     padding-bottom: 5px;
//   }
//   .el-input__inner {
//     padding-left: 5px !important;
//     max-width: 200px !important;
//   }
// }
.checkNumber {
  font-size: 16px;
  margin-bottom: 5px;
}
.bh-row {
  margin-bottom: 10px;
}
.bh-label {
  margin-top: 5px;
  font-weight: bold
}
.bh-tip {
  font-weight: bold;
  color: red;
  text-align: center;
  margin-bottom: 20px;
}
</style>
