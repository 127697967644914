<template>
    <div class="new-box">
        <div class="mg-search">
            <el-card style="margin: 10px">
                <!-- @search="handleFilter" @reset="resetQuery" @keyup.enter.native="handleFilter" -->
                <SearchFilter @search="FormData.page = 1; handleFilter(); " label-width="150px" @reset="resetQuery"
                    @keyup.enter.native="FormData.page = 1; handleFilter();">
                    <el-form-item label="月份">
                        <el-select v-model="FormData.mth" placeholder="请选择">
                            <el-option v-for="item in mth" :key="item.code" :label="item.name" :value="item.code">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="是否拆架装">
                        <el-select v-model="FormData.types" placeholder="请选择">
                            <el-option label="否" :value="0"> </el-option>
                            <el-option label="是" :value="1"> </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="赛道">
                        <el-select v-model="FormData.newRaceNames" filterable multiple collapse-tags placeholder="请选择">
                            <el-option v-for="item in race" :key="item.code" :label="item.name" :value="item.code">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="大类">
                        <el-select v-model="FormData.newMainCategoryNames" filterable multiple collapse-tags
                            placeholder="请选择" @change="changeCategories">
                            <el-option v-for="(item,index) in mainCategory" :key="index" :label="item.category"
                                :value="item.category">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="中类">
                        <el-select v-model="FormData.newMidCategoryNames" filterable multiple collapse-tags
                            placeholder="请选择" @change="changeMediumClass">
                            <el-option v-for="(item,index) in midCategory" :key="index" :label="item.category"
                                :value="item.category">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="小类">
                        <el-select v-model="FormData.newSubCategoryNames" filterable multiple collapse-tags
                            placeholder="请选择">
                            <el-option v-for="(item, index) in subCategory" :key="index" :label="item.category"
                                :value="item.category">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="是否创新中心">
                        <el-select v-model="FormData.isCxzx" placeholder="请选择">
                            <el-option label="否" :value="0"> </el-option>
                            <el-option label="是" :value="1"> </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="办公十大爆品品类">
                        <el-select v-model="FormData.ifTop10bp" placeholder="请选择">
                            <el-option label="否" :value="0"> </el-option>
                            <el-option label="是" :value="1"> </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="等级">
                        <el-select v-model="FormData.grades" filterable multiple collapse-tags placeholder="请选择">
                            <el-option v-for="item in grade" :key="item.code" :label="item.name" :value="item.code">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="型号">
                        <el-input v-model="FormData.skuModel" placeholder="请输入型号"></el-input>
                    </el-form-item>
                    <el-form-item label="模具">
                        <el-input v-model="FormData.moCode" placeholder="请输入模具"></el-input>
                    </el-form-item>
                    <el-form-item label="商品编码">
                        <el-input v-model="FormData.skuCode" placeholder="请输入商品编码"></el-input>
                    </el-form-item>
                    <el-form-item label="品名">
                        <el-input v-model="FormData.skuName" placeholder="请输入品名"></el-input>
                    </el-form-item>
                    <el-form-item label="是否显示近3月增长率" lable-width="150px">
                        <el-select v-model="growthRateShow" placeholder="请选择">
                            <el-option label="否" :value="1"> 否 </el-option>
                            <el-option label="是" :value="0"> 是</el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="是否显示近3月销售" lable-width="150px">
                        <el-select v-model="salesShow" placeholder="请选择">
                            <el-option label="否" :value="1"> 否 </el-option>
                            <el-option label="是" :value="0"> 是</el-option>
                        </el-select>
                    </el-form-item>
                </SearchFilter>
            </el-card>
        </div>
        <el-card style="margin: 10px" class="box-card">
            <el-button type="primary" size="small" @click="download" :loading="exportLoading">导出</el-button>
            <el-table :data="tableData" style="width: 100%; margin-top: 10px;" border size="small" :key="getRandom(20)"
                v-loading="loading" :header-cell-style="{ 'text-align': 'center', 'background': '#eef1f6' }"
                :cell-style="{ 'text-align': 'center' }">
                <el-table-column fixed prop="skuCode" label="商品编码">
                </el-table-column>
                <el-table-column prop="skuName" label="品名" width="150" fixed align="left">
                </el-table-column>
                <el-table-column prop="skuModel" label="型号" width="100" fixed align="left">
                </el-table-column>
                <el-table-column prop="moCode" label="模具">
                </el-table-column>
                <el-table-column prop="newRaceName" label="赛道" width="100" align="left">
                </el-table-column>
                <el-table-column prop="newMainCategoryName" label="大类" align="left">
                </el-table-column>
                <el-table-column prop="newMidCategoryName" label="中类" width="120" align="left">
                </el-table-column>
                <el-table-column prop="newSubCategoryName" label="小类" width="120" align="left">
                </el-table-column>
                <el-table-column prop="grade" label="等级">
                </el-table-column>
                <el-table-column prop="listingDay" label="上市日期" width="100">
                </el-table-column>
                <el-table-column prop="ifCxzx" label="是否创新中心" width="100">
                </el-table-column>
                <el-table-column prop="ifTop10bp" label="办公十个爆品品类" width="120">
                </el-table-column>
                <el-table-column prop="bnJhTotal" label="本年" sortable>
                    <template slot-scope="scope">
                        {{ Math.round(scope.row.bnJhTotal) }}
                    </template>
                </el-table-column>
                <el-table-column prop="zl" label="同比增量" sortable>
                    <template slot-scope="scope">
                        <div :style="{ color: scope.row.zl < 0 ? 'red' : '' }">
                            {{ Math.round(scope.row.zl) }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="zzl" label="增长率" sortable>
                    <template slot-scope="scope">
                        <div :style="{ color: formatPercentage(scope.row, 'zzl').color }">
                            {{ formatPercentage(scope.row, 'zzl').percentage }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="ssyZzl" label="上上月" sortable v-if="growthRateShow == 0">
                    <template slot-scope="scope">
                        <div :style="{ color: formatPercentage(scope.row, 'ssyZzl').color }">
                            {{ formatPercentage(scope.row, 'ssyZzl').percentage }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="syZzl" label="上月" sortable v-if="growthRateShow == 0">
                    <template slot-scope="scope">
                        <div :style="{ color: formatPercentage(scope.row, 'syZzl').color }">
                            {{ formatPercentage(scope.row, 'syZzl').percentage }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="byZzl" label="本月" sortable v-if="growthRateShow == 0">
                    <template slot-scope="scope">
                        <div :style="{ color: formatPercentage(scope.row, 'byZzl').color }">
                            {{ formatPercentage(scope.row, 'byZzl').percentage }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="近3月趋势" width="120">
                    <template slot-scope="scope">
                        <TrendCharts :first="scope.row.ssyZzl" :second="scope.row.syZzl" :third="scope.row.byZzl"
                            :easyKey="getRandom(20)" :month="years"></TrendCharts>
                    </template>
                </el-table-column>
                <el-table-column prop="ssyJhTotal" :label="`${previousMonths[0].year}年${previousMonths[0].month}月`"
                sortable v-if="salesShow == 0">
                    <template slot-scope="scope">
                        <div :style="{ color: scope.row.ssyJhTotal < 0 ? 'red' : '' }">
                            {{ scope.row.ssyJhTotal.toFixed(1) }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="syJhTotal" :label="`${previousMonths[1].year}年${previousMonths[1].month}月`"
                sortable v-if="salesShow == 0">
                    <template slot-scope="scope">
                        <div :style="{ color: scope.row.syJhTotal < 0 ? 'red' : '' }">
                            {{ scope.row.syJhTotal.toFixed(1) }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="byJhTotal" :label="`${previousMonths[2].year}年${previousMonths[2].month}月`"
                sortable v-if="salesShow == 0">
                    <template slot-scope="scope">
                        <div :style="{ color: scope.row.byJhTotal < 0 ? 'red' : '' }">
                            {{ scope.row.byJhTotal.toFixed(1) }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="ssyJhTotalTq" :label="`${lastYearMonths[0].year}年${lastYearMonths[0].month}月`"
                sortable v-if="salesShow == 0">
                    <template slot-scope="scope">
                        <div :style="{ color: scope.row.ssyJhTotalTq < 0 ? 'red' : '' }">
                            {{ scope.row.ssyJhTotalTq.toFixed(1) }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="syJhTotalTq" :label="`${lastYearMonths[1].year}年${lastYearMonths[1].month}月`"
                sortable v-if="salesShow == 0">
                    <template slot-scope="scope">
                        <div :style="{ color: scope.row.syJhTotalTq < 0 ? 'red' : '' }">
                            {{ scope.row.syJhTotalTq.toFixed(1) }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="byJhTotalTq" :label="`${lastYearMonths[2].year}年${lastYearMonths[2].month}月`"
                sortable v-if="salesShow == 0">
                    <template slot-scope="scope">
                        <div :style="{ color: scope.row.byJhTotalTq < 0 ? 'red' : '' }">
                            {{ scope.row.byJhTotalTq.toFixed(1) }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="bnOfflineJhTotal" label="线下本年" sortable>
                    <template slot-scope="scope">
                        {{ Math.round(scope.row.bnOfflineJhTotal) }}
                    </template>
                </el-table-column>
                <el-table-column prop="bnOfflineJhTotalZb" label="线下销售占比" sortable>
                    <template slot-scope="scope">
                        <div :style="{ color: formatPercentage(scope.row, 'bnOfflineJhTotalZb').color }">
                            {{ formatPercentage(scope.row, 'bnOfflineJhTotalZb').percentage }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="offlineZl" label="线下同比增量" sortable>
                    <template slot-scope="scope">
                        <div :style="{ color: scope.row.offlineZl < 0 ? 'red' : '' }">
                            {{ Math.round(scope.row.offlineZl) }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="offlineZlAb" label="线下增量占比" sortable>
                    <template slot-scope="scope">
                        <div :style="{ color: formatPercentage(scope.row, 'offlineZlAb').color }">
                            {{ formatPercentage(scope.row, 'offlineZlAb').percentage }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="offlineZzl" label="线下增长率" sortable>
                    <template slot-scope="scope">
                        <div :style="{ color: formatPercentage(scope.row, 'offlineZzl').color }">
                            {{ formatPercentage(scope.row, 'offlineZzl').percentage }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="offlineSsyZzl" label="线下上上月" sortable v-if="growthRateShow == 0">
                    <template slot-scope="scope">
                        <div :style="{ color: formatPercentage(scope.row, 'offlineSsyZzl').color }">
                            {{ formatPercentage(scope.row, 'offlineSsyZzl').percentage }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="offlineSyZzl" label="线下上月" sortable v-if="growthRateShow == 0">
                    <template slot-scope="scope">
                        <div :style="{ color: formatPercentage(scope.row, 'offlineSyZzl').color }">
                            {{ formatPercentage(scope.row, 'offlineSyZzl').percentage }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="offlineByZzl" label="线下本月" sortable v-if="growthRateShow == 0">
                    <template slot-scope="scope">
                        <div :style="{ color: formatPercentage(scope.row, 'offlineByZzl').color }">
                            {{ formatPercentage(scope.row, 'offlineByZzl').percentage }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="线下近3月趋势" width="120">
                    <template slot-scope="scope">
                        <TrendCharts :first="scope.row.offlineSsyZzl" :second="scope.row.offlineSyZzl"
                            :third="scope.row.offlineByZzl" :easyKey="getRandom(20)" :month="years"></TrendCharts>
                    </template>
                </el-table-column>
                <el-table-column prop="ssyOfflineJhTotal"
                    :label="`线下${previousMonths[0].year}年${previousMonths[0].month}月`" sortable
                    v-if="salesShow == 0">
                    <template slot-scope="scope">
                        <div :style="{ color: scope.row.ssyOfflineJhTotal < 0 ? 'red' : '' }">
                            {{ scope.row.ssyOfflineJhTotal.toFixed(1) }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="syOfflineJhTotal"
                    :label="`线下${previousMonths[1].year}年${previousMonths[1].month}月`" sortable
                    v-if="salesShow == 0">
                    <template slot-scope="scope">
                        <div :style="{ color: scope.row.syOfflineJhTotal < 0 ? 'red' : '' }">
                            {{ scope.row.syOfflineJhTotal.toFixed(1) }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="byOfflineJhTotal"
                    :label="`线下${previousMonths[2].year}年${previousMonths[2].month}月`" sortable
                    v-if="salesShow == 0">
                    <template slot-scope="scope">
                        <div :style="{ color: scope.row.byOfflineJhTotal < 0 ? 'red' : '' }">
                            {{ scope.row.byOfflineJhTotal.toFixed(1) }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="ssyOfflineJhTotalTq"
                    :label="`线下${lastYearMonths[0].year}年${lastYearMonths[0].month}月`" sortable
                    v-if="salesShow == 0">
                    <template slot-scope="scope">
                        <div :style="{ color: scope.row.ssyOfflineJhTotalTq < 0 ? 'red' : '' }">
                            {{ scope.row.ssyOfflineJhTotalTq.toFixed(1) }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="syOfflineJhTotalTq"
                    :label="`线下${lastYearMonths[1].year}年${lastYearMonths[1].month}月`" sortable
                    v-if="salesShow == 0">
                    <template slot-scope="scope">
                        <div :style="{ color: scope.row.syOfflineJhTotalTq < 0 ? 'red' : '' }">
                            {{ scope.row.syOfflineJhTotalTq.toFixed(1) }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="byOfflineJhTotalTq"
                    :label="`线下${lastYearMonths[2].year}年${lastYearMonths[2].month}月`" sortable
                    v-if="salesShow == 0">
                    <template slot-scope="scope">
                        <div :style="{ color: scope.row.byOfflineJhTotalTq < 0 ? 'red' : '' }">
                            {{ scope.row.byOfflineJhTotalTq.toFixed(1) }}
                        </div>
                    </template>
                </el-table-column>

                <el-table-column prop="bnOnlineJhTotal" label="线上本年" sortable>
                    <template slot-scope="scope">
                        {{ Math.round(scope.row.bnOnlineJhTotal) }}
                    </template>
                </el-table-column>
                <el-table-column prop="bnOnlineJhTotalZb" label="线上销售占比" sortable>
                    <template slot-scope="scope">
                        <div :style="{ color: formatPercentage(scope.row, 'bnOnlineJhTotalZb').color }">
                            {{ formatPercentage(scope.row, 'bnOnlineJhTotalZb').percentage }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="onlineZl" label="线上同比增量" sortable>
                    <template slot-scope="scope">
                        <div :style="{ color: scope.row.onlineZl < 0 ? 'red' : '' }">
                            {{ Math.round(scope.row.onlineZl) }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="onlineZlZb" label="线上增量占比" sortable>
                    <template slot-scope="scope">
                        <div :style="{ color: formatPercentage(scope.row, 'onlineZlZb').color }">
                            {{ formatPercentage(scope.row, 'onlineZlZb').percentage }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="onlineZzl" label="线上增长率" sortable>
                    <template slot-scope="scope">
                        <div :style="{ color: formatPercentage(scope.row, 'onlineZzl').color }">
                            {{ formatPercentage(scope.row, 'onlineZzl').percentage }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="onlineSsyZzl" label="线上上上月" sortable v-if="growthRateShow == 0">
                    <template slot-scope="scope">
                        <div :style="{ color: formatPercentage(scope.row, 'onlineSsyZzl').color }">
                            {{ formatPercentage(scope.row, 'onlineSsyZzl').percentage }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="onlineSyZzl" label="线上上月" sortable v-if="growthRateShow == 0">
                    <template slot-scope="scope">
                        <div :style="{ color: formatPercentage(scope.row, 'onlineSyZzl').color }">
                            {{ formatPercentage(scope.row, 'onlineSyZzl').percentage }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="onlineByZzl" label="线上本月" sortable v-if="growthRateShow == 0">
                    <template slot-scope="scope">
                        <div :style="{ color: formatPercentage(scope.row, 'onlineByZzl').color }">
                            {{ formatPercentage(scope.row, 'onlineByZzl').percentage }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="线上近3月趋势" width="120">
                    <template slot-scope="scope">
                        <TrendCharts :first="scope.row.onlineSsyZzl" :second="scope.row.onlineSyZzl"
                            :third="scope.row.onlineByZzl" :easyKey="getRandom(20)" :month="years"></TrendCharts>
                    </template>
                </el-table-column>
                <el-table-column prop="ssyOnlineJhTotal"
                    :label="`线上${previousMonths[0].year}年${previousMonths[0].month}月`" sortable
                    v-if="salesShow == 0">
                    <template slot-scope="scope">
                        <div :style="{ color: scope.row.ssyOnlineJhTotal < 0 ? 'red' : '' }">
                            {{ scope.row.ssyOnlineJhTotal.toFixed(1) }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="syOnlineJhTotal"
                    :label="`线上${previousMonths[1].year}年${previousMonths[1].month}月`" sortable
                    v-if="salesShow == 0">
                    <template slot-scope="scope">
                        <div :style="{ color: scope.row.syOnlineJhTotal < 0 ? 'red' : '' }">
                            {{ scope.row.syOnlineJhTotal.toFixed(1) }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="byOnlineJhTotal"
                    :label="`线上${previousMonths[2].year}年${previousMonths[2].month}月`" sortable
                    v-if="salesShow == 0">
                    <template slot-scope="scope">
                        <div :style="{ color: scope.row.byOnlineJhTotal < 0 ? 'red' : '' }">
                            {{ scope.row.byOnlineJhTotal.toFixed(1) }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="ssyOnlineJhTotalTq"
                    :label="`线上${lastYearMonths[0].year}年${lastYearMonths[0].month}月`" sortable
                    v-if="salesShow == 0">
                    <template slot-scope="scope">
                        <div :style="{ color: scope.row.ssyOnlineJhTotalTq < 0 ? 'red' : '' }">
                            {{ scope.row.ssyOnlineJhTotalTq.toFixed(1) }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="syOnlineJhTotalTq"
                    :label="`线上${lastYearMonths[1].year}年${lastYearMonths[1].month}月`" sortable
                    v-if="salesShow == 0">
                    <template slot-scope="scope">
                        <div :style="{ color: scope.row.syOnlineJhTotalTq < 0 ? 'red' : '' }">
                            {{ scope.row.syOnlineJhTotalTq.toFixed(1) }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="byOnlineJhTotalTq"
                    :label="`线上${lastYearMonths[2].year}年${lastYearMonths[2].month}月`" sortable
                    v-if="salesShow == 0">
                    <template slot-scope="scope">
                        <div :style="{ color: scope.row.byOnlineJhTotalTq < 0 ? 'red' : '' }">
                            {{ scope.row.byOnlineJhTotalTq.toFixed(1) }}
                        </div>
                    </template>
                </el-table-column>
            
            </el-table>
            <Pagination :total="total" :page.sync="FormData.page" :limit.sync="FormData.limit"
                @pagination="handleFilter" />
        </el-card>
    </div>
</template>

<script>
import SearchFilter from "@/components/SearchFilter/index";
import { getPreviousAndLastYearMonths } from "@/utils/index";
import Pagination from "@/components/Pagination";
import TrendCharts from "./trendCharts.vue";
import { productDimensions, dropDownOptions, exportProductDimensions, selectOptions } from "@/api/sales";
export default {
    components: { SearchFilter, TrendCharts, Pagination },
    props: {
        propName: String,
        time: String
    },
    data() {
        return {
            total: 0,
            growthRateShow: 1,
            salesShow: 1,
            loading: false,
            years: "",
            exportLoading: false,
            tableData: [],
            lastYearMonths: [],
            previousMonths: [],
            FormData: {
                page: 1,
                limit: 10,
                mth: "",
                newRaceNames: "",
                newMainCategoryNames: "",
                newMidCategoryNames: "",
                newSubCategoryNames: "",
                types: 0,
                isCxzx: "",
                ifTop10bp: "",
                skuCode: "",
                skuName: "",
                skuModel: "",
                grades: "",
                moCode: "",
            },
            mth: [],
            race: [],
            grade: [],
            mainCategory: [],
            midCategory: [],
            subCategory: [],
        };
    },

    created() {
        if (this.propName) this.FormData.newSubCategoryNames = [this.propName]; this.FormData.mth = this.time;
    },
    mounted() {
        this.setCurrentMonthRange();
        this.dropDownOptionsList();
        this.getData();
    },
    methods: {
        formatPercentage(row, propName) {
            const value = row[propName];
            const percentage = value ? (value * 100).toFixed() + '%' : '0%';
            const color = value && value * 100 < 0 ? 'red' : '';
            return { percentage, color };
        },
        getRandom(num) {
            return Math.floor((Math.random() + Math.floor(Math.random() * 9 + 1)) * Math.pow(10, num - 1));
        },
        formatYearMonth(yearMonthStr) {
            // 提取年份和月份  
            const year = yearMonthStr.substring(0, 4);
            const month = yearMonthStr.substring(4, 6);
            const endMonth = month.padStart(2, '0');
            return `${year}-${endMonth}`

        },
        getData() {
            this.loading = true;
            productDimensions(this.FormData).then((res) => {
                this.tableData = res.data.datas;
                this.total = res.data.total;
                this.loading = false;
            });
        },
        getCurrentYearMonthString() {
            const date = new Date();
            const year = date.getFullYear();
            const month = date.getMonth() + 1;
            const toMonth = `${year}${month.toString().padStart(2, "0")}`;
            return toMonth
        },
        setCurrentMonthRange() {
            if(!this.time){
             this.FormData.mth = this.getCurrentYearMonthString();
            }
            const end = this.formatYearMonth(this.FormData.mth);
            this.lastYearMonths = getPreviousAndLastYearMonths(end).lastYearMonths;
            this.previousMonths = getPreviousAndLastYearMonths(end).previousMonths;
            this.years = this.FormData.mth;
        },
        dropDownOptionsList() {
            const promises = [
                dropDownOptions({ tableType: "sku", fieldType: "mth" }).then(res => res.data),
                dropDownOptions({ tableType: "sku", fieldType: "race" }).then(res => res.data),
                // dropDownOptions({ tableType: "sku", fieldType: "mainCategory" }).then(res => res.data),
                // dropDownOptions({ tableType: "sku", fieldType: "midCategory" }).then(res => res.data),
                // dropDownOptions({ tableType: "sku", fieldType: "subCategory" }).then(res => res.data),
                dropDownOptions({ tableType: "sku", fieldType: "grade" }).then(res => res.data),
                selectOptions().then(res => res.data)
            ];
            //  mainCategory, midCategory, subCategory,
            Promise.all(promises).then(([mth, race, grade, type]) => {
                this.mth = mth;
                this.race = race;
                this.grade = grade;
                this.mainCategory = type;
                this.mediumClassAndSmallClassDataProcessing();
                console.log(type);
            }).catch(error => {
                console.error('加载数据出错:', error);
            });
        },
        download() {
            this.exportLoading = true;
            exportProductDimensions(this.FormData).then((res) => {
                const link = document.createElement('a')
                link.style.display = 'none'
                link.href = window.URL.createObjectURL(new Blob([res]))
                link.setAttribute('download', '单品维度报表.xlsx')
                document.body.appendChild(link)
                link.click()
                link.remove()
            }).finally(()=>{
                this.exportLoading = false
             })
        },
        handleFilter() {
            const end = this.formatYearMonth(this.FormData.mth);
            this.lastYearMonths = getPreviousAndLastYearMonths(end).lastYearMonths;
            this.previousMonths = getPreviousAndLastYearMonths(end).previousMonths;
            this.years = this.FormData.mth;
            this.getData();
        },
        changeCategories() {
            this.midCategory = [];
            this.subCategory = [];
            this.FormData.newMidCategoryNames = null;
            this.FormData.newSubCategoryNames = null;
            if (this.FormData.newMainCategoryNames.length != 0) {
                for (const item of this.mainCategory) {
                    for (const item1 of this.FormData.newMainCategoryNames) {
                        if (item.category == item1) {
                            this.midCategory.push(...item.subCategorys)
                        }
                    }
                }
            } else {
                this.mediumClassAndSmallClassDataProcessing();
            }
        },
        changeMediumClass() {
            this.subCategory = [];
            this.FormData.newSubCategoryNames = null;
            if (this.FormData.newMainCategoryNames.length != 0 || this.FormData.newMidCategoryNames.length != 0) {
            for (const item of this.midCategory) {
                for (const item1 of this.FormData.newMidCategoryNames) {
                    if (item.category == item1) {
                        this.subCategory.push(...item.subCategorys)
                    }
                }
            }
        }else{
            this.mediumClassAndSmallClassDataProcessing()
        }
        },

        mediumClassAndSmallClassDataProcessing() {
            this.mainCategory.forEach(item => {
                if (item.subCategorys) {
                    this.midCategory.push(...item.subCategorys);
                }
            });
            this.midCategory.forEach(midItem => {
                if (midItem.subCategorys) {
                    this.subCategory.push(...midItem.subCategorys);
                }
            });
        },

        resetQuery() {
            this.FormData = {
                page: 1,
                limit: 10,
                mth: this.getCurrentYearMonthString(),
                types: 0,
            };
            this.growthRateShow = 1;
            this.salesShow = 1
            this.getData();
        },
    },
};
</script>

<style lang="scss" scoped>
:deep(.el-table .caret-wrapper){
    width: 10px !important;
}
</style>
