<template>
  <div class="new-box">
    <div class="mg-search" >
        <el-card style="margin: 10px;">
        <SearchFilter @search="handleFilter('hand')"  @reset="resetQuery" @keyup.enter.native="handleFilter">
      <!-- <el-form ref="queryFormRef" size="mini" label-width="auto" :inline="true" :model="queryForm" @keyup.enter.native="handleFilter"> -->
        <el-form-item v-if="authOrgOptions.length > 1" label="分公司">
          <el-select v-model="queryForm.orgCode" filterable :clearable="authOrgAll" default-first-option>
            <el-option v-for="item in authOrgOptions" :key="item.value" :value="item.value" :label="item.value + '-' + item.label" />
          </el-select>
        </el-form-item>
        <el-form-item label="核销名称">
          <el-input v-model="queryForm.name" clearable placeholder="请输入名称关键字" />
        </el-form-item>
        <!-- <el-form-item>
          <el-button class="form-btn-item" size="mini" icon="el-icon-search" type="primary" @click="handleFilter('hand')">查询</el-button>

        </el-form-item> -->
      <!-- </el-form> -->
    </SearchFilter>
    </el-card>
    </div>
    <el-card style="margin: 10px;" class="box-card">
      <el-button class="form-btn-item" size="mini" icon="el-icon-plus" type="primary" @click="handleAdd('add')">新增</el-button>
    <el-table
      style="margin-top: 10px;"
      ref="tableRef"
      :data="pageData.rows"
      class="customer-table"
      border
           size="small"
      v-loading="loading"
      highlight-current-row
    >
      <el-table-column label="分公司" prop="title"  >
        <template #default="{ row }">
          <span>{{ getOrgInfo(row.orgCode) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="名称" prop="name"  />
      <el-table-column label="创建时间" prop="createTime"  />
      <el-table-column label="创建人" prop="createUser" />
      <el-table-column label="操作" width="250" align="center">
        <template #default="{ row }">
          <el-button v-if="row.createTime && new Date(row.createTime).getTime()>new Date('2024-05-10').getTime() && (row.orgCode!=='ALL' || (row.orgCode==='ALL' && authOrgAll))" size="mini" type="text" style="border: 0px;" @click="handleEdit(row)">编辑</el-button>
<!--          <el-button size="mini" type="text" style="border: 0px;" @click="handleDel(row)">删除</el-button>-->
        </template>
      </el-table-column>
    </el-table>
    <Pagination :total="pageData.total" :page.sync="queryForm.current" :limit.sync="queryForm.size" @pagination="handleQuery" />
</el-card>
    <el-drawer :title="saveDialog.titleMap[saveDialog.type]" :center="true" :visible.sync="saveDialog.visible" append-to-body size="70%" direction="ltr" :close-on-click-modal="false">
      <el-form ref="saveFormRef" size="mini" :inline="true" :rules="saveDialog.rules" :model="saveDialog.form" style="padding-left: 20px;">
        <div style="overflow-y: auto;">
          <el-row>
            <el-col>
              <el-form-item label="核销名称" prop="name">
                <el-input v-model="saveDialog.form.name" style="width: 350px" clearable placeholder="请输入核销名称" />
              </el-form-item>
            </el-col>
            <el-col>
              <el-form-item label="核销范围" prop="orgCode">
                <el-select v-model="saveDialog.form.orgCode" @change="handleOrgChange">
                  <el-option v-for="item in authOrgOptions" :label="item.label" :value="item.value" :key="item.key" />
                </el-select>
              </el-form-item>
            </el-col>
<!--            <el-col>-->
<!--              <el-form-item label="照片限制" prop="takePhotos">-->
<!--                <el-radio-group v-model="saveDialog.form.takePhotos">-->
<!--                  <el-radio :label="1" >仅允许拍照上传</el-radio><el-radio :label="0" >允许拍照上传+相册上传</el-radio>-->
<!--                </el-radio-group>-->
<!--              </el-form-item>-->
<!--            </el-col>-->
            <el-col>
              <el-form-item label="审核设置" prop="onlyAuditByOrg">
                <el-radio-group v-model="saveDialog.form.onlyAuditByOrg">
                  <el-radio :label="1" >仅需分公司审核(总部可抽查)</el-radio><el-radio :label="0" >分公司审核+总部审核</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col>
              <el-form-item label="门店头图" prop="orgCode">
                <el-radio-group v-model="saveDialog.form.doorHeadPhotoV3.show">
                  <el-radio :label="true" >需要</el-radio><el-radio :label="false" >不需要</el-radio>
                </el-radio-group>
                <div v-if="saveDialog.form.doorHeadPhotoV3.show" style="margin-left: 10px">
                  <label>照片数范围：</label>
                  <el-input-number v-model="saveDialog.form.doorHeadPhotoV3.photoMin" clearable :controls="false" :min="0" size="small" style="width: 50px" />-
                  <el-input-number v-model="saveDialog.form.doorHeadPhotoV3.photoMax" clearable :controls="false" :min="0" size="small" style="width: 50px" />
                </div>
                <el-select v-model="saveDialog.form.doorHeadPhotoV3.include" style="width: 150px" placeholder="上传方式" multiple>
                  <el-option v-for="item in taskPhotosOptions" :label="item.label" :value="item.value" :key="item.value" />
                </el-select>
              </el-form-item>
            </el-col>

            <el-col>
              <el-form-item label="上传次数">
                <el-radio-group v-model="saveDialog.form.multiUpload">
                  <el-radio :label="0">单次上传(可撤回修改)</el-radio>
                  <el-radio :label="1">可多次上传</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>

            <el-col v-for="(t,index) in saveDialog.form.taskConfigs" :key="index" style="border: 1px solid #d9cccc;padding: 5px;">
              <el-form-item>
                <el-row>
                  <el-row>
                    <el-col style="color: #ac7017">
                      任务 {{ index + 1 }}
                    </el-col>
                    <el-col >
                      <el-form-item label="任务" style="display: inline-flex">
                        <el-select v-model="t.mtTaskId" filterable @change="(val) => {
                          t.boostId = undefined;
                          listTaskBoost(val)
                        }">
                          <el-option v-for="ele in taskOptions" :value="ele.mtTaskId" :label="ele.orgName + '-' + ele.taskName" :key="ele.mtTaskId" />
                        </el-select>
                      </el-form-item>
                      <el-form-item v-if="t.mtTaskId && taskBoostOptions[t.mtTaskId] && taskBoostOptions[t.mtTaskId].length > 0" label="激励" style="display: inline-flex">
                        <el-select v-model="t.boostId" filterable @change="() => item.boostInfo = undefined" clearable>
                          <el-option v-for="ele in taskBoostOptions[t.mtTaskId]" :value="ele.id" :label="ele.name" :key="ele.id" />
                        </el-select>
                      </el-form-item>
                      <el-form-item v-if="t.mtTaskId">
                        <el-button type="text" style="border: 0px;" @click="handleEditBoost(t, index)">{{ (t.boostId || (t.boostInfo && t.boostInfo.name))? '编辑激励' + (t.boostInfo && t.boostInfo.name? '-(' + t.boostInfo.name + ')': ''): '新建激励' }}</el-button>
                      </el-form-item>
                    </el-col>
                    <el-col style="margin-left: 20px;">
                      <el-row v-for="(item, mIndex) in t.moduleConfigs" :key="mIndex" style="border: 1px solid gray;padding: 5px;margin-top: 10px">
                        <el-col style="color: #ac7017">
                          模块 {{ mIndex + 1 }}
                        </el-col>
                        <el-col>
                          <el-form-item label="模块名称">
                            <el-input v-model="item.name" />
                          </el-form-item>
                        </el-col>
                        <el-col>
                          <div>
                            <label>照片项配置</label>
                          </div>
                          <div v-for="(ele,i) in item.photoConfigs" :key="i" style="margin-bottom: 10px;border: 1px solid #aca8a8;padding: 5px;">
                            <el-row>
                              <el-col :span="6">
                                <el-form-item label="配置名">
                                  <el-input v-model="ele.name" placeholder="如：上传照片" />
                                </el-form-item>
                              </el-col>
                              <el-col :span="6">
                                <el-form-item label="配置备注">
                                  <el-input v-model="ele.remark" placeholder="配置的备注信息" />
                                </el-form-item>
                              </el-col>
                              <el-col>
                                <el-form-item label="照片必填">
                                  <el-radio-group v-model="ele.required">
                                    <el-radio :label="true" >是</el-radio><el-radio :label="false" >否</el-radio>
                                  </el-radio-group>
                                </el-form-item>
                              </el-col>
                              <el-col>
                                <el-form-item label="类型">
                                  <el-radio-group v-model="ele.showAfter">
                                    <el-radio :label="false" >上传照片</el-radio><el-radio :label="true" >打造前/打造后</el-radio>
                                  </el-radio-group>
                                  <div>
                                    <div v-if="!ele.showAfter" style="display: inline-block;margin-left: 10px">
                                      <label>照片数范围：</label>
                                      <el-input-number v-model="ele.photoMin" clearable :controls="false" :min="1" :max="10" size="small" style="width: 50px" />-
                                      <el-input-number v-model="ele.photoMax" clearable :controls="false" :min="2" :max="10" size="small" style="width: 50px" />

                                      <el-input v-model="ele.desc" clearable size="small" style="width: 150px" placeholder="请输入描述" />
                                      <el-select v-model="ele.include" multiple>
                                        <el-option v-for="item in taskPhotosOptions" :label="item.label" :value="item.value" :key="item.value" />
                                      </el-select>
                                    </div>
                                    <div v-if="ele.showAfter" style="display: inline-block;margin-left: 10px">
                                      <div>
                                        <label>打造前照片数范围：</label>
                                        <el-input-number v-model="ele.photoMin" clearable :controls="false" :min="0" size="small" style="width: 50px" />-
                                        <el-input-number v-model="ele.photoMax" clearable :controls="false" :min="0" size="small" style="width: 50px" />
                                        <el-input v-model="ele.desc" clearable size="small" style="width: 150px" placeholder="请输入上传前照片描述" />
                                        <el-select v-model="ele.include" style="width: 150px" placeholder="上传方式" multiple>
                                          <el-option v-for="item in taskPhotosOptions" :label="item.label" :value="item.value" :key="item.value" />
                                        </el-select>
                                      </div>
                                      <div>
                                        <label>打造后照片数范围：</label>
                                        <el-input-number v-model="ele.photoMinAfter" clearable :controls="false" :min="0" size="small" style="width: 50px" />-
                                        <el-input-number v-model="ele.photoMaxAfter" clearable :controls="false" :min="0" size="small" style="width: 50px" />
                                        <el-input v-model="ele.descAfter" clearable size="small" style="width: 150px" placeholder="请输入上传前照片描述" />
                                        <el-select v-model="ele.includeAfter" style="width: 150px" placeholder="上传方式" multiple>
                                          <el-option v-for="item in taskPhotosOptions" :label="item.label" :value="item.value" :key="item.value" />
                                        </el-select>
                                      </div>
                                    </div>
                                  </div>
                                </el-form-item>
                              </el-col>
                              <el-button type="text" style="border: 0px;" @click="() => {
                                  item.photoConfigs.splice(i, 1);
                                  $forceUpdate();
                                }">删除</el-button>
                              <el-button type="text" style="border: 0px;" @click="() => {
                                let photoConfig = deepClone(saveDialog.defaultPhotoConfig);
                                photoConfig.id = uuidv4().replaceAll('-', '');
                                item.photoConfigs.push(photoConfig);
                                $forceUpdate();
                              }">新增照片配置</el-button>
                              <el-button :disabled="i===0" type="text" style="border: 0px;" @click="() => {
                                  item.photoConfigs.splice(i, 1)
                                  item.photoConfigs.splice(i - 1, 0, ele)
                                }">照片配置上移</el-button>
                              <el-button :disabled="i==item.photoConfigs.length-1" type="text" style="border: 0px;" @click="() => {
                                  item.photoConfigs.splice(i, 1)
                                  item.photoConfigs.splice(i + 1, 0, ele)
                                }">照片配置下移</el-button>
                            </el-row>
                            <el-divider v-if="i!== item.photoConfigs.length-1" style="margin: 5px;width: 50%;"></el-divider>
                          </div>
                        </el-col>
                        <el-col>
                          <div>
                            <label>模块-文本项配置</label>
                          </div>
                          <div>
                            <el-form-item label="配置名">
                              <el-input v-model="item.textConfigs.name" clearable placeholder="如：道具上柜" />
                            </el-form-item>
                            <el-form-item label="配置备注">
                              <el-input v-model="item.textConfigs.remark" clearable placeholder="如：选填" />
                            </el-form-item>
                            <el-row v-for="(ele, i) in item.textConfigs.configs" :key="i">
                              <el-form-item label="描述">
                                <el-input v-model="ele.desc" clearable />
                              </el-form-item>
                              <el-form-item label="类型">
                                <el-radio-group v-model="ele.type">
                                  <el-radio label="text">文本</el-radio><el-radio label="number">数字</el-radio><el-radio label="select">选项</el-radio>
                                </el-radio-group>
                                <div v-if="ele.type === 'select'" style="display: inline-block;margin-left: 10px">
                                  <label>选项类型</label>
                                  <el-select v-model="ele.chooseType">
                                    <el-option label="单选" value="single" /> <el-option label="多选" value="multiple" />
                                  </el-select>
                                  <label>选项数据</label>
                                  <el-select v-model="ele.chooseText" filterable allow-create multiple placeholder="请输入后手动选择即可">
                                  </el-select>
                                </div>
                              </el-form-item>
                              <el-form-item>
                                <el-button type="text" style="border: 0px;" @click="() => {
                              item.textConfigs.configs.splice(i, 1);
                              $forceUpdate();
                            }">删除文本配置</el-button>
                              </el-form-item>
                              <el-button :disabled="i===0" type="text" style="border: 0px;" @click="() => {
                                  item.textConfigs.configs.splice(i, 1)
                                  item.textConfigs.configs.splice(i - 1, 0, ele)
                                }">模块文本配置上移</el-button>
                              <el-button :disabled="i===item.textConfigs.configs.length-1" type="text" style="border: 0px;" @click="() => {
                                  item.textConfigs.configs.splice(i, 1)
                                  item.textConfigs.configs.splice(i + 1, 0, ele)
                                }">模块文本配置下移</el-button>
                            </el-row>
                            <el-button type="text" style="border: 0px;" @click="() => {
                              let textConfig = deepClone(saveDialog.defaultTextConfig);
                              textConfig.id = item.textConfigs.configs.length + 1
                              item.textConfigs.configs.push(textConfig);
                              $forceUpdate();
                            }">新增文本配置</el-button>

                          </div>
                        </el-col>
                        <el-col>
                          <el-button :disabled="t.moduleConfigs.length < 2" type="text" style="border: 0px;" @click="() => {
                            t.moduleConfigs.splice(mIndex, 1);
                            $forceUpdate();
                          }">删除模块配置</el-button>
                            <el-button type="text" style="border: 0px;" @click="() => {
                              let tmp = deepClone(defaultModuleConfig);
                              tmp.id = uuidv4().replaceAll('-', '');
                              t.moduleConfigs.push(tmp);
                              $forceUpdate();
                            }">新增模块配置</el-button>
                            <el-button :disabled="mIndex===0" type="text" style="border: 0px;" @click="() => {
                                    t.moduleConfigs.splice(mIndex, 1)
                                    t.moduleConfigs.splice(mIndex - 1, 0, item)
                                  }">模块配置上移</el-button>
                            <el-button :disabled="mIndex===t.moduleConfigs.length-1" type="text" style="border: 0px;" @click="() => {
                                    t.moduleConfigs.splice(mIndex, 1)
                                    t.moduleConfigs.splice(mIndex + 1, 0, item)
                                  }">模块配置下移</el-button>
                        </el-col>
                      </el-row>
                    </el-col>
                    <el-col>
                      <el-button :disabled="saveDialog.form.taskConfigs.length < 2" type="text" style="border: 0px;" @click="() => {
                        saveDialog.form.taskConfigs.splice(index, 1);
                        $forceUpdate();
                      }">删除任务配置</el-button>
                      <el-button type="text" style="border: 0px;" @click="() => {
                        const config =deepClone(defaultTaskConfig);
                        config.moduleConfigs.forEach(e => e.id=uuidv4().replaceAll('-', ''));
                        saveDialog.form.taskConfigs.push(config);
                        $forceUpdate();
                      }">新增任务配置</el-button>
                      <el-button type="text" style="border: 0px;" @click="() => {
                        let obj = deepClone(saveDialog.form.taskConfigs[index]);
                        obj.boostId = undefined;
                        obj.moduleConfigs.forEach(e => {
                          e.id=uuidv4().replaceAll('-', '');
                          e.photoConfigs.forEach(p => {
                            p.id=uuidv4().replaceAll('-', '');
                          })
                        });
                        saveDialog.form.taskConfigs.push(obj);
                        $forceUpdate();
                      }">复制并新增任务配置</el-button>
                      <el-button :disabled="index===0" type="text" style="border: 0px;" @click="() => {
                                    saveDialog.form.taskConfigs.splice(index, 1)
                                    saveDialog.form.taskConfigs.splice(index - 1, 0, t)
                                  }">任务配置上移</el-button>
                      <el-button :disabled="index===saveDialog.form.taskConfigs.length-1" type="text" style="border: 0px;" @click="() => {
                                    saveDialog.form.taskConfigs.splice(index, 1)
                                    saveDialog.form.taskConfigs.splice(index + 1, 0, t)
                                  }">任务配置下移</el-button>
                    </el-col>
                  </el-row>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col>
              <div>
                <label>核销-文本项配置</label>
              </div>
              <div>
                <el-form-item label="配置名">
                  <el-input v-model="saveDialog.form.textConfigs.name" clearable placeholder="如：道具上柜" />
                </el-form-item>
                <el-form-item label="配置备注">
                  <el-input v-model="saveDialog.form.textConfigs.remark" clearable placeholder="如：选填" />
                </el-form-item>
                <el-row v-for="(ele, i) in saveDialog.form.textConfigs.configs" :key="i">
                  <el-form-item label="描述">
                    <el-input v-model="ele.desc" clearable />
                  </el-form-item>
                  <el-form-item label="类型">
                    <el-radio-group v-model="ele.type">
                      <el-radio label="text">文本</el-radio><el-radio label="number">数字</el-radio><el-radio label="select">选项</el-radio>
                    </el-radio-group>
                    <div v-if="ele.type === 'select'" style="display: inline-block;margin-left: 10px">
                      <label>选项类型</label>
                      <el-select v-model="ele.chooseType">
                        <el-option label="单选" value="single" /> <el-option label="多选" value="multiple" />
                      </el-select>
                      <label>选项数据</label>
                      <el-select v-model="ele.chooseText" filterable allow-create multiple placeholder="请输入后手动选择即可">
                      </el-select>
                    </div>
                  </el-form-item>
                  <el-form-item>
                    <el-button type="text" style="border: 0px;" @click="() => {
                              saveDialog.form.textConfigs.configs.splice(i, 1);
                              $forceUpdate();
                            }">删除</el-button>
                  </el-form-item>
                </el-row>
                <el-button type="text" style="border: 0px;" @click="() => {
                  let textConfig = deepClone(saveDialog.defaultTextConfig);
                  textConfig.id = uuidv4().replaceAll('-', '');
                  saveDialog.form.textConfigs.configs.push(textConfig);
                  $forceUpdate();
                }">新增文本配置</el-button>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-form>
      <div class="dialog-footer" style="text-align: right;position: absolute; right: 50px;bottom: 50px;">
        <el-button type="primary" size="mini" :disabled="submitDisabled" @click="handleSubmit">提交</el-button>
        <el-button type="primary" size="mini" @click="saveDialog.visible = false">关闭</el-button>
      </div>
    </el-drawer>

    <el-dialog title="操作结果" :visible.sync="noticeDialog.visible" append-to-body width="700px">
      <div v-html="noticeDialog.msg" style="font-size: 16px">
      </div>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button type="primary" size="mini" @click="noticeDialog.visible = false">关闭</el-button>
      </div>
    </el-dialog>

    <JL v-if="currentBoost.show" :task-id="currentBoost.taskId" :task-info="currentBoost.taskInfo" :import-scope="currentBoost.importScope" component-type="HX" :component-boost-id="currentBoost.boostId"
        :modules="currentBoost.modules" :boost-info="currentBoost.boostInfo" @handleHxBoostChange="handleHxBoostChange" />
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import {  dictSelect,  pageVerificationConfs, saveVerificationConf, getTaskByOrg, pageTaskBoost, getVerificationConf } from "@/api/appTask";
import { Notification } from "element-ui";
import {deepClone} from "@/utils";
import {v4 as uuidv4} from "uuid";
import DictSelect from "@/components/DictSelect";
import SearchFilter from "@/components/SearchFilter/index";
import JL from "../../task/Jl"

export default {
  name: "VerificationConfig",
  components: { Pagination, DictSelect, JL , SearchFilter},
  data() {
    return {
      deepClone,
      uuidv4,
      pageData: {
        total: 0,
        rows: [],
        selections: [],
      },
      queryForm: {
        current: 1,
        size: 10,
        title: undefined,
        type: undefined,
      },
      loading: false,
      dictParam: {},
      authOrgAll: false,
      saveDialog: {
        visible: false,
        type: undefined,
        titleMap: {
          add: '新增核销',
          edit: '编辑核销',
        },
        form: {
          id: undefined,
          name: undefined,
          orgCode: undefined,
          takePhotos: 1,
          type: undefined,
          onlyAuditByOrg: 1,
          multiUpload: 0,
          taskConfigs: [
            {
              mtTaskId: undefined,
              boostId: undefined,
              verificationType: 'V3',
              moduleConfigs: [
                {
                  id: undefined,
                  name: undefined,
                  photoConfigs: [{
                    name: undefined,
                    remark: undefined,
                    photoMin: undefined,
                    photoMax: undefined,
                    desc: undefined,
                    include: ["image-camera", "image-gallery"],
                    showAfter: false,
                    photoMinAfter: undefined,
                    photoMaxAfter: undefined,
                    descAfter: undefined,
                    required: true,
                    includeAfter: ["image-camera", "image-gallery"],
                  }],
                  textConfigs: {
                    id: 1,
                    name: undefined,
                    remark: undefined,
                    configs: []
                  }
                }
              ],
            }
          ],
          textConfigs: {
            id:1,
            name: undefined,
            remark: undefined,
            configs: []
          },
          doorHeadPhotoV3: {
            show: true,
            photoMin: undefined,
            photoMax: undefined,
            include: ["image-camera", "image-gallery"],
          }
        },
        defaultTextConfig: {
          id: undefined,
          type: 'number',
          desc: undefined,
          chooseType: 'single',
          chooseText: []
        },
        defaultPhotoConfig: {
          name: undefined,
          remark: undefined,
          photoMin: undefined,
          photoMax: undefined,
          desc: undefined,
          include: ["image-camera", "image-gallery"],
          showAfter: false,
          photoMinAfter: undefined,
          photoMaxAfter: undefined,
          descAfter: undefined,
          includeAfter: ["image-camera", "image-gallery"],
          required: true,
        },
        rules: {
          name: [{ required: true, message: " ", trigger: "change" }],
        },
      },
      submitDisabled: false,
      authOrgOptions: [],
      authOrgCodes: [],
      taskPhotosOptions: [{ label: '拍照', value: 'image-camera'}, { label: '相册', value: 'image-gallery'}],
      noticeDialog: {
        visible: false,
        msg: undefined
      },
      relateTaskOptions: ['modules'],
      taskOptions: [],
      taskBoostOptions: {},
      currentBoost: {
        index: 0,
        show: false,
        taskId: undefined,
        boostId: undefined,
        importScope: undefined,
        taskInfo: undefined,
        boostInfo: {
        },
        modules: {
          id: undefined,
          name: undefined,
          moduleMoney: 0
        }
      }
    };
  },
  computed: {
    defaultModuleConfig() {
      return {
        id: undefined,
        name: undefined,
        photoConfigs: [this.saveDialog.defaultPhotoConfig],
        textConfigs: {
          name: undefined,
          remark: undefined,
          configs: []
        }
      }
    },
    defaultTaskConfig() {
      return {
        mtTaskId: undefined,
        boostId: undefined,
        moduleConfigs: [this.defaultModuleConfig]
      }
    }
  },
  watch: {
  },
  created() {
    dictSelect("ht-org-all").then(res => {
      this.authOrgCodes = res.data.map(e => e.value);
      this.authOrgOptions = res.data;
      if (res.data.filter(e => e.value === 'ALL').length > 0) {
        this.authOrgAll = true;
      }
      if (this.authOrgOptions.length === 1) {
        this.queryForm.orgCode = this.authOrgOptions[0].value;
      }
      this.handleFilter();
    });
    getTaskByOrg("?query=ALL").then(res => {
      this.taskOptions = res.data;
    })
  },
  mounted() {
  },
  methods: {
    handleQuery() {
      this.loading = true;
      const param = deepClone(this.queryForm);

      pageVerificationConfs(param).then(res => {
        if (!res.data) {
          return false;
        }
        this.loading = false;
        this.pageData.rows = res.data?.records;
        this.pageData.total = res.data?.total;
      })
    },
    handleFilter() {
      this.queryForm.page = 1;
      this.handleQuery();
    },
    resetQuery() {
      const queryForm = {
        current: 1,
        size: 10,
      };
      this.queryForm = Object.assign({}, queryForm);
      this.$forceUpdate();
    },
    handleAdd(type) {
      this.saveDialog.type = type;
      this.saveDialog.form = {
        id: undefined,
        name: undefined,
        orgCode: this.authOrgOptions[0].value,
        taskConfigs: [{
          mtTaskId: undefined,
          boostId: undefined,
          moduleConfigs: [
            {
              id: uuidv4().replaceAll("-", ""),
              name: undefined,
              photoConfigs: [{
                id: uuidv4().replaceAll("-", ""),
                name: undefined,
                remark: undefined,
                photoMin: undefined,
                photoMax: undefined,
                include: ["image-camera", "image-gallery"],
                desc: undefined,
                showAfter: false,
                photoMinAfter: undefined,
                photoMaxAfter: undefined,
                descAfter: undefined,
                includeAfter: ["image-camera", "image-gallery"],
                required: true
              }],
              textConfigs: {
                id: 1,
                name: undefined,
                remark: undefined,
                configs: []
              }
            }
          ],
        }],
        textConfigs: {
          id: 1,
          name: undefined,
          remark: undefined,
          configs: []
        },
        takePhotos: 1,
        type: undefined,
        onlyAuditByOrg: 1,
        doorHeadPhotoV3: {
          show: true,
          photoMin: undefined,
          photoMax: undefined,
          include: ["image-camera", "image-gallery"]
        },
        multiUpload: 0,
      };
      this.saveDialog.visible = true;
      this.submitDisabled = false;
      getTaskByOrg("?query=" + this.saveDialog.form.orgCode).then(res => {
        this.taskOptions = res.data;
      })
    },
    handleEdit(row) {
      getVerificationConf(row.id).then(res => {
        let form = deepClone(res.data);
        getTaskByOrg("?query=" + form.orgCode).then(res => {
          this.taskOptions = res.data;
        })
        if (form.configJson) {
          const configJson = JSON.parse(form.configJson);
          if (configJson.doorHeadPhotoV3) {
            form.doorHeadPhotoV3 = configJson.doorHeadPhotoV3;
          } else {
            form.doorHeadPhotoV3 = {
              show: true,
              photoMin: undefined,
              photoMax: undefined
            }
          }
          if (configJson.associationTask) {
            let taskConfigs = [];
            configJson.associationTask.forEach(e => {
              if (e.boosts) {
                e.boosts.forEach(b => {
                  taskConfigs.push({mtTaskId: e.mtTaskId, boostId: b.id, moduleConfigs: b.moduleConfigs, verificationType: e.verificationType? e.verificationType: 'V3'});
                })
                this.listTaskBoost(e.mtTaskId);
              }
            })
            form.taskConfigs = taskConfigs;
          } else {
            form.taskConfigs = this.defaultTaskConfig;
          }
          if (configJson.textConfigs) {
            form.textConfigs = configJson.textConfigs;
          } else {
            form.textConfigs = deepClone(this.saveDialog.defaultTextConfig)
          }
        }
        this.saveDialog.form = form;
        this.saveDialog.visible = true;
        this.submitDisabled = false;
      })
    },
    handleSubmit() {
      this.$refs.saveFormRef.validate(valid => {
        if (valid) {
          this.submitDisabled = true;
          let tmpForm = deepClone(this.saveDialog.form)
          let msg = this.verifyFormMsg(tmpForm);
          if (msg) {
            this.noticeDialog.msg =msg;
            this.noticeDialog.visible = true;
            this.submitDisabled = false;
            return;
          }

          saveVerificationConf(tmpForm).then(res => {
            this.submitDisabled = false;
            if (res.code===200) {
              Notification.success("操作成功！");
              this.saveDialog.visible = false;
              this.handleFilter();
              tmpForm.taskConfigs.forEach(e => {
                this.listTaskBoost(e.mtTaskId, true)
              })
            }
          })
        }
      })
    },
    verifyFormMsg(form) {
      let msg = '';
      if (form.doorHeadPhotoV3.show) {
        if (form.doorHeadPhotoV3.photoMin==undefined || !form.doorHeadPhotoV3.photoMax < 0) {
          msg+='门店头图照片数量范围不可为空;</br>'
        }
        if (!form.doorHeadPhotoV3.include || form.doorHeadPhotoV3.include.length == 0) {
          msg+='门店头图照片上传方式不可为空;</br>'
        }
      }
      let index =1;
      form.taskConfigs.forEach(e => {
        const taskName = "任务" + index;

        if (!e.mtTaskId) {
          msg+= taskName + "所选任务不能为空;</br>"
        }
        let mIndex =1;
        e.moduleConfigs.forEach(m => {
          let moduleName = taskName+ " 模块" + mIndex;
          if (!m.name) {
            msg+= moduleName + "模块名称不能为空;</br>"
          }
          let pIndex =1;
          m.photoConfigs.forEach(p => {
            const pPhotoName = "模块-照片配置" + pIndex;
            if (!p.name) {
              msg+=moduleName + "  " + pPhotoName +' 配置名称不可为空;</br>'
            }

            if (p.showAfter) {
              if (p.photoMin==undefined || !p.photoMax < 0) {
                msg+=moduleName + "  " + pPhotoName +' 打造前照片数量范围不可为空;</br>'
              } else if (!p.desc){
                p.desc = '打造前照片（'+ p.photoMin + '-' + p.photoMax + '张）';
              }
              if (!p.include || p.include.length == 0) {
                msg+=moduleName + "  " + pPhotoName +' 打造前照片上传方式不可为空;</br>'
              }
              if (p.photoMinAfter==undefined || !p.photoMaxAfter < 0) {
                msg+=moduleName + "  " + pPhotoName +'打造后照片数量范围不可为空;</br>'
              } else if (!p.descAfter) {
                p.descAfter = '打造后照片（'+ p.photoMinAfter + '-' + p.photoMaxAfter + '张）';
              }
              if (!p.includeAfter || p.includeAfter.length == 0) {
                msg+=moduleName + "  " + pPhotoName +' 打造后照片上传方式不可为空;</br>'
              }
            } else {
              if (p.photoMin==undefined || !p.photoMax < 0) {
                msg+=moduleName + "  " + pPhotoName +' 照片数量范围不可为空;</br>'
              } else if (!p.desc)  {
                p.desc = '上传照片（'+ p.photoMin + '-' + p.photoMax + '张）';
              }
              if (!p.include || p.include.length == 0) {
                msg+=moduleName + "  " + pPhotoName +' 照片上传方式不可为空;</br>'
              }
            }
            pIndex++;
          })
          let tIndex =1;
          if (!m.textConfigs.name && m.textConfigs.configs.length > 0) {
            msg+=moduleName + "  " +' 模块-文本配置 配置名称不可为空;</br>'
          }
          m.textConfigs.configs.forEach(t => {
            const pTextName = "模块-文本项配置" + tIndex;

            if (!t.desc) {
              msg+=moduleName + "  " + pTextName +' 描述不可为空;</br>'
            }
            if (t.type === 'select' && t.chooseText && t.chooseText.length <1) {
              msg+=moduleName + "  " + pTextName +' 选项数据不可为空;</br>'
            }
            t.id = tIndex;
            tIndex++;
          })
          mIndex++;
        })
        index++;
      })
      let tIndex =1;
      if (!form.textConfigs.name && form.textConfigs.configs.length > 0) {
        msg+=' 核销-文本配置 配置名称不可为空;</br>'
      }
      form.textConfigs.configs.forEach(t => {
        const pTextName = "核销-文本项配置" + tIndex;
        if (!t.desc) {
          msg+=pTextName +' 描述不可为空;</br>'
        }
        if (t.type === 'select' && t.chooseText && t.chooseText.length <1) {
          msg+=pTextName +' 选项数据不可为空;</br>'
        }
        t.id = tIndex;
        tIndex++;
      })
      return msg;
    },
    transferDataToJson(form) {
      let associationTask = [];
      let updateModules = [];
      form.taskConfigs.forEach(e => {
        let taskObj = associationTask.find(t => t.mtTaskId === e.mtTaskId);
        if (!taskObj) {
          taskObj = {
            mtTaskId: e.mtTaskId,
            boosts: []
          }
        }
        let boostObj = taskObj.boosts.find(t => t.boostId === e.boostId);
        if (!boostObj) {
          boostObj = {
            id: e.boostId,
            modules: [],
          }
        }
        e.moduleConfigs.forEach(m => {
          const moduleObj = {
            id: m.id,
            name: m.name,
            photoConfig: m.photoConfigs,
            textConfigs: m.textConfigs
          }
          updateModules.push({
            id:moduleObj.id,
            name: moduleObj.name,
            boostId: e.boostId,
          });
          boostObj.modules.push(moduleObj);
          taskObj.boosts.push(boostObj);
          associationTask.push(taskObj);
        })
      })
    },
    getOrgInfo(orgCode) {
      const org = this.authOrgOptions.find(e => e.value === orgCode);
      if (org) {
        return org.value + '-' + org.label;
      } else {
        return '';
      }
    },
    listTaskBoost(taskId, force) {
      if (!this.taskBoostOptions[taskId] || force) {
        this.taskBoostOptions[taskId] = []
        pageTaskBoost({mtTaskId: taskId, size: 20, bonusTarget: 'salesman'}).then(res => {
          res.data.records.forEach(e => {
            e.id=e.id+'';
          })
          this.taskBoostOptions[taskId] = res.data.records;
          this.$forceUpdate();
        })
      }
    },
    handleOrgChange(val) {
      this.saveDialog.form.taskConfigs.forEach(e => {
        e.mtTaskId = undefined;
        e.boostId = undefined;
      })
      getTaskByOrg("?query=" + val).then(res => {
        this.taskOptions = res.data;
      })
    },
    handleEditBoost(item, index) {
      const modules = [];
      item.moduleConfigs.forEach(e => {
        modules.push({ id: e.id, name: e.name })
      })
      if (modules.filter(e => !e.name).length > 0) {
        Notification.warning("有模块名称未填写，请填写后再进行操作！");
        return
      }
      const taskInfo = this.taskOptions.find(e => e.mtTaskId == item.mtTaskId);
      console.log("taskInfo:" + taskInfo)
      this.currentBoost = {
        show: true,
        index: index,
        taskId: item.mtTaskId,
        boostId: item.boostId,
        importScope: taskInfo? taskInfo.importScope: 0,
        taskInfo: taskInfo,
        boostInfo: item.boostInfo,
        modules: item.moduleConfigs.map(e => e)
      }
    },
    handleHxBoostChange(val) {
      if (val) {
        this.saveDialog.form.taskConfigs[this.currentBoost.index].boostInfo = val;
      }
      this.currentBoost.show = false;
    },
    handleMove(type, index) {
      const item = this.sortDialog.sorts[index];
      if (type === "up") {
        this.sortDialog.sorts.splice(index, 1)
        this.sortDialog.sorts.splice(index - 1, 0, item)
      } else if (type === "down") {
        this.sortDialog.sorts.splice(index, 1)
        this.sortDialog.sorts.splice(index + 1, 0, item)
      } else if (type === "remove") {
        this.sortDialog.sorts.splice(index, 1)
        this.sortDialog.removeSorts.push(item.label);
      }
    },
  }
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding-top: 10px;
  padding-bottom: 5px;
}

.common-form {
  &-row {
    display: flex !important;
    flex-wrap: wrap;
  }
  div .el-form-item {
    display: flex !important;
    ::v-deep {
      .el-form-item__content {
        width: 100%;
        .el-input {
          width: 100%;
        }
        .el-input-number {
          width: 100%;
        }
        .el-select {
          width: 100%;
        }
        .el-date-editor {
          width: 100%;
        }
      }
    }
  }
}

.label-class {
  font-weight: 600;
  font-size: 15px;
  color: #ac7017;
}
::v-deep{
  .el-upload--picture-card {
    height:0px;
    width: 0px;
    .el-button{
      display: flex;
      margin: 3px auto;
    }
  }
  .el-upload-list__item {
    height:30px;
    width: 60px;
  }
  .el-checkbox__label {
    padding-left: 1px;
  }
}
</style>
