var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { padding: "20px" } },
    [
      _c(
        "div",
        { staticClass: "mg-search" },
        [
          _c(
            "el-card",
            { staticStyle: { margin: "10px" } },
            [
              _c(
                "SearchFilter",
                {
                  on: { search: _vm.handleFilter, reset: _vm.resetQuery },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.handleQuery.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm.options.orgOptions.length > 0
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "分公司" } },
                        [
                          _c("DictSelect", {
                            ref: "org",
                            attrs: {
                              type: "org",
                              value: _vm.queryForm.orgCode,
                              fullLabel: "",
                              "init-options": _vm.options.orgOptions,
                              init: "",
                              clearable: false,
                            },
                            on: {
                              "update:value": function ($event) {
                                return _vm.$set(
                                  _vm.queryForm,
                                  "orgCode",
                                  $event
                                )
                              },
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    { attrs: { label: "指标名称" } },
                    [
                      _c("el-input", {
                        attrs: { clearable: "" },
                        model: {
                          value: _vm.queryForm.quotaName,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryForm, "quotaName", $$v)
                          },
                          expression: "queryForm.quotaName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-card",
        { staticClass: "box-card", staticStyle: { margin: "10px" } },
        [
          _c(
            "div",
            { staticStyle: { margin: "0 0 10px 0" } },
            [
              _c(
                "el-button",
                {
                  staticStyle: { border: "0px" },
                  attrs: {
                    size: "mini",
                    type: "primary",
                    icon: "el-icon-plus",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.handleEditQuota()
                    },
                  },
                },
                [_vm._v("新增")]
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "table",
              attrs: {
                data: _vm.pageData.rows,
                height: "calc(100vh - 350px)",
                size: "mini",
                border: "",
                "highlight-current-row": "",
                "header-cell-style": {
                  background: "rgb(235 235 235)",
                  color: "black",
                  textAlign: "center",
                  verticalAlign: "middle",
                },
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "指标名称", prop: "quotaName", width: "200" },
              }),
              _c("el-table-column", {
                attrs: { label: "指标类型", prop: "nickName", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              row.quotaTypeCode === "CUSTOM" ? "自定义" : "通用"
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "APP显示模板", prop: "quotaTpl", width: "300" },
              }),
              _c("el-table-column", {
                attrs: { label: "单位", prop: "unit", width: "80" },
              }),
              _c("el-table-column", {
                attrs: { label: "备注", prop: "remark", width: "400" },
              }),
              _c("el-table-column", {
                attrs: { label: "操作", width: "120", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function ({ row }) {
                      return [
                        _c(
                          "el-button",
                          {
                            staticStyle: { border: "0px" },
                            attrs: {
                              disabled: row.quotaTypeCode !== "CUSTOM",
                              size: "mini",
                              type: "text",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleEditQuota(row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _c(
                          "el-button",
                          {
                            staticStyle: { border: "0px" },
                            attrs: {
                              disabled: row.quotaTypeCode !== "CUSTOM",
                              size: "mini",
                              type: "text",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleRemoveQuota(row)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("Pagination", {
            attrs: {
              total: _vm.pageData.total,
              page: _vm.queryForm.current,
              limit: _vm.queryForm.size,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.queryForm, "current", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.queryForm, "size", $event)
              },
              pagination: _vm.handleQuery,
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title:
              _vm.saveDialog.type === "add" ? "新增指标配置" : "编辑指标配置",
            visible: _vm.saveDialog.visible,
            width: "500px",
            "append-to-body": "",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.saveDialog, "visible", $event)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "saveForm",
              attrs: {
                model: _vm.saveDialog.form,
                inline: true,
                rules: _vm.saveDialog.rules,
                size: "mini",
                "label-width": "120px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "分公司", prop: "orgCode" } },
                [
                  _c("DictSelect", {
                    ref: "org",
                    staticStyle: { width: "200px" },
                    attrs: {
                      type: "org",
                      value: _vm.saveDialog.form.orgCode,
                      fullLabel: "",
                      "init-options": _vm.options.orgOptions,
                      init: "",
                      clearable: false,
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(_vm.saveDialog.form, "orgCode", $event)
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "指标名称", prop: "quotaName" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: { clearable: "" },
                    model: {
                      value: _vm.saveDialog.form.quotaName,
                      callback: function ($$v) {
                        _vm.$set(_vm.saveDialog.form, "quotaName", $$v)
                      },
                      expression: "saveDialog.form.quotaName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "单位", prop: "unit" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: {
                      placeholder: "如: 个/家/元/万元等",
                      clearable: "",
                    },
                    model: {
                      value: _vm.saveDialog.form.unit,
                      callback: function ($$v) {
                        _vm.$set(_vm.saveDialog.form, "unit", $$v)
                      },
                      expression: "saveDialog.form.unit",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "APP显示模板", prop: "quotaTpl" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: {
                      placeholder:
                        "如: 签到家数${param}家, 只可填写一个动态参数",
                      clearable: "",
                    },
                    model: {
                      value: _vm.saveDialog.form.quotaTpl,
                      callback: function ($$v) {
                        _vm.$set(_vm.saveDialog.form, "quotaTpl", $$v)
                      },
                      expression: "saveDialog.form.quotaTpl",
                    },
                  }),
                  _c(
                    "el-button",
                    {
                      staticStyle: { border: "0px" },
                      attrs: {
                        disabled:
                          _vm.saveDialog.form.quotaTpl.indexOf("${param}") > -1,
                        size: "mini",
                        type: "text",
                      },
                      on: {
                        click: () => {
                          _vm.saveDialog.form.quotaTpl += "${param}"
                        },
                      },
                    },
                    [_vm._v("添加参数")]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "备注", prop: "remark" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: { type: "textarea", clearable: "" },
                    model: {
                      value: _vm.saveDialog.form.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.saveDialog.form, "remark", $$v)
                      },
                      expression: "saveDialog.form.remark",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.saveDialog.visible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: { click: _vm.submitForm },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }