var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { padding: "20px" } },
    [
      _c(
        "div",
        { staticClass: "mg-search", staticStyle: { "padding-bottom": "15px" } },
        [
          _c(
            "el-form",
            {
              ref: "queryFormRef",
              attrs: {
                size: "mini",
                "label-width": "auto",
                inline: true,
                model: _vm.queryForm,
              },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.handleFilter.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "分公司" } },
                [
                  _c("DictSelect", {
                    ref: "org",
                    attrs: {
                      type: "org",
                      value: _vm.queryForm.orgCode,
                      linkageRef: "dc,shop",
                      dictFun: _vm.dictFun,
                      fullLabel: "",
                      init: true,
                      "init-options": _vm.orgDictInitOptions,
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(_vm.queryForm, "orgCode", $event)
                      },
                      changeLinkageRef: _vm.handleChangeLinkageRef,
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "配送中心" } },
                [
                  _c("DictSelect", {
                    ref: "dc",
                    attrs: {
                      type: "dc",
                      value: _vm.queryForm.dcCodes,
                      dictFun: _vm.dictFun,
                      parentLinkageRef: "",
                      fullLabel: "",
                      multiple: "",
                      "collapse-tags": "",
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(_vm.queryForm, "dcCodes", $event)
                      },
                      getOtherJsonParam: (callback) => callback(_vm.dictParam),
                      change: function ($event) {
                        _vm.dictParam = Object.assign({}, _vm.queryForm)
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "店铺" } },
                [
                  _c("DictSelect", {
                    ref: "shop",
                    attrs: {
                      type: "shop",
                      value: _vm.queryForm.shopCode,
                      dictFun: _vm.dictFun,
                      parentLinkageRef: "",
                      fullLabel: "",
                      remote: "",
                      "init-props": { label: "label", value: "fullCode" },
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(_vm.queryForm, "shopCode", $event)
                      },
                      getOtherJsonParam: (callback) =>
                        callback({
                          orgCode: _vm.queryForm.orgCode,
                          dcCode: _vm.queryForm.dcCodes,
                        }),
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "form-btn-item",
                      attrs: {
                        size: "mini",
                        icon: "el-icon-search",
                        type: "primary",
                      },
                      on: { click: _vm.handleFilter },
                    },
                    [_vm._v("查询")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: {
            data: _vm.pageData.rows,
            height: "calc(100vh - 150px)",
            size: "mini",
            border: "",
            "highlight-current-row": "",
            "header-cell-style": {
              background: "rgb(227 224 224)",
              color: "black",
            },
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "分公司名称", prop: "orgName", width: "200" },
          }),
          _c("el-table-column", {
            attrs: { label: "配送中心名称", prop: "dcName", width: "200" },
          }),
          _c("el-table-column", {
            attrs: { label: "终端编码", prop: "shopCode", width: "60" },
          }),
          _c("el-table-column", {
            attrs: { label: "终端名称", prop: "shopName", width: "200" },
          }),
          _c("el-table-column", {
            attrs: { label: "系统名称", prop: "systemName", width: "120" },
          }),
          _c("el-table-column", {
            attrs: {
              label: "系统中编码(多个编码，使用，隔开)",
              prop: "fillField",
              width: "260",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.$index !== _vm.currentIndex
                      ? _c("span", [_vm._v(_vm._s(scope.row.fillField))])
                      : _c("el-input", {
                          attrs: { size: "mini", clearable: "" },
                          model: {
                            value: _vm.currentRow.fillField,
                            callback: function ($$v) {
                              _vm.$set(_vm.currentRow, "fillField", $$v)
                            },
                            expression: "currentRow.fillField",
                          },
                        }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "匹配状态", prop: "matchStatus", width: "200" },
          }),
          _c("el-table-column", {
            attrs: { label: "操作", width: "120", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.$index !== _vm.currentIndex
                      ? _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-button",
                              {
                                staticStyle: { border: "0px" },
                                attrs: {
                                  size: "mini",
                                  type: "text",
                                  icon: "el-icon-edit",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDcExternalShopConfigEdit(
                                      scope.row,
                                      scope.$index
                                    )
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    scope.$index == _vm.currentIndex
                      ? _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-button",
                              {
                                staticStyle: { border: "0px" },
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: () => {
                                    _vm.currentRow = undefined
                                    _vm.currentIndex = undefined
                                  },
                                },
                              },
                              [_vm._v("取消")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    scope.$index == _vm.currentIndex
                      ? _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-button",
                              {
                                staticStyle: {
                                  border: "0px",
                                  "margin-left": "50%",
                                },
                                attrs: { size: "mini", type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.submitDcExternalShopConfigEdit(
                                      _vm.currentRow
                                    )
                                  },
                                },
                              },
                              [_vm._v("保存")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("Pagination", {
        attrs: {
          total: _vm.pageData.total,
          page: _vm.queryForm.current,
          limit: _vm.queryForm.size,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.queryForm, "current", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.queryForm, "size", $event)
          },
          pagination: _vm.handleQuery,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }