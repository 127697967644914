var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { padding: "20px" } },
    [
      _c(
        "div",
        { staticClass: "mg-search", staticStyle: { "padding-bottom": "15px" } },
        [
          _c(
            "el-form",
            {
              ref: "queryFormRef",
              attrs: {
                size: "mini",
                "label-width": "auto",
                inline: true,
                model: _vm.queryForm,
              },
              nativeOn: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.handleQuery.apply(null, arguments)
                },
              },
            },
            [
              _vm.options.orgOptions.length > 0
                ? _c(
                    "el-form-item",
                    { attrs: { label: "分公司" } },
                    [
                      _c("DictSelect", {
                        ref: "org",
                        attrs: {
                          type: "org",
                          value: _vm.queryForm.orgCode,
                          fullLabel: "",
                          "init-options": _vm.options.orgOptions,
                          init: "",
                          clearable: false,
                          disabled: "",
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(_vm.queryForm, "orgCode", $event)
                          },
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "类型" } },
                [
                  _c("DictSelect", {
                    ref: "org",
                    attrs: {
                      type: "org",
                      value: _vm.queryForm.tagId,
                      "init-options": _vm.options.tags,
                      init: "",
                      "init-props": { value: "id", label: "tagName" },
                      clearable: false,
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(_vm.queryForm, "tagId", $event)
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    attrs: {
                      size: "mini",
                      clearable: "",
                      placeholder: "请输入关键词",
                    },
                    model: {
                      value: _vm.queryForm.userName,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "userName", $$v)
                      },
                      expression: "queryForm.userName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      staticClass: "form-btn-item",
                      attrs: {
                        size: "mini",
                        icon: "el-icon-search",
                        type: "primary",
                      },
                      on: { click: _vm.handleQuery },
                    },
                    [_vm._v("查询")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticStyle: { border: "0px" },
                      attrs: {
                        size: "mini",
                        type: "primary",
                        icon: "el-icon-plus",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.handleEditUserTag()
                        },
                      },
                    },
                    [_vm._v("新增")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "form-btn-item",
                      attrs: {
                        size: "mini",
                        icon: "el-icon-upload",
                        type: "primary",
                      },
                      on: {
                        click: () => {
                          _vm.importDialog.visible = true
                          _vm.submitDisabled = false
                        },
                      },
                    },
                    [_vm._v("导入")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticStyle: { border: "0px" },
                      attrs: { size: "mini", type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.handleEditTag()
                        },
                      },
                    },
                    [_vm._v("类型配置")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "table",
          attrs: {
            data: _vm.pageData.rows,
            height: "calc(100vh - 350px)",
            size: "mini",
            border: "",
            "highlight-current-row": "",
            "header-cell-style": {
              background: "rgb(235 235 235)",
              color: "black",
              textAlign: "center",
              verticalAlign: "middle",
            },
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "用户账号", prop: "userName", width: "100" },
          }),
          _c("el-table-column", {
            attrs: { label: "用户姓名", prop: "nickName", width: "100" },
          }),
          _c("el-table-column", {
            attrs: {
              label: "配送中心",
              prop: "dcNames",
              width: "280",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "类型",
              prop: "tagNames",
              width: "280",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              label: "备注",
              prop: "remark",
              width: "280",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: { label: "操作", width: "120", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function ({ row }) {
                  return [
                    _c(
                      "el-button",
                      {
                        staticStyle: { border: "0px" },
                        attrs: { size: "mini", type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handleEditUserTag(row)
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticStyle: { border: "0px" },
                        attrs: { size: "mini", type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handleRemoveUserTag(row)
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("Pagination", {
        attrs: {
          total: _vm.pageData.total,
          page: _vm.queryForm.current,
          limit: _vm.queryForm.size,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.queryForm, "current", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.queryForm, "size", $event)
          },
          pagination: _vm.handleQuery,
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "人员配置导入",
            visible: _vm.importDialog.visible,
            "append-to-body": "",
            width: "400px",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.importDialog, "visible", $event)
            },
          },
        },
        [
          _c(
            "el-upload",
            {
              ref: "goodsUpload",
              staticClass: "upload-demo",
              attrs: {
                drag: "",
                limit: 1,
                action: "#",
                "auto-upload": false,
                "file-list": _vm.importDialog.fileList,
                "on-change": (file, fileList) =>
                  _vm.handleFileChange(file, fileList, "change"),
                "on-remove": (file, fileList) =>
                  _vm.handleFileChange(file, fileList, "remove"),
              },
            },
            [
              _c("i", { staticClass: "el-icon-upload" }),
              _c("div", { staticClass: "el-upload__text" }, [
                _vm._v("将文件拖到此处，或"),
                _c("em", [_vm._v("点击上传")]),
              ]),
              _c(
                "div",
                {
                  staticClass: "el-upload__tip",
                  attrs: { slot: "tip" },
                  slot: "tip",
                },
                [
                  _c("span", [_vm._v("仅允许导入xls、xlsx格式文件。")]),
                  _c(
                    "el-link",
                    {
                      staticStyle: {
                        "font-size": "12px",
                        "vertical-align": "baseline",
                      },
                      attrs: {
                        type: "primary",
                        underline: false,
                        target: "_blank",
                        href: _vm.importDialog.template,
                      },
                    },
                    [_vm._v("下载模板")]
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "mini",
                    disabled: _vm.submitDisabled,
                  },
                  on: { click: _vm.submitImport },
                },
                [_vm._v("确定")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.importDialog.visible = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "编辑类型",
            visible: _vm.tagDialog.visible,
            "append-to-body": "",
            width: "600px",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.tagDialog, "visible", $event)
            },
          },
        },
        [
          _vm._l(_vm.tagDialog.tags, function (item, index) {
            return _c(
              "el-row",
              { key: index },
              [
                _vm.tagDialog.currentTag === item.id
                  ? _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c("el-input", {
                          attrs: {
                            size: "mini",
                            clearable: "",
                            placeholder: "请输入类型名称",
                          },
                          model: {
                            value: item.tagName,
                            callback: function ($$v) {
                              _vm.$set(item, "tagName", $$v)
                            },
                            expression: "item.tagName",
                          },
                        }),
                      ],
                      1
                    )
                  : _c("el-col", { attrs: { span: 8 } }, [
                      _vm._v(_vm._s(item.tagName)),
                    ]),
                _c(
                  "el-col",
                  { attrs: { span: 8 } },
                  [
                    _vm.tagDialog.currentTag !== item.id
                      ? _c(
                          "el-button",
                          {
                            staticStyle: { border: "0px" },
                            attrs: { size: "mini", type: "text" },
                            on: {
                              click: () => {
                                _vm.tagDialog.currentTag = item.id
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        )
                      : _vm._e(),
                    _vm.tagDialog.currentTag === item.id
                      ? _c(
                          "el-button",
                          {
                            staticStyle: { border: "0px" },
                            attrs: { size: "mini", type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.handleSaveTag(item)
                              },
                            },
                          },
                          [_vm._v("保存")]
                        )
                      : _vm._e(),
                    _vm.tagDialog.currentTag === item.id
                      ? _c(
                          "el-button",
                          {
                            staticStyle: { border: "0px" },
                            attrs: { size: "mini", type: "text" },
                            on: {
                              click: function ($event) {
                                _vm.tagDialog.currentTag = -1
                              },
                            },
                          },
                          [_vm._v("取消")]
                        )
                      : _vm._e(),
                    _c(
                      "el-button",
                      {
                        staticStyle: { border: "0px" },
                        attrs: { size: "mini", type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.handleRemoveTag(item, index)
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          }),
          _c(
            "el-row",
            [
              _c(
                "el-button",
                {
                  staticStyle: { border: "0px" },
                  attrs: { size: "mini", type: "text" },
                  on: {
                    click: function ($event) {
                      return _vm.handleAddTag()
                    },
                  },
                },
                [_vm._v("新增")]
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.tagDialog.visible = false
                    },
                  },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "编辑人员配置",
            visible: _vm.userTagDialog.visible,
            width: "400px",
            "append-to-body": "",
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.userTagDialog, "visible", $event)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "userTagForm",
              attrs: {
                model: _vm.userTagDialog.form,
                inline: true,
                rules: _vm.userTagDialog.rules,
                "label-width": "120px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "用户", prop: "userName" } },
                [
                  !_vm.userTagDialog.form.id
                    ? _c("DictSelect", {
                        ref: "user",
                        attrs: {
                          type: "user",
                          value: _vm.userTagDialog.form.userName,
                          dictFun: _vm.dictUserFun,
                          fullLabel: "",
                          remote: "",
                          version: "v3",
                          placeholder: "请输入账号或姓名",
                          "init-props": {
                            label: "nickName",
                            value: "userName",
                          },
                          size: "mini",
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(
                              _vm.userTagDialog.form,
                              "userName",
                              $event
                            )
                          },
                          getOtherJsonParam: (callback) =>
                            callback({ pageSize: 50, pageNum: 1 }),
                        },
                      })
                    : _c("span", [
                        _vm._v(
                          _vm._s(_vm.userTagDialog.form.userName) +
                            " - " +
                            _vm._s(_vm.userTagDialog.form.nickName)
                        ),
                      ]),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "管辖配送中心" } },
                [
                  _c("DictSelect", {
                    ref: "org",
                    attrs: {
                      type: "dc",
                      value: _vm.userTagDialog.form.dcCodes,
                      "init-options": _vm.options.dcOptions,
                      fullLabel: "",
                      init: "",
                      size: "mini",
                      multiple: "",
                      clearable: false,
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(
                          _vm.userTagDialog.form,
                          "dcCodes",
                          $event
                        )
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "类型", prop: "tagIds", size: "mini" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { clearable: "", filterable: "", multiple: "" },
                      model: {
                        value: _vm.userTagDialog.form.tagIds,
                        callback: function ($$v) {
                          _vm.$set(_vm.userTagDialog.form, "tagIds", $$v)
                        },
                        expression: "userTagDialog.form.tagIds",
                      },
                    },
                    _vm._l(_vm.options.tags, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.tagName, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "备注", prop: "remark", size: "mini" } },
                [
                  _c("el-input", {
                    attrs: { clearable: "", type: "textarea" },
                    model: {
                      value: _vm.userTagDialog.form.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.userTagDialog.form, "remark", $$v)
                      },
                      expression: "userTagDialog.form.remark",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.userTagDialog.visible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: { click: _vm.submitUserForm },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }