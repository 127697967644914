<template>
    <div>
        <el-card style="margin: 10px;">
        <el-tabs v-model="activeName" type="card"  @tab-click="newRaceNames=''; time = ''">
            <el-tab-pane label="赛道维度" name="track">
                <trackView v-if="activeName == 'track'" @callbackNameType="callbackNameType"></trackView>
            </el-tab-pane>
            <el-tab-pane label="中类维度" name="mediumClass">
                <mediumClass v-if="activeName == 'mediumClass'" :propName="newRaceNames" :time="time" @callbackNameType="callbackNameType"></mediumClass>
            </el-tab-pane>
            <el-tab-pane label="小类维度" name="subCategories">
                <subCategories v-if="activeName == 'subCategories'" :propName="newRaceNames"  :time="time" @callbackNameType="callbackNameType"></subCategories>
            </el-tab-pane>
            <el-tab-pane label="单品维度" name="singles">
                <singles v-if="activeName =='singles'" :propName="newRaceNames"  :time="time"></singles>
            </el-tab-pane>
        </el-tabs>
    </el-card>
    </div>
</template>

<script>
import singles from './singles.vue';
import trackView from './track.vue';
import mediumClass from './mediumClass.vue';
import subCategories from './subCategories.vue';
export default {
    name: "reportingRequirements",
    components: {singles, trackView, mediumClass, subCategories},
    data() {
        return {
            activeName: "track",
            newRaceNames: "",
            time: ""
        }
    },
    methods:{
        callbackNameType(name,type, time){
            this.newRaceNames = name
            this.activeName = type
            this.time = time
        },
    }
}
</script>

<style lang="scss" scoped>
:deep(.el-table .cell){
    overflow: visible !important;
}
</style>