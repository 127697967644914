import { axiosGet, axiosPost, axiosPut, axiosDelete, axiosDownload } from "@/utils/request";

// -- 电商科技 授权
export function pageTechData(data) {
  return axiosPost("/e-commerce/tech/page", data, 1);
}

export function getTechBaseOptions() {
  return axiosGet("/e-commerce/tech/options", {}, 1);
}

export function saveTechData(data) {
  return axiosPost("/e-commerce/tech", data, 1);
}

export function importTechData(data) {
  return axiosPost("/e-commerce/tech/import", data, 1);
}
export function importTechTmData(data) {
  return axiosPost("/e-commerce/tech/import-tm", data, 1);
}
export function importTechShopData(data) {
  return axiosPost("/e-commerce/tech/import-shop", data, 1);
}

export function exportTechData(type, data) {
  return axiosDownload("/e-commerce/tech/export/" + type, data, 1);
}

export function deleteTechData(data) {
  return axiosDelete("/e-commerce/tech?codes=" + data, {}, 1);
}
export function pageActionLog(data) {
  return axiosPost("/actionLog/page", data, 1);
}
export function getCodeCount(data) {
  return axiosGet("/e-commerce/tech/count?code=" + data, {}, 1);
}
export function getDetailTech(data) {
  return axiosGet("/e-commerce/tech/" + data, {}, 1);
}
export function auditTechTm(data) {
  return axiosPost("/e-commerce/tech/audit", data, 1);
}
export function getTechDict(type, data) {
  return axiosPost("/e-commerce/tech/dict/" + type, data, 1);
}
export function getCurrentUser() {
  return axiosGet("/e-commerce/tech/user", {}, 1);
}
// ---------------  京东补货 ---------------
export function pageJdBhData(data) {
  return axiosPost("/jdbh/page", data, 1);
}
export function initJdBhData(data) {
  return axiosPost("/jdbh/init", data, 1);
}
export function saveJdBhData(data) {
  return axiosPost("/jdbh/batch", data, 1);
}
export function exportJdBhData(data) {
  return axiosDownload("/jdbh/export", data, 1);
}
export function exportJdBhDsData(data) {
  return axiosDownload("/jdbh/export-ds", data, 1);
}
export function getCategories(data) {
  return axiosGet("/jdbh/list/category", data, 1);
}
export function getUpdateDate(data) {
  return axiosGet("/jdbh/date", data, 1);
}
export function syncWholeToAll() {
  return axiosPost("/jdbh/sync-whole-all", {}, 1);
}

// ---------------  科技催货 ---------------
export function pageHasten(data) {
  return axiosPost("/ec-hasten/page", data, 1);
}
export function getBaseOptions() {
  return axiosGet("/ec-hasten/base-options", {}, 1);
}
export function getCategoryOptions(type, data) {
  return axiosGet("/ec-hasten/options/" + type, data, 1);
}
export function importHasten(data) {
  return axiosPost("/ec-hasten/import", data, 1);
}
export function confirmHandleHasten(data) {
  return axiosPost("/ec-hasten/confirm-handle", data, 1);
}
export function batchHandleHasten(data) {
  return axiosPost("/ec-hasten/batch-handle", data, 1);
}
export function saveSingle(data) {
  return axiosPost("/ec-hasten/save", data, 1);
}
export function exportHasten(data) {
  return axiosDownload("/ec-hasten/export", data, 1);
}
export function exportCompleteHasten(data) {
  return axiosDownload("/ec-hasten/export-complete", data, 1);
}
export function checkData(data) {
  return axiosPost("/ec-hasten/check", data, 1);
}
export function exportCurrent(data) {
  return axiosDownload("/ec-hasten/export-current", data, 1);
}
export function runDayData(data) {
  return axiosPost("/ec-hasten/run", data, 1);
}
export function resetData(qdName) {
  return axiosPost("/ec-hasten/reset/" + qdName, {}, 1);
}

// -------------------科技天眼-------------------
export function pageTechMatch(data) {
  return axiosPost("/technology-match/page/list", data, 1);
}
export function getTechMatchOptions() {
  return axiosGet("/technology-match/select-merge", {}, 1);
}
export function getTechMatchShopNameList(type, data) {
  return axiosPost("/technology-match/list/" + type, data, 1);
}
export function getTechMatchGoodsList(type, data) {
  return axiosPost("/technology-match/list/" + type, data, 1);
}
export function confirmChange(data) {
  return axiosPost("/technology-match/change_result", data, 1);
}
export function getTechMatchCountData(data) {
  return axiosGet("/technology-match/sku_data_statistics", data, 1);
}
export function updateTechMatch(data) {
  return axiosPost("/technology-match/update", data, 1);
}
export function exportTechMatch(data) {
  return axiosPost("/technology-match/page/list/export", data, 1);
}

export function getTechMatchTagOptions() {
  return axiosPost("/technology-match/list/tag", {}, 1);
}
// -------------------控价分析-------------------
export function pageControlPrice(data) {
  return axiosPost("/tech_eye/price_control/analyse/shop/sku_is_sale/page/list", data, 1);
}
export function yhPriceDetail(data) {
  return axiosPost("/tech_eye/price_control/analyse/yh_price/detail", data, 1);
}
export function getControlPriceOptions(type, data) {
  return axiosPost("/tech_eye/price_control/analyse/basic_select/" + type, data, 1);
}
export function exportOnSaleGoods(data) {
  return axiosPost("/tech_eye/price_control/analyse/shop/sku_is_sale/list/export", data, 1);
}
export function pagePriceNotice(data) {
  return axiosPost("/tech_eye/price_control/analyse/page/list", data, 1);
}
export function exportPriceNotice(data) {
  return axiosPost("/tech_eye/price_control/analyse/page/list/export", data, 1);
}
export function importPriceNotice(data) {
  return axiosPost("/tech_eye/price_control/analyse/import", data, 1);
}
export function exportSkuPriceControlNotice() {
  return axiosPost("/tech_eye/price_control/analyse/export", {}, 1);
}
export function updatePriceNotice(data) {
  return axiosPost("/tech_eye/price_control/analyse/remark/update", data, 1);
}
export function updateIfCombined(data) {
  return axiosPost("/technology-match/update/if_combined", data, 1);
}

export function getSkuModelInfoList(type, data) {
  return axiosPost("/technology-match/list/" + type, data, 1);
}

export function priceControlConfirm(data) {
  return axiosPost("/technology-match/confirm", data, 1);
}

export function priceControlBatchConfirm(data) {
  return axiosPost("/technology-match/batch/confirm", data, 1);
}

export function addSkuModelTask(data) {
  return axiosPost("/tech_eye/sku_model/task/create", data, 1);
}

export function getSkuModelsMatchSkuUrlCount(data) {
  return axiosPost("/technology-match/skuUrlCount", data, 1);
}

export function queryTaskPageList(data) {
  return axiosPost("/tech_eye/sku_model/task/list", data, 1);
}

export function deleteSkuModelTask(data) {
  return axiosPost("/tech_eye/sku_model/task/delete", data, 1);
}

export function exportSpuNotice(data) {
  return axiosPost("/tech_eye/spu/export", data, 1);
}

export function dimEcSpuImport(data) {
  return axiosPost("/tech_eye/spu/import", data, 1);
}

export function techSpuPriceControlPageList(data) {
  return axiosPost("/tech_eye/spu/page/list", data, 1);
}

export function getTechSpuPriceControlOptions(type, data) {
  return axiosPost("/tech_eye/spu/basic_select/" + type, data, 1);
}

export function deleteSpu(data) {
  return axiosPost("/tech_eye/spu/delete", data, 1);
}

export function updateSpu(data) {
  return axiosPost("/tech_eye/spu/edit", data, 1);
}

export function addSpu(data) {
  return axiosPost("/tech_eye/spu/add", data, 1);
}

export function getSpuSkuModelSelectList(type, data) {
  return axiosPost("/tech_eye/spu/list/" + type, data, 1);
}

export function getTechSpuSpecificationsOptions(type, data) {
  return axiosPost("/tech_eye/spu_specifications/basic_select/" + type, data, 1);
}

export function techSpuSpecificationsPageList(data) {
  return axiosPost("/tech_eye/spu_specifications/page/list", data, 1);
}

export function deleteSpuSpecifications(data) {
  return axiosPost("/tech_eye/spu_specifications/delete", data, 1);
}

export function updateSpuSpecifications(data) {
  return axiosPost("/tech_eye/spu_specifications/edit", data, 1);
}

export function exportSpuSpecificationsNotice(data) {
  return axiosPost("/tech_eye/spu_specifications/export", data, 1);
}

export function addSpuSpecifications(data) {
  return axiosPost("/tech_eye/spu_specifications/add", data, 1);
}

export function dimEcSpuSpecificationsImport(data) {
  return axiosPost("/tech_eye/spu_specifications/import", data, 1);
}

export function batchDeleteSpuSpecifications(data) {
  return axiosPost("/tech_eye/spu_specifications/batch_delete", data, 1);
}

export function skuModelMatchBatchUpdate(data) {
  return axiosPost("/technology-match/sku_model/batch/update", data, 1);
}

export function skuPriceControlTraceGenerate(data) {
  return axiosPost("/tech_eye/price_control/trace/generate", data, 1);
}

export function pageControlPriceTrace(data) {
  return axiosPost("/tech_eye/price_control/trace/page/list", data, 1);
}

export function skuPriceControlTraceCancel(data) {
  return axiosPost("/tech_eye/price_control/trace/cancel", data, 1);
}

export function techEyeSkuPriceControlPageList(data) {
  return axiosPost("/tech_eye/sku/price_control/page/list", data, 1);
}

export function getTechSkuPriceControlOptions(type, data) {
  return axiosPost("/tech_eye/sku/price_control/basic_select/" + type, data, 1);
}

export function getTechPriceControlSkuModelInfoList(type, data) {
  return axiosPost("/tech_eye/sku/price_control/basic_select/" + type, data, 1);
}

export function exportTechSkuPriceControl(data) {
  return axiosPost("/tech_eye/sku/price_control/export", data, 1);
}

export function skuPriceControlImport(data) {
  return axiosPost("/tech_eye/sku/price_control/import", data, 1);
}

export function deleteSkuPriceControl(data) {
  return axiosPost("/tech_eye/sku/price_control/delete", data, 1);
}

export function addSkuPriceControl(data) {
  return axiosPost("/tech_eye/sku/price_control/add", data, 1);
}

export function editSkuPriceControl(data) {
  return axiosPost("/tech_eye/sku/price_control/edit", data, 1);
}

export function querySpuInfo(data) {
  return axiosPost("/tech_eye/spu/get", data, 1);
}

export function querySkuModelPageList(data) {
  return axiosPost("/tech_eye/sku/price_control/page/list/by_skuModel", data, 1);
}

export function exportPriceControlTraceNotice(data) {
  return axiosPost("/tech_eye/price_control/trace/page/list/export", data, 1);
}

export function queryLowControlPricePageList(data) {
  return axiosPost("/tech_eye/price_control/analyse/low/page/list", data, 1);
}

export function getUserModelDefaultOptions() {
  return axiosPost("/tech_eye/price_control/analyse/model_default/list", {}, 1);
}

export function getUserPlatformDefaultOptions() {
  return axiosPost("/tech_eye/price_control/analyse/platform_default/list", {}, 1);
}
