<template>
  <div class="new-box">
    <div class="mg-search" >
        <el-card style="margin: 10px;">
        <SearchFilter  @search="handleQuery"  @reset="resetQuery" @keyup.enter.native="handleQuery">
      <!-- <el-form ref="queryFormRef" size="mini" label-width="auto" :inline="true" :model="queryForm" @keyup.enter.native="handleQuery"> -->
        <el-form-item label="分公司">
          <DictSelect
            ref="org"
            type="org"
            :value.sync="queryForm.orgCode"
            linkageRef="dc,shop"
            :dictFun="dictFun"
            fullLabel
            :clearable="orgAllAuth"
            :init="true"
            :init-options="this.options.orgOptions"
            @changeLinkageRef="handleChangeLinkageRef"
          />
        </el-form-item>
        <el-form-item label="配送中心">
          <DictSelect
            ref="dc"
            type="dc"
            :value.sync="queryForm.dcCode"
            :dictFun="dictFun"
            parentLinkageRef
            fullLabel
            multiple
            collapse-tags
            @getOtherJsonParam="(callback) => callback(dictParam)"
            @change="dictParam = Object.assign({}, queryForm)"
          />
        </el-form-item>
        <el-form-item label="店铺">
          <DictSelect
            ref="shop"
            type="shop"
            :value.sync="queryForm.shopCode"
            :dictFun="dictFun"
            parentLinkageRef
            fullLabel
            remote
            :init-props="{ label: 'label', value: 'fullCode'}"
            @getOtherJsonParam="(callback) => callback(dictParam)"
          />
        </el-form-item>
        <el-form-item label="审核状态">
          <el-select v-model="queryForm.status" clearable placeholder="请选择">
            <el-option v-for="item in options.statusOptions" :label="item.label" :value="item.value" :key="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item label="提交时间">
          <el-date-picker v-model="queryForm.createTimes"
          type="daterange"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
></el-date-picker>
        </el-form-item>
        <el-form-item label="审核时间">
          <el-date-picker v-model="queryForm.updateTimes"
          type="daterange"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"></el-date-picker>
        </el-form-item>
        <!-- <el-form-item>
          <el-button class="form-btn-item" size="mini" icon="el-icon-search" type="primary" @click="handleQuery">搜索</el-button>
          <el-button v-if="orgAllAuth" class="form-btn-item" size="mini" type="success" @click="handleBatchAudit('1')">批量通过</el-button>
          <el-button v-if="orgAllAuth" class="form-btn-item" size="mini" type="danger" @click="handleBatchAudit('2')">批量拒绝</el-button>
          <el-button v-if="!orgAllAuth" class="form-btn-item" size="mini" type="warning" @click="handleBatchAudit('3')">批量撤回</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-download" type="primary" @click="handleExport">导出</el-button>
        </el-form-item> -->

      <!-- </el-form> -->
    </SearchFilter>
    </el-card>
    </div>
    <el-card style="margin: 10px;" class="box-card">
        <el-button v-if="orgAllAuth" class="form-btn-item" size="mini" type="success" @click="handleBatchAudit('1')">批量通过</el-button>
          <el-button v-if="orgAllAuth" class="form-btn-item" size="mini" type="danger" @click="handleBatchAudit('2')">批量拒绝</el-button>
          <el-button v-if="!orgAllAuth" class="form-btn-item" size="mini" type="warning" @click="handleBatchAudit('3')">批量撤回</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-download" type="primary" @click="handleExport">导出</el-button>
          <span style="margin-left: 10px; color: red;">
            <span>重点终端(未审核):</span> <span style="margin-left: 3px">增加: <strong>{{summary.addNum}}</strong> 条</span> <span style="margin-left: 3px">删除: <strong>{{summary.reduceNum}}</strong> 条</span><span style="margin-left: 3px">{{ summary.realAddNum &lt; 0 ? '合计减少:':  '合计增加:'}} <strong>{{ (summary.realAddNum &lt; 0 ?0 - summary.realAddNum: summary.realAddNum) }}</strong>家</span>
          </span>
    <el-table
    style="margin-top: 10px;"
      :data="pageData.rows"
      class="customer-table"
      border
            size="small"
      v-loading="loading"
      ref="table"
      highlight-current-row
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="50" :selectable="(row, index) => {
        return row.status===0;
      }" />
      <el-table-column label="分公司" prop="shop" width="120">
        <template #default="{ row }">
          <span>{{ row.orgCode + "-" + row.orgName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="所属配送中心" prop="dcName" width="250">
        <template #default="{ row }">
          <span>{{ row.dcCode + ( row.dcCode!==row.beforeDcCode || row.shopCode!==row.beforeShopCode ? "(" + row.beforeDcCode +")":"") + "-" + row.dcName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="店铺" prop="shop" width="300">
        <template #default="{ row }">
          <span>{{ row.shopCode + ( row.dcCode!==row.beforeDcCode || row.shopCode!==row.beforeShopCode?"(" + row.beforeShopCode +")":"")  + "-" + row.shopName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="提交人" prop="createUser" width="140">
        <template #default="{ row }">
          <span>{{ row.createUser ? (row.createUser + (row.createUserName ? '-' + row.createUserName: '')):'' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="提交时间" prop="createTime" width="200" />
      <el-table-column label="审核状态" prop="status" >
        <template #default="{ row }">
          <el-tag :type="$options.filters.statusFilter(row.status)">
            {{ options.statusOptions.find(e => e.value===row.status).label }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="审核人" prop="updateUser" width="180">
        <template #default="{ row }">
          <span>{{ row.updateUser ? (row.updateUser + (row.updateUserName ? '-' + row.updateUserName: '')):'' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="审核时间" prop="updateTime" width="180" />
      <el-table-column label="操作" width="140" align="center" fixed="right">
        <template #default="{ row }">
          <div>
            <el-button v-if="orgAllAuth && row.status===0" size="mini" type="text" @click="handleAudit(row, 'audit')">审核</el-button>
            <el-button v-if="!orgAllAuth && row.status===0" size="mini" type="text" @click="handleAudit(row, 'withdraw')">撤回</el-button>
            <el-button size="mini" type="text" @click="handleAudit(row, 'view')">查看</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <Pagination :total="pageData.total" :page.sync="queryForm.current" :pageSizes="[50, 100, 200]" :limit.sync="queryForm.size" @pagination="handleQuery" />
</el-card>
    <el-dialog :title="editDialog.type === 'view'? '查看': '审核'"  :visible.sync="editDialog.visible" append-to-body width="800px" :close-on-click-modal="false">
      <el-form ref="form" size="mini" inline :rules="editDialog.rules" label-width="160px" :model="editDialog.form" class="common-form">
        <el-row class="common-form-row">
          <div style="padding: 10px">
            <el-col :span="12">
              <el-form-item label="分公司" prop="orgCode">
                <span>{{ editDialog.form.orgCode+ '-' + editDialog.form.orgName}}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="配送中心" prop="dcCode">
                <span>{{ editDialog.form.dcCode + ( editDialog.form.dcCode!=editDialog.form.beforeDcCode || editDialog.form.beforeShopCode!=editDialog.form.beforeShopCode ? "(" + editDialog.form.beforeDcCode +")":"") + '-' + editDialog.form.dcName}}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="店铺" prop="shopCode">
                <span>{{ editDialog.form.shopCode +( editDialog.form.dcCode!=editDialog.form.beforeDcCode || editDialog.form.beforeShopCode!=editDialog.form.beforeShopCode?"(" + editDialog.form.beforeShopCode +")":"")  + '-' + editDialog.form.shopName}}</span>
              </el-form-item>
            </el-col>
            <el-col class="form-label-class">审核前数据</el-col>
            <el-col :span="12">
              <el-form-item label="是否重点店铺" prop="ifOptimumShop">
                <span>{{ editDialog.form.oldIfOptimumShop ==='1' ? '是':'否' }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="终端容量标签" prop="shopCapacity">
                <span>{{ editDialog.form.oldShopCapacity }}</span>
              </el-form-item>
            </el-col>
            <el-col  class="form-label-class">申请变更数据</el-col>
            <el-col :span="12">
              <el-form-item label="是否重点店铺">
                <span>{{ editDialog.form.ifOptimumShop ==='1' ? '是':'否'  }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="终端容量标签">
                <span>{{ editDialog.form.shopCapacity }}</span>
              </el-form-item>
            </el-col>
            <el-col  class="form-label-class">审核员变更数据</el-col>
            <el-col :span="12">
              <el-form-item label="是否重点店铺">
                <el-select v-model="editDialog.form.auditIfOptimumShop" clearable @change="() => editDialog.form.auditShopNewCapacity=undefined">
                  <el-option label="是" value="1"></el-option>
                  <el-option label="否" value="0"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="终端容量标签">
                <el-select v-model="editDialog.form.auditShopNewCapacity" clearable filterable>
                  <el-option v-for="item in shopCapacities" :lable="item" :value="item" :key="item"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col>
              <el-form-item label="备注" prop="oldIfOptimumShop" label-width="120px">
                <el-input v-model="editDialog.form.remark" clearable type="textarea" />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="提交人" prop="name">
                <span>{{ editDialog.form.createUser }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="提交时间" prop="name">
                <span>{{ editDialog.form.createTime }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12" v-if="editDialog.form.status!==0">
              <el-form-item label="审核人" prop="name">
                <span>{{ editDialog.form.updateUser }}</span>
              </el-form-item>
            </el-col>
            <el-col :span="12" v-if="editDialog.form.status!==0">
              <el-form-item label="审核时间" prop="name">
                <span>{{ editDialog.form.updateTime }}</span>
              </el-form-item>
            </el-col>
          </div>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: right" v-if="!editDialog.disabled">
        <el-button type="primary" size="mini" @click="editDialog.visible = false">取消</el-button>
        <el-button v-if="editDialog.type === 'audit' && orgAllAuth" type="success" size="mini" @click="submitAudit(1)">审核通过</el-button>
        <el-button v-if="editDialog.type === 'audit' && orgAllAuth" type="danger" size="mini" @click="submitAudit(2)">审核不通过</el-button>
      </div>
    </el-dialog>

  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import { pageShopAuditNew,auditShopNew,batchAuditShopNew, topShopDict, dictSelect, exportShopAuditRecordsNew } from "@/api/appTask";
import { parseTime, deepClone } from "@/utils"
import { Notification } from "element-ui";
import DictSelect from "@/components/DictSelect";
import SearchFilter from "@/components/SearchFilter/index";
import {getAunAuditSummary} from "../../../api/appTask";

export default {
  name: "redPacket",
  components: { Pagination, DictSelect, SearchFilter },
  filters: {
    statusFilter(status) {
      const statusMap = {
        0: "primary",
        1: "success",
        2: "danger",
        3: "warning",
      };
      return statusMap[status];
    },
  },
  data() {
    return {
      pageData: {
        total: 0,
        rows: [],
        selections: [],
      },
      queryForm: {
        current: 1,
        size: 100,
        status: undefined,
        orgCode: undefined,
        dcCode: undefined,
        shopCode: undefined,
        updateTimes:[],
        createTimes:[],
        createTimeStart: undefined,
        createTimeEnd: undefined,
        updateTimeStart: undefined,
        updateTimeEnd: undefined
      },
      loading: false,
      dictParam: {},
      dictFun: topShopDict,
      orgAllAuth: false,
      editDialog: {
        visible: false,
        type: 'audit',
        form: {
          id: undefined,
          orgCode: undefined,
          poiCode: undefined,
          dcCode: undefined,
          shopCode: undefined,
          shopCapacity: undefined,
          oldShopCapacity: undefined,
          auditShopNewCapacity: undefined,
          ifOptimumShop: undefined,
          oldIfOptimumShop: undefined,
          auditIfOptimumShop: undefined,
          deleted: 0,
          status: 0,
          createTime: undefined,
          createUser: undefined,
          updateTime: undefined,
          updateUser: undefined,
          orgName: undefined,
          dcName: undefined,
          shopName: undefined
        },
        rules: {
        },
      },
      options: {
        statusOptions: [{value: 0, label: '未审核'},{value: 1, label: '已通过'},{value: 2, label: '已拒绝'},{value: 3, label: '已撤回'}],
        shopCapacities: ['TOP','一档','二档','三档','四档',]
      },
      summary: {
        addNum: 0,
        reduceNum: 0,
        realAddNum: 0,
      }
    };
  },
  computed: {
    shopCapacities() {
      if (this.editDialog.form.auditIfOptimumShop === '1') {
        return this.options.shopCapacities;
      } else {
        return ['其它']
      }
    }
  },
  watch: {
  },
  created() {
    dictSelect('ht-org-all').then(res => {
      this.orgAllAuth = res.data.filter(e => e.value === 'ALL').length > 0 ? true: false;
      this.options.orgOptions = res.data.filter(e => e.value !== 'ALL');
      if (!this.orgAllAuth) {
        this.queryForm.orgCode = this.options.orgOptions[0].value;
      }
      this.handleFilter();
    })
  },
  mounted() {
    // this.handleFilter();
  },
  methods: {
    handleQuery() {
      this.loading = true;
      let query = Object.assign({}, this.queryForm);
      this.setQueryShop(query);
      pageShopAuditNew(query).then(res => {
        this.loading = false;
        if (!res.data) {
          return false;
        }
        this.pageData.rows = res.data?.records;
        this.pageData.total = res.data?.total;
        this.$refs.table.doLayout();
      });
      this.summary ={
        addNum: 0,
        reduceNum: 0,
        realAddNum: 0,
      }
      getAunAuditSummary(query).then(res => {
        if (res && res.code == 200) {
          res.data.realAddNum = res.data.addNum - res.data.reduceNum;
          this.summary = res.data;
        }
      })
    },
    setQueryShop(query) {
      if (this.queryForm.dcCode) {
        query.dcCode = this.queryForm.dcCode.join(',')
      }
      if (this.queryForm.shopCode) {
        const shop = this.$refs.shop.itemOptions.find(e => e.fullCode === this.queryForm.shopCode);
        query.shopCode = undefined;
        if (shop) {
          query.shopCode = shop.value;
          query.dcCode = shop.fullCode.replace("_" +query.shopCode, "");
        }
      }
      if (query.updateTimes && query.updateTimes.length>0) {
        query.updateTimeStart = query.updateTimes[0];
        query.updateTimeEnd = query.updateTimes[1];
      }
      if (query.createTimes && query.createTimes.length>0) {
        query.createTimeStart = query.createTimes[0];
        query.createTimeEnd = query.createTimes[1];
      }
    },
    handleFilter() {
      this.queryForm.current = 1;
      this.handleQuery();
    },
    resetQuery() {
      const queryForm = {
        current: 1,
        size: 100,
      };
      this.queryForm = Object.assign({}, queryForm);
      this.$forceUpdate();
    },
    handleSelectionChange(selections) {
      this.pageData.selections = selections;
    },
    handleChangeLinkageRef(ref, val, type, form, dict) {
      this.$nextTick(() => {
        ref.split(',').forEach(e => {
          if (this.$refs[e]) {
            if (form && dict) {
              let tmp = Object.assign({}, form);
              for(const key in this[dict]) {
                this[dict][key] = tmp[key];
              }
            } else {
              this.dictParam = Object.assign({}, this.queryForm)
            }
            setTimeout(() => {
              this.$refs[e].handleRefChange(val, type);
            }, 100)
            this.$forceUpdate();
          } else {
            this.dictParam = Object.assign({}, this.queryForm)
          }
        })
      });
    },
    handleAudit(row, type) {
      this.editDialog.form = deepClone(row);
      this.editDialog.type = type;
      if (type == 'withdraw') {
        this.submitAudit('3');
      } else {
        this.editDialog.visible = true;
      }
    },
    submitAudit(status) {
      this.editDialog.form.status = status;
      auditShopNew(this.editDialog.form).then(res => {
        if(res && res.code == 200) {
          Notification.success("操作成功！");
          this.editDialog.visible = false;
          this.handleFilter();
        }
      })
    },
    handleBatchAudit(type) {
      if (this.pageData.selections.length === 0) {
        Notification.warning("请至少选择一条未审核记录！");
        return;
      }
      const textButton = type === '3'? '撤回': (type==='2'? '拒绝':'通过')
      this.$confirm('请确认是否批量 ' + textButton + ' 勾选的' + this.pageData.selections.length + '条数据！')
        .then(() => {
          batchAuditShopNew({ list: this.pageData.selections.map(e => e.id) }, type).then(res => {
            if(res && res.code == 200) {
              Notification.success("操作成功！");
              this.handleFilter();
            }
          })
        })
        .catch(() => {});

    },
    handleExport() {
      this.loading = true;
      let query = Object.assign({}, this.queryForm);
      this.setQueryShop(query);
      exportShopAuditRecordsNew(query).then(res => {
        this.loading = false;
        if (!res) {
          return
        }
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = window.URL.createObjectURL(new Blob([res]))
        link.setAttribute('download', '店铺审核数据导出.xlsx')
        document.body.appendChild(link)
        link.click()
        link.remove()
      })
    },
  }
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding-top: 10px;
  padding-bottom: 5px;
}

.common-form {
  &-row {
    display: flex !important;
    flex-wrap: wrap;
  }
  div .el-form-item {
    display: flex !important;
    ::v-deep {
      .el-form-item__content {
        width: 100%;
        .el-input {
          width: 100%;
        }
        .el-input-number {
          width: 100%;
        }
        .el-select {
          width: 100%;
        }
        .el-date-editor {
          width: 100%;
        }
      }
    }
  }
}
.link-text-class {
  margin-left: 20px;
  color: gray;
}
::v-deep .el-form-item__error {
  top: 70%
}

.form-label-class {
  font-size: 16px;
  font-weight: 500;
  color: #f57d13;
}
</style>
