var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-card",
        { staticStyle: { margin: "10px" } },
        [
          _c(
            "el-tabs",
            {
              attrs: { type: "card" },
              on: {
                "tab-click": function ($event) {
                  _vm.newRaceNames = ""
                  _vm.time = ""
                },
              },
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "赛道维度", name: "track" } },
                [
                  _vm.activeName == "track"
                    ? _c("trackView", {
                        on: { callbackNameType: _vm.callbackNameType },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "中类维度", name: "mediumClass" } },
                [
                  _vm.activeName == "mediumClass"
                    ? _c("mediumClass", {
                        attrs: { propName: _vm.newRaceNames, time: _vm.time },
                        on: { callbackNameType: _vm.callbackNameType },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "小类维度", name: "subCategories" } },
                [
                  _vm.activeName == "subCategories"
                    ? _c("subCategories", {
                        attrs: { propName: _vm.newRaceNames, time: _vm.time },
                        on: { callbackNameType: _vm.callbackNameType },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "单品维度", name: "singles" } },
                [
                  _vm.activeName == "singles"
                    ? _c("singles", {
                        attrs: { propName: _vm.newRaceNames, time: _vm.time },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }