<template>
  <div class="new-box">
    <div class="mg-search">
      <el-card style="margin: 10px">
        <SearchFilter @search="queryAll" @reset="resetQueryAll" @keyup.enter.native="queryAll">
              <el-form-item v-if="!defaultOrgCode" label="分公司">
                <DictSelect
                  ref="org"
                  type="ht-org"
                  :value.sync="queryForm.orgCode"
                  init
                  :initOptions="orgDictInitOptions"
                  fullLabel
                  :clearable="false"
                  :default-index="0"
                  placeholder="请选择分公司"
                  @changeLinkageRef="handleQueryChangeLinkageRef"
                />
              </el-form-item>
              <el-form-item label="配送中心">
                <el-autocomplete
                  ref="dcSuggestion"
                  class="inline-input"
                  v-model="queryForm.dcKeyWord"
                  :fetch-suggestions="queryDcSuggestions"
                  @select="handleDcSelect"
                  placeholder="请输入配送中心关键字"
                  clearable
                />
              </el-form-item>
              <el-form-item label="用户">
                <el-input v-model="queryForm.userName" placeholder="请输入用户账号或姓名" clearable />
              </el-form-item>
              <el-form-item label="职级">
                <el-select clearable v-model="queryForm.positionId" class="filter-item filter-set-width" size="mini" placeholder="请选择">
                  <el-option v-for="item in positionOptions" :key="item.value" :label="item.label" :value="item.value"/>
                </el-select>
              </el-form-item>
              <el-form-item label="是否主要负责人">
                <el-select clearable v-model="queryForm.principal" class="filter-item filter-set-width" size="mini" placeholder="请选择">
                  <el-option v-for="item in options.principalOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="账号状态">
                <el-select clearable v-model="queryForm.status" class="filter-item filter-set-width" size="mini" placeholder="请选择">
                  <el-option v-for="item in options.userStatusOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
        </SearchFilter>
      </el-card>
    </div>
    <el-row class="contnet" style="margin-left: 10px; margin-right: 10px;" :gutter="20">
      <!--左侧树-->
      <el-col id="left-tree" class="left-tree" :offset="0" :span="6">
        <el-scrollbar style="height:calc(100vh); width: 100%">
          <el-tree
            ref="tree"
            node-key="id"
            class="unselect"
            v-loading="aside.loading"
            :highlight-current="true"
            :data="aside.treeData"
            :props="aside.defaultProps"
            :default-expanded-keys="aside.nodeKey"
            :expand-on-click-node="false"
            @node-click="handleNodeClick"
            :allow-drop="allowDrop"
            :allow-drag="allowDrag"
            @node-drop="handleDrop"
            draggable
          >
              <span class="custom-tree-node" slot-scope="{ node, data }">
                <span v-if="showColor && data.existCurrent" class="exist_current">
                  <i v-if="data.positionId === 1 || data.positionId === 3" class="el-icon-folder"/>
                  <i v-else class="el-icon-user"/>
                  {{node.label}}
                </span>
                <span v-else>
                  <i v-if="data.positionId === 1 || data.positionId === 3" class="el-icon-folder"/>
                  <i v-else class="el-icon-user"/>
                  {{node.label}}
                </span>
                <i class="positionColor"> [{{positionOptions.filter(item => item.value === data.positionId).map(item => item.label)[0]}}]</i>
              </span>
          </el-tree>
        </el-scrollbar>
        <!--中间拖动栏-->
        <div id="resize" class="resize" title="收缩侧边栏"><i class="el-icon-more" style="transform:rotate(90deg);color: #fff;width: 8px;font-size: x-large;"></i></div>
      </el-col>
      <!--右侧内容-->
      <el-col id="right-content" class="right-content" :offset="0" :span="18">
        <el-button class="form-btn-item" size="mini" icon="el-icon-plus" type="primary" @click="handleEdit(undefined)">新增组织用户</el-button>
        <el-button class="form-btn-item" size="mini" icon="el-icon-upload" type="primary" @click="handleImport">组织用户导入</el-button>
        <el-button class="form-btn-item" size="mini" icon="el-icon-download" type="primary" @click="handleExport">组织用户导出</el-button>
        <el-button class="form-btn-item" size="mini" icon="el-icon-delete" type="warning" @click="handleBatchDelete">删除组织用户</el-button>
        <el-table
          style="margin-top: 10px;"
          :data="pageData.rows"
          v-loading="loading"
          size="small"
          row-class-name="rowClassName"
          @selection-change="handleSelectionChange"
          :show-checkbox="true"
        >
          <el-table-column type="selection" width="88"/>
          <el-table-column label="用户" prop="userName">
            <template #default="{ row }">
              <span>{{ row.userName + "-" + row.nickName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="账号状态" prop="status"/>
          <el-table-column label="分公司" prop="orgCode">
            <template #default="{ row }">
              <span>{{ row.orgCode + "-" + row.orgName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="配送中心" prop="dcCode">
            <template #default="{ row }">
              <span>{{ row.dcCode === 'ALL' ? '' : row.dcCode + "-" + row.dcName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="职级" prop="position"/>
          <el-table-column label="是否主要负责人" prop="principal"/>
          <el-table-column label="岗位名称" prop="positionName"/>
          <el-table-column label="团队名称" prop="teamName">
            <template #default="{ row }">
              <span>{{row.positionId === 6 ? '' : row.teamName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="主要负责人" prop="principalUser">
            <template #default="{ row }">
              <span>{{row.principalUserName === null ? '' : row.principalUserName + "-" + row.principalNickName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="上级" prop="parentUser">
            <template #default="{ row }">
              <span>{{row.parentUserName === null ? '' : row.parentUserName + "-" + row.parentNickName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="备注" prop="remark" show-tooltip-when-overflow />
          <el-table-column label="操作" fixed="right" width="180px" align="center">
            <template #default="{ row }">
              <el-button size="mini" type="text" style="border: 0px;" @click="handleEdit(row)">编辑</el-button>
              <el-button size="mini" type="text" style="border: 0px;" @click="handleDelRow(row)">删除</el-button>
              <el-button v-if="row.positionId < 6" :disabled="row.principal==='是' && row.positionId <6" size="mini" type="text" style="border: 0px;" @click="handleChangeMain(row)">设为主负责人</el-button>
            </template>
          </el-table-column>
        </el-table>
        <Pagination :total="pageData.total" :page.sync="queryForm.current" :limit.sync="queryForm.size" @pagination="queryPage" />
      </el-col>
    </el-row>

    <el-dialog :title="editDialog.form.id?'编辑组织人员':'新增组织人员'" :visible.sync="editDialog.visible" append-to-body width="450px" :close-on-click-modal="false" top="5%">
      <el-form ref="editFormRef" size="mini" label-width="120px" label-position="right" :inline="true" :model="editDialog.form">
         <el-form-item label="关联用户" required>
           <DictSelect
             ref="user"
             type="user"
             :disabled="editDialog.form.id!==undefined"
             :value.sync="editDialog.form.userName"
             :dictFun="dictUserFun"
             fullLabel
             remote
             version="v3"
             placeholder="请输入用户账号或姓名"
             :init-props="{ label: 'nickName', value: 'userName'}"
             @getOtherJsonParam="(callback) => callback({ pageSize: 50, pageNum: 1})"
             @change="changeUser"
           />
         </el-form-item>
         <el-form-item label="分公司" required>
           <DictSelect
             ref="org"
             type="org"
             :disabled="editDialog.form.id!==undefined"
             :value.sync="editDialog.form.orgCode"
             linkageRef="dc"
             init
             :initOptions="orgDictInitOptions"
             fullLabel
             :clearable="false"
             placeholder="请选择分公司"
             @changeLinkageRef="handleChangeLinkageRef"
           />
         </el-form-item>
         <el-form-item label="职级" required>
           <el-select v-model="editDialog.form.positionId" @change="val => changePosition(val, 'position')">
             <el-option v-for="item in positionOptions" :key="item.value" :label="item.label" :value="item.value" />
           </el-select>
         </el-form-item>
         <el-form-item label="配送中心" v-show="editDialog.form.positionId >= 3" required>
           <el-select v-show="editDialog.form.positionId && editDialog.form.positionId === 3" v-model="editDialog.form.dcCode" filterable clearable @change="val => changePosition(val, 'dc')">
             <el-option v-for="item in allDcOptions" :key="item.value" :label="item.dcCode + '-' + item.dcName" :value="item.dcCode" />
           </el-select>
           <el-select v-show="editDialog.form.positionId && editDialog.form.positionId > 3" v-model="editDialog.form.dcCode" filterable clearable @change="val => changePosition(val, 'dc')">
             <el-option v-for="item in branchDcOptions" :key="item.value" :label="item.value + '-' + item.label" :value="item.value" />
           </el-select>
         </el-form-item>
         <el-form-item label="上级" v-show="editDialog.form.positionId > 1" required>
           <el-select v-model="editDialog.form.parentId" filterable @change="val => {$forceUpdate();}">
             <el-option v-for="item in parentOptions" :key="item.id" :label="item.userName + (item.nickName ? '-' + item.nickName: '')" :value="item.id" />
           </el-select>
         </el-form-item>
         <el-form-item label="是否主要负责人" v-if="editDialog.form.positionId<6" required>
           <el-radio-group v-model="editDialog.form.principal" :disabled="editDialog.principalDisabled"
                           @input="() => {handleGetDeputy();}">
             <el-radio :label="1">是</el-radio>
             <el-radio :label="0">否</el-radio>
           </el-radio-group>
           <div style="width: 250px">
             <span style="color: red;">非主要负责人，不能成为下一职级的上级，可作为副手，享有主要负责人同等数据权限。</span>
           </div>
         </el-form-item>
         <el-form-item label="主要负责人" v-if="editDialog.form.positionId > 1 && editDialog.form.positionId<6 && editDialog.form.principal!==1" required>
           <el-select v-model="editDialog.form.principalId" filterable clearable @change="val => {$forceUpdate();}">
             <el-option v-for="item in editDialog.deputyList" :key="item.id" :label="item.nickName + (item.positionName ? '-' + item.positionName: '')" :value="item.id" />
           </el-select>
         </el-form-item>
         <el-form-item label="岗位名称" required>
           <el-input v-model="editDialog.form.positionName" placeholder="请输入岗位名称" clearable @input="val => {
              editDialog.form.positionName = val
              $forceUpdate();
            }" />
         </el-form-item>
         <el-form-item label="团队名称" v-if="editDialog.form.positionId < 6" required>
           <el-input v-model="editDialog.form.teamName" placeholder="请输入团队名称" clearable @input="val => {
             editDialog.form.teamName = val
             $forceUpdate();
           }" />
         </el-form-item>
         <el-form-item label="备注">
           <el-input v-model="editDialog.form.remark" type="textarea" placeholder="请输入备注" clearable @input="val => {
              editDialog.form.remark = val
              $forceUpdate();
            }"  />
         </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button :disabled="buttonLoading" type="primary" size="mini" @click="handleSave">确定</el-button>
        <el-button type="primary" size="mini" @click="editDialog.visible = false">关闭</el-button>
      </div>
    </el-dialog>

    <el-dialog title="岗位人员" :visible.sync="viewDialog.visible" append-to-body width="1000px" :close-on-click-modal="false">
      <el-table
        ref="viewTableRef"
        :data="viewDialog.list"
        height="500px"
        v-loading="viewDialog.loading"
        size="mini"
        border
        style="margin-top: 5px"
      >
        <el-table-column v-if="viewDialog.queryParam.positionId < 6" label="主负责人" prop="orgName" width="80">
          <template #default="{ row }">
            <span :style="row.principal===1 && row.positionId <6? {'font-weight': 600}: ''">{{ row.principal===1?'是':'否' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="用户" prop="nickName" width="150">
          <template #default="{ row }">
            <span :style="row.principal===1 && row.positionId <6? {'font-weight': 600}: ''">{{ row.userName + '-' + row.nickName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="关联组织" prop="orgName" width="200" >
          <template #default="{ row }">
            <span :style="row.principal===1 && row.positionId <6? {'font-weight': 600}: ''" v-if="row.positionId<3">{{ row.orgCode + '-' + row.orgName }}</span>
            <span v-else :style="row.principal===1 && row.positionId <6? {'font-weight': 600}: ''" >{{ row.dcCode ? (row.dcCode + "-" + (row.dcName? row.dcName: '')) : '' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="上级" prop="parentName" width="150">
          <template #default="{ row }">
            <span :style="row.principal===1 && row.positionId <6? {'font-weight': 600}: ''">{{ (row.parentUserName && row.parentName) ? (row.parentUserName + '-' + row.parentName): '' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="岗位" prop="positionName" width="100">
          <template #default="{ row }">
            <span :style="row.principal===1 && row.positionId <6? {'font-weight': 600}: ''">{{ row.positionName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="备注" prop="remark" show-overflow-tooltip>
          <template #default="{ row }">
            <span :style="row.principal===1 && row.positionId <6? {'font-weight': 600}: ''">{{ row.remark }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="200">
          <template #default="{ row }">
            <el-button size="mini" type="text" style="border: 0px;" @click="handleEdit(row)">编辑</el-button>
            <el-button size="mini" type="text" style="border: 0px;" @click="handleDelRow(row)">删除</el-button>
            <el-button v-if="row.positionId < 6" :disabled="row.principal===1 && row.positionId <6" size="mini" type="text" style="border: 0px;" @click="handleChangeMain(row)">设为主负责人</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="text-align: center;border: 1px dashed skyblue;"> <el-button size="mini" type="text" style="border: 0px; width: 100%" @click="handleAdd">新增</el-button></div>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <!--        <el-button type="primary" size="mini" @click="handleSave">确定</el-button>-->
        <el-button type="primary" size="mini" @click="viewDialog.visible = false">关闭</el-button>
      </div>
    </el-dialog>

    <el-dialog title="导入" :visible.sync="importDialog.visible" append-to-body width="400px">
      <el-upload
        ref="upload"
        class="upload-demo"
        drag
        :limit="1"
        action="#"
        :auto-upload="false"
        :file-list="importDialog.fileList"
        :on-change="(file, fileList) => handleFileChange(file, fileList, 'change')"
        :on-remove="(file, fileList) => handleFileChange(file, fileList, 'remove')"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">
          <span>仅允许导入xls、xlsx格式文件。</span>
          <el-link type="primary" :underline="false" style="font-size:12px;vertical-align: baseline;" target="_blank" :href="importDialog.template">下载模板</el-link>
        </div>
      </el-upload>
      <div style="margin-top: 10px">
        <span><strong>导入类型</strong> </span>
        <el-radio-group v-model="importDialog.type">
          <el-radio label="add">新增
            <el-popover
              placement="top-start"
              width="300"
              trigger="hover"
              content="此方式导入，将在该职级下新增该用户，如果已经存在，导入不成功">
              <i slot="reference" class="el-icon-question" />
            </el-popover>
          </el-radio>
          <el-radio label="override">覆盖
            <el-popover
              placement="top-start"
              width="300"
              trigger="hover"
              content="此方式导入，将清空本分公司所有组织用户数据，导入本文档中的数据">
              <i slot="reference" class="el-icon-question" />
            </el-popover>
          </el-radio>
        </el-radio-group>
      </div>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button type="primary" size="mini" :disabled="submitDisabled" @click="submitImport">确定</el-button>
        <el-button type="primary" size="mini" @click="importDialog.visible = false">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import {
  dictSelect,
  saveOrgBranch,
  checkMain,
  listBranch,
  delBranch,
  importBranchV2,
  exportBranchV2,
  branchDict,
  userSelect,
  changeMain,
  getOrgBranchTreeV1, pageOrgBranchV1, batchDelBranch, saveTreeSort
} from "@/api/appTask";
import DictSelect from "@/components/DictSelect";

import SearchFilter from "@/components/SearchFilter/index";
import { deepClone, excelExport } from "@/utils";
import {Loading, Notification} from "element-ui";

export default {
  name: "OrgBranch",
  components: { Pagination, DictSelect, SearchFilter},
  data() {
    return {
      showColor: false,
      lastClickNode: undefined,
      pageData: {
        total: 0,
        rows: [],
        selections: [],
      },
      queryForm: {
        current: 1,
        size: 20,
        orgCode: undefined,
        dcKeyWord: undefined,
        dcCode: undefined,
        userName: undefined,
        positionId: undefined,
        principal: undefined,
        status: undefined,
        branchIds: undefined,
        nodeSelectPositionId: undefined,
      },
      draggable: false,
      conditionChanged: false,
      loading: false,
      dictFun: dictSelect,
      dictUserFun: userSelect,
      orgDictInitOptions: [],
      positionOptions: [
        { value: 1, label : '正/副总经理'},
        { value: 2, label : '大区经理/省级经理'},
        { value: 3, label : '配送中心负责人'},
        { value: 4, label : '区域经理'},
        { value: 5, label : '业务经理'},
        { value: 6, label : '业务员'},
      ],
      options: {
        principalOptions: [{value: 1, label: '是'}, {value: 0, label: '否'}],
        userStatusOptions: [{value: 0, label: '正常'}, {value: 1, label: '关闭'}]
      },
      allDcOptions: [],
      branchDcOptions: [],
      parentOptions: [],
      defaultOrgCode: undefined,
      addOrgBranchInit: {
        orgCode: undefined,
        dcCode: undefined,
        positionId: undefined,
        parentId: undefined,
        principalId: undefined,
      },
      editDialog: {
        visible: false,
        principalDisabled: true,
        rules: {

        },
        deputyList: [],
        form: {
          orgCode: undefined,
          dcCode: undefined,
          positionId: undefined,
          userName: undefined,
          nickName: undefined,
          positionName: undefined,
          teamName: undefined,
          principal: 0,
          parentId: undefined,
          principalId: undefined,
          remark: undefined,
          parentName: undefined,
          parentPositionName: undefined,
        }
      },
      exportHeaders:
        {
          userName: '用户账号',
          positionIdName: '职级',
          orgCode: '分公司编码',
          dcCode: '配送中心编码',
          ifMain: '是否主要负责人',
          positionName: '岗位名称',
          teamName: '团队名称',
          mainUserName: '主要负责人账号',
          parentUserName: '上级账号',
          remark: '备注',
          errorMsg: '校验信息',
        },
      importDialog: {
        visible: false,
        type: 'add',
        fileList: [],
        accept: ['.xls', '.xlsx'],
        title: '分公司组织导入',
        template: process.env.VUE_APP_OSS_TEMPLATE_PATH +'/%E5%88%86%E5%85%AC%E5%8F%B8%E7%BB%84%E7%BB%87%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BFV2.xlsx'
      },
      proMap: {
        name1: 1,
        name2: 2,
        name3: 3,
        name4: 4,
        name5: 5,
        name6: 6,
      },
      principalMap: {
        name1: 'p1Id',
        name2: 'p2Id',
        name3: 'p3Id',
        name4: 'p4Id',
        name5: 'p5Id',
        name6: 'p6Id',
      },
      viewDialog: {
        visible: false,
        loading: false,
        queryParam: {
          orgCode: undefined,
          dcCode: undefined,
          positionId: undefined,
        },
        list: [],
      },
      buttonLoading: false,
      colFields: ['orgCode','p1Id', 'p2Id','dcCode','p3Id','p4Id', 'p5Id', 'p6Id'],
      spanArr: [],
      aside: {
        loading: false,
        treeData: [],
        defaultProps: {
          children: 'children',
          label: 'teamName'
        },
        nodeKey: []
      },
      submitDisabled: false,
      defaultCheckedKeys: []
    };
  },
  computed: {
    importBaseParams() {
      const importDialog = this.importDialog;
      return importParams => {
        return importParams.find(item => item.type === importDialog.currentImportType);
      };
    },
  },
  created() {
    dictSelect('ht-org').then(res => {
      this.orgDictInitOptions = res.data;
      if (res.data.length > 0) {
        if (res.data.length === 1) {
          this.defaultOrgCode = res.data[0].value;
          this.queryForm.orgCode = this.defaultOrgCode;
          this.queryAll();
        }
      }
    });
  },
  mounted() {
    this.dragControllerDiv()
  },
  watch: {
    'queryForm.orgCode' () {
      this.queryForm.branchIds = undefined;
      this.resetQueryAll();
    },
    'queryForm.userName' () {
      this.conditionChanged = true;
    },
    'editDialog.form.orgCode' () {
      this.setTeamNameIfExist();
    }
  },
  methods: {
    queryAll() {
      this.queryTree();
      this.firstPage();
    },
    queryPage() {
      this.loading = true;
      // 搜索条件变化需要刷新树形结构
      if (this.conditionChanged === true) {
        this.queryForm.branchIds = []
        this.queryTree();
        this.conditionChanged = false;
      }
      const param = deepClone(this.queryForm);
      if (param.nodeSelectPositionId && !param.positionId) {
        param.positionId = param.nodeSelectPositionId;
      }
      pageOrgBranchV1(param).then(res => {
        if (!res.data) {
          return false;
        }
        this.pageData.rows = res.data?.records;
        this.pageData.total = res.data?.total;
        this.loading = false;
      });
    },
    queryTree() {
      this.aside.loading = true;
      let param = deepClone(this.queryForm)
      // 查询树形结构不需要过滤
      param.branchIds = []

      getOrgBranchTreeV1(param).then(res => {
        if (!res.data) {
          return false;
        }
        this.aside.treeData = res.data;
        this.handleTreeDefaultExpand();
        // 设置是否可以树形数据拖动排序
        this.setDraggable(param);
        // 决定节点是否展示颜色
        this.showColor = param.userName !== undefined || param.positionId !== undefined || param.principal !== undefined || param.status !== undefined;
        this.aside.loading = false;
      })
    },
    setDraggable(param) {
      if (param.dcKeyWord === undefined
        && param.positionId === undefined
        && param.userName === undefined
        && param.status === undefined
        && param.principal === undefined) {
        this.draggable = true;
      } else {
        this.draggable = false;
      }
    },
    handleTreeDefaultExpand() {
      // 判断是否清空默认展示节点
      this.aside.nodeKey = []
      // 选择存在上次点击节点
      if (this.lastClickNode) {
        console.log("上次点击节点: " + this.lastClickNode)
        this.$nextTick(() => {
          this.aside.nodeKey.push(this.lastClickNode)
          this.$refs.tree.setCurrentKey(this.lastClickNode);
        })
      }
      // 默认展开一二级
      else if (this.aside.treeData?.length === 1) {
        this.aside.nodeKey.push(this.aside.treeData[0].id)
        if (this.aside.treeData[0].children?.length === 1) {
          this.aside.nodeKey.push(this.aside.treeData[0].children[0].id);
        }
        // 如果搜索了配送中心，默认展开三级
        if (this.queryForm.dcKeyWord && this.aside.treeData[0].children?.length > 0) {
          this.aside.treeData[0].children.forEach(item => {this.aside.nodeKey.push(item.id)});
        }
      } else {
        this.aside.nodeKey = [];
      }
    },
    firstPage() {
      this.queryForm.current = 1;
      this.queryPage();
    },
    resetQueryAll() {
      const queryForm = {
        current: 1,
        size: 10,
        orgCode: this.queryForm.orgCode,
        branchIds: undefined,
      };
      this.queryForm = Object.assign({}, queryForm);
      this.addOrgBranchInit = {};
      // 清除树节点选中状态
      this.lastClickNode = undefined;
      this.aside.nodeKey = [];
      this.queryForm.nodeSelectPositionId = undefined;
      this.$forceUpdate();
      this.queryAll();
    },
    handleNodeClick(data, node) {
      // 记录点击节点
      this.lastClickNode = data.id;
      this.parentTreeNodeList = [];
      this.getTreeParent(data, node);
      this.queryForm.nodeSelectPositionId = data.positionId;
      this.queryForm.branchIds = this.parentTreeNodeList.reverse();
      this.firstPage()

      // 设置新增用户默认所属组织
      this.addOrgBranchInit.orgCode = data.orgCode;
      this.addOrgBranchInit.positionId = data.positionId;
      this.addOrgBranchInit.parentId = data.parentId;
      this.addOrgBranchInit.principalId = data.principalId;
      if (data.dcCode !== 'ALL') {
        this.addOrgBranchInit.dcCode = data.dcCode;
        this.loadOrgBranchDictByParam({orgCode: data.orgCode,positionId: data.positionId, dcCode: data.dcCode})
      } else {
        this.loadOrgBranchDictByParam({orgCode: data.orgCode, positionId: data.positionId})
      }
      this.$forceUpdate()
    },
    handleEdit(row) {
      // 编辑组织用户
      if (row) {
        this.loadOrgBranchDictByParam(row);
      }
      // 新增组织用户
      else {
        const data = {
          orgCode: this.addOrgBranchInit.orgCode ? this.addOrgBranchInit.orgCode : this.defaultOrgCode,
          dcCode: this.addOrgBranchInit.positionId <= 2 ? 'ALL' : this.addOrgBranchInit.dcCode,
          positionId: this.addOrgBranchInit.positionId,
          userName: undefined,
          nickName: undefined,
          positionName: undefined,
          teamName: undefined,
          principal: 0,
          parentId: this.addOrgBranchInit.parentId,
          principalId: this.addOrgBranchInit.principalId,
          remark: undefined,
          parentName: undefined,
          parentPositionName: undefined
        };
        this.editDialog.form = deepClone(data);
        this.setEditPositionName();
        this.editableCheckIfExistMain()
      }
      this.buttonLoading = false;
      this.editDialog.visible = true;
    },
    loadOrgBranchDictByParam(row) {
      let globalLoading = Loading.service({
        lock: true,
        text: '加载中',
        background: 'rgb(255,255,255, 0.75)'
      });
      if (row.positionId > 3) {
        branchDict('branch-dc?query=' + row.orgCode).then(res => {
          this.branchDcOptions = res.data;
        })
      } else if (row.positionId === 3) {
        branchDict('all-dc?query=' + row.orgCode).then(res => {
          this.allDcOptions = res.data;
        })
      }
      if (row.positionId) {
        row.positionName = this.positionOptions.find(e => e.value === row.positionId).label;
      }
      if (row.positionId > 1) {
        let param = {
          positionId: row.positionId -1,
          dcCode: row.dcCode,
          orgCode: row.orgCode,
          principal: 1,
        };
        if (param.positionId < 3) {
          param.dcCode = 'ALL';
        }
        listBranch(param).then(res => {
          this.parentOptions = res.data;
        })
      }
      const resp = row.userName?row.userName: '';
      this.$nextTick(() => {
        this.getUser(resp, row);
      })
      this.editDialog.form = deepClone(row);
      // 编辑的时候不允许修改是否主要负责人
      this.editDialog.form.principal = row.principal === '是' ? 1 : 0;
      this.editDialog.principalDisabled = true;
      this.handleGetDeputy(row);
      this.$forceUpdate();
      globalLoading.close();
    },
    getUser(resp, row) {
      this.buttonLoading = true;
      if (this.$refs.user) {
        new Promise(async (resolve, reject) => {
          await this.$refs.user.remoteMethod(resp)
          resolve()
        }).then(() => {
          this.editDialog.form.userName = row.userName;
          this.editDialog.form.nickName = row.nickName;
          this.editDialog.form.teamName = row.teamName;
          this.buttonLoading = false;
          this.$forceUpdate();
        })
      } else {
        setTimeout(() => this.getUser(resp, row), 200)
      }
    },
    queryDcSuggestions(queryString, cb) {
      if (this.queryForm.orgCode) {
        let param = {
          orgCode: this.queryForm.orgCode,
          current: 1,
          size: 20
        };
        dictSelect("ht-dc", param).then(res => {
          let data = [];
          res.data.forEach(e => {
            e.value.indexOf(queryString)
            e.label.indexOf(queryString)

            if (!queryString
              || queryString === ''
              || e.value.indexOf(queryString) >= 0
              || e.label.indexOf(queryString) >= 0
            ) {
              data.push({value: e.value + '-' + e.label})
            }
          })
          cb(data);
        })
      }
    },
    handleDcSelect(node) {
      this.queryForm.dcKeyWord = node.value;
    },
    handleQueryChangeLinkageRef(ref, val, type, form, dict) {
      this.$nextTick(() => {
        ref.split(',').forEach(e => {
          if (this.$refs[e]) {
            if (form && dict) {
              this[dict] = Object.assign({}, form)
            } else {
              this.dictParam = Object.assign({}, this.queryForm)
            }
            setTimeout(() => {
              this.$refs[e].handleRefChange(val, type);
            }, 100)
            this.$forceUpdate();
          }
        })
      });
    },
    handleChangeLinkageRef(ref, val, type, form, dict) {
      this.$nextTick(() => {
        if (type === 'hand') {
          this.editDialog.form.dcCode = undefined;
          this.editDialog.form.positionId = undefined;
          // this.editDialog.form.userName = undefined;
          // this.editDialog.form.teamName = undefined;
          // this.editDialog.form.nickName = undefined;
          this.editDialog.form.positionName = undefined;
          this.editDialog.form.principal = 0;
          this.editDialog.form.parentId = undefined;
          this.editDialog.form.principalId = undefined;
          this.editDialog.form.remark = undefined;
          this.editDialog.form.parentName = undefined;
          this.editDialog.form.parentPositionName = undefined;
          this.checkIfExistMain(this.editDialog.form);
        }
      });
    },
    checkIfExistMain(row) {
      let param = deepClone(this.editDialog.form);
      param.principal = 1;
      checkMain(param).then(res => {
        let existMain = res.data;
        // 不存在主负责人，默认将当前设为主负责人且不可更改
        if (!existMain) {
          this.editDialog.form.principal = 1;
          this.editDialog.principalDisabled = true;
        }
        // 存在主负责人，默认设置当前为非主负责人，不可更改
        else {
          this.editDialog.form.principal = 0;
          this.editDialog.principalDisabled = true;
        }
        this.handleGetDeputy(row);
      })
    },
    editableCheckIfExistMain() {
      let checkMainParam = deepClone(this.editDialog.form);
      checkMainParam.principal = 1;
      checkMain(checkMainParam).then(res => {
        let existMain = res.data;
        this.editDialog.form.principal = existMain ? 0 : 1;
        if (this.editDialog.form.positionId === 3 || this.editDialog.form.positionId === 1) {
          this.editDialog.principalDisabled = true;
        } else {
          this.editDialog.principalDisabled = false;
        }
      })
    },
    handleAdd() {
      const row = {
        parentId: this.viewDialog.queryParam.parentId,
        positionId: this.viewDialog.queryParam.positionId,
        orgCode: this.viewDialog.queryParam.orgCode,
        dcCode: this.viewDialog.queryParam.dcCode,
        principalId: this.viewDialog.queryParam.principalId,
      }
      if (row.positionId) {
        row.positionName = this.positionOptions.find(e => e.value === row.positionId).label;
      }
      row.principal = 0;
      this.handleEdit(row);
      this.$forceUpdate();
    },
    handleGetDeputy(row) {
      this.editDialog.deputyList = [];
      if (!this.editDialog.form.principal
        && this.editDialog.form.positionId > 1
        && this.editDialog.form.positionId < 6
      ) {
        let param = {
          orgCode: this.editDialog.form.orgCode,
          dcCode: this.editDialog.form.dcCode,
          positionId: this.editDialog.form.positionId,
          principal: 1
        };
        listBranch(param).then(res => {
          let resList = res.data;
          // // 如果主要负责人设置自己为非负责人，则主要负责人列表中不能出现自己
          // if (this.editDialog.form.principal === 0) {
          //   resList.push(.filter(e => e.id !== this.editDialog.form.id));
          // }
          this.editDialog.deputyList = resList;
          if (resList && resList.length > 0) {
            if (row && row.principalId) {
              this.editDialog.form.principalId = row.principalId;
            } else {
              this.editDialog.form.principalId = resList[0].id;
            }
          }
          this.$forceUpdate();
        })
      }
      this.setEditPositionName();
    },
    changePosition(val, type) {
      this.editDialog.form.principalId = undefined;
      this.editDialog.form.parentId = undefined;
      let param = {
        positionId: this.editDialog.form.positionId - 1,
        dcCode: this.editDialog.form.dcCode,
        orgCode: this.editDialog.form.orgCode,
        principal: 1,
      };
      if (param.positionId < 3) {
        param.dcCode = 'ALL';
      }
      param.parentId = undefined;
      param.principalId = undefined;
      listBranch(param).then(res => {
        this.parentOptions = res.data;
        // 区域经理上级为配送中心主负责人
        if (this.editDialog.form.positionId === 4) {
          this.editDialog.form.parentId = this.parentOptions[0]?.id
        }
      })
      // 切换职位进行的操作
      if (type === 'position') {
        // 清空数据
        this.branchDcOptions = [];
        switch (val) {
          case 1:
          case 2:
            this.editDialog.form.dcCode = 'ALL';
            this.editDialog.form.parentId = undefined;
            this.editDialog.form.principal = 0;
            break;
          case 3:
            this.editDialog.form.dcCode = this.editDialog.form.dcCode === 'ALL' ? undefined : this.editDialog.form.dcCode;
            branchDict('all-dc?query=' + this.editDialog.form.orgCode).then(res => {
              this.allDcOptions = res.data;
            })
            break;
          case 4:
          case 5:
          case 6:
            this.editDialog.form.dcCode = this.editDialog.form.dcCode === 'ALL' ? undefined : this.editDialog.form.dcCode;
            branchDict('branch-dc?query=' + this.editDialog.form.orgCode).then(res => {
              this.branchDcOptions = res.data;
            })
            break;
        }
        // 设置岗位名称
        const name = this.positionOptions.find(e => e.value === val).label;
        this.editDialog.form.positionName = name
      }
      // 新增的用户在切换职级时可以修改是否主要负责人
      if (!this.editDialog.form.id) {
        this.editableCheckIfExistMain()
      }
      // 切换职位的时候刷新主要负责人列表
      this.handleGetDeputy();
    },
    changeUser(val, type) {
      this.$nextTick(() => {
        if (type === 'hand') {
          let user = this.$refs.user.itemOptions.find(e => e.userName === val);
          user = user? user: { nickName: '', userName: ''}
          this.editDialog.form.userName = user.userName;
          // this.$set(this.editDialog.form, 'userName', user.userName)
          this.editDialog.form.nickName = user.nickName;
          this.editDialog.form.teamName = user.nickName + '团队';
          this.setTeamNameIfExist();
          this.$forceUpdate();
        }
      });
    },
    handleSave() {
      if (!this.editDialog.form.userName) {
        Notification.warning("关联用户不能为空");
        return;
      }
      if (!this.editDialog.form.orgCode) {
        Notification.warning("分公司不能为空");
        return;
      }
      if (!this.editDialog.form.dcCode) {
        Notification.warning("配送中心不能为空");
      }
      if (!this.editDialog.form.positionId) {
        Notification.warning("岗位不能为空");
        return;
      }
      if (!this.editDialog.form.positionName) {
        Notification.warning("岗位名称不能为空");
        return;
      }
      if (!this.editDialog.form.teamName) {
        Notification.warning("团队名称不能为空");
        return;
      }
      if (!this.editDialog.form.positionId > 1 && !this.editDialog.form.parentId) {
        Notification.warning("上级不能为空");
        return;
      }
      if (this.editDialog.form.positionId === 3) {
        if (this.editDialog.form.principal === 0 && !this.editDialog.form.principalId) {
          Notification.warning("主要负责人不能为空");
          return;
        }
      }
      if (this.editDialog.form.positionId === 6) {
        this.editDialog.form.principal = 1;
      }
      //定义loading
      let globalLoading = Loading.service({
        lock: true,
        text: '加载中',
        background: 'rgb(255,255,255, 0.75)'
      })
      this.buttonLoading = true;
      const param = deepClone(this.editDialog.form);
      saveOrgBranch(param).then(res => {
        if (res.code === 200) {
          Notification.success("操作成功！");
          this.queryAll();
          this.editDialog.visible =false;
          this.$refs.editFormRef.resetFields();
        } else {
          Notification.error(res.msg);
        }
        this.buttonLoading = false;
        globalLoading.close()
      })
    },
    handleDelRow(row) {
      this.$confirm('请确认是否删除所选的数据?', '提示', { type: 'warning' }).then(() => {
        //定义loading
        let globalLoading = Loading.service({
          lock: true,
          text: '加载中',
          background: 'rgb(255,255,255, 0.75)'
        })
        delBranch(row.id).then(res => {
          if (res.code ===200) {
            Notification.success("操作成功！");
          } else {
            Notification.error(res.msg);
          }
          globalLoading.close();
          // 删除主要负责人，设置缓存节点为上一级
          if (row.id === this.lastClickNode) {
            this.clearTreeClickNode(row);
          }
          this.queryAll();
        })
      }).catch(() => {
      })
    },
    handleSelectionChange(selections) {
      this.pageData.selections = selections;
    },
    handleBatchDelete() {
      if (!this.pageData.selections || this.pageData.selections.length <= 0) {
        Notification.warning('请先选中要批量删除的用户！');
        return;
      }
      this.$confirm('请确认是否删除所选的数据?', '提示', { type: 'warning' }).then(() => {
        //定义loading
        let globalLoading = Loading.service({
          lock: true,
          text: '加载中',
          background: 'rgb(255,255,255, 0.75)'
        })
        let data = [];
        this.pageData.selections.forEach(selection => {
          if (selection.id) {
            data.push(selection.id);
          }
        });
        batchDelBranch(data).then(res => {
          globalLoading.close();
          if (res.code ===200) {
            Notification.success("操作成功！");
            // 删除主要负责人，设置缓存节点为上一级
            console.log(data.includes(this.lastClickNode))
            if (data.includes(this.lastClickNode)) {
              let row = this.pageData.selections.find(e => e.id === this.lastClickNode)
              console.log(row)
              this.clearTreeClickNode(row);
            }
            this.queryAll();
          }
        })
      }).catch(() => {
      })
    },

    handleChangeMain(row) {
      let globalLoading = Loading.service({
        lock: true,
        text: '加载中',
        background: 'rgb(255,255,255, 0.75)'
      })
      let param = Object.assign({}, row);
      param.principal = 1;
      changeMain(param).then((res) => {
        if (res.code === 200) {
          Notification.success("操作成功！");
          if (row.positionId === 1) {
            this.resetQueryAll();
          } else {
            this.clearTreeClickNode(row);
          }
          this.queryAll();
          globalLoading.close();
        } else {
          Notification.error(res.msg);
        }
      })
    },
    handleImport() {
      this.importDialog.fileList = [];
      if (this.$refs.upload) {
        this.$nextTick(() => {
          this.$refs.upload.clearFiles();
        })
      }
      this.importDialog.type='add'
      this.importDialog.visible = true;
      this.submitDisabled = false;
    },
    handleFileChange(file, fileList, type) {
      if (type === 'remove') {
        this.importDialog.fileList = fileList;
        return false;
      }
      const suffix =
        file.name.indexOf(".") > 0
          ? file.name.substring(file.name.lastIndexOf("."))
          : undefined;
      if (this.importDialog.accept.indexOf(suffix) < 0) {
        this.$message.error("文件格式不支持");
        this.importDialog.fileList = [];
      } else {
        this.importDialog.fileList = fileList;
      }
    },
    submitImport() {
      if (this.importDialog.fileList.length === 0) {
        Notification.warning('请先上传文件！');
        return false;
      }
      let globalLoading = Loading.service({
        lock: true,
        text: '加载中',
        background: 'rgb(255,255,255, 0.75)'
      })
      let fd = new FormData();
      this.importDialog.fileList.forEach(item=>{
        //文件信息中raw才是真的文件
        fd.append("file",item.raw);
      })
      this.submitDisabled = true;
      importBranchV2(this.importDialog.type, fd).then((res) => {
        let errList = res.data.filter(e => e.errorMsg)
        if (errList.length > 0) {
          Notification.error("导入失败，存在错误数据，正在导出，请稍候！");
          this.downloadImportResult(res.data)
        } else {
          Notification.success("操作成功,共" + res.data.length+"条!");
          this.importDialog.visible = false;
          this.firstPage();
        }
        this.submitDisabled = false;
        this.loading = false;
        this.$refs.upload.clearFiles();
        globalLoading.close()
      })
    },
    downloadImportResult(data) {
      let title = '分公司组织导入校验结果';
      let headers = this.exportHeaders;
      excelExport({
        topHeaders: headers,
        data: data,
        title: title,
      });
    },
    handleExport() {
      this.loading = true;
      const param = Object.assign({}, this.queryForm);
      exportBranchV2(param).then(res => {
        this.loading = false;
        if (!res) {
          return
        }
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = window.URL.createObjectURL(new Blob([res]))
        link.setAttribute('download', '分公司组织导出.xlsx')
        document.body.appendChild(link)
        link.click()
        link.remove()
      })
    },
    dragControllerDiv() {
      let left = document.getElementById('left-tree')
      let line = document.getElementById('resize')
      let right = document.getElementById('right-content')
      // 鼠标按下事件
      line.onmousedown = function (e) {
        let startX = e.clientX
        line.left = line.offsetLeft
        // 鼠标拖动事件
        document.onmousemove = function (e) {
          let moveLen = line.left + (e.clientX - startX)
          if (
            moveLen >= document.body.clientWidth * 0.1 &&
            moveLen <= document.body.clientWidth * 0.4
          ) {
            line.style.left = moveLen + 'px'
            left.style.width = moveLen + 'px'
            right.style.width = document.body.clientWidth + 'px'
          }
        }
        document.onmouseup = function () {
          document.onmousemove = null
          document.onmouseup = null
        }
      }
    },
    getTreeParent(data, node) {
      if (data.positionId === 1){
        this.parentTreeNodeList.push(data.id);
        return;
      }else if (data.positionId === 2) {
        this.parentTreeNodeList.push(data.id);
        this.parentTreeNodeList.push(data.parentId);
        return;
      } else {
        this.parentTreeNodeList.push(data.id);
        if (node.parent != null) {
          this.getTreeParent(node.parent.data, node.parent);
        }
      }
    },
    setTeamNameIfExist() {
      let nickName = this.editDialog.form.nickName;
      if (nickName && nickName !== '' && this.editDialog.form.orgCode) {
        const data = {
          orgCode: this.editDialog.form.orgCode,
          allName: '%'+nickName+'%',
        }
        listBranch(data).then(res => {
          if (res.data && res.data.length > 0) {
            this.editDialog.form.teamName = res.data[0].teamName;
            this.$forceUpdate();
          }
        })
      }
    },
    setEditPositionName() {
      if (this.editDialog.form.positionId) {
        if (this.editDialog.form.positionId === 1) {
          this.editDialog.form.positionName = this.editDialog.form.principal === 1 ? '总经理' : '副总经理';
        } else if (this.editDialog.form.positionId === 2) {
          this.editDialog.form.positionName = '大区经理';
        } else {
          this.editDialog.form.positionName = this.positionOptions.find(e => e.value === this.editDialog.form.positionId).label;
        }
      }
    },
    allowDrop(draggingNode, dropNode, type) {
      // 只能放入当前节点下的顺序
      return draggingNode.data.positionId === 3
        && dropNode.data.positionId === 3
        && dropNode.data.parentId === draggingNode.data.parentId
        && (type === 'next' || type === 'prev');
    },
    allowDrag(draggingNode) {
      // 过滤后的树形结构不允许拖动
      return draggingNode.data.positionId === 3 && this.draggable
    },
    handleDrop(draggingNode, dropNode, dropType) {
      if (dropType === 'before' || dropType === 'after') {
        let param = [];
        let list = dropNode.parent.childNodes;
        for (let i = 0; i < list.length; i++) {
          param.push({id: list[i].data.id, sort: i + 1})
        }
        saveTreeSort(param).then(res => {
          if (res.code !== 200){
            this.$message.success('移动位置失败');
          }
        })
      }
    },
    clearTreeClickNode(row) {
      if (!row) return;
      this.lastClickNode = undefined;
      this.lastClickNode = row.parentId ? row.parentId : undefined;
      this.queryForm.branchIds?.pop();
      this.queryForm.nodeSelectPositionId = this.queryForm.nodeSelectPositionId > 1 ? this.queryForm.nodeSelectPositionId - 1 : this.queryForm.nodeSelectPositionId;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding-top: 10px;
  padding-bottom: 5px;
  overflow: visible
}

::v-deep .rowClassName {
  cursor: pointer !important;
}

::v-deep .el-table__header .el-table-column--selection .cell .el-checkbox:after {
  content: "本页全选";
  color: black;
  font-weight: bold;
  font-size: 12px;
  margin-left: 5px;
}

</style>

<style lang="scss">
::v-deep .columnClass {
  color: #0040ff !important;
  vertical-align: middle;
}
.aside-option {
  width: 400px;
  margin: 20px 0;
  //height: calc(100vh);
  background-color: #fff;
  font-weight: normal!important;
}
.el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content {
  background-color: #409EFF !important;
  color: rgb(255, 255, 255);
}
.positionColor {
  color: #909399;
}
.exist_current {
  color: #f56c6c
}
.el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content>.custom-tree-node>.exist_current {
  background-color: #409EFF !important;
  color: rgb(255, 255, 255);
}
.el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content>.custom-tree-node>.positionColor {
  background-color: #409EFF !important;
  color: rgb(255, 255, 255);
}
.unselect {
  -webkit-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -ms-user-select: none;

  /* 以下两个属性目前并未支持，写在这里为了减少风险 */
  -o-user-select: none;
  user-select: none;
}
.contnet {
  display: flex;
}
.left-tree {
  position: relative;
  width: 250px;
  height: 100%; // 如果存在box-sizing，此处设置就会用会影响，可以先去掉看看效果
  background: #fff;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.050980392156862744);
  border-radius: 4px;
  vertical-align: top;
  display: inline-block;
  box-sizing: border-box;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding: 10px 0 0 10px;
  margin-right: 8px;
}
.resize {
  cursor: col-resize;
  position: absolute;
  top: 45%;
  right: -8px;
  background-color: #d6d6d6;
  border-radius: 5px;
  margin-top: -10px;
  width: 8px;
  height: 40px;
  background-size: cover;
  background-position: 50%;
}
.right-content {
  display: inline-block;
  width: 100%;
  height: 100%;
  background: #fff;
  -webkit-box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.050980392156862744);
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.050980392156862744);
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 10px;
  vertical-align: top;
  overflow: auto;
}
</style>
