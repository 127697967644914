<template>
  <div  v-loading="homeLoading" class="new-box">
    <div class="mg-search" >
        <el-card style="margin: 10px;">
            <SearchFilter  @search="handleFilter"  @reset="resetQuery" @keyup.enter.native="handleQuery">
      <!-- <el-form ref="queryFormRef" size="mini" label-width="auto" :inline="true" :model="queryForm" @keyup.enter.native="handleQuery"> -->
        <el-form-item label="分公司">
          <DictSelect
              ref="org"
              type="ht-org"
              :value.sync="queryForm.orgCode"
              fullLabel
              :init-options="options.orgOptions"
              init
              :clearable="false"
              @change="handleOrgChange"
          />
        </el-form-item>
        <el-form-item label="配送中心" size="mini">
          <DictSelect ref="orgUpdate" :value.sync="queryForm.queryDc" fullLabel type="ht-dc-all" :init-options="options.dcAllOptions" init />
        </el-form-item>
        <el-form-item label="展示时间" prop="startTime">
          <el-date-picker
            v-model="queryForm.queryMth"
            type="month"
            @change="val => handleDateChange(val, 'query')"
            format="yyyy-MM"
            :default-time="['00:00:00', '23:59:59']"
            :clearable="false"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="名称">
          <el-input v-model="queryForm.backgroundName" placeholder="请输入后台名称" clearable />
        </el-form-item>
        <!-- <el-form-item>
          <el-button class="form-btn-item" size="mini" icon="el-icon-search" type="primary" @click="handleFilter">查询</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-plus" type="primary" @click="handleEdit()">新增</el-button>
          <el-button class="form-btn-item" :disabled="pageData.rows.length === 0" size="mini" icon="el-icon-check" type="primary" @click="handleTargetSort()">保存排序</el-button>
          <el-button class="form-btn-item" size="mini" type="warning" @click="() => {
            userTagDrawer.visible = true;
          }">人员角色配置</el-button>
          <el-button size="mini" type="primary" style="border: 0px; " @click="() => {
            mthSummaryDrawer.visible = true;
          }">查看月度成绩</el-button>
        </el-form-item> -->
      <!-- </el-form> -->
    </SearchFilter>
    </el-card>
    </div>
    <el-card style="margin: 10px;" class="box-card">
        <el-button class="form-btn-item" size="mini" icon="el-icon-plus" type="primary" @click="handleEdit(undefined, 'add')">新增</el-button>
          <el-button class="form-btn-item" :disabled="pageData.rows.length === 0" size="mini" icon="el-icon-check" type="primary" @click="handleTargetSort()">保存排序</el-button>
          <el-button class="form-btn-item" size="mini" type="warning" @click="() => {
            userTagDrawer.visible = true;
          }">人员角色配置</el-button>
          <el-button size="mini" type="primary" style="border: 0px; " @click="() => {
            mthSummaryDrawer.visible = true;
          }">查看月度成绩</el-button>
    <el-table
      style="margin-top: 10px;"
      :data="pageData.rows"
           size="small"
           class="customer-table"
           border
      v-loading="loading"
      highlight-current-row
    >
      <el-table-column type="index" />
      <el-table-column label="分公司编码-名称" prop="orgCode" >
        <template #default="{ row }">
          <span>{{ row.orgCode + "-" + row.orgName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="名称" prop="backgroundName"  />
      <el-table-column label="月份" prop="startTime" >
        <template #default="{ row }">
          <span>{{ new Date(row.startTime).getMonth() + 1 }}</span>月
        </template>
      </el-table-column>
      <el-table-column label="创建时间" prop="createTime"  />
      <el-table-column label="操作" width="250" align="center">
        <template #default="scope">
          <el-row>
            <el-button size="mini" type="text" style="border: 0px; " v-if="new Date(scope.row.endTime).getTime() >= new Date().getTime()" @click="handleEdit(scope.row, 'edit')">编辑配置</el-button>
            <el-button size="mini" type="text" style="border: 0px; " v-if="new Date(scope.row.endTime).getTime() < new Date().getTime()" @click="handleEdit(scope.row, 'view')">查看配置</el-button>
            <el-button size="mini" type="text" style="border: 0px; " @click="handleViewScope(scope.row)">查看成绩</el-button>
            <el-button size="mini" type="text" style="border: 0px; " @click="handleRemove(scope.row)">删除</el-button>
            <el-button type="text" size="mini" icon="el-icon-upload2" :disabled="scope.$index === 0" @click="handleMove('up', scope.$index)" />
            <el-button type="text" size="mini" icon="el-icon-download" :disabled="scope.$index === pageData.rows.length - 1" @click="handleMove('down', scope.$index)" />
            <el-button size="mini" type="text" style="border: 0px; " @click="handleCopyTargetDialog(scope.row)">复制</el-button>
          </el-row>
        </template>
      </el-table-column>
    </el-table>
  </el-card>

    <el-drawer title="绩效设置" :visible.sync="targetDrawer.visible" size="100%" direction="rtl" append-to-body :modal-append-to-body="false" :wrapperClosable="false">
      <el-form v-loading="targetDrawer.pageLoading" ref="jxFormRef" size="mini" label-width="auto" :inline="true" :model="targetDrawer.form" :rules="targetDrawer.rules">
        <el-row>
          <el-row>
            <el-col :span="4">
              <el-form-item label="分公司" size="mini" prop="orgCode">
                <DictSelect ref="orgUpdate" :value.sync="targetDrawer.form.orgCode" fullLabel type="ht-org" :clearable="false" :init-options="options.orgOptions"
                            init disabled @change="handleOrgChange" />
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="配送中心" size="mini" prop="dcCodes">
                <DictSelect ref="orgUpdate" :value.sync="targetDrawer.form.dcCodes" fullLabel type="ht-dc-all" multiple :init-options="options.dcAllOptions" init />
              </el-form-item>
            </el-col>
          </el-row>
          <el-col :span="3">
            <el-form-item label="得分类型" size="mini">
              <el-radio-group v-model="targetDrawer.form.scoreType">
                <el-radio-button :label="2">金额</el-radio-button>
                <el-radio-button :label="1">分数</el-radio-button>
              </el-radio-group>
            </el-form-item>
           </el-col>
          <el-col :span="6">
            <el-form-item label="计算类型" size="mini">
              <el-radio-group v-model="targetDrawer.form.computeType" @input="handleComputeChange">
                <el-radio-button :label="1">梯度</el-radio-button>
                <el-radio-button :label="2">百分比</el-radio-button>
                <el-radio-button :label="5">百分比(梯度系数)</el-radio-button>
                <el-radio-button :label="4">负向奖励</el-radio-button>
              </el-radio-group>
            </el-form-item>
           </el-col>
          <el-col :span="6">
            <el-form-item label="计算条件" size="mini">
              <el-radio-group v-model="targetDrawer.form.condition" @input="handleConditionChange">
                <el-radio-button label="1">单项计算</el-radio-button>
                <el-radio-button :disabled="targetDrawer.form.computeType===4" label="2">混合指标-且</el-radio-button>
                <el-radio-button :disabled="targetDrawer.form.computeType===4" label="3">混合指标-或</el-radio-button>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item label="App显示名称" size="mini" prop="targetName">
              <el-input v-model="targetDrawer.form.targetName" clearable placeholder="聚宝盆App上显示" />
            </el-form-item>
            <el-form-item label="后台名称" size="mini" prop="backgroundName">
              <el-input v-model="targetDrawer.form.backgroundName" clearable placeholder="仅供后台区分" />
            </el-form-item>
            <el-form-item label="展示时间" prop="startTime">
              <el-date-picker
                v-model="targetDrawer.form.showDate"
                type="month"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                @change="val => handleDateChange(val)"
                format="yyyy-MM"
                :default-time="['00:00:00', '23:59:59']"
              ></el-date-picker>
            </el-form-item>
           </el-col>
          <el-col v-if="targetDrawer.form.computeType === 2 && targetDrawer.form.condition === '2'" :span="6">
            <el-form-item label="得分设置" size="mini">
              <el-select v-model="targetDrawer.form.sumMode">
                <el-option label="都达成后按最小单项计算得分（当前）" :value="1" />
                <el-option label="都达成后按最大单项计算得分" :value="2" />
                <el-option label="都达成后，按每项的达成百分比相乘计算得分（每项最高限制为100%）" :value="3" />
                <el-option label="都达成后，按每项的达成百分比相乘计算得分（单项可超过100%，但总分不会超过满分）" :value="4" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item label="指标数据" size="mini" prop="quotaIds">
              <el-button class="form-btn-item" size="mini" icon="el-icon-plus" type="primary" @click="handleAddQuota">添加</el-button>
              <el-row v-for="(item,index) in targetDrawer.form.quotaIds" :key="index" style="width: 500px">
                <el-col :span="12">
                  <el-tag :type="getQuotaTagType(targetDrawer.form.quotaIds, item)" size="small" closable @close="handleQuotaChange(item, 'remove', index)"> {{ getQuotaInfo(item).quotaName }} </el-tag>
                  <el-popover
                    placement="top-start"
                    width="300"
                    trigger="hover"
                    :content="getQuotaInfo(item).remark">
                    <i slot="reference" v-if="getQuotaInfo(item).remark" class="el-icon-question" />
                  </el-popover>
                </el-col>
                <el-col :span="4">
                  <el-button :disabled="getQuotaInfo(item).limits.length === 0" class="form-btn-item" size="mini" type="text" plain @click="handleSetQuotaLimit(item)">设置</el-button>
                  <el-button :disabled="index===0 || quotaMoving" type="text" style="border: 0px;" icon="el-icon-arrow-up" @click="handleMoveQuota(item, index, 'up')" />
                  <el-button :disabled="index==targetDrawer.form.quotaIds.length-1 || quotaMoving" type="text" style="border: 0px;" icon="el-icon-arrow-down" @click="handleMoveQuota(item, index, 'down')" />
                </el-col>
              </el-row>
            </el-form-item>
          </el-col>
          <el-col v-if="validateQuota('任务') || validateQuota('238盘点')">
            <el-form-item label="任务" size="mini" prop="mtTaskId">
              <el-select v-model="targetDrawer.form.mtTaskId" filterable default-first-option clearable @change="handleTaskChange">
                <el-option v-for="item in options.taskOptions" :key="item.mtTaskId" :value="item.mtTaskId" :label="'(' + item.orgName + ')'+ item.taskName" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col v-if="targetDrawer.form.quotaIds && targetDrawer.form.quotaIds.length>0" style="margin-bottom: 10px">
            <el-button class="form-btn-item" size="mini" icon="el-icon-plus" type="primary" @click="handleAddQuotaUser">添加用户</el-button>
            <el-button class="form-btn-item" size="mini" icon="el-icon-upload" type="primary" @click="handleImport(undefined)">导入用户</el-button>
            <el-button class="form-btn-item" size="mini" icon="el-icon-upload" type="primary" @click="handleImport('achieve')">导入用户达成数据</el-button>
            <el-button class="form-btn-item" size="mini" icon="el-icon-download" type="primary" @click="handleExport('achieve')">导出用户达成数据</el-button>
            <el-button class="form-btn-item" size="mini" icon="el-icon-upload" type="primary" @click="handleImport('reward')">导入用户结果数据</el-button>
            <el-button class="form-btn-item" size="mini" icon="el-icon-download" type="primary" @click="handleExport('reward')">导出用户结果数据</el-button>
          </el-col>
          <el-col v-if="targetDrawer.form.quotaIds && targetDrawer.form.quotaIds.length>0">
            <div>
                <el-table
                  ref="userTableRef"
                  :data="targetDrawer.form.userData"
                  max-height="500px"
                  size="mini"
                  highlight-current-row
                  :header-cell-style="{ background: 'rgb(235 235 235)', color: 'black',textAlign: 'center', verticalAlign: 'middle' }"
                  :span-method="objectSpanMethod"
                  border
                  v-if="showTable"
                >
                  <el-table-column label="账号" prop="userName" width="100" align="center"/>
                  <el-table-column label="姓名" prop="nickName" width="100" align="center" />
                  <el-table-column type="index" label="序号" width="50" />
                  <el-table-column v-if="validateDcQuota()" label="配送中心" prop="dcCode" width="350" align="center">
                    <template slot-scope="scope">
                      <el-select v-if="getQuotaInfo(scope.row.jxQuotaId).ifAvg === 0" v-model="scope.row.shopRange" filterable size="mini" :disabled="getQuotaInfo(scope.row.jxQuotaId).ifRepeat === 0" placeholder="请选择" style="width:100px" @change="val => handleShopRangeChange(val, scope.row, scope.$index)">
                        <el-option v-for="item in options.shopRangeOptions" :disabled="item.value === '1' && getQuotaInfo(scope.row.jxQuotaId).quotaType === '伙伴业绩'" :key="item.value" :label="item.label" :value="item.value" />
                        <el-option v-if="validateMultipleDcQuota(scope.row.jxQuotaId)" label="组织结构下的店铺范围" value="3">
                          <span>组织结构下的店铺范围</span>
                          <el-popover
                            placement="top-start"
                            width="300"
                            trigger="hover"
                            content="指分公司组织下对应的区域范围（一级总经理、二级大区经理、三级配送中心责任人是整个配送中心范围，四五级是下属的包干汇总，六级业务员是自己的包干店铺">
                            <i slot="reference" class="el-icon-question" />
                          </el-popover>
                        </el-option>
                      </el-select>
                      <el-select v-if="scope.row.shopRange === '2' || getQuotaInfo(scope.row.jxQuotaId).ifAvg === 1" size="mini" v-model="scope.row.dcCode" filterable
                                 placeholder="请选择配送中心" style="width:150px" :multiple="validateMultipleDcQuota(scope.row.jxQuotaId)" @change="() => {
                        $refs.userTableRef.doLayout();
                        $forceUpdate()
                      }">
                        <el-option v-for="item in options.dcOptions" :key="item.value" :label="item.value + '-' +item.label + (userManageDc[scope.row.userName] && userManageDc[scope.row.userName].indexOf(item.value)>-1 ? '(管辖)': '') " :value="item.value" />
                      </el-select>

                      <el-select v-if="getQuotaInfo(scope.row.jxQuotaId).ifAvg === 1 && scope.row.dcCode" v-model="scope.row.userRange" size="mini" placeholder="请选" style="width:120px" @change="() => {
                        $refs.userTableRef.doLayout();
                        $forceUpdate()
                      }">
                        <el-option v-for="item in options.userRangeOptions" :key="item.value" :label="item.label" :value="item.value">
                          <span>{{ item.label }}</span>
                          <el-popover
                            placement="top-start"
                            width="300"
                            trigger="hover"
                            :content="item.remark">
                            <i slot="reference" v-if="item.remark" class="el-icon-question" />
                          </el-popover>
                        </el-option>
                      </el-select>
                      <el-button v-if="getQuotaInfo(scope.row.jxQuotaId).ifAvg === 1 && scope.row.userRange==='2' && scope.row.dcCode && scope.row.dcCode.length > 0" size="mini" type="text" plain
                                 @click="handleSetDcUser(scope.$index, scope.row.dcCode, scope.row.avgUsers)">添加
                        <span>{{ scope.row.avgUsers ? scope.row.avgUsers.length: 0 }}</span>
                      </el-button>
                    </template>
                  </el-table-column>
                  <el-table-column label="指标" prop="quotaName" width="400" align="center">
                    <template #default="{ row }">
                      <span>{{ getQuotaInfo(row.jxQuotaId).quotaName }}</span>
                      <el-select v-if="options.boostQuotas.indexOf(getQuotaInfo(row.jxQuotaId).quotaCode) > -1" size="mini" collapse-tags v-model="row.boostId" multiple
                                 filterable default-first-option placeholder="关联激励 必填" style="width:120px" @change="handleConsole">
                        <el-option v-for="item in options.boosts" :key="item.id" :value="item.id" :label="item.name" />
                      </el-select>
                      <el-input v-if="options.boostQuotas.indexOf(getQuotaInfo(row.jxQuotaId).quotaCode) > -1" v-model="row.boostShortName" size="mini" placeholder="简称 " style="width:100px" @input="handleConsole" />
                    </template>
                  </el-table-column>
                  <el-table-column label="目标" prop="target" width="150" align="center">
                    <template #default="{ row }">
                      <div v-for="(item, index) in row.rules" :key="index">
                        <span v-if="targetDrawer.form.computeType===1"> ≥</span>
                        <el-input-number v-model="item.numA" :controls="false" size="mini" style="width:80px" placeholder="目标 必填" />
                        <span>{{ getQuotaInfo(item.jxQuotaId).unit }}</span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column :label="targetDrawer.form.scoreType ===1?'满分':'满分金额'" prop="boost" width="300" align="center">
                    <template #default="{ row }">
                      <div v-if="targetDrawer.form.computeType===4">
                        <div v-for="(item, index) in row.rules" :key="index">
                          <el-row>
                            达成奖励
                            <el-input-number v-if="targetDrawer.form.scoreType ===1" v-model="item.score" :controls="false" style="width:100px" size="mini" placeholder="满分 必填" />
                            <el-input-number v-if="targetDrawer.form.scoreType ===2" v-model="item.money" :controls="false" style="width:100px" size="mini" placeholder="金额 必填" />
                            <span style="margin-right: 10px">{{ targetDrawer.form.scoreType ===2? '元': '分' }}</span>
                          </el-row>
                          <el-row>
                            每不达成 <el-input-number v-model="item.perNum" :controls="false" style="width:80px" size="mini" placeholder=" 必填" />
                            <span style="margin-right: 10px">{{ getQuotaInfo(item.jxQuotaId).unit }}</span>
                            扣<el-input-number v-model="item.perBoostNum" :controls="false" style="width:80px" size="mini" placeholder=" 必填" />
                            <span style="margin-right: 10px">{{ targetDrawer.form.scoreType ===2? '元': '分' }}</span>
                          </el-row>
                          <el-row>
                            最多扣减 <el-input-number v-model="item.maxNum" :controls="false" style="width:100px" size="mini" placeholder=" 必填" />
                            <span style="margin-right: 10px">{{ targetDrawer.form.scoreType ===2? '元': '分' }}</span>
                          </el-row>
                        </div>
                      </div>
                      <div v-else-if="targetDrawer.form.condition !== '1'">
                        <el-input-number v-if="targetDrawer.form.scoreType ===1" v-model="row.boostNum" :controls="false" style="width:100px" size="mini" placeholder="满分 必填" />
                        <el-input-number v-if="targetDrawer.form.scoreType ===2" v-model="row.boostNum" :controls="false" style="width:100px" size="mini" placeholder="金额 必填" />
                        <span style="margin-right: 10px">{{ targetDrawer.form.scoreType ===2? '元': '分' }}</span>
                      </div>
                      <div v-else>
                        <div v-for="(item, index) in row.rules" :key="index">
                          <el-input-number v-if="targetDrawer.form.scoreType ===1" v-model="item.score" :controls="false" style="width:100px" size="mini" placeholder="满分 必填" />
                          <el-input-number v-if="targetDrawer.form.scoreType ===2" v-model="item.money" :controls="false" style="width:100px" size="mini" placeholder="金额 必填" />
                          <span style="margin-right: 10px">{{ targetDrawer.form.scoreType ===2? '元': '分' }}</span>
                          <el-button :disabled="targetDrawer.form.computeType === 2" size="mini" type="text" plain icon="el-icon-circle-plus-outline" @click="() => {
                            let cloneRule = deepClone(defaultRule);
                            cloneRule.jxQuotaId = item.jxQuotaId;
                            row.rules.push(cloneRule)
                          }" />
                          <el-button :disabled="row.rules.length == 1" size="mini" type="text" plain icon="el-icon-remove-outline" @click="() => {
                            row.rules.splice(index, 1)
                          }" />
                        </div>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column v-if="(targetDrawer.form.computeType === 2 && targetDrawer.form.condition === '1') || targetDrawer.form.computeType === 5" :label="'最大' + (targetDrawer.form.scoreType ===1?'得分':'金额')"
                                   prop="limitReward" width="120" align="center">
                    <template slot="header" slot-scope="scope">
                      <span>{{ '最大' + (targetDrawer.form.scoreType ===1?'得分':'金额') }}</span>
                      <el-popover
                        placement="top-start"
                        width="300"
                        trigger="hover"
                        content="指完成率超过100%时，最终得分是否可超过满分/满分金额。 不可超过则配与满分/满分金额相同数字，可超过则配更大数字">
                        <i slot="reference" class="el-icon-question" />
                      </el-popover>
                    </template>
                    <template #default="{ row }">
                      <el-input-number v-model="row.limitReward" :controls="false" size="mini" style="width:80px" placeholder="最大奖励" />
                    </template>
                  </el-table-column>
                  <el-table-column v-if="targetDrawer.form.computeType === 5" label="梯度系数">
                    <el-table-column :label="(targetDrawer.form.scoreType ===1?'得分':'金额') + '占比'" prop="boost" width="120" align="center">
                      <template #default="{ row }">
                        <div v-for="(item, index) in row.coefficients" :key="index">
                          <span> ≥</span>
                          <el-input-number v-model="item.numA" :controls="false" size="mini" style="width:80px" placeholder="占比 如0.5" />
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column :label="'最终' + (targetDrawer.form.scoreType ===1?'得分':'金额') + '系数'" prop="boost" width="180" align="center">
                      <template #default="{ row }">
                        <div v-for="(item, index) in row.coefficients" :key="index">
                          <el-input-number v-model="item.coefficient" :controls="false" size="mini" style="width:80px" placeholder="系数 如0.5" />
                          <el-button size="mini" type="text" plain icon="el-icon-circle-plus-outline" @click="() => {
                          row.coefficients.push(deepClone(defaultCoefficient))
                        }" />
                          <el-button :disabled="row.coefficients.length == 1" size="mini" type="text" plain icon="el-icon-remove-outline" @click="() => {
                          row.coefficients.splice(index, 1)
                        }" />
                        </div>
                      </template>
                    </el-table-column>
                  </el-table-column>
                  <el-table-column label="计算类型" prop="boost" width="110" align="center">
                    <template #default="{ row }">
                      <el-select v-model="row.importType" filterable placeholder="数据源" size="mini" style="width:80px">
                        <el-option v-for="item in getImportTypeOptions(row.jxQuotaId)" :key="item.value" :value="item.value" :label="item.label" />
                      </el-select>
                    </template>
                  </el-table-column>
                  <el-table-column label="导入的达成数据" prop="achieveNum" width="110" align="center">
                    <template slot-scope="scope">
                      <el-input v-if="scope.row.importType === 1" v-model="scope.row.achieveNum" :disabled="scope.row.importType !== 1" size="mini" clearable placeholder="数据源" @input="() => $forceUpdate()" />
                    </template>
                  </el-table-column>
                  <el-table-column label="导入的结果数据" prop="rewardNum" width="110" align="center">
                    <template slot-scope="scope">
                      <el-input v-if="scope.row.importType === 2" v-model="scope.row.rewardNum" :disabled="scope.row.importType !== 2" size="mini" clearable placeholder="结果数据" @input="() => $forceUpdate()"  />
                    </template>
                  </el-table-column>
                  <el-table-column label="操作" width="200" prop="action" align="center" fixed="right">
                    <template slot-scope="scope">
                      <el-row>
                        <el-col :span="2">
                          <el-button size="mini" type="text" plain icon="el-icon-remove-outline" @click="() => {targetDrawer.form.userData.splice(scope.$index, 1); resetPageData()}" />
                        </el-col>
                        <el-col :span="16" style="margin-left:10px;">
                          <el-button size="mini" type="text" plain @click="handleUserCopy(scope.row, scope.$index)">一键同步至其他用户</el-button>
                        </el-col>
                      </el-row>
                    </template>
                  </el-table-column>
                </el-table>
          </div>
          </el-col>
        </el-row>
      </el-form>
      <div style="text-align: right;position: absolute; bottom: 100px; right: 100px; z-index: 999">
        <el-button size="mini" :disabled="targetDrawer.buttonLoading" @click="targetDrawer.visible = false">取 消</el-button>
        <el-button v-if="targetDrawer.type !== 'view'" :disabled="targetDrawer.buttonLoading" size="mini" type="primary" @click="submitForm()">确 定</el-button>
      </div>
    </el-drawer>

    <el-dialog title="添加用户" :visible.sync="userDialog.visible" append-to-body width="450px" :close-on-click-modal="false">
      <h4>需先在人员角色配置页面先配置相关人员数据</h4>
      <div style="height: 500px">
        <el-input placeholder="输入关键字进行过滤" v-model="userDialog.filterText" size="mini" clearable @input="handleFilterChange" />
        <el-button size="mini" type="primary" @click="handleTreeCheckedAll">全 选</el-button>
        <el-button size="mini" type="primary" @click="$refs.userTagTreeRef.setCheckedKeys([]);">清 空</el-button>
        <el-tree
          v-if="userDialog.visible"
          style="height:450px;overflow-y: scroll;"
          ref="userTagTreeRef"
          :data="userTagTree"
          show-checkbox
          node-key="id"
          :filter-node-method="filterUserTagNode"
          @check="handleUserTagChange"
          :props="{ children: 'users', label: 'name', value: 'id' }">
          <span class="custom-tree-node" slot-scope="{ node, data }">
            <span>{{ node.label }}</span>
            <span style="margin-left: 20px">{{ data.remark }}</span>
          </span>
        </el-tree>
      </div>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button type="primary" size="mini" @click="userDialog.visible = false">取消</el-button>
        <el-button type="primary" size="mini" @click="submitAddUser">确定</el-button>
      </div>
    </el-dialog>


    <el-dialog title="添加配送中心人员" :visible.sync="dcUsersDialog.visible" append-to-body width="600px" :close-on-click-modal="false">
      <div style="height: 500px">
        <h4>需先在人员角色配置页面先配置相关人员数据</h4>
        <div v-for="item in dcUsersDialog.avgUsers" :key="item" style="margin-bottom: 10px;">
          <el-tag :type="getQuotaTagType(dcUsersDialog.avgUsers, item)" size="small" closable @close="handleRemoveAvgUser(item)"> {{ item + '-' + dcUsersDialog.selections.find(e => e.userName == item)?.nickName }} </el-tag>
        </div>
        <el-table
          ref="dcUserTableRef"
          :data="dcUsersDialog.rows"
          height="calc(100vh - 450px)"
          v-loading="dcUsersDialog.loading"
          size="mini"
          border
          highlight-current-row
          :header-cell-style="{ background: 'rgb(235 235 235)', color: 'black',textAlign: 'center', verticalAlign: 'middle' }"
          @selection-change="handleDcUserSelectionChange"
        >
          <el-table-column type="selection" width="90" align="center"  />
          <el-table-column label="用户账号" prop="userName" width="100" />
          <el-table-column label="用户姓名" prop="nickName" width="100"/>
          <el-table-column label="配送中心" prop="dcNames" width="280" show-overflow-tooltip />
        </el-table>
      </div>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button type="primary" size="mini" @click="dcUsersDialog.visible = false">取消</el-button>
        <el-button type="primary" size="mini" @click="submitAddDcUser">确定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="添加指标" :visible.sync="quotaDialog.visible" append-to-body width="400px" :close-on-click-modal="false">
      <el-form ref="userForm" size="mini" label-width="110px" :inline="true" :model="quotaDialog" :rules="quotaDialog.rules" class="common-form">
        <el-form-item label="指标数据" prop="dcCode">
          <el-select v-model="quotaDialog.quotaIds" filterable multiple>
            <el-option v-for="item in quotaDialog.quotaOptions" :key="item.id" :value="item.id" :label="item.quotaType + '-' + item.quotaName" :disabled="item.disabled" />
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button type="primary" size="mini" @click="quotaDialog.visible = false">取消</el-button>
        <el-button type="primary" size="mini" @click="handleAddQuotas">确定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="限制条件" :visible.sync="quotaLimitDialog.visible" append-to-body width="600px" :close-on-click-modal="false">
      <el-checkbox-group v-if="targetDrawer.form.quotaData[quotaLimitDialog.quotaId]" v-model="quotaLimitDialog.limitCheckedObj" @change="() => $forceUpdate()">
        <el-row v-for="item in quotaLimitDialog.limits" :key="item.id" >
          <el-checkbox :label="item.limitCode" :disabled="item.ifRequired==1">
            <div v-if="item.limitParamCode1">
              <div v-for="(l, index) in item.limitParamName1.split('${' + item.limitParamCode1 + '}')" :key="index" style="display: inline-block">
                <span>{{ l }}</span>
                <el-input v-if="index<item.limitParamName1.split('${' + item.limitParamCode1 + '}').length-1 " v-model="item.limitParamValue1" style="width:60px" size="mini" :disabled="item.ifRequired==1" />
              </div>
            </div>
            <div v-else style="display: inline-block">
              {{ item.limitParamName1 }}
            </div>
          </el-checkbox>
        </el-row>
      </el-checkbox-group>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button type="primary" size="mini" @click="quotaLimitDialog.visible = false">取消</el-button>
        <el-button type="primary" size="mini" @click="submitSetLimits">确定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="一键同步用户" :visible.sync="copyUserDialog.visible" append-to-body width="300px" :close-on-click-modal="false">
      <el-form ref="copyUserFormRef" size="mini" label-width="110px" :inline="true" :model="copyUserDialog" :rules="copyUserDialog.rules" class="common-form">
        <el-form-item label="用户" prop="userNames">
          <el-select v-model="copyUserDialog.userNames" filterable multiple>
            <el-option v-for="item in copyUserDialog.userOptions" :key="item.userName" :value="item.userName" :label="item.nickName" />
          </el-select>
        </el-form-item>
        <el-button size="mini" type="primary" @click="handleCheckAll(true)">全选</el-button>
        <el-button size="mini" type="primary" @click="handleCheckAll(false)">清空</el-button>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button type="primary" size="mini" @click="copyUserDialog.visible = false">取消</el-button>
        <el-button type="primary" size="mini" @click="submitUserCopy">确定</el-button>
      </div>
    </el-dialog>

    <el-dialog :title="importDialog.title[importDialog.type]" :visible.sync="importDialog.visible" append-to-body width="400px">
      <el-upload
        ref="goodsUpload"
        class="upload-demo"
        drag
        :limit="1"
        action="#"
        :auto-upload="false"
        :file-list="importDialog.fileList"
        :on-change="(file, fileList) => handleFileChange(file, fileList, 'change')"
        :on-remove="(file, fileList) => handleFileChange(file, fileList, 'remove')"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">
          <span>仅允许导入xls、xlsx格式文件。</span>
          <h3>
            {{ importDialog.tips[importDialog.type] }}
          </h3>
          <el-link v-if="importDialog.template[importDialog.type]" type="primary" :underline="false" style="font-size:12px;vertical-align: baseline;" target="_blank" :href="importDialog.template[importDialog.type]">下载模板</el-link>
          <el-link v-if="!importDialog.template[importDialog.type]" type="primary" :underline="false" style="font-size:12px;vertical-align: baseline;" @click="handleDownloadTemplate(importDialog.type)">下载模板</el-link>
        </div>
      </el-upload>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button type="primary" size="mini" @click="submitImport">确定</el-button>
        <el-button type="primary" size="mini" @click="importDialog.visible = false">取消</el-button>
      </div>
    </el-dialog>

    <el-dialog title="一键复制" :visible.sync="copyTargetDialog.visible" append-to-body width="400px" :close-on-click-modal="false">
      <el-form ref="copyTargetFormRef" size="mini" label-width="110px" :inline="true" :model="copyTargetDialog" :rules="copyTargetDialog.rules" class="common-form">
        <el-form-item label="展示时间" prop="startTime">
          <el-date-picker
            v-model="copyTargetDialog.showDate"
            type="month"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            @change="val => handleDateChange(val, 'copyTarget')"
            format="yyyy-MM"
            :picker-options="copyTargetDialog.pickerOptions"
            :default-time="['00:00:00', '23:59:59']"
          ></el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button type="primary" size="mini" @click="copyTargetDialog.visible = false">取消</el-button>
        <el-button type="primary" size="mini" @click="submitTargetCopy">确定</el-button>
      </div>
    </el-dialog>

    <el-drawer title="人员角色配置" :visible.sync="userTagDrawer.visible" size="75%" direction="rtl" append-to-body :modal-append-to-body="false" :wrapperClosable="false">
      <UserTag :orgCode="queryForm.orgCode" />
      <div style="text-align: right;position: absolute; bottom: 50px; right: 50px;">
        <el-button size="mini" @click="userTagDrawer.visible = false">关 闭</el-button>
      </div>
    </el-drawer>

    <el-drawer title="汇总" :visible.sync="summaryDrawer.visible" size="85%" direction="rtl" append-to-body :modal-append-to-body="false" :wrapperClosable="false">
      <Summary :id="currentRow.id" :condition="currentRow.condition" :target-name="currentRow.targetName" />
      <div style="text-align: right;position: absolute; bottom: 50px; right: 50px;">
        <el-button size="mini" :disabled="summaryDrawer.buttonLoading" @click="summaryDrawer.visible = false">关 闭</el-button>
      </div>
    </el-drawer>

    <el-drawer title="月度汇总" :visible.sync="mthSummaryDrawer.visible" size="85%" direction="rtl" append-to-body :modal-append-to-body="false" :wrapperClosable="false">
      <UserSummary :org-code="queryForm.orgCode" :start-time="queryForm.startTime" :end-time="queryForm.endTime" />
      <div style="text-align: right;position: absolute; bottom: 50px; right: 50px;">
        <el-button size="mini" :disabled="mthSummaryDrawer.buttonLoading" @click="mthSummaryDrawer.visible = false">关 闭</el-button>
      </div>
    </el-drawer>

  </div>
</template>
<script>
import { dictSelect, userSelect, pageTargetData, saveTargetData, importTargetData, getTargetDetail, getQuotas, getOrgTaskOption, getTaskBoostOptionsByTask, removeTarget,
  saveTargetSort, getTagUserTree, copyTarget, pageUserTag } from "@/api/appTask";
import { Notification } from "element-ui";
import {parseTime, deepClone, excelExport} from "@/utils";
import DictSelect from "@/components/DictSelect";
import SearchFilter from "@/components/SearchFilter/index";
import UserTag from "./userTag";
import Summary from "./summary.vue";
import UserSummary from "./userSummary.vue";
import {read, utils} from "xlsx";

export default {
  name: "JX",
  components: { DictSelect, UserTag, Summary, UserSummary, SearchFilter },
  data() {
    return {
      deepClone,
      homeLoading: false,
      activeTab: 0,
      currentRow: {},
      pageData: {
        total: 0,
        rows: [],
        selections: [],
      },
      queryForm: {
        current: 1,
        size: 10,
        orgCode: undefined,
        queryMth: undefined,
        startTime: undefined,
        endTime: undefined,
        queryDc: undefined,
        backgroundName: undefined
      },
      loading: false,
      currentUser: {},
      dictFun: dictSelect,
      dictUserFun: userSelect,
      shopJsonParam: {},
      activeNames: undefined,
      targetDrawer: {
        visible: false,
        pageLoading: false,
        buttonLoading: false,
        type: 'edit',
        form: {
          id: undefined,
          targetName: undefined,
          backgroundName: undefined,
          scoreType: 1,
          orgCode: undefined,
          orgName: undefined,
          condition: '1',
          computeType: 1,
          quotaType: undefined,
          mtTaskId: undefined,
          startTime: undefined,
          endTime: undefined,
          deleted: 0,
          rid: undefined,
          remark: undefined,
          createTime: undefined,
          updateTime: undefined,
          createUser: undefined,
          updateUser: undefined,
          showDate: undefined,
          quotaIds: [],
          quotaData: {
          },
          userData: [],
          sumMode: undefined
        },
        pageData: {
          total: 0,
          rows: [],
          current: 1,
          size: 10,
        },
        rules: {
          orgCode: [{ required: true, message: " ", trigger: "change" }],
          targetName: [{ required: true, message: " ", trigger: "change" }],
          backgroundName: [{ required: true, message: " ", trigger: "change" }],
          startTime: [{ required: true, message: " ", trigger: "change" }],
          mtTaskId: [{ required: true, message: " ", trigger: "change" }],
          quotaIds: [{ type: 'array', required: true, message: " ", trigger: "change" }],
          dcCodes: [{ type: 'array', required: true, message: " ", trigger: "change" }],
        },
      },
      defaultQuota: {
        limitCheckedObj: [],
        limits: []
      },
      exportHeaders: {
        userName: '用户账号',
        errorMsg: '校验结果',
      },
      exportDcHeaders: {
        userName: '用户账号',
        dcCode: '配送中心编码',
        errorMsg: '校验结果',
      },
      options: {
        quotas: [],
        limits: [],
        dynamicQuotaOptions: [],
        taskOptions: [],
        boostQuotas: ['TASK_REACH_MODULES', 'TASK_MODULE_REACH_SHOP', 'TASK_MODULE_REACH_SHOP_IMP', 'TASK_12_MODULE_REACH_SHOP_IMP'],
        boosts: [],
        repeatQuotaIds: [],
        orgOptions: [],
        dcOptions: [],
        dcAllOptions: [],
        esbTasks: [{ mtTaskId: 'T202305290852206529', taskName: '经典畅销238(上柜行动)', orgName: '全国'}],
        wdTasks:  [{ mtTaskId: 'T202405311135147514', taskName: '2024年大学汛五大阵地突破', orgName: '全国'}],
        zdTasks:  [{ mtTaskId: 'T202405311135147514', taskName: '2024年大学汛五大阵地突破', orgName: '全国'}, { mtTaskId: 'T202305290852206529', taskName: '经典畅销238(上柜行动)', orgName: '全国'}],
        wdQuotaCodes: ['TASK_12_MODULE_REACH_SHOP_IMP'],
        zdQuotaCodes: ['TASK_REACH_MODULES', 'TASK_MODULE_REACH_SHOP', 'TASK_12_MODULE_REACH_SHOP_IMP'],
        esbQuotaCodes: ['TASK_238_CHECK', 'TASK_MODULE_REACH_SHOP_IMP'],
        shopRangeOptions: [{ label: '包干店铺', value: '1'},{ label: '指定配送中心', value: '2'}],
        importTypeOptions: [{ label: '自动计算', value: 0},{ label: '导入达成', value: 1}, { label: '导入结果', value: 2}],
        userRangeOptions: [{ label: '配送中心业务员', value: '1', remark: '指分公司组织下该配送中心下所有的四五六级人员（四级区域经理、五级业务经理、六级业务员'},{ label: '自定义业务员', value:  '2'}],
        multipleDcQuoTas: ['TASK_MODULE_REACH_SHOP_IMP', 'TASK_12_MODULE_REACH_SHOP_IMP']
      },
      defaultRule: {
        numA: undefined,
        score: undefined,
        money: undefined,
        boostShortName: undefined,
        boostId: undefined,
        condition: '>=',
        jxQuotaId: undefined,
        perNum: undefined,
        perBoostNum: undefined,
        maxNum: undefined,
      },
      defaultCoefficient: {
        numA: undefined,
        coefficient: undefined,
      },
      userDialog: {
        visible: false,
        form: {
          userName: undefined,
          dcCode: undefined,
        },
        filterText: undefined,
        rules:{
          userName: [{ required: true, message: ' ', trigger: 'change' },],
          dcCode:  [{ required: true, message: ' ', trigger: 'change' },],
        },
        checkedUsers: [],
        dynamicQuotaOptions: [],
      },
      dcUsersDialog: {
        visible: false,
        avgUsers: [],
        loading: false,
        index: undefined,
        queryForm: {
          dcCodes: [],
        },
        rows: [],
        selections: []
      },
      quotaDialog: {
        visible: false,
        quotaIds: [],
        quotaOptions: [],
        rules: {
          quotaIds: [{ type: 'array' ,required: true, message: ' ', trigger: 'change' },],
        }
      },
      quotaLimitDialog: {
        visible: false,
        quotaId: undefined,
        limits: [],
        limitCheckedObj: []
      },
      colFields: ['userName','nickName'],
      spanArr: [],
      importDialog: {
        visible: false,
        type: 'common',
        fileList: [],
        accept: ['.xls', '.xlsx'],
        title: {
          common: '绩效目标导入',
          dc: '伙伴绩效目标导入',
          achieve: '用户目标达成数据',
          reward: '用户目标结果数据'
        },
        tips: {
          achieve: '由于不同的指标的需要填写的数据均不太一样.下载模板前,请先确保当前数据存在计算类型为导入达成的数据!',
          reward: '由于不同的指标的需要填写的数据均不太一样.下载模板前,请先确保当前数据存在计算类型为导入结果的数据!'
        },
        template: {
          common: process.env.VUE_APP_OSS_TEMPLATE_PATH +'/%E7%BB%A9%E6%95%88%E7%9B%AE%E6%A0%87%E8%B4%A6%E5%8F%B7%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx',
          dc: process.env.VUE_APP_OSS_TEMPLATE_PATH +'/%E4%BC%99%E4%BC%B4%E7%BB%A9%E6%95%88%E7%9B%AE%E6%A0%87%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx',
        },
        uploadData: []
      },
      userTagDrawer: {
        visible: false
      },
      userTagTree: [],
      copyUserDialog: {
        visible: false,
        userOptions: [],
        userNames: [],
        sourceUserName: undefined,
        source: undefined,
        sourceIndex: undefined,
        rules: {
          userNames: [{ type: 'array' ,required: true, message: ' ', trigger: 'change' },],
        }
      },
      copyTargetDialog: {
        visible: false,
        showDate: [],
        startTime: undefined,
        endTime: undefined,
        targetId: undefined,
        pickerOptions: {
          disabledDate(date) {
            const now = new Date();
            const current = new Date(now.getFullYear(), now.getMonth() + 1, 0)
            const year = current.getFullYear();
            const month = current.getMonth() + 1;
            const maxDate = new Date(year +'-' + month + '-01 00:00:00')
            const newDate = new Date(date.getFullYear() +'-' + (date.getMonth() + 1) + '-01 00:00:00');
            return  newDate.getTime() < maxDate.getTime();
          },
        },
        rules: {
          startTime: [{ required: true, message: ' ', trigger: 'change' },],
        }
      },
      quotaMoving: false,
      summaryDrawer: {
        visible:false,
      },
      mthSummaryDrawer: {
        visible: false
      },
      exportTargetHeaders: {
        baseHeaders: {
          userName: '用户账号(不可修改)',
          nickName: '用户姓名(不可修改)',
          index: '序号(不可修改)',
          boostName: '阵地名称(不可修改)',
          shopRange: '配送中心范围(不可修改)',
          dcCode: '配送中心编码(不可修改)',
          quotaName: '指标名称(不可修改)',
        },
        achieve: {
          1: {
            achieveNum: '导入达成数',
          },
          2: {
            numA: '目标',
            boostNum: '得分/金额',
            achieveNum: '导入达成数',
          },
          3: {
            numA: '目标',
            boostNum: '得分/金额',
            achieveNum: '导入达成数',
          },
          4: {
            numA: '目标',
            boostNum: '得分/金额',
            achieveNum: '导入达成数',
          },
          5: {
            numA: '目标',
            boostNum: '得分/金额',
            achieveNum: '导入达成数',
          },
        },
        reward: {
          1: {
            rewardNum: '导入结果(得分/金额)',
          },
          2: {
            numA: '目标',
            rewardNum: '导入结果(得分/金额)',
          },
          3: {
            numA: '目标',
            rewardNum: '导入结果(得分/金额)',
          },
          4: {
            numA: '目标',
            rewardNum: '导入结果(得分/金额)',
          },
          5: {
            numA: '目标',
            rewardNum: '导入结果(得分/金额)',
          },
        }
      },
      showTable: true,
      userManageDc: {},
    };
  },
  computed: {
  },
  watch: {
    "quotaDialog.quotaIds": {
      handler(nV, oV) {
        this.quotaDialog.quotaOptions = this.getQuotaOptions();
      },
      immediate: true,
      deep: true,
    },
    "queryForm.orgCode": {
      handler(nV, oV) {
        if (!nV) {
          return
        }
        this.handleFilter();
        getQuotas(nV).then(res => {
          this.options.quotas = res.data;
          this.options.repeatQuotaIds = this.options.quotas.filter(e => e.ifRepeat == 1).map(e => e.id);
        })
        dictSelect('ht-dc-all', { orgCode: nV}).then(res => {
          this.options.dcAllOptions = res.data;
        })
        getTagUserTree(nV).then(res => {
          this.userTagTree = res.data;
          this.userManageDc = {};
          res.data.forEach(e => {
            if (e.users && e.users.length > 0) {
              e.users.forEach(u => {
                this.userManageDc[u.id] = u.dcCodes;
              })
            }
          })
        })
      },
      deep: true,
      immediate: true
    },
    "targetDrawer.form.computeType": {
      handler(nV, oV) {
        if (oV === 1) {
          this.targetDrawer.form.userData.forEach(e => e.rules = [e.rules[0]]);
        }
        if (nV === 5) {
          this.targetDrawer.form.userData.forEach(e => {
            if (!e.coefficients || e.coefficients.length === 0) {
              e.coefficients = [deepClone(this.defaultCoefficient)]
            }
          });
        }
      },
      deep:true
    },
    "userTagDrawer.visible": {
      handler(nV, oV) {
        if (oV) {
          getTagUserTree(this.queryForm.orgCode).then(res => {
            this.userTagTree = res.data;
          })
        }
      },
      deep: true
    },
  },
  created() {
    const now =new Date()
    this.queryForm.queryMth = new Date(parseTime(now, "{y}-{m}" + "-01 00:00:00"))
    this.queryForm.startTime = parseTime(this.queryForm.queryMth, "{y}-{m}-{d} {h}:{i}:{s}");
    this.queryForm.endTime = parseTime(this.queryForm.queryMth, "{y}-{m}-{d}") + " 23:59:59";
    dictSelect('ht-org').then(res => {
      this.options.orgOptions = res.data;
      if (res.data.length > 0) {
        this.queryForm.orgCode = res.data[0].value;
        this.handleFilter();
      }
    })
  },
  mounted() {
  },
  methods: {
    handleQuery() {
      this.loading = true;

      const query = Object.assign({}, this.queryForm);
      pageTargetData(query).then(res => {
        if (!res.data) {
          return false;
        }
        this.loading = false;
        this.pageData.rows = res.data;
      });
    },
    handleFilter() {
      this.queryForm.page = 1;
      this.handleQuery();
    },
    resetQuery() {
      const queryForm = {
        current: 1,
        size: 10,
        orgCode: undefined,
      };
      this.queryForm = Object.assign({}, queryForm);
      this.$forceUpdate();
    },
    getSpanArr(list) {
      for (let i = 0; i < list.length; i++) {
        let row = i;
        // let col = i % this.colCount;
        if (row === 0) {
          // i 表示行 j表示列
          for (let j = 0; j < this.colFields.length; j++) {
            this.spanArr[i * this.colFields.length + j] = {
              rowspan: 1,
              colspan: 1,
            };
          }
        } else {
          for (let j = 0; j < this.colFields.length; j++) {
            // 当前和上一次的一样
            // 1. 合并所有列的相同数据单元格
            if (
              list[row][this.colFields[j]] ===
              list[row - 1][this.colFields[j]]
            ) {
              let beforeItem = this.spanArr[(row - 1) * this.colFields.length + j];
              this.spanArr[row * this.colFields.length + j] = {
                rowspan: 1 + beforeItem.rowspan, // 合并几行
                colspan: 1, // 合并几列,我这里只是跨行合并,不跨列合并,所以用的1
              };
              beforeItem.rowspan = 0;
              beforeItem.colspan = 0;
            } else {
              // rowspan 和 colspan 都为1表格此单元格不合并
              this.spanArr[row * this.colFields.length + j] = {
                rowspan: 1,
                colspan: 1,
              };
            }
          }
        }
      }
      // 对数据进行倒序
      let stack = [];
      for (let i = 0; i < this.colFields.length; i++) {
        for (let j = 0; j < list.length; j++) {
          // console.log("i=" + i + " j=" + j);
          // i 表示列 j表示行
          if (j === 0) {
            if (this.spanArr[j * this.colFields.length + i].rowspan === 0) {
              stack.push(this.spanArr[j * this.colFields.length + i]);
            }
          } else {
            if (this.spanArr[j * this.colFields.length + i].rowspan === 0) {
              stack.push(this.spanArr[j * this.colFields.length + i]);
            } else {
              stack.push(this.spanArr[j * this.colFields.length + i]);
              while (stack.length > 0) {
                let pop = stack.pop();
                let len = stack.length;
                this.spanArr[(j - len) * this.colFields.length + i] = pop;
              }
            }
          }
        }
      }
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (this.colFields.indexOf(column.property) > -1 || (this.targetDrawer.form.condition !=='1' && column.property === 'boost')) {
        return this.spanArr[rowIndex * this.colFields.length]
      }
    },
    handleMove(type, index) {
      const item = this.pageData.rows[index];
      if (type === "up") {
        this.pageData.rows.splice(index, 1)
        this.pageData.rows.splice(index - 1, 0, item)
      } else if (type === "down") {
        this.pageData.rows.splice(index, 1)
        this.pageData.rows.splice(index + 1, 0, item)
      }
    },
    handleMoveQuota(item, index, type) {
      this.quotaMoving = true;
      if (type === "up") {
        this.targetDrawer.form.quotaIds.splice(index, 1)
        this.targetDrawer.form.quotaIds.splice(index - 1, 0, item)
        this.targetDrawer.form.userData = this.targetDrawer.form.userData.map(e => {
          if (e.sort == index) {
            e.sort+=-1;
          } else if (e.sort == index -1) {
            e.sort+=1;
          }
          return e;
        })
      } else if (type === "down") {
        this.targetDrawer.form.quotaIds.splice(index, 1)
        this.targetDrawer.form.quotaIds.splice(index + 1, 0, item)
        this.targetDrawer.form.userData = this.targetDrawer.form.userData.map(e => {
          if (e.sort == index) {
            e.sort+=1;
          } else if (e.sort == index + 1) {
            e.sort+=-1;
          }
          return e;
        })
      }
      this.resetPageData();
      this.quotaMoving = false;
    },
    async handleEdit(row, type) {
      await getQuotas(this.queryForm.orgCode).then(res => {
        this.options.quotas = res.data;
        this.options.repeatQuotaIds = this.options.quotas.filter(e => e.ifRepeat == 1).map(e => e.id);
      })
      if (row && row.id) {
        getTargetDetail(row.id).then(res => {
          let tmpForm = deepClone(res.data);
          if (tmpForm.startTime && row.endTime) {
            tmpForm.showDate = new Date(tmpForm.startTime);
          }

          tmpForm.quotaIds = Array.from(new Set(tmpForm.userData.map(e => e.jxQuotaId + '_' + e.sort))).map(e => {
              return { jxQuotaId: e.split('_')[0], sort: e.split('_')[1] }
            }).sort((a, b) => {
              return a.sort - b.sort
          }).map(e => parseInt(e.jxQuotaId));
          const quotaIds = Array.from(new Set(tmpForm.quotaIds));
          tmpForm.quotaData = {};
          quotaIds.forEach(e => {
            tmpForm.quotaData[e] = deepClone(this.defaultQuota);
            const quota = this.options.quotas.find(q => q.id == e)
            tmpForm.quotaData[e].limits = deepClone(quota.limits? quota.limits: []);
            tmpForm.quotaData[e].limitCheckedObj = tmpForm.limits.filter(l => l.jxQuotaId == e).map(l => l.paramName);

            tmpForm.quotaData[e].limits.forEach(l => {
              if (tmpForm.quotaData[e].limitCheckedObj.indexOf(l.limitCode) > -1) {
                const limit = tmpForm.limits.find(tl => tl.paramName == l.limitCode && e === tl.jxQuotaId);
                if (limit && limit.paramValue) {
                  l.limitParamValue1 = limit.paramValue;
                }
              }
            })

          })
          tmpForm.userData.forEach(e => {
            if (e.boostId) {
              e.boostId = e.boostId.split(",");
              e.boostId = e.boostId.map(b => parseInt(b))
            }
            if (e.dcCode && this.validateMultipleDcQuota(e.jxQuotaId)) {
              e.dcCode = e.dcCode.split(',');
            }
            if (tmpForm.condition!=='1') {
              if (tmpForm.scoreType === 1) {
                e.boostNum = e.rules[0].score
              } else {
                e.boostNum = e.rules[0].money
              }
            }
          })
          if (tmpForm.mtTaskId) {
            getTaskBoostOptionsByTask(tmpForm.mtTaskId).then(res => {
              if (res.data) {
                this.options.boosts = res.data;
              }
            })
          }
          this.targetDrawer.form = tmpForm;
          this.targetDrawer.type = type;
          this.resetPageData()
        })
      } else {
        this.targetDrawer.form = {
          id: undefined,
          targetName: undefined,
          backgroundName: undefined,
          scoreType: 1,
          orgCode: this.queryForm.orgCode + '',
          orgName: undefined,
          condition: '1',
          computeType: 1,
          quotaType: undefined,
          mtTaskId: undefined,
          startTime: undefined,
          endTime: undefined,
          deleted: 0,
          rid: undefined,
          remark: undefined,
          createTime: undefined,
          updateTime: undefined,
          createUser: undefined,
          updateUser: undefined,
          showDate: undefined,
          quotaIds: [],
          quotaData: {},
          userData: []
        }
        const orgInfo = this.options.orgOptions.find(o => o.value === this.queryForm.orgCode);
        if (orgInfo) {
          this.targetDrawer.form.orgName = orgInfo.label;
        }
      }
      this.targetDrawer.type = type;
      this.targetDrawer.visible= true;
      if (row && row.id) {
        this.handleOrgChange(this.targetDrawer.form.orgCode)
      }
    },
    handleViewScope(row) {
      this.currentRow = deepClone(row);
      this.summaryDrawer.visible= true;
    },
    handleDateChange(val, type) {
      if (type === 'query') {
        if (val) {
          const selectDate = new Date(val);
          const date = new Date(selectDate.getFullYear(), selectDate.getMonth() + 1, 0);
          this.queryForm.startTime = parseTime(val, "{y}-{m}-{d} {h}:{i}:{s}");
          this.queryForm.endTime = parseTime(date, "{y}-{m}-{d}") + " 23:59:59";
        } else {
          this.targetDrawer.form.startTime = undefined;
          this.targetDrawer.form.endTime = undefined;
        }
      } else if (type === 'copyTarget') {
        if (val) {
          const selectDate = new Date(val);
          const date = new Date(selectDate.getFullYear(), selectDate.getMonth() + 1, 0);
          this.copyTargetDialog.startTime = parseTime(val, "{y}-{m}-{d} {h}:{i}:{s}");
          this.copyTargetDialog.endTime = parseTime(date, "{y}-{m}-{d}") + " 23:59:59";
        } else {
          this.copyTargetDialog.startTime = undefined;
          this.copyTargetDialog.endTime = undefined;
        }
      } else {
        if (val) {
          const selectDate = new Date(val);
          const date = new Date(selectDate.getFullYear(), selectDate.getMonth() + 1, 0);
          this.targetDrawer.form.startTime = parseTime(val, "{y}-{m}-{d} {h}:{i}:{s}");
          this.targetDrawer.form.endTime = parseTime(date, "{y}-{m}-{d}") + " 23:59:59";
        } else {
          this.targetDrawer.form.startTime = undefined;
          this.targetDrawer.form.endTime = undefined;
        }
      }
    },
    submitEditForm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          saveTargetData(this.editDialog.form).then(() => {
            this.editDialog.visible = false;
            Notification.success("操作成功！");
            this.handleFilter()
          });
        }
      })
    },
    handleImport(type) {
      this.importDialog.type = type? type: (this.validateDcQuota() ? 'dc': 'common');
      if (this.$refs.goodsUpload) {
        this.$nextTick(() => {
          this.$refs.goodsUpload.clearFiles();
        })
      }
      this.importDialog.uploadData = [];
      this.importDialog.visible = true;
    },
    handleFileChange(file, fileList, type) {
      if (type === 'remove') {
        this.importDialog.fileList = fileList;
        return false;
      }
      const suffix =
        file.name.indexOf(".") > 0
          ? file.name.substring(file.name.lastIndexOf("."))
          : undefined;
      if (this.importDialog.accept.indexOf(suffix) < 0) {
        this.$message.error("文件格式不支持");
        this.importDialog.fileList = [];
      } else {
        this.importDialog.fileList = fileList;
      }
      if (['achieve', 'reward'].indexOf(this.importDialog.type) > -1 && file) {
        this.readerData(file.raw, this.importDialog.type);
      }
    },
    submitImport() {
      if (this.importDialog.fileList.length === 0) {
        Notification.warning('请先上传文件！');
        return false;
      }
      let fd = new FormData();
      if (['achieve', 'reward'].indexOf(this.importDialog.type) > -1) {
        this.verifyImportData();
      } else {
        this.importDialog.fileList.forEach(item=>{
          //文件信息中raw才是真的文件
          fd.append("file",item.raw);
        })
        importTargetData(this.importDialog.type, this.targetDrawer.form.orgCode, fd).then((res) => {
          let errList = res.data.filter(e => e.errorMsg)
          if (errList.length > 0) {
            Notification.error("导入失败，存在错误数据，正在导出，请稍候！");
            this.downloadImportResult(res.data)
          } else {
            let data = [];
            let flag = false;
            let userKey = [];
            res.data = res.data.filter(e => {
              if (userKey.indexOf(e.userName + "-" + e.dcCode) > -1) {
                flag = true;
                return false;
              } else {
                userKey.push(e.userName + "-" + e.dcCode);
                return true;
              }
            })
            let allKeys = this.targetDrawer.form.userData.map(e => this.getUserKey(e));
            res.data.forEach(e => {
              const userData = this.addSingleUser(e.userName, e.dcCode, e.nickName, allKeys);
              if (userData && userData.length > 0) {
                data = data.concat(userData)
              } else {
                flag = true;
              }
            });

            if (flag) {
              Notification.success("操作成功,存在重复添加数据已自动过滤!");
            }
            this.targetDrawer.form.userData = this.targetDrawer.form.userData.concat(data);
            this.resetPageData();
            this.importDialog.visible = false;
          }
          this.loading = false;
          this.$refs.goodsUpload.clearFiles();
        })
      }

    },
    verifyImportData() {
      if (this.importDialog.uploadData.length == 0) {
        Notification.warning("导入的数据不可为空!");
        return;
      }
      let errorFlag = false;
      this.importDialog.uploadData.forEach(e => {
        let errorMsg = [];
        if (typeof e.index !== "number") {
          errorMsg.push("序号类型错误且应为数字!");
        }
        if (this.targetDrawer.form.userData[e.index - 1]) {
          const data = this.targetDrawer.form.userData[e.index - 1];
          if (this.importDialog.type === 'achieve' && data.importType!==1) {
            errorMsg.push("导入的是达成数据但页面填写的计算类型非导入达成!");
          }
          if (this.importDialog.type === 'reward' && data.importType!==2) {
            errorMsg.push("导入的是结果数据但页面填写的计算类型非导入结果!");
          }
        }
        if (errorMsg.length > 0) {
          errorFlag = true;
        }
        e.errorMsg = errorMsg.join(";");
      });
      if (!errorFlag) {
        this.importDialog.uploadData.forEach(e => {
          let data = this.targetDrawer.form.userData[e.index - 1];
          if (data.importType === 1) {
            data.achieveNum = e.achieveNum;
          } else if (data.importType === 2) {
            data.rewardNum = e.rewardNum;
          }
          if (this.targetDrawer.form.computeType !== 1) {
            data.rules[0].numA = e.numA;
          }
          this.targetDrawer.form.userData[e.index - 1] = data;
        })
        this.$forceUpdate();
        if (this.$refs.userTableRef) {
          this.$refs.userTableRef.doLayout();
        }
        this.importDialog.visible = false;
      } else {
        Notification.error("导入数据校验失败，注意自动导出,请查看错误信息！");
        excelExport({
          topHeaders: Object.assign({}, this.getHeader(this.importDialog.type), { errorMsg: '校验信息'}),
          data: this.importDialog.uploadData,
          title: this.importDialog.type == 'achieve'? '用户导入达成数据校验结果': '用户导入结果数据校验结果',
        });
        this.importDialog.visible = true;
        return;
      }
    },
    resetPageData() {
      this.resetTable();
      this.targetDrawer.form.userData = this.targetDrawer.form.userData.sort((a, b) => {
          return a.userName.localeCompare(b.userName) || a.jxQuotaId-b.jxQuotaId || a.sort-b.sort
      })
      this.getSpanArr(this.targetDrawer.form.userData);
      this.$nextTick(() => {
        if (this.$refs.userTableRef) {
          this.$refs.userTableRef.doLayout();
        }
      })
    },
    downloadImportResult(data) {
      excelExport({
        topHeaders: this.validateDcQuota()? this.exportDcHeaders: this.exportHeaders,
        data: data,
        title: '用户导入校验结果',
      });
    },
    handleQuotaChange(val, type, index) {
      let quotas = [];
      if (type === 'remove') {
        this.targetDrawer.form.userData = this.targetDrawer.form.userData.filter(e => {
          return !(e.sort === index && e.jxQuotaId === val);
        }).map(e => {
          if (e.sort > index) {
            e.sort = e.sort -1;
          }
          return e
        });
        quotas = this.options.quotas.filter(e => val === e.id);
        this.targetDrawer.form.quotaIds.splice(index, 1)
      } else {
        let startIndex = this.targetDrawer.form.quotaIds.length;
        val.forEach(e => this.targetDrawer.form.quotaIds.push(e));

        val.forEach(e => {
          if (!this.targetDrawer.form.quotaData[e + '']) {
            this.targetDrawer.form.quotaData[e + ''] = deepClone(this.defaultQuota)
          }
        })

        this.targetDrawer.form.userData = this.targetDrawer.form.userData.filter(e => this.targetDrawer.form.quotaIds.indexOf(e.jxQuotaId) > -1);
        let currentQuotaIds =  Array.from(new Set(this.targetDrawer.form.userData.map(e => e.jxQuotaId)));
        let userNames = [];
        let currentUsers = [];
        this.targetDrawer.form.userData.forEach(e => {
          let key = e.userName;
          if (userNames.indexOf(key) < 0) {
            currentUsers.push({userName: e.userName, nickName: e.nickName, dcCode: undefined, dcName: e.dcName, shopRange: '1', userRange: '1', importType: 0,  });
            userNames.push(key);
          }
        })

        val.forEach((e, index) => {
          if (currentQuotaIds.indexOf(e) < 0 || this.getQuotaInfo(e).ifRepeat) {
            const quotaInfo = this.getQuotaInfo(e);
            currentUsers.forEach(u => {
              let obj = deepClone({...u, ...{ jxQuotaId: e, sort: startIndex + index, achieveNum: undefined, rewardNum: undefined }
                , ...{rules: [{...deepClone(this.defaultRule), ... {jxQuotaId: e}}]}, coefficients: [deepClone(this.defaultCoefficient)] });
              if (quotaInfo.quotaTypeCode === 'CUSTOM') {
                obj.importType = 1
              }
              if (quotaInfo.quotaType === '伙伴业绩') {
                obj.shopRange = '2'
              }
              this.targetDrawer.form.userData.push(obj);
            })
          }
        })
        val.forEach(e => {
          if (this.targetDrawer.form.quotaData[e].limits.length == 0) {
            this.targetDrawer.form.quotaData[e].limits = this.options.quotas.find(q => (q.id + '') == e).limits;
            this.targetDrawer.form.quotaData[e].limitCheckedObj = this.targetDrawer.form.quotaData[e].limits.filter(l => l.ifRequired == 1).map(l => l.limitCode)
          }
        })
        quotas = this.options.quotas.filter(e => val.indexOf(e.id) > -1);
      }
      const if238 = quotas.filter(e => this.options.esbQuotaCodes.indexOf(e.quotaCode) > -1).length> 0;
      const ifwuda = quotas.filter(e => this.options.wdQuotaCodes.indexOf(e.quotaCode)>-1).length> 0;
      const ifZd = quotas.filter(e => this.options.zdQuotaCodes.indexOf(e.quotaCode)>-1).length> 0;
      if (if238) {
        this.options.taskOptions = deepClone(this.options.esbTasks);
        this.targetDrawer.form.mtTaskId = this.options.taskOptions[0].mtTaskId;
        this.handleTaskChange(this.targetDrawer.form.mtTaskId);
      } else if (ifwuda) {
        this.options.taskOptions = deepClone(this.options.wdTasks);
        this.targetDrawer.form.mtTaskId = this.options.taskOptions[0].mtTaskId;
        this.handleTaskChange(this.targetDrawer.form.mtTaskId);
      } else if (ifZd) {
        this.options.taskOptions = deepClone(this.options.zdTasks);
        if (this.targetDrawer.form.mtTaskId && this.options.taskOptions.filter(e => e.mtTaskId === this.targetDrawer.form.mtTaskId).length === 0) {
          this.targetDrawer.form.mtTaskId = undefined;
        }
        if (this.targetDrawer.form.mtTaskId) {
          this.handleTaskChange();
        }
      } else {
        getOrgTaskOption(this.targetDrawer.form.orgCode).then(res => {
          if (res.data) {
            this.options.taskOptions = res.data;
          }
        })
      }
      this.resetPageData();
      this.$forceUpdate();
    },
    handleAddQuotaUser() {
      this.userDialog.form = {
        dcCode: undefined,
        userName: undefined,
        quotaId: undefined,
      };
      this.userDialog.dynamicQuotaOptions = [];
      this.userDialog.checkedUsers = [];
      this.userDialog.visible = true;
    },
    validateDcQuota() {
      return this.options.quotas.filter(e => this.targetDrawer.form.quotaIds.indexOf(e.id) > -1 && e.ifRepeat == 1).length > 0
    },
    validateQuota(key) {
      if (!key) {
        key = '伙伴业绩'
      }
      const checkedQuotas = this.options.quotas.filter(e => this.targetDrawer.form.quotaIds.indexOf(e.id) > -1)
      const dcQuotaIds = checkedQuotas.filter(e => e.quotaType == key);
      return dcQuotaIds.length > 0 ? true:false;
    },
    handleUserChange(val) {
      this.userDialog.dynamicQuotaOptions = [];
      const currentUserQuotas = this.targetDrawer.form.userData.filter(e => this.options.repeatQuotaIds.indexOf(e.jxQuotaId) < 0 && e.userName == val).map(e => e.jxQuotaId);
      let options = JSON.parse(JSON.stringify(this.options.quotas.filter(e => this.targetDrawer.form.quotaIds.indexOf(e.id) > -1)));
      options.forEach(e => {
        e.disabled = currentUserQuotas.indexOf(e.id) > -1? true: false;
      })
      this.userDialog.dynamicQuotaOptions = options;
    },
    submitAddUser() {
      if (this.userDialog.checkedUsers.length == 0) {
        Notification.warning("请至少选择一个!");
        return;
      }
      let data = [];
      let allUserKeys = this.targetDrawer.form.userData.map(e => this.getUserKey(e));
      this.userDialog.checkedUsers.forEach(e => {
        if (e.dcCodes && e.dcCodes.length > 0 && this.validateDcQuota()) {
          e.dcCodes.forEach(d => {
            data = data.concat(this.addSingleUser(e.id, d, e.name.split('-')[1], allUserKeys));
          })
        } else {
          data = data.concat(this.addSingleUser(e.id, undefined, e.name.split('-')[1], allUserKeys));
        }
      })
      this.targetDrawer.form.userData = this.targetDrawer.form.userData.concat(data);
      this.userDialog.visible = false;
      this.resetPageData();
      Notification.success("添加成功!");
    },
    addSingleUser(userName, dcCode, nickName, allUserKeys) {
      let repeatUser = [];
      let dc = {};
      let data = [];
      let addQuotaIds = [];
      let existQuotaIds = [];
      if (this.validateDcQuota() && dcCode) {
        repeatUser = this.targetDrawer.form.userData.filter(e =>e.userName == userName && e.dcCode == dcCode);
        existQuotaIds = repeatUser.map(e => e.jxQuotaId);
        addQuotaIds = this.targetDrawer.form.quotaIds.filter(e => existQuotaIds.indexOf(e) < 0);
      } else {
        repeatUser = this.targetDrawer.form.userData.filter(e =>e.userName == userName)
        existQuotaIds = repeatUser.map(e => e.jxQuotaId);
        addQuotaIds = this.targetDrawer.form.quotaIds.filter(e => existQuotaIds.indexOf(e) < 0);
      }
      if (addQuotaIds.length === 0) {
        return [];
      } else {
        let rule = deepClone(this.defaultRule);
        this.targetDrawer.form.quotaIds.forEach((e, index) => {
          const quotaInfo = this.getQuotaInfo(e);
          if (quotaInfo.ifRepeat) {
            const dcInfo = this.options.dcOptions.find(e => e.value === dcCode)
            if (dcInfo) {
              dc = {
                dcCode: dcCode,
                dcName: dcInfo.label
              }
            }
          } else {
            dc = {};
          }
          if (existQuotaIds.indexOf(e) < 0) {
            let obj = deepClone({ ...{userName: userName, nickName: nickName, jxQuotaId: e, shopRange: '1', sort: index, importType: 0, userRange: '1',
                coefficients: [deepClone(this.defaultCoefficient)] }, ...dc,...{ rules: [{...rule, ...{ jxQuotaId: e}}] } });
            if (quotaInfo.quotaTypeCode === 'CUSTOM') {
              obj.importType = 1
            }
            if (this.validateMultipleDcQuota(e)) {
              obj.dcCode = [obj.dcCode]
            }
            if (quotaInfo.ifRepeat === 1) {
              obj.shopRange = '2'
            }
            if (allUserKeys.indexOf(this.getUserKey(obj)) > -1) {
              return [];
            } else {
              allUserKeys.push(this.getUserKey(obj))
            }
            data.push(obj)
          }
        })
      }
      return data;
    },
    getQuotaOptions() {
      let options = JSON.parse(JSON.stringify(this.options.quotas));
      if (this.targetDrawer.form.condition !== '1') {
        options.forEach(e => {
          if (e.quotaTypeCode === 'CUSTOM') {
            e.disabled = true;
          }
        });
      }
      if (this.targetDrawer.form.quotaIds.length === 0 && this.quotaDialog.quotaIds.length === 0) {
        return options;
      }
      let allCheckedQuotaIds = this.quotaDialog.quotaIds.concat(this.targetDrawer.form.quotaIds);
      const checkedQuotas = this.options.quotas.filter(e => allCheckedQuotaIds.indexOf(e.id) > -1)

      return options.map(e => {
        e.disabled = this.targetDrawer.form.quotaIds.indexOf(e.id) > -1 && e.ifRepeat == 0 ? true: false;
        return e;
      });

    },
    getQuotaInfo(id) {
      return this.options.quotas.find(e => parseInt(id + '') == e.id)
    },
    validateMultipleDcQuota(id) {
      const quota = this.getQuotaInfo(id);
      if (quota) {
        return this.options.multipleDcQuoTas.indexOf(quota.quotaCode) > -1
      }
      return false;
    },
    handleComputeChange() {
     this.targetDrawer.form.condition='1';
     this.resetTable();
    },
    resetTable() {
      this.showTable = false;
      this.targetDrawer.pageLoading = true;
      setTimeout(() => {
        this.showTable = true;
        if (this.$refs.userTableRef) {
          this.$nextTick(() => {
            this.$refs.userTableRef.doLayout();
          })
        }
        this.targetDrawer.pageLoading = false;
      }, 200)
    },
    handleConditionChange(val) {
      this.$confirm('请确认是否变更当前的计算条件,变更后将清除之前配置的用户数据!', '提示', { type: 'warning' }).then(() => {
        this.targetDrawer.form.quotaData = [];
        this.targetDrawer.form.userData = [];
        this.targetDrawer.form.quotaIds.forEach(e => {
          this.targetDrawer.form.quotaData[e + ''] = deepClone(this.defaultQuota)
        })
      }).catch(() => {
        this.targetDrawer.form.condition = val =='1'? '2': '1'
      })
    },
    handleTaskChange(val) {
      getTaskBoostOptionsByTask(val).then(res => {
        if (res.data) {
          this.options.boosts = res.data;
        }
      })
      this.targetDrawer.form.userData.forEach(u => {
        u.boostId = [];
        u.boostShortName = undefined;
      })
    },
    handleOrgChange(val) {
      if (this.targetDrawer.visible && val) {
        const orgInfo = this.options.orgOptions.find(o => o.value === val);
        if (orgInfo) {
          this.targetDrawer.form.orgName = orgInfo.label;
        }
        let quotas = this.options.quotas.filter(e => val.indexOf(e.id) > -1);
        const if238 = quotas.filter(e => e.quotaCode=='TASK_238_CHECK').length> 0;
        const ifZd = quotas.filter(e => this.options.zdQuotaCodes.indexOf(e.quotaCode)>-1).length> 0;
        const ifWuda = quotas.filter(e => this.options.wdQuotaCodes.indexOf(e.quotaCode)>-1).length> 0;
        if (ifZd) {
          this.options.taskOptions = deepClone(this.options.zdTasks);
          if (this.targetDrawer.form.mtTaskId && this.options.taskOptions.filter(e => e.mtTaskId === this.targetDrawer.form.mtTaskId).length === 0) {
            this.targetDrawer.form.mtTaskId = undefined;
          }
        } else if (ifWuda) {
          this.options.taskOptions = deepClone(this.options.wdTasks);
          this.targetDrawer.form.mtTaskId = this.options.taskOptions[0].mtTaskId;
        } else if (if238) {
          this.options.taskOptions = deepClone(this.options.esbTasks);
          this.targetDrawer.form.mtTaskId = this.options.taskOptions[0].mtTaskId;
        } else {
          getOrgTaskOption(val).then(res => {
            if (res.data) {
              this.options.taskOptions = res.data;
            }
          })
        }
      }
      if (val) {
        dictSelect('ht-dc', { orgCode: val}).then(res => {
          this.options.dcOptions = res.data;
        })
        getTagUserTree(val).then(res => {
          this.userTagTree = res.data;
        })
      }
    },
    submitForm() {
      this.$refs.jxFormRef.validate(valid => {
        if (valid) {
          let form = deepClone(this.targetDrawer.form);
          if (this.validateQuota("任务") && !form.mtTaskId) {
            Notification.warning("任务未填写,请填写相关数据后再提交!")
            return;
          }
          let allRemarks = {};
          let emptyData = [];
          let allUsers = [];
          let allLimits = [];
          let repeatData = [];
          const boostUnit = (form.scoreType == 1? '分': '元');
          if (form.userData.length === 0) {
            Notification.warning("用户的指标数据不可空!");
            return;
          } else if (form.condition !== '1' && form.quotaIds.length < 2){
            Notification.warning("至少包含两个指标数据")
            return;
          } else {
            let allUserKeys = [];
            let userBoostMap = {}
            form.userData.forEach(u => {
              if (form.condition !== '1') {
                if (!userBoostMap[u.userName]) {
                  userBoostMap[u.userName] = {
                    boostNum: u.boostNum,
                    importType: u.importType,
                    achieveNum: u.achieveNum,
                    rewardNum: u.rewardNum,
                    limitReward: u.limitReward
                  };
                } else {
                  u.boostNum = userBoostMap[u.userName].boostNum;
                  u.importType = userBoostMap[u.userName].importType;
                  u.achieveNum = userBoostMap[u.userName].achieveNum;
                  u.rewardNum = userBoostMap[u.userName].rewardNum;
                  u.limitReward = userBoostMap[u.userName].limitReward;
                }
              }
              const quotaInfo = this.getQuotaInfo(u.jxQuotaId);
              if (this.options.boostQuotas.indexOf(quotaInfo.quotaCode) > -1) {
                if ((!u.boostId || u.boostId.length === 0) || !u.boostShortName) {
                  emptyData.push(quotaInfo.quotaName + "/" + u.userName + ": 未选择相应阵地或未填写阵地简称")
                }
              }
              if (quotaInfo.ifRepeat === 1) {
                if (u.shopRange === '2' && !u.dcCode) {
                  emptyData.push(quotaInfo.quotaName + "/" + u.userName + ": 未选择配送中心")
                }
              }
              if (quotaInfo.ifAvg == 1 && u.userRange === '2' && (!u.avgUsers || u.avgUsers.length === 0)) {
                emptyData.push(quotaInfo.quotaName + "/" + u.userName + ": 未选择指定人员")
              }
              if (allUserKeys.indexOf(this.getUserKey(u)) > -1) {
                repeatData.push(u.userName + '/' + u.nickName)
              } else {
                allUserKeys.push(this.getUserKey(u))
              }
              let remarks = [];
              const quotaName = quotaInfo.quotaName;
              const unit = quotaInfo.unit;
              u.rules.forEach((rule, index) => {
                if (form.condition !== '1') {
                  if (form.scoreType === 1) {
                    rule.money = undefined;
                    rule.score = userBoostMap[u.userName] ? userBoostMap[u.userName].boostNum: u.boostNum;
                  } else {
                    rule.score = undefined;
                    rule.money = userBoostMap[u.userName] ? userBoostMap[u.userName].boostNum: u.boostNum;
                  }
                }
                if (form.computeType !== 4 && form.computeType !== 3) {
                  rule.perNum = undefined;
                  rule.perBoostNum = undefined;
                  rule.maxNum = undefined;
                }
                rule.id = undefined;
                let boost = form.scoreType == 1? rule.score: rule.money
                if (form.scoreType === 1 && rule.score == undefined) {
                  emptyData.push(quotaName + "/" + u.userName + ":未填写得分")
                } else if (form.scoreType === 2 && rule.money == undefined) {
                  emptyData.push(quotaName + "/" + u.userName + ":未填写金额")
                } else if (form.computeType == 4 || form.computeType == 3) {
                  if (!rule.perNum || !rule.perBoostNum || !rule.maxNum) {
                    emptyData.push(quotaName + "/" + u.userName + + ":未填写奖励")
                  }
                } else if (!rule.numA) {
                  emptyData.push(quotaName + "/" + u.userName + ":未填写目标")
                }
                if (form.condition === '1') {
                  if (form.computeType === 4) {
                    remarks.push('共' +  boost + boostUnit + '目标' +  rule.numA + unit + ',每不达成' + rule.perNum + unit + ',扣' + rule.perBoostNum + boostUnit + ',最多扣减' + rule.maxNum+ boostUnit)
                  } else if (form.computeType === 2) {
                    remarks.push('目标' +  rule.numA + unit + ",满分可得" + boost + boostUnit)
                  } else {
                    if (index === u.rules.length - 1) {
                      remarks.push(rule.numA + unit + '及以上得' + boost + boostUnit)
                    } else {
                      remarks.push(rule.numA + unit + '-' + u.rules[index + 1].numA + unit +'得' + boost + boostUnit)
                    }
                  }
                } else {
                  if (form.computeType === 4) {
                    remarks.push('共' +  boost + boostUnit + '目标' +  rule.numA + unit + ',每不达成' + rule.perNum + unit + ',扣' + rule.perBoostNum + boostUnit + ',最多扣减' + rule.maxNum + boostUnit)
                  } else if (form.computeType === 2) {
                    remarks.push(quotaName + ':目标' +  rule.numA + unit)
                  } else {
                    if (index === u.rules.length - 1) {
                      remarks.push(quotaName + ":" +rule.numA + unit + '及以上')
                    } else {
                      remarks.push(rule.numA + unit + '-' + u.rules[index + 1].numA + unit)
                    }
                  }
                }
              })

              if (form.computeType === 5) {
                u.coefficients.forEach((c, index) => {
                  if (c.numA===undefined || c.coefficient===undefined) {
                    emptyData.push(quotaName + "/" + u.userName + ' 得分占比或最终得分系数未填写!')
                  } else {
                    remarks.push(unit + '占比大于' + c.numA + '及以上,最终 ' + unit + ' 系数为: ' + c.coefficient)
                  }
                })
              }

              switch (quotaInfo.quotaCode) {
                case 'TASK_MODULE_REACH_SHOP':
                case 'TASK_REACH_MODULES':
                  if (u.shopRange === '1') {
                    remarks = ['包干店铺的任务明细']
                  } else {
                    remarks = [u.dcName + '的任务明细']
                  }
                  break;
                case 'TASK_JH_TOTAL':
                case 'TASK_JH_KSS':
                  if (u.shopRange === '1') {
                    remarks = ['该业务员包干重点店铺的任务期间的上柜款数']
                  } else {
                    remarks = [u.dcName + '的任务期间的上柜款数']
                  }
                  break;
                case 'KPI_WH_CNT':
                case 'KPI_WH_SHOP_CNT':
                case 'MY_ORDER_JH':
                case 'MY_RESP_SHOP_JH':
                case 'TASK_238_CHECK':
              }
              if (form.condition !== '1') {
                if (!allRemarks[u.userName]) {
                  allRemarks[u.userName] = [];
                }
                allRemarks[u.userName] = allRemarks[u.userName].concat(remarks);
              }

              u.remark = remarks.join(",")
              u.id = undefined;
              allUsers.push(u);
            })
          }
          if (emptyData.length > 0) {
            if (emptyData.length > 10) {
              Notification.warning("用户的存在大量指标数据未填写,请检查后再提交!")
              return;
            } else {
              Notification.warning("用户的 " + emptyData.join("、") + " 存在未填写的指标数据,请检查后再提交!")
              return;
            }
          } else if (repeatData.length > 0) {
            Notification.warning("部分用户存在重复数据" + repeatData.join(","))
            return;
          }
          if (form.condition == '2' || form.condition == '3') {
            form.userData.forEach(u => {
              let condition = form.condition =='3'? '或': '且';
              u.remark = allRemarks[u.userName].join(condition) + ',得' + u.boostNum + boostUnit
            })
          }

          for (const key in form.quotaData) {
            if (form.quotaIds.indexOf(parseInt(key)) > -1) {
              form.quotaData[key].limits.forEach(l => {
                if (form.quotaData[key].limitCheckedObj.indexOf(l.limitCode) > -1) {
                  allLimits.push({ jxQuotaId: l.jxQuotaId, paramName: l.limitCode, paramValue: l.limitParamValue1 })
                }
              })
            }
          }
          let quotaMap = {};
          if (!this.validateQuota("任务")) {
            form.mtTaskId = undefined;
          }
          this.options.quotas.forEach(e => quotaMap[e.id + ''] = e.quotaType);
          allUsers.forEach(e => {
            e.id = undefined;
            e.quotaType = quotaMap[e.jxQuotaId]
            if (!this.validateQuota("任务")) {
              e.boostId = undefined;
              e.boostShortName = undefined;
            } else if (e.boostId) {
              e.boostId = e.boostId.join(',')
            }
            if (e.dcCode && typeof e.dcCode !== 'string') {
              e.dcCode = e.dcCode.join(',')
            }
            if ((form.computeType !== 2 && form.computeType !== 5) && e.limitReward!== undefined) {
              e.limitReward = undefined;
            }
            if (e.importType === 0) {
              e.achieveNum = undefined;
              e.rewardNum = undefined;
            } else if (e.importType === 1) {
              e.rewardNum = undefined;
            } else if (e.importType === 2) {
              e.achieveNum = undefined;
            }
          })

          form.limits = allLimits;
          form.userData = allUsers;
          this.targetDrawer.buttonLoading = true;
          saveTargetData(form).then(res => {
            if (res.code == 200) {
              Notification.success("操作成功!");
              this.handleFilter();
              this.targetDrawer.visible = false;
            }
            this.targetDrawer.buttonLoading = false;
          })
        }
      })
    },
    getUserKey(e) {
      let key = e.userName + "-" + e.jxQuotaId + "-" +  e.shopRange + "-" +  e.dcCode + "-" +  e.boostId;
      if (e.boostId) {
        if (typeof e.boostId == "string") {
          key+= e.boostId.split(',').sort((a, b) => a - b).join(',');
        } else {
          key+=e.boostId.sort((a, b) => a - b).join(',');
        }
      }
      return key;
     },
    handleRemove(row) {
      this.$confirm('请确认是否删除所选的数据', '提示', { type: 'warning' }).then(() => {
        removeTarget(row.id).then((res) => {
          if (res.code == 200) {
            Notification.success("操作成功！");
            this.handleFilter();
          }
        })
      }).catch(() => {
      })
    },
    handleTargetSort() {
      const ids = this.pageData.rows.map(e => e.id)
      if (ids.length === 0) {
        Notification.warning("无可操作书卷！");
        return;
      }
      saveTargetSort({ ids: ids}).then(res => {
        if (res.code == 200) {
          Notification.success("操作成功！");
          this.handleFilter();
        }
      })
    },
    handleAddQuota() {
      this.getQuotaOptions();
      this.quotaDialog.quotaIds = [];
      this.quotaDialog.visible = true;
    },
    handleAddQuotas() {
      this.handleQuotaChange(this.quotaDialog.quotaIds, 'add');
      this.quotaDialog.visible = false;
    },
    getQuotaTagType(array, id) {
      const num = array.indexOf(id)%5
      switch (num) {
        case 0:
          return ''
        case 1:
          return 'success'
        case 2:
          return 'info'
        case 3:
          return 'danger'
        case 4:
          return 'warning'
      }
    },
    handleSetQuotaLimit(id) {
      this.quotaLimitDialog.quotaId = id + '';
      this.quotaLimitDialog.limitCheckedObj = deepClone(this.targetDrawer.form.quotaData[id + ''].limitCheckedObj);
      this.quotaLimitDialog.limits = deepClone(this.targetDrawer.form.quotaData[id + ''].limits)
      this.quotaLimitDialog.visible = true;
    },
    submitSetLimits() {
      this.targetDrawer.form.quotaData[this.quotaLimitDialog.quotaId].limitCheckedObj = this.quotaLimitDialog.limitCheckedObj;
      this.targetDrawer.form.quotaData[this.quotaLimitDialog.quotaId].limits = this.quotaLimitDialog.limits;
      this.quotaLimitDialog.visible = false;
    },
    handleFilterChange(val) {
      if (this.$refs.userTagTreeRef) {
        this.$refs.userTagTreeRef.filter(val);
      }
    },
    handleTreeCheckedAll() {
      let allKeys = [];
      this.userTagTree.forEach(e => {
        if (e.users) {
          e.users.forEach(e => allKeys.push(e.id))
        }
      })
      if (this.$refs.userTagTreeRef) {
        this.$refs.userTagTreeRef.setCheckedKeys(allKeys);
        this.userDialog.checkedUsers = this.$refs.userTagTreeRef.getCheckedNodes(true, false);
      }
    },
    filterUserTagNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    handleUserTagChange(node, data, key) {
      let checkNodes = [];
      let checkKeys = [];
      data.checkedNodes.forEach(e => {
        if (e.users.length == 0) {
          if (checkKeys.indexOf(e.id) < 0) {
            checkNodes.push(e);
            checkKeys.push(e.id);
          }
        }
      })
      this.userDialog.checkedUsers = checkNodes;
    },
    handleCopyTargetDialog(row) {
      this.copyTargetDialog.startTime = undefined;
      this.copyTargetDialog.endTime = undefined;
      this.copyTargetDialog.showDate = [];
      this.copyTargetDialog.targetId = row.id;
      this.copyTargetDialog.visible = true;
    },
    submitTargetCopy() {
      this.$refs.copyTargetFormRef.validate(valid => {
        if (valid) {
          copyTarget(this.copyTargetDialog.targetId, { startTime: this.copyTargetDialog.startTime, endTime: this.copyTargetDialog.endTime}).then(res => {
            if (res.code == 200) {
              Notification.success("操作成功！");
              this.handleFilter();
              this.copyTargetDialog.visible = false;
            }
          })
        }
      })
    },
    handleUserCopy(row, index) {
      this.copyUserDialog.userNames = [];
      this.copyUserDialog.userOptions = [];
      let userKeys = [];
      this.targetDrawer.form.userData.forEach(e => {
        if (userKeys.indexOf(e.userName) < 0 && row.userName!==e.userName) {
          userKeys.push(e.userName);
          this.copyUserDialog.userOptions.push({ userName: e.userName, nickName:e.nickName });
        }
      })
      this.copyUserDialog.sourceUserName = row.userName;
      this.copyUserDialog.source = deepClone(row);
      this.copyUserDialog.sourceIndex = index
      this.copyUserDialog.visible = true;
    },
    submitUserCopy() {
      this.$refs.copyUserFormRef.validate(valid => {
        if (valid) {
          const source = deepClone(this.targetDrawer.form.userData[this.copyUserDialog.sourceIndex]);
          this.targetDrawer.form.userData = this.targetDrawer.form.userData.map(e => {
            if (this.copyUserDialog.userNames.indexOf(e.userName) > -1 && e.jxQuotaId === source.jxQuotaId) {
              let obj = JSON.parse(JSON.stringify(source));
              obj.userName = e.userName;
              obj.nickName = e.nickName;
              obj.shopRange = e.shopRange;
              obj.dcCode = e.dcCode;

              obj.userRange = e.userRange
              obj.avgUsers = e.avgUsers;
              e = obj;
            }
            return e;
          })
          this.$forceUpdate();
          this.copyUserDialog.visible= false
          Notification.success("操作成功！");
          this.resetPageData();
        }
      })
    },
    handleShopRangeChange(val, row, index) {
      row.dcCode = undefined;
    },
    handleCheckAll(action) {
      if (action) {
        this.copyUserDialog.userNames = [].concat(this.copyUserDialog.userOptions.map(e => e.userName));
      } else {
        this.copyUserDialog.userNames = [];
      }
    },
    handleDownloadTemplate(type) {
      let data = deepClone(this.targetDrawer.form.userData.filter((e,index) => {
        e.index = index + 1;
        return (type === 'achieve' && e.importType === 1) || (type === 'reward' && e.importType === 2);
      }));
      if (data.length === 0) {
        Notification.warning("没有模板数据可导出！");
        return;
      }
      data.forEach(e => {
        if (this.options.boosts.length > 0 && e.boostId) {
          e.boostName = this.options.boosts.filter(e => e.boostId.indexOf(e.id) > -1).map(e => e.name).join('、');
        }
        if (e.shopRange === '1') {
          e.shopRange = '指定包干负责人'
        } else if (e.shopRange === '2') {
          e.shopRange = '指定配送中心'
        } else if (e.shopRange === '3') {
          e.shopRange = '组织结构下的店铺范围'
        }
        e.quotaName = this.getQuotaInfo(e.jxQuotaId).quotaName
        e.numA = e.rules[0].numA;
      })
      excelExport({
        topHeaders: this.getHeader(type),
        data: data,
        title: type === 'achieve'? '导出用户达成数据模板数据': '导出用户结果数据模板数据',
      });
    },
    isExcel(file) {
      return /\.(xlsx|xls|csv)$/.test(file.name)
    },
    isCsv(file) {
      return /\.(csv)$/.test(file.name)
    },
    getHeader(type) {
      if (type === 'achieve' || type === 'reward') {
       return deepClone(Object.assign({}, this.exportTargetHeaders.baseHeaders ,this.exportTargetHeaders[type][this.targetDrawer.form.computeType]))
      }
    },
    readerData: function (rawFile, type) {
      if (!this.isExcel(rawFile)) return;

      let isCSV = this.isCsv(rawFile);

      this.loading = true
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = e => {
          const data = e.target.result
          const workbook = read(data, {type: isCSV ? 'string' : 'array', codepage: 936})
          const firstSheetName = workbook.SheetNames[0]
          const worksheet = workbook.Sheets[firstSheetName]
          const header = this.getImportHeaderRow(worksheet)
          let results = JSON.parse(JSON.stringify(utils.sheet_to_json(worksheet, { defval: null })))
          // console.log("results", header, results);
          console.log(header, results)
          const headers = this.getHeader(type);
          let arrays = [];
          results.forEach(r => {
            let map = {};
            for (const k in headers) {
              if (r[headers[k]]) {
                map[k] = r[headers[k]];
              }
            }
            if (Object.keys(map).length > 0) {
              arrays.push(map);
            }
          })
          this.importDialog.uploadData = arrays;
          this.loading = false
          resolve()
        }
        if(isCSV) {
          reader.readAsText(rawFile)
        } else {
          reader.readAsArrayBuffer(rawFile)
        }

      })
    },
    getImportHeaderRow(sheet) {
      const headers = []
      const range = utils.decode_range(sheet['!ref'])
      let C
      const R = range.s.r
      /* start in the first row */
      for (C = range.s.c; C <= range.e.c; ++C) { /* walk every column in the range */
        const cell = sheet[utils.encode_cell({ c: C, r: R })]
        /* find the cell in the first row */
        let hdr = 'UNKNOWN ' + C // <-- replace with your desired default
        if (cell && cell.t) hdr = utils.format_cell(cell)
        headers.push(hdr)
      }
      return headers
    },
    handleExport(type) {
      let data = deepClone(this.targetDrawer.form.userData.filter((e,index) => {
        e.index = index + 1;
        return (type === 'achieve' && e.importType === 1) || (type === 'reward' && e.importType === 2);
      }));
      if (data.length === 0) {
        Notification.warning("没有数据可导出！");
        return;
      }

      data.forEach(e => {
        if (this.options.boosts.length > 0 && e.boostId) {
          e.boostName = this.options.boosts.filter(e => e.boostId.indexOf(e.id) > -1).map(e => e.name).join('、');
        }
        if (e.shopRange === '1') {
          e.shopRange = '指定包干负责人'
        } else {
          e.shopRange = '指定配送中心'
        }
        e.quotaName = this.getQuotaInfo(e.jxQuotaId).quotaName
        e.numA = e.rules[0].numA;
      })
      excelExport({
        topHeaders: this.getHeader(type),
        data: data,
        title: type == 'achieve'? '导出用户达成数据': '导出用户结果数据',
      });
    },
    handleSetDcUser(index, dcCodes, avgUsers) {
      this.dcUsersDialog.index = index;
      this.dcUsersDialog.loading = true;
      this.dcUsersDialog.visible = true;
      pageUserTag({ orgCode: this.targetDrawer.form.orgCode,dcCodes: [dcCodes], size: 1000, current: 1}).then(res => {
        if (res.code == 200) {
          this.dcUsersDialog.loading = false;
          this.dcUsersDialog.rows = res.data.records;
          this.$nextTick(() => {
            if (this.$refs.dcUserTableRef) {
              this.dcUsersDialog.rows.forEach(e => {
                if (avgUsers && avgUsers.indexOf(e.userName) > -1) {
                  this.$refs.dcUserTableRef.toggleRowSelection(e, true)
                }
              });
            }
          })
        }
      })
    },
    handleDcUserSelectionChange(selections) {
      this.dcUsersDialog.selections = selections;
      this.dcUsersDialog.avgUsers = selections.map(e => e.userName);
    },
    handleRemoveAvgUser(item) {
      const r = this.dcUsersDialog.selections.find(e => e.userName === item);
      if (r) {
        this.$refs.dcUserTableRef.toggleRowSelection(r, false)
      }
    },
    submitAddDcUser() {
      this.targetDrawer.form.userData[this.dcUsersDialog.index].avgUsers = deepClone(this.dcUsersDialog.avgUsers);
      this.$forceUpdate();
      this.$refs.userTableRef.doLayout();
      this.dcUsersDialog.visible = false;
    },
    getImportTypeOptions(quotaId) {
      if (this.targetDrawer.form.condition!=='1') {
        return this.options.importTypeOptions.filter(e => e.value === 0)
      }
      return this.getQuotaInfo(quotaId).quotaTypeCode === 'CUSTOM' ? this.options.importTypeOptions.filter(e => e.value!==0) : this.options.importTypeOptions
    },
    handleConsole() {
      this.targetDrawer.form.userData = JSON.parse(JSON.stringify(this.targetDrawer.form.userData));
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-table__row {
  td:not(.is-hidden):first-child, td:not(.is-hidden):last-child {
    bottom: 0px;
  }
}
::v-deep .el-drawer__header {
  margin-bottom: 0px;
}
::v-deep .el-dialog__body {
  padding: 0 20px;
}
</style>
