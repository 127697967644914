<template>
  <div v-loading="homeLoading" style="padding: 20px">
    <div style="padding-bottom: 15px">
      <el-form ref="queryFormRef" size="mini" label-width="auto" :inline="true" :model="queryForm" @keyup.enter.native="handleQuery">
        <el-form-item label="商品ID">
          <el-input v-model="queryForm.itemId" clearable />
        </el-form-item>
        <el-form-item label="SKUID">
          <el-input v-model="queryForm.skuId" clearable />
        </el-form-item>
        <el-form-item label="分公司">
          <DictSelect type="org" :value.sync="queryForm.orgNames" :dictFun="dictFun" multiple />
        </el-form-item>
        <el-form-item label="平台">
          <DictSelect
            ref="platform"
            type="platform"
            :value.sync="queryForm.platforms"
            multiple
            linkageRef="shop"
            :dictFun="dictFun"
          />
        </el-form-item>
        <el-form-item label="店铺名称">
          <DictSelect
            ref="shop"
            type="shop"
            parentLinkageRef
            :value.sync="queryForm.shopNames"
            :dictFun="dictFun"
            multiple
            remote
            @getOtherJsonParam="(callback) => callback({ platforms: queryForm.platforms})"
          />
        </el-form-item>
        <el-form-item label="赛道">
          <DictSelect
            ref="race"
            type="race"
            :value.sync="queryForm.raceCodes"
            :dictFun="dictFun"
            multiple
            linkageRef="main"
            @changeLinkageRef="handleChangeLinkageRef"
          />
        </el-form-item>
        <el-form-item label="大类">
          <DictSelect
            ref="main"
            type="main"
            linkageRef="mid"
            parentLinkageRef
            :value.sync="queryForm.mainCategoryCodes"
            :dictFun="dictFun"
            multiple
            @getOtherJsonParam="(callback) => callback({ ...queryForm })"
            @changeLinkageRef="handleChangeLinkageRef"
          />
        </el-form-item>
        <el-form-item label="中类">
          <DictSelect
            ref="mid"
            type="mid"
            parentLinkageRef
            :value.sync="queryForm.midCategoryCodes"
            :dictFun="dictFun"
            linkageRef="sub"
            multiple
            @getOtherJsonParam="(callback) => callback({ ...queryForm })"
            @changeLinkageRef="handleChangeLinkageRef"
          />
        </el-form-item>
        <el-form-item label="小类">
          <DictSelect
            ref="sub"
            type="sub"
            parentLinkageRef
            :value.sync="queryForm.subCategoryCodes"
            :dictFun="dictFun"
            multiple
            @getOtherJsonParam="(callback) => callback({ ...queryForm })"
          />
        </el-form-item>
        <el-form-item label="商品型号">
          <DictSelect type="sku" :value.sync="queryForm.ecCargoNos" :dictFun="dictFun" remote multiple />
        </el-form-item>

        <el-form-item label="警示价">
          <el-input-number v-model="queryForm.warningPrice" clerable :step="2" :min="0" :controls="false" />元
        </el-form-item>
        <el-form-item label="控价结果">
          <DictSelect disabled="true" type="controlPrice" :value.sync="queryForm.controlPriceResult" :dictFun="dictFun"/>
        </el-form-item>
        <el-form-item label="价差">
          <el-input-number v-model="queryForm.diffPriceStart" clearable="" style="width: 100px" @input="handleDiffPriceStart">
          </el-input-number>
          %&nbsp;至&nbsp;
          <el-input-number v-model="queryForm.diffPriceEnd" clearable="" style="width: 100px" @input="handleDiffPriceEnd">
            <template slot="suffix">%</template>
          </el-input-number>
          %
        </el-form-item>
        <el-row>
          <el-form-item label="价格更新时间">
            <el-date-picker
              v-model="queryForm.priceModifiedDate"
              size="mini"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
              style="width: 360px">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="是否组装" prop="ifCombined">
            <DictSelect
              ref="ifSpu"
              type="ifSpu"
              :value.sync="queryForm.ifCombined"
              :dictFun="dictSpuFun"
              @getOtherJsonParam="(callback) => callback({ platforms: queryForm.platforms})"
              style="width: 500px"
            />
          </el-form-item>
          <el-form-item label="快捷筛选">
            <el-select v-model="queryForm.tag" placeholder="请选择">
              <el-option
                v-for="item in tagOptions"
                :key="item"
                :label="item"
                :value="item">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button class="form-btn-item" size="mini" icon="el-icon-search" type="primary" @click="handleFilter">查询</el-button>
            <el-button class="form-btn-item" size="mini" icon="el-icon-refresh" type="primary" @click="resetQuery">重置</el-button>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item>
            <el-button class="form-btn-item" size="mini" icon="el-icon-download" type="primary" @click="handleExportNotice">导出</el-button>
            <el-button class="form-btn-item" size="mini" icon="el-icon-delete-solid" type="primary" @click="handleBatchSaveNotice">清除所选项的备注</el-button>
            <!--        <el-button class="form-btn-item" size="mini" type="primary" @click="handleBatchMatchConfirm">批量确认匹配</el-button>-->
            <el-button class="form-btn-item" size="mini" type="primary" @click="handleSkuPriceControlTraceGenerate">生成控价追踪表</el-button>
            <el-popover
              placement="right"
              width="600"
              trigger="hover">
              <div style="overflow-y: scroll;">
                <label>功能说明：</label><br>
                1.页面商品需要有到SKU的对应关系，系统会根据页面信息及图片识别。如未识别上需要人工点击【匹配】按钮进行维护<br>
                2.控价计算结果需要依赖 控价表，用户可以点击 【导出控价表】获取当前控价信息。通过【导入控价表】可以覆盖最新控价信息。<br>
                3.控价到型号、规格、售卖数量上下限。型号需要完全一致，规格如果是一级规格（支、块、套、卡、本、包、袋、件、桶、本体、把、箱、张、个、筒）会自动转成"个"匹配上。<br>
                4.价差=(当前售价-控价总价)/控价总价，可以通过筛选项查找价差范围，如价差非常大，可能匹配关系异常，建议复核<br>
                5.警示价:即低于筛选低于警示价的所有型号，您可以针对指定型号或某个品类进行操作，快速找到低价品。<br>
                <br>

                <label>控价逻辑：</label><br>
                1.在控价表中的型号及规格范围，用   [页面售价]  与 [符合规格数量的控价] 对比<br>
                2.如控价表中没有的型号，则以  [零售价*页面售卖数量] 的9折对比 [页面售价]<br>
                <br>

                <label>控价结果解释：</label><br>
                1.符合控价： 价格高于控价<br>
                2.价格低于控价： 价格低于控价<br>
                3.不符合控价售卖规格：控价表中没有该型号页面售卖的规格<br>
                4.匹配关系缺失：页面商品型号或规格未知，点击【匹配】维护
                <br>
              </div>
              <el-link icon="el-icon-question" type="info" slot="reference" style="margin-left: 10px">使用说明</el-link>
            </el-popover>
          </el-form-item>
        </el-row>
      </el-form>
    </div>

    <div v-show="tabName=='Notice'">
      <el-table
        ref="tableRef"
        :data="pageNoticeData.rows"
        height="calc(100vh - 250px)"
        v-loading="noticeLoading"
        size="mini"
        border
        highlight-current-row
        :header-cell-style="{ background: 'rgb(235 235 235)', color: 'black',textAlign: 'center', verticalAlign: 'middle' }"
        @sort-change="handleSortChange"
        @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="90"></el-table-column>
        <el-table-column label="平台" prop="ptName" min-width="60" />
        <el-table-column label="分公司" prop="orgName" min-width="80" />
        <el-table-column label="店铺名称" prop="shopName" width="200" />
        <el-table-column label="页面标题" prop="title" width="200" />
        <el-table-column label="商品ID" prop="itemId" min-width="100" />
        <el-table-column label="SKUID" prop="skuId" min-width="100" />
        <el-table-column label="商品信息" prop="skuName" min-width="200">
          <template #default="{ row }">
            <a target="_blank" style="color: #6a6ae9;font-size: 12px" :href="row.skuUrl">{{ row.skuName }}</a>
          </template>
        </el-table-column>
        <el-table-column label="商品图片" prop="skuPic" width="100">
          <template #default="{ row }">
            <el-image :preview-src-list="[row.skuPic]" :src="row.skuPic" lazy style="height: 80px;margin: 5px;" />
          </template>
        </el-table-column>
        <el-table-column label="匹配关系" prop="finalMatch" min-width="200">
          <template #default="{ row }">
            <div v-if="row.finalMatch">
              <p v-for="item in row.finalMatch.split('\n')" :key="item"> {{ item }}</p>
            </div>
            <el-button class="form-btn-item" size="mini" type="text" @click="handleJumpQuery(row.skuId)">匹配</el-button>
            <el-button v-if="row.ifShowConfirm === 'N'" class="form-btn-item" size="mini" type="text" @click="handleMatchConfirm(row)">确认</el-button>
          </template>
        </el-table-column>
        <el-table-column label="折算最小单位数量" prop="discountMinUnitQuantity" min-width="80" />
        <el-table-column label="控价单价" prop="controlMinUnitPrice" min-width="80" />
        <el-table-column label="x 控价规格" prop="saleUnitKj" min-width="80" />
        <el-table-column label="= 控价总价" prop="controlTotalPrice" min-width="80">
          <template slot="header">
            <div style="background-color: #99cbff">= 控价总价</div>
          </template>
        </el-table-column>
        <el-table-column label="当前售价" prop="currentSellPrice" min-width="80">
          <template slot="header">
            <div style="background-color: #99cbff">当前售价</div>
          </template>
          <template #default="{ row }">
            <el-popover
              placement="top-start"
              width="80"
              trigger="hover"
              v-if="row.screenshotImageUrl"
            >
              <el-image v-if="row.screenshotImageUrl" :preview-src-list="[row.screenshotImageUrl]" :src="row.screenshotImageUrl" style="height: 80px;margin: 5px;" />
<!--              <span v-else>图片待上传</span>-->
              <el-button slot="reference" type="text">{{ row.currentSellPrice }}</el-button>
            </el-popover>
            <span v-else>{{ row.currentSellPrice }}</span>
          </template>
        </el-table-column>
        <el-table-column label="价差" prop="diffPrice" sortable min-width="80" >
          <template #default="{ row }">
            <span v-if="row.diffPrice != undefined" :style="row.diffPrice < 0? {color: 'red'}: {}">{{ row.diffPrice + '%' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="控价赠品说明" prop="controlPriceGiftExplain" min-width="80" />
        <el-table-column label="控价结果" prop="controlPriceResult" min-width="80">
          <template #default="{ row }">
            <span :style="row.controlTotalPrice>row.currentSellPrice? {color: 'red'}: {}">{{ row.controlPriceResult }}</span>
          </template>
        </el-table-column>
        <el-table-column label="价格更新时间" prop="priceModified" sortable min-width="140" />
        <el-table-column label="备注" prop="orgName" min-width="140">
          <template #default="{ row }">
            <el-input v-model="row.remark" size="mini" @input="handleUpdatePriceNotice(row)" placeholder="输入后自动保存" />
          </template>
        </el-table-column>
      </el-table>
      <Pagination :total="pageNoticeData.total" :page.sync="queryForm.page" :limit.sync="queryForm.limit" @pagination="handleQuery" />
    </div>
    <el-dialog title="请选择正确的匹配关系" :visible.sync="skuModelUpdateDialog.visible" append-to-body width="1200px">
      <div style="overflow-y: scroll;max-height: 860px">
        <el-form ref="saveSkuModelUpdateFormRef" size="mini" :inline="true" :model="skuModelUpdateDialog.form">
          <div>
            <el-row style="width: 1100px">
              <el-col>
                <div>
                  <span style="font-size: 16px;font-weight: bold">识别到可能是以下商品,请选择:</span>
                </div>
                <div v-for="item in skuModelUpdateOptions"
                     :key="item.skuModel"
                     :label="item.skuModel" style="display: inline-block;margin-top: 1%;margin-left: 1%">
                  <el-carousel height="200px">
                    <el-carousel-item v-for="item1 in item.skuUrls" :key="item1">
                      <el-image :preview-src-list="[item1]" :src="item1" />
                    </el-carousel-item>
                  </el-carousel>
                  <div class="button-group">
                    <el-button :title="item.skuModelName" class="btn button-online" :class="{'selected': skuModelUpdateDialog.selectSkuModelButton === item.skuModel}" style="width: 200px;font-size: 10px;margin-top: 1%" @click="handSkuModelChange(item)">{{item.skuModel}}<br>{{'型号品名：' + item.skuModelName}}</el-button>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
          <div style="border: 1px dashed red;margin-top: 1%;width: 1120px">
            <el-form-item label="最终型号" class="redLabel" style="margin-top: 1%;margin-left: 2%;color: #ea2838"></el-form-item>
            <el-form-item label="请从上方选择自动带入型号或下拉直接选择:" style="margin-top: 1%">
              <DictSelect
                ref="skuModel"
                type="sku_model"
                :value.sync="skuModelUpdateDialog.form.skuModelByPerson"
                :dictFun="dictSkuModelsFun"
                :init-props="{ label: 'skuModel', value: 'skuModel'}"
                @change="val =>handSkuModelInfoChange(val)"
                remote
              />
            </el-form-item>
          </div>
          <div>
            <el-row>
              <el-col :span="12">
                <div style="display: inline-block;margin-left: 30%;margin-top: 1%;border: 1px dashed black;height: 350px">
                  <el-carousel height="300px" style="width: 300px;">
                    <el-carousel-item v-for="item in skuModelUpdateDialog.selectSkuModelOptions" :key="item">
                      <el-image :preview-src-list="[item]" :src="item" />
                    </el-carousel-item>
                  </el-carousel>
                  <div style="width: 300px;height: 20px;text-align:center">
                    <span style="font-size: 12px">你最终选择匹配的商品图片<br>{{'型号品名：' + skuModelUpdateDialog.skuModelName}}</span>
                  </div>
                </div>

              </el-col>
              <el-col :span="12">
                <div style="display: inline-block;margin-left: 10%;margin-top: 1%;border: 1px dashed black;height: 350px">
                  <el-carousel height="300px" style="width: 300px;text-align:center;">
                    <el-carousel-item v-for="item in [skuModelUpdateDialog.form.skuPic]" :key="item">
                      <el-image :preview-src-list="[item]" :src="item" style="height: 300px"/>
                    </el-carousel-item>
                  </el-carousel>
                  <div style="text-align:center;height: 20px">
                    <span style="font-size: 12px">店铺商品图片<br>{{'型号品名：' + skuModelUpdateDialog.shopSkuModelName}}</span>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
          <div style="margin-top: 1%">
            <div>
              <span style="font-size: 16px;font-weight: bold">识别到规格,请确认或修改:</span>
            </div>
            <div style="border: 1px dashed red;margin-top: 1%;width: 1120px">
              <el-form-item label="最终规格" class="redLabel" style="margin-top: 1%;margin-left: 2%"></el-form-item>
              <el-form-item label="识别到规格:" prop="skuSpecificationsBySystem" style="margin-top: 1%" >
                <span>{{skuModelUpdateDialog.form.skuSpecificationsBySystem}}</span>
              </el-form-item>
              <el-form-item label="人工维护售卖规格" prop="skuSpecificationsByPerson" style="margin-top: 1%" >
                <el-input v-model="skuModelUpdateDialog.form.skuSpecificationsByPerson" clearable placeholder="请输入规格" />
              </el-form-item>
              <el-form-item label="是否组装" prop="ifCombined" style="margin-top: 1%" >
<!--                <el-select v-model="skuModelUpdateDialog.form.ifCombined" size="mini" clearable>
                  <el-option value="是" label="是" /><el-option value="否" label="否" />
                </el-select>-->
                <DictSelect
                  ref="ifSpu"
                  type="ifSpu"
                  :value.sync="skuModelUpdateDialog.form.ifCombined"
                  :dictFun="dictSpuFun"
                  @getOtherJsonParam="(callback) => callback({ platform: skuModelUpdateDialog.form.ptName})"
                  style="width: 500px"
                />
              </el-form-item>
            </div>
          </div>
        </el-form>
        <div slot="footer" class="dialog-footer" style="width: 90%;background-color: white;height: 30px">
          <div style="display: inline-block; float: right;margin-top: 1%">
            <el-button type="primary" size="mini" @click="skuModelUpdateDialog.visible=false">取消</el-button>
            <el-button type="primary" size="mini" @click="submitSkuModelEdit">确定</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import {
  pageControlPrice,
  getControlPriceOptions,
  updateTechMatch,
  exportPriceNotice,
  exportOnSaleGoods,
  pagePriceNotice,
  updatePriceNotice,
  priceControlConfirm,
  priceControlBatchConfirm,
  pageTechMatch,
  getSkuModelInfoList,
  getTechSpuPriceControlOptions,
  skuPriceControlTraceGenerate,
  getTechMatchTagOptions
} from "@/api/eCommerce";
import { parseTime, deepClone } from "@/utils"
import { Notification } from "element-ui";
import DictSelect from "@/components/DictSelect";

export default {
  name: "SinglePriceControlRemind",
  components: { Pagination, DictSelect},
  data() {
    return {
      homeLoading: false,
      tabName: 'Notice',
      dictFun: getControlPriceOptions,
      dictSkuModelsFun: getSkuModelInfoList,
      dictSpuFun: getTechSpuPriceControlOptions,
      pageGoodsData: {
        total: 0,
        rows: [],
        selections: [],
      },
      pageNoticeData: {
        total: 0,
        rows: [],
        selections: [],
      },
      queryForm: {
        page: 1,
        limit: 10,
        orgNames: [],
        platforms: [],
        shopNames: [],
        raceCodes: [],
        mainCategoryCodes: [],
        midCategoryCodes: [],
        subCategoryCodes: [],
        ecCargoNos: [],
        controlPriceResult: '价格低于控价',
        warningPrice: undefined,
        diffPriceStart: undefined,
        diffPriceEnd: undefined,
        priceModifiedDate: undefined,
        priceModifiedStartDate: undefined,
        priceModifiedEndDate: undefined,
        order: {
          column: undefined,
          operator: undefined
        },
        skuId: undefined,
        ifPersonConfirm: 'Y',
        ifCombined: undefined,
        itemId: undefined,
        tag: '全部',
      },
      goodsLoading: false,
      noticeLoading: false,
      dictParam: {},
      currentRow: {},
      currentIndex: undefined,
      options: {
        controlOptions: [],
      },
      parseTime: parseTime,
      importDialog: {
        visible: false,
        fileList: [],
        accept: ['.xls', '.xlsx'],
        title: '238预估补货重点商品导入',
        template: process.env.VUE_APP_OSS_TEMPLATE_PATH +'/%E5%8D%95%E5%93%81%E6%8E%A7%E4%BB%B7%E6%8F%90%E9%86%92%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx'
      },
      submitDisabled: false,
      rules: {
        diffPriceStart: [
          {
            validator: (rule, value, callback) => {
              if (value != undefined && this.queryForm.diffPriceStart != undefined && value < this.queryForm.diffPriceStart) {
                callback(new Error('最大值不能小于最小值'));
              } else {
                callback();
              }
            }, trigger: ["blur", "change"]
          }
        ],
      },
      skuModelUpdateDialog: {
        visible: false,
        form: {
          ptName: undefined,
          skuId: undefined,
          shopName: undefined,
          skuModelByPerson: undefined,
          skuSpecificationsByPerson: undefined,
          operateType: undefined,
          finalMatchSkuSpecifications: undefined,
          finalMatchSkuModel: undefined,
          skuPic: undefined,
          ifCombined: undefined,
          skuSpecificationsBySystem: undefined,
        },
        selectSkuModelOptions: [],
        selectSkuModelButton: undefined,
        skuModelName: '',
      },
      skuModelUpdateOptions: [],
      tagOptions: []
    };
  },
  watch: {
  },
  created() {
    getTechMatchTagOptions().then(res => {
      this.tagOptions = res.data;
    })
    this.queryForm.page = 1;
    this.handleFilter();
  },
  mounted() {
  },
  methods: {
    handleQuery() {
      switch (this.tabName) {
        case 'Goods':
          this.handleQueryGoods();
          break;
        case 'Notice':
          this.handleQueryNotice();
          break;
        case 'Trace':
          this.handleQueryTrace();
          break;
      }
    },
    handleQueryGoods() {
      this.goodsLoading = true;
      const query = Object.assign({}, this.queryForm);
      pageControlPrice(query).then(res => {
        if (res.code !== 200) {
          return false;
        }
        this.goodsLoading = false;
        this.pageGoodsData.rows = res.data?.datas;
        this.pageGoodsData.total = res.data?.total;
        this.$nextTick(() => {
          this.$refs.tableRef.doLayout();
        })
      });
    },
    handleQueryNotice() {
      this.noticeLoading = true;
      const query = Object.assign({}, this.queryForm);
      for (const key in this.queryForm) {
        if (this.queryForm[key]) {
          if (this.queryForm[key] instanceof Array) {
            if (key === 'priceModifiedDate') {
              query.priceModifiedStartDate = this.queryForm[key][0];
              query.priceModifiedEndDate = this.queryForm[key][1];
            }
          }
        }
      }
      pagePriceNotice(query).then(res => {
        if (res.code !== 200) {
          return false;
        }
        this.noticeLoading = false;
        this.pageNoticeData.rows = res.data?.datas;
        this.pageNoticeData.total = res.data?.total;
        this.$nextTick(() => {
          this.$refs.tableRef.doLayout();
        })
      });
    },
    handleSelectionChange(selections) {
      this.pageNoticeData.selections = selections;
    },
    handleFilter() {
      this.queryForm.page = 1;
      this.handleQuery();
    },
    resetQuery() {
      const queryForm = {
        page: 1,
        limit: 10,
        orgNames: [],
        platforms: [],
        shopNames: [],
        raceCodes: [],
        mainCategoryCodes: [],
        midCategoryCodes: [],
        subCategoryCodes: [],
        ecCargoNos: [],
        controlPriceResult: '价格低于控价',
        warningPrice: undefined,
        diffPriceStart: undefined,
        diffPriceEnd: undefined,
        priceModifiedDate: undefined,
        priceModifiedStartDate: undefined,
        priceModifiedEndDate: undefined,
        order: {
          column: undefined,
          operator: undefined
        },
        skuId: undefined,
        ifPersonConfirm: 'Y',
        tag: '全部',
      };
      this.queryForm = Object.assign({}, queryForm);
      this.$forceUpdate();
    },
    handleSortChange(column) {
      this.queryForm.order.column = column.prop;
      this.queryForm.order.operator = column.order === "ascending" ?"ASC" : "DESC";
      this.handleFilter();
    },
    handleChangeLinkageRef(ref, val, type) {
      this.$nextTick(() => {
        ref.split(',').forEach(e => {
          if (this.$refs[e]) {
            setTimeout(() => {
              this.$refs[e].handleRefChange(val, type);
            }, 100)
            this.$forceUpdate();
          }
        })
      });
    },
    handleEdit(row, index) {
      this.currentRow = deepClone(row);
      this.currentIndex = index;
    },
    submitEdit(row, operateType) {
      updateTechMatch({...row, ...{ operateType: operateType }}).then(res => {
        if (res.code == 200) {
          this.$message.success("操作成功！");
          this.currentIndex = undefined;
          this.currentRow = undefined;
          this.handleFilter();
        }
      })
    },
    handleRowClick(row, type) {
      if (type === 'Goods') {
        this.queryForm.platforms= [row.ptName];
        this.$refs["shop"].remoteMethod('', true);
        this.queryForm.shopNames = [row.shopName];
        this.tabName = 'Notice';
        this.handleQueryNotice();
      }
    },
    handleExportGoods() {
      this.homeLoading = true;
      exportOnSaleGoods(this.queryForm).then(res => {
        if (res.code == 200) {
          if (res.data) {
            const link = document.createElement('a');
            link.href = res.data;
            link.download = "在售商品导出";
            link.target = "_blank"; // 可选，如果希望在新窗口中下载文件，请取消注释此行
            link.click()
          }else {
            Notification.info("无可下载数据！");
          }
        }
        this.homeLoading = false;
      })
    },
    handleExportNotice() {
      this.homeLoading = true;
      exportPriceNotice(this.queryForm).then(res => {
        if (res.data) {
          const link = document.createElement('a');
          link.href = res.data;
          link.download = "单品控价提醒导出";
          link.target = "_blank"; // 可选，如果希望在新窗口中下载文件，请取消注释此行
          link.click()
        } else {
          Notification.info("无可下载数据！");
        }
        this.homeLoading = false;
      })
    },
    handleUpdatePriceNotice(row) {
      this.timer = setTimeout(() => {
        updatePriceNotice({ remarkUpdateDtos: [row]}).then(res => {
          if (res.code==200) {
            Notification.success("操作成功");
            this.handleFilter();
          }
        })
      }, 1000)
    },
    handleBatchSaveNotice(row) {
      let delParam = [];
      if (row && row.skuId) {
        delParam.push({ skuId: row.skuId, ptName: row.ptName, remark: row.remark});
      } else if (this.pageNoticeData.selections.length > 0) {
        delParam = this.pageNoticeData.selections.map(e => {return { skuId: e.skuId, ptName: e.ptName, remark: null }});
      } else {
        Notification.warning("请至少选择一条数据进行操作！");
        return;
      }
      updatePriceNotice({ remarkUpdateDtos: delParam }).then((res) => {
        if (res.code == 200){
          Notification.success("操作成功！");
          this.handleFilter();
        }
      })
    },
    async handleJumpQuery(skuId) {
      // this.$emit('handleJumpQuery', skuId);
      var parameters = {
        page: 1,
        skuId: skuId,
      }
      const res = await pageTechMatch(parameters);
      const code = res.code;
      if (code === 200) {
        this.handleSkuModelEdit(res.data?.datas[0]);
      }else {
        return false;
      }

    },
    handleDiffPriceStart(){
      if(this.queryForm.diffPriceStart != undefined && this.queryForm.diffPriceEnd != undefined && this.queryForm.diffPriceStart > this.queryForm.diffPriceEnd){
        Notification.warning('最小值不能大于最大值');
        this.queryForm.diffPriceStart = undefined;
        this.queryForm.diffPriceEnd = undefined;
      }
    },
    handleDiffPriceEnd(){
      if(this.queryForm.diffPriceStart != undefined && this.queryForm.diffPriceEnd != undefined && this.queryForm.diffPriceStart > this.queryForm.diffPriceEnd){
        Notification.warning('最小值不能大于最大值');
        this.queryForm.diffPriceStart = undefined;
        this.queryForm.diffPriceEnd = undefined;
        this.$forceUpdate();
      }
    },
    handleMatchConfirm(row) {
      priceControlConfirm(row).then((res) => {
        if (res.code == 200){
          Notification.success("操作成功！");
          this.handleQuery();
        }
      })
    },
    handleBatchMatchConfirm(row) {
      let matchParam = [];
      if (row && row.skuId) {
        matchParam.push({ ptName: row.ptName, skuId: row.skuId, shopName: row.shopName, finalMatch: row.finalMatch, ifShowConfirm: row.ifShowConfirm});
      } else if (this.pageNoticeData.selections.length > 0) {
        matchParam = this.pageNoticeData.selections.map(e => {return { ptName: e.ptName, skuId: e.skuId, shopName: e.shopName, finalMatch: e.finalMatch, ifShowConfirm: e.ifShowConfirm}});
      } else {
        Notification.warning("请至少选择一条数据进行操作！");
        return;
      }
      matchParam = matchParam.filter(item => {
        return item.ifShowConfirm.indexOf('N') !== -1;
      });
      priceControlBatchConfirm({ matchBatchConfirms: matchParam }).then((res) => {
        if (res.code == 200){
          Notification.success("操作成功！");
          this.handleQuery();
        }
      })
    },
    handleSkuModelEdit(row) {
      this.rsetSkuModelUpdateDialogForm();
      this.skuModelUpdateDialog.visible = true;
      this.skuModelUpdateOptions = deepClone(row.skuModelInfoDtos);
      this.skuModelUpdateDialog.form = deepClone(row);
      if(row.finalMatchSkuModel){
        this.skuModelUpdateDialog.form.skuModelByPerson = row.finalMatchSkuModel;
        if(row.finalMatchSkuModelInfoDtos.length > 0){
          this.skuModelUpdateDialog.selectSkuModelOptions = row.finalMatchSkuModelInfoDtos[0].skuUrls;
          this.skuModelUpdateDialog.skuModelName = row.finalMatchSkuModelInfoDtos[0].skuModelName;
        }
        this.skuModelUpdateDialog.selectSkuModelButton = row.finalMatchSkuModel;
      }
      this.skuModelUpdateDialog.form.skuSpecificationsByPerson = row.skuSpecificationsByPerson;
      this.skuModelUpdateDialog.form.skuSpecificationsBySystem = row.skuSpecificationsBySystem;
      this.skuModelUpdateDialog.form.shopName = row.shopName;
      this.skuModelUpdateDialog.shopSkuModelName = row.skuName;
      const resp = this.skuModelUpdateDialog.form.skuModelByPerson;
      const parameter = this.skuModelUpdateDialog.form.ptName;
      this.$nextTick(async () => {
        if (this.$refs.skuModel) {
          await this.$refs.skuModel.remoteMethod(resp);
        }
        if (this.$refs.ifSpu) {
          await this.$refs.ifSpu.remoteMethod(parameter)
        }
      });
    },
    rsetSkuModelUpdateDialogForm(){
      this.skuModelUpdateDialog.form = {
        ptName: undefined,
        skuId: undefined,
        shopName: undefined,
        skuModelByPerson: undefined,
        skuSpecificationsByPerson: 1,
        operateType: undefined,
        finalMatchSkuSpecifications: undefined,
        finalMatchSkuModel: undefined,
        skuPic: undefined,
      };
      this.skuModelUpdateDialog.selectSkuModelOptions = [];
      this.skuModelUpdateOptions = [];
      this.selectSkuModelButton = undefined;
      this.skuModelUpdateDialog.skuModelName = '';
      this.skuModelUpdateDialog.shopSkuModelName = '';
    },
    submitSkuModelEdit() {
      const argument = deepClone(this.skuModelUpdateDialog.form);
      argument.operateType = '1';
      updateTechMatch(argument).then(res => {
        if (res.code == 200) {
          this.$message.success("操作成功！");
          this.rsetSkuModelUpdateDialogForm();
          this.skuModelUpdateDialog.visible = false;
          this.handleQuery();
        }
      })
    },
    handSkuModelInfoChange(val){
      if(val===''){
        this.skuModelUpdateDialog.selectSkuModelOptions = [];
        this.skuModelUpdateDialog.selectSkuModelButton = undefined;
        this.skuModelUpdateDialog.skuModelName = '';
      }
      if(this.$refs.skuModel.itemOptions !== null){
        if(this.$refs.skuModel.itemOptions.length > 0){
          const obj = this.$refs.skuModel.itemOptions.find(e => e.skuModel === val);
          this.skuModelUpdateDialog.selectSkuModelOptions = obj.skuUrls;
          this.skuModelUpdateDialog.selectSkuModelButton = obj.skuModel;
          this.skuModelUpdateDialog.skuModelName = obj.skuModelName;
        }
      }
    },
    handSkuModelChange(item){
      this.skuModelUpdateDialog.form.skuModelByPerson = item.skuModel;
      this.skuModelUpdateDialog.selectSkuModelOptions = item.skuUrls;
      this.skuModelUpdateDialog.selectSkuModelButton = item.skuModel;
      this.skuModelUpdateDialog.skuModelName = item.skuModelName;
    },
    handleSkuPriceControlTraceGenerate() {
      let traceParam = [];
      if (this.pageNoticeData.selections.length > 0) {
        traceParam = this.pageNoticeData.selections.map(e => {return { ptName: e.ptName, itemId: e.itemId, skuId: e.skuId,
          shopName: e.shopName, currentSellPrice: e.currentSellPrice}});
      } else {
        Notification.warning("请至少选择一条数据进行操作！");
        return;
      }
      skuPriceControlTraceGenerate({ traceGenerateDtos: traceParam }).then((res) => {
        if (res.code == 200){
          Notification.success("操作成功！");
          this.handleQuery();
        }
      })
    },
  },
};
</script>


<style lang="scss" scoped>

::v-deep .el-table__header .el-table-column--selection .cell .el-checkbox:after {
  content: "本页全选";
  color: black;
  font-weight: bold;
  font-size: 12px;
  margin-left: 5px;
}

.button-online {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.selected {
  color: #409EFF;
  border-color: #c6e2ff;
  background-color: #ecf5ff;
}
</style>
