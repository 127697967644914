<template>
  <div style="padding: 20px">
    <div class="mg-search" style="padding-bottom: 15px">
      <el-form ref="queryFormRef" size="mini" label-width="auto" :inline="true" :model="queryForm" @keyup.enter.native="handleFilter">
        <el-form-item label="分公司">
          <DictSelect
            ref="org"
            type="ht-org"
            :value.sync="queryForm.orgCode"
            linkageRef="dc"
            init
            :initOptions="orgDictInitOptions"
            fullLabel
            :clearable="false"
            placeholder="请选择分公司"
            @changeLinkageRef="handleQueryChangeLinkageRef"
          />
        </el-form-item>
        <el-form-item label="配送中心">
          <DictSelect
            ref="dc"
            type="dc"
            :value.sync="queryForm.dcCodes"
            :dictFun="dictFun"
            parentLinkageRef
            fullLabel
            multiple
            collapse-tags
            @getOtherJsonParam="(callback) => callback(queryForm)"
            @changeLinkageRef="handleChangeLinkageRef"
          />
        </el-form-item>
        <el-form-item>
          <el-button class="form-btn-item" size="mini" icon="el-icon-search" type="primary" @click="handleFilter">查询</el-button>
          <el-button class="form-btn-item" size="mini" icon="el-icon-plus" type="primary" @click="handleDcSystemConfigAdd">新增</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :data="pageData.rows"
      height="calc(100vh - 150px)"
      v-loading="loading"
      size="mini"
      border
      highlight-current-row
      :header-cell-style="{ background: 'rgb(227 224 224)', color: 'black' }"
    >
      <el-table-column label="分公司编码" prop="orgCode" width="100" />
      <el-table-column label="分公司名称" prop="orgName" width="200" />
      <el-table-column label="配送中心编码" prop="dcCode" width="100" />
      <el-table-column label="配送中心名称" prop="dcName" width="200" />
      <el-table-column label="系统名称" prop="systemName" width="120" />
      <el-table-column label="接入方式" prop="joinMode"  width="100" >
        <template slot-scope="scope" >
          <span v-if="scope.row.joinMode === 0">{{'数据库'}}</span>
          <span v-else >{{'接口'}}</span>
        </template>
      </el-table-column>
      <el-table-column label="数据库类型" prop="databaseType" width="120" >
        <template slot-scope="scope" >
          <span v-if="scope.row.databaseType === 0">{{'mysql'}}</span>
          <span v-if="scope.row.databaseType === 1">{{'oracle'}}</span>
          <span v-if="scope.row.databaseType === 2">{{'sql server'}}</span>
          <span v-else >{{''}}</span>
        </template>
      </el-table-column>
      <el-table-column label="链接地址" prop="databaseUrl"  width="120" />
      <el-table-column label="用户名" prop="databaseUsername"  width="120" />
      <el-table-column label="密码" prop="databasePassword"  width="120" />
      <el-table-column label="库名" prop="tableSchema"  width="120" />
      <el-table-column label="店铺维表" prop="shopTable"  width="120" />
      <el-table-column label="接口地址" prop="apiUrl"  width="200" />
      <el-table-column label="关联主键" prop="correlationPrimaryKey"  width="120" />
      <el-table-column label="操作" width="120" align="center">
        <template #default="scope">
          <el-button
            size="mini"
            type="text"
            icon="el-icon-edit"
            @click="handleEditDcSystemConfigForm(scope.row)"
          >编辑</el-button>
          <el-button
            size="mini"
            type="text"
            icon="el-icon-delete"
            @click="handleDeleteDcSystemConfig(scope.row)"
          >删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Pagination :total="pageData.total" :page.sync="queryForm.current" :limit.sync="queryForm.size" @pagination="handleQuery" />

    <el-dialog :title="dcSystemConfigAddDialog.title" :visible.sync="dcSystemConfigAddDialog.visible" width="600px" :close-on-click-modal="false">
      <el-form ref="spuInfoUpdate" size="mini" label-width="100px" :model="dcSystemConfigAddDialog.form" :rules="dcSystemConfigAddDialog.rules">
        <el-form-item label="分公司" prop="orgCode">
          <DictSelect
            ref="addOrg"
            type="ht-org"
            :value.sync="dcSystemConfigAddDialog.form.orgCode"
            linkageRef="addDc"
            init
            :initOptions="orgDictInitOptions"
            fullLabel
            :clearable="false"
            placeholder="请选择分公司"
            @changeLinkageRef="handleQueryChangeLinkageRef"
          />
        </el-form-item>
        <el-form-item label="配送中心" prop="dcCode">
          <DictSelect
            ref="addDc"
            type="ht-dc"
            :value.sync="dcSystemConfigAddDialog.form.dcCode"
            :dictFun="dictFun"
            parentLinkageRef
            fullLabel
            @getOtherJsonParam="(callback) => callback(dcSystemConfigAddDialog.form)"
          />
        </el-form-item>
        <el-form-item label="系统名称" prop="systemName">
          <el-input v-model="dcSystemConfigAddDialog.form.systemName" placeholder="请输入系统名称"/>
        </el-form-item>
        <el-form-item label="接入方式" prop="joinMode">
          <el-select v-model="dcSystemConfigAddDialog.form.joinMode" size="mini" clearable>
            <el-option :value=0 label="数据库" /><el-option :value=1 label="接口" />
          </el-select>
        </el-form-item>
        <el-form-item v-if="dcSystemConfigAddDialog.form.joinMode === 0" label="数据库类型" prop="databaseType">
          <el-select v-model="dcSystemConfigAddDialog.form.databaseType" size="mini" clearable>
            <el-option :value=0 label="mysql" />
            <el-option :value=1 label="oracle" />
            <el-option :value=2 label="sql server" />
          </el-select>
        </el-form-item>
        <el-form-item v-if="dcSystemConfigAddDialog.form.joinMode === 0" label="链接地址" prop="databaseUrl">
          <el-input v-model="dcSystemConfigAddDialog.form.databaseUrl" placeholder="请输入链接地址"/>
        </el-form-item>
        <el-form-item v-if="dcSystemConfigAddDialog.form.joinMode === 0" label="用户名" prop="databaseUsername">
          <el-input v-model="dcSystemConfigAddDialog.form.databaseUsername" placeholder="请输入用户名"/>
        </el-form-item>
        <el-form-item v-if="dcSystemConfigAddDialog.form.joinMode === 0" label="密码" prop="databasePassword">
          <el-input v-model="dcSystemConfigAddDialog.form.databasePassword" placeholder="请输入密码"/>
        </el-form-item>
        <el-form-item label="库名" prop="tableSchema">
          <el-input v-model="dcSystemConfigAddDialog.form.tableSchema" placeholder="请输入库名"/>
        </el-form-item>
        <el-form-item v-if="dcSystemConfigAddDialog.form.joinMode === 1" label="接口地址" prop="apiUrl">
          <el-input v-model="dcSystemConfigAddDialog.form.apiUrl" placeholder="请输入接口地址"/>
        </el-form-item>
        <el-form-item label="店铺维表" prop="shopTable">
          <el-input v-model="dcSystemConfigAddDialog.form.shopTable" placeholder="请输入店铺维表"/>
        </el-form-item>
        <el-form-item label="关联主键" prop="correlationPrimaryKey">
          <el-input v-model="dcSystemConfigAddDialog.form.correlationPrimaryKey" placeholder="请输入关联主键"/>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button size="mini" @click="dcSystemConfigAddDialog.visible = false">取消</el-button>
        <el-button size="mini" type="primary" @click="submitAddDcSystemConfigForm">确定</el-button>
      </div>
    </el-dialog>

    <el-dialog :title="dcSystemConfigEditDialog.title" :visible.sync="dcSystemConfigEditDialog.visible" width="600px" :close-on-click-modal="false">
      <el-form ref="spuInfoUpdate" size="mini" label-width="100px" :model="dcSystemConfigEditDialog.form" :rules="dcSystemConfigEditDialog.rules">
        <el-form-item label="分公司" prop="orgCode">
          <DictSelect
            ref="addOrg"
            type="ht-org"
            :value.sync="dcSystemConfigEditDialog.form.orgCode"
            linkageRef="addDc"
            init
            :initOptions="orgDictInitOptions"
            fullLabel
            :clearable="false"
            placeholder="请选择分公司"
            @changeLinkageRef="handleQueryChangeLinkageRef"
            disabled="true"
          />
        </el-form-item>
        <el-form-item label="配送中心" prop="dcCode">
          <DictSelect
            ref="addDc"
            type="ht-dc"
            :value.sync="dcSystemConfigEditDialog.form.dcCode"
            :dictFun="dictFun"
            parentLinkageRef
            fullLabel
            @getOtherJsonParam="(callback) => callback(dcSystemConfigEditDialog.form)"
            disabled="true"
          />
        </el-form-item>
        <el-form-item label="系统名称" prop="systemName">
          <el-input disabled="true" v-model="dcSystemConfigEditDialog.form.systemName" placeholder="请输入系统名称"/>
        </el-form-item>
        <el-form-item label="接入方式" prop="joinMode">
          <el-select disabled="true" v-model="dcSystemConfigEditDialog.form.joinMode" size="mini" clearable>
            <el-option :value=0 label="数据库" /><el-option :value=1 label="接口" />
          </el-select>
        </el-form-item>
        <el-form-item v-if="dcSystemConfigEditDialog.form.joinMode === 0" label="数据库类型" prop="databaseType">
          <el-select v-model="dcSystemConfigEditDialog.form.databaseType" size="mini" clearable>
            <el-option :value=0 label="mysql" />
            <el-option :value=1 label="oracle" />
            <el-option :value=2 label="sql server" />
          </el-select>
        </el-form-item>
        <el-form-item v-if="dcSystemConfigEditDialog.form.joinMode === 0" label="链接地址" prop="databaseUrl">
          <el-input v-model="dcSystemConfigEditDialog.form.databaseUrl" placeholder="请输入链接地址"/>
        </el-form-item>
        <el-form-item v-if="dcSystemConfigEditDialog.form.d === 0" label="用户名" prop="databaseUsername">
          <el-input v-model="dcSystemConfigEditDialog.form.databaseUsername" placeholder="请输入用户名"/>
        </el-form-item>
        <el-form-item v-if="dcSystemConfigEditDialog.form.joinMode === 0" label="密码" prop="databasePassword">
          <el-input v-model="dcSystemConfigEditDialog.form.databasePassword" placeholder="请输入密码"/>
        </el-form-item>
        <el-form-item label="库名" prop="tableSchema">
          <el-input v-model="dcSystemConfigEditDialog.form.tableSchema" placeholder="请输入库名"/>
        </el-form-item>
        <el-form-item v-if="dcSystemConfigEditDialog.form.joinMode === 1" label="接口地址" prop="apiUrl">
          <el-input v-model="dcSystemConfigEditDialog.form.apiUrl" placeholder="请输入接口地址"/>
        </el-form-item>
        <el-form-item label="店铺维表" prop="shopTable">
          <el-input v-model="dcSystemConfigEditDialog.form.shopTable" placeholder="请输入店铺维表"/>
        </el-form-item>
        <el-form-item label="关联主键" prop="correlationPrimaryKey">
          <el-input v-model="dcSystemConfigEditDialog.form.correlationPrimaryKey" placeholder="请输入关联主键"/>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button size="mini" @click="dcSystemConfigEditDialog.visible = false">取消</el-button>
        <el-button size="mini" type="primary" @click="submitEditDcSystemConfigForm">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import DictSelect from "@/components/DictSelect";
import {
  deleteDcSystemConfig,
  dictSelect,
  queryDcSystemConfigPageList,
  addDcSystemConfig
} from "@/api/appTask";
import {Notification} from "element-ui";
import { deepClone } from "@/utils"
import {editDcSystemConfig} from "../../../api/appTask";

export default {
  name: "dcSystemConfig",
  components: { Pagination, DictSelect },
  data() {
    return {
      pageData: {
        total: 0,
        rows: [],
        selections: [],
      },
      queryForm: {
        current: 1,
        size: 10,
        orgCode: undefined,
        dcCodes: [],
      },
      loading: false,
      dictFun: dictSelect,
      orgDictInitOptions: [],
      dcSystemConfigAddDialog: {
        visible: false,
        title: "新增配送中心信息系统维护",
        form: {
          orgCode: undefined,
          dcCode: undefined,
          systemName: undefined,
          joinMode: 0,
          databaseType: undefined,
          databaseUrl: undefined,
          databaseUsername: undefined,
          databasePassword: undefined,
          tableSchema: undefined,
          shopTable: undefined,
          apiUrl: undefined,
          correlationPrimaryKey: undefined,
        },
        rules: {
          orgCode: [{required: true, message: '分公司编码不能为空！', trigger: 'change'}],
          dcCode: [{required: true, message: '配送中心编码不能为空！', trigger: 'change'}],
          systemName: [{required: true, message: '系统名称不能为空！', trigger: 'change'}],
          joinMode: [{required: true, message: '接入方式不能为空！', trigger: 'change'}],
        }
      },
      dcSystemConfigEditDialog: {
        visible: false,
        title: "编辑配送中心信息系统维护",
        form: {
          orgCode: undefined,
          dcCode: undefined,
          systemName: undefined,
          joinMode: 0,
          databaseType: undefined,
          databaseUrl: undefined,
          databaseUsername: undefined,
          databasePassword: undefined,
          tableSchema: undefined,
          shopTable: undefined,
          apiUrl: undefined,
          correlationPrimaryKey: undefined,
        },
        rules: {
          orgCode: [{required: true, message: '分公司编码不能为空！', trigger: 'change'}],
          dcCode: [{required: true, message: '配送中心编码不能为空！', trigger: 'change'}],
          systemName: [{required: true, message: '系统名称不能为空！', trigger: 'change'}],
          joinMode: [{required: true, message: '接入方式不能为空！', trigger: 'change'}],
        }
      },
    };
  },
  created() {
    this.handleFilter();
    dictSelect('ht-org').then(res => {
      this.orgDictInitOptions = res.data;
      if (res.data.length > 0) {
        if (res.data.length === 1) {
          this.defaultOrgCode = res.data[0].value;
          this.queryForm.orgCode = this.defaultOrgCode;
          this.$refs['dc'].handleRefChange(this.defaultOrgCode, 'auto');
          this.handleFilter();
        }
      }
    });
  },
  methods: {
    handleQuery() {
      this.loading = true;
      queryDcSystemConfigPageList(this.queryForm).then(res => {
        if (!res.data) {
          return false;
        }
        this.loading = false;
        this.pageData.rows = res.data?.records;
        this.pageData.total = res.data?.total;
      })
    },
    handleFilter() {
      this.queryForm.current = 1;
      this.handleQuery();
    },
    handleDeleteDcSystemConfig(data) {
      const argument = Object.assign({}, data);
      this.$confirm('是否确认删除该数据！', '提示', { type: 'warning' }).then(() => {
        deleteDcSystemConfig(argument).then(res=> {
          if (res.code === 200) {
            Notification.success('操作成功!');
            this.handleQuery();
          }
        })
      }).catch(() => {
      })
    },
    handleQueryChangeLinkageRef(ref, val, type, form, dict) {
      this.$nextTick(() => {
        ref.split(',').forEach(e => {
          if (this.$refs[e]) {
            if (form && dict) {
              this[dict] = Object.assign({}, form)
            } else {
              this.dictParam = Object.assign({}, this.queryForm)
            }
            setTimeout(() => {
              this.$refs[e].handleRefChange(val, type);
            }, 100)
            this.$forceUpdate();
          }
        })
      });
    },
    handleDcSystemConfigAdd() {
      this.rsetDcSystemConfigAddDialogForm();
      this.dcSystemConfigAddDialog.visible = true;
    },
    rsetDcSystemConfigAddDialogForm(){
      this.dcSystemConfigAddDialog.form = {
        orgCode: undefined,
        dcCode: undefined,
        systemName: undefined,
        joinMode: 0,
        databaseType: undefined,
        databaseUrl: undefined,
        databaseUsername: undefined,
        databasePassword: undefined,
        tableSchema: undefined,
        shopTable: undefined,
        apiUrl: undefined,
        correlationPrimaryKey: undefined,
      };
      this.dcSystemConfigAddDialog.visible = false;
    },
    submitAddDcSystemConfigForm() {
      const argument = deepClone(this.dcSystemConfigAddDialog.form);
      addDcSystemConfig(argument).then(res => {
        if (res.code === 200) {
          if (res.data) {
            Notification.error(res.data);
          } else {
            this.$message.success("操作成功！");
            this.rsetDcSystemConfigAddDialogForm();
            this.dcSystemConfigAddDialog.visible = false;
            this.handleQuery();
          }
        }
      })
    },
    handleEditDcSystemConfigForm(data) {
      this.rsetDcSystemConfigEditDialogForm();
      this.dcSystemConfigEditDialog.visible = true;
      this.dcSystemConfigEditDialog.form = deepClone(data);
    },
    rsetDcSystemConfigEditDialogForm(){
      this.dcSystemConfigEditDialog.form = {
        orgCode: undefined,
        dcCode: undefined,
        systemName: undefined,
        joinMode: 0,
        databaseType: undefined,
        databaseUrl: undefined,
        databaseUsername: undefined,
        databasePassword: undefined,
        tableSchema: undefined,
        shopTable: undefined,
        apiUrl: undefined,
        correlationPrimaryKey: undefined,
      };
      this.dcSystemConfigEditDialog.visible = false;
    },
    submitEditDcSystemConfigForm() {
      const argument = deepClone(this.dcSystemConfigEditDialog.form);
      editDcSystemConfig(argument).then(res => {
        if (res.code === 200) {
          if (res.data) {
            Notification.error(res.data);
          } else {
            this.$message.success("操作成功！");
            this.rsetDcSystemConfigEditDialogForm();
            this.dcSystemConfigEditDialog.visible = false;
            this.handleQuery();
          }
        }
      })
    },
  },
};
</script>
