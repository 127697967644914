<template>
  <div style="padding: 20px">
    <div class="mg-search" style="padding-bottom: 15px">
      <el-form ref="queryFormRef" size="mini" label-width="auto" :inline="true" :model="queryForm" @keyup.enter.native="handleFilter">
        <el-form-item label="分公司">
          <DictSelect
            ref="org"
            type="org"
            :value.sync="queryForm.orgCode"
            linkageRef="dc,shop"
            :dictFun="dictFun"
            fullLabel
            :init="true"
            :init-options="orgDictInitOptions"
            @changeLinkageRef="handleChangeLinkageRef"
          />
        </el-form-item>
        <el-form-item label="配送中心">
          <DictSelect
            ref="dc"
            type="dc"
            :value.sync="queryForm.dcCodes"
            :dictFun="dictFun"
            parentLinkageRef
            fullLabel
            multiple
            collapse-tags
            @getOtherJsonParam="(callback) => callback(dictParam)"
            @change="dictParam = Object.assign({}, queryForm)"
          />
        </el-form-item>
        <el-form-item label="店铺">
          <DictSelect
            ref="shop"
            type="shop"
            :value.sync="queryForm.shopCode"
            :dictFun="dictFun"
            parentLinkageRef
            fullLabel
            remote
            :init-props="{ label: 'label', value: 'fullCode'}"
            @getOtherJsonParam="(callback) => callback({ orgCode: queryForm.orgCode, dcCode:queryForm.dcCodes})"
          />
        </el-form-item>
        <el-form-item>
          <el-button class="form-btn-item" size="mini" icon="el-icon-search" type="primary" @click="handleFilter">查询</el-button>
<!--          <el-button class="form-btn-item" size="mini" icon="el-icon-plus" type="primary" @click="handleDcSystemConfigAdd">新增</el-button>-->
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :data="pageData.rows"
      height="calc(100vh - 150px)"
      v-loading="loading"
      size="mini"
      border
      highlight-current-row
      :header-cell-style="{ background: 'rgb(227 224 224)', color: 'black' }"
    >
      <el-table-column label="分公司名称" prop="orgName" width="200" />
      <el-table-column label="配送中心名称" prop="dcName" width="200" />
      <el-table-column label="终端编码" prop="shopCode" width="60" />
      <el-table-column label="终端名称" prop="shopName" width="200" />
      <el-table-column label="系统名称" prop="systemName" width="120" />
      <el-table-column label="系统中编码(多个编码，使用，隔开)" prop="fillField"  width="260" >
        <template slot-scope="scope">
          <span v-if="scope.$index!==currentIndex">{{ scope.row.fillField }}</span>
          <el-input v-else v-model="currentRow.fillField" size="mini" clearable />
        </template>
      </el-table-column>
      <el-table-column label="匹配状态" prop="matchStatus"  width="200" />
      <el-table-column label="操作" width="120" align="center">
        <template #default="scope">
          <el-col v-if="scope.$index!==currentIndex" :span="6">
            <el-button size="mini" type="text" style="border: 0px;" icon="el-icon-edit" @click="handleDcExternalShopConfigEdit(scope.row, scope.$index)">编辑</el-button>
          </el-col>
          <el-col v-if="scope.$index==currentIndex" :span="6">
            <el-button size="mini" type="text" style="border: 0px;" @click="() => {currentRow = undefined; currentIndex = undefined; }">取消</el-button>
          </el-col>
          <el-col v-if="scope.$index==currentIndex" :span="6">
            <el-button size="mini" type="text" style="border: 0px;margin-left: 50%" @click="submitDcExternalShopConfigEdit(currentRow)">保存</el-button>
          </el-col>
        </template>
      </el-table-column>
    </el-table>
    <Pagination :total="pageData.total" :page.sync="queryForm.current" :limit.sync="queryForm.size" @pagination="handleQuery" />
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import DictSelect from "@/components/DictSelect";
import {
  dictSelect,
  topShopDict,
  queryDcExternalShopConfigPageList,
} from "@/api/appTask";
import {Notification} from "element-ui";
import { deepClone } from "@/utils"
import {updateDcExternalShopConfig} from "../../../api/appTask";

export default {
  name: "dcExternalShopConfig",
  components: { Pagination, DictSelect },
  data() {
    return {
      pageData: {
        total: 0,
        rows: [],
        selections: [],
      },
      queryForm: {
        current: 1,
        size: 10,
        orgCode: undefined,
        dcCodes: [],
        shopCode: undefined,
      },
      loading: false,
      dictFun: topShopDict,
      orgDictInitOptions: [],
      currentRow: {},
      currentIndex: undefined,
      dictParam: {},
    };
  },
  created() {
    dictSelect('ht-org-all').then(res => {
      this.orgAllAuth = res.data.filter(e => e.value === 'ALL').length > 0 ? true: false;
      this.orgDictInitOptions = res.data.filter(e => e.value !== 'ALL');
    })
    this.handleFilter();
  },
  methods: {
    handleQuery() {
      this.loading = true;
      queryDcExternalShopConfigPageList(this.queryForm).then(res => {
        if (!res.data) {
          return false;
        }
        this.loading = false;
        this.pageData.rows = res.data?.records;
        this.pageData.total = res.data?.total;
      })
    },
    handleFilter() {
      this.queryForm.current = 1;
      this.handleQuery();
    },
    handleChangeLinkageRef(ref, val, type, form, dict) {
      this.$nextTick(() => {
        ref.split(',').forEach(e => {
          if (this.$refs[e]) {
            if (form && dict) {
              let tmp = Object.assign({}, form);
              for(const key in this[dict]) {
                this[dict][key] = tmp[key];
              }
            } else {
              this.dictParam = Object.assign({}, this.queryForm)
            }
            setTimeout(() => {
              this.$refs[e].handleRefChange(val, type);
            }, 200)
            this.$forceUpdate();
          } else {
            this.dictParam = Object.assign({}, this.queryForm)
          }
        })
      });
    },
    handleChangeLinkageRef(ref, val, type, form, dict) {
      this.$nextTick(() => {
        ref.split(',').forEach(e => {
          if (this.$refs[e]) {
            if (form && dict) {
              let tmp = Object.assign({}, form);
              for(const key in this[dict]) {
                this[dict][key] = tmp[key];
              }
            } else {
              this.dictParam = Object.assign({}, this.queryForm)
            }
            setTimeout(() => {
              this.$refs[e].handleRefChange(val, type);
            }, 100)
            this.$forceUpdate();
          } else {
            this.dictParam = Object.assign({}, this.queryForm)
          }
        })
      });
    },
    handleDcExternalShopConfigEdit(row, index) {
      this.currentRow = deepClone(row);
      this.currentIndex = index;
    },
    submitDcExternalShopConfigEdit(row) {
      updateDcExternalShopConfig({...row}).then(res => {
        if (res.code == 200) {
          this.$message.success("操作成功！");
          this.currentIndex = undefined;
          this.currentRow = undefined;
          this.handleQuery();
        }
      })
    },
  },
};
</script>
